import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SidebarToggleBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const SidebarToggleStick = styled.span`
  width: 25px;
  height: 3px;
  display: block;
  margin-bottom: 5px;
  background: #fff;
`;

const SidebarToggler = ({ className, onClick }) => {
  return (
    <SidebarToggleBtn
      className={`sidebar-toggler ${className}`}
      onClick={onClick}
    >
      <SidebarToggleStick className="stick" />
      <SidebarToggleStick className="stick" />
      <SidebarToggleStick className="stick mb-0" />
    </SidebarToggleBtn>
  );
};

SidebarToggler.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

SidebarToggler.defaultProps = {
  onClick: () => {},
  className: null,
};

export default SidebarToggler;

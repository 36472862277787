import React, { useEffect, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHedgieRequest } from '../../../store/hedgies/actions';
import { getWorlds } from '../../../store/journey/actions';
import { selectWorlds } from '../../../store/journey/selectors';
import { useHistory } from 'react-router-dom';
import { getParticipantInfo } from './battleLogic';
import useMedia from '../../custom-hooks/useMedia';
import { useWebsocket } from '../../../contexts/websocket-context';

const BattleContext = createContext(null);

export const pageNames = {
  BATTLE_HOME: 'battle_home',
  WAITING_CHALLENGER: 'waiting_challenger',
  CHALLENGER_FOUND: 'challenger_found',
  POINTS_SUMMARY: 'points_summary',
  WINNER: 'winner',
};

const BattleProvider = ({ children }) => {
  const [betData, setBetData] = useState(null);
  const [battleError, setBattleError] = useState('');
  const [selectedWorld, setSelectedWorld] = useState('');
  const { webSocket, socketMessage, sendEvent } = useWebsocket();
  const hedgie = useSelector((state) => state.hedgies.hedgie);
  const [page, setPage] = useState(pageNames.BATTLE_HOME);
  let { color } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const isMobile = useMedia(
    ['(min-width: 992px)', '(max-width: 768px)'],
    [false, true],
    false,
  );

  useEffect(() => {
    dispatch(getHedgieRequest(color));
  }, [dispatch, color]);

  useEffect(() => {
    dispatch(getWorlds());
    // eslint-disable-next-line
  }, []);

  const worlds = useSelector((state) => selectWorlds(state));

  function submitBetForm(curio, color, user) {
    sendEvent('find_challenge', {
      hedgie_id: hedgie.id,
      hedgie_opponent_color:color,
      hedgie_opponent_user:user,
      bet_amount: curio,
    });
  }

  function onLeave() {
    sendEvent('cancelled_challenge', {
      hedgie_id: hedgie.id,
    });
    history.push('/my-hedgies');
  }

  function selectWorld(imgUrl) {
    setSelectedWorld(imgUrl);
  }

  useEffect(() => {
    if (worlds.length) {
      setSelectedWorld(worlds[0]);
    }
  }, [worlds]);

  useEffect(() => {
    switch (socketMessage.event) {
      case 'waiting_challenger': {
        if (
          hedgie &&
          socketMessage.data &&
          socketMessage.event !== 'error' &&
          socketMessage.data.hedgie_id === hedgie.id
        ) {
          setPage(pageNames.WAITING_CHALLENGER);
        }
        break;
      }

      case 'cancelled_challenge':
        setPage(pageNames.BATTLE_HOME);
        break;

      case 'battle_finished':
        setPage(pageNames.CHALLENGER_FOUND);
        break;

      case 'error':
        setBattleError(socketMessage.data.message);
        break;

      default:
        setPage(hedgie?.jsBattling ? pageNames.WAITING_CHALLENGER : pageNames.BATTLE_HOME);
        break;
    }
  }, [socketMessage.event, socketMessage.data, hedgie]);

  const { hedgieOwner, opponent } = React.useMemo(
    () => getParticipantInfo(socketMessage, hedgie ? hedgie.id : null),
    [socketMessage, hedgie],
  );

  return (
    <BattleContext.Provider
      value={{
        webSocket,
        hedgie,
        betData,
        setBetData,
        submitBetForm,
        socketMessage,
        onLeave,
        worlds,
        selectWorld,
        selectedWorld,
        setPage,
        page,
        battleInfo: socketMessage.data,
        hedgieOwner,
        opponent,
        isMobile,
        battleError,
      }}
    >
      {children}
    </BattleContext.Provider>
  );
};

const useBattle = () => useContext(BattleContext);

export { BattleProvider, useBattle };

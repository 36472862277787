import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import iconsSvg from './iconsSvg';
import { POWER_GRADIENT_COLORS } from './gradientColors';

function getGradientColors(powerName) {
  if (!powerName) {
    return POWER_GRADIENT_COLORS.default;
  }
  return POWER_GRADIENT_COLORS[powerName];
}

const TEXT_FONT_SIZE = 14;
const LABEL_FONT_SIZE = 12;
const LABEL_COLOR = '#20041E';
const BAR_BG_COLOR = '#CAD6D9';
const MASK_BG_COLOR = 'rgba(255,255,255,0.5)';
const AMOUNT_TEXT_SPACE = 5;
const VALUE_TEXT_COLOR = '#ffffff';
const INCREASE_VALUE_COLOR = '#39B54A';
const DECREASE_VALUE_COLOR = '#FF0000';
const TEXT_SPACE = 6;
const BAR_HEIGHT = 167;
const BAR_WIDTH = 19;
const ICON_SIZE = 45;
const ARROW_WIDTH = 15;
const ARROW_HEIGHT = 10;
const TEXT_DISPLAY_THRESHOLD = 80;
const VALUE_THRESHOLD = 100;

const ARROW_POS = 'ARROW_POS';
const VALUE_POS = 'VALUE_POS';
const AMOUNT_POS = 'AMOUNT_POS';

const UP_ARROW_FIXED_POS = -42;
const DOWN_ARROW_FIXED_POS = -32;
const AMOUNT_FIXED_POS = -22;
const VALUE_FIXED_POS = -8;

const POWER_NAME_TRANSLATE = 4;
const POWER_IMAGE_TRANSLATE = 6;
const POWER_CIRCLE_TRANSLATE = 10;
const AXIS_TRANSLATE = 65;

const ARROW_TRANSLATE = -30;
const AMOUNT_TRANSLATE = -8;

const TEXT_POSITIONS = {
  [ARROW_POS]: ARROW_TRANSLATE,
  [AMOUNT_POS]: AMOUNT_TRANSLATE,
};

const generatePos = (value, amount, dynamicPos, fixedPos, type) => {
  if (value >= VALUE_THRESHOLD) {
    return fixedPos;
  } else if (value + amount >= TEXT_DISPLAY_THRESHOLD) {
    if (type === VALUE_POS) {
      return dynamicPos;
    }
    return TEXT_POSITIONS[type];
  }
  return dynamicPos;
};

const HedgiePowerChart = ({
  value,
  amount,
  powerName,
  label,
  svgWidth,
  svgHeight,
  circleBg,
}) => {
  const absAmount = Math.abs(amount);
  const barHeight = BAR_HEIGHT;
  const iconWidth = ICON_SIZE;
  const iconHeight = ICON_SIZE;
  const barWidth = BAR_WIDTH;

  const percentValue = Math.ceil((value * barHeight) / 100);
  const percentAmount = Math.ceil((absAmount * barHeight) / 100);

  // const posYValue = percentValue + percentAmount;
  const posYValueText = barHeight - percentValue + TEXT_FONT_SIZE;
  const posXText = (svgWidth - TEXT_FONT_SIZE - 1) / 2;
  const posYAmountText =
    amount < 0
      ? barHeight - percentValue - AMOUNT_TEXT_SPACE
      : barHeight - percentValue - percentAmount - AMOUNT_TEXT_SPACE;

  const { gradientColorStart, gradientColorEnd } = getGradientColors(powerName);

  const barPosX = (iconWidth - barWidth) / 2;
  const iconPosX = 0;
  const iconPosY = barHeight + TEXT_SPACE - iconWidth / 2;
  // const isRoundedBar = Math.ceil(value + amount) >= 100;
  const grStyleFrom = {
    stopColor: gradientColorStart,
    stopOpacity: 1,
  };
  const labelPosX = (iconWidth + TEXT_SPACE) / 2;
  const labelPosY = iconPosY + iconHeight + TEXT_SPACE;

  const grStyleEnd = {
    stopColor: gradientColorEnd,
    stopOpacity: 1,
  };

  const textStyle = {
    fontSize: TEXT_FONT_SIZE,
    fontWeight: 'bold',
    color: VALUE_TEXT_COLOR,
    letterSpacing: 0,
    textAnchor: 'middle',
  };
  const labelStyle = {
    fontSize: LABEL_FONT_SIZE,
    color: LABEL_COLOR,
    fontWeight: 600,
    textAnchor: 'end',
  };

  const props3 = useSpring({
    value: amount < 0 ? percentValue : percentValue + percentAmount,
  });

  const propsPercent = useSpring({
    value: percentAmount,
  });

  const propsText = useSpring({
    value: generatePos(
      value,
      amount,
      posYValueText,
      VALUE_FIXED_POS,
      VALUE_POS,
    ),
  });

  return (
    <svg
      width={svgWidth}
      height={svgHeight + AXIS_TRANSLATE}
      viewBox={`0 0 ${svgWidth} ${svgHeight + AXIS_TRANSLATE}`}
      style={{
        width: '100%',
        maxWidth: 45,
        height: 'auto',
        padding: '0 2px',
      }}
    >
      <defs>
        <linearGradient id={powerName} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={grStyleEnd} />
          <stop offset="100%" style={grStyleFrom} />
        </linearGradient>
      </defs>

      <defs>
        <clipPath id="clipPath">
          <rect
            x={barPosX}
            y="0"
            rx={Math.ceil(barWidth / 2)}
            ry={Math.ceil(barWidth / 2)}
            width={barWidth}
            height={barHeight}
          />
        </clipPath>
      </defs>
      <g transform={`translate(0,${AXIS_TRANSLATE - 10})`}>
        <rect
          x={barPosX}
          y="0"
          rx={barWidth / 2}
          ry={barWidth / 2}
          width={barWidth}
          height={barHeight}
          fill={BAR_BG_COLOR}
        />
        <g
          transform={`scale(1,-1) translate(0,-${barHeight})`}
          style={{ clipPath: 'url(#clipPath)' }}
        >
          <animated.rect
            x={barPosX}
            y={0}
            width={barWidth}
            height={props3.value}
            fill={`url(#${powerName})`}
          />
          {amount !== 0 ? (
            <animated.rect
              x={barPosX}
              y={amount < 0 ? percentValue - percentAmount : percentValue}
              width={barWidth}
              height={propsPercent.value}
              fill={MASK_BG_COLOR}
            />
          ) : null}
        </g>
        <animated.text
          x={svgWidth / 2}
          y={propsText.value}
          fill={value >= VALUE_THRESHOLD ? '#000' : VALUE_TEXT_COLOR}
          style={textStyle}
        >
          {Math.ceil(value)}
        </animated.text>
        {amount !== 0 ? (
          <g>
            <g
              id="Group"
              fill={amount < 0 ? DECREASE_VALUE_COLOR : INCREASE_VALUE_COLOR}
              transform={
                amount > 0
                  ? `translate(${posXText},${generatePos(
                      value,
                      amount,
                      posYAmountText - 2.1 * ARROW_HEIGHT,
                      UP_ARROW_FIXED_POS,
                      ARROW_POS,
                    )})`
                  : `translate(${posXText + ARROW_WIDTH},${generatePos(
                      value,
                      amount,
                      posYAmountText - 1.2 * ARROW_HEIGHT,
                      DOWN_ARROW_FIXED_POS,
                      ARROW_POS,
                    )}) rotate(180)`
              }
            >
              <polygon
                id="Fill-128"
                points="7.09999045 0.424078078 0.394703206 7.201003 2.6294939 9.46059459 7.0990753 4.94233634 11.5695718 9.46059459 13.8043625 7.201003"
              />
            </g>
            <text
              x={svgWidth / 2}
              y={generatePos(
                value,
                amount,
                posYAmountText,
                AMOUNT_FIXED_POS,
                AMOUNT_POS,
              )}
              fill={amount < 0 ? DECREASE_VALUE_COLOR : INCREASE_VALUE_COLOR}
              style={textStyle}
            >
              {Math.ceil(absAmount)}
            </text>
          </g>
        ) : null}
        <circle
          cx={svgWidth / 2}
          cy={barHeight + POWER_CIRCLE_TRANSLATE}
          r={iconWidth / 2}
          fill={circleBg}
          stroke={circleBg}
          strokeWidth="4"
        />
        <image
          x={iconPosX}
          y={iconPosY + POWER_IMAGE_TRANSLATE}
          width={iconWidth}
          height={iconHeight}
          xlinkHref={iconsSvg[powerName]}
        />
        <text
          x={labelPosX}
          y={labelPosY + POWER_NAME_TRANSLATE}
          transform={`rotate(-90, ${labelPosX}, ${labelPosY +
            POWER_NAME_TRANSLATE})`}
          style={labelStyle}
        >
          {label}
        </text>
      </g>
    </svg>
  );
};

HedgiePowerChart.defaultProps = {
  value: 0,
  amount: 0,
  powerName: '',
  label: '',
  svgWidth: 45,
  svgHeight: 270,
  circleBg: '#fff',
};

HedgiePowerChart.propTypes = {
  value: PropTypes.number,
  amount: PropTypes.number,
  powerName: PropTypes.string,
  label: PropTypes.string,
  svgWidth: PropTypes.number,
  svgHeight: PropTypes.number,
  circleBg: PropTypes.string,
};

export default React.memo(HedgiePowerChart);

import { useEffect, useState } from 'react';
import MetaMaskUtil from '../services/metamaskutil';
import { NET_WORK, netWorkMapper } from '../const/metamask';

export default function useMetamaskNetWork(){
  const [isMainnet, setIsMainnet] = useState(false);

  useEffect(() => {
    function handleChainId(id){
      setIsMainnet( id === netWorkMapper[NET_WORK].hex);
    }
    if(window.ethereum)
      window.ethereum.on("chainChanged", handleChainId);

    return () => {
      if( window.ethereum)
        window.ethereum.removeListener("chainChanged", handleChainId);
    }
  },[]);

  useEffect(()=> {
    console.log('network', NET_WORK);
    (async () => {
      const chainId = await MetaMaskUtil.getChainId();
      setIsMainnet(chainId === netWorkMapper[NET_WORK].decimal)
    })();
  },[]);

  return {
    isMainnet
  };
}
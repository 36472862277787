import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import Drawer from '../../commons/Drawer';
import Badge from '../../stateless/Badge';
import List from './List';
import MarkAllAsReadButton from './MarkAllAsReadButton';
import NotificationTurnOffWarning from './NotificationTurnOffWarning';
import GuidanceModal from './GuidanceModal';
import useMessageReceiver from './useMessageReceiver';
import usePermission from './usePermission';
import { updateNotificationReference } from '../../../store/notification/actions';
import useOnClickOutside from '../../custom-hooks/useOnClickOutside';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationPage = ({
  notifications,
  getNotifications,
  getNotificationsError,
  getNotificationsLoading,
  pagination,
  userIsReady,
  getNumberOfUnseenNotifications,
  unseenNotifications,
  markAllNotificationsAsRead,
  markANotificationAsRead,
  archiveNotification,
  history,
}) => {
  const { limit, offset, total } = pagination;
  const [open, toggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  usePermission(userIsReady, dispatch);
  useMessageReceiver(userIsReady, dispatch);

  const notificationRef = useRef(null);
  useOnClickOutside(notificationRef, () => (open ? toggle(false) : null));

  const openDrawerCallback = useCallback(
    () => {
      toggle(true);
      getNotifications({
        limit,
        offset: 0,
        isLoadMore: false,
      });
    },
    [getNotifications, limit],
  );

  const closeDrawerCallback = useCallback(() => {
    toggle(false);
  }, []);

  const loadMoreMemoized = useMemo(() => limit + offset < total, [
    limit,
    offset,
    total,
  ]);

  const handleLoadMoreCallback = useCallback(
    () => {
      getNotifications({
        limit,
        offset: limit + offset,
        isLoadMore: true,
      });
    },
    [getNotifications, limit, offset],
  );

  const isAllNotSeen = useMemo(
    () => {
      if (isEmpty(notifications)) {
        return false;
      }
      return (
        size(
          notifications.filter(
            notification =>
              notification.status === 'new' || notification.status === 'seen',
          ),
        ) > 0
      );
    },
    [notifications],
  );

  const handleGuidanceModalCallback = useCallback(() => {
    setOpenModal(true);
  }, []);

  const closeModalCallback = useCallback(() => {
    setOpenModal(false);
  }, []);

  const isBlockingBrowser = useSelector(
    state => state.notification.isBlockingBrowser,
  );

  const isEnabledSetting = useSelector(
    state => state.notification.isEnabledSetting,
  );

  const handleIgnoreCallback = useCallback(
    () => {
      dispatch(updateNotificationReference(false));
    },
    [dispatch],
  );

  return (
    <StyledWrapper>
      {isEnabledSetting && isBlockingBrowser ? (
        <NotificationTurnOffWarning
          openModal={handleGuidanceModalCallback}
          ignoreWarning={handleIgnoreCallback}
        >
          Your browser has blocked showing notification. Please turn it on!
        </NotificationTurnOffWarning>
      ) : null}

      <GuidanceModal openModal={openModal} closeModal={closeModalCallback} />

      <div>
        {userIsReady ? (
          <Badge
            onClick={openDrawerCallback}
            messageNo={unseenNotifications}
            getNumberOfUnseenNotifications={getNumberOfUnseenNotifications}
          />
        ) : null}
        <div ref={notificationRef}>
          <Drawer
            toggle={closeDrawerCallback}
            open={open}
            title="Notifications"
          >
            <React.Fragment>
              {isAllNotSeen ? (
                <MarkAllAsReadButton
                  markAllNotificationsAsRead={markAllNotificationsAsRead}
                />
              ) : null}
              <List
                notifications={notifications}
                error={getNotificationsError}
                loading={getNotificationsLoading}
                canLoadMore={loadMoreMemoized}
                loadMore={handleLoadMoreCallback}
                markANotificationAsRead={markANotificationAsRead}
                archiveNotification={archiveNotification}
                history={history}
                onCloseDrawer={closeDrawerCallback}
              />
            </React.Fragment>
          </Drawer>
        </div>
      </div>
    </StyledWrapper>
  );
};

NotificationPage.propTypes = {
  userIsReady: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  getNotifications: PropTypes.func.isRequired,
  markAllNotificationsAsRead: PropTypes.func.isRequired,
  getNumberOfUnseenNotifications: PropTypes.func.isRequired,
  registerADevice: PropTypes.func.isRequired,
  getNotificationsLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  getNotificationsError: PropTypes.object.isRequired,
  unseenNotifications: PropTypes.number.isRequired,
  markANotificationAsRead: PropTypes.func,
  archiveNotification: PropTypes.func,
  updateNewNotification: PropTypes.func,
  history: PropTypes.object.isRequired,
  unRegisterDevice: PropTypes.func,
};

NotificationPage.defaultProps = {
  markANotificationAsRead: null,
  archiveNotification: null,
  updateNewNotification: null,
  unRegisterDevice: null,
};

export default NotificationPage;

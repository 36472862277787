import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'components';
import styled from 'styled-components';
import mountainsImg from '../../../../images/kingdoms/mountains-world-small.jpg';
import wetlandsImg from '../../../../images/kingdoms/wetlands-world-small.jpg';
import desertImg from '../../../../images/kingdoms/desert-world-small.jpg';
import forestImg from '../../../../images/kingdoms/forest-world-small.jpg';
import mountainsFullImg from '../../../../images/kingdoms/mountains-world-full.jpg';
import wetlandsFullImg from '../../../../images/kingdoms/wetlands-world-full.jpg';
import desertFullImg from '../../../../images/kingdoms/desert-world-full.jpg';
import forestFullImg from '../../../../images/kingdoms/forest-world-full.jpg';

import ClickableSlide from '../ClickableSlide';
import Info from '../Info';
import Anchor from '../Anchor';

const TEXT =
  'Where shall we break ground?! There are four kingdoms to choose from: Desert,Wetlands, ' +
  'Forest, and Mountains. Send your Hedgie to the kingdom that corresponds to its power for best results!';

const CLICKING_GUIDELINE = 'Click on a Kingdom to learn more!';

const DATA = [
  {
    label: 'Mountain',
    name: 'mountain',
    description: '',
    imgUrl: mountainsImg,
    fullImgUrl: mountainsFullImg,
  },
  {
    label: 'Wetland',
    name: 'wetland',
    description: '',
    imgUrl: wetlandsImg,
    fullImgUrl: wetlandsFullImg,
  },
  {
    label: 'Desert',
    name: 'desert',
    description: '',
    imgUrl: desertImg,
    fullImgUrl: desertFullImg,
  },
  {
    label: 'Forest',
    name: 'forest',
    description: '',
    imgUrl: forestImg,
    fullImgUrl: forestFullImg,
  },
];

const StyledWorldsCarousel = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledWorldPhoto = styled.img`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.imgURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const WorldImgMobile = ({ imgURL }) => {
  return <StyledWorldPhoto imgURL={imgURL} />;
};

WorldImgMobile.propTypes = {
  imgURL: PropTypes.string.isRequired,
};

const KingdomsMobile = ({ onNextPage }) => {
  return (
    <StyledWorldsCarousel>
      <Carousel
        hasPagination
        hideArrow
        noGap
        numberOfItems={DATA.length}
        isOverLapSlide
      >
        {DATA.map(world => (
          <WorldImgMobile key={world.name} imgURL={world.fullImgUrl} />
        ))}
      </Carousel>
      <Info
        isMobile
        subTitle="The Kingdoms"
        text={TEXT}
        textColor="#000"
        title="Curiopia is Waiting"
        styles={{
          left: '50%',
          top: 50,
          transform: 'translateX(-50%)',
          position: 'absolute',
        }}
      />
      <Anchor
        text="Hunt for treasure"
        textColor="#fff"
        onClick={onNextPage}
        bottom={80}
      />
    </StyledWorldsCarousel>
  );
};

KingdomsMobile.propTypes = {
  onNextPage: PropTypes.func,
};

KingdomsMobile.defaultProps = {
  onNextPage: null,
};

const Kingdoms = ({ isMobile, onNextPage }) => {
  if (isMobile) {
    return <KingdomsMobile onNextPage={onNextPage} />;
  }
  return (
    <React.Fragment>
      <ClickableSlide
        introTitle="The Kingdoms"
        clickItemGuideline={CLICKING_GUIDELINE}
        introText={TEXT}
        data={DATA}
        isCircleSmallImg
        largePhotoRatio={824 / 639}
      />
      <Anchor
        text="Give Me Loot"
        textColor="#fff"
        onClick={onNextPage}
        bottom={isMobile ? 100 : 50}
      />
    </React.Fragment>
  );
};

Kingdoms.propTypes = {
  isMobile: PropTypes.bool,
  onNextPage: PropTypes.func,
};

Kingdoms.defaultProps = {
  isMobile: false,
  onNextPage: null,
};

export default memo(Kingdoms);

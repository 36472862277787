import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HedgieImg } from 'components';
import * as types from 'types';
//import * as paths from 'config/paths';
import styled from 'styled-components';
//import { Link } from 'react-router-dom';
import {
  EmptyHedgieListItemContainer,
  HedgieListItemContainer,
  // HedgieListItemLink,
} from './styles';
import { media } from '../../../styles/main';

const HedgieImgContainer = styled.div`
  position: relative;
  top: 0;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;

  ${media.largeDesktop`
    padding-top: 19px;
    padding-left: 9px;
    width: 153px;
  `}
  ${media.desktop`
    padding-top: 20px;
    padding-left: 10px;
    width: 140px;
  `}
  ${media.tablet`
    padding-top: 15px;
    padding-left: 9px;
    width: 120px;
  `}
  ${media.phone`
    padding-top: 15px;
    padding-left: 4px;
    width: 75px;
  `}
`;

const HedgieListItem = styled.div`
  max-width: 100% !important;
`;

const HedgieOnJourney = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HedgiesListItem = ({ hedgie, onClick, selected }) => {
  const clickCb = useCallback(() => {
    if (onClick) {
      onClick(hedgie);
    }
  }, [hedgie, onClick]);

  if (hedgie === null) {
    return (
      <EmptyHedgieListItemContainer>
        <HedgieListItem>
          {/* <Link to={paths.GET_HEDGIE}>
            <HedgieListItemLink>Get a Hedgie!</HedgieListItemLink>
          </Link> */}
        </HedgieListItem>
      </EmptyHedgieListItemContainer>
    );
  }
  return (
    <HedgieListItem className={classnames(['hedgieListItem', { selected }])}>
      <HedgieListItemContainer
        key={hedgie.color}
        onClick={hedgie.disabled ? undefined : clickCb}
        active={!hedgie.disabled && selected}
        disabled={!!hedgie.disabled}
      >
        <HedgieImgContainer>
          {hedgie.disabled ? (
            <HedgieOnJourney>On Journey</HedgieOnJourney>
          ) : null}
          <HedgieImg
            color={hedgie.disabled ? 'gray' : `#${hedgie.color}`}
            width="100%"
            maxWidth="initial"
            imgURL={hedgie.imgURL}
          />
        </HedgieImgContainer>
      </HedgieListItemContainer>
    </HedgieListItem>
  );
};

HedgiesListItem.propTypes = {
  hedgie: types.hedgie,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

HedgiesListItem.defaultProps = {
  hedgie: null,
  selected: false,
  disabled: false,
};

export default React.memo(HedgiesListItem);

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import { useSpring, animated } from 'react-spring';
import arrowUp from './arrow-up.svg';

const StyledWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: ${p => p.width + 10}px;
  height: ${p => p.width + 10}px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${p => (p.canLevelUp ? 'pointer' : null)};
  &:hover {
    .ugd-text {
      fill: #30e6cc;
    }
  }
`;

const StyledSvg = styled.svg`
  display: block;
`;

const StyledPath = styled(animated.path)`
  stroke: #30e6cc;
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
`;

const StyledCirclePath = styled.path`
  fill: none;
  stroke: #9e9e9e;
  stroke-width: 2;
`;

const StyledText = styled.text`
  fill: ${p => p.color};
  font-size: ${p => p.size}em;
  font-weight: ${p => (p.bold ? 700 : 400)};
  text-anchor: middle;
`;

const StyledSmallText = styled.text`
  fill: ${p => p.color};
  font-size: 0.3em;
  font-weight: 600;
  text-anchor: middle;
`;

const StyledImage = styled.image`
  width: 16px;
  height: 16px;
`;

const StyledGroup = styled.g``;

const circlePath =
  'M18 2.0845' +
  'a 15.9155 15.9155 0 0 1 0 31.831' +
  'a 15.9155 15.9155 0 0 1 0 -31.831';

const CircleProgress = ({ percent, level, canLevelUp, onClick, ...props }) => {
  const props3 = useSpring({
    stroke: `${percent}, 100`,
  });
  return (
    <StyledWrapper {...props} onClick={onClick}>
      <StyledSvg viewBox="0 0 36 36" {...props}>
        <StyledCirclePath d={circlePath} />
        <StyledPath strokeDasharray={props3.stroke} d={circlePath} />
        {canLevelUp ? (
          <StyledGroup>
            <StyledImage x={10} y={6} xlinkHref={arrowUp} />
            <StyledSmallText
              x="18"
              y="24.5"
              color="#63275F"
              className="ugd-text"
            >
              Upgrade
            </StyledSmallText>
          </StyledGroup>
        ) : null}
        {canLevelUp ? null : (
          <StyledText x="18" y="19.5" size={1.1} bold color="#63275F">
            {level}
          </StyledText>
        )}
        {canLevelUp ? null : (
          <StyledText x="18" y="27.5" size={0.32} color="#63275F">
            Lvl
          </StyledText>
        )}
      </StyledSvg>
    </StyledWrapper>
  );
};

CircleProgress.propTypes = {
  percent: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  level: PropTypes.number,
  canLevelUp: PropTypes.bool,
  onClick: PropTypes.func,
};

CircleProgress.defaultProps = {
  width: 100,
  height: 100,
  percent: 20,
  level: 3,
  canLevelUp: false,
  onClick: null,
};

export default React.memo(CircleProgress);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';
import styled from 'styled-components';
import { palette } from 'styled-theme';
import Carousel from 'nuka-carousel';
import { findIndex } from 'lodash';
import HedgiesListItem from './HedgiesListItem';
import useMedia from '../../custom-hooks/useMedia';

const HedgieSelectedIndicator = styled.div`
  background-color: ${palette(1)};
  height: 25px;
  width: 112px;
  max-width: 90%;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
`;

const HedgieSliderContainer = styled.div`
  max-width: 800px;
  margin: auto;
`;

HedgieSelectedIndicator.defaultProps = {
  palette: 'primary',
};

const HedgiePagination = ({
  hedgies,
  onSelectHedgie,
  selectedHedgie,
  onLoadMore,
  urlColor,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderLimit = useMedia(
    ['(min-width: 992px)', '(max-width: 768px)'],
    [4, 3],
    4,
  );

  useEffect(() => {
    if (hedgies.length > 0) {
      const hedgieByColorIndex = findIndex(
        hedgies,
        hedgie => hedgie && hedgie.color === urlColor,
      );
      if (hedgieByColorIndex !== -1) {
        setSlideIndex(hedgieByColorIndex + 1 - sliderLimit);
        onSelectHedgie(hedgies[hedgieByColorIndex]);
      }
    }
  }, [hedgies, onSelectHedgie, sliderLimit, urlColor]);

  // const handleAfterSlideCallback = useCallback(
  //   slideIndex => {
  //     if (slideIndex) {
  //       setSlideIndex(slideIndex);
  //       if (slideIndex + sliderLimit >= hedgies.length) {
  //         onLoadMore(HEDGIE_LIST_LENGTH);
  //       }
  //     }
  //   },
  //   [hedgies.length, onLoadMore, sliderLimit],
  // );

  return (
    <HedgieSliderContainer>
      <Carousel
        slideIndex={slideIndex}
        slidesToShow={sliderLimit}
        slidesToScroll={sliderLimit}
        heightMode="max"
        className="hedgie-slider"
      >
        {hedgies.map((hedgie, index) => (
          <HedgiesListItem
            key={`${index}${hedgie ? hedgie.color : 'color'}`}
            hedgie={hedgie}
            selected={
              hedgie && selectedHedgie && hedgie.id === selectedHedgie.id
            }
            onClick={onSelectHedgie}
          />
        ))}
      </Carousel>
    </HedgieSliderContainer>
  );
};

HedgiePagination.propTypes = {
  hedgies: PropTypes.arrayOf(types.hedgie),
  onSelectHedgie: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  urlColor: PropTypes.string,
  selectedHedgie: PropTypes.object,
};

HedgiePagination.defaultProps = {
  hedgies: [],
  urlColor: '',
  selectedHedgie: null,
};

export default React.memo(HedgiePagination);

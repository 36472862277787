import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.pcss';

const { string, bool } = PropTypes;

export default class Icon extends Component {
  static propTypes = {
    name: string.isRequired,
    style: PropTypes.object,
    hover: bool,
  };

  static defaultProps = {
    hover: false,
    style: {},
  };

  render() {
    const hover = this.props.hover && styles.hover;
    const classes = classNames(
      styles.default,
      {
        [styles[`${this.props.name}`]]: this.props.name,
      },
      hover,
    );
    return <div style={this.props.style} className={classes} />;
  }
}

import { isEmpty } from 'lodash';
import createReducer from '../createReducer';
import {
  GET_ALL_ANNOUNCEMENTS,
  GET_ALL_ANNOUNCEMENTS_SUCCESS,
  GET_ALL_ANNOUNCEMENTS_FAILURE,
  GET_UNREAD_ANNOUNCEMENTS,
  GET_UNREAD_ANNOUNCEMENTS_SUCCESS,
  GET_UNREAD_ANNOUNCEMENTS_FAILURE,
  MARK_AN_ANNOUNCEMENT_AS_READ,
  MARK_AN_ANNOUNCEMENT_AS_READ_SUCCESS,
  MARK_AN_ANNOUNCEMENT_AS_READ_FAILURE,
} from './actions';

const initialState = {
  announcements: [],
  announcementsError: {
    error: false,
    message: '',
  },
  announcementsLoading: false,
  unreadAnnouncements: [],
  unreadAnnouncementsError: {
    error: false,
    message: '',
  },
  unreadAnnouncementsLoading: false,
  markedAnnouncementAsRead: {},
  markAnnouncementAsReadLoading: false,
  markAnnouncementAsReadLoadingError: {
    error: false,
    message: '',
  },
};

export default createReducer(initialState, {
  [GET_ALL_ANNOUNCEMENTS]: state => {
    state.announcementsLoading = true;
  },
  [GET_ALL_ANNOUNCEMENTS_SUCCESS]: (state, action) => {
    state.announcementsLoading = false;
    state.announcements = action.payload.data;
  },
  [GET_ALL_ANNOUNCEMENTS_FAILURE]: (state, action) => {
    state.announcementsLoading = false;
    state.announcementsError.error = true;
    state.announcementsError.message = action.payload.message;
  },
  [GET_UNREAD_ANNOUNCEMENTS]: state => {
    state.unreadAnnouncementsLoading = true;
  },
  [GET_UNREAD_ANNOUNCEMENTS_SUCCESS]: (state, action) => {
    state.unreadAnnouncementsLoading = false;
    if (isEmpty(action.payload.data)) {
      state.unreadAnnouncements = [];
    } else {
      state.unreadAnnouncements = action.payload.data.map(unreadAnn => ({
        ...unreadAnn,
        alreadyRead: false,
      }));
    }
  },
  [GET_UNREAD_ANNOUNCEMENTS_FAILURE]: (state, action) => {
    state.unreadAnnouncementsLoading = false;
    state.unreadAnnouncementsError.error = true;
    state.unreadAnnouncementsError.message = action.payload.message;
  },
  [MARK_AN_ANNOUNCEMENT_AS_READ]: state => {
    state.markAnnouncementAsReadLoading = true;
  },
  [MARK_AN_ANNOUNCEMENT_AS_READ_SUCCESS]: (state, action) => {
    state.markAnnouncementAsReadLoading = false;
    state.markedAnnouncementAsRead = action.payload.data;
    state.unreadAnnouncements = state.unreadAnnouncements.map(announcement => {
      if (announcement.id === action.payload.data.announcementID) {
        return { ...announcement, alreadyRead: true };
      }
      return announcement;
    });
  },
  [MARK_AN_ANNOUNCEMENT_AS_READ_FAILURE]: (state, action) => {
    state.markAnnouncementAsReadLoading = false;
    state.markAnnouncementAsReadLoadingError.error = true;
    state.markAnnouncementAsReadLoadingError.message = action.payload.message;
  },
});

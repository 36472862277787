import { LOCATION_CHANGE } from 'connected-react-router';
import { concat } from 'lodash';
import { initialState } from './selectors';
import {
  GET_HEDGIE_REQUEST,
  GET_HEDGIE_SUCCESS,
  GET_HEDGIE_FAILURE,
  GET_HEDGIES_REQUEST,
  GET_HEDGIES_SUCCESS,
  GET_HEDGIES_FAILURE,
  GET_HEDGIES_RESET,
  BOOKING_HEDGIE_REQUEST,
  BOOKING_HEDGIE_SUCCESS,
  BOOKING_HEDGIE_FAILURE,

  ORDER_HEDGIE_REQUEST,
  ORDER_HEDGIE_SUCCESS,

  GIFT_HEDGIE_REQUEST,
  GIFT_HEDGIE_SUCCESS,
  GIFT_HEDGIE_FAILURE,
  PURCHASE_HEDGIE_REQUEST,
  PURCHASE_HEDGIE_SUCCESS,
  PURCHASE_HEDGIE_FAILURE,
  PURCHASE_HEDGIE_WITH_ETHER_REQUEST,
  PURCHASE_HEDGIE_WITH_ETHER_SUCCESS,
  PURCHASE_HEDGIE_WITH_ETHER_FAILURE,
  REDEEM_GIFTCODE_HEDGIE_REQUEST,
  REDEEM_GIFTCODE_HEDGIE_SUCCESS,
  REDEEM_GIFTCODE_HEDGIE_FAILURE,
  GET_MY_HEDGIES_REQUEST,
  GET_MY_HEDGIES_SUCCESS,
  GET_MY_HEDGIES_FAILURE,
  SYNC_MY_HEDGIES_REQUEST,
  SYNC_MY_HEDGIES_SUCCESS,
  SYNC_MY_HEDGIES_FAILURE,
} from './actions';
import { UPGRADE_HEDGIE_SUCCESS } from '../level/actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        error: null,
        loading: false,
        loadingPremium: false,
        errorGettingHedgies: null,
        errorGettingHedgie: null,
        errorBookingHedgie: null,
        giftingError: null,
        errorRedeemingGiftCode: null,
        errorGetMyHedgies: null,
        errorPurchasingWithEther: null,
        giftingSuccess: false,
        purchaseSuccess: false,
        purchaseWithEtherSuccess: false,
        errorPurchasingHedgie: null,
        redeemSuccess: false,
        myHedgiesSuccess: undefined,
        // myHedgies: [],
        // syncHedgiesSuccess: false,
        errorSyncingHedgies: null,
        // syncHedgiesLoading: false,
        working: false,
      };
    }
    case GET_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HEDGIE_SUCCESS: {
      const { hedgie, tier } = payload.data;
      return {
        ...state,
        hedgie,
        tier,
        loading: false,
        errorGettingHedgie: null,
        bookingSuccess: false,
      };
    }
    case GET_HEDGIE_FAILURE:
      return {
        ...state,
        hedgie: null,
        tier: null,
        errorGettingHedgie: {
          error: true,
          message: payload.message,
        },
        loading: false,
      };
    case ORDER_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_HEDGIE_SUCCESS:
        return {
          ...state,
          orderSuccess: payload.data.ok,
          loading: false,
          errorOrderHedgie: null,
        };
    case BOOKING_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case BOOKING_HEDGIE_SUCCESS:
      return {
        ...state,
        bookingSuccess: payload.data.ok,
        loading: false,
        errorBookingHedgie: null,
      };
    case BOOKING_HEDGIE_FAILURE:
      return {
        ...state,
        bookingSuccess: false,
        errorBookingHedgie: payload,
        loading: false,
      };
    case GET_HEDGIES_REQUEST:
      return {
        ...state,
        loadingPremium: true,
      };
    case GET_HEDGIES_SUCCESS:
      return {
        ...state,
        hedgies: [...state.hedgies, ...payload.data],
        hedgiesPagination: payload.meta.pagination,
        loadingPremium: false,
        errorGettingHedgies: null,
      };
    case GET_HEDGIES_FAILURE:
      return {
        ...state,
        hedgies: [],
        hedgiesPagination: initialState.hedgiesPagination,
        errorGettingHedgies: payload,
        loadingPremium: false,
      };
    case GET_HEDGIES_RESET:
      return {
        ...state,
        hedgies: [],
        hedgiesPagination: initialState.hedgiesPagination,
      };
    case GIFT_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GIFT_HEDGIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        giftingSuccess: true,
        giftingError: null,
        transactionInfo: { id: payload.data.id, amount: payload.data.total },
      };
    }
    case GIFT_HEDGIE_FAILURE:
      return {
        ...state,
        loading: false,
        giftingError: payload,
        giftingSuccess: false,
      };
    case PURCHASE_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PURCHASE_HEDGIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        purchaseSuccess: true,
        errorPurchasingHedgie: null,
        transactionInfo: { id: payload.data.id, amount: payload.data.total },
      };
    }
    case PURCHASE_HEDGIE_FAILURE:
      return {
        ...state,
        loading: false,
        errorPurchasingHedgie: payload,
        purchaseSuccess: false,
      };

    case PURCHASE_HEDGIE_WITH_ETHER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PURCHASE_HEDGIE_WITH_ETHER_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseWithEtherSuccess: true,
        errorPurchasingWithEther: null,
        transactionInfo: { id: payload.data.id, amount: payload.data.total },
      };

    case PURCHASE_HEDGIE_WITH_ETHER_FAILURE:
      return {
        ...state,
        loading: false,
        errorPurchasingWithEther: payload,
        purchaseWithEtherSuccess: false,
      };

    case REDEEM_GIFTCODE_HEDGIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REDEEM_GIFTCODE_HEDGIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        redeemSuccess: true,
        errorRedeemingGiftCode: null,
      };
    }
    case REDEEM_GIFTCODE_HEDGIE_FAILURE:
      return {
        ...state,
        loading: false,
        errorRedeemingGiftCode: payload,
        redeemSuccess: false,
      };
    case GET_MY_HEDGIES_REQUEST:
      return {
        ...state,
        loading: true,
        myHedgiesSuccess: undefined,
      };
    case GET_MY_HEDGIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        myHedgies: payload.isLoadMore
          ? concat(state.myHedgies, payload.data)
          : payload.data,
        hedgie_ids_on_journey: payload.meta.hedgie_ids_on_journey
          ? [
              ...new Set([
                ...state.hedgie_ids_on_journey,
                ...payload.meta.hedgie_ids_on_journey,
              ]),
            ]
          : [],
        myHedgiesSuccess: true,
        errorGetMyHedgies: null,
      };
    }
    case UPGRADE_HEDGIE_SUCCESS:
      return {
        ...state,
        myHedgies: state.myHedgies.map(hedgie => {
          if (hedgie.id === payload.data.id) {
            return { ...payload.data };
          }
          return hedgie;
        }),
      };
    case GET_MY_HEDGIES_FAILURE:
      return {
        ...state,
        loading: false,
        myHedgies: initialState.myHedgies,
        errorGetMyHedgies: payload,
        myHedgiesSuccess: false,
      };
    case SYNC_MY_HEDGIES_REQUEST:
      return {
        ...state,
        walletAddress: payload.wallet,
        syncHedgiesLoading: true,
        syncHedgiesSuccess: false,
        errorSyncingHedgies: null,
      };
    case SYNC_MY_HEDGIES_SUCCESS:
      return {
        ...state,
        syncHedgiesLoading: false,
        syncHedgiesSuccess: true,
        walletTyle: payload.type,
        errorSyncingHedgies: null,
      };
    case SYNC_MY_HEDGIES_FAILURE:
      return {
        ...state,
        walletTyle: payload.type,
        syncHedgiesLoading: false,
        errorSyncingHedgies: payload,
        syncHedgiesSuccess: false,
      };
    default:
      return state;
  }
};

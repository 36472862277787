import React, { useCallback, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle, Button, FormError } from 'components';
import { getAllProducts } from '../../../store/business/actions';
import MarketplaceProductsList from './MarketplaceProductsList';

const StyledContainer = styled.div`
  max-width: 932px;
  margin: 0 auto;
`;

const MarketplacePage = () => {
  const dispatch = useDispatch();

  const pagination = useSelector(state => state.business.allProductsPagination);
  const { limit, offset, total } = pagination;

  useEffect(() => {
    dispatch(getAllProducts({ offset: 0, limit, isLoadMore: false }));
  }, [dispatch, limit]);

  const products = useSelector(state => state.business.allProducts);
  const getProductsError = useSelector(
    state => state.business.allProductsError,
  );

  const canLoadMore = useMemo(() => offset + limit < total, [
    limit,
    offset,
    total,
  ]);

  const loadMore = useCallback(() => {
    dispatch(
      getAllProducts({ offset: limit + offset, limit, isLoadMore: true }),
    );
  }, [dispatch, limit, offset]);

  return (
    <StyledContainer>
      <PageTitle title="Marketplace" />
      <div>
        <MarketplaceProductsList products={products} />
      </div>
      {canLoadMore ? (
        <div className="align-center pt-3 pb-5">
          <Button onClick={loadMore}>Load more</Button>
        </div>
      ) : null}
      {getProductsError.error ? <FormError error={getProductsError} /> : null}
    </StyledContainer>
  );
};

// MarketplacePage.propTypes = {};

export default MarketplacePage;

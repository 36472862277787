import React from 'react';
import { Col, Row } from 'reactstrap';
import { HedgieImg, CurioPrice, PageTitle, Button } from 'components';
import {pageNames, useBattle} from './battle-context';
import styled from 'styled-components';
import useMedia from '../../custom-hooks/useMedia';
import { useWebsocket } from '../../../contexts/websocket-context';

const StyledWinnerContainer = styled.div`
  .hedgie-owner {
    position: relative;
  }
`;

const StyleWinnerInfo = styled.div`
  position: absolute;
  right: 0;
  top: 35%;
`;

const Winner = () => {
  const {
    hedgieOwner,
    opponent,
    battleInfo,
    onLeave,
    setPage,
    isMobile,
  } = useBattle();
  const { sendEvent } = useWebsocket();
  const hedgieImgWidth = useMedia(
    ['(min-width: 992px)', '(max-width: 768px)'],
    [360, 220],
    360,
  );

  function fightAgain() {
    sendEvent('cancelled_challenge');
    setPage(pageNames.BATTLE_HOME);
  }

  return (
    hedgieOwner &&
    opponent && (
      <StyledWinnerContainer>
        <PageTitle title="Arena" />
        <Row gutter={20}>
          <Col
            md={6}
            lg={6}
            sm={6}
            xs={6}
            className={`hedgie-owner align-start`}
            key={hedgieOwner.hedgie.id}
          >
            <div className="text-center">
              <HedgieImg
                color={`#${hedgieOwner.hedgie.color}`}
                imgURL={hedgieOwner.hedgie? hedgieOwner.hedgie.imgURL: null}
                className="img-fluid"
                width={
                  hedgieOwner.isWinner ? hedgieImgWidth : isMobile ? 100 : 180
                }
              />
              {hedgieOwner.isWinner && !isMobile && (
                <StyleWinnerInfo>
                  <p className="font-weight-bold">You win: </p>
                  <p>Exp: {battleInfo.exp_for_winner}</p>
                  <CurioPrice curioPrice={`${battleInfo.curio_for_winner}`} />
                </StyleWinnerInfo>
              )}
              <div
                className={`${hedgieOwner.isWinner ? 'font-weight-bold' : ''}`}
              >
                {hedgieOwner.isWinner ? (
                  <span className="blink_me">Winner!</span>
                ) : (
                  <span>Better luck next time!</span>
                )}
              </div>
              <div className="font-weight-bold">
                {hedgieOwner.hedgie.ownerName
                  ? hedgieOwner.hedgie.ownerName
                  : 'Anonymous'}
              </div>
              {!hedgieOwner.isWinner ? (
                <p>Exp: {battleInfo.exp_for_winner}</p>
              ) : (
                <div>#{hedgieOwner.hedgie.color}</div>
              )}
            </div>
          </Col>
          <Col
            md={4}
            lg={4}
            sm={isMobile ? 6 : 4}
            xs={isMobile ? 6 : 4}
            className={`${opponent.isWinner ? 'align-start' : 'align-center'}`}
            key={opponent.hedgie.id}
          >
            <div className="text-center">
              <HedgieImg
                color={`#${opponent.hedgie.color}`}
                imgURL={opponent.hedgie? opponent.hedgie.imgURL: null}
                className="img-fluid"
                width={
                  opponent.isWinner ? hedgieImgWidth : isMobile ? 100 : 180
                }
              />
              {opponent.isWinner && (
                <React.Fragment>
                  <span className="blink_me">Winner!</span>
                  <div className="font-weight-bold">
                    {opponent.hedgie.ownerName
                      ? opponent.hedgie.ownerName
                      : 'Anonymous'}
                  </div>
                  <div>#{opponent.hedgie.color}</div>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
        {hedgieOwner.isWinner && isMobile && (
          <div className="d-flex justify-content-center pb-4">
            <div>
              <p className="font-weight-bold">You win: </p>
              <p>Exp: {battleInfo.exp_for_winner}</p>
              <CurioPrice curioPrice={`${battleInfo.curio_for_winner}`} />
            </div>
          </div>
        )}
        <div className="text-center pb-5">
          <Button onClick={onLeave}>Leave</Button>
          <Button onClick={fightAgain}>Battle again!</Button>
        </div>
      </StyledWinnerContainer>
    )
  );
};

export default Winner;

//import React, { useCallback } from 'react';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import styled from 'styled-components';
import { Button } from 'components';
import Log from 'services/log';
import { paymentAddress } from 'config/env';
import MetaMaskUtil from 'services/metamaskutil';
import api from '../../../services/api/index'

import MetaMask from '../MetaMask';
import { useDispatch } from "react-redux";
import {purchaseWithEtherRequest} from 'store/actions';
import Spinner from '../Spinner';
import { MyHedgiesContext } from '../../pages/NewMyHedgiesPage/MyHedgiesWrapper';
import useMetamaskNetWork from '../../../hooks/useMetamaskNetWork';
import { NET_WORK, netWorkMapper } from '../../../const/metamask';



const PurchaseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: -5px;
  //margin-left: 30px;
`;

const HedgiePurchaseWithPriceButtonNew = ({
  className,
  hedgie,
  onPurchaseHedgie,
  color,
  onCompleteEtherBuyNow,
}) => {
  const {isMinting, setIsMinting, fetchHedgieTimer} = useContext(MyHedgiesContext);
  const dispatch = useDispatch();

  const {isMainnet} = useMetamaskNetWork();

  const resolvePrice = async(hedgie) => {

    const apiInstance = api.create();
    const res = await apiInstance.get(`/hedgies/order/${hedgie.id}`);
      hedgie = res.data.hedgie;
      Log.info(`Resolving price for ${hedgie}`);
        let etherPrice = (hedgie?.etherPrice>0)?(hedgie.etherPrice/1e18).toFixed(3):0;
        let estimatedCost = (hedgie.gasFeeWei/1e18).toFixed(3);
        let estimatedTotal = (Number(etherPrice)+Number(estimatedCost)).toFixed(3)
      
            return {
              weiCost: Number(hedgie.etherPrice)+Number(hedgie.gasFeeWei),
              etherPrice: etherPrice,
              estimatedCost: estimatedCost,
              estimatedTotal: estimatedTotal
            };     
  }
  
  const handleSubmitEtherBuyNow = (submitParams = {}) => {
    const params = {
      ...submitParams,
      hedgieIds: [hedgie.id],
    };
    dispatch(purchaseWithEtherRequest(params));
  };
  
  const handleSubmit = async () => {
    //e.preventDefault();
    if(!isMainnet){
      const network = netWorkMapper[NET_WORK].name;
      cogoToast.error(<span>Please switch Metamask to <strong>{network}</strong></span>);
      return;
    }
    setIsMinting(true);
    var wallet="";
    const state = await resolvePrice(hedgie);
    Log.info(`Submitting metamask purchase for amount: ${state.weiCost}`);
    MetaMaskUtil.getAccount()
    .then(account => {
      wallet=account;
      Log.info(`Submitting metamask purchase for account address: ${account} ${paymentAddress} ${state.weiCost}`);
      console.log(`>> Submitting metamask purchase for account address: ${account} ${paymentAddress} ${state.weiCost}`);
      return MetaMaskUtil.makePurchase(account, paymentAddress, state.weiCost);
    }).then(txHash => {
      Log.info(`Got transaction hash, txHash: ${JSON.stringify(txHash)}`);
      console.log(`-->> Got transaction hash, txHash: ${JSON.stringify(txHash)}`);
      handleSubmitEtherBuyNow({
        paymentMethod: 'ether',
        payment_transaction: txHash,
        hedgie_id:hedgie.id,
        wallet:wallet,
      });
      setIsMinting(false);
      window.location = "/my-hedgies#"+hedgie.color;
      fetchHedgieTimer(hedgie.color);

    }).catch(err => {
      setIsMinting(false);
      Log.error(`Purchase action has error: ${JSON.stringify(err)}`);
      console.log(`Purchase action has error:`,err);
    });
  }

  var eth = 0;
  var price = 0;
  if (hedgie) {
    eth = hedgie.etherPrice / 1e18;
    price = (hedgie.dollarPrice + hedgie.gasPrice) / 100;
  }

  return (
    <MetaMask>
      <StyledETHPrice>{eth}ETH+gas (${price})</StyledETHPrice>
      <PurchaseContainer className={className}>
        <StyledButton size="large" type="submit" onClick={handleSubmit} disabled={isMinting}>
          {isMinting && <Spinner noFixed/>}
          <StyledText>{isMinting ? 'Minting...' : 'Mint this Hedgie' }</StyledText>
        </StyledButton>
      </PurchaseContainer>
    </MetaMask>
  );
};

const StyledETHPrice =styled.div`
  padding-top: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
margin-top: 0!important;
`
const StyledText = styled.span`
  padding-left: 10px;
  display: inline-block;
`

HedgiePurchaseWithPriceButtonNew.propTypes = {
  className: PropTypes.string,
  hedgie: PropTypes.object.isRequired,
  onPurchaseHedgie: PropTypes.func.isRequired,
  color: PropTypes.string,
};

HedgiePurchaseWithPriceButtonNew.defaultProps = {
  color: '',
  className: '',
};

export default HedgiePurchaseWithPriceButtonNew;

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ImageCircle, CurioPrice } from 'components';
import { Link } from 'react-router-dom';
import Typography from '../../stateless/Typography';
import { BusinessContext } from './index';

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledLink = styled(StyledContent)``;

function checkPriceEnough(userCurioBalance, productPrice) {
  return userCurioBalance - productPrice >= 0;
}

const MESSAGE = 'You don\'t have enough Curio';

const ProductItem = ({ product }) => {
  const { curioBalance } = useContext(BusinessContext);

  const hasEnoughCurio = useMemo(
    () => checkPriceEnough(curioBalance, product.curioPrice),
    [curioBalance, product],
  );

  const renderProduct = (
    <StyledLink>
      <ImageCircle src={product.imgURL} disabled={!hasEnoughCurio} />
      <Typography className="pt-2" text={product.name} isBold />
    </StyledLink>
  );

  return (
    <StyledContent>
      {hasEnoughCurio ? (
        <Link to={`/product/${product.id}`}>{renderProduct}</Link>
      ) : (
        renderProduct
      )}
      <Typography className="pt-2" text={product.description} fontSize={16} />
      {hasEnoughCurio ? (
        <CurioPrice className="pt-2" curioPrice={product.curioPrice} />
      ) : (
        <Typography
          text={MESSAGE}
          fontSize={14}
          color="#F39331"
          className="pt-3"
        />
      )}
    </StyledContent>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default React.memo(ProductItem);

import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const DividerBlock = styled.div`
    width: 100px;
    height: 6px;
    margin: auto;
    background-color: ${palette(1)};
`;

DividerBlock.defaultProps = {
    palette: 'primary',
  };

const Divider = ({ ...props }) => {
  return (
    <Container fluid {...props} className={`text-center ${props.className}`}>
      <DividerBlock />
    </Container>);
};

export default Divider;

import Web3 from 'web3';

const SQUARELINK_ID = process.env.REACT_APP_SQUARELINK_ID;
const NETWORK = process.env.REACT_APP_SQUARELINK_NETWORK;

export function syncApp() {
  const sqlk = new window.Squarelink(SQUARELINK_ID, NETWORK, {
    useSync: true,
  });

  const provider = sqlk.getProviderSync();
  let address = null;
  const web3 = new Web3(provider);

  return new Promise((resolve, reject) => {
    web3.eth.currentProvider.enable().then(value => {
      web3.eth.getAccounts().then(accounts => {
        address = accounts[0];
        web3.eth.personal
          .sign('HedgieForTheWin', address)
          .then(signature =>
            resolve({
              address,
              signature,
            }),
          )
          .catch(err => {
            reject(err);
          });
      });
    });
  });
}

import createReducer from '../createReducer';
import {
  CONSUME_ITEM,
  CONSUME_ITEM_SUCCESS,
  CONSUME_ITEM_FAILURE,
  RESET_CONSUMABLE_ITEM,
} from './actionTypes';

const initialState = {
  consumableItem: {
    isSold: false,
    successMessage: '',
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
};

export default createReducer(initialState, {
  [CONSUME_ITEM]: state => {
    state.consumableItem.loading = true;
    state.consumableItem.error.error = false;
  },
  [CONSUME_ITEM_SUCCESS]: (state, action) => {
    state.consumableItem.loading = false;
    state.consumableItem.isSold = true;
    state.consumableItem.successMessage =
      'This item has been consumed successfully!';
  },
  [CONSUME_ITEM_FAILURE]: (state, action) => {
    state.consumableItem.loading = false;
    state.consumableItem.error.error = true;
    state.consumableItem.error.message = action.payload.message;
  },
  [RESET_CONSUMABLE_ITEM]: state => {
    state.consumableItem = initialState.consumableItem;
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBaseButton = styled.button`
  background: #62295e;
  font-weight: normal;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  width: ${props => (props.full ? '100%' : null)};
  &:disabled {
    background-color: gray;
    border: none;
  }
  &:hover:not(:disabled) {
    background-color: #12dfc0;
  }
  &:focus {
    outline: none;
  }
`;

const HedgieButton = ({ ...props }) => {
  return <StyledBaseButton {...props}>{props.children}</StyledBaseButton>;
};

HedgieButton.propTypes = {
  className: PropTypes.string,
  full: PropTypes.bool,
};

HedgieButton.defaultProps = {
  className: '',
  full: false,
};

export default HedgieButton;

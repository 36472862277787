import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  PresaleHeader,
  PresaleTopSlideRu,
  PresalePremiumSlideRu,
  // PresalePickerSlide,
  PageTitle,
  SubHeading,
  SubHeadingBold,
  BoldSpan,
  Mobile,
  Desktop,
  Divider,
  Button,
  HedgiePowerRu,
  Icon,
} from 'components';
import * as types from 'types';
import * as paths from 'config/paths';
import ReserveYourHedgieImage from 'images/reserve-your-hedgie.png';
import BuyYourHedgieImage from 'images/buy-your-hedgie.png';
import PlayTheGameImage from 'images/play-the-game.png';
import Edibles from 'images/edibles.png';
import CoinStackImage from 'images/coin-stack.png';
import KingdomIcon from 'images/kingdom-icon.png';
import DurationIcon from 'images/timer-icon.png';
import GoalsIcon from 'images/star-icon.png';
import ToolsUpgradeImage from 'images/tools-upgrade.png';
import GearUpgradeImage from 'images/gear-upgrade.png';
import OrangeMushroom from 'images/orange-mushroom.png';
import OysterMushroom from 'images/oyster-mushroom.png';
import QuestionMark from 'images/question-mark.png';
import BuyItemsImage from 'images/buy-items.png';
import SellOnExchangesImage from 'images/sell-on-exchanges.png';
import SpendInWorldImage from 'images/spend-in-world.png';
import PartOfRevolutionImage from 'images/part-of-revolution.png';

// const DEFAULT_COLOR = '#229bd4';

const CollectionItem = styled.div`
  width: 20%;
  background-color: #eff3f4;
  border-radius: 10px;
  display: inline-block;
  max-width: 60px;
  margin: 2px;
`;

const CollectionImg = styled.img`
  max-height: 60px
  display: block;
  margin: auto;
  padding: 10px;
`;

const CollectionRow = styled.div`
  min-width: 320px;
  height: 64px;
`;

const ActionImage = ({ className, src, alt }) => (
  <img src={src} className={`${className} rounded-circle`} alt={alt} />
);

const StyledActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`;

const StyledActionImageLarge = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
`;

const StyledBusinessActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  margin: auto;
  margin-bottom: 20px;
`;

const UpgradeBlockContainer = styled.div`
  max-width: 320px;
`;

const CenteredColumn = styled(Col)`
  margin: auto;
`;

const LootImg = styled.img`

`;

const LootImgColumn = styled(CenteredColumn)`
  text-align: center;
`;

const SetHeightRow = styled(Row)`
  min-height: ${props => props.height};
`;

const Upgrades = [
  {
    id: 1,
    name: 'Инструменты',
    description:
      'Инструменты включают в себя такие предметы, как карта, корзина и лопата, и предназначены для того, чтобы усилить характеристики твоего Хеджи. Например, сократить время путешествия, повысить шансы найти добычу и избежать потери добычи по пути домой. Как и любая хорошая вещь, инструменты не могут быть вечными. Со временем они могут теряться или ломаться!',
    image: ToolsUpgradeImage,
  },
  {
    id: 2,
    name: 'Экипировка',
    description:
      'Экипировка включает такие предметы как сапоги, пальто, перчатки, шарфы и головные уборы, предназначенные для постоянной прокачки навыков твоего Хеджи. Приобретая экипировку, которая соответствует эксклюзивному HEX цвету твоего Hedgie, ты получаешь более мощную прокачку. Собери весь комплект снаряжения в родном цвете Хеджи и испытай мощнейщий непрерывный буст! В отличие от инструментов, снаряжение не будет теряться или ломаться.',
    image: GearUpgradeImage,
  },
];

const UpgradeBlock = ({ upgrade, className }) => {
  return (
    <UpgradeBlockContainer
      className={`tier-block d-flex text-center justify-content-center flex-column ${className}`}
    >
      <StyledBusinessActionImage
        src={upgrade.image}
        style={{ width: '170px', margin: 'auto' }}
        alt=""
      />
      <SubHeading heading={upgrade.name} />
      <p className="text-left">{upgrade.description}</p>
    </UpgradeBlockContainer>
  );
};

UpgradeBlock.propTypes = {
  upgrade: PropTypes.object.isRequired,
  className: PropTypes.string,
};

UpgradeBlock.defaultProps = {
  className: null,
};

const subHeading = () => {
  return (
    <React.Fragment>
      <BoldSpan>Хеджи</BoldSpan> - игровая стратегия в режиме реального времени.
      Отправляйся с Хеджи в путешествие по четырем мирам, находи спрятанные
      сокровища и прокачивай производительность своего Хеджи чтобы заработать
      больше крипто монет!
    </React.Fragment>
  );
};

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const NumberedHeaderNumber = styled.div`
  width: 32px;
  height: 32px;
  font-size: 1rem;
  line-height: 32px;
  text-align: center;
  margin-right: 10px;
`;

const NumberedHeaderText = styled.div`
  width: auto;
  height: 32px;
  font-size: 20px;
  text-align: center;
`;

const NumberedHeader = ({ num, text, color }) => {
  const style = {};
  if (color) {
    style.color = color;
  }
  return (
    <FlexRowContainer className="number-header color-dark-contrast">
      <NumberedHeaderNumber className="number-container green-light-bgr ff-extra-bold">
        {num}
      </NumberedHeaderNumber>
      <NumberedHeaderText className="ff-semi-bold" style={style}>
        {text}
      </NumberedHeaderText>
    </FlexRowContainer>
  );
};

NumberedHeader.propTypes = {
  num: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

NumberedHeader.defaultProps = {
  color: null,
};

const IconTitleImage = styled.img`
  height: 2rem;
  padding-right: .5rem;
`;

const IconTitle = ({ img, text }) => {
  return (
    <FlexRowContainer className="icon-title pb-4 pt-2">
      <IconTitleImage className="icon-title-image" src={img} alt="" />
      <NumberedHeaderText
        style={{ fontSize: '1.1rem', lineHeight: '2rem' }}
        className="ff-medium color-contrast"
      >
        {text}
      </NumberedHeaderText>
    </FlexRowContainer>
  );
};

IconTitle.propTypes = {
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const WORLDS = [];
WORLDS['1'] = {
  id: 1,
  name: 'The Forest Kingdom',
  dominantPower: 'earth',
  iconName: 'powerEarth',
};

WORLDS['2'] = {
  id: 2,
  name: 'The Wetlands',
  dominantPower: 'water',
  iconName: 'powerWater',
};

WORLDS['3'] = {
  id: 3,
  name: 'The Mountains Kingdom',
  dominantPower: 'air',
  iconName: 'powerAir',
};

WORLDS['4'] = {
  id: 4,
  name: 'The Desert Kingdom',
  dominantPower: 'fire',
  iconName: 'powerFire',
};

const WorldBlockContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const WorldBlockMobileContainer = styled.div`
  width: 100%;
  height: 100px;
`;

const WorldBlockFullContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const WorldBlock = ({ worldId, handleOpen, hideButton }) => {
  return (
    <WorldBlockContainer className={`world-block world-${worldId}`}>
      <button
        className={classnames([
          'world-block-button open-button',
          { hideButton },
        ])}
        onClick={e => {
          e.preventDefault();
          handleOpen(worldId);
        }}
      >
        +
      </button>
    </WorldBlockContainer>
  );
};

WorldBlock.propTypes = {
  worldId: PropTypes.string.isRequired,
  handleOpen: PropTypes.func,
  hideButton: PropTypes.bool,
};

WorldBlock.defaultProps = {
  hideButton: false,
  handleOpen: () => {},
};

const WorldBlockMobile = ({ worldId, handleOpen }) => {
  return (
    <WorldBlockMobileContainer
      className={`world-block-mobile world-${worldId}`}
      onClick={e => {
        e.preventDefault();
        handleOpen(worldId);
      }}
    />
  );
};

WorldBlockMobile.propTypes = {
  worldId: PropTypes.string.isRequired,
  handleOpen: PropTypes.func,
};

WorldBlockMobile.defaultProps = {
  handleOpen: () => {},
};

const WorldBlockFull = ({ world, worldId, handleClose }) => {
  return (
    <WorldBlockFullContainer
      className={`full-world-block full-world-${worldId}`}
    >
      <div className="d-flex align-items-center flex-column p-4 full-world-details">
        <h2 className="world-name ff-extra-bold">{world.name}</h2>
        <div className="world-details ff-semi-bold">
          Dominant Power{' '}
          <Icon
            name={world.iconName}
            style={{ display: 'inline-block', marginLeft: '10px' }}
          />
        </div>
      </div>
      <div className={classnames(['world-block-section'])}>
        <Desktop>
          <button
            className="button-text"
            onClick={e => {
              e.preventDefault();
              handleClose(worldId);
            }}
          >
            Close
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              handleClose(worldId);
            }}
            className={classnames([
              'world-full-close-button world-block-button',
            ])}
          >
            -
          </button>
        </Desktop>
        <Mobile>
          <button
            className={classnames([
              'world-full-close-button world-block-button',
            ])}
            onClick={e => {
              e.preventDefault();
              handleClose(worldId);
            }}
          >
            -
          </button>
        </Mobile>
      </div>
    </WorldBlockFullContainer>
  );
};

WorldBlockFull.propTypes = {
  worldId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  world: PropTypes.object.isRequired,
};

class RussianLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: '1',
      expandedWorld: null,
    };
    this.handlePurchase = this.handlePurchase.bind(this);
  }

  componentDidMount() {
    // Puting this work around here to account
    // for that case where the user clicks 'Get Hedgie' on the home page
    // We need to detect route change and scroll manually. Unless other ideas?
    this.unlisten = this.props.history.listen((location, action) => {
      // Decode entities in the URL
      // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
      window.location.hash = window.decodeURIComponent(window.location.hash);
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 1) {
        const hash = hashParts.slice(-1)[0];
        const element = document.querySelector(`#${hash}`);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handlePurchase = hedgie => {
    this.props.history.push(`${paths.BOOKING_HEDGIE}/${hedgie.color}`);
  };

  handleModal = e => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleOpen = worldId => {
    if (this.state.expandedWorld !== worldId) {
      this.setState({
        expandedWorld: worldId,
      });
    }
  };

  handleClose = () => {
    if (this.state.expandedWorld !== null) {
      this.setState({
        expandedWorld: null,
      });
    }
  };

  render() {
    const {
      errorGettingHedgies,
      hedgies,
      loadingPremium,
      user,
      authenticated,
    } = this.props;
    const rowClass = 'py-5';

    let expandedWorldId;
    if (this.state.expandedWorld !== null) {
      expandedWorldId = this.state.expandedWorld;
    }

    return (
      <div className={classnames(['tabbed-container', 'home-page'])}>
        <PresaleHeader authenticated={authenticated} {...user} anchor />
        <main>
          <PresaleTopSlideRu />
          <Container fluid>
            <Container>
              <Row className="mt-5">
                <Col md="3" sm="4">
                  <div
                    style={{ maxWidth: '150px' }}
                    className="text-center m-auto"
                  >
                    <StyledActionImageLarge
                      src={PartOfRevolutionImage}
                      alt="Прими участие в крипто революции"
                    />
                    <div className="ff-semi-bold pt-3">
                      <SubHeading heading="Прими участие в крипто революции" />
                    </div>
                  </div>
                </Col>
                <Col md="7" sm="8">
                  <SubHeadingBold
                    heading="Почему Хеджи?"
                    className="mt-4 text-center text-sm-left"
                  />
                  <p className="ff-semi-bold">
                    Мы рады, что Вы спросили! Хеджи - это ваш самый простой и
                    легкий способ познакомится с новым и не многим понятным
                    миром крипто в игровой форме и стать активным участником в
                    продвижении революционной технологии.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-center">
                  <PageTitle title="Игра" />
                  <SubHeading
                    render={subHeading}
                    className="sub-heading-center"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageTitle title="Как это работает" />
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col md="12">
                  <Row>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage
                          src={ReserveYourHedgieImage}
                          alt=""
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="1. Выбери своего Хеджи - предпродажа со скидкой 50%" />
                        </div>
                        <p className="text-left text-md-center">
                          В отличие от других блокчейн игр, в которых для старта
                          требуется криптовалюта, Хеджи также можно оплатить
                          кредитной картой.
                        </p>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage src={PlayTheGameImage} alt="" />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="2. Играй в игру Хеджи - выйдет в начале 2019-го" />
                        </div>
                        <p className="text-left text-md-center">
                          Исследуй четыре уникальных мира со своим Хеджи, чтобы
                          заработать крипто монету! Ищи на своем пути артефакты,
                          которые твой Хеджи может использовать, чтобы стать
                          успешнее. Каждый герой уникален и только опыт покажет
                          тебе правильный путь. Процесс игры бесплатный, не
                          ограничивай себя. Бета-версия ожидается в начале
                          2019-го года.
                        </p>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage src={BuyYourHedgieImage} alt="" />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="3. Трать монеты - лето 2019" />
                        </div>
                        <p className="text-left text-md-center">
                          Крипто монеты которые ты заработаешь в игре, можно
                          потратить в реальном мире! За игровые крипто монеты
                          можно покупать вещи в локальных магазинах и
                          онлайн-бизнесах, а также оплачивать услуги, которые
                          предоставляются партнерами Хеджи. Доступно уже летом
                          2019 года!
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="grey-light-bgr pt-5 pb-5">
            <Container>
              <Row>
                <Col md="12">
                  <NumberedHeader num={1} text="Выбери своего Хеджи" />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <HedgiePowerRu performsView showTitle={false} />
                </Col>
                <Col md="6" style={{ marginTop: '50px' }}>
                  <p>
                    Сначала выбери собственного Хеджи! После этого можешь просто
                    наслаждаться обладанием Хеджи или играть и зарабатывать
                    крипто монеты. В игре нет двух одинаковых Хеджи - каждый из
                    них соответствует одному из существующих 16 777 216 HEX
                    цветов и обладает уникальными характеристиками, основанными
                    на четырех элементов силы природы и четырех персональных
                    качеств. Эти силы и качества определяют производительность
                    твоего Хеджи в игре.
                  </p>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="purple-light-bgr pt-5 pb-5 pos-rel">
            <Container className="pos-rel">
              <Row className="purple-light-bgr pb-5">
                <Col md="12">
                  <NumberedHeader
                    num={2}
                    text="Планируй Путешествие"
                    color="#ffffff"
                  />
                </Col>
              </Row>
              <Row className="purple-light-bgr pt-4">
                <Col md="6">
                  <Row className="pb-5 pr-md-5 mr-md-5">
                    <Col>
                      <IconTitle img={KingdomIcon} text="Выбери королевство" />
                      <p className="journey-description">
                        Где твой Хеджи будет искать для тебя артефакты? На выбор
                        есть четыре мира: пустыня, водно-болотные угодья, лес и
                        горы. Не знаешь, с чего начать? Мы рекомендуем мир,
                        который соответствует силе твоего Хеджи (там он будет
                        работать лучше всего!).
                      </p>
                    </Col>
                  </Row>
                  <Mobile>
                    {!expandedWorldId && (
                      <Row className="mobile-world pb-5">
                        <Col xs="3">
                          <WorldBlockMobile
                            worldId="1"
                            name="World 1"
                            handleOpen={this.handleOpen}
                          />
                        </Col>
                        <Col xs="3">
                          <WorldBlockMobile
                            worldId="2"
                            name="World 2"
                            handleOpen={this.handleOpen}
                          />
                        </Col>
                        <Col xs="3">
                          <WorldBlockMobile
                            worldId="3"
                            name="World 3"
                            handleOpen={this.handleOpen}
                          />
                        </Col>
                        <Col xs="3">
                          <WorldBlockMobile
                            worldId="4"
                            name="World 4"
                            handleOpen={this.handleOpen}
                          />
                        </Col>
                      </Row>
                    )}
                    {expandedWorldId && (
                      <div className="mobile-full-world">
                        <WorldBlockFull
                          worldId={expandedWorldId}
                          world={WORLDS[expandedWorldId]}
                          handleClose={this.handleClose}
                        />
                      </div>
                    )}
                  </Mobile>
                  <Row className="pb-5 pr-md-5 mr-md-5">
                    <Col>
                      <IconTitle
                        img={DurationIcon}
                        text="Выбери продолжительность"
                      />
                      <p className="journey-description">
                        Длительность путешествия варьируется от одной минуты до
                        двадцати четырех часов. Чем дольше путешествие тем
                        больше шанса что Хеджи вернется не с пустыми руками.
                      </p>
                    </Col>
                  </Row>
                  <Row className="pb-5 pr-md-5 mr-md-5">
                    <Col>
                      <IconTitle img={GoalsIcon} text="Задай цель" />
                      <p className="journey-description">
                        Что нужно искать? Миссия твоего Хеджи будет определяться
                        тем, какой артефакт ты хочешь или в каком нуждаешься, а
                        можно просто на удачу. Разные миры предлагают различные
                        виды артефактов. Кстати, если ты здесь просто за крипто
                        монетами, ты можешь задать поиск Кюрио как более
                        приоритетный над другими видами находок.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Desktop>
              {!expandedWorldId && (
                <Row className="full-width-height p-0 m-0">
                  <Col md="6">&nbsp;</Col>
                  <Col md="6" className="p-0 m-0">
                    <Row className="world-block-container">
                      <Col className="p-0 m-0">
                        <WorldBlock
                          worldId="1"
                          name="World 1"
                          handleOpen={this.handleOpen}
                        />
                      </Col>
                      <Col className="p-0 m-0">
                        <WorldBlock
                          worldId="2"
                          name="World 2"
                          handleOpen={this.handleOpen}
                        />
                      </Col>
                    </Row>
                    <Row className="world-block-container">
                      <Col className="p-0 m-0">
                        <WorldBlock
                          worldId="3"
                          name="World 3"
                          handleOpen={this.handleOpen}
                        />
                      </Col>
                      <Col className="p-0 m-0">
                        <WorldBlock
                          worldId="4"
                          name="World 4"
                          handleOpen={this.handleOpen}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {expandedWorldId && (
                <div className="full-width-height p-0 m-0">
                  <WorldBlockFull
                    worldId={expandedWorldId}
                    world={WORLDS[expandedWorldId]}
                    name="World 1"
                    handleClose={this.handleClose}
                  />
                </div>
              )}
            </Desktop>
          </Container>
          <Container fluid className="white-light-bgr pt-5 pb-5">
            <Container>
              <Row className="pb-5 pr-2 mr-5">
                <Col md="12">
                  <NumberedHeader num={3} text="Хеджи возвращается и…" />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Container className="p-0 large-nav">
                    <Nav tabs className="d-flex justify-content-start">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === '1',
                          })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          Поедай
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === '2',
                          })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          Собери коллекцию
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === '3',
                          })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          Зарабатывай
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <SetHeightRow height="166px">
                          <CenteredColumn sm="6">
                            <p>
                              Tвой Хеджи может вернуться со съедобной добычей,
                              такой как грибы или ягоды. Потребляй съедобную
                              добычу для увеличения силы Хеджи в течение 24-х
                              часов, а также Храни найденные съестные или не
                              съестные припасы, чтобы собрать полную коллекцию.
                              И наконец, Меняй все что захочешь на монеты.
                            </p>
                          </CenteredColumn>
                          <LootImgColumn sm="6">
                            <LootImg
                              src={Edibles}
                              style={{ maxWidth: '278px' }}
                            />
                          </LootImgColumn>
                        </SetHeightRow>
                      </TabPane>
                      <TabPane tabId="2">
                        <SetHeightRow height="166px">
                          <CenteredColumn sm="6">
                            <p>
                              Kоллекционировать можно все съедобное или
                              несъедобное, например, коллекция грибов или
                              драгоценных камней. Когда Хеджи соберет полную
                              коллекцию, ты сможешь обменять ее на недельную
                              прокачку силы (на целых семь дней!), или поменять
                              на звонкую монету.
                            </p>
                          </CenteredColumn>
                          <LootImgColumn sm="6">
                            <CollectionRow>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={OysterMushroom} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                            </CollectionRow>
                            <CollectionRow>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={OrangeMushroom} />
                              </CollectionItem>
                              <CollectionItem>
                                <CollectionImg src={QuestionMark} />
                              </CollectionItem>
                            </CollectionRow>
                          </LootImgColumn>
                        </SetHeightRow>
                      </TabPane>
                      <TabPane tabId="3">
                        <SetHeightRow height="166px">
                          <CenteredColumn sm="6">
                            <p>
                              Каждый раз когда твой Хеджи приносит тебе что-то
                              из похода, ты сможешь обменять это на Кюрио!
                              Накопи или используй свои монеты для покупки
                              снаряжения для своего Хеджи. Ты также можешь
                              потратить их в реальном мире (подробнее об этом в
                              разделах 4 и 5).
                            </p>
                          </CenteredColumn>
                          <LootImgColumn sm="6">
                            <LootImg
                              src={CoinStackImage}
                              style={{ maxWidth: '221px' }}
                            />
                          </LootImgColumn>
                        </SetHeightRow>
                      </TabPane>
                    </TabContent>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="grey-light-bgr pt-5 pb-5">
            <Container>
              <Row className="pb-5 pr-2 mr-5">
                <Col md="12">
                  <NumberedHeader num={4} text="Прокачивайся" />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p>
                    Такие предметы, как инструменты и экипировка, помогают
                    улучшить производительность твоего Хеджи. Инструменты могут
                    быть найдены Хеджи по пути или приобретены за крипто монеты,
                    в то время как экипировка может быть только куплена за
                    Кюрио.
                  </p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col md="6">
                  <UpgradeBlock
                    upgrade={Upgrades[0]}
                    className="m-auto mr-md-auto"
                  />
                </Col>
                <Col md="6">
                  <UpgradeBlock
                    upgrade={Upgrades[1]}
                    className="m-auto ml-md-auto"
                  />
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="light-light-bgr pt-5 pb-5">
            <Container>
              <Row className="pb-5 pr-2 mr-5">
                <Col md="12">
                  <NumberedHeader
                    num={5}
                    text="Трать монеты в реальной жизни"
                  />
                </Col>
              </Row>
              <Row className="pb-5">
                <Col md="12">
                  <p>
                    Какая польза от всех этих монет, если ты не можешь их
                    потратить? К счастью, Кюрио, который ты зарабатываешь со
                    своим Hedgie, можно потратить в локальных и
                    интернет-компаниях, которые являются партнерами Hedgie{' '}
                    <Link to={paths.SPEND_CURIO}>
                      Локальные и онлайн бизнес-партнеры Hedgie.
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col md="6">
                  <SubHeadingBold
                    className="text-center text-md-left"
                    heading="Что такое Кюрио?"
                  />
                  <p>Кюрио это токен ERC20 на блокчейне эфириума.</p>
                  <p>
                    Он имеет жесткое количественное ограничение в размере 8 888
                    888 888 единиц и может быть создан только внутри игры Хеджи.
                    Ты можешь заработать Кюрио, отправив Хеджи в путешествие в
                    игре. Когда ты заработал достаточно, используй монеты, чтобы
                    покупать предметы внутри игры, продавать их на крипто
                    обменниках или тратить в реальном мире!
                  </p>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={BuyItemsImage}
                          alt="Покупай предметы в игре"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Покупай предметы в игре" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={SellOnExchangesImage}
                          alt="Продавай на крипто обменнике"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Продавай на крипто обменнике" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={SpendInWorldImage}
                          alt="Трать в реальном мире"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Трать в реальном мире" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubHeadingBold
                    heading="Ну что, поехали?"
                    style={{ fontSize: '1.5em' }}
                  />
                 {/*  <Button tag={Link} size="large" to={paths.GET_HEDGIE}>
                    Выбери Своего Хеджи
                  </Button> */}
                  <Button
                    tag={Link}
                    size="large"
                    to={paths.GIFT_CODE}
                    style={{ marginLeft: '20px' }}
                  >
                    Подари Хеджи в подарок
                  </Button>
                </Col>
              </Row>
            </Container>
          </Container>
          <Mobile>
            <Divider />
          </Mobile>
          <PresalePremiumSlideRu
            premiumHedgies={hedgies}
            loading={loadingPremium}
            error={errorGettingHedgies}
          />
        </main>
      </div>
    );
  }
}

RussianLandingPage.propTypes = {
  hedgie: types.hedgie,
  tier: types.tier,
  hedgies: types.hedgies,

  errorChoosingHedgie: types.error,
  errorGettingHedgies: types.error,

  loading: PropTypes.bool.isRequired,
  loadingPremium: PropTypes.bool.isRequired,
  user: types.user,
  authenticated: PropTypes.bool.isRequired,
  // actions
  getHedgie: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

RussianLandingPage.defaultProps = {
  hedgie: null,
  tier: null,
  hedgies: [],
  errorGettingHedgies: {},
  errorChoosingHedgie: {},
  user: null,
};

export default RussianLandingPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as paths from 'config/paths';
import * as types from 'types';
import {
  GiftCode,
  SideHedgie,
  PageTitle,
  BoldSpan,
  FormError,
} from 'components';

class GiftCodePage extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.giftingSuccess !== prevProps.giftingSuccess &&
      this.props.giftingSuccess === true
    ) {
      this.props.history.push(paths.GIFT_CODE_THANK_YOU);
    }
  }

  render() {
    const { user, onSubmitGiftRequest, giftingError } = this.props;
    return (
      <div>
        <main>
          <Container fluid className="pos-rel sign-up-wrap">
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Give the gift of Hedgie!" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Purchase <BoldSpan>Hedgie</BoldSpan> credit for someone
                    below and we’ll give you a special code to send them.
                  </p>
                </Col>
              </Row>
              {giftingError && <FormError error={giftingError} />}
              <Row style={{ marginBottom: 60 }}>
                <Col>
                  <GiftCode
                    {...user}
                    onSubmitGiftRequest={onSubmitGiftRequest}
                  />
                </Col>
              </Row>
            </Container>
            <SideHedgie
              className="side-hedgie-gift"
              color="purple"
              title="So generous!"
            />
          </Container>
        </main>
      </div>
    );
  }
}

GiftCodePage.propTypes = {
  user: types.user,
  onSubmitGiftRequest: PropTypes.func,
  giftingSuccess: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  giftingError: types.error,
};
GiftCodePage.defaultProps = {
  user: null,
  onSubmitGiftRequest: () => {},
  giftingError: null,
};

export default GiftCodePage;

import {
  GET_USER_ITEMS,
  GET_USER_ITEMS_FAILURE,
  GET_USER_ITEMS_SUCCESS,
  SELL_ITEM,
  SELL_ITEM_SUCCESS,
  SELL_ITEM_FAILURE,
  RESET_SELL_ITEM,
} from './actionTypes';

export const getUserItems = payload => ({
  type: GET_USER_ITEMS,
  payload,
});

export const getUserItemsSuccess = payload => ({
  type: GET_USER_ITEMS_SUCCESS,
  payload,
});
export const getUserItemsFailure = error => ({
  type: GET_USER_ITEMS_FAILURE,
  payload: error,
});

export const sellItem = payload => ({
  type: SELL_ITEM,
  payload,
});

export const sellItemSuccess = payload => ({
  type: SELL_ITEM_SUCCESS,
  payload,
});
export const sellItemFailure = error => ({
  type: SELL_ITEM_FAILURE,
  payload: error,
});

export const resetSellItemState = () => ({
  type: RESET_SELL_ITEM,
});

import { put, call, fork, take, select } from 'redux-saga/effects';
import Log from 'services/log';
import cogoToast from 'cogo-toast';
import { sessionService } from 'redux-react-session';
import { resourceDetailReadRequest } from 'store/actions';
import * as actions from './actions';
import { selectCurrentUser } from './selectors';
import NumberUtil from '../../services/numberUtil';

export function* getCurrentUser({ api, firebase }) {
  try {
    Log.debug(firebase, 'saga: getCurrentUser:begin');
    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

    if (!idToken) {
      throw Error('saga: getCurrentUser: No access token in session');
    }
    yield call([api, api.setToken], idToken);
    const currentUser = yield call([api, api.get], '/users/me');
    Log.debug(currentUser, 'saga: getCurrentUser:detail');


    yield call(sessionService.saveUser, currentUser.data);
    yield put(actions.getCurrentUserSuccess(currentUser.data));
  } catch (e) {
    Log.error(e, 'saga: getCurrentUser:error');
    yield put(actions.getCurrentUserFailure(e));
  }
}

export function* updateUserWallet({ api, firebase }, payload) {
  try {
    Log.debug(firebase, 'saga: updateUserWallet:begin');
    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

    if (!idToken) {
      throw Error('saga: updateUserWallet: No access token in session');
    }
    yield call([api, api.setToken], idToken);
    const detail = yield call([api, api.post], '/hedgies/sync', payload);
    Log.debug(detail, 'saga: updateUserWallet:detail');
    yield put(actions.updateUserWalletSuccess(detail));
    yield put(resourceDetailReadRequest('users', 'me'));
  } catch (e) {
    Log.error(e, 'saga: updateUserWallet:error');
    yield put(actions.updateUserWalletFailure(e));
    // yield put(resourceDetailReadRequest('users', 'me'));
  }
}

export function* getCurioTransactions(api, payload) {
  try {
    const { limit, offset, isLoadMore } = payload;
    const response = yield call(
      [api, api.get],
      '/users/me/curio-transactions',
      {
        params: {
          limit,
          offset,
        },
      },
    );
    Log.debug(response, 'saga: updateUserWallet:detail');
    yield put(actions.getCurioTransactionsSuccess({ ...response, isLoadMore }));
  } catch (e) {
    Log.error(e, 'saga: updateUserWallet:error');
    yield put(actions.getCurioTransactionsFailure(e));
  }
}

export function* updateUsername(api, payload) {
  try {
    const response = yield call([api, api.put], '/users/me/username', payload);
    yield call(sessionService.saveUser, response.data);
    yield put({
      type: actions.UPDATE_USER_NAME_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: actions.UPDATE_USER_NAME_FAILURE,
      payload: e,
    });
  }
}
export function* transferCurio(api, payload) {
  try {
    const response = yield call(
      [api, api.post],
      '/curio-transactions',
      payload,
    );
    yield put(actions.transferCurioSuccess(response));
    cogoToast.success('Transferred success');
    yield put(actions.toggleTransferCurioModal(false));

    const currentUser = yield select(selectCurrentUser);
    yield call(sessionService.saveUser, {
      ...currentUser,
      curioBalance: NumberUtil.minusTwoBigNumber(
        currentUser.curioBalance,
        response.data.amount,
      ),
    });
  } catch (e) {
    yield put(actions.transferCurioFailure(e));
  }
}

export function* requestOtpCode(api, payload) {
  try {
    yield call([api, api.post], '/auth/request-otp');
    yield put({ type: actions.REQUEST_OTP_CODE_SUCCESS });
    cogoToast.success('A confirmation code has been sent to your email');
  } catch (e) {
    yield put({ type: actions.REQUEST_OTP_CODE_FAILURE, payload: e, });
  }
}

export function* verifyEmailAddress({ api, firebase }, payload) {
  try {
    yield call([api, api.post], '/auth/verify-email', { otp: payload },);
    yield put({ type: actions.VERIFY_EMAIL_ADDRESS_SUCCESS });
  } catch (e) {
    yield put({ type: actions.VERIFY_EMAIL_ADDRESS_FAILURE, payload: e, });
  }
}

export function* watchGetCurrentUserRequest({ api, firebase }) {
  while (true) {
    const { payload } = yield take(actions.GET_CURRENT_USER_REQUEST);
    yield call(getCurrentUser, { api, firebase }, payload);
  }
}

export function* watchUpdateUserWalletRequest({ api, firebase }) {
  while (true) {
    const { payload } = yield take(actions.UPDATE_USER_WALLET_REQUEST);
    yield call(updateUserWallet, { api, firebase }, payload);
  }
}

export function* watchGetCurioTransactionsRequest(api) {
  while (true) {
    const { payload } = yield take(actions.GET_CURIO_TRANSACTIONS_REQUEST);
    yield call(getCurioTransactions, api, payload);
  }
}

export function* watchUpdateUsername(api) {
  while (true) {
    const { payload } = yield take(actions.UPDATE_USER_NAME);
    yield call(updateUsername, api, payload);
  }
}

export function* watchTransferCurio(api) {
  while (true) {
    const { payload } = yield take(actions.TRANSFER_CURIO);
    yield call(transferCurio, api, payload);
  }
}

export function* watchOtpCodeRequest(api) {
  while (true) {
    const { payload } = yield take(actions.REQUEST_OTP_CODE);
    yield call(requestOtpCode, api, payload);
  }
}

export function* watchVerifyEmailAddress({ api, firebase }) {
  while (true) {
    const { payload } = yield take(actions.VERIFY_EMAIL_ADDRESS);
    yield call(verifyEmailAddress, { api, firebase }, payload);
  }
}

export default function* ({ api, firebase }) {
  yield fork(watchGetCurrentUserRequest, { api, firebase });
  yield fork(watchUpdateUserWalletRequest, { api, firebase });
  yield fork(watchGetCurioTransactionsRequest, api);
  yield fork(watchUpdateUsername, api);
  yield fork(watchTransferCurio, api);
  yield fork(watchOtpCodeRequest, api);
  yield fork(watchVerifyEmailAddress, { api, firebase });
}

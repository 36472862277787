import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad, { forceCheck } from 'react-lazyload';
import ProductDiscount from '../ProductDiscount';

const StyledList = styled.div`
  padding-top: 20px;
`;

const MarketplaceProductsList = ({ products }) => {
  useEffect(() => {
    setTimeout(forceCheck, 1000);
  }, []);

  return (
    <StyledList>
      {products.map((product, index) => (
        <LazyLoad height={307} key={product.id} once throttle={100}>
          <ProductDiscount
            product={product}
            isOddOrder={index % 2 === 0}
            isLink
            showAddress
          />
        </LazyLoad>
      ))}
    </StyledList>
  );
};

MarketplaceProductsList.propTypes = {
  products: PropTypes.array,
};

MarketplaceProductsList.defaultProps = {
  products: [],
};

export default React.memo(MarketplaceProductsList);

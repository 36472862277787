export const GET_MY_BUSINESSES = 'GET_MY_BUSINESSES';
export const GET_MY_BUSINESSES_SUCCESS = 'GET_MY_BUSINESSES_SUCCESS';
export const GET_MY_BUSINESSES_FAILURE = 'GET_MY_BUSINESSES_FAILURE';

export const GET_BUSINESS_PRODUCTS = 'GET_BUSINESS_PRODUCTS';
export const GET_BUSINESS_PRODUCTS_SUCCESS = 'GET_BUSINESS_PRODUCTS_SUCCESS';
export const GET_BUSINESS_PRODUCTS_FAILURE = 'GET_BUSINESS_PRODUCTS_FAILURE';

export const GET_BUSINESS_DETAIL = 'GET_BUSINESS_DETAIL';
export const GET_BUSINESS_DETAIL_SUCCESS = 'GET_BUSINESS_DETAIL_SUCCESS';
export const GET_BUSINESS_DETAIL_FAILURE = 'GET_BUSINESS_DETAIL_FAILURE';

export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';

export const CREATE_AN_ORDER = 'CREATE_AN_ORDER';
export const CREATE_AN_ORDER_SUCCESS = 'CREATE_AN_ORDER_SUCCESS';
export const CREATE_AN_ORDER_FAILURE = 'CREATE_AN_ORDER_FAILURE';
export const CLEAR_ORDER_STATE = 'CLEAR_ORDER_STATE';

export const GET_ORDERS_OF_BUSINESS = 'GET_ORDERS_OF_BUSINESS';
export const GET_ORDERS_OF_BUSINESS_SUCCESS = 'GET_ORDERS_OF_BUSINESS_SUCCESS';
export const GET_ORDERS_OF_BUSINESS_FAILURE = 'GET_ORDERS_OF_BUSINESS_FAILURE';

export const GET_MY_ORDERS = 'GET_MY_ORDERS';
export const GET_MY_ORDERS_SUCCESS = 'GET_MY_ORDERS_SUCCESS';
export const GET_MY_ORDERS_FAILURE = 'GET_MY_ORDERS_FAILURE';

export const ARCHIVE_ORDER = 'ARCHIVE_ORDER';
export const ARCHIVE_ORDER_SUCCESS = 'ARCHIVE_ORDER_SUCCESS';
export const ARCHIVE_ORDER_FAILURE = 'ARCHIVE_ORDER_FAILURE';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';

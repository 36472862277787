import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  Button,
  PageTitle,
  SubHeadingBold,
  Divider,
} from 'components';
import * as paths from 'config/paths';
import ExclusiveItems from 'images/exclusive_items.png';
import styled from 'styled-components';

const ExclusiveImages = styled.div`
  min-height: 180px;
`;

class PresalePremiumSlideRu extends React.PureComponent {
  render() {
    const rowClass = 'py-5 ';
    return (
      <ScrollableAnchor id="premium-hedgies">
        <div className="container" id="premium-hedgies">
          <Row className="pt-5">
            <Col>
              <PageTitle title="Эксклюзивные Хеджи" />
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={{ size: 10, offset: 1 }}>
              <Row>
                <Col>
                  <ExclusiveImages className="d-flex text-center justify-content-center mx-2 align-items-center">
                    <img src={ExclusiveItems} alt="exclusive items" style={{ width: '100%' }} />
                  </ExclusiveImages>
                </Col>
              </Row>
              <Row className="pt-2 pb-3">
                <Col md="12">
                  <SubHeadingBold className="text-center" heading="Эксклюзивный контент" />
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p className="text-center">
                    Каждый эксклюзивный уровень открывает новый эксклюзивный элемент,
                    который повышает производительность твоего Хеджи в игре.
                    На самом высоком уровне Хеджи получает все шесть эксклюзивных предметов,
                    следующий уровень ниже дает 5 предметов, следующий - 4 … В общем, ты уловил суть!
                  </p>
                  <p className="text-center">
                    Кто знает, возможно, когда мы создадим в игре Хеджи новые королевства,
                    мы запросто дадим эксклюзивным владельцам Хеджи первые доступ.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md="12">
              <div className="d-flex text-center">
                <Button size="large" className="m-auto" tag={Link} to={paths.PREMIUM_HEDGIES}>
                  Выбери своего эксклюзивного Hedgie сейчас!
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ScrollableAnchor>
    );
  }
}

PresalePremiumSlideRu.propTypes = {
};

PresalePremiumSlideRu.defaultProps = {
};

export default PresalePremiumSlideRu;

import { put, call, fork, take, delay } from 'redux-saga/effects';
import querystring from 'querystring';
import Log from 'services/log';
import { GET_USER_ITEMS, SELL_ITEM } from './actionTypes';
import {
  getUserItemsFailure,
  getUserItemsSuccess,
  sellItemSuccess,
  sellItemFailure,
  resetSellItemState,
} from './actions';
import { getCurrentUserRequest } from '../user/actions';

export function* getUserItems(api, payload) {
  try {
    Log.debug('saga: getUserItems');
    const qs = querystring.stringify(payload);
    const response = yield call([api, api.get], `/users/me/items?${qs}`);
    Log.debug('saga: getUserItems:response');
    yield put(
      getUserItemsSuccess({ ...response, canLoadMore: payload.canLoadMore }),
    );
  } catch (e) {
    Log.debug('saga: getUserItems:failure');
    yield put(getUserItemsFailure(e));
  }
}

export function* sellItem(api, { userItemId, itemId }) {
  try {
    Log.debug('saga: sellItem');
    const response = yield call(
      [api, api.post],
      `/users/me/items/${userItemId}/sell`,
    );
    Log.debug('saga: sellItem:response');
    yield put(sellItemSuccess({ ...response, userItemId, itemId }));
    yield put(getCurrentUserRequest());
    yield delay(1000);
    yield put(resetSellItemState());
  } catch (e) {
    Log.debug('saga: sellItem:failure');
    yield put(sellItemFailure(e));
  }
}

export function* watchGetUserItems(api) {
  while (true) {
    const { payload } = yield take(GET_USER_ITEMS);
    yield call(getUserItems, api, payload);
  }
}

export function* watchSellItem(api) {
  while (true) {
    const { payload } = yield take(SELL_ITEM);
    yield call(sellItem, api, payload);
  }
}

export default function* ({ api, firebase }) {
  yield fork(watchGetUserItems, api);
  yield fork(watchSellItem, api);
}

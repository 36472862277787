import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NumberUtil from 'services/numberUtil';
import curioImg from 'images/curio.svg';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledText = styled.div`
  color: ${p => p.color};
`;

const CurioPrice = ({ curioPrice, imgWidth, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <img width={imgWidth} src={curioImg} alt="curio-icon" />
      <StyledText color={props.textColor} className="pl-2">
        {NumberUtil.toDecimal(curioPrice)}
      </StyledText>
    </StyledWrapper>
  );
};

CurioPrice.propTypes = {
  curioPrice: PropTypes.string.isRequired,
  imgWidth: PropTypes.number,
  textColor: PropTypes.string,
};

CurioPrice.defaultProps = {
  imgWidth: 24,
  textColor: '#000',
};

export default memo(CurioPrice);

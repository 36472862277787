import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { media } from 'styles/main';

const modalRoot = document.getElementById('modal-root');

const StyledBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledModalContainer = styled.div`
  background-color: #fff;
  width: 800px;
  height: auto;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 3px 40px rgba(0, 0, 0, 0.16);
  ${media.desktop`
    width: ${props => (props.modalWidth ? props.modalWidth : '90%')};
  `}

  ${media.phone`
    width: 96%;
  `}
`;

const StyledCloseIcon = styled.span`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  &:hover {
    color: #000;
  }
`;

class Modal extends React.PureComponent {
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  el = document.createElement('div');

  render() {
    const { onClose, modalWidth } = this.props;
    return createPortal(
      <StyledBackdrop>
        <StyledModalContainer modalWidth={modalWidth}>
          <StyledCloseIcon onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 20 }} />
          </StyledCloseIcon>
          {this.props.children}
        </StyledModalContainer>
      </StyledBackdrop>,
      this.el,
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  modalWidth: PropTypes.string,
};

Modal.defaultProps = {
  modalWidth: '100%',
};

export default Modal;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeadLine, Typography } from 'components';
import imgCurio from 'images/curio-styled.svg';
import TransferModal from '../../pages/TransferCurioPage/TransferModal';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCurioInfo = styled.div`
  padding-left: 20px;
`;

const CurioBalance = ({ curioBalance, fromBusinessID }) => {

  return (
    <StyledContainer>
      <StyledGroup>
        <img src={imgCurio} alt="curio" />
        <StyledCurioInfo>
          <Typography fontSize={18} text="Curio:" isBold align="left" />
          <HeadLine
            title={curioBalance}
            fontSize={24}
            fontWeight={800}
            align="left"
            color="#642460"
          />
          <TransferModal fromBusinessID={fromBusinessID} curioBalance={curioBalance} />
        </StyledCurioInfo>
      </StyledGroup>
    </StyledContainer>
  );
};

CurioBalance.propTypes = {
  curioBalance: PropTypes.string.isRequired,
  fromBusinessID: PropTypes.string,
};

export default memo(CurioBalance);

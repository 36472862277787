import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import TopSlide from './TopSlide';

const TheGame = React.forwardRef(({ isMobile }, ref) => {
  function handleNext() {
    if (ref.current) {
      ref.current.scrollTo(1);
    }
  }

  return (
    <React.Fragment>
      <ParallaxLayer
        offset={0}
        speed={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TopSlide isMobile={isMobile} onClick={handleNext} />
      </ParallaxLayer>
    </React.Fragment>
  );
});

TheGame.propTypes = {
  isMobile: PropTypes.bool,
};

TheGame.defaultProps = {
  isMobile: false,
};

export default TheGame;

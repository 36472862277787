import powerWater from 'images/powers/powerWater.png';
import powerFire from 'images/powers/powerFire.png';
import powerEarth from 'images/powers/powerEarth.png';
import powerAir from 'images/powers/powerAir.png';
import skillLuck from 'images/powers/skillLuck.png';
import skillPrudence from 'images/powers/skillPrudence.png';
import skillIntelligence from 'images/powers/skillIntelligence.png';
import skillCharm from 'images/powers/skillCharm.png';

export default {
  powerWater,
  powerFire,
  powerEarth,
  powerAir,
  skillLuck,
  skillPrudence,
  skillIntelligence,
  skillCharm,
};

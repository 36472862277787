import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromApp } from 'store/selectors';
import { subscribeNewsRequest } from 'store/actions';
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import { Button, FormError } from 'components';

import * as types from 'types';

const INITIAL_STATE = {
  email: '',
  validation: {},
};

class NewsLetterForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.status !== this.props.status && newProps.status === true) {
      this.setState({
        email: '',
        validation: {},
      });
    }
  }

  onChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      validation: {},
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      email,
    } = this.state;

    if (!email) {
      this.setState({
        validation: { message: 'Please enter your email address' },
      });
    } else {
      this.props.onSubscribe(email);
    }
  }

  render() {
    const {
      email,
      validation,
    } = this.state;

    const {
      error,
      working,
    } = this.props;

    return (
      <div>
        <Form m3={3} onSubmit={this.onSubmit} className="mb-4">
          <FormGroup>
            <Label for="email" className="color-pink">Subscribe To Our Newsletter</Label>
            <InputGroup>
              <Input
                disabled={working}
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                onChange={event => this.onChange(event)}
                value={email}
              />
              <InputGroupAddon addonType="append">
                <Button style={{ margin: '0px' }} disabled={validation.message !== undefined || working} type="submit">Subscribe</Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormError className="p-0 m-0" error={error || validation} />
        </Form>
      </div>
    );
  }
}

NewsLetterForm.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
  status: PropTypes.bool,
};

NewsLetterForm.defaultProps = {
  error: null,
  working: false,
  status: false,
};

const mapStateToProps = state => ({
  error: fromApp.getStateValue(state, 'subscribe', 'error'),
  working: fromApp.getStateValue(state, 'subscribe', 'working'),
  status: fromApp.getStateValue(state, 'subscribe', 'status'),
});

const mapDispatchToProps = dispatch => ({
  onSubscribe: email => dispatch(subscribeNewsRequest(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterForm);

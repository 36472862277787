export function randomInRange(start, end) {
  return Math.floor(Math.random() * (end - start + 1) + start);
}

export function createMarkup(htmlStr) {
  return { __html: htmlStr };
}

export const getNotificationLink = href => {
  const paths = href.split('/');
  return paths[paths.length - 1];
};

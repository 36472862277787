import { createSelector } from 'reselect';

const hedgiesSelector = state => state.hedgies;

export const selectAHedgie = createSelector(
  hedgiesSelector,
  hedgies => hedgies.hedgie,
);

export const selectAHedgieError = createSelector(
  hedgiesSelector,
  hedgie => hedgie.errorGettingHedgie,
);

import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';
import Indicator from './Indicator';
import DesktopPage from './DesktopPage';

const StyledPages = styled.div`
  width: 100%;
  min-height: calc(100vh - 58px);
  position: relative;
`;

const AboutPage = ({ isMobile }) => {
  const prevOffset = useRef(0);
  const parallaxRef = useRef();
  const [currentPage, setCurrentPage] = useState(0);

  const onScroll = useCallback(() => {
    const { current, space } = parallaxRef.current;
    const currentIndex = Math.round(current / space);
    if (currentIndex === 7 && prevOffset.current > current) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    prevOffset.current = current;
    setCurrentPage(currentIndex);
  }, []);

  function handleClickIndicator(index) {
    parallaxRef.current.scrollTo(index);
  }

  return (
    <StyledPages onScroll={onScroll}>
      {isMobile ? null : (
        <Indicator
          pages={8}
          active={currentPage}
          onClick={handleClickIndicator}
        />
      )}
      <DesktopPage isMobile={isMobile} ref={parallaxRef} />
    </StyledPages>
  );
};

AboutPage.propTypes = {
  isMobile: PropTypes.bool,
};

AboutPage.defaultProps = {
  isMobile: false,
};

export default withResponsive(AboutPage);

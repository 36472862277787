import { useContext, useMemo } from 'react';
import { forEach, includes, isEmpty, replace, toLower } from 'lodash';
import { HouseContext } from './index';

function checkResourcesEnough(requiredResources) {
  return !requiredResources.filter(rs => !rs.hasEnoughResource).length > 0;
}

function getRequiredResources(furniture, resourceTypes) {
  const rs = [];
  if (isEmpty(furniture)) {
    return rs;
  }

  forEach(furniture, (value, key) => {
    if (includes(key, 'required')) {
      const name = toLower(replace(key, 'required', ''));
      rs.push({
        ...resourceTypes[name],
        amount: value,
        hasEnoughResource: value > 0 && value <= resourceTypes[name].amount,
      });
    }
  });

  return rs.filter(item => item.amount > 0);
}

export default furniture => {
  const { resourceTypesDetail } = useContext(HouseContext);

  const requiredResources = useMemo(
    () => getRequiredResources(furniture, resourceTypesDetail),
    [furniture, resourceTypesDetail],
  );

  const hasEnoughResources = useMemo(
    () => checkResourcesEnough(requiredResources),
    [requiredResources],
  );

  return { requiredResources, hasEnoughResources };
};

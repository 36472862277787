import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeadLine } from 'components';
//import Linkify from 'react-linkify'

const StyledContainer = styled.div`
  padding: 0 10px;
`;
const StyledList = styled.div`
  list-style: circle;
`;

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url.substr(0, 50) + '</a>';
  })
}

const Limits = ({ limits, ...props }) => {
  return (
    <StyledContainer {...props}>
      <HeadLine title="Limits" align="left" fontSize={18} color="#000" />
      <StyledList>
        {limits.map(limit => (
           //<li key={limit}><Linkify truncate={10} properties={{ target: '_blank', truncate: 20 }}>{limit}</Linkify></li>
          <li key={limit}><span dangerouslySetInnerHTML={{ __html: urlify(limit) }} /></li>
        ))}
      </StyledList>
    </StyledContainer>
  );
};


Limits.propTypes = {
  limits: PropTypes.array,
};

Limits.defaultProps = {
  limits: ['Testing 1', 'Testing 2', 'Testing 3'],
};

export default Limits;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
import Photo from '../Photo';

const StyledBaseContainer = styled.div`
  display: grid;
`;

const cssInlineGrid = css`
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  .photo {
    grid-column: 4 / -1;
    grid-row: 1/-1;
  }
  .content {
    grid-column: 1 / 4;
    grid-row: 1/-1;
  }
`;

const cssOddGrid = css`
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  .photo {
    grid-column: 1 / 6;
    grid-row: 1/-1;
  }
  .content {
    grid-column: 6 / -1;
    grid-row: 1/-1;
  }
`;

const cssColumn = css`
  grid-auto-columns: fit-content(1fr);
`;

const generateStyle = (type, isOddIndex) => {
  if (type === 'column') {
    return cssColumn;
  } else if (isOddIndex) {
    return cssOddGrid;
  }
  return cssInlineGrid;
};

const StyledResponsive = styled(StyledBaseContainer)(
  breakpoints({
    sm: cssColumn,
    md: ({ type, isOddIndex }) => generateStyle(type, isOddIndex),
  }),
);

const StyledContent = styled.div`
  background-color: #eef3f4;
  padding: 20px;
  max-height: ${p => p.maxHeight}px;
  overflow: hidden;
`;

const INLINE_RATIO = 541 / 307;

const Card = ({
  imgUrl,
  onClickPhoto,
  content,
  type,
  imageRatio,
  isOddIndex,
  maxImageHeight,
  numberOfSmallPhotos,
  onClick,
}) => {
  return (
    <StyledResponsive type={type} isOddIndex={isOddIndex} onClick={onClick}>
      <Photo
        src={imgUrl}
        className="photo"
        ratio={type === 'inline' ? INLINE_RATIO : imageRatio}
        onClick={onClickPhoto}
        numberOfPhotos={numberOfSmallPhotos}
        maxHeight={maxImageHeight}
      />
      <StyledContent className="content" maxHeight={maxImageHeight}>
        {content}
      </StyledContent>
    </StyledResponsive>
  );
};

Card.propTypes = {
  imgUrl: PropTypes.string,
  maxImageHeight: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.any,
  imageRatio: PropTypes.number,
  isOddIndex: PropTypes.bool,
  onClickPhoto: PropTypes.func,
  numberOfSmallPhotos: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['column', 'inline']),
};

Card.defaultProps = {
  imgUrl: '',
  title: '',
  description: '',
  content: null,
  type: 'inline',
  isOddIndex: false,
  imageRatio: 16 / 9,
  onClickPhoto: null,
  maxImageHeight: 320,
  numberOfSmallPhotos: 0,
  onClick: null,
};

export default Card;

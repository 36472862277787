export const GET_MY_JOURNEYS = 'GET_MY_JOURNEYS';
export const GET_MY_JOURNEYS_SUCCESS = 'GET_MY_JOURNEYS_SUCCESS';
export const GET_MY_JOURNEYS_FAILURE = 'GET_MY_JOURNEYS_FAILURE';

export const CREATE_JOURNEY = 'CREATE_JOURNEY';
export const CREATE_JOURNEY_SUCCESS = 'CREATE_JOURNEY_SUCCESS';
export const CREATE_JOURNEY_FAILURE = 'CREATE_JOURNEY_FAILURE';
export const RESET_CREATED_JOURNEY_STATE = 'RESET_CREATED_JOURNEY_STATE';

export const JOURNEY_SELECT_HEDGIE = 'JOURNEY_SELECT_HEDGIE';

export const GET_WORLDS = 'GET_WORLDS';
export const GET_WORLDS_SUCCESS = 'GET_WORLDS_SUCCESS';
export const GET_WORLDS_FAILURE = 'GET_WORLDS_FAILURE';

export const SELECT_WORLD = 'SELECT_WORLD';
export const CLEAR_SELECTED_WORLD = 'CLEAR_SELECTED_WORLD';

export const SELECT_GOAL = 'SELECT_GOAL';
export const CLEAR_GOAL = 'CLEAR_GOAL';

export const GET_ITEMS_OF_WORLD = 'GET_ITEMS_OF_WORLD';
export const GET_ITEMS_OF_WORLD_SUCCESS = 'GET_ITEMS_OF_WORLD_SUCCESS';
export const GET_ITEMS_OF_WORLD_FAILURE = 'GET_ITEMS_OF_WORLD_FAILURE';

export const FETCH_ITEMS_OF_THE_WORLD = 'FETCH_ITEMS_OF_THE_WORLD';

export const GET_COLLECTIONS_OF_WORLD = 'GET_COLLECTIONS_OF_WORLD';
export const GET_COLLECTIONS_OF_WORLD_SUCCESS =
  'GET_COLLECTIONS_OF_WORLD_SUCCESS';
export const GET_COLLECTIONS_OF_WORLD_FAILURE =
  'GET_COLLECTIONS_OF_WORLD_FAILURE';

export const GET_REWARDS_BY_JOURNEY_ID = 'GET_REWARDS_BY_JOURNEY_ID';
export const GET_REWARDS_BY_JOURNEY_ID_SUCCESS =
  'GET_REWARDS_BY_JOURNEY_ID_SUCCESS';
export const GET_REWARDS_BY_JOURNEY_ID_FAILURE =
  'GET_REWARDS_BY_JOURNEY_ID_FAILURE';

export const ARCHIVE_JOURNEY = 'ARCHIVE_JOURNEY';
export const ARCHIVE_JOURNEY_SUCCESS = 'ARCHIVE_JOURNEY_SUCCESS';
export const ARCHIVE_JOURNEY_FAILURE = 'ARCHIVE_JOURNEY_FAILURE';

export const CLOSE_REWARD_DIALOG = 'CLOSE_REWARD_DIALOG';

export const GET_JOURNEY_COSTS = 'GET_JOURNEY_COSTS';
export const GET_JOURNEY_COSTS_SUCCESS = 'GET_JOURNEY_COSTS_SUCCESS';
export const GET_JOURNEY_COSTS_FAILURE = 'GET_JOURNEY_COSTS_FAILURE';

export const getMyJourneys = payload => ({
  type: GET_MY_JOURNEYS,
  payload,
});

export const getMyJourneysSuccess = payload => ({
  type: GET_MY_JOURNEYS_SUCCESS,
  payload,
});

export const getMyJourneysFailure = error => ({
  type: GET_MY_JOURNEYS_FAILURE,
  error: true,
  payload: error,
});

export const selectJourneyHedgie = hedgie => ({
  type: JOURNEY_SELECT_HEDGIE,
  selectedJourneyHedgie: hedgie,
});

export const createJourney = params => ({
  type: CREATE_JOURNEY,
  params,
});

export const createJourneySuccess = payload => ({
  type: CREATE_JOURNEY_SUCCESS,
  payload,
});

export const createJourneyFailure = error => ({
  type: CREATE_JOURNEY_FAILURE,
  error: true,
  payload: error,
});

export const resetJourneyState = () => ({
  type: RESET_CREATED_JOURNEY_STATE,
});

export const getWorlds = () => ({
  type: GET_WORLDS,
});

export const getWorldsSuccess = payload => ({
  type: GET_WORLDS_SUCCESS,
  payload,
});

export const getWorldsFailure = error => ({
  type: GET_WORLDS_FAILURE,
  payload: error,
});

export const selectWorld = world => ({
  type: SELECT_WORLD,
  payload: world,
});

export const clearSelectedWorld = () => ({
  type: CLEAR_SELECTED_WORLD,
});

export const selectGoal = goal => ({
  type: SELECT_GOAL,
  payload: goal,
});

export const clearGoal = () => ({
  type: CLEAR_GOAL,
});

export const getRewardsByJourneyId = journeyId => ({
  type: GET_REWARDS_BY_JOURNEY_ID,
  payload: journeyId,
});

export const getRewardsByJourneyIdSuccess = payload => ({
  type: GET_REWARDS_BY_JOURNEY_ID_SUCCESS,
  payload,
});

export const getRewardsByJourneyIdFailure = error => ({
  type: GET_REWARDS_BY_JOURNEY_ID_FAILURE,
  payload: error,
});

export const archiveJourney = journeyId => ({
  type: ARCHIVE_JOURNEY,
  journeyId,
});

export const archiveJourneySuccess = payload => ({
  type: ARCHIVE_JOURNEY_SUCCESS,
  payload,
});

export const archiveJourneyFailure = error => ({
  type: ARCHIVE_JOURNEY_FAILURE,
  payload: error,
});

export const closeRewardDialog = journeyId => ({
  type: CLOSE_REWARD_DIALOG,
  journeyId,
});

export const getJourneyCosts = () => ({ type: GET_JOURNEY_COSTS });

export const getJourneyCostsSuccess = payload => ({
  type: GET_JOURNEY_COSTS_SUCCESS,
  payload,
});

export const getJourneyCostsFailure = error => ({
  type: GET_JOURNEY_COSTS_FAILURE,
  payload: error,
});

import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import stripeSlide03 from '../images/stripeSlide03.svg';
import cloud from '../images/cloud.png';
import Power from './Power';

const PowerSlide = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer
        offset={2}
        speed={0.1}
        factor={2}
        style={{ backgroundColor: '#FFCA61' }}
      />
      <ParallaxLayer offset={2} speed={0.1}>
        <img
          src={stripeSlide03}
          style={{
            display: 'block',
            width: isMobile ? '80%' : '50%',
            opacity: isMobile ? 0.5 : 1,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          alt="stripeSlide03"
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={isMobile ? 2.6 : 2.4}
        speed={isMobile ? 0.4 : -0.4}
        style={{ opacity: isMobile ? 0.6 : 0.5 }}
      >
        <img
          src={cloud}
          style={{
            width: isMobile ? '80%' : '50%',
            marginLeft: isMobile ? '10%' : '46%',
          }}
          alt="cloud"
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={2}
        speed={-0.3}
        style={{ backgroundSize: '80%', backgroundPosition: 'center' }}
      />
      <ParallaxLayer offset={isMobile ? 2 : 2.1} speed={0.1} key="power">
        <Power isMobile={isMobile} onNextPage={onNextPage} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

PowerSlide.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

PowerSlide.defaultProps = {
  isMobile: false,
};

export default PowerSlide;

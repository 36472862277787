import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { PageTitle, InventoryTabs } from 'components';
import styled from 'styled-components';

export const InventoryContext = React.createContext({
  items: [],
  loading: false,
  error: {
    error: false,
    message: '',
  },
  getUserItems: null,
  itemsPagination: null,
});

const StyledWrapper = styled.div``;

const StyledMainBackground = styled.div`
  background: #eff3f4;
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const InventoryPage = memo(
  ({
    userItems,
    userItemsLoading,
    userItemsError,
    getUserItems,
    getMyHedgiesRequest,
    itemsPagination,
  }) => {
    useEffect(() => {
      getMyHedgiesRequest({
        limit: 20,
        offset: 0,
        isLoadMore: false,
      });
    }, [getMyHedgiesRequest]);
    return (
      <InventoryContext.Provider
        value={{
          items: userItems,
          error: userItemsError,
          loading: userItemsLoading,
          getUserItems,
          itemsPagination,
        }}
      >
        <StyledWrapper>
          <PageTitle title="Inventory" />
          <StyledMainBackground>
            <InventoryTabs />
          </StyledMainBackground>
        </StyledWrapper>
      </InventoryContext.Provider>
    );
  },
);

InventoryPage.propTypes = {
  getUserItems: PropTypes.func,
  userItems: PropTypes.array,
  userItemsLoading: PropTypes.bool,
  userItemsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  getMyHedgiesRequest: PropTypes.func,
  itemsPagination: PropTypes.object,
};

InventoryPage.defaultProps = {
  getUserItems: null,
  userItems: [],
  userItemsLoading: false,
  userItemsError: {
    error: false,
    message: '',
  },
  getMyHedgiesRequest: null,
  itemsPagination: null,
};

export default InventoryPage;

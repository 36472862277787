import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { scrollableOption } from 'config/scroller';
import { useBattle } from './battle-context';
import { Participants } from './ChallengerFound';
import { PageTitle } from 'components';
import AttributeSummary from './AttributeSummary';
import PureSpinner from '../../commons/PureSpinner';
import { useDispatch } from 'react-redux';
import { sawBattleDetail } from '../../../store/battle/actions';

const StyledContent = styled.div`
  margin-top: ${(p) => (p.isMobile ? '0' : '-200px')};
`;

const StyledCover = styled.div`
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.5)
    ),
    url(${(p) => p.imgUrl});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  height: ${(p) => (p.isMobile ? '100%' : '580px')};
`;

const BattlePoints = () => {
  const [loading, setLoading] = React.useState(false);
  const { worlds, battleInfo, isMobile, hedgieOwner } = useBattle();
  const [showAttribute, setShowAttribute] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sawBattleDetail(hedgieOwner?.hedgie?.color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlImgCover = React.useMemo(() => {
    if (worlds.length) {
      const foundW = worlds.find((w) => w.id === battleInfo?.world_id);
      return foundW ? foundW.imageURL : '';
    }
  }, [worlds, battleInfo]);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // setPage(pageNames.WINNER);
      scroller.scrollTo('battle-attribute', scrollableOption);
      setShowAttribute(true);
    }, 1500);
    // eslint-disable-next-line
  }, []);

  if (isMobile) {
    return (
      <div>
        <StyledCover imgUrl={urlImgCover} isMobile={isMobile}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
              <PureSpinner size={36} />
              <span style={{ color: '#fff', fontSize: 16 }} className="pl-2">
                Fighting...
              </span>
            </div>
          ) : (
            <PageTitle title="Arena" />
          )}
          <div>
            <StyledContent isMobile={isMobile}>
              <Participants hasDarkBg={true} />
              <Element name="battle-attribute">
                {showAttribute && <AttributeSummary />}
              </Element>
            </StyledContent>
          </div>
        </StyledCover>
      </div>
    );
  }
  return (
    <div>
      <StyledCover imgUrl={urlImgCover}>
        <PageTitle title="Arena" />
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <PureSpinner size={50} />
            <span
              style={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}
              className="pl-2"
            >
              Fighting battle...
            </span>
          </div>
        )}
      </StyledCover>
      <div>
        <StyledContent>
          <Participants />
          <Element name="battle-attribute">
            {showAttribute && <AttributeSummary />}
          </Element>
        </StyledContent>
      </div>
    </div>
  );
};

export default BattlePoints;

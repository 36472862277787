import React, {
  useCallback,
  useState,
  Fragment,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row } from 'reactstrap';
import { get } from 'lodash';
import styled from 'styled-components';
//import * as paths from 'config/paths';
import { withRouter } from 'react-router-dom';
import {
  HedgiePower,
  Level,
  HedgieImg,
 // HedgiePurchaseWithPriceButtonNew,
  SubHeading,
  HedgieEnergy,
  GeneralEffects,
  PowerChangePopup,
  MyHedgieInfo,
  TooltipsModal,
} from 'components';
import Hedgie from 'models/Hedgie';
import { MyHedgiesContext } from '../../pages/NewMyHedgiesPage/MyHedgiesWrapper';


export const HedgieImgContainer = styled.div`
  padding-top: 60px;
  width: 350px;
  max-width: 100%;
  margin: auto;
  position: relative;
`;

const StyledPopUpExp = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
`;

const StyledLevel = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const StatusTooltip = styled.button`
  top: 20px;
  right: 123px;
`;

const StatusModalTitle = (
  <h2 className="ff-extra-bold fs-lg color-contrast text-center title-with-tooltip mt-4">
    Pending Transaction
  </h2>
);

const StatusModalBody = (
  <p className="lead ff-semi-bold mb-0 mt-2">
    If you paid with ether, it could take a little time for your payment to be
    confirmed. Don't worry, nobody can take this Hedgie while we are confirming
    it. If this does not change within 20 minutes, please let us know by
    emailing us at{' '}
    <a style={{ color: '#12dfc0' }} href="mailto:hedgie@hedgie.io">
      hedgie@hedgie.io
    </a>
    .
  </p>
);

function getHedgieStatus(hedgie) {
  return {
    isPurchased: Hedgie.isPurchased(hedgie),
    isReserved: Hedgie.isReserved(hedgie),
    isPending: Hedgie.isPending(hedgie),
  };
}

const SelectedHedgie = ({ history,  onCompleteEtherBuyNow }) => {
  const {selectedHedgie} = useContext(MyHedgiesContext);

  const { isPurchased, isReserved, isPending } = useMemo(
    () => getHedgieStatus(selectedHedgie),
    [selectedHedgie],
  );

  const [modal, setModal] = useState(false);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

 /*  const gotoPurchaseHedgie = useCallback(
    color => {
      history.push(`${paths.BOOKING_HEDGIE}/${color}`);
    },
    [history],
  ); */





  const currentXp = get(selectedHedgie, 'xp', 0);
  const currentLevel = get(selectedHedgie, 'currentLevel') || {
    level: 0,
    requiredXp: 0,
    points: 0,
  };
  const nextLevel = get(selectedHedgie, 'nextLevel', {
    level: 0,
    requiredXp: 0,
    points: 0,
  });

  if (!selectedHedgie) {
    return null;
  }

  return (
    <Fragment>
      <Row>
        <Col md="6">
          <HedgieImgContainer>
            <HedgieImg
              color={`#${selectedHedgie.color}`}
              className="img-fluid"
              width={350}
              imgURL={selectedHedgie.imgURL}
            />
            <StyledPopUpExp>
              <PowerChangePopup
                hedgieId={selectedHedgie.id}
                nextLevel={nextLevel}
              />
            </StyledPopUpExp>
            <StyledLevel>
              <Level
                xp={currentXp}
                currentLevel={currentLevel}
                nextLevel={nextLevel}
              />
            </StyledLevel>
          </HedgieImgContainer>
        </Col>
        <Col md="5">
          <div
            style={{
              marginTop: '62px',
              marginLeft: '38px',
              margin: 'auto',
            }}
          >
            <MyHedgieInfo isPurchased={isPurchased} {...selectedHedgie} />
            <SubHeading
              className="pt-4 sub-heading-center"
              heading={
                selectedHedgie.description ||
                selectedHedgie.tierInfo.description
              }
            />
            <HedgiePower hedgie={selectedHedgie} className="pt-5" />
            <HedgieEnergy
              className="pt-1"
              color={`#${selectedHedgie.color}`}
              energy={selectedHedgie.energy}
              recovery={selectedHedgie.energyRecovery}
            />
            <GeneralEffects hedgie={selectedHedgie} className="pt-3" />
          </div>

          {(isReserved ||(isPurchased && selectedHedgie.mintingStatus==="ready")) && (
           /*  <HedgiePurchaseWithPriceButtonNew
              color={selectedHedgie.color}
              onPurchaseHedgie={gotoPurchaseHedgie}
              // price={selectedHedgie.tierInfo.dollar}
              onCompleteEtherBuyNow={onCompleteEtherBuyNow}
              price={selectedHedgie}
              hedgie={selectedHedgie}
            /> */
            <></>
          )}
          {isPending && (
            <RelativeContainer>
              <SubHeading
                className="pt-5 sub-heading-center text-center ff-bold"
                heading="Awaiting payment confirmation..."
              />
              <StatusTooltip
                href=""
                onClick={toggleModal}
                className="c-tooltip"
              >
                ?
              </StatusTooltip>
            </RelativeContainer>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggleModal}>
        <TooltipsModal title={StatusModalTitle} body={StatusModalBody} />
        <p className="mt-4 btn-modal">
          <button
            onClick={toggleModal}
            className="road-sign-contrast extra-btn-big ff-extra-bold fs-extra-md read-more-btn dark-color title-arrow"
          >
            Gotcha!
          </button>
        </p>
      </Modal>
    </Fragment>
  );
};

SelectedHedgie.propTypes = {
  history: PropTypes.object,
};

SelectedHedgie.defaultProps = {
  history: null,
};

export default withRouter(SelectedHedgie);

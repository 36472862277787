import { useEffect } from 'react';

export default function(toggle) {
  useEffect(
    () => {
      document.body.style.overflow = toggle ? 'hidden' : 'visible';
      return () => (document.body.style.overflow = 'visible');
    },
    [toggle],
  );
}

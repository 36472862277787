import { put, call, fork, take } from 'redux-saga/effects';
import {
  GET_ALL_ANNOUNCEMENTS,
  GET_UNREAD_ANNOUNCEMENTS,
  getAllAnnouncementsFailure,
  getAllAnnouncementsSuccess,
  getUnreadAnnouncementsFailure,
  getUnreadAnnouncementsSuccess,
  MARK_AN_ANNOUNCEMENT_AS_READ,
  markAnAnnouncementAsReadFailure,
  markAnAnnouncementAsReadSuccess,
} from './actions';

export function* getAllAnnouncements(api, { limit }) {
  try {
    const response = yield call(
      [api, api.get],
      `/announcements?limit=${limit}`,
    );
    yield put(getAllAnnouncementsSuccess(response));
  } catch (e) {
    yield put(getAllAnnouncementsFailure(e));
  }
}
export function* getUnreadAnnouncements(api) {
  try {
    const response = yield call([api, api.get], '/users/me/announcements');
    yield put(getUnreadAnnouncementsSuccess(response));
  } catch (e) {
    yield put(getUnreadAnnouncementsFailure(e));
  }
}

export function* markAnAnnouncementAsRead(api, announcementId) {
  try {
    const response = yield call([api, api.post], '/users/me/announcements', {
      announcementID: announcementId,
    });

    yield put(markAnAnnouncementAsReadSuccess(response));
  } catch (e) {
    yield put(markAnAnnouncementAsReadFailure(e));
  }
}

export function* watchGetAllAnnouncements(api) {
  while (true) {
    const { payload } = yield take(GET_ALL_ANNOUNCEMENTS);
    yield call(getAllAnnouncements, api, payload);
  }
}
export function* watchGetUnreadAnnouncements(api) {
  while (true) {
    yield take(GET_UNREAD_ANNOUNCEMENTS);
    yield call(getUnreadAnnouncements, api);
  }
}
export function* watchMarkAnAnnouncementAsRead(api) {
  while (true) {
    const { payload } = yield take(MARK_AN_ANNOUNCEMENT_AS_READ);
    yield call(markAnAnnouncementAsRead, api, payload);
  }
}

export default function*({ api }) {
  yield fork(watchGetAllAnnouncements, api);
  yield fork(watchGetUnreadAnnouncements, api);
  yield fork(watchMarkAnAnnouncementAsRead, api);
}

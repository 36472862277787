import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';
import { Container, Row, Col } from 'reactstrap';
import * as paths from 'config/paths';
import { BookingConfirmation, Spinner, BookingPurchase } from 'components';
import Log from 'services/log';

class BookingPage extends Component {
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    this.props.getHedgie(match.params.hex);
  }

  componentDidUpdate(prevProps) {
    Log.debug(
      'booking page component did update',
      JSON.stringify({ prevProps, props: this.props }),
    );
    if (
      this.props.redeemSuccess ||
      this.props.purchaseSuccess ||
      this.props.purchaseWithEtherSuccess
    ) {
      this.props.history.push(paths.BOOKING_THANK_YOU);
    } else if (
      this.props.userIsReady !== prevProps.userIsReady &&
      this.props.userIsReady === true &&
      this.props.hedgie &&
      !this.props.bookingSuccess &&
      !this.props.errorBookingHedgie
    ) {
      Log.debug('calling on reserve');
      this.onReserve();
    } else if (
      this.props.userIsReady === true &&
      this.props.hedgie &&
      this.props.hedgie !== prevProps.hedgie &&
      !this.props.bookingSuccess &&
      !this.props.errorBookingHedgie
    ) {
      Log.debug('calling on reserve - after hedgie load');
      this.onReserve();
      Log.debug('Did update, but not handled', this.props);
    }
  }

  onReserve = () => {
    const { match } = this.props;
    const hexColor = match.params.hex;
    this.props.bookingHedgie(hexColor);
  };

  handleSubmitCC = submitParams => {
    const { hedgie } = this.props;
    const params = {
      ...submitParams,
      hedgieIds: [hedgie.id],
    };
    this.props.handlePurchaseHedgie(params);
  };

  handleSubmitGiftCode = submitParams => {
    const { hedgie } = this.props;
    const params = {
      ...submitParams,
      hedgieID: hedgie.id,
    };
    this.props.handleRedeemGiftCode(params);
  };

  handleSubmitEtherBuyNow = (submitParams = {}) => {
    const { hedgie } = this.props;
    const params = {
      ...submitParams,
      hedgieIds: [hedgie.id],
    };
    this.props.handlePurchaseWithEther(params);
  };

  render() {
    const {
      user,
      hedgie,
      tier,
      loading,
      errorPurchasingHedgie,
      errorRedeemingGiftCode,
      errorPurchasingWithEther,
      bookingSuccess,
      errorBookingHedgie,
    } = this.props;

    Log.debug('Booking page render', { errorPurchasingWithEther });

    return (
      <div>
        <main>
          <Container fluid>
            {loading && <Spinner />}
            {!bookingSuccess &&
              !errorBookingHedgie && (
                <Container>
                  <Row className="my-5">
                    <Col>
                      <Spinner />
                    </Col>
                  </Row>
                </Container>
              )}
            {!bookingSuccess &&
              errorBookingHedgie && (
                <Container>
                  <Row className="my-5">
                    <Col>
                      <p>Sorry, there was an error intializing the booking.</p>
                      <p>{errorBookingHedgie.message}</p>
                    </Col>
                  </Row>
                </Container>
              )}
            {bookingSuccess &&
              hedgie &&
              tier && (
                <React.Fragment>
                  <BookingConfirmation
                    hedgie={hedgie}
                    tier={tier}
                    onReserve={this.onReserve}
                  />
                  <BookingPurchase
                    user={user}
                    hedgie={hedgie}
                    tier={tier}
                    working={loading}
                    purchaseError={errorPurchasingHedgie}
                    purchaseWithEtherError={errorPurchasingWithEther}
                    redeemError={errorRedeemingGiftCode}
                    onSubmitCC={this.handleSubmitCC}
                    onSubmitGiftCode={this.handleSubmitGiftCode}
                    onCompleteEtherBuyNow={this.handleSubmitEtherBuyNow}
                  />
                </React.Fragment>
              )}
          </Container>
        </main>
      </div>
    );
  }
}

BookingPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  hedgie: types.hedgie,
  tier: types.tier,

  bookingSuccess: PropTypes.bool,
  purchaseSuccess: PropTypes.bool,
  purchaseWithEtherSuccess: PropTypes.bool,
  redeemSuccess: PropTypes.bool,
  getSuccess: PropTypes.bool,

  errorBookingHedgie: types.error,
  errorGettingHedgie: types.error,
  errorPurchasingHedgie: types.error,
  errorPurchasingWithEther: types.error,

  errorRedeemingGiftCode: types.error,

  loading: PropTypes.bool.isRequired,
  user: types.user,
  userIsReady: PropTypes.bool.isRequired,

  getHedgie: PropTypes.func.isRequired,
  bookingHedgie: PropTypes.func.isRequired,
  handlePurchaseHedgie: PropTypes.func.isRequired,
  handleRedeemGiftCode: PropTypes.func.isRequired,
  handlePurchaseWithEther: PropTypes.func.isRequired,
};

BookingPage.defaultProps = {
  hedgie: null,
  tier: null,
  bookingSuccess: false,
  purchaseSuccess: false,
  purchaseWithEtherSuccess: false,
  redeemSuccess: false,
  getSuccess: false,
  errorPurchasingHedgie: null,
  errorBookingHedgie: null,
  errorGettingHedgie: null,
  errorRedeemingGiftCode: null,
  errorPurchasingWithEther: null,
  user: null,
  working: false,
};

export default BookingPage;

import styled from 'styled-components';

import noSelectionImage from '../../../images/myhedgies-no-selection.png';
import { media } from '../../../styles/main';

export const HedgieListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const hedgieListSizeWidth = 161;
// const hedgieListSizeHeight = 161;

export const HedgieListItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  margin: 10px auto 0 auto;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 10px 4px rgba(0,0,0,0.15);
  border-radius: 50%;
  border: ${props =>
    props.disabled ? '4px solid rgba(211,211,211,1)' : undefined};
  background-image: ${props =>
    props.active
      ? 'linear-gradient(white, white), radial-gradient(circle at top center, #CA8314 0%, #F7E890 100%)'
      : undefined};
  background-origin: ${props => (props.active ? 'border-box' : undefined)};
  background-clip: ${props =>
    props.active ? 'content-box, border-box' : undefined};
    
  ${media.largeDesktop`width: 160px;height: 160px;
    border: ${props => (props.active ? 'double 5px transparent' : undefined)};
  `}
  ${media.desktop`width: 140px;height: 140px;
    border: ${props => (props.active ? 'double 5px transparent' : undefined)};
  `}
  ${media.tablet`width: 120px;height: 120px;
    border: ${props => (props.active ? 'double 4px transparent' : undefined)};
  `}
  ${media.phone`width: 80px;height: 80px;
    border: ${props => (props.active ? 'double 3px transparent' : undefined)};
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.2);
  `}
`;

export const EmptyHedgieListItemContainer = styled(HedgieListItemContainer)`
  background-image: url(${noSelectionImage});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
`;

export const HedgieListItemLink = styled.button`
  align-self: center;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  max-width: 80%;
  text-align: center;
  outline: 0 !important;
  cursor: pointer;
  color: white;
  margin-top: 32px;
  font-size: 13px;
`;

import { join, trimEnd, isEmpty } from 'lodash';

export const removeLastCharacter = str => {
  if (!str) {
    return '';
  }
  if (str[str.length - 1] === '/') return str.slice(0, -1);
  return str;
};

export const assembleTextWithComma = textArray => {
  if (isEmpty(textArray)) {
    return '';
  }

  const SPACE = ' ';
  const additionCharacter = `,${SPACE}`;

  const fullMapText = textArray.filter(text => !!text);

  if (fullMapText.length === 0) {
    return '';
  }

  if (fullMapText.length === 1) {
    return fullMapText[0];
  }
  const fullText = join(fullMapText, additionCharacter);
  return trimEnd(fullText, additionCharacter);
};

import { LOCATION_CHANGE } from 'connected-react-router';
import { concat } from 'lodash';
import { initialState } from './selectors';
import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  UPDATE_USER_WALLET_REQUEST,
  UPDATE_USER_WALLET_SUCCESS,
  UPDATE_USER_WALLET_FAILURE,
  GET_CURIO_TRANSACTIONS_REQUEST,
  GET_CURIO_TRANSACTIONS_SUCCESS,
  GET_CURIO_TRANSACTIONS_FAILURE,
  UPDATE_USER_NAME_FAILURE,
  UPDATE_USER_NAME,
  UPDATE_USER_NAME_SUCCESS,
  TRANSFER_CURIO_FAILURE,
  TRANSFER_CURIO_SUCCESS,
  TOGGLE_MODAL,
  REQUEST_OTP_CODE_SUCCESS,
  REQUEST_OTP_CODE_FAILURE,
  VERIFY_EMAIL_ADDRESS_FAILURE,
  VERIFY_EMAIL_ADDRESS_SUCCESS,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
      };
    }
    case GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        errorGettingUser: false,
      };
    case GET_CURRENT_USER_SUCCESS: {
      const currentUser = payload.data;
      return {
        ...state,
        currentUser,
        errorGettingUser: null,
        loading: false,
      };
    }
    case GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        currentUser: null,
        errorGettingUser: payload,
        loading: false,
      };
    case UPDATE_USER_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_WALLET_SUCCESS: {
      const currentUser = payload.data;
      return {
        ...state,
        currentUser,
        errorUpdatingWallet: null,
        loading: false,
      };
    }
    case UPDATE_USER_WALLET_FAILURE:
      return {
        ...state,
        currentUser: null,
        errorUpdatingWallet: payload,
        loading: false,
      };
    case GET_CURIO_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CURIO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        curioTransactions: {
          ...payload,
          data: payload.isLoadMore
            ? concat(state.curioTransactions.data, payload.data)
            : payload.data,
        },
        loading: false,
      };
    case GET_CURIO_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_NAME:
      return {
        ...state,
        updateUsernameLoading: true,
      };
    case UPDATE_USER_NAME_SUCCESS:
      return {
        ...state,
        updateUsernameLoading: false,
      };
    case UPDATE_USER_NAME_FAILURE:
      return {
        ...state,
        updateUsernameLoading: false,
        updateUsernameError: {
          error: true,
          message: payload.message,
        },
      };

    case TRANSFER_CURIO_SUCCESS:
      return {
        ...state,
        curioTransactions: {
          ...state.curioTransactions,
          data: concat(payload.data, state.curioTransactions.data),
        },
      };

    case TRANSFER_CURIO_FAILURE:
      return {
        ...state,
        transferredCurioError: {
          error: true,
          message: payload.message || 'Something went wrong!',
        },
      };

    case TOGGLE_MODAL:
      return state.transferredCurioError.error
        ? {
          ...state,
          isOpenTransferModal: payload,
          transferredCurioError: {
            error: false,
            message: '',
          },
        }
        : {
          ...state,
          isOpenTransferModal: payload,
        };
    case REQUEST_OTP_CODE_FAILURE:
      return {
        ...state,
        verifyingEmailError: {
          by_requesting_otp: true,
          by_verifying_otp: false,
          message: payload.message || 'Something went wrong!',
        }
      };

    case REQUEST_OTP_CODE_SUCCESS:
      return {
        ...state,
        requestOtpSuccess: true,
      };

    case VERIFY_EMAIL_ADDRESS_FAILURE:
      return {
        ...state,
        verifyingEmailError: {
          by_requesting_otp: false,
          by_verifying_otp: true,
          message: payload.message || 'Something went wrong!',
        }
      };
    case VERIFY_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        verifyingEmailSucesss: true,
      };

    default:
      return state;
  }
};

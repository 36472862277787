import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import MyBusinessBlock from './MyBusinessBlock';

const StyledGrid = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  justify-items: center;
  align-items: start;
  grid-gap: 20px;
  padding: 20px;
`;

const MyBusinessesList = ({ myBusinesses }) => {
  if (isEmpty(myBusinesses)) {
    return null;
  }
  return (
    <StyledGrid>
      {myBusinesses.map(business => (
        <MyBusinessBlock business={business} key={business.id} />
      ))}
    </StyledGrid>
  );
};

MyBusinessesList.propTypes = {
  myBusinesses: PropTypes.array.isRequired,
};

export default MyBusinessesList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as types from 'types';
import styled from 'styled-components';
import { SignInForm, PageTitle, Button, BoldSpan } from 'components';
// import { SignInForm, PageTitle, Button } from 'components';
import * as paths from 'config/paths';
import { palette } from 'styled-theme';


const SquareBlock = styled.div`
  background-color: ${palette(1)};
  width: 75px;
  height: 75px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  &:focus {
    outline: none;
  }
`;


SquareBlock.defaultProps = {
  palette: 'primary',
};

const CenterCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;


class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.signinSuccess !== prevProps.signinSuccess &&
      this.props.signinSuccess === true
    ) {
      const { from } = this.props.location.state || {
        from: { pathname: paths.HOME },
      };
      this.props.history.push(from);
    }
  }

  handleSignIn = (email, password) => {
    this.props.signin(email, password);
  };

  LinkedButton = props => {
    const to = {
      pathname: props.to,
    };

    if (this.props.location.state && this.props.location.state.from) {
      to.state = {
        from: this.props.location.state.from,
      };
    }

    return (
      <Link to={to}>
        <Button size={props.size}>{props.children}</Button>
      </Link>
    );
  };

  render() {
    const { error } = this.props;
    return (
      <div>
        <main style={{ marginBottom: 100 }}>
          <Container>
            <Row>
              <Col>
                <PageTitle title="Sign In" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <SignInForm onSignIn={this.handleSignIn} error={error} />
              </Col>

              <Col xs="12" md="4">
                <CenterCol>
                  <SquareBlock>
                    <BoldSpan>OR</BoldSpan>
                  </SquareBlock>
                </CenterCol>
              </Col>
              <Col xs="12" md="4" className="mt-5 mt-md-0">
                <h3 className="text-center text-md-left">
                  Don’t have an account?
                </h3>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <this.LinkedButton size="large" to={paths.SIGNUP}>
                    Sign Up
                  </this.LinkedButton>
                </div>
              </Col>


            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

SignInPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  signinFailed: PropTypes.bool.isRequired,
  signinSuccess: PropTypes.bool.isRequired,
  signin: PropTypes.func.isRequired,
  error: types.error,
};

SignInPage.defaultProps = {
  error: null,
};

export default SignInPage;

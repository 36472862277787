import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from 'store/configure';
import api from 'services/api';
import firebase from 'services/firebase';
import App from 'components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/main.scss';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_DEBUG_LOG) {
  localStorage.setItem('debug', process.env.REACT_APP_DEBUG_LOG);
}

export const apiInstance = api.create();

const store = configureStore({}, { api: apiInstance, firebase });

const renderApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

const root = document.getElementById('app');
render(renderApp(), root);

serviceWorker.unregister();

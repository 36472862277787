import firebase from 'services/firebase';
import { sessionService } from 'redux-react-session';
import useEffectOnce from './useEffectOnce';
import { forceRefreshToken } from '../../services/firebase/auth';

export default (firebaseAuthChange) => {
  useEffectOnce(() => {
    firebase.firebase.auth.onAuthStateChanged(async (authUser) => {
      const { currentUser } = firebase.firebase.auth;
      if (currentUser) {
        const idToken = await forceRefreshToken();

        await sessionService.saveSession({
          idToken,
          refreshToken: currentUser.refreshToken,
        });
        firebaseAuthChange(true);
      } else {
        await sessionService.deleteSession();
        await sessionService.deleteUser();
        firebaseAuthChange(false);
      }
    });
  });
};

import { hot } from 'react-hot-loader/root';
import React, { lazy, Suspense, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@xstyled/styled-components';
import { fromAuth } from 'store/selectors';
import {
  firebaseAuthSuccess,
  firebaseAuthFailure,
  getCurrentUserRequest,
  getResourcesMeta,
  authWebsocket,
} from 'store/actions';
// import { apiInstance } from 'services/api';
import Log from 'services/log';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './themes/default';
import Routes from './routes';
import CenterSpinner from '../components/stateless/Spinner/CenterSpinner';
import StripeContext from '../containers/StripeContext';
import useAuthStateChanged from './custom-hooks/useAuthStateChanged';
import { WebSocketProvider } from '../contexts/websocket-context';
import Modal from '../containers/Modal'

const Header = lazy(() => import('../containers/Header'));
const PresaleFooter = lazy(() =>
  import('../components/stateless/PresaleFooter'),
);



const App = ({
  getResourcesMeta,
  authenticated,
  checked,
  userIsReady,
  firebaseAuthChange,
  getCurrentUser,
}) => {
  useAuthStateChanged(firebaseAuthChange);

  useEffect(() => {
    getResourcesMeta();
  }, [getResourcesMeta]);

  useEffect(() => {
    if (userIsReady) {
      getCurrentUser();
    }
  }, [getCurrentUser, userIsReady]);

  sessionService.refreshFromLocalStorage();

  return (
    <div>
      <StripeContext>
        <div>
          {checked ? (
            <div className="primary-content">
              <Suspense fallback={<CenterSpinner />}>
                <Header />
              </Suspense>
              <Suspense fallback={<CenterSpinner />}>
                <div className="body-content">
                  <ThemeProvider theme={theme}>
                    <WebSocketProvider isReadyToConnectWebsocket={userIsReady}>
                      <Routes
                        authenticated={authenticated}
                        userIsReady={userIsReady}
                      />
                    </WebSocketProvider>
                  </ThemeProvider>
                </div>
                <PresaleFooter />
              </Suspense>
            </div>
          ) : null}
        </div>
      </StripeContext>
      <ToastContainer
        autoClose={6000}
        closeOnClick
        hideProgressBar
        pauseOnHover
        toastClassName="toast-message"
      />
      <Modal></Modal>
    </div>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  firebaseAuthChange: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  userIsReady: PropTypes.bool.isRequired,
  getResourcesMeta: PropTypes.func,
  authWebsocket: PropTypes.func,
};

App.defaultProps = {
  getResourcesMeta: null,
  authWebsocket: null,
};

const mapStateToProps = (state) => ({
  checked: state.session.checked,
  authenticated: state.session.authenticated,
  userIsReady: !!state.session.user.id && fromAuth.firebaseAuthSuccess(state),
});

const mapDispatchToProps = (dispatch) => ({
  firebaseAuthChange: (status) => {
    Log.debug(status, 'firebaseAuthChange');
    if (status === true) {
      dispatch(firebaseAuthSuccess());
    } else {
      dispatch(firebaseAuthFailure());
    }
  },
  getCurrentUser: () => dispatch(getCurrentUserRequest()),
  getResourcesMeta: () => dispatch(getResourcesMeta()),
  authWebsocket: () => dispatch(authWebsocket()),
});

export default hot(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App)),
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dot from './Dot';

const StyledPaginationContainer = styled.div`
  margin-top: 10px;
  //display: flex;
  //justify-content: center;
  //align-content: center;
`;

const StyledPagination = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.max}, 20px);
  grid-gap: 8px;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

const Pagination = ({ max, currentIndex, onSelect }) => {
  const items = Array(max).fill(1);
  return (
    <StyledPaginationContainer>
      <StyledPagination max={max}>
        {items.map((item, index) => {
          return (
            <Dot
              key={index}
              active={currentIndex === index}
              onClick={() => onSelect(index)}
            />
          );
        })}
      </StyledPagination>
    </StyledPaginationContainer>
  );
};

Pagination.propTypes = {
  max: PropTypes.number,
  currentIndex: PropTypes.number,
  onSelect: PropTypes.func,
};

Pagination.defaultProps = {
  max: 0,
  currentIndex: 0,
  onSelect: null,
};

export default Pagination;

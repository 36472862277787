import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const modalRoot = document.getElementById('warning');

const StyledWarningPopup = styled.div`
  background-color: #ffeed0;
  //border: 1px solid #f9bd57;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .wn-icon-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .wn-content {
    font-size: 14px;
    color: #573a08;
  }
`;
const StyledButtons = styled.div`
  padding: 10px;
  button {
    margin: 0 4px;
  }
`;

const NotificationTurnOffWarning = ({ children, openModal, ignoreWarning }) => {
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(
    () => {
      modalRoot.appendChild(el);
      return () => {
        modalRoot.removeChild(el);
      };
    },
    [el],
  );

  const closeCallback = useCallback(
    () => {
      ignoreWarning();
    },
    [ignoreWarning],
  );

  return createPortal(
    <StyledWarningPopup>
      <div className="wn-content">{children}</div>
      <StyledButtons>
        <Button onClick={openModal} color="info" size="sm">
          Turn on
        </Button>
        <Button color="secondary" size="sm" onClick={closeCallback}>
          Later
        </Button>
      </StyledButtons>
    </StyledWarningPopup>,
    el,
  );
};

NotificationTurnOffWarning.propTypes = {
  children: PropTypes.any.isRequired,
  openModal: PropTypes.func,
  ignoreWarning: PropTypes.func,
};

NotificationTurnOffWarning.defaultProps = {
  openModal: null,
  ignoreWarning: null,
};

export default React.memo(NotificationTurnOffWarning);

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  faLink,
  faMapMarkerAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ProgressiveImageLoader, Typography, IconAndText } from 'components';
import styled from 'styled-components';
import { media } from 'styles/main';
import { assembleTextWithComma } from 'helper/string';
import withResponsive from 'helper/withResponsive';

const StyledInfo = styled.div`
  display: grid;
  grid-template-columns: 180px auto;
  ${media.phone`
    grid-template-columns: 1fr;
    justify-items: center;
    align-items:center;
  `};
  align-items: end;
  width: 100%;
  margin-top: -70px;
  ${media.phone`
    margin-top: -80px;
  `};
`;
const StyledExtraInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.phone``}
`;

const StyledContainer = styled.div``;

const StyledSiteAndPhone = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledImage = styled.div`
  border-radius: 30px;
  width: 180px;
  height: 180px;
  overflow: hidden;
  ${media.phone`
    border-radius: 26px;
    width: 150px;
    height: 150px;
  `}
`;

const StyledRightSide = styled.div`
  width: 100%;
  padding-left: 10px;
`;

const CompanyInfo = ({
  avatarUrl,
  name,
  website,
  phoneNumber,
  description,
  address,
  city,
  country,
  isMobile,
}) => {
  const companyLocation = useMemo(
    () => {
      return assembleTextWithComma([address, city, country]);
    },
    [address, city, country],
  );

  return (
    <StyledContainer>
      <StyledInfo>
        <StyledImage>
          <ProgressiveImageLoader
            imgUrl={avatarUrl}
            width={isMobile ? 150 : 180}
            height={isMobile ? 150 : 180}
            placeHolderUrl={avatarUrl}
          />
        </StyledImage>
        <StyledRightSide>
          <Typography
            align={isMobile ? 'center' : 'left'}
            text={name}
            fontSize={16}
            className="pb-3"
          />
          <StyledExtraInfo>
            {companyLocation ? (
              <div>
                <IconAndText text={companyLocation} icon={faMapMarkerAlt} />
              </div>
            ) : null}
            <StyledSiteAndPhone>
              {website ? (
                <IconAndText text="Website" icon={faLink} link={website} />
              ) : null}
              {phoneNumber ? (
                <IconAndText
                  text={phoneNumber}
                  icon={faMobileAlt}
                  className="pl-3"
                />
              ) : null}
            </StyledSiteAndPhone>
          </StyledExtraInfo>
        </StyledRightSide>
      </StyledInfo>
      {description ? (
        <Typography
          text={description}
          fontSize={16}
          align="left"
          className="pt-4 pl-2 pr-2"
        />
      ) : null}
    </StyledContainer>
  );
};

CompanyInfo.propTypes = {
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  phoneNumber: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  isMobile: PropTypes.bool,
};

CompanyInfo.defaultProps = {
  avatarUrl: '',
  name: '',
  website: '',
  phoneNumber: '',
  description: '',
  address: '',
  city: '',
  country: 'null',
  isMobile: false,
};

export default React.memo(withResponsive(CompanyInfo));

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ItemImage,
  HedgieButton,
  Spinner,
  FormError,
  Typography,
} from 'components';
import useCheckingUpgradable from './useCheckingUpgradable';
import { getIncrementalEnergy, StyledIncrementalEnergy } from './Furniture';

const StyledResourcesMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
  grid-gap: 10px;
  justify-content: center;
  justify-items: center;
  padding: 0 20px;
`;

const BuildingFurnitureModal = ({
  selectedFurniture,
  buildFurniture,
  houseId,
  loading,
  error,
}) => {
  const clickCallback = useCallback(
    () => {
      buildFurniture(selectedFurniture, houseId);
    },
    [buildFurniture, selectedFurniture, houseId],
  );

  const { requiredResources, hasEnoughResources } = useCheckingUpgradable(
    selectedFurniture,
  );

  const { currentEffectValue, effectValue } = selectedFurniture;

  const incrementalEnergy = useMemo(
    () => getIncrementalEnergy(currentEffectValue, effectValue),
    [currentEffectValue, effectValue],
  );

  return (
    <div className="pt-4 pb-3">
      <div className="align-center">
        <ItemImage
          width={180}
          item={{ imgURL: selectedFurniture.imgURL }}
          insideCircle={false}
          hasHover={false}
        />
      </div>
      <Typography
        text={selectedFurniture.name}
        align="center"
        className="pt-2 mb-0"
        isBold
        fontSize={16}
      />
      <Typography
        text={`Level ${selectedFurniture.level}`}
        align="center"
        className="mb-0 pt-1"
        fontSize={14}
      />
      <Typography
        text={`Energy Recovery: ${effectValue}`}
        align="center"
        className="mb-0 pt-1"
        fontSize={14}
      >
        <StyledIncrementalEnergy>
          {`(+${incrementalEnergy})`}
        </StyledIncrementalEnergy>
      </Typography>
      <StyledResourcesMobile className="pt-3 pb-3">
        {requiredResources.map(rs => (
          <ItemImage
            key={rs.name}
            width={50}
            item={rs}
            label={`x${rs.amount}`}
          />
        ))}
      </StyledResourcesMobile>
      {!hasEnoughResources ? (
        <Typography
          fontSize={14}
          text="You don’t have enough Resources to build this"
        />
      ) : (
        <div className="align-center mt-2 pl-3 pr-3">
          <HedgieButton onClick={clickCallback} className="furniture-btn" full>
            Upgrade
          </HedgieButton>
        </div>
      )}
      <div>
        {loading ? <Spinner noFixed /> : null}
        {error.error ? (
          <div className="align-center">
            <FormError error={error} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BuildingFurnitureModal.propTypes = {
  selectedFurniture: PropTypes.object,
  buildFurniture: PropTypes.func,
  houseId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
};

BuildingFurnitureModal.defaultProps = {
  selectedFurniture: null,
  buildFurniture: null,
  houseId: '',
  loading: false,
  error: {
    error: false,
    message: '',
  },
};

export default BuildingFurnitureModal;

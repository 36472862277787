import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { trackClickingJourneyButton } from 'tracking';
import * as paths from 'config/paths';
import { GradientButton, FormErrorMessage } from 'components';
import { MyHedgiesContext } from '../../pages/NewMyHedgiesPage/MyHedgiesWrapper';
import useCheckingInProgress from '../../pages/NewMyHedgiesPage/useCheckingInProgress';

const StartJourneyButton = () => {
  const {selectedHedgie} = useContext(MyHedgiesContext);
  const history = useHistory();

  const handleStartingJourney = useCallback(() => {
    history.push({
      pathname: paths.JOURNEY,
      state: { hedgieId: selectedHedgie.id },
    });
    trackClickingJourneyButton();
  }, [history, selectedHedgie]);

  const {
    isJourneyInProgress,
    isBattleInProgress,
    isNotEnoughEnergy,
  } = useCheckingInProgress(selectedHedgie);

  const isJourneyDisabled =
    isJourneyInProgress || isBattleInProgress || isNotEnoughEnergy;

  return selectedHedgie ? (
    <div>
      <GradientButton
        disabled={isJourneyDisabled}
        label="Start Journey!"
        onClick={isJourneyDisabled ? null : handleStartingJourney}
      />
      {isJourneyInProgress ? (
        <div className="pt-2" style={{ textAlign: 'center' }}>
          Journey In Progress
        </div>
      ) : null}
      {!isJourneyInProgress && isNotEnoughEnergy ? (
        <FormErrorMessage
          fontSize={14}
          error="I'm too tired to travel"
          align="center"
        />
      ) : null}
    </div>
  ) : null;
};

export default StartJourneyButton;

import { LOCATION_CHANGE } from 'connected-react-router';
import { initialState } from './selectors';
import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  SIGNOUT_REQUEST,
  SIGNOUT_SUCCESS,
  SIGNOUT_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  CLEAR_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  FIREBASE_AUTH_SUCCESS,
  FIREBASE_AUTH_FAILURE,
  CLOSE_REFERRAL_CODE_MODAL,
} from './actions';

import { firebaseAuthErrorBuilder } from './errors';
import {
  RESOURCE_CREATE_FAILURE,
  RESOURCE_DETAIL_READ_FAILURE,
} from '../resource/actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        error: null,
        working: false,
        updatePasswordStatus: null,
        forgotPasswordStatus: null,
        resetPasswordStatus: null,
      };
    }
    case SIGNIN_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        signinSuccess: false,
        signinFailed: false,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        signinSuccess: true,
        signinFailed: false,
        error: null,
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        signinFailed: true,
        signinSuccess: false,
      };
    case RESOURCE_DETAIL_READ_FAILURE:
      return {
        ...state,
        error: payload,
        signinFailed: true,
        signinSuccess: false,
      };
    case SIGNOUT_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        signoutSuccess: false,
        signoutFailed: false,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        error: null,
        signoutSuccess: true,
        signoutFailed: false,
      };
    case SIGNOUT_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        signoutFailed: true,
        signoutSuccess: false,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        signUpFailed: false,
        signUpSuccess: false,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        error: null,
        working: false,
        signUpFailed: false,
        signUpSuccess: true,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        working: false,
        signUpFailed: true,
        signUpSuccess: false,
      };
    case RESOURCE_CREATE_FAILURE:
      return {
        ...state,
        error: payload,
        working: false,
        signUpFailed: true,
        signUpSuccess: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        updatePasswordStatus: null,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        working: false,
        updatePasswordStatus: true,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        working: false,
        updatePasswordStatus: false,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        forgotPasswordStatus: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        working: false,
        forgotPasswordStatus: true,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        working: false,
        forgotPasswordStatus: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        working: true,
        resetPasswordStatus: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        working: false,
        resetPasswordStatus: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: firebaseAuthErrorBuilder(payload),
        working: false,
        resetPasswordStatus: false,
      };
    case FIREBASE_AUTH_SUCCESS:
      return {
        ...state,
        firebaseAuthSuccess: true,
        firebaseAuthFailure: false,
      };
    case FIREBASE_AUTH_FAILURE:
      return {
        ...state,
        firebaseAuthSuccess: false,
        firebaseAuthFailure: true,
      };

    case CLOSE_REFERRAL_CODE_MODAL:
      return {
        ...state,
        signUpSuccess: false,
      };

    default:
      return state;
  }
};

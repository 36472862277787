import styled from 'styled-components';

const PlainButton = styled.button`
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  z-index: 100;
  &:focus {
    outline: none;
  }
`;

export default PlainButton;

import React from 'react';
import styled from 'styled-components';

const StyledPoint = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #accacc;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid #accacc;
  padding: 5px 0;
  min-height: 64px;
  border-radius: ${(p) => (p.isMobile ? '27px' : 0)};
  background-color: ${(p) =>
        p.isHighlight ? '#12DFC0' : p.isMobile ? 'rgba(0,0,0,.4)' : '#fff'};
  .point-name {
    color: ${(p) => (p.isMobile ? '#fff' : '#000')};
  }
`;

function genTextPoint(point) {
    if (point <= 0) {
        return '';
    }
    if (point === 1) {
        return `+${point} point`;
    }
    return `+${point} points`;
}

const Point = ({
    id,
    ownerPoint,
    opponentPoint,
    iconUrl,
    text,
    isMobile,
    className,
}) => {
    return (
        <StyledPoint
            isHighlight={id === 'energy_points'}
            isMobile={isMobile}
            className={className}
        >
            <div className="point-name">{genTextPoint(ownerPoint)}</div>
            <div>
                {text ? (
                    <span className="point-name">{text}</span>
                ) : (
                        <img width={40} src={iconUrl} alt="point" />
                    )}
            </div>
            <div className="point-name">{genTextPoint(opponentPoint)}</div>
        </StyledPoint>
    );
};

export default Point;
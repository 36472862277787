import styled from '@xstyled/styled-components';

export const StyledBranch = styled.span`
  a {
    font-weight: 800;
    font-size: 34px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    padding-left: 4rem;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
`;

export const StyledRightCorner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

import React, { memo } from 'react';
import { Input, FormFeedback } from 'reactstrap';

const CustomInput = memo(
  ({ field, form: { touched, errors }, hasIcon, ...props }) => {
    return (
      <div>
        <Input
          {...field}
          {...props}
          invalid={touched[field.name] && !!errors[field.name]}
          valid={
            !!field.value &&
            touched[field.name] &&
            !errors[field.name] &&
            hasIcon
          }
        />
        {touched[field.name] && errors[field.name] && (
          <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
      </div>
    );
  },
);

CustomInput.defaultProps = {
  hasIcon: true,
};

export default CustomInput;

import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, HedgieImg } from 'components';
import { isEmpty, omit } from 'lodash';
import {
  StyledBgContainer,
  StyledHouseBg,
  StyledHedgieIcon,
  StyledDirtyHouseContainer,
} from './style';
import FurnitureImage from './FurnitureImage';
import { FURNITURE_POSITIONS } from '../../../const/hedgieHouse';
import PageTitle from '../../stateless/PageTitle';
import { useSelector } from 'react-redux';
import { selectCleanedHouse } from '../../../store/hedgieHouse/selectors';

const dirtyHouseImgLevel1 = 'https://dcjpkt8n82lcg.cloudfront.net/houses/House-dirt-layer_01.png';
const dirtyHouseImgLevel2 = 'https://dcjpkt8n82lcg.cloudfront.net/houses/House-dirt-layer_02.png';

const House = ({
  furniture,
  loadingBackground,
  loadedBgHouse,
  onClickHedgieIcon,
  health,
}) => {
  const { data } = furniture;
  let mapFurnitureInfo = [];

  const cleaned = useSelector(state => selectCleanedHouse(state));

  if (!isEmpty(data)) {
    mapFurnitureInfo = data
      .map(fur => omit(fur, 'upgradeTo'))
      .map(fur => ({
        ...fur,
        ...FURNITURE_POSITIONS[fur.type],
      }));
  }
  return (
    <StyledBgContainer
      loaded={loadingBackground.loaded}
      src={loadingBackground.imgUrl}
    >
      <PageTitle title="Hedgie House" />
      {!loadedBgHouse.loaded ? (
        <Spinner noFixed />
      ) : (
          <StyledHouseBg url={loadedBgHouse.imgUrl}>
            {!cleaned ? (
              <StyledDirtyHouseContainer>
                <img src={health > 50 ? dirtyHouseImgLevel1 : dirtyHouseImgLevel2} alt="dirty" />
              </StyledDirtyHouseContainer>
            ) : null}
            {mapFurnitureInfo.map(furniture => (
              <FurnitureImage key={furniture.id} {...furniture} />
            ))}
            <StyledHedgieIcon onClick={onClickHedgieIcon}>
              <div className="wrapper-icon">
                <HedgieImg color="#62C036" />
              </div>
            </StyledHedgieIcon>
          </StyledHouseBg>
        )}
    </StyledBgContainer>
  );
};

House.propTypes = {
  furniture: PropTypes.object.isRequired,
  loadingBackground: PropTypes.object.isRequired,
  loadedBgHouse: PropTypes.object.isRequired,
  health: PropTypes.number.isRequired,
  onClickHedgieIcon: PropTypes.func,
};

House.defaultProps = {
  health: 100,
  onClickHedgieIcon: null,
};

export default House;

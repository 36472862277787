import { useEffect } from 'react';
import {
  blockBrowser,
  getNotificationReference,
  registerADevice,
} from '../../../store/notification/actions';
import useLocalStorage from '../../custom-hooks/useLocalStorage';
import { askForPermission } from './notification-services';
import { isSupportedNotification } from '../../../services/firebase/firebase';

export default (userIsReady, dispatch) => {
  const [deviceTokenStorage, setDeviceTokenStorage] = useLocalStorage(
    'deviceToken',
    '',
  );
  useEffect(
    () => {
      if (userIsReady) {
        dispatch(getNotificationReference());
      }
    },
    [dispatch, userIsReady],
  );

  useEffect(
    () => {
      if (userIsReady) {
        askForPermission()
          .then(currentToken => {
            if (!deviceTokenStorage || currentToken !== deviceTokenStorage) {
              dispatch(
                registerADevice({
                  platform: 'web',
                  deviceToken: currentToken,
                  prevToken: deviceTokenStorage || '',
                }),
              );
              setDeviceTokenStorage(currentToken);
            }
          })
          .catch(err => {
            if (isSupportedNotification) {
              dispatch(blockBrowser(true));
            }
          });
      }
    },
    // eslint-disable-next-line
    [dispatch, userIsReady],
  );
};

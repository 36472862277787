import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { brandIcons } from 'icons';
import { HedgieSocialIcon, Button } from 'components';
import * as paths from 'config/paths';
import { MOBILE_PRIVATE_MENUS, MOBILE_PUBLIC_MENUS } from 'config/menus';
import Notification from '../../../containers/Notification';
import MenuBarsIcon from './MenuBarsIcon';
import CurioBalance from './CurioBalance';
import { loggedInCls } from './index';
import { StyledBranch, StyledRightCorner } from './commonStyles';
import Menu from './Menu';

const MobileNav = ({
  email,
  curioBalance,
  authenticated,
  toggleNavbar,
  collapsed,
}) => {
  const className = useMemo(() => loggedInCls(authenticated), [authenticated]);
  return (
    <Navbar className={className} dark expand="lg">
      <NavbarBrand tag={Link} to="/">
        <StyledBranch>Hedgie</StyledBranch>
      </NavbarBrand>
      <StyledRightCorner>
        <Notification />
        <MenuBarsIcon onClick={toggleNavbar} className="mr-2" />
      </StyledRightCorner>
      <Collapse isOpen={!collapsed} className="dark-bgr" navbar>
        {authenticated && (
          <Nav className="mobileUserMenuNav" navbar>
            <div className="mobileUserMenu">
              <NavItem className="navEmail">{email}</NavItem>
              <CurioBalance balance={curioBalance} onClick={toggleNavbar} />
              {MOBILE_PRIVATE_MENUS.map(menu => (
                <Menu key={menu.id} onClick={toggleNavbar} {...menu} />
              ))}
            </div>
          </Nav>
        )}
        <Nav onClick={toggleNavbar} onSelect={toggleNavbar} navbar>
          {!authenticated && (
            <NavItem
              style={{ paddingTop: '30px', paddingBottom: '30px' }}
              className="sign-in"
            >
              <Button
                tag={Link}
                size="menu"
                className="nav-link"
                to={paths.SIGNIN}
              >
                Sign In
              </Button>
            </NavItem>
          )}
          {MOBILE_PUBLIC_MENUS.map(menu => (
            <Menu key={menu.id} {...menu} />
          ))}
        </Nav>
        <Row>
          <Col md="6" className="flex-vcenter social-column mb-1">
            <div className="soc-icons">
              <HedgieSocialIcon
                url="https://t.me/hellohedgie"
                network={brandIcons.faTelegramPlane}
              />
              <HedgieSocialIcon
                url="https://discord.gg/mhYwH5G"
                network={brandIcons.faDiscord}
              />
              <HedgieSocialIcon
                url="https://www.instagram.com/hedgieio/"
                network={brandIcons.faInstagram}
              />
              <HedgieSocialIcon
                url="https://twitter.com/hellohedgie"
                network={brandIcons.faTwitter}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="flex-vcenter social-column mb-3">
            <div className="soc-icons">
              <HedgieSocialIcon
                url="https://www.facebook.com/hellohedgie/"
                network={brandIcons.faFacebookF}
              />
              <HedgieSocialIcon
                url="https://medium.com/hellohedgie"
                network={brandIcons.faMediumM}
              />
              <HedgieSocialIcon
                url="https://www.youtube.com/hellohedgie"
                network={brandIcons.faYoutube}
              />
              <HedgieSocialIcon
                url="https://www.reddit.com/r/Hedgie"
                network={brandIcons.faReddit}
              />
            </div>
          </Col>
        </Row>
      </Collapse>
    </Navbar>
  );
};

MobileNav.propTypes = {
  email: PropTypes.string,
  curioBalance: PropTypes.string,
  authenticated: PropTypes.bool,
  toggleNavbar: PropTypes.func,
  collapsed: PropTypes.bool,
};

MobileNav.defaultProps = {
  email: '',
  curioBalance: '',
  authenticated: false,
  toggleNavbar: null,
  collapsed: false,
};

export default React.memo(MobileNav);

import styled, { keyframes } from 'styled-components';
import { palette } from 'styled-theme';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const CenterSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(50%, 40%);
  border: 0.2em solid ${palette('grayscale', 1, true)};
  border-bottom-color: #12dfc0;
  border-radius: 50%;
  margin: 0 auto;
  width: 2em;
  height: 2em;
  animation: ${spin} 1s linear infinite;
`;

export default CenterSpinner;

import {
  BUILD_FURNITURE,
  BUILD_FURNITURE_FAILURE,
  BUILD_FURNITURE_SUCCESS,
  CANCEL_REQUEST,
  CLEAN_HOUSE,
  CLEAN_HOUSE_FAILURE,
  CLEAN_HOUSE_SUCCESS,
  CLEAR_BUILDING_FURNITURE,
  GET_FURNITURE_FOR_HOUSE,
  GET_FURNITURE_FOR_HOUSE_FAILURE,
  GET_FURNITURE_FOR_HOUSE_SUCCESS,
  GET_HOUSE_EFFECTS,
  GET_HOUSE_EFFECTS_FAILURE,
  GET_HOUSE_EFFECTS_SUCCESS,
  GET_HOUSES,
  GET_HOUSES_FAILURE,
  GET_HOUSES_SUCCESS,
  GET_RESOURCES_META,
  GET_RESOURCES_META_FAILURE,
  GET_RESOURCES_META_SUCCESS,
  RESET_CLEAN_HOUSE,
} from './actionTypes';

export const getResourcesMeta = () => ({
  type: GET_RESOURCES_META,
});

export const getResourcesMetaSuccess = payload => ({
  type: GET_RESOURCES_META_SUCCESS,
  payload,
});

export const getResourcesMetaFailure = error => ({
  type: GET_RESOURCES_META_FAILURE,
  payload: error,
});

export const getHouses = () => ({
  type: GET_HOUSES,
});

export const getHousesSuccess = payload => ({
  type: GET_HOUSES_SUCCESS,
  payload,
});

export const cancelRequest = () => ({
  type: CANCEL_REQUEST,
});

export const getHousesFailure = error => ({
  type: GET_HOUSES_FAILURE,
  payload: error,
});

export const getFurnitureForHouse = payload => {
  return {
    type: GET_FURNITURE_FOR_HOUSE,
    payload,
  };
};

export const getFurnitureForHouseSuccess = payload => ({
  type: GET_FURNITURE_FOR_HOUSE_SUCCESS,
  payload,
});

export const getFurnitureForHouseFailure = error => ({
  type: GET_FURNITURE_FOR_HOUSE_FAILURE,
  payload: error,
});

export const buildFurniture = payload => ({
  type: BUILD_FURNITURE,
  payload,
});

export const buildFurnitureSuccess = payload => ({
  type: BUILD_FURNITURE_SUCCESS,
  payload,
});

export const buildFurnitureFailure = error => ({
  type: BUILD_FURNITURE_FAILURE,
  payload: error,
});

export const clearBuildingFurnitureState = () => ({
  type: CLEAR_BUILDING_FURNITURE,
});

export const getHouseEffects = houseId => ({
  type: GET_HOUSE_EFFECTS,
  payload: houseId,
});

export const getHouseEffectsSuccess = payload => ({
  type: GET_HOUSE_EFFECTS_SUCCESS,
  payload,
});

export const getHouseEffectsFailure = error => ({
  type: GET_HOUSE_EFFECTS_FAILURE,
  payload: error,
});

export const cleanHouse = payload => ({
  type: CLEAN_HOUSE,
  payload,
});

export const cleanHouseSuccess = payload => ({
  type: CLEAN_HOUSE_SUCCESS,
  payload,
});

export const cleanHouseFailure = error => ({
  type: CLEAN_HOUSE_FAILURE,
  payload: error,
});

export const resetCleanHouse = () => ({
  type: RESET_CLEAN_HOUSE,
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledContainer = styled.div``;

const StyledSpeech = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: ${props => props.width}px;
  margin: 0 auto 50px auto;
  background: ${props => props.backgroundColor};
  padding: 20px;
  text-align: center;
  font-weight: 400;
  font-size: ${props => (props.isWarning ? 14 : 16)}px;
  color: #fff;
  border-radius: 15px;
  ${media.tablet`
    width: ${props => props.width - 30}px;
    font-size: 14px;
    margin-bottom:20px;
    padding: 10px;
  `}
  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid ${props => props.backgroundColor};
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => props.backgroundColor};
    border-bottom: 10px solid transparent;
    right: 80px;
    bottom: -19px;
  }
`;

const SpeechBubble = ({ backgroundColor, text, ...props }) => {
  return (
    <StyledContainer {...props}>
      <StyledSpeech
        backgroundColor={backgroundColor}
        width={props.width}
        isWarning={props.isWarning}
      >
        {text || props.children}
      </StyledSpeech>
    </StyledContainer>
  );
};

SpeechBubble.propTypes = {
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.element,
  width: PropTypes.number,
  isWarning: PropTypes.bool,
};

SpeechBubble.defaultProps = {
  backgroundColor: '#EE6506',
  text: '',
  children: null,
  width: 320,
  isWarning: false,
};

export default SpeechBubble;

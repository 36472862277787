import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Stone } from 'components';
import stoneImgUrl from 'images/stones/stone-1.png';
import largeImg from 'images/stones/stone_large.png';
import mobilePinkUrl from 'images/stones/pinkMobileUrl.png';
import pinkUrl from 'images/stones/pinkUrl.png';

const STONES = [
  {
    url: largeImg,
    mobileUrl: stoneImgUrl,
    label: 'Name 1',
  },
  {
    url: pinkUrl,
    mobileUrl: mobilePinkUrl,
    label: 'Name 2',
  },
  {
    url: pinkUrl,
    mobileUrl: mobilePinkUrl,
    label: 'Name 3',
  },
  {
    url: largeImg,
    mobileUrl: stoneImgUrl,
    label: 'Name 4',
  },
  {
    url: largeImg,
    mobileUrl: stoneImgUrl,
    label: 'Name 5',
  },
  {
    url: pinkUrl,
    mobileUrl: mobilePinkUrl,
    label: 'Name 6',
  },
  {
    url: largeImg,
    mobileUrl: stoneImgUrl,
    label: 'Name 7',
  },
  {
    url: largeImg,
    mobileUrl: stoneImgUrl,
    label: 'Name 8',
  },
];

class StonesList extends Component {
  state = {
    selectedIndex: 0,
  };

  onSelect = (stone, index) => () => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { stones, loading } = this.props;
    const { selectedIndex } = this.state;
    if (!stones) {
      return (
        <div style={{ textAlign: 'center' }} className="pb-3">
          No data
        </div>
      );
    }
    return (
      <Container>
        <Row>
          {stones.map((stone, index) => (
            <Col xs={3} sm={3} md={4} lg={3} xl={3} key={index}>
              <Stone
                loading={loading}
                stone={stone}
                onClick={this.onSelect(stone, index)}
                active={selectedIndex === index}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

StonesList.propTypes = {
  stones: PropTypes.array,
  loading: PropTypes.bool,
};

StonesList.defaultProps = {
  stones: STONES,
  loading: false,
};

export default StonesList;

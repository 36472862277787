import { put, call, fork, take } from 'redux-saga/effects';
import querystring from 'querystring';
import Log from 'services/log';
import {
  GET_MY_JOURNEYS,
  getMyJourneysFailure,
  getMyJourneysSuccess,
  CREATE_JOURNEY,
  createJourneySuccess,
  createJourneyFailure,
  GET_WORLDS,
  getWorldsSuccess,
  getWorldsFailure,
  selectWorld,
  GET_REWARDS_BY_JOURNEY_ID,
  getRewardsByJourneyIdSuccess,
  getRewardsByJourneyIdFailure,
  ARCHIVE_JOURNEY,
  archiveJourneySuccess,
  archiveJourneyFailure,
  GET_JOURNEY_COSTS,
  getJourneyCostsSuccess,
  getJourneyCostsFailure,
} from './actions';
import { startJourney } from '../onboarding/actions';
import { getCurrentUserRequest } from '../user/actions';
import { eventCategories, trackStartingtAJourney } from '../../tracking';

export function* getMyJourneys(
  { api, firebase },
  { offset, limit, status, isLoadMore },
) {
  Log.debug(firebase, 'saga: getMyJourneys:begin');
  const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

  if (!idToken) {
    throw Error('saga: getMyJourneys: No access token in session');
  }
  yield call([api, api.setToken], idToken);
  const requestParams = {
    offset,
    limit,
    status,
  };
  const qs = querystring.stringify(requestParams);
  try {
    Log.debug('saga: getMyJourneys');
    const response = yield call([api, api.get], `/journeys?${qs}`);
    Log.debug(response, 'saga: getMyJourneys:response');
    yield put(getMyJourneysSuccess({ ...response, isLoadMore }));
  } catch (e) {
    yield put(getMyJourneysFailure(e));
  }
}

export function* createJourney(api, params) {
  try {
    Log.debug('saga: createJourney');
    const response = yield call([api, api.post], '/journeys', params);
    Log.debug(response, 'saga: createJourney:response');
    yield put(createJourneySuccess(response));
    yield put(startJourney('count_down', params.duration));
    trackStartingtAJourney(params.duration, eventCategories.JOURNEYS);
  } catch (e) {
    yield put(createJourneyFailure(e));
  }
}

export function* fetchWorlds(api) {
  try {
    Log.debug('saga: fetchWorlds');
    const response = yield call([api, api.get], '/worlds');
    Log.debug('saga: fetchWorlds:response');

    yield put(getWorldsSuccess(response));
    yield put(selectWorld(response.data.find(w => w.name === 'Wetland Kingdom')));
  } catch (e) {
    yield put(getWorldsFailure(e));
  }
}

export function* fetchReward(api, journeyId) {
  try {
    Log.debug('saga: fetchReward');
    const response = yield call(
      [api, api.get],
      `/journeys/${journeyId}/rewards`,
    );
    Log.debug('saga: fetchReward:response');
    yield put(getRewardsByJourneyIdSuccess({ ...response, journeyId }));
  } catch (e) {
    yield put(getRewardsByJourneyIdFailure({ ...e, journeyId }));
  }
}

export function* archiveJourney(api, journeyId) {
  try {
    Log.debug('saga: archiveJourney');
    const response = yield call(
      [api, api.post],
      `/journeys/${journeyId}/archive`,
    );
    Log.debug('saga: archiveJourney:response');
    yield put(archiveJourneySuccess(response));
    yield put(getCurrentUserRequest());
  } catch (e) {
    yield put(archiveJourneyFailure(e));
  }
}

export function* getJourneyCosts(api) {
  try {
    Log.debug('saga: getJourneyCosts');
    const response = yield call([api, api.get], '/journeys/costs');
    Log.debug('saga: getJourneyCosts:response');
    yield put(getJourneyCostsSuccess(response));
  } catch (e) {
    yield put(getJourneyCostsFailure(e));
  }
}

export function* watchGetMyJourneys({ api, firebase }) {
  while (true) {
    const { payload } = yield take(GET_MY_JOURNEYS);
    yield call(getMyJourneys, { api, firebase }, payload);
  }
}

export function* watchCreateJourney(api) {
  while (true) {
    const { params } = yield take(CREATE_JOURNEY);
    yield call(createJourney, api, params);
  }
}

export function* watchFetchWorlds(api) {
  while (true) {
    yield take(GET_WORLDS);
    yield call(fetchWorlds, api);
  }
}

export function* watchFetchRewards(api) {
  while (true) {
    const { payload } = yield take(GET_REWARDS_BY_JOURNEY_ID);
    yield call(fetchReward, api, payload);
  }
}

export function* watchArchiveJourney(api) {
  while (true) {
    const { journeyId } = yield take(ARCHIVE_JOURNEY);
    yield call(archiveJourney, api, journeyId);
  }
}

export function* watchGetJourneyCosts(api) {
  while (true) {
    yield take(GET_JOURNEY_COSTS);
    yield call(getJourneyCosts, api);
  }
}

export default function*({ api, firebase }) {
  yield fork(watchGetMyJourneys, { api, firebase });
  yield fork(watchCreateJourney, api);
  yield fork(watchFetchWorlds, api);
  yield fork(watchFetchRewards, api);
  yield fork(watchArchiveJourney, api);
  yield fork(watchGetJourneyCosts, api);
}

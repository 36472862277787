import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import * as types from 'types';
import { isEmpty } from 'lodash';
import { MyHedgies, ReferralCodeModal } from 'components';
// import AnnouncementsModal from '../../../containers/AnnouncementsModal';

const Pagination = {
  LIMIT: 100,
  OFFSET: 0,
};

const MyHedgiesPage = memo(
  ({
    user,
    staticUrl,
    selectJourneyHedgie,
    lowestJourneyEnergy,
    journeyCostsError,
    hedgies,
    hedigesOnJourney,
    getMyHedgies,
    history,
    getJourneyCosts,
    journeyCostsList,
    selectedHedgie,
    signUpSuccess,
    closeReferralCodeModal,
    location,
    handlePurchaseWithEther,
  }) => {
    useEffect(() => {
      getMyHedgies({
        limit: Pagination.LIMIT,
        offset: Pagination.OFFSET,
        isLoadMore: false,
      });
      if (isEmpty(journeyCostsList)) {
        getJourneyCosts();
      }
    }, [getJourneyCosts, getMyHedgies, journeyCostsList]);

    const handleLoadMore = limit => {
      getMyHedgies({ limit, offset: hedgies.length, isLoadMore: true });
    };

    const handleOnGetHedgie = () => {
    };

    const handleSubmitEtherBuyNow = (submitParams = {}) => {
      const { hedgie } = this.props;
      const params = {
        ...submitParams,
        hedgieIds: [hedgie.id],
      };
      handlePurchaseWithEther(params);
    };

    return (
      <div>
        {signUpSuccess ? (
          <ReferralCodeModal
            onClose={closeReferralCodeModal}
            referralCode={user.referralCode}
          />
        ) : null}
        {/* <AnnouncementsModal /> */}
        <main>
          <Container fluid>
            <MyHedgies
              selectJourneyHedgie={selectJourneyHedgie}
              hedgies={hedgies}
              hedigesOnJourney={hedigesOnJourney}
              onGetHedgie={handleOnGetHedgie}
              onLoadMore={handleLoadMore}
              staticUrl={staticUrl}
              lowestJourneyEnergy={lowestJourneyEnergy}
              journeyCostsError={journeyCostsError}
              selectedHedgie={selectedHedgie}
              history={history}
              location={location}
              onCompleteEtherBuyNow={handleSubmitEtherBuyNow}
            />
          </Container>
        </main>
      </div>
    );
  },
);

MyHedgiesPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  hedgies: PropTypes.arrayOf(types.hedgie),
  hedigesOnJourney: PropTypes.array,
  tier: types.tier,

  bookingSuccess: PropTypes.bool,
  purchaseSuccess: PropTypes.bool,
  redeemSuccess: PropTypes.bool,
  getSuccess: PropTypes.bool,

  errorBookingHedgie: types.error,
  errorGettingHedgie: types.error,
  errorPurchasingHedgie: types.error,
  errorRedeemingGiftCode: types.error,
  loading: PropTypes.bool.isRequired,
  user: types.user,
  getMyHedgies: PropTypes.func.isRequired,
  selectJourneyHedgie: PropTypes.func,
  staticUrl: PropTypes.string,
  getJourneyCosts: PropTypes.func,
  lowestJourneyEnergy: PropTypes.number,
  journeyCostsList: PropTypes.array,
  journeyCostsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  selectedHedgie: PropTypes.object,
  signUpSuccess: PropTypes.bool,
  closeReferralCodeModal: PropTypes.func,
};

MyHedgiesPage.defaultProps = {
  hedgies: [],
  hedigesOnJourney: [],
  tier: null,
  bookingSuccess: false,
  purchaseSuccess: false,
  redeemSuccess: false,
  getSuccess: false,
  errorPurchasingHedgie: null,
  selectJourneyHedgie: null,
  errorBookingHedgie: null,
  errorGettingHedgie: null,
  errorRedeemingGiftCode: null,
  user: null,
  staticUrl: '',
  getJourneyCosts: null,
  journeyCostsError: {
    error: false,
    message: '',
  },
  lowestJourneyEnergy: 0,
  journeyCostsList: [],
  selectedHedgie: null,
  signUpSuccess: false,
  closeReferralCodeModal: null,
};

export default MyHedgiesPage;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'components';
import NumberUtil from 'services/numberUtil';
import curioImg from 'images/curio.svg';

const StyledRewardWithoutItem = styled.div`
  min-height: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RewardCurio = ({ curio }) => {
  return (
    <StyledRewardWithoutItem>
      <div>
        <div className="pt-3 pb-3 align-center">
          <img src={curioImg} alt="curio-icon" />
        </div>
        <Typography text="Curio" align="center" fontSize={14} />
        <Typography
          className="pt-1 pb-1"
          text={NumberUtil.toDecimal(curio)}
          align="center"
          isBold
          fontSize={16}
        />
      </div>
    </StyledRewardWithoutItem>
  );
};

RewardCurio.propTypes = {
  curio: PropTypes.string.isRequired,
};

export default RewardCurio;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as types from 'types';
import { Row, Col } from 'reactstrap';
import get from 'lodash/get';
import NumberUtil from 'services/numberUtil';
import moment from 'moment';
import { Desktop, Mobile, Button } from 'components';
import imgCurio from 'images/curio-styled.svg';
import { TRANSACTION_DATE_FORMAT } from 'const/dateTime';

const CurioBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CurioImg = styled.img`
  width: 90px;
`;
const StyledBalance = styled.div`
  margin-left: 20px;
`;
const Divider = styled.div`
  height: 2px;
  background: #eff2f3;
`;

const CurioBalance = ({ balance, className }) => (
  <CurioBalanceWrapper className={className}>
    <CurioImg src={imgCurio} />
    <StyledBalance>
      <span className="ff-semi-bold">Curio Balance: </span>
      <span
        className="color-dark-contrast ff-semi-bold"
        style={{ fontSize: '22px' }}
      >
        {NumberUtil.toDecimal(balance)}
      </span>
    </StyledBalance>
  </CurioBalanceWrapper>
);

CurioBalance.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

CurioBalance.defaultProps = {
  balance: 0,
  className: null,
};

const CurioTransactionHistory = ({ data, user }) => (
  <div>
    <Divider className="row" />
    {data && data.length ? (
      data.map((history, index) => {
        const isReceived = user && user.id === history.toUserID;
        return (
          <Row
            key={index}
            className={`p-4 pl-5 pr-5${
              index % 2 === 1 ? ' grey-light-bgr' : ''
              }`}
          >
            <Col md={3}>
              <span className="color-dark-contrast ff-semi-bold">
                {moment(history.createdAt).format(TRANSACTION_DATE_FORMAT)}
              </span>
            </Col>
            <Col md={9} className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{history.note}</p>
              </div>
              {history.meta.battle_id ? (
                <div>
                  <a href={"/battles/" + history.meta.battle_id}>view</a>
                </div>
              ) : null}
              <div>
                {isReceived ? (
                  <span className="color-success ff-semi-bold">
                    +{NumberUtil.toDecimal(history.amount)}
                  </span>
                ) : (
                    <span className="color-error-dark ff-semi-bold">
                      -{NumberUtil.toDecimal(history.amount)}
                    </span>
                  )}
              </div>
            </Col>
          </Row>
        );
      })
    ) : (
        <p className="p-4 text-center">No Activitiy</p>
      )}
  </div >
);

CurioTransactionHistory.propTypes = {
  data: PropTypes.array,
  user: PropTypes.object,
};

CurioTransactionHistory.defaultProps = {
  data: [],
  user: null,
};

const CurioTransactionHistoryMobile = ({ data, user }) => (
  <div>
    <Divider className="row" />
    {data && data.length ? (
      data.map((history, index) => {
        const isReceived = user && user.id === history.toUserID;
        return (
          <Row
            key={index}
            className={`p-4 pl-2 pr-2${
              index % 2 === 1 ? ' grey-light-bgr' : ''
              }`}
          >
            <Col md={3}>
              <span className="color-dark-contrast ff-semi-bold">
                {moment(history.createdAt).format(TRANSACTION_DATE_FORMAT)}
              </span>
            </Col>
            <Col md={9} className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{history.note}</p>
              </div>
              <div>
                {isReceived ? (
                  <span className="color-success ff-semi-bold">
                    +{NumberUtil.toDecimal(history.amount)}
                  </span>
                ) : (
                    <span className="color-error-dark ff-semi-bold">
                      -{NumberUtil.toDecimal(history.amount)}
                    </span>
                  )}
              </div>
            </Col>
          </Row>
        );
      })
    ) : (
        <p className="p-4 text-center">No Activitiy</p>
      )}
  </div>
);

CurioTransactionHistoryMobile.propTypes = {
  user: PropTypes.object,
  data: PropTypes.array,
};

CurioTransactionHistoryMobile.defaultProps = {
  user: null,
  data: [],
};

class CurioTransactions extends Component {
  static propTypes = {
    user: types.user,
    userIsReady: PropTypes.bool,
    curioTransactions: PropTypes.object,
    getCurioTransactions: PropTypes.func,
  };

  static defaultProps = {
    user: null,
    userIsReady: false,
    curioTransactions: {},
    getCurioTransactions: () => { },
  };

  componentDidMount() {
    if (this.props.userIsReady) {
      this.props.getCurioTransactions({
        limit: 5,
        offset: 0,
        isLoadMore: false,
      });
    }
  }

  handleLoadMore = () => {
    const { curioTransactions } = this.props;
    const limit = get(curioTransactions, 'meta.pagination.limit', 0);
    const offset = get(curioTransactions, 'meta.pagination.offset', 0);
    const total = get(curioTransactions, 'meta.pagination.total', 0);
    const canLoadMore = limit + offset < total;

    if (canLoadMore) {
      this.props.getCurioTransactions({
        offset: limit + offset,
        limit,
        isLoadMore: true,
      });
    }
  };

  render() {
    const { user, curioTransactions } = this.props;
    const limit = get(curioTransactions, 'meta.pagination.limit', 0);
    const offset = get(curioTransactions, 'meta.pagination.offset', 0);
    const total = get(curioTransactions, 'meta.pagination.total', 0);
    const canLoadMore = limit + offset < total;
    return (
      <div>
        <main>
          <Desktop>
            <div className="container pt-5 pb-5">
              <Row className="pb-5">
                <Col>
                  <CurioTransactionHistory
                    data={curioTransactions.data}
                    user={user}
                  />
                  {canLoadMore && (
                    <div className="text-center p-4 pr-5">
                      <Button size="large" onClick={this.handleLoadMore}>
                        Load More
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Desktop>
          <Mobile>
            <div className="container-fluid pt-5 pb-5">
              <CurioTransactionHistoryMobile
                data={curioTransactions.data}
                user={user}
              />
              {canLoadMore && (
                <div className="text-center pt-4">
                  <Button size="large" onClick={this.handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </div>
          </Mobile>
        </main>
      </div>
    );
  }
}

export default CurioTransactions;

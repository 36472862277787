import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { getUnreadAnnouncements } from 'store/actions';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isEmpty, find, size } from 'lodash';
import Announcements from '../components/stateless/Announcements';
import {
  selectUnreadAnnouncements,
  selectUnreadAnnouncementsError,
} from '../store/announcement/selectors';
import FormError from '../components/stateless/FormError';
import { markAnAnnouncementAsRead } from '../store/announcement/actions';

function findAnnById(announcements, id) {
  if (!id || isEmpty(announcements)) {
    return null;
  }
  return find(announcements, ann => ann.id === id);
}

const AnnouncementsModal = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [loadedData, setLoadedData] = useState(false);

  const unreadAnnouncements = useSelector(state =>
    selectUnreadAnnouncements(state),
  );
  const unreadAnnouncementsError = useSelector(state =>
    selectUnreadAnnouncementsError(state),
  );

  useEffect(() => {
    dispatch(getUnreadAnnouncements());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (!loadedData) {
      if (
        !isEmpty(unreadAnnouncements) &&
        size(unreadAnnouncements.filter(ann => !ann.alreadyRead)) !== 0
      ) {
        setOpen(true);
        setLoadedData(true);
      }
    }
  }, [loadedData, unreadAnnouncements]);

  function markAnnouncementAsRead(index) {
    const { alreadyRead, id } = unreadAnnouncements[index];
    if (!alreadyRead) {
      dispatch(markAnAnnouncementAsRead(id));
    }
  }

  function handleClose() {
    markAnnouncementAsRead(currentIndex);
    setOpen(false);
  }

  function handleCurrentIndexOfAnnouncement(prevIndex, index) {
    setCurrentIndex(index);
    markAnnouncementAsRead(prevIndex);
  }

  const handleOpenLink = useCallback(
    (href, announcementId) => {
      window.open(href, '_blank');
      const clickedAnnouncement = findAnnById(
        unreadAnnouncements,
        announcementId,
      );
      if (!clickedAnnouncement.alreadyRead) {
        dispatch(markAnAnnouncementAsRead(announcementId));
      }
    },
    [dispatch, unreadAnnouncements],
  );

  return (
    <div>
      <Modal isOpen={open} className="large-modal">
        <ModalHeader toggle={handleClose}>Announcements</ModalHeader>
        <ModalBody>
          <Announcements
            announcements={unreadAnnouncements}
            openHref={handleOpenLink}
            getIndexOfAnnouncement={handleCurrentIndexOfAnnouncement}
          />
          {unreadAnnouncementsError.error ? (
            <FormError error={unreadAnnouncementsError} />
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  );
};

// AnnouncementsModal.propTypes = {};

export default React.memo(AnnouncementsModal);

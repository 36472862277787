import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import {
  Desktop, Mobile, SubHeading,
}
  from 'components';

const descriptionText = 'Хеджи это cryptocollectible с ограниченным тиражом, герой free-to-play блокчейн игры в которой игроки зарабатывают крипто монеты.';

const DesktopWelcome = () => (
  <Container fluid className="row-section welcome-section desktop-welcome">
    <Container>
      <Jumbotron>
        <h1 className="title">Привет, я Хеджи!</h1>
        <div className="col-md-7 px-0">
          <p className="lead ff-semi-bold">{descriptionText}</p>
        </div>
      </Jumbotron>
    </Container>
  </Container>
);

const MobileWelcome = () => (
  <React.Fragment>
    <Container fluid className="row-section welcome-section mobile-welcome">
      <Jumbotron>
        <h1 className="color-light title">
          Привет,<br /> я Хеджи!
        </h1>
      </Jumbotron>
    </Container>
    <Container fluid>
      <Container>
        <Row className="pt-5">
          <Col>
            <div className="ff-semi-bold pb-3 text-center"><SubHeading heading={descriptionText} /></div>
          </Col>
        </Row>
      </Container>
    </Container>
  </React.Fragment>
);


class PresaleTopSlideRu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = e => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>
          <DesktopWelcome />
        </Desktop>
        <Mobile>
          <MobileWelcome />
        </Mobile>
      </React.Fragment>
    );
  }
}

export default PresaleTopSlideRu;

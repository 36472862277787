import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  PageTitle,
  CurioBalance,
  OrderList,
  FormError,
  Tabs,
} from 'components';
import styled from 'styled-components';
import { get, includes } from 'lodash';
import NumberUtil from 'services/numberUtil';
import { BUSINESS_PERMISSIONS } from 'const/permissions';

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px 40px 20px;
`;

export const BusinessTabIds = {
  PENDING: 'pending',
  COMPLETED: 'completed',
};

function checkPermissionsWrite(permissions) {
  return includes(permissions, BUSINESS_PERMISSIONS.write);
}

const PAGE_LIMIT = 8;
const BusinessOwnerOrdersPage = ({
  getBusinessDetail,
  getOrdersOfBusiness,
  archiveAnOrder,
  match,
  businessDetail,
  businessOrders,
  archivedOrder,
}) => {
  const businessId = get(match, 'params.businessId', '');

  const { data, error, sizePerResponse, permissions } = businessOrders;
  const isLoadableMore = sizePerResponse >= PAGE_LIMIT;
  const businessBalance = get(businessDetail, 'data.curioBalance', '0');

  const memoizedCurioBalance = useMemo(
    () => NumberUtil.toDecimal(businessBalance),
    [businessBalance],
  );


  const memoizedHasPermissionWrite = useMemo(
    () => checkPermissionsWrite(permissions),
    [permissions],
  );

  const fetchBusiness = useCallback(
    (businessId) => {
      getBusinessDetail(businessId);
    },
    [getBusinessDetail],
  );

  const fetchOrders = useCallback(
    (status, businessId, isLoadMore, offset) => {
      getOrdersOfBusiness({
        status,
        businessId,
        isLoadMore,
        limit: PAGE_LIMIT,
        offset,
      });
    },
    [getOrdersOfBusiness],
  );

  function handleArchiveOrder(orderId) {
    archiveAnOrder(orderId);
  }

  function handleLoadMore(tabId) {
    fetchOrders(tabId, businessId, true, data.length);
  }

  const tabs = [
    {
      id: BusinessTabIds.PENDING,
      name: 'Pending',
      tabPanel: (
        <OrderList
          orders={data}
          isBusinessOwner
          key="pending-tab"
          tabId={BusinessTabIds.PENDING}
          archiveOrder={handleArchiveOrder}
          hasPermissionWrite={memoizedHasPermissionWrite}
          loadMore={handleLoadMore}
          isLoadableMore={isLoadableMore}
        />
      ),
    },
    {
      id: BusinessTabIds.COMPLETED,
      name: 'Completed',
      tabPanel: (
        <OrderList
          tabId={BusinessTabIds.COMPLETED}
          orders={data}
          isBusinessOwner
          loadMore={handleLoadMore}
          isLoadableMore={isLoadableMore}
          key="completed-tab"
        />
      ),
    },
  ];

  // Use a trick to made useEffect call once time
  const businessIdRef = useRef(businessId);
  const fetchOrdersRef = useRef(fetchOrders);
  const fetchBusinessRef = useRef(fetchBusiness);
  const defaultTabRef = useRef(tabs[0].id);

  useEffect(() => {
    fetchOrdersRef.current(defaultTabRef.current, businessIdRef.current, false, 0);
    fetchBusinessRef.current(businessIdRef.current);
  }, []);

  function handleTabChange(tabId) {
    fetchOrders(tabId, businessId, false, 0);
  }


  return (
    <StyledContainer>
      <PageTitle title="Business Orders" />
      <CurioBalance curioBalance={memoizedCurioBalance} fromBusinessID={businessId} />
      <Tabs
        tabsData={tabs}
        activeTab={tabs[0].id}
        onTabChange={handleTabChange}
      />
      {error.error ? (
        <div className="align-center">
          <FormError error={error} />
        </div>
      ) : null}
      {archivedOrder.error.error ? (
        <div className="align-center">
          <FormError error={archivedOrder.error} />
        </div>
      ) : null}
    </StyledContainer>
  );
};

BusinessOwnerOrdersPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  businessOrders: PropTypes.object.isRequired,
  getOrdersOfBusiness: PropTypes.func.isRequired,
  businessDetail: PropTypes.object.isRequired,
  getBusinessDetail: PropTypes.func.isRequired,
  archiveAnOrder: PropTypes.func.isRequired,
  archivedOrder: PropTypes.object.isRequired,
};

export default BusinessOwnerOrdersPage;

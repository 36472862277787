import React, { useEffect, memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';
import { Container } from 'reactstrap';
import { includes, isEmpty } from 'lodash';
// import { Link } from 'react-router-dom';
import {
  Spinner,
  HedgiePower,
  SubHeading,
  PageTitle,
  HedgieEnergy,
  Button,
  FormError,
} from 'components';
import * as paths from 'config/paths';
import styled from 'styled-components';
import HedgiePagination from '../../stateless/MyHedgies/HedgiePagination';
import { StyledFlexBox } from '../../../styles/main';
import withResponsive from '../../../helper/withResponsive';
import FormErrorMessage from '../../forms/FormErrorMessage';
import { trackSelectingHedgie } from '../../../tracking';

const StyledGroupPowerAndEnergy = styled.div`
  margin: 0 auto;
  width: ${props => (props.isMobile ? '100%' : '460px')};
`;

const JourneySelectHedgiePage = memo(
  ({
    loading,
    isMobile,
    lowestJourneyEnergy,
    journeyCostsError,
    getMyHedgies,
    history,
    hedgies,
    hedigesOnJourney,
    getJourneyCosts,
    journeyCostsList,
    mergedWidthHedgiesOnJourney,
  }) => {
    const isRunOnce = useRef(true);
    const [selectedHedgie, setSelectedHedgie] = useState(null);

    useEffect(() => {
      getMyHedgies({ limit: 100, offset: 0, isLoadMore: false });
      if (isEmpty(journeyCostsList)) {
        getJourneyCosts();
      }
    }, [getJourneyCosts, getMyHedgies, journeyCostsList]);

    useEffect(() => {
      if (!isEmpty(hedgies)) {
        if (isRunOnce) {
          setSelectedHedgie(hedgies[0]);
          isRunOnce.current = false;
        }
      }
    }, [hedgies, setSelectedHedgie]);

    const handleLoadMore = limit => {
      getMyHedgies({ limit, offset: hedgies.length, isLoadMore: true });
    };

    const handleSelectHedgie = hedgie => {
      if (hedgie) {
        setSelectedHedgie(hedgie);
      } else {
        //history.push(paths.GET_HEDGIE);
      }
    };

    function onNextPage() {
      history.push({
        pathname: paths.JOURNEY,
        state: { hedgieId: selectedHedgie.id },
      });
      trackSelectingHedgie();
    }

    return (
      <div>
        {loading && <Spinner />}
        <Container fluid>
          <PageTitle title="Select a Hedgie" />
          <HedgiePagination
            hedgies={mergedWidthHedgiesOnJourney}
            selectedHedgie={selectedHedgie}
            onSelectHedgie={handleSelectHedgie}
            onLoadMore={handleLoadMore}
          />
          {selectedHedgie && !includes(hedigesOnJourney, selectedHedgie.id) ? (
            <StyledFlexBox direction="column" className="mb-4 pt-2">
              <div className="pt-3 pb-1">
                <SubHeading
                  className="pt-1 sub-heading-center ff-bold"
                  heading={selectedHedgie.name}
                />
                <SubHeading
                  className="pt-1 sub-heading-center"
                  heading={`#${selectedHedgie.color}`}
                />
              </div>
              <StyledGroupPowerAndEnergy isMobile={isMobile}>
                <HedgiePower
                  hedgie={selectedHedgie}
                  className="pt-4"
                  minWidth={460}
                />
                <HedgieEnergy
                  className="pt-1"
                  color={`#${selectedHedgie.color}`}
                  energy={selectedHedgie.energy}
                />
              </StyledGroupPowerAndEnergy>
              {selectedHedgie.energy < lowestJourneyEnergy ? (
                <FormErrorMessage
                  error="I'm too tired to travel."
                  align="center"
                />
              ) : null}
              <Button
                disabled={selectedHedgie.energy < lowestJourneyEnergy}
                size="large"
                className="justify-content-center"
                onClick={onNextPage}
              >
                Next
              </Button>
              {journeyCostsError.error ? (
                <FormError error={journeyCostsError} />
              ) : null}
            </StyledFlexBox>
          ) : (
              <div className="pt-5" />
            )}
        </Container>
      </div>
    );
  },
);

JourneySelectHedgiePage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hedgies: PropTypes.arrayOf(types.hedgie),
  hedigesOnJourney: PropTypes.array,
  mergedWidthHedgiesOnJourney: PropTypes.array,
  getMyHedgies: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  userIsReady: PropTypes.bool.isRequired,
  user: types.user,
  isMobile: PropTypes.bool,
  getJourneyCosts: PropTypes.func,
  lowestJourneyEnergy: PropTypes.number,
  journeyCostsList: PropTypes.array,
  journeyCostsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
};

JourneySelectHedgiePage.defaultProps = {
  hedgies: [],
  hedigesOnJourney: [],
  mergedWidthHedgiesOnJourney: [],
  loading: false,
  user: null,
  isMobile: false,
  getJourneyCosts: null,
  lowestJourneyEnergy: 0,
  journeyCostsList: [],
  journeyCostsError: {
    error: false,
    message: '',
  },
};
export default withResponsive(JourneySelectHedgiePage);

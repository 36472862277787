import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { HedgieImg, Spinner, PageTitle } from 'components';
import styled from 'styled-components';
import JourneyDuration from '../../stateless/JourneyDuration';
import { StyledFlexBox } from '../../../styles/main';
import messageSvg from './img.svg';
import withResponsive from '../../../helper/withResponsive';

const StyledWrapper = styled.div`
  position: relative;
`;
const StyledContainer = styled.div`
  position: relative;
  height: 346px;
  overflow: hidden;
  .img-fluid {
    position: absolute;
    right: ${props => (props.isMobile ? '-9%' : '10%')};
    bottom: ${props => (props.isMobile ? '-32%' : '-120px')};
  }
`;
const StyledMessage = styled.img`
  position: absolute;
  top: 90px;
  right: ${props => (props.isMobile ? '6%' : '22%')};
  z-index: 100;
`;

const JourneyConfirmationPage = memo(({ hedgie, duration, isMobile }) => {
  if (!hedgie) {
    return (
      <div style={{ position: 'relative', height: 200, top: 50 }}>
        <Spinner />
      </div>
    );
  }
  return (
    <StyledWrapper>
      <PageTitle title="New Journey" />
      <StyledMessage src={messageSvg} alt="notice" className="message" />
      <StyledContainer
        isMobile={isMobile}
        className={`${
          isMobile
            ? 'world-block-mobile world-3'
            : 'full-world-block full-world-3'
        }`}
      >
        <HedgieImg
          className="img-fluid"
          color={`#${hedgie.color}`}
          width={isMobile ? 310 : 350}
          imgURL={hedgie.imgURL}
        />
      </StyledContainer>
      <div className="duration pt-3 pb-2">
        <StyledFlexBox>
          <JourneyDuration duration={duration} />
        </StyledFlexBox>
      </div>
    </StyledWrapper>
  );
});

JourneyConfirmationPage.propTypes = {
  hedgie: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

JourneyConfirmationPage.defaultProps = {
  isMobile: false,
};

export default withResponsive(JourneyConfirmationPage);

//  Hedgie after selecting Color
export const GET_HEDGIE_REQUEST = 'GET_HEDGIE_REQUEST';
export const GET_HEDGIE_SUCCESS = 'GET_HEDGIE_SUCCESS';
export const GET_HEDGIE_FAILURE = 'GET_HEDGIE_FAILURE';

export const getHedgieRequest = colorHexCode => ({
  type: GET_HEDGIE_REQUEST,
  payload: {
    colorHexCode,
  },
});

export const getHedgieSuccess = payload => ({
  type: GET_HEDGIE_SUCCESS,
  payload,
});

export const getHedgieFailure = error => ({
  type: GET_HEDGIE_FAILURE,
  error: true,
  payload: error,
});

// Booking Hedgie
export const BOOKING_HEDGIE_REQUEST = 'BOOKING_HEDGIE_REQUEST';
export const BOOKING_HEDGIE_SUCCESS = 'BOOKING_HEDGIE_SUCCESS';
export const BOOKING_HEDGIE_FAILURE = 'BOOKING_HEDGIE_FAILURE';

export const bookingHedgieRequest = colorHexCode => ({
  type: BOOKING_HEDGIE_REQUEST,
  payload: {
    colorHexCode,
  },
});

export const bookingHedgieSuccess = payload => ({
  type: BOOKING_HEDGIE_SUCCESS,
  payload,
});

export const bookingHedgieFailure = error => ({
  type: BOOKING_HEDGIE_FAILURE,
  error: true,
  payload: error,
});

// Premium Hedgies
export const GET_HEDGIES_REQUEST = 'GET_HEDGIES_REQUEST';
export const GET_HEDGIES_SUCCESS = 'GET_HEDGIES_SUCCESS';
export const GET_HEDGIES_FAILURE = 'GET_HEDGIES_FAILURE';
export const GET_HEDGIES_RESET = 'GET_HEDGIES_RESET';

export const getHedgiesRequest = params => ({
  type: GET_HEDGIES_REQUEST,
  payload: {
    ...params,
  },
});

export const getHedgiesSuccess = payload => ({
  type: GET_HEDGIES_SUCCESS,
  payload,
});

export const getHedgiesFailure = error => ({
  type: GET_HEDGIES_FAILURE,
  error: true,
  payload: error,
});

export const getHedgiesReset = () => ({
  type: GET_HEDGIES_RESET,
});

// Gift Hedgie
export const GIFT_HEDGIE_REQUEST = 'GIFT_HEDGIE_REQUEST';
export const GIFT_HEDGIE_SUCCESS = 'GIFT_HEDGIE_SUCCESS';
export const GIFT_HEDGIE_FAILURE = 'GIFT_HEDGIE_FAILURE';

export const giftHedgieRequest = payload => ({
  type: GIFT_HEDGIE_REQUEST,
  payload,
});

export const giftHedgieRequestSuccess = payload => ({
  type: GIFT_HEDGIE_SUCCESS,
  payload,
});

export const giftHedgieRequestFailure = error => ({
  type: GIFT_HEDGIE_FAILURE,
  error: true,
  payload: error,
});

// Purchase Hedgie
export const PURCHASE_HEDGIE_REQUEST = 'PURCHASE_HEDGIE_REQUEST';
export const PURCHASE_HEDGIE_SUCCESS = 'PURCHASE_HEDGIE_SUCCESS';
export const PURCHASE_HEDGIE_FAILURE = 'PURCHASE_HEDGIE_FAILURE';

export const purchaseHedgieRequest = payload => ({
  type: PURCHASE_HEDGIE_REQUEST,
  payload,
});

export const purchaseHedgieRequestSuccess = payload => ({
  type: PURCHASE_HEDGIE_SUCCESS,
  payload,
});

export const purchaseHedgieRequestFailure = error => ({
  type: PURCHASE_HEDGIE_FAILURE,
  error: true,
  payload: error,
});

// Purchase Hedgie with Ether
export const PURCHASE_HEDGIE_WITH_ETHER_REQUEST =
  'PURCHASE_HEDGIE_WITH_ETHER_REQUEST';
export const PURCHASE_HEDGIE_WITH_ETHER_SUCCESS =
  'PURCHASE_HEDGIE_WITH_ETHER_SUCCESS';
export const PURCHASE_HEDGIE_WITH_ETHER_FAILURE =
  'PURCHASE_HEDGIE_WITH_ETHER_FAILURE';

export const purchaseWithEtherRequest = payload => ({
  type: PURCHASE_HEDGIE_WITH_ETHER_REQUEST,
  payload,
});

export const purchaseWithEtherRequestSuccess = payload => ({
  type: PURCHASE_HEDGIE_WITH_ETHER_SUCCESS,
  payload,
});

export const purchaseWithEtherRequestFailure = error => ({
  type: PURCHASE_HEDGIE_WITH_ETHER_FAILURE,
  error: true,
  payload: error,
});

// Redeem Giftcode for Hedgie
export const REDEEM_GIFTCODE_HEDGIE_REQUEST = 'REDEEM_GIFTCODE_HEDGIE_REQUEST';
export const REDEEM_GIFTCODE_HEDGIE_SUCCESS = 'REDEEM_GIFTCODE_HEDGIE_SUCCESS';
export const REDEEM_GIFTCODE_HEDGIE_FAILURE = 'REDEEM_GIFTCODE_HEDGIE_FAILURE';

export const redeemGiftCodeHedgieRequest = payload => ({
  type: REDEEM_GIFTCODE_HEDGIE_REQUEST,
  payload,
});

export const redeemGiftCodeHedgieRequestSuccess = payload => ({
  type: REDEEM_GIFTCODE_HEDGIE_SUCCESS,
  payload,
});

export const redeemGiftCodeHedgieRequestFailure = error => ({
  type: REDEEM_GIFTCODE_HEDGIE_FAILURE,
  error: true,
  payload: error,
});

//  Get My Hedgies
export const GET_MY_HEDGIES_REQUEST = 'GET_MY_HEDGIES_REQUEST';
export const GET_MY_HEDGIES_SUCCESS = 'GET_MY_HEDGIES_SUCCESS';
export const GET_MY_HEDGIES_FAILURE = 'GET_MY_HEDGIES_FAILURE';

export const getMyHedgiesRequest = payload => ({
  type: GET_MY_HEDGIES_REQUEST,
  payload,
});

export const getMyHedgiesSuccess = payload => ({
  type: GET_MY_HEDGIES_SUCCESS,
  payload,
});

export const getMyHedgiesFailure = error => ({
  type: GET_MY_HEDGIES_FAILURE,
  error: true,
  payload: error,
});

//  Sync My Hedgies
export const SYNC_MY_HEDGIES_REQUEST = 'SYNC_MY_HEDGIES_REQUEST';
export const SYNC_MY_HEDGIES_SUCCESS = 'SYNC_MY_HEDGIES_SUCCESS';
export const SYNC_MY_HEDGIES_FAILURE = 'SYNC_MY_HEDGIES_FAILURE';

export const syncMyHedgiesRequest = payload => ({
  type: SYNC_MY_HEDGIES_REQUEST,
  payload,
});

export const syncMyHedgiesSuccess = payload => ({
  type: SYNC_MY_HEDGIES_SUCCESS,
  payload,
});

export const syncMyHedgiesFailure = error => ({
  type: SYNC_MY_HEDGIES_FAILURE,
  error: true,
  payload: error,
});



export const ORDER_HEDGIE_REQUEST = 'ORDER_HEDGIE_REQUEST';
export const ORDER_HEDGIE_SUCCESS = 'ORDER_HEDGIE_SUCCESS';

export const orderHedgieRequest = id => ({
  type: ORDER_HEDGIE_REQUEST,
  payload: {
    id,
  },
});

export const orderHedgieSuccess = payload => ({
  type: ORDER_HEDGIE_SUCCESS,
  payload,
});

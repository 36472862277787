import React, { useContext, useState, memo, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { FormError, Modal } from 'components';
import { get } from 'lodash';
import ConsumablesList from './ConsumablesList';
import { InventoryContext } from '../../pages/InventoryPage';
import SellAndConsumeItemPage from '../../../containers/SellAndConsumeItem';
import { TABS } from './index';

const EMPTY_MESSAGE = 'You currently do not have any Consumable item';

const Boosts = memo(() => {
  const { items, error, loading, getUserItems, itemsPagination } = useContext(
    InventoryContext,
  );
  const { limit, offset, total } = itemsPagination;

  const [state, setState] = useState({
    modal: false,
    selectedConsumable: null,
  });

  useEffect(() => {
    getUserItems({
      item_type: TABS.boosts.id,
      limit,
      offset: 0,
      canLoadMore: false,
    });
  }, [getUserItems, limit]);

  function loadMoreItems() {
    if (limit + offset < total) {
      getUserItems({
        item_type: TABS.boosts.id,
        limit,
        offset: limit + offset,
        canLoadMore: true,
      });
    }
  }

  const closeModal = () => {
    setState({ modal: false });
  };

  function selectItem(consumable) {
    setState({
      modal: true,
      selectedConsumable: consumable,
    });
  }

  function handleAfterSellingOrConsumingSuccess() {
    setState({ modal: false });
  }

  const { modal, selectedConsumable } = state;

  const selectOneUserItemId = useMemo(() => {
    return get(selectedConsumable, 'user_item_ids[0]', '');
  }, [selectedConsumable]);

  const sortedItems = [...items];

  sortedItems.sort((a, b) => {
    if (a.scarcity === b.scarcity) {
      return 0;
    } else if (a.scarcity === 'rare') {
      return -1;
    } else if (b.scarcity === 'rare') {
      return 1;
    } else if (a.scarcity === 'special') {
      return -1;
    }
    return 1;
  });

  return (
    <div>
      {!sortedItems ? (
        <div className="align-center">{EMPTY_MESSAGE}</div>
      ) : (
        <React.Fragment>
          <ConsumablesList
            selectItem={selectItem}
            consumables={sortedItems}
            loading={loading}
            loadMore={loadMoreItems}
            canLoadMore={limit + offset < total}
          />
          {error.error ? (
            <div className="align-center">
              <FormError error={error} />
            </div>
          ) : null}
          {modal ? (
            <Modal onClose={closeModal}>
              <SellAndConsumeItemPage
                item={selectedConsumable}
                userItemId={selectOneUserItemId}
                onActionSuccess={handleAfterSellingOrConsumingSuccess}
              />
            </Modal>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
});

Boosts.propTypes = {};

Boosts.defaultProps = {};

export default Boosts;

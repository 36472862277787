import React, { useCallback, useMemo } from 'react';
import { size, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
import LazyLoad from 'react-lazyload';
import { Carousel, HeadLine } from 'components';
import withResponsive from 'helper/withResponsive';
import AnnouncementCard from './AnnouncementCard';

const StyledBaseContainer = styled.div`
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
`;

const StyledContainer = styled(StyledBaseContainer)(
  breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      width: 100%;
    `,
    xl: css`
      max-width: 932px;
    `,
  }),
);

const Announcements = ({
  isMobile,
  announcements,
  getIndexOfAnnouncement,
  openHref,
}) => {
  const handleChangeSlideIndex = useCallback(
    (prev, index) => {
      if (getIndexOfAnnouncement) {
        getIndexOfAnnouncement(prev, index);
      }
    },
    [getIndexOfAnnouncement],
  );

  const numOfAnnouncements = useMemo(() => size(announcements), [
    announcements,
  ]);

  if (isEmpty(announcements)) {
    return null;
  }

  return (
    <StyledContainer>
      <HeadLine title="Announcements" fontSize={isMobile ? 20 : 32} />
      {numOfAnnouncements === 1 ? (
        <AnnouncementCard
          key={announcements[0].id}
          bannerURL={announcements[0].bannerURL}
          href={announcements[0].href}
          openHref={openHref}
          {...announcements[0]}
        />
      ) : (
        <Carousel
          hasPagination
          numberOfItems={numOfAnnouncements}
          hideArrow={isMobile}
          disabledTouchEvent={!isMobile}
          changeSlideIndex={handleChangeSlideIndex}
        >
          {announcements.map(announcement => (
            <LazyLoad height={isMobile ? 200 : 320} once key={announcement.id}>
              <AnnouncementCard
                bannerURL={announcement.bannerURL}
                href={announcement.href}
                openHref={openHref}
                {...announcement}
              />
            </LazyLoad>
          ))}
        </Carousel>
      )}
    </StyledContainer>
  );
};

Announcements.propTypes = {
  isMobile: PropTypes.bool,
  announcements: PropTypes.array,
  getIndexOfAnnouncement: PropTypes.func,
  openHref: PropTypes.func,
};

Announcements.defaultProps = {
  isMobile: false,
  announcements: [],
  getIndexOfAnnouncement: null,
  openHref: null,
};

export default React.memo(withResponsive(Announcements));

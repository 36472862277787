import createReducer from '../createReducer';
import {
  ON_BOARDING_START_JOURNEY,
  ON_BOARDING_GOTO_CHOOSE_DURATION,
  ON_BOARDING_END_JOURNEY,
  ON_BOARDING_RESET_STATE,
  GOTO_JOURNEY_STEP,
} from './actions';

const initialState = {
  currentStep: 'choose_a_world',
  duration: 0,
  worldId: '',
};

export default createReducer(initialState, {
  [ON_BOARDING_GOTO_CHOOSE_DURATION]: (state, action) => {
    state.currentStep = action.payload.nextStep;
    state.worldId = action.payload.worldId;
  },
  [ON_BOARDING_START_JOURNEY]: (state, action) => {
    state.currentStep = action.payload.nextStep;
    state.duration = action.payload.duration;
  },
  [ON_BOARDING_END_JOURNEY]: (state, action) => {
    state.currentStep = action.payload.nextStep;
  },
  [ON_BOARDING_RESET_STATE]: () => {
    return initialState;
  },
  [GOTO_JOURNEY_STEP]: (state, action) => {
    state.currentStep = action.step;
  },
});

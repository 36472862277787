import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';
import { variant } from '@xstyled/system';

const StyledLoader = styled.div`
  ${variant({
    default: 'rec',
    variants: {
      rec: css`
        width: ${p => p.width}px;
        height: ${p => p.height}px;
        background-color: #eff2f3;
        border-radius: ${p => p.height / 2}px;
      `,
      circle: css`
        width: ${p => p.width}px;
        height: ${p => p.height}px;
        border-radius: 50%;
        background-color: #eff2f3;
      `,
      text: css`
        width: ${p => p.width}px;
        height: ${p => p.height}px;
        background-color: #eff2f3;
        border-radius: ${p => p.height / 2}px;
      `,
    },
  })}
`;

const ContentLoader = ({ type, ...props }) => {
  return <StyledLoader variant={type} {...props} />;
};

ContentLoader.propTypes = {
  type: PropTypes.oneOf(['circle', 'rec', 'text']),
  width: PropTypes.number,
  height: PropTypes.number,
};

ContentLoader.defaultProps = {
  type: 'rec',
  width: 80,
  height: 20,
};

export default ContentLoader;

import React from 'react';
import PropTypes from 'prop-types';
import { get, includes, isEmpty, take } from 'lodash';
import { Typography, ViewMore } from 'components';
import styled from 'styled-components';
import energySvgUrl from 'images/energy_icon.svg';
import HedgieEffectInfo from './HedgieEffectInfo';

const StyledWrapper = styled.div``;

const POWER_NAMES = [
  'water',
  'fire',
  'earth',
  'air',
  'luck',
  'prudence',
  'intelligence',
  'charm',
];

const StyledTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function getGeneralEffects(effects) {
  if (isEmpty(effects)) {
    return [];
  }

  const generalEffects = effects.filter(
    effect =>
      !includes(POWER_NAMES, effect.effectType) && !effect.byHouseFurnitureID,
  );
  return isEmpty(generalEffects) ? [] : generalEffects;
}

const GeneralEffects = ({ hedgie, className }) => {
  const hedigeEffect = get(hedgie, 'effects', []);
  const generalEffects = getGeneralEffects(hedigeEffect);

  if (isEmpty(generalEffects)) return null;

  const previewList = take(generalEffects, 2);
  return (
    <StyledWrapper className={className}>
      <StyledTitle>
        <img width={20} src={energySvgUrl} alt="energy_hedgie" />
        <Typography className="pl-1" isBold text="Effects:" color="#62295E" />
      </StyledTitle>
      <ViewMore
        itemsLength={generalEffects.length}
        previewList={
          <div>
            {previewList.map((effect, index) => (
              <HedgieEffectInfo
                {...effect}
                key={`${hedgie.id}-${index}`}
                isLastOne={index === 1}
              />
            ))}
          </div>
        }
        moreList={
          <div>
            {generalEffects.map((effect, index) =>
              index < 2 ? null : (
                <HedgieEffectInfo
                  {...effect}
                  key={index}
                  isLastOne={index === generalEffects.length - 1}
                />
              ),
            )}
          </div>
        }
      />
    </StyledWrapper>
  );
};

GeneralEffects.propTypes = {
  hedgie: PropTypes.object,
  className: PropTypes.string,
};

GeneralEffects.defaultProps = {
  hedgie: null,
  className: null,
};

export default GeneralEffects;

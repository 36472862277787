import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PageTitle, SubHeadingBold, SubHeading, SideHedgie } from 'components';
import Redirect from 'config/redirect';

const MILESTONES = [
  {
    id: 1,
    date: 'March, 2018',
    name: 'Launch Affiliate Program',
    description:
      'Hedgie enthusiasts able to refer their friends and earn Hedgie Community Token (HCT). HCT has a guaranteed fixed price for a Hedgie that will never change.',
    locked: false,
  },
  {
    id: 2,
    date: 'April 2018',
    name: 'Hedgie Airdrop',
    description:
      '500 Essential Hedgies distributed to early members of community. Every Airdrop recipient will be sent a gift code allowing them to redeem an Essential Hedgie.',
    locked: false,
  },
  {
    id: 3,
    date: 'April 2018',
    name: 'Choose & Reserve Hedgie',
    description:
      'Colour picker introduced allowing players to choose their favourite colour Hedgie and reserve it with a Metamask sign-in.',
    locked: false,
  },
  {
    id: 4,
    date: 'August 2018',
    name: 'Purchase & Redeem your Hedgie',
    description:
      'Choose and purchase a Hedgie with a credit card, or redeem with a gift code. Ability to purchase gift codes for those wishing to gift a Hedgie made available. All functionality fully supported on desktop and mobile.',
    locked: false,
  },
  {
    id: 5,
    date: 'August 2018',
    name: 'First Special Edition Campaign',
    description:
      '32 Special Edition Hedgies released to commemorate the 2018 World Cup, one for each participating country.',
    locked: false,
  },
  {
    id: 6,
    date: 'September 2018',
    name: 'Sync with Blockchain, Purchase with Ether',
    description:
      'Hedgie owners able to connect their accounts with Metamask, and sync Hedgies to blockchain. Purchase of a Hedgie made available to everyone with ETH, in addition to credit cards.',
    locked: false,
  },
  {
    id: 7,
    date: 'Fall 2018',
    name: 'Private Alpha Game Launch',
    description:
      'First alpha release of the game made available to select early members of community. Testing and refining the game. First Curio coin made by players.',
    locked: false,
  },
  {
    id: 8,
    date: 'Early 2019',
    name: 'Alpha game launch',
    description:
      'First alpha release of the game made available to early members of community. Testing and refining the game. First Curio coin made by players. Updates and new features added every few weeks.',
    locked: false,
  },
];

const MilestoneContainer = props => {
  const { milestone, index } = props;
  const zebra = index % 2 ? 'grey-light-bgr' : '';
  return (
    <Container fluid className={`milestone-container pos-rel ${zebra}`}>
      <Container
        className={`${index === 0 ? 'pb-5' : 'py-5'} milestone-container-inner`}
      >
        <Row className="milestone-row pod-rel">
          <Col
            md="8"
            className={classnames([
              'milestone-col',
              { locked: milestone.locked, unlocked: !milestone.locked },
            ])}
          >
            <div>
              <SubHeading heading={milestone.date} />
              <div>
                <SubHeadingBold
                  heading={milestone.name}
                  style={{ display: 'inline-block' }}
                  noPadding
                />
                {!milestone.locked && (
                  <div className="callout ff-medium color-contrast fs-extra-md">
                    Unlocked!
                  </div>
                )}
              </div>
              <p>{milestone.description}</p>
            </div>
          </Col>
        </Row>
        {index === 0 && (
          <SideHedgie
            className="side-hedgie-milestones"
            color="blue"
            title=""
            width="350"
            height="400"
          />
        )}
      </Container>
    </Container>
  );
};

MilestoneContainer.propTypes = {
  milestone: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

class RoadmapPage extends Component {
  render() {
    return (
      <div className="roadmap-page">
        <Redirect />
        <main>
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Roadmap 1.0 (2018-2019)" />
                </Col>
              </Row>
            </Container>
          </Container>
          {MILESTONES.map((milestone, index) => {
            return (
              <MilestoneContainer
                index={index}
                key={milestone.id}
                milestone={milestone}
                className="text-center justify-content-center align-items-center"
              />
            );
          })}
        </main>
      </div>
    );
  }
}

RoadmapPage.propTypes = {};
RoadmapPage.defaultProps = {};
export default RoadmapPage;

import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, CustomInput } from 'components';
import styled from 'styled-components';
import { updateUsername } from '../../../store/user/actions';
import FormError from '../FormError';

const ButtonWrapper = styled.div`
  margin-left: -16px;
  margin-top: 10px;
`;

const UsernameRegex = /^[a-z0-9.\-_]{3,32}$/

const UserSchema = Yup.object().shape({
  username: Yup.string()
    .matches(UsernameRegex, 'Invalid username'),
});

const Username = () => {
  const dispatch = useDispatch();
  const currentUsername = useSelector(state => state.session.user.username);
  const updateUsernameError = useSelector(
    state => state.user.updateUsernameError,
  );
  const updateUsernameLoading = useSelector(
    state => state.user.updateUsernameLoading,
  );

  const handleSubmit = useCallback(
    values => {
      dispatch(updateUsername(values));
    },
    [dispatch],
  );
  return (
    <React.Fragment>
      <Formik
        validationSchema={UserSchema}
        onSubmit={handleSubmit}
        initialValues={{
          username: currentUsername,
        }}
      >
        {({ dirty }) => (
          <Form>
            <Field
              name="username"
              component={CustomInput}
              placeholder="Username"
              hasIcon={false}
            />
            <ButtonWrapper>
              <Button disabled={!dirty} type="submit">
                {updateUsernameLoading ? 'Saving...' : 'Update'}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
      {updateUsernameError.error ? (
        <FormError error={updateUsernameError} />
      ) : null}
    </React.Fragment>
  );
};

// Username.propTypes = {};

export default Username;

import { normalize } from 'normalizr';
import { env } from 'config/env';
import Log from 'services/log';
import { entitiesReceive } from './actions';
import * as schemas from './schemas';

const middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.entities) {
    Log.debug(action, 'store/entities:middleware');

    const schema = schemas[meta.entities];
    Log.debug(schema, 'store/entities:middleware:schema');

    if (schema) {
      const { data } = payload;
      const { result, entities } = normalize(
        payload,
        Array.isArray(payload) ? [schema] : schema
      );
      Log.debug({ result, entities, data }, 'store/entities:middleware post normalize');
      store.dispatch(entitiesReceive(entities));
      return next({ ...action, payload: result });
//      return next({ ...action, payload: data });
    }
    Log.debug(`store/entities:middleware no schema for ${ meta.entities }`);

    // istanbul ignore next
    if (env === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        `[entities] There is no ${ meta.entities } schema on schemas.js`
      );
    }
  }
  return next(action);
};

export default middleware;

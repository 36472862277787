import React from 'react';
import { BattleProvider } from './battle-context';
import MainFlow from './MainFlow';
import { useWebsocket } from '../../../contexts/websocket-context';

const Battle = () => {
  const { sendEvent, setSocketMessage, socketMessage } = useWebsocket();
  React.useEffect(() => {
    if (socketMessage.event === 'cancelled_challenge') {
      setSocketMessage({
        event: '',
        data: socketMessage.data,
      })
    };
    return () => {
      sendEvent('cancelled_challenge');
    };
    // eslint-disable-next-line
  }, []);
  return (
    <BattleProvider>
      <MainFlow />
    </BattleProvider>
  );
};

export default Battle;

import React from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';

import CardPurchase from './CardPurchase';

const PurchaseWithCard = props => {
  const className = `pageTitle text-center ${props.className}`;
  return (
    <CardPurchase error={props.error} working={props.working} className={className} price={props.price} onSubmitCC={props.onSubmitCC} />
  );
};

PurchaseWithCard.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number.isRequired,
  onSubmitCC: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

PurchaseWithCard.defaultProps = {
  className: '',
  error: null,
  working: PropTypes.bool,
};

export default PurchaseWithCard;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EEF3F4;
  transition: transform 400ms ease-in-out;
  &.active {
    background-color: #12dfc0;
    border-radius: 50%;
    transform: scale(1.5, 1.5);
    transition: transform 400ms ease-in-out;
  }
`;

const Dot = ({ active, onClick }) => {
  return <StyledDot className={active ? 'active' : null} onClick={onClick} />;
};

Dot.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Dot.defaultProps = {
  active: false,
  onClick: null,
};

export default Dot;

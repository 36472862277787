import { createSelector } from 'reselect';
import NumberUtil from 'services/numberUtil';

export const initialState = {
  currentUser: null,
  errorGettingUser: null,
  errorUpdatingWallet: null,
  updateWalletSuccess: null,
  loading: false,
  curioTransactions: {},
  updateUsernameLoading: false,
  updateUsernameError: {
    error: false,
    message: '',
  },
  transferredCurioError: {
    error: false,
    message: '',
  },
  isOpenTransferModal: false,
  requestOtpSuccess: false,
  verifyingEmailError: null,
  verifyingEmailSucesss: false,
};

export const currentUser = (state = initialState) =>
  state.currentUser || initialState.currentUser;
export const errorGettingUser = (state = initialState) =>
  state.errorGettingUser || initialState.errorGettingUser;
export const errorUpdatingWallet = (state = initialState) =>
  state.errorUpdatingWallet || initialState.errorUpdatingWallet;
export const updateWalletSuccess = (state = initialState) =>
  state.updateWalletSuccess || initialState.updateWalletSuccess;
export const loading = (state = initialState) =>
  state.loading || initialState.loading;
export const curioTransactions = (state = initialState) =>
  state.curioTransactions || initialState.curioTransactions;

const userSessionSelector = state => state.session.user;
const userSelector = state => state.user;
const amountSelector = (state, amount) => amount;

export const selectCurrentUser = createSelector(
  [userSessionSelector],
  user => user,
);

export const selectCurrentCurio = createSelector(
  [userSessionSelector],
  user => user.curioBalance,
);

export const selectRemainingCurio = createSelector(
  [selectCurrentCurio, amountSelector],
  (curioBalance, amount) => NumberUtil.minusTwoBigNumber(curioBalance, amount),
);

export const selectTransferredCurioError = createSelector(
  [userSelector],
  userState => userState.transferredCurioError,
);

export const selectIsOpenModal = createSelector(
  [userSelector],
  userState => userState.isOpenTransferModal,
);

export const requestOtpSuccess = (state = initialState) =>
  state.requestOtpSuccess || initialState.requestOtpSuccess;

export const verifyingEmailError = (state = initialState) =>
  state.verifyingEmailError || initialState.verifyingEmailError;

export const verifyingEmailSucesss = (state = initialState) =>
  state.verifyingEmailSucesss || initialState.verifyingEmailSucesss;
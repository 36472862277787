import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CustomTab } from 'components';
import * as paths from 'config/paths';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import StonesList from './StonesList';
import { StyledFlexBox } from '../../../styles/main';

const StyledContainer = styled.div`
  background-color: ${props => props.theme.palette.gray.light};
  height: 100%;
`;

class SelectGoalPage extends Component {
  componentDidMount() {
    this.props.selectGoal('curio');
  }

  onSwitchTab = activeTab => {
    this.props.selectGoal(activeTab);
  };

  render() {
    const { selectedGoal } = this.props;
    const activeTab = selectedGoal;
    const tabContent = [
      {
        id: 'curio',
        tabName: 'Curio',
        content: (
          <div>
            <StonesList />
          </div>
        ),
      },
      {
        id: 'collections',
        tabName: 'Collections',
        content: <div>Collections</div>,
      },
      {
        id: 'items',
        tabName: 'Items',
        content: <div>Items</div>,
      },
      {
        id: 'random',
        tabName: 'Random',
        content: <div>Random</div>,
      },
    ];
    return (
      <StyledContainer>
        <CustomTab
          tabContent={tabContent}
          switchTab={this.onSwitchTab}
          activeTab={activeTab}
        />
        <StyledFlexBox>
          <Button
            tag={Link}
            size="large"
            className="justify-content-center mt-3 mb-3"
            to={{ pathname: paths.NEW_JOURNEY, search: '?step=4' }}
          >
            Next
          </Button>
        </StyledFlexBox>
      </StyledContainer>
    );
  }
}

SelectGoalPage.propTypes = {
  test: PropTypes.string,
  selectGoal: PropTypes.func,
  clearGoal: PropTypes.func,
  selectedGoal: PropTypes.string,
};

SelectGoalPage.defaultProps = {
  test: '',
  selectGoal: null,
  selectedGoal: '',
  clearGoal: null,
};

export default SelectGoalPage;

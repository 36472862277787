import React, { memo } from 'react';
import { Resources, Button } from 'components';
import { Link } from 'react-router-dom';
import * as paths from 'config/paths';

const ResourcesTab = memo(() => {
  return (
    <React.Fragment>
      <Resources />
      <div className="align-center pt-3">
        <Button tag={Link} to={paths.HEDGIE_HOUSE}>
          Build Furniture
        </Button>
      </div>
    </React.Fragment>
  );
});

ResourcesTab.propTypes = {};

export default ResourcesTab;

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const getCurrentUserRequest = () => ({
  type: GET_CURRENT_USER_REQUEST,
  payload: {},
});

export const getCurrentUserSuccess = payload => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload,
});

export const getCurrentUserFailure = (error) => ({
  type: GET_CURRENT_USER_FAILURE,
  error: true,
  payload: error,
});

//  Update Current User's Wallet (MetaMask)
export const UPDATE_USER_WALLET_REQUEST = 'UPDATE_USER_WALLET_REQUEST';
export const UPDATE_USER_WALLET_SUCCESS = 'UPDATE_USER_WALLET_SUCCESS';
export const UPDATE_USER_WALLET_FAILURE = 'UPDATE_USER_WALLET_FAILURE';

export const updateUserWalletRequest = payload => ({
  type: UPDATE_USER_WALLET_REQUEST,
  payload,
});

export const updateUserWalletSuccess = payload => ({
  type: UPDATE_USER_WALLET_SUCCESS,
  payload,
});

export const updateUserWalletFailure = error => ({
  type: UPDATE_USER_WALLET_FAILURE,
  error: true,
  payload: error,
});

// Get transaction history
export const GET_CURIO_TRANSACTIONS_REQUEST = 'GET_CURIO_TRANSACTIONS_REQUEST';
export const GET_CURIO_TRANSACTIONS_SUCCESS = 'GET_CURIO_TRANSACTIONS_SUCCESS';
export const GET_CURIO_TRANSACTIONS_FAILURE = 'GET_CURIO_TRANSACTIONS_FAILURE';

export const getCurioTransactionsRequest = payload => ({
  type: GET_CURIO_TRANSACTIONS_REQUEST,
  payload,
});
export const getCurioTransactionsSuccess = payload => ({
  type: GET_CURIO_TRANSACTIONS_SUCCESS,
  payload,
});
export const getCurioTransactionsFailure = error => ({
  type: GET_CURIO_TRANSACTIONS_FAILURE,
  error: true,
  payload: error,
});

export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAILURE = 'UPDATE_USER_NAME_FAILURE';

export const updateUsername = payload => ({
  type: UPDATE_USER_NAME,
  payload,
});

export const TRANSFER_CURIO = 'TRANSFER_CURIO';
export const TRANSFER_CURIO_SUCCESS = 'TRANSFER_CURIO_SUCCESS';
export const TRANSFER_CURIO_FAILURE = 'TRANSFER_CURIO_FAILURE';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const toggleTransferCurioModal = isOpen => ({
  type: TOGGLE_MODAL,
  payload: isOpen,
});

export const transferCurioSuccess = payload => ({
  type: TRANSFER_CURIO_SUCCESS,
  payload,
});

export const transferCurioFailure = e => ({
  type: TRANSFER_CURIO_FAILURE,
  payload: e,
});

export const transferCurio = payload => ({
  type: TRANSFER_CURIO,
  payload,
});


export const REQUEST_OTP_CODE = 'REQUEST_OTP_CODE';
export const REQUEST_OTP_CODE_FAILURE = 'REQUEST_OTP_CODE_FAILURE';
export const REQUEST_OTP_CODE_SUCCESS = 'REQUEST_OTP_CODE_SUCCESS';

export const VERIFY_EMAIL_ADDRESS = 'VERIFY_EMAIL_ADDRESS';
export const VERIFY_EMAIL_ADDRESS_FAILURE = 'VERIFY_EMAIL_ADDRESS_FAILURE';
export const VERIFY_EMAIL_ADDRESS_SUCCESS = 'VERIFY_EMAIL_ADDRESS_SUCCESS';

export const requestOtpCode = () => ({
  type: REQUEST_OTP_CODE,
});

export const requestOtpCodeFailure = e => ({
  type: REQUEST_OTP_CODE_FAILURE,
  payload: e,
});

export const requestOtpCodeSuccess = payload => ({
  type: REQUEST_OTP_CODE_SUCCESS,
  payload: payload,
});

export const verifyEmailAddress = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS,
  payload
});

export const verifyEmailAddressFailure = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS_FAILURE,
  payload
});

export const verifyEmailAddressSuccess = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS_SUCCESS,
  payload
});

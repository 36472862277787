import { createSelector } from 'reselect';

const leaderBoardSelector = state => state.leaderboard;

export const selectLeaderBoardList = createSelector(
  [leaderBoardSelector],
  board => board.list,
);

export const selectMyTopHedgiesList = createSelector(
  [leaderBoardSelector],
  board => board.myTopHedgies,
);
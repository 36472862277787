import React from 'react';
import { Col, Row } from 'reactstrap';
import { HedgieImg } from 'components';
import useMedia from '../../custom-hooks/useMedia';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import POINTS from './points'
import Point from './Point'
import energyIcon2 from 'images/powers/energy2.svg';

const StyledText = styled.div`
color: #fff;
`;

const StyledPoint = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #accacc;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid #accacc;
  padding: 5px 0;
  min-height: 64px;
  border-radius: ${(p) => (p.isMobile ? '27px' : 0)};
  background-color: ${(p) =>
        p.isHighlight ? '#12DFC0' : p.isMobile ? 'rgba(0,0,0,.4)' : '#fff'};
  .point-name {
    color: ${(p) => (p.isMobile ? '#fff' : '#000')};
  }
`;

const StyledWrapper = styled.div`
  .show-att {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
    transition: all ease-in-out 300ms;
  }
  .hide-att {
    transition: all ease-in-out 300ms;
    visibility: hidden;
    opacity: 0;
    transform: scale(0, 0);
  }
`;

const Participants = ({ participants, myUserID }) => {
    const hedgieImgWidth = useMedia(
        ['(min-width: 992px)', '(max-width: 768px)'],
        [360, 160],
        360,
    );

    if (participants === undefined) {
        return null
    }

    const { me, opponent, } = participants[0].hedgie.owner === myUserID ? { me: participants[0], opponent: participants[1] } : { me: participants[1], opponent: participants[0] }
    return (
        <div>
            <Row gutter={20}>
                {[me.hedgie, opponent.hedgie].map((hg) => {
                    return (
                        < Col md={6} lg={6} sm={6} xs={6} className="align-center" key={hg.id} >
                            <div className="text-center">
                                <HedgieImg
                                    color={`#${hg.color}`}
                                    className="img-fluid"
                                    width={hedgieImgWidth}
                                    imgURL={hg? hg.imgURL: null}
                                />
                                <StyledText
                                    className="font-weight-bold"
                                    isMobile={false}
                                    hasDarkBg={false}
                                >
                                    {hg.ownerName ? hg.ownerName : 'Anonymous'}
                                </StyledText>
                                <h3>#{hg.color}</h3>
                            </div>
                        </Col>
                    )
                })}
            </Row >
            <Element name="battle-attribute">
                <StyledWrapper className="pt-4">
                    {POINTS.map((point, index) => (
                        <Point
                            key={point.id}
                            id={point.id}
                            className='show-att'
                            opponentPoint={opponent[point.id]}
                            iconUrl={point.url}
                            text={point.text || ''}
                            ownerPoint={me[point.id]}
                            isMobile={false}
                        />
                    ))}
                    {me.sub_total_points === opponent.sub_total_points ? (
                        <div>
                            <div className="text-center pt-3 pb-3">It’s a Tie!</div>
                            <StyledPoint isHighlight={true} isMobile={false}>
                                <div>{me.energy_points} points</div>
                                <img width={40} src={energyIcon2} alt="point" />
                                <div>{opponent.energy_points} points</div>
                            </StyledPoint>
                        </div>
                    ) : null}
                    <StyledPoint isMobile={false}>
                        <div>
                            {me.sub_total_points + me.energy_points}{' '} points
                                </div>
                        <span>Total</span>
                        <div>
                            {opponent.sub_total_points + opponent.energy_points} points
                                </div>
                    </StyledPoint>
                    <StyledPoint className={'show-att'} isMobile={false}>
                        <div>
                            {me.total_points > opponent.total_points ? (
                                <span className="blink_me">
                                    You win!{' '}
                                    <span role="img" aria-label="funny">
                                        🤩
                                    </span>
                                </span>
                            ) : (
                                    <span>
                                        {' '}
                                        <span role="img" aria-label="sad">
                                            😓
                                        </span>
                                        {' '}You lose
                                    </span>
                                )}
                        </div>
                        <div style={{ fontSize: 30 }}>
                            <span role="img" aria-label="cup">
                                🏆
                        </span>
                        </div>
                        <div>{opponent.isWinner ? <span>Winner!</span> : ''}</div>
                    </StyledPoint>
                </StyledWrapper>
            </Element>
        </div>
    );
}

Participants.propTypes = {
    myUserID: PropTypes.string.isRequired,
    participants: PropTypes.array.isRequired,
};

export default Participants;
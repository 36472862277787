import React from 'react';
import PropTypes from 'prop-types';

const TooltipsModal = ({
  body,
  title,
}) => {
  return (
    <div className="tooltip-holder">
      <div className="tooltip-holder-inner  color-contrast ayuda-ruben">
        <div className="pos-rel text-center">
          <label htmlFor="?">
            <span className="c-tooltip single-item ff-extra-bold fs-lg color-contrast text-center title-with-tooltip top-modal-center">?</span>
          </label>
        </div>
        {title}
        {body}
      </div>
    </div>
  );
};

TooltipsModal.propTypes = {
  body: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
};

export default TooltipsModal;

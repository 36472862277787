import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JourneyWorldsGrid, HedgieImg, CountDown } from 'components';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';
import { Element } from 'react-scroll';
import WelcomeInfo from './WelcomeInfo';
import ChooseDuration from './ChooseDuration';
import EndOnBoarding from './EndOnBoarding';
import {
  eventCategories,
  trackClickingNextWithWorldSelected,
  trackClickingOnDifferentWorlds,
  trackDifferentDuration,
  trackStartingtAJourney,
} from '../../../tracking';

const StyledBgHeader = styled.div`
  background-image: ${props => `linear-gradient(
      rgba(0, 0, 0, .5), 
      rgba(0, 0, 0, .5)
    ), url(${props.background})`};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.isMobile ? 'center right' : 'center center'};
  background-size: cover;
  background-attachment: scroll;
`;

const StyledHeaderContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const StyledInfo = styled.div`
  padding: ${props => (props.isMobile ? '75px 0 87px 0' : '174px 0 160px 0')};
  width: 100%;
  > div {
    height: ${props => (props.isMobile ? '210px' : '230px')};
  }
`;

const StyledWorldsList = styled.div`
  position: ${props => (props.isMobile ? null : 'relative')};
  margin: ${props => `${props.isMobile ? 0 : -50}px auto 0 auto`};
  z-index: 9;
  max-width: 940px;
  transition: all ease-in-out 450ms;
`;

const StyledHedgieImg = styled.div`
  position: absolute;
  bottom: ${props => (props.isMobile ? '-65px' : '-80px')};
  right: ${props => (props.isMobile ? '-25px' : 0)};
  width: ${props => (props.isMobile ? '150px' : '290px')};
  transform: ${props => (props.isMobile ? 'rotate(8deg)' : 'rotate(0deg)')};
  transition: all ease-in-out 450ms;
  &.moving {
    transform: translateX(50%) rotate(0deg);
    right: 50%;
    transition: all ease-in-out 450ms;
    width: ${props => (props.isMobile ? '150px' : '226px')};
  }
`;

const StyledRibbonHedgieGroup = styled.div`
  position: relative;
  .ribbon {
    position: absolute;
    left: ${props => (props.isMobile ? '-50%' : '-212px')};
    top: ${props => (props.isMobile ? '-100px' : '-70px')};
  }
`;

const STEPS = {
  CHOOSE_A_WORLD: 'choose_a_world',
  CHOOSE_DURATION: 'choose_duration',
  COUNT_DOWN: 'count_down',
  END_ON_BOARDING: 'end_on_boarding',
};

const DURATIONS_SECONDS = [5, 7, 10];

class HeaderHomePage extends Component {
  onDurationComplete = () => {
    this.props.finishOnboarding(STEPS.END_ON_BOARDING);
  };

  selectWorld = world => () => {
    this.props.selectWorld(world);
    trackClickingOnDifferentWorlds(world.name, eventCategories.FREE_GAME);
  };

  handleDurationChange = ({ value }) => {
    const { duration } = value;
    trackDifferentDuration(duration, eventCategories.FREE_GAME);
  };

  gotoChooseDuration = () => {
    this.props.gotoChooseDuration(
      STEPS.CHOOSE_DURATION,
      this.props.selectedWorld.id,
    );
    trackClickingNextWithWorldSelected(
      this.props.selectedWorld.name,
      eventCategories.FREE_GAME,
    );
  };

  startJourney = ({ duration }) => {
    this.props.startJourney(STEPS.COUNT_DOWN, duration);
    trackStartingtAJourney(duration, eventCategories.FREE_GAME);
  };

  render() {
    const {
      worlds,
      isMobile,
      onboarding,
      selectedWorld,
      history,
      emitHeaderVersion,
    } = this.props;
    const steps = {
      [STEPS.CHOOSE_A_WORLD]: (
        <WelcomeInfo handleNext={this.gotoChooseDuration} isMobile={isMobile} />
      ),
      [STEPS.CHOOSE_DURATION]: (
        <ChooseDuration
          startJourney={this.startJourney}
          unitDuration="sec"
          onDurationChange={this.handleDurationChange}
          durationsDataList={DURATIONS_SECONDS}
        />
      ),
      [STEPS.END_ON_BOARDING]: (
        <EndOnBoarding
          isMobile={isMobile}
          history={history}
          emitHeaderVersion={emitHeaderVersion}
        />
      ),
    };
    const { currentStep, duration } = onboarding;
    return (
      <div>
        <Element name="#onboarding-header" />
        <StyledBgHeader
          background={selectedWorld ? selectedWorld.imageURL : ''}
        >
          <StyledHeaderContainer>
            <StyledInfo isMobile={isMobile}>
              <div>{steps[currentStep]}</div>
            </StyledInfo>
            <StyledHedgieImg
              className={currentStep !== STEPS.CHOOSE_A_WORLD ? 'moving' : ''}
              isMobile={isMobile}
            >
              <StyledRibbonHedgieGroup isMobile={isMobile}>
                {currentStep === STEPS.COUNT_DOWN ? (
                  <CountDown
                    isMobile={isMobile}
                    className="ribbon"
                    duration={duration}
                    isDemo
                    onDurationComplete={this.onDurationComplete}
                  />
                ) : null}
                <HedgieImg color="#F57A17" width={isMobile ? 150 : '100%'} />
              </StyledRibbonHedgieGroup>
            </StyledHedgieImg>
          </StyledHeaderContainer>
        </StyledBgHeader>
        {currentStep === STEPS.CHOOSE_A_WORLD ? (
          <StyledWorldsList isMobile={isMobile}>
            <JourneyWorldsGrid
              worlds={worlds}
              selectedWorld={selectedWorld}
              selectWorld={this.selectWorld}
            />
          </StyledWorldsList>
        ) : null}
      </div>
    );
  }
}

HeaderHomePage.propTypes = {
  worlds: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  worldsLoading: PropTypes.bool,
  gotoChooseDuration: PropTypes.func,
  startJourney: PropTypes.func,
  selectWorld: PropTypes.func,
  emitHeaderVersion: PropTypes.func,
  finishOnboarding: PropTypes.func,
  selectedWorld: PropTypes.object,
  onboarding: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

HeaderHomePage.defaultProps = {
  isMobile: false,
  gotoChooseDuration: null,
  selectedWorld: null,
  finishOnboarding: null,
  onboarding: null,
  startJourney: null,
  selectWorld: null,
  emitHeaderVersion: null,
  worldsLoading: false,
};

export default withResponsive(HeaderHomePage);

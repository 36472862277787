import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import could2 from '../images/could2.png';
import Treasures from './Treasures';

const TreasuresSlide = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer
        offset={4}
        speed={0}
        style={{ backgroundColor: '#FFCA61' }}
      />
      <ParallaxLayer offset={isMobile ? 4.4 : 4.2} speed={-0.25}>
        <img
          src={could2}
          style={{
            width: isMobile ? '80%' : '50%',
            marginLeft: isMobile ? '10%' : '44%',
          }}
          alt="treasure"
        />
      </ParallaxLayer>
      <ParallaxLayer offset={isMobile ? 4.05 : 4.1} speed={0.1} key="Treasures">
        <Treasures isMobile={isMobile} onNextPage={onNextPage} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

TreasuresSlide.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

TreasuresSlide.defaultProps = {
  isMobile: false,
};

export default TreasuresSlide;

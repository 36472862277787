import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageTitle, Button, SubHeading, SubHeadingBold } from 'components';
import PartOfRevolutionImage from 'images/part-of-revolution.png';
import GetPromotedSiteImage from 'images/get-promoted-site.png';
import GetPromotedGameImage from 'images/get-promoted-game.png';

import ChooseYourOfferingImage from 'images/thumbs-up.png';
import GetPaidInCurioImage from 'images/curio-circle.png';
import MonetizeTheCurioImage from 'images/moneybag.png';

import Redirect from 'config/redirect';

const ActionImage = ({ className, src, alt }) => (
  <img src={src} className={`${className} rounded-circle`} alt={alt} />
);

const StyledActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`;

const StyledBusinessActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  margin: 10px 10px;
`;

const handleGetInTouch = e => {
  e.preventDefault();
  window.open('mailto:hedgie@hedgie.io');
};

class PartnerWithHedgiePage extends Component {
  render() {
    const rowClass = 'py-5 ';
    return (
      <div>
        <Redirect />
        <main>
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Partner with Hedgie" />
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col md="6">
                  <SubHeadingBold
                    heading="Why Hedgie"
                    className="text-center text-md-left"
                  />
                  <p>
                    We’re so glad you asked! Hedgie is your friendly
                    introduction to the cryptocoin revolution. Be among the
                    early, forward-thinking businesses who are making themselves
                    available to cryptocoin owners (and spenders!). As a
                    Hedgie-Approved business, you’ll be promoted on the Hedgie
                    website, and within the game itself to players looking for a
                    place to spend their hard-earned coin.
                  </p>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={PartOfRevolutionImage}
                          alt="Be part of the crypto revolution"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Be part of the crypto revolution" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={GetPromotedSiteImage}
                          alt="Get promoted on the Hedgie site and game"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Get promoted on the Hedgie site" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={GetPromotedGameImage}
                          alt="Say hello to new customers"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Say hello to new customers" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="grey-light-bgr">
            <Container>
              <Row className="pt-5">
                <Col md="12" className="text-center">
                  <SubHeadingBold heading="How It Works" />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <p className="text-left text-md-center">
                    Hedgie has its own unique form of cryptocoin called Curio.
                    This is what players earn by playing the game. They can then
                    use it to spend on your business’s products and services.
                    Here’s how to get started...{' '}
                  </p>
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={ChooseYourOfferingImage}
                        style={{ width: '170px' }}
                        alt=""
                      />
                      <SubHeadingBold
                        heading="Choose Your Offering"
                        className="pt-3"
                      />
                      <p className="text-left text-md-center pt-3 pb-5">
                        Decide what products and services you want to be made
                        available, and how much Curio you’re willing to accept.
                        It could be 100% on smaller items, or as little as 5% of
                        the cost.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={GetPaidInCurioImage}
                        style={{ width: '170px' }}
                        alt=""
                      />
                      <SubHeadingBold
                        heading="Get Paid in Curio"
                        className="pt-3"
                      />
                      <p className="text-left text-md-center">
                        Let the coin roll in as Hedgie players spend Curio on
                        your products and services!
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={MonetizeTheCurioImage}
                        style={{ width: '170px' }}
                        alt=""
                      />
                      <SubHeadingBold heading="Spend Curio" className="pt-3" />
                      <p className="text-left text-md-center">
                        At other participating businesses, in the Hedgie game,
                        or use it for employee perks.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid>
            <Container>
              <Row className="pt-5">
                <Col md="12" className="text-center">
                  <SubHeadingBold heading="Let’s get started!" />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <p className="text-left text-md-center">
                    We’re always digging around for potential partners. Learn
                    more about our partnership opportunities and what Hedgie can
                    do for your business. This is the start of something
                    beautiful, we just know it!
                  </p>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col className="text-center">
                  <Button size="large" onClick={handleGetInTouch}>
                    Get in touch
                  </Button>
                </Col>
              </Row>
            </Container>
          </Container>
        </main>
      </div>
    );
  }
}

PartnerWithHedgiePage.propTypes = {};

PartnerWithHedgiePage.defaultProps = {};

export default PartnerWithHedgiePage;

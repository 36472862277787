import { connect } from 'react-redux';
import { fromHedgies } from 'store/selectors';
import {
  sellItem,
  resetSellItemState,
  consumeItem,
  resetConsumableItem,
} from 'store/actions';
import { selectSoldItemState } from '../store/item/selectors';
import { selectConsumeItemState } from '../store/effect/selectors';
import SellAndConsumeItemPage from '../components/pages/SellAndConsumeItemPage';

const mapState = state => ({
  soldItemState: selectSoldItemState(state),
  consumeItemState: selectConsumeItemState(state),
  hedgies: fromHedgies.myHedgies(state),
});

export default connect(
  mapState,
  {
    sellItem,
    resetSellItemState,
    consumeItem,
    resetConsumableItem,
  },
)(SellAndConsumeItemPage);

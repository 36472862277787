import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POWER_LABEL } from 'const/common';
import { Icon } from 'components';
import { hedgie as hedgieType } from 'types';
import { forEach, isEmpty, get } from 'lodash';
import HedgiePowerChart from '../HedgiePowerChart';

const PowerRow = styled.div`
  display: flex;
  justify-content: center;
  //flex-wrap: wrap;
`;

const PowerItem = styled.div`
  flex: 1;
  margin-bottom: 20px;
  text-align: center;
`;

const PowerTitle = styled.h2`
  margin: 10px 0;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 800;
  color: #20041e;
`;

const PowerLabel = ({ label }) => (
  <span className="power-label ff-semi-bold">{label}</span>
);

PowerLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const PerformsLabel = ({ label }) => (
  <span className="performs-label">{label}</span>
);

PerformsLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const hedgiePowerValue = hedgie => {
  return attr => {
    if (!hedgie) {
      return '0';
    }
    return `${hedgie[attr]}`;
  };
};

const PowerItemContainer = memo(({ label, name, value, circleBg, amount }) => (
  <PowerItem key={label}>
    <HedgiePowerChart
      circleBg={circleBg}
      value={parseFloat(value)}
      powerName={name}
      label={label}
      amount={amount}
    />
  </PowerItem>
));

PowerItemContainer.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  circleBg: PropTypes.string,
  amount: PropTypes.number,
};

PowerItemContainer.defaultProps = {
  circleBg: null,
  amount: 0,
};

const PowerItemPerformsContainer = memo(
  ({ label, name, performsIn, performsLabel }) => (
    <PowerItem key={label}>
      <PowerLabel label={label} />
      <Icon name={name} />
      <PerformsLabel label={`${performsLabel} ${performsIn || ''}`} />
    </PowerItem>
  ),
);

PowerItemPerformsContainer.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  performsIn: PropTypes.string,
  performsLabel: PropTypes.string,
};

PowerItemPerformsContainer.defaultProps = {
  performsLabel: 'Performs best in the',
  performsIn: null,
};

const getNewAmount = (powerName, currentValue, effects) => {
  if (isEmpty(effects)) {
    return 0;
  }
  let amount = 0;
  forEach(effects, (value, key) => {
    if (value.effectType === powerName) {
      let newAmount = amount;
      if (value.effectUnit === 'percentage') {
        newAmount = (value.effectValue * currentValue) / 100;
      } else {
        newAmount = value.effectValue;
      }
      amount += newAmount;
    }
  });
  return amount;
};

const HedgiePower = memo(props => {
  const { hedgie, showTitle, performsView, circleBg } = props;
  const hedigeEffect = get(hedgie, 'effects', []);

  const hedgiePowerBuilder = hedgiePowerValue(hedgie);
  let ItemContainer;
  if (performsView) {
    ItemContainer = PowerItemPerformsContainer;
  } else {
    ItemContainer = PowerItemContainer;
  }
  return (
    <div>
      {!showTitle && <PowerTitle>Power</PowerTitle>}
      <PowerRow>
        {POWER_LABEL.map(power => {
          const currentValue = hedgiePowerBuilder(power.powerName);
          const amount = useMemo(
            () => getNewAmount(power.powerName, currentValue, hedigeEffect),
            [power.powerName, currentValue],
          );
          return (
            <ItemContainer
              key={power.name}
              label={power.label}
              name={power.name}
              performsIn={power.performsIn}
              value={hedgiePowerBuilder(power.powerName)}
              circleBg={circleBg}
              performsLabel={power.performsLabel}
              amount={amount}
            />
          );
        })}
      </PowerRow>
    </div>
  );
});

HedgiePower.propTypes = {
  hedgie: hedgieType,
  performsView: PropTypes.bool,
  showTitle: PropTypes.bool,
  circleBg: PropTypes.string,
};

HedgiePower.defaultProps = {
  hedgie: null,
  performsView: false,
  showTitle: true,
  circleBg: '#fff',
};

export default HedgiePower;

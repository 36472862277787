import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
// import styled from 'styled-components';
import { HedgiePower, HedgiePlaceholder } from 'components';
import { HedgieImgContainer, hedgieImgWidth } from './index';
import * as paths from 'config/paths';
import { MyHedgiesContext } from '../../pages/NewMyHedgiesPage/MyHedgiesWrapper';

/* const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
 */
const HedgiePreLoader = ({ history }) => {
  const {selectedHedgie} = useContext(MyHedgiesContext);

  const handleOnSelectHedgie = useCallback(() => {
    history.push(paths.GET_HEDGIE);
  }, [history]);

  return (
    !selectedHedgie && (
      <Row>
        <Col md="6">
          <HedgieImgContainer>
            <HedgiePlaceholder
              className="img-fluid"
              width={hedgieImgWidth}
              onGetHedgie={handleOnSelectHedgie}
            />
          </HedgieImgContainer>
        </Col>
        <Col md="6">
          <div
            style={{
              marginTop: '62px',
              marginLeft: '38px',
              maxWidth: '300px',
              margin: 'auto',
            }}
          >
            <HedgiePower hedgie={null} />
            {/* <CenteredContainer>
              <Button size="large" type="submit" onClick={handleOnSelectHedgie}>
                Choose Your Hedgie now!
              </Button>
            </CenteredContainer> */}
          </div>
        </Col>
      </Row>
    )
  );
};

HedgiePreLoader.propTypes = {
  history: PropTypes.object,
};

HedgiePreLoader.defaultProps = {
  history: null,
};

export default withRouter(HedgiePreLoader);

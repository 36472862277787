import styled from 'styled-components';

export const Form = styled.form`

`;

export const InputContainer = styled.div`

`;

export const InputEmail = styled.input`

`;

export const SubmitButton = styled.button`
  bottom: -90px !important;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  z-index: 100;
  &:focus {
    outline: none;
  }
`;

export const SubtotalWrapper = styled.div`
  margin: auto;
`;


export const BoldSpan = styled.span`
  font-weight: bold;
`;

import { put, call, fork, take, cancel } from 'redux-saga/effects';
import Log from 'services/log';
import { scroller } from 'react-scroll';
import { scrollableOption } from 'config/scroller';
import cogoToast from 'cogo-toast';
import {
  BUILD_FURNITURE,
  CANCEL_REQUEST,
  CLEAN_HOUSE,
  GET_FURNITURE_FOR_HOUSE,
  GET_HOUSE_EFFECTS,
  GET_HOUSES,
  GET_RESOURCES_META,
} from './actionTypes';
import {
  buildFurnitureFailure,
  buildFurnitureSuccess,
  cleanHouseFailure,
  cleanHouseSuccess,
  getFurnitureForHouseFailure,
  getFurnitureForHouseSuccess,
  getHouseEffectsFailure,
  getHouseEffectsSuccess,
  getHousesFailure,
  getHousesSuccess,
  getResourcesMetaFailure,
  getResourcesMetaSuccess,
} from './actions';
import { getCurrentUserRequest } from '../user/actions';

export function* refreshToken(api, firebase) {
  const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

  if (!idToken) {
    throw Error('saga: getMyHedgies: No access token in session');
  }
  yield call([api, api.setToken], idToken);
}

export function* getHouseEffects(api, houseId) {
  try {
    Log.debug('saga: getHouseEffects');
    const response = yield call([api, api.get], `/houses/${houseId}`);

    Log.debug('saga: getHouseEffects:response');
    yield put(getHouseEffectsSuccess(response));
  } catch (e) {
    Log.debug('saga: getHouseEffects:failure');
    yield put(getHouseEffectsFailure(e));
  }
}

export function* getResourcesMeta(api) {
  try {
    Log.debug('saga: getResourcesMeta');
    const response = yield call([api, api.get], '/meta/resources');
    Log.debug('saga: getResourcesMeta:response');
    yield put(getResourcesMetaSuccess(response));
  } catch (e) {
    Log.debug('saga: getResourcesMeta:failure');
    yield put(getResourcesMetaFailure(e));
  }
}

export function* getFurnitureForHouse(api, houseId) {
  try {
    Log.debug('saga: getFurnitureForHouse');
    const response = yield call(
      [api, api.get],
      `/houses/${houseId}/furnitures`,
    );
    Log.debug('saga: getFurnitureForHouse:response');
    yield put(getFurnitureForHouseSuccess(response));
  } catch (e) {
    Log.debug('saga: getFurnitureForHouse:failure');
    yield put(getFurnitureForHouseFailure(e));
  }
}

export function* getHouses(api) {
  try {
    Log.debug('saga: getHouses');
    const response = yield call([api, api.get], '/houses');
    Log.debug('saga: getHouses:response');
    yield put(getHousesSuccess(response));
    yield call(getFurnitureForHouse, api, response.data[0].id);
    yield call(getHouseEffects, api, response.data[0].id);
  } catch (e) {
    Log.debug('saga: getHouses:failure');
    yield put(getHousesFailure(e));
  }
}

export function* buildFurniture(api, { houseId, furnitureId }) {
  try {
    Log.debug('saga: buildFurniture');
    const response = yield call(
      [api, api.post],
      `/houses/${houseId}/furnitures/${furnitureId}/upgrade`,
    );
    Log.debug('saga: buildFurniture:response');
    yield put(buildFurnitureSuccess(response));
    yield call(getHouseEffects, api, houseId);
    scroller.scrollTo(response.data.type, scrollableOption);
    yield put(getCurrentUserRequest());
  } catch (e) {
    Log.debug('saga: buildFurniture:failure');
    yield put(buildFurnitureFailure(e));
  }
}

export function* cleanHouse(api, { houseId, resourceType, amount }) {
  try {
    const response = yield call([api, api.post], `/houses/${houseId}/clean`, {
      resourceType,
      amount,
    });
    yield put(cleanHouseSuccess(response));
    cogoToast.success("Your hedgies' house has been cleaned");
  } catch (e) {
    yield put(cleanHouseFailure(e));
  }
}

export function* watchGetResourcesMeta(api) {
  while (true) {
    const { payload } = yield take(GET_RESOURCES_META);
    yield call(getResourcesMeta, api, payload);
  }
}

export function* watchGetHouses(api) {
  while (yield take(GET_HOUSES)) {
    const getHouseTask = yield fork(getHouses, api);
    yield take(CANCEL_REQUEST);
    yield cancel(getHouseTask);
  }
}

export function* watchGetFurnitureForHouse(api) {
  while (true) {
    const { payload } = yield take(GET_FURNITURE_FOR_HOUSE);
    yield call(getFurnitureForHouse, api, payload);
  }
}

export function* watchBuildFurniture(api) {
  while (true) {
    const { payload } = yield take(BUILD_FURNITURE);
    yield call(buildFurniture, api, payload);
  }
}

export function* watchGetHouseEffects(api) {
  while (true) {
    const { payload } = yield take(GET_HOUSE_EFFECTS);
    yield call(getHouseEffects, api, payload);
  }
}

export function* watchCleanHouse(api) {
  while (true) {
    const { payload } = yield take(CLEAN_HOUSE);
    yield call(cleanHouse, api, payload);
  }
}

export default function* ({ api }) {
  yield fork(watchGetResourcesMeta, api);
  yield fork(watchGetHouses, api);
  yield fork(watchGetFurnitureForHouse, api);
  yield fork(watchBuildFurniture, api);
  yield fork(watchGetHouseEffects, api);
  yield fork(watchCleanHouse, api);
}

import faDiscord from '@fortawesome/fontawesome-free-brands/faDiscord';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faMediumM from '@fortawesome/fontawesome-free-brands/faMediumM';
import faReddit from '@fortawesome/fontawesome-free-brands/faReddit';
import faTelegramPlane from '@fortawesome/fontawesome-free-brands/faTelegramPlane';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';

export const brandIcons = {
  faDiscord,
  faFacebookF,
  faInstagram,
  faMediumM,
  faReddit,
  faTelegramPlane,
  faTwitter,
  faYoutube,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
`;

const StyledStoneHolder = styled.div`
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border: ${props => (props.active ? '4px solid #12DFC0' : undefined)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all ease-in-out 300ms;
`;

const StyledLabel = styled.span`
  font-weight: 400;
  font-size: ${props => (props.isMobile ? '18px' : '16px')};
  color: #000000;
  text-align: center;
  line-height: 22px;
  display: block;
  margin-top: ${props => (props.isMobile ? '10px' : '30px')};
`;

const ImgeContainer = styled.div`
  background-image: ${props => (props.url ? `url(${props.url})` : undefined)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: ${props => (props.isMobile ? '34px' : '94px')};
  height: ${props => (props.isMobile ? '46px' : '129px')};
`;

const Stone = ({ stone, isMobile, active, onClick, loading }) => {
  const stoneHolderSize = isMobile ? 68 : 191;
  return (
    <StyledContainer onClick={onClick}>
      <StyledStoneHolder size={stoneHolderSize}>
        {loading ? null : (
          <ImgeContainer
            url={isMobile ? stone.mobileUrl : stone.url}
            isMobile={isMobile}
          />
        )}
      </StyledStoneHolder>
      <StyledLabel isMobile={isMobile}>
        {loading ? 'loading....' : stone.label}
      </StyledLabel>
    </StyledContainer>
  );
};

Stone.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  stone: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
};

Stone.defaultProps = {
  active: false,
  loading: false,
  onClick: null,
  stone: {
    url: '',
    label: 'Name',
  },
};

export default withResponsive(Stone);

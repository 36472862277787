import React, { useState, memo } from 'react';
// import PropTypes from 'prop-types';
import { CustomTab } from 'components';
import Gear from './Gear';
import Tools from './Tools';
import Boosts from './Boosts';
import ResourcesTab from './ResourcesTab';

export const TABS = {
  gear: {
    id: 'gear',
    tabName: 'Gear',
  },
  tools: {
    id: 'collective',
    tabName: 'Tools',
  },
  boosts: {
    id: 'consumable',
    tabName: 'Boosts',
  },
  resources: {
    id: 'resources',
    tabName: 'Resources',
  },
};

const InventoryTabs = memo(() => {
  const [activeTab, setActiveTab] = useState(TABS.boosts.id);

  function switchTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const tabContent = [
    {
      ...TABS.gear,
      content: <Gear />,
    },
    {
      ...TABS.tools,
      content: <Tools />,
    },
    {
      ...TABS.boosts,
      content: <Boosts />,
    },
    {
      ...TABS.resources,
      content: <ResourcesTab />,
    },
  ];

  return (
    <CustomTab
      activeTab={activeTab}
      tabContent={tabContent}
      switchTab={switchTab}
    />
  );
});

// InventoryTabs.propTypes = {
//   selectTab: PropTypes.func,
// };
//
// InventoryTabs.defaultProps = {
//   selectTab: null,
// };

export default InventoryTabs;

import { put, call, fork, take, select, delay } from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import { get } from 'lodash';
import {
  resourceCreateRequest,
  resourceDetailReadRequest,
} from 'store/actions';
import { fromEntities } from 'store/selectors';
import Log from 'services/log';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actions';
import * as resourceActions from '../resource/actions';
import { selectOnboardingWorldId } from '../onboarding/selectors';
import { resetOnboardingState } from '../onboarding/actions';
import {
  trackSignUp,
  trackSignUpSuccess,
  trackUserBalance,
  trackUserState,
} from '../../tracking';
// import { CLEAN_HOUSE } from '../hedgieHouse/actionTypes';
// import { cleanHouse } from '../hedgieHouse/sagas';
// import { AUTH_WS_REQUEST } from './actions';
// import { cleanHouseFailure, cleanHouseSuccess } from '../hedgieHouse/actions';
// import cogoToast from 'cogo-toast';

const getCurrentUserState = (state) => state.session.authenticated;
const getCurrentCuriobalance = (state) => state.session.user.curioBalance;

export function* signIn(firebase, api, payload, meta) {
  try {
    Log.debug(firebase, 'auth saga:handleSignInWithEmailAndPassword firebase');
    Log.debug(payload, 'auth saga:handleSignInWithEmailAndPassword begin');
    const { email, password } = payload;
    const result = yield call(
      firebase.auth.handleSignInWithEmailAndPassword,
      email,
      password,
    );
    Log.debug(result, 'auth saga:handleSignInWithEmailAndPassword response');
    const { currentUser } = firebase.firebase.auth;
    Log.debug(result.user, 'auth saga:handleSignInWithEmailAndPassword user');

    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();
    yield call([api, api.setToken], idToken);

    Log.debug(idToken, 'auth saga:handleSignInWithEmailAndPassword idToken');

    yield call(sessionService.saveSession, {
      idToken,
      refreshToken: currentUser.refreshToken,
    });
    Log.debug(
      idToken,
      'auth saga:handleSignInWithEmailAndPassword savedSession w/ token',
    );

    yield put(resourceDetailReadRequest('users', 'me'));
  } catch (e) {
    Log.error(
      e.code,
      'auth saga:signin handleSignInWithEmailAndPassword error',
    );
    Log.error(
      e.message,
      'auth saga:signin handleSignInWithEmailAndPassword error',
    );
    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);
    yield put(actions.signinFailure(e, meta));
  }
}

export function* fetchUserDetailComplete(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth fetchUserDetailComplete saga begin');
    const currentUser = yield select(fromEntities.getDetail, 'users', payload);
    Log.debug(currentUser, 'auth fetchUserDetailComplete saga currentUser');
    yield call(sessionService.saveUser, currentUser);

    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();
    yield call([api, api.setToken], idToken);

    Log.debug(idToken, 'auth saga:fetchUserDetailComplete idToken');

    yield call(sessionService.saveSession, {
      idToken,
      refreshToken: currentUser.refreshToken,
    });
    yield put(actions.signinSuccess(currentUser));
    yield put(resetOnboardingState());
  } catch (e) {
    Log.error(e, 'auth fetchUserDetailComplete saga error');
    yield put(actions.signUpFailure(e, meta));
  }
}

export function* signOut(firebase, meta) {
  try {
    Log.debug(firebase, 'auth saga:signout firebase');
    const result = yield call(firebase.auth.handleSignOut);
    Log.debug(result, 'auth saga:handleSignOut:result');
    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);
    yield put(actions.signoutSuccess(meta));
  } catch (e) {
    Log.error(e, 'auth saga:signout.error');
    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);
    yield put(actions.signoutFailure(e, meta));
  }
}

export function* signUp(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth saga:signUp begin');
    const {
      email,
      username,
      password,
      caslAccepted,
      tocAccepted,
      referralCode,
      recaptcha,
    } = payload;
    const journeyWorldID = yield select(selectOnboardingWorldId);
    const result = yield call(
      firebase.auth.handleCreateUserWithEmailAndPassword,
      email,
      password,
    );
    Log.debug(result, 'auth saga:signUp response');
    // const { currentUser } = firebase.firebase.auth;
    Log.debug(result.user, 'auth saga:signUpuser');

    const idToken = yield firebase.firebase.auth.currentUser.getIdToken(true);
    yield call([api, api.setToken], idToken);

    Log.debug(idToken, 'auth saga:signUp idToken');

    // README: move this as response to create user success on backend - we have to force a reload of the token after we call the create user on the api
    // because of the 'default' "resource" implementation, we have to listen for the generic message and query the meta
    let params = { email, username, caslAccepted, tocAccepted, recaptcha };
    if (journeyWorldID) {
      params = Object.assign({}, params, { journeyWorldID });
    }
    if (referralCode) {
      params = Object.assign({}, params, { referralCode });
    }
    yield put(resourceCreateRequest('users', params));
    trackSignUp(referralCode);
  } catch (e) {
    Log.error(e, 'auth saga:signUpuser.error');
    yield put(actions.signUpFailure(e, meta));
  }
}

export function* createUserComplete(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth createUserComplete saga begin');
    const currentHedgieUser = yield select(
      fromEntities.getDetail,
      'users',
      payload,
    );
    const { currentUser } = firebase.firebase.auth;
    Log.debug(currentUser, 'auth createUserComplete saga currentUser');
    Log.debug(
      currentHedgieUser,
      'auth createUserComplete saga currentHedgieUser',
    );
    const idToken = yield firebase.firebase.auth.currentUser.getIdToken(true);
    yield call([api, api.setToken], idToken);
    Log.debug(idToken, 'auth createUserComplete saga idToken');
    // move this as response to create user success on backend - we have to force a reload of the token
    // because of the 'default' "resource" implementation, we have to listen for the generic message and query the meta
    yield call(sessionService.saveSession, {
      idToken,
      refreshToken: currentUser.refreshToken,
    });
    yield call(sessionService.saveUser, currentHedgieUser);
    yield put(actions.signUpSuccess(payload));

    const journeyWorldID = yield select(selectOnboardingWorldId);
    if (journeyWorldID) {
      yield put(resetOnboardingState());
    }

    const referralCode = get(meta, 'request.data.referralCode', '');
    trackSignUpSuccess(referralCode);
  } catch (e) {
    Log.error(e, 'auth createUserComplete saga error');
    yield put(actions.signUpFailure(e, meta));
  }
}

export function* updatePassword(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth saga:updatePassword begin');
    const { currentPassword, newPassword } = payload;
    const reauth = yield call(
      firebase.auth.handleReAuthenticate,
      currentPassword,
    );
    Log.debug(reauth, 'auth saga:updatePassword reauth');

    const result = yield call(firebase.auth.handlePasswordUpdate, newPassword);
    Log.debug(result, 'auth saga:updatePassword response');

    const idToken = yield firebase.firebase.auth.currentUser.getIdToken(true);
    yield call([api, api.setToken], idToken);
    Log.debug('auth saga:updatePassword.success');
    yield put(actions.updatePasswordSuccess());
  } catch (e) {
    Log.error(e, 'auth saga:updatePassword.error');
    yield put(actions.updatePasswordFailure(e, meta));
  }
}

export function* forgotPassword(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth saga:forgotPassword begin');
    const { email } = payload;
    Log.debug(email, 'auth saga:forgotPassword send reset');
    yield call(firebase.auth.handlePasswordReset, email);
    Log.debug('auth saga:forgotPassword.success');
    yield put(actions.forgotPasswordSuccess());
  } catch (e) {
    Log.error(e, 'auth saga:forgotPassword.error');
    yield put(actions.forgotPasswordFailure(e, meta));
  }
}

export function* resetPassword(firebase, api, payload, meta) {
  try {
    Log.debug(payload, 'auth saga:resetPassword begin');
    const { actionCode, newPassword } = payload;
    Log.debug(
      { actionCode, newPassword },
      'auth saga:resetPassword send reset',
    );
    yield call(
      firebase.auth.handleCommitPasswordReset,
      actionCode,
      newPassword,
    );
    Log.debug('auth saga:resetPassword.success');
    yield put(actions.resetPasswordSuccess());
  } catch (e) {
    Log.error(e, 'auth saga:resetPassword.error');
    yield put(actions.resetPasswordFailure(e, meta));
  }
}

export function* watchSignInRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(actions.SIGNIN_REQUEST);
    yield call(signIn, firebase, api, payload, meta);
  }
}

export function* watchSignOutRequest(firebase) {
  while (true) {
    const { meta } = yield take(actions.SIGNOUT_REQUEST);
    yield call(signOut, firebase, meta);
  }
}

export function* watchSignUpRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(actions.SIGNUP_REQUEST);
    yield call(signUp, firebase, api, payload, meta);
  }
}

// This should be triggered from signup above ^
export function* watchResourceCreateRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(
      resourceActions.RESOURCE_CREATE_SUCCESS,
    );
    Log.debug(
      {
        payload,
        meta,
      },
      'auth watchResourceCreateRequest',
    );
    if (meta.resource === 'users') {
      yield call(createUserComplete, firebase, api, payload, meta);
    }
  }
}

// This should be triggered from signin above ^
export function* watchResourceDetailReadRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(
      resourceActions.RESOURCE_DETAIL_READ_SUCCESS,
    );
    Log.debug(
      {
        payload,
        meta,
      },
      'auth watchResourceDetailReadRequest',
    );
    if (meta.resource === 'users') {
      yield call(fetchUserDetailComplete, firebase, api, payload, meta);
    }
  }
}

export function* watchUpdatePasswordRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(actions.UPDATE_PASSWORD_REQUEST);
    yield call(updatePassword, firebase, api, payload, meta);
  }
}

export function* watchForgotPasswordRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(actions.FORGOT_PASSWORD_REQUEST);
    yield call(forgotPassword, firebase, api, payload, meta);
  }
}

export function* watchResetPasswordRequest(firebase, api) {
  while (true) {
    const { payload, meta } = yield take(actions.RESET_PASSWORD_REQUEST);
    yield call(resetPassword, firebase, api, payload, meta);
  }
}

export function* watchRouterChange() {
  while (true) {
    yield take(LOCATION_CHANGE);
    yield delay(1000);
    const isAuthenticated = yield select(getCurrentUserState);
    trackUserState(isAuthenticated);
    const curioBalance = yield select(getCurrentCuriobalance);
    trackUserBalance(curioBalance);
  }
}

export default function* ({ firebase, api }) {
  yield fork(watchSignInRequest, firebase, api);
  yield fork(watchSignOutRequest, firebase);
  yield fork(watchSignUpRequest, firebase, api);
  yield fork(watchResourceCreateRequest, firebase, api);
  yield fork(watchUpdatePasswordRequest, firebase, api);
  yield fork(watchResourceDetailReadRequest, firebase, api);
  yield fork(watchForgotPasswordRequest, firebase, api);
  yield fork(watchResetPasswordRequest, firebase, api);
  yield fork(watchRouterChange);
}

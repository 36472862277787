import React from 'react';
import { PresaleHeader } from 'components';
import image from 'images/blue-result.png';

const MobileNotSupported = () => {
  return (
    <div>
      <PresaleHeader />
      <main>
        <div className="container-fluid row-section pos-rel pink-sky overflow-hidden-reg">
          <div className="container">
            <div className="jumbotron ">
              <h1 className="dark-color title mb-3">
                Hedgies are desktop only, for now
              </h1>
              <div className="col-md-6 px-0">
                <p className="lead ff-extra-bold mb-0">
                  We don't support mobile yet. MetaMask, the wallet where you
                  will store your Hedgies, is only on desktop Chrome browser. We
                  are working hard to port it to mobile and apologize for the
                  inconvenience.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-end overflow-hidden bgr-bottom-images pt-5 mt-5">
            <div className="col-md-4 text-right hedige-bubble pt-5 mt-5" />
            <div className="col-md-7 text-right hedige-on-bgr-holder" />
          </div>
          <div className="buble-on-bgr cool-bubble btn-big ff-semi-bold fs-extra-md color-light">
            <span>How exciting!</span>
          </div>
          <img alt="" src={image} className="img-fluid cool-rotate" />
        </div>
      </main>
    </div>
  );
};

export default MobileNotSupported;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 5px;
  transition: all 300ms ease-in-out;
  border: 4px solid transparent;
  &:hover {
    border: ${props => (props.disabled ? null : '4px solid #12dfc0')};
    transition: all 300ms ease-in-out;
  }
`;
const StyledImg = styled.div`
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: auto;
  transition: all ease-in-out 300ms;
  &:after {
    content: '';
    display: block;
    padding-bottom: calc(100%);
  }
`;

const ImageCircle = ({ src, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledImg src={src} />
    </StyledWrapper>
  );
};

ImageCircle.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
};

ImageCircle.defaultProps = {
  size: 161,
};

export default ImageCircle;

import React, { useCallback, useState, useEffect } from 'react';
import { Button, Typography } from 'components';
import { Resources } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCleanedHouse,
  selectCleanHouseError,
  selectHouseHealth,
  selectHouseId,
} from '../../../store/hedgieHouse/selectors';
import FormError from '../../stateless/FormError';
import { cleanHouse } from '../../../store/hedgieHouse/actions';

const ResourcesWrapper = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const health = useSelector(state => selectHouseHealth(state));
  const houseId = useSelector(state => selectHouseId(state));
  const cleaned = useSelector(state => selectCleanedHouse(state));
  const cleanHouseError = useSelector(state => selectCleanHouseError(state));

  const cleanHouseCallback = useCallback(() => {
    if (houseId) {
      dispatch(
        cleanHouse({
          houseId,
        }),
      );
    }
  }, [dispatch, houseId]);

  const handleClick = useCallback(item => {
    setSelected(item);
  }, []);

  useEffect(() => {
    if (cleaned) {
      setSelected(null);
    }
  }, [cleaned]);

  return (
    <div className="pt-2">
      <Resources
        onClick={health < 100 ? handleClick : null}
        selectedName={health < 100 && selected ? selected.type : ''}
      />
      {health < 100 && !cleaned ? (
        <React.Fragment>
          <Typography className="pt-3 pb-3">
            <strong>Hedgie’s house got dirty </strong>
            {'please clean the house'}
          </Typography>
          <div className="align-center">
            <Button
              disabled={cleaned}
              onClick={cleanHouseCallback}
              className="furniture-btn"
            >
              Clean it!
            </Button>
          </div>
          {selected && selected.amount === 0 ? (
            <Typography
              className="pt-2"
              text="Not enough resources!"
              align="center"
              fontSize={14}
            />
          ) : null}
          <div className="align-center">
            {cleanHouseError.error ? (
              <FormError error={cleanHouseError} />
            ) : null}
          </div>
        </React.Fragment>
      ) : null
      }
    </div >
  );
};

ResourcesWrapper.propTypes = {};

export default ResourcesWrapper;

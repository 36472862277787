import {
  CONSUME_ITEM,
  CONSUME_ITEM_SUCCESS,
  CONSUME_ITEM_FAILURE,
  RESET_CONSUMABLE_ITEM,
} from './actionTypes';

export const consumeItem = payload => ({
  type: CONSUME_ITEM,
  payload,
});

export const consumeItemSuccess = payload => ({
  type: CONSUME_ITEM_SUCCESS,
  payload,
});
export const consumeItemFailure = error => ({
  type: CONSUME_ITEM_FAILURE,
  payload: error,
});

export const resetConsumableItem = () => ({
  type: RESET_CONSUMABLE_ITEM,
});

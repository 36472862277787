import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { domain } from 'config/env';
import * as types from 'types';
import Log from 'services/log';
import * as paths from 'config/paths';
import {
  ChangePasswordForm,
  PageTitle,
  SideHedgie,
  NotificationSubscription,
  Username,
} from 'components';
import HeadLine from '../../stateless/HeadLine';

const Title = ({ title }) => (
  <HeadLine title={title} color="#000" fontSize={18} align="left" />
);

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    if (this.props.user === null) {
      this.props.history.push(paths.HOME);
    }
  }

  componentDidUpdate() {
    if (this.props.changeSettingsSuccess) {
      this.props.history.push(paths.HOME);
    }
  }

  handleUpdatePassword = ({ currentPassword, newPassword }) => {
    Log.debug({ currentPassword, newPassword }, 'handleUpdatePassword');
    this.props.updatePassword({ currentPassword, newPassword });
  };

  render() {
    const { error, working } = this.props;
    Log.debug(this.props, 'SettingsPage:render');
    return (
      <React.Fragment>
        <main>
          <Container fluid className="pos-rel sign-up-wrap">
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Settings" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="pb-4">
                    <Title title="Notification" />
                    <NotificationSubscription />
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Title title="Your Referral Code" />
                  <div>{`${domain}/signup?referral_code=${
                    this.props.user.referralCode
                  }`}</div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={4}>
                  <Title title="Update Username" />
                  <Username />
                </Col>
              </Row>
              <Row style={{ marginBottom: 100 }}>
                <Col>
                  <Title title="Change Password" />
                  <ChangePasswordForm
                    onSubmit={this.handleUpdatePassword}
                    error={error}
                    working={working}
                  />
                </Col>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-5" />
                    <div className="col-md-7" />
                  </div>
                </div>
              </Row>
            </Container>
            <SideHedgie
              className="side-hedgie-secret"
              color="blue"
              title="It’s a secret!"
            />
          </Container>
        </main>
      </React.Fragment>
    );
  }
}

SettingsPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  working: PropTypes.bool,
  updatePassword: PropTypes.func.isRequired,
  error: types.error,
  user: types.user.isRequired,
  changeSettingsSuccess: PropTypes.bool,
};

SettingsPage.defaultProps = {
  signUpFailed: false,
  signUpSuccess: false,
  error: null,
  working: false,
  changeSettingsSuccess: false,
};

export default SettingsPage;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { SubHeading, ImgLoader, JourneyWorldsGrid } from 'components';
import withResponsive from '../../../helper/withResponsive';

export const BIG_IMAGE_RATIO = 1440 / 968;

class JourneyWorlds extends React.PureComponent {
  componentDidMount() {
    // reset worlds selection to first one
    const { worlds, selectWorld } = this.props;
    if (!isEmpty(worlds)) {
      selectWorld(worlds[0]);
    }
  }

  selectWorld = world => () => {
    this.props.selectWorld(world);
  };

  render() {
    const { worlds, selectedWorld, isMobile } = this.props;
    if (isEmpty(worlds)) {
      return <span>No worlds</span>;
    }
    return (
      <div>
        <JourneyWorldsGrid
          isMobile={isMobile}
          worlds={worlds}
          selectedWorld={selectedWorld}
          selectWorld={this.selectWorld}
        />
        {selectedWorld ? (
          <SubHeading
            className="pt-4 pb-4 sub-heading-center ff-bold text-center"
            heading={selectedWorld.name}
          />
        ) : null}
        {selectedWorld ? (
          <ImgLoader
            alt="full-world-image"
            ratio={BIG_IMAGE_RATIO}
            url={selectedWorld.imageURL}
            preview={selectedWorld.previewImageURL}
          />
        ) : null}
      </div>
    );
  }
}

JourneyWorlds.propTypes = {
  worlds: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectWorld: PropTypes.func.isRequired,
  selectedWorld: PropTypes.object,
};

JourneyWorlds.defaultProps = {
  selectedWorld: null,
};

export default withResponsive(JourneyWorlds);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, StyledOpenNewTab } from 'styles/main';

const TierBlockContainer = styled.img`
  color: #ffffff;
  border-radius: 30%;
  padding: 10px 10px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledHeading = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  text-align: center;
  ${media.tablet`
    font-size: 11px;
  `}
`;

const BusinessBlock = ({
  business,
  width,
  height,
  noDescription,
  hideName,
}) => {
  return (
    <StyledContainer width={width}>
      <StyledOpenNewTab href={business.website} target="_blank">
        <TierBlockContainer
          width={width}
          height={height}
          src={business.imageURL}
          className="tier-block d-flex text-center justify-content-center flex-column m-auto"
        />
      </StyledOpenNewTab>
      {hideName ? null : <StyledHeading>{business.name}</StyledHeading>}
      {noDescription ? null : <p className="pt-2">{business.description}</p>}
    </StyledContainer>
  );
};

BusinessBlock.propTypes = {
  business: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  noDescription: PropTypes.bool,
  hideName: PropTypes.bool,
};

BusinessBlock.defaultProps = {
  width: 180,
  height: 180,
  noDescription: false,
  hideName: false,
};

export default BusinessBlock;

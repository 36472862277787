import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const Menu = ({ path, name, href, onClick }) => {
  if (href) {
    return (
      <NavLink href={href} target="blank">
        {name}
      </NavLink>
    );
  }
  return (
    <NavItem onClick={onClick}>
      <NavLink tag={Link} to={path}>
        {name}
      </NavLink>
    </NavItem>
  );
};

Menu.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

Menu.defaultProps = {
  name: '',
  path: '',
  onClick: null,
  href: null,
};

export default React.memo(Menu);

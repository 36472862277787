import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div``;

const Location = ({ width, ...props }) => {
  return (
    <StyledContainer {...props}>
      <svg
        width="23px"
        height="30px"
        viewBox="0 0 23 30"
        style={{ width, height: 'auto' }}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="company/description_learn-more-copy"
            transform="translate(-220.000000, -185.000000)"
            fill="#62295E"
          >
            <g id="icon/Location" transform="translate(220.000000, 185.000000)">
              <path
                d="M2.28932602,20.0183868 C2.15096928,19.7966716 1.93223197,19.4423471 1.63311409,18.955413 C0.596203266,17.0748813 0,14.8734498 0,12.5204116 C0,5.60557922 5.14872538,0 11.5,0 C17.8512746,0 23,5.60557922 23,12.5204116 C23,14.9025239 22.3889723,17.1292599 21.3282481,19.0249733 L21.3211073,19.0182153 C21.1751729,19.309299 21.0318529,19.5617482 20.8911472,19.7755627 L20.8290178,19.8432049 C20.7679228,19.9353021 20.7057055,20.0264585 20.6423878,20.1166504 C20.6045086,20.1629793 20.5832573,20.1929684 20.578634,20.2066179 C19.9064176,21.1464188 19.1137914,21.9791051 18.2259868,22.6772067 L11.5,30 L4.77401319,22.6772067 C3.82737807,21.9328453 2.98895338,21.0354717 2.28932602,20.0183868 Z M11.5,18 C15.0898509,18 18,15.0898509 18,11.5 C18,7.91014913 15.0898509,5 11.5,5 C7.91014913,5 5,7.91014913 5,11.5 C5,15.0898509 7.91014913,18 11.5,18 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </StyledContainer>
  );
};

Location.propTypes = {
  width: PropTypes.number,
};

Location.defaultProps = {
  width: 24,
};

export default Location;

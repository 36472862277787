import firebase from 'firebase/app';

import { auth } from './firebase';
import { apiInstance } from '../../index';
import tokenConfig from '../../config/tokenConfig';

// Sign Up
export const handleCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const handleSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const handleSignOut = () => auth.signOut();

// Password Reset
export const handlePasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const handlePasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

// Reset Password VerifyRequest
export const handleConfirmPasswordResetCode = (actionCode) =>
  auth.verifyPasswordResetCode(actionCode);

// Reset Password Commit
export const handleCommitPasswordReset = (actionCode, newPassword) =>
  auth.confirmPasswordReset(actionCode, newPassword);

// Re-Authenticate
export const handleReAuthenticate = (password) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    auth.currentUser.email,
    password,
  );
  return auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
    credential,
  );
};

export function forceRefreshToken() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      apiInstance.setToken(token);
      tokenConfig.token = token;
      resolve(token);
    } catch (e) {
      reject(e);
    }
  });
}

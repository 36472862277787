import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledMenuBar = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${media.desktop`
    padding-left: 8px;
  `}
  ${media.phone`
    padding-left: 5px;
  `}
`;

const styles = {
  color: '#fff',
  height: 24,
  width: 24,
};

const MenuBarsIcon = ({ onClick }) => {
  return (
    <StyledMenuBar onClick={onClick}>
      <FontAwesomeIcon icon={faBars} style={styles} />
    </StyledMenuBar>
  );
};

MenuBarsIcon.propTypes = {
  onClick: PropTypes.func,
};
MenuBarsIcon.defaultProps = {
  onClick: null,
};

export default MenuBarsIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, size } from 'lodash';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { Spinner, ContentContainer } from 'components';
import BusinessBlock from '../BusinessBlock';

const PlaceholderBusinessImg = styled.div`
  background-color: #EFF3F4;
  border-radius: 30%;
  padding: 10px 10px;
  width: 180px;
  height: 180px;
`;

const ListCell = ({ business, size }) => {
  if (!business) {
    return null;
  }
  return (
    <Col md={12 / size}>
      <BusinessBlock business={business} />
    </Col>
  );
};

ListCell.propTypes = {
  business: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
};

const ListRow = ({ businesses }) => {
  if (isEmpty(businesses)) {
    return null;
  }
  return (
    <Row
      className="d-flex text-center justify-content-center align-items-start tier-row"
      style={{ marginBottom: '40px' }}
    >
      {businesses.map(business => {
        if (business) {
          return (
            <LazyLoad
              height={180}
              once
              key={business.id}
              offset={[-180, 0]}
              placeholder={
                <Col md={12 / size(businesses)}>
                  <PlaceholderBusinessImg />
                </Col>
              }
            >
              <ListCell business={business} size={businesses.length} />
            </LazyLoad>
          );
        }
        return null;
      })}
    </Row>
  );
};

ListRow.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const renderBusinesses = businesses => {
  const colCount = 4;
  const rowCount = Math.ceil(businesses.length / colCount);
  const rows = Array.from(Array(rowCount).keys());
  const cols = Array.from(Array(colCount).keys());
  return rows.map(val => {
    const index = val * colCount;
    const rowBusinesses = cols.map(val => businesses[index + val]);
    return <ListRow key={val} businesses={rowBusinesses} />;
  });
};

const RenderList = ({ businesses, loading }) => {
  let BusinessList;
  if (loading) {
    BusinessList = (
      <div className="pos-rel m-5 p-5">
        <Spinner />
      </div>
    );
  } else {
    BusinessList = renderBusinesses(businesses);
  }
  return BusinessList;
};

const BusinessList = props => {
  const { businesses, loading } = props;
  const className = `businessList ${props.className}`;
  return (
    <div className={className}>
      <ContentContainer>
        <RenderList businesses={businesses} loading={loading} />
      </ContentContainer>
    </div>
  );
};

BusinessList.propTypes = {
  className: PropTypes.string,
  businesses: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

BusinessList.defaultProps = {
  className: '',
  error: null,
  businesses: [],
  loading: false,
};

export default BusinessList;

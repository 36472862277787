import { GET_BATTLE_DETAIL, GET_BATTLE_DETAIL_SUCCESS, GET_BATTLE_DETAIL_FAILURE } from './actionTypes'
import createReducer from '../createReducer';

const initialState = {
    detail: {
        data: {},
        loading: false,
        error: {
            error: false,
            message: '',
        },
    },
}

export default createReducer(initialState, {
    [GET_BATTLE_DETAIL]: state => {
        state.loading = true;
    },
    [GET_BATTLE_DETAIL_SUCCESS]: (state, action) => {
        state.detail.loading = false;
        state.detail.data = action.payload.data;
    },
    [GET_BATTLE_DETAIL_FAILURE]: (state, action) => {
        state.detail.loading = false;
        state.detail.error = {
            error: true,
            message: action.payload.message,
        };
    },
})
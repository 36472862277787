import React from 'react';

const MAIN_IMAGE_LOADED = 'MAIN_IMAGE_LOADED';
const FALLBACK_IMAGE_LOADED = 'FALLBACK_IMAGE_LOADED';

function reducer(currentSrc, action) {
  if (action.type === MAIN_IMAGE_LOADED) {
    return action.src;
  }
  if (!currentSrc) {
    return action.src;
  }
  return currentSrc;
}

export default function(src, fallbackSrc) {
  const [currentSrc, dispatch] = React.useReducer(reducer, null);

  React.useEffect(
    () => {
      let mainImage = new Image();
      let fallbackImage = new Image();

      mainImage.onload = () => {
        dispatch({ type: MAIN_IMAGE_LOADED, src });
      };
      fallbackImage.onload = () => {
        dispatch({ type: FALLBACK_IMAGE_LOADED, src: fallbackSrc });
      };

      mainImage.src = src;
      fallbackImage.src = fallbackSrc;
      return () => {
        mainImage = null;
        fallbackImage = null;
      };
    },
    [fallbackSrc, src],
  );

  return currentSrc;
}

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Button, Typography, ExpPointInput } from 'components';
import water from '../../../images/powers/powerWater.svg';
import fire from '../../../images/powers/powerFire.svg';
import earth from '../../../images/powers/powerEarth.svg';
import air from '../../../images/powers/powerAir.svg';
import luck from '../../../images/powers/skillLuck.svg';
import prudence from '../../../images/powers/skillPrudence.svg';
import intelligence from '../../../images/powers/skillIntelligence.svg';
import charm from '../../../images/powers/skillCharm.svg';
import energySvgUrl from 'images/energy_icon.svg';

const DATA = [
  {
    name: 'water',
    url: water,
  },
  {
    name: 'fire',
    url: fire,
  },
  {
    name: 'earth',
    url: earth,
  },
  {
    name: 'air',
    url: air,
  },
  {
    name: 'luck',
    url: luck,
  },
  {
    name: 'prudence',
    url: prudence,
  },
  {
    name: 'intelligence',
    url: intelligence,
  },
  {
    name: 'charm',
    url: charm,
  },
  {
    name: 'energy',
    url: energySvgUrl,
  },
];

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  justify-items: center;
`;

const PowerChangeGrid = ({ points, hedgieId, upgradeHedgie, dispatch }) => {
  const [power, setPower] = useState({});
  const [limitedNo, setLimitedNo] = useState(points);

  const changeCallback = useCallback(
    (name, point, type) => {
      setPower({ ...power, [name]: point });
      if (type === 'minus') {
        setLimitedNo(limitedNo + 1);
      } else {
        setLimitedNo(limitedNo - 1);
      }
    },
    [limitedNo, power],
  );

  const submitCallback = useCallback(() => {
    dispatch(
      upgradeHedgie({
        hedgieId,
        upgrades: power,
      }),
    );
  }, [dispatch, hedgieId, power, upgradeHedgie]);

  return (
    <div>
      <Typography
        text={`Points remaining: ${limitedNo}`}
        color="#62295E"
        fontSize={20}
        className="pb-2"
      />
      <StyledGrid>
        {DATA.map(power => (
          <ExpPointInput
            {...power}
            key={power.name}
            limitedPoints={limitedNo}
            onChange={changeCallback}
          />
        ))}
      </StyledGrid>
      <div className="align-center pt-3 pb-1">
        <Button onClick={submitCallback} disabled={isEmpty(power)}>
          Submit
        </Button>
      </div>
    </div>
  );
};

PowerChangeGrid.propTypes = {
  points: PropTypes.number.isRequired,
  hedgieId: PropTypes.number,
  upgradeHedgie: PropTypes.func,
  dispatch: PropTypes.func,
};

PowerChangeGrid.defaultProps = {
  hedgieId: 0,
  upgradeHedgie: null,
  dispatch: null,
};

export default React.memo(PowerChangeGrid);

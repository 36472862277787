import React from 'react';
import PropTypes from 'prop-types';

const ROOT_URL = 'https://hedgie.idevaffiliate.com/sale.php?profile=72198';
const AffiliateTracker = ({ orderNum, salesAmount }) => {
  const fullURL = `${ROOT_URL}&idev_saleamt=${salesAmount}&idev_ordernum=${orderNum}`;
  return (
    <img
      alt=""
      src={fullURL}
      style={{ height: '0px', width: '0px', border: '0px' }}
    />
  );
};

AffiliateTracker.propTypes = {
  orderNum: PropTypes.string,
  salesAmount: PropTypes.number,
};

AffiliateTracker.defaultProps = {
  orderNum: '',
  salesAmount: 0,
};

export default AffiliateTracker;

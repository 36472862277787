import React from 'react';
import PropTypes from 'prop-types';
import { HeadLine, Typography, Button } from 'components';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: ${props => (props.isMobile ? '290px' : '500px')};
  margin: 0 auto;
`;

const StyledButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${props => (props.isMobile ? 0 : '30px')};
`;

const WelcomeInfo = ({ handleNext, isMobile }) => {
  return (
    <StyledContainer isMobile={isMobile}>
      <HeadLine
        title="Hello, I’m Hedgie!"
        color="#fff"
        fontSize={isMobile ? 27 : 40}
      />
      <Typography
        color="#fff"
        fontSize={isMobile ? 16 : 18}
        text="Play the game for free. Earn cryptocoin. Spend in real life."
        key="text-1"
        className="pt-2"
      />
      <Typography
        className="pt-3"
        fontSize={isMobile ? 16 : 18}
        color="#fff"
        text="Try your luck now, select a kingdom, and click Next!"
        key="text-2"
      />
      <StyledButtonWrapper isMobile={isMobile}>
        <Button size="large" onClick={handleNext}>
          Next
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

WelcomeInfo.propTypes = {
  handleNext: PropTypes.func,
  isMobile: PropTypes.bool,
};

WelcomeInfo.defaultProps = {
  handleNext: null,
  isMobile: false,
};

export default WelcomeInfo;

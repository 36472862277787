import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Row, Col } from 'reactstrap';

import Log from 'services/log';
import { Button, FormError } from 'components';

import * as types from 'types';

const INITIAL_STATE = {
  email: '',
  validation: {},
};

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      validation: {},
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      email,
    } = this.state;
    Log.debug(this.state, 'ChangePasswordForm:onSubmit');

    if (!email) {
      this.setState({
        validation: { message: 'Please enter your email address' },
      });
    } else {
      this.props.onSubmit({ email });
    }
  }

  render() {
    Log.debug('render', 'ChangePasswordForm');

    const {
      email,
      validation,
    } = this.state;

    const {
      error,
      working,
    } = this.props;

    const mdSize = 8;
    return (
      <div>
        <h4>Forgot Password</h4>
        <Form m3={3} onSubmit={this.onSubmit} >
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                onChange={event => this.onChange(event)}
                value={email}
              />
            </Col>
          </FormGroup>
          <FormError error={error || validation} />
          <Row>
            <FormGroup className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start" >
              <Button size="large" disabled={validation.message !== undefined || working} type="submit">Submit</Button>
            </FormGroup>
          </Row>
        </Form>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  error: null,
  working: false,
};

export default ChangePasswordForm;

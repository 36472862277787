import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowCSS } from 'components';

const StyledContainer = styled.div`
  border: 1px solid #12dfc0;
  width: ${props => props.width}px;
  height: 45px;
  border-radius: 8px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  justify-items: center;
  align-items: center;
`;

const StyledText = styled.span`
  display: inline-block;
  font-size: 18px;
  color: #000000;
  text-align: center;
  line-height: 22px;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .power-img {
    margin-right: 8px;
  }
`;

const ExpPointInput = ({ name, url, limitedPoints, onChange, width }) => {
  const [num, setNum] = useState(0);
  const handleAddCallback = useCallback(
    () => {
      if (limitedPoints > 0) {
        setNum(num => num + 1);
        onChange(name, num + 1, 'plus');
      }
    },
    [limitedPoints, name, num, onChange],
  );

  const handleMinusCallback = useCallback(
    () => {
      if (num > 0) {
        setNum(num => num - 1);
        onChange(name, num - 1, 'minus');
      }
    },
    [name, num, onChange],
  );

  return (
    <StyledWrapper>
      {url ? (
        <img width={35} src={url} alt={name} className="power-img" />
      ) : null}
      <StyledContainer width={width}>
        <ArrowCSS direction="left" size={5} onClick={handleMinusCallback} />
        <StyledText>{num}</StyledText>
        <ArrowCSS direction="right" size={5} onClick={handleAddCallback} />
      </StyledContainer>
    </StyledWrapper>
  );
};

ExpPointInput.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.number,
  limitedPoints: PropTypes.number,
  onChange: PropTypes.func,
};

ExpPointInput.defaultProps = {
  limitedPoints: 2,
  onChange: null,
  width: 90,
  name: '',
  url: '',
};

export default ExpPointInput;

import React from 'react';
// import PropTypes from 'prop-types';

const PriceDisplayNew = ({ hedgie }) => {
  var eth=0;
  var priceD=0;
  if(hedgie){
  eth = hedgie.etherPrice/1e18;
  priceD = (hedgie.dollarPrice+hedgie.gasPrice)/100;
  }
 

  return(
    <span>{eth}ETH+gas (${priceD})</span>
  )
};



export default PriceDisplayNew;

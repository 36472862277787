export const POWER_GRADIENT_COLORS = {
  powerWater: {
    gradientColorStart: '#004DCD',
    gradientColorEnd: '#00FFFF',
  },
  powerFire: {
    gradientColorStart: '#B50041',
    gradientColorEnd: '#FBAF3B',
  },
  powerEarth: {
    gradientColorStart: '#8C8C8C',
    gradientColorEnd: '#000000',
  },
  powerAir: {
    gradientColorStart: '#829EAA',
    gradientColorEnd: '#FFFFFF',
  },
  skillLuck: {
    gradientColorStart: '#FFEC3E',
    gradientColorEnd: '#F9CD5F',
  },
  skillPrudence: {
    gradientColorStart: '#1AA338',
    gradientColorEnd: '#08DF12',
  },
  skillIntelligence: {
    gradientColorStart: '#1460FF',
    gradientColorEnd: '#1741F9',
  },
  skillCharm: {
    gradientColorStart: '#FA89FF',
    gradientColorEnd: '#C741C5',
  },
  default: {
    gradientColorStart: '#CAD6D9',
    gradientColorEnd: '#CAD6D9',
  },
};

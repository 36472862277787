import React, { useEffect } from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { messaging } from 'services/firebase/firebase';
import {
  markANotificationAsRead,
  updateNewNotification,
} from 'store/notification/actions';
import NotificationToast from './NotificationToast';

export default (isLoggedIn, dispatch) => {
  useEffect(() => {
    if (isLoggedIn && messaging) {
      messaging.onMessage(payload => {
        const href = get(payload, 'data.href', '');
        const notificationId = get(payload, 'data.id', '');
        const notification = get(payload, 'notification', null);
        if (notification) {
          dispatch(updateNewNotification());
          toast(
            <NotificationToast
              message={notification}
              href={href}
              dispatch={dispatch}
              notificationId={notificationId}
              markANotificationAsRead={markANotificationAsRead}
            />,
          );
        }
      });
    }
  }, [dispatch, isLoggedIn]);
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import {
  PresaleTopSlide,
  PresalePremiumSlide,
  PresalePickerSlide,
  PageTitle,
  SubHeading,
  Mobile,
  Divider,
} from 'components';
import * as types from 'types';
import * as paths from 'config/paths';
import ReserveYourHedgieImage from 'images/reserve-your-hedgie.png';
import BuyYourHedgieImage from 'images/buy-your-hedgie.png';
import PlayTheGameImage from 'images/play-the-game.png';

const DEFAULT_COLOR = '#229bd4';

const ActionImage = ({ className, src, alt }) => (
  <img src={src} className={`${className} rounded-circle`} alt={alt} />
);

const StyledActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`;

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: DEFAULT_COLOR,
      modal: false,
    };
    this.handlePurchase = this.handlePurchase.bind(this);
  }

  componentDidMount() {
    // Puting this work around here to account
    // for that case where the user clicks 'Get Hedgie' on the home page
    // We need to detect route change and scroll manually. Unless other ideas?
    this.unlisten = this.props.history.listen((location, action) => {
      // Decode entities in the URL
      // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
      window.location.hash = window.decodeURIComponent(window.location.hash);
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 1) {
        const hash = hashParts.slice(-1)[0];
        const element = document.querySelector(`#${hash}`);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleChangeComplete = color => {
    this.setState({ selectedColor: color.hex }, () => {
      this.props.getHedgie(color.hex.substr(1));
    });
  };

  handlePurchase = hedgie => {
    this.props.history.push(`${paths.BOOKING_HEDGIE}/${hedgie.color}`);
  };

  handleModal = e => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    const {
      hedgie,
      tier,
      errorGettingHedgies,
      errorChoosingHedgie,
      loading,
      hedgies,
      loadingPremium,
      user,
    } = this.props;
    const { selectedColor, modal } = this.state;
    const rowClass = 'py-5';
    return (
      <div className="home-page">
        <main>
          <PresaleTopSlide />
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="How it Works" />
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col md="12">
                  <Row>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage
                          src={ReserveYourHedgieImage}
                          alt=""
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="1. Buy your Hedgie" />
                        </div>
                        <p className="text-left text-md-center">
                          Unlike other blockchain games that require
                          cryptocurrency to get started, all you need is a
                          credit card. Essential Hedgies are $10 USD, and
                          Exclusive Hedgies start at $100 USD.
                        </p>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage src={PlayTheGameImage} alt="" />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="2. Play the game - Early 2019" />
                        </div>
                        <p className="text-left text-md-center">
                          Explore four unique kingdoms with your Hedgie and dig
                          to earn crypto coin! Find loot along the way that your
                          Hedgie can consume, collect, or trade. There’s lots to
                          be found, and there’s no cost to play! Beta game
                          launching early 2019.
                        </p>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0 px-5">
                      <div className="text-center m-auto">
                        <StyledActionImage src={BuyYourHedgieImage} alt="" />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="3. Spend coin - Summer 2019" />
                        </div>
                        <p className="text-left text-md-center">
                          Crypto coin you earn in the game can be spent in the
                          REAL world! Shop at local and online businesses and
                          services that are Hedgie-Approved. Coming Summer 2019!
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <PresalePickerSlide
            user={user}
            hedgie={hedgie}
            tier={tier}
            error={errorChoosingHedgie}
            selectedColor={selectedColor}
            onChange={this.handleChangeComplete}
            onClick={this.handleModal}
            modal={modal}
            loading={loading}
            onPurchaseHedgie={this.handlePurchase}
          />
          <Mobile>
            <Divider />
          </Mobile>
          <PresalePremiumSlide
            premiumHedgies={hedgies}
            loading={loadingPremium}
            error={errorGettingHedgies}
          />
        </main>
      </div>
    );
  }
}

LandingPage.propTypes = {
  hedgie: types.hedgie,
  tier: types.tier,
  hedgies: types.hedgies,

  errorChoosingHedgie: types.error,
  errorGettingHedgies: types.error,

  loading: PropTypes.bool.isRequired,
  loadingPremium: PropTypes.bool.isRequired,
  user: types.user,
  authenticated: PropTypes.bool.isRequired,
  // actions
  getHedgie: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

LandingPage.defaultProps = {
  hedgie: null,
  tier: null,
  hedgies: [],
  errorGettingHedgies: {},
  errorChoosingHedgie: {},
  user: null,
};

export default LandingPage;

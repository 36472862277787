import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { SubHeading, Divider } from 'components';
import ExclusiveItems from '../../../images/exclusive_items.png';
import NewWorldPlaceholder from '../../../images/new_world_placeholder.png';

const EXCLUSIVE_CONTENT = [
  {
    id: '1',
    title: 'Crystal Mushroom',
    description:
      'Known for its psychic powers, the Mushroom Crystal lets you choose which item to find on your Exclusive Hedgie’s journey into Forest Kingdom.',
  },
  {
    id: '2',
    title: 'Desert Crown',
    description:
      'The Desert Crown lets you choose which item your Exclusive Hedgie will find on its journey into the Desert Kingdom.',
  },
  {
    id: '3',
    title: 'Liquid Light',
    description:
      'Exclusive Hedgies equipped with Liquid Light will find more loot when journeying through the Wetlands Kingdom.',
  },
  {
    id: '4',
    title: 'Cloud Ring',
    description:
      'It’s a bird... It’s a plane... No, it’s the Cloud Ring! With this tool you can choose which item your Exclusive Hedgie will find while journeying through the Mountain Kingdom.',
  },
  {
    id: '5',
    title: 'Ether Baton',
    description:
      'Conjure up sunny skies in any kingdom so your Hedgie can find special loot that cannot be found without it!. You also get Mushroom Crystal, Desert Crown, Liquid Light and Cloud Ring. That’s mighty powerful!',
  },
  {
    id: '6',
    title: 'Sky Key',
    description:
      'Don’t lose this one! The Sky Key shortens your Exclusive Hedgie’s travel time in any Kingdom by 50%, making for super-efficient journeys.',
  },
];

const ExcludeContentRowItem = props => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <SubHeading className="text-center" heading={props.title} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-left">{props.description}</p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ExcludeContentRowItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const ExclusiveContentRow = props => {
  const rowClass = '';
  return (
    <Row className={`${rowClass} justify-content-center`}>
      <Col lg="4" md="12">
        <ExcludeContentRowItem
          title={props.items[0].title}
          description={props.items[0].description}
        />
      </Col>
      <Col lg="2" md="12" />
      <Col lg="4" md="12">
        <ExcludeContentRowItem
          title={props.items[1].title}
          description={props.items[1].description}
        />
      </Col>
    </Row>
  );
};

ExclusiveContentRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const ExclusiveContent = props => {
  const className = `exclusiveContent ${props.className}`;
  const cols = 2;
  const rowCount = Math.ceil(EXCLUSIVE_CONTENT.length / cols);
  const rows = Array.from(Array(rowCount).keys());
  return (
    <div className={className}>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SubHeading
            className="text-center"
            heading="Each exclusive tier comes with different special item that boosts your Hedgie’s performance in the game. The highest tier will re- ceive all six items, the next highest will receive 5, then 4...you get it!"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center py-5">
          <img
            src={ExclusiveItems}
            alt="exclusive items"
            style={{ width: '100%', maxWidth: '525px' }}
          />
        </Col>
      </Row>
      {rows.map(val => {
        const index = val * cols;
        return (
          <ExclusiveContentRow
            key={val}
            items={[EXCLUSIVE_CONTENT[index], EXCLUSIVE_CONTENT[index + 1]]}
          />
        );
      })}
      <Row className="pt-5">
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SubHeading className="text-center" heading="New Worlds" />
        </Col>
      </Row>
      <Divider />
      <Row className="pt-3">
        <Col sm="12">
          <p className="text-center">
            When we introduce new kingdoms in the Hedgie game, we may just give
            exclusive Hedgie owners first dibs...
          </p>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col sm="12" className="text-center py-5">
          <img
            src={NewWorldPlaceholder}
            alt="new worlds"
            className="img-fluid"
            style={{ maxWidth: '950px', width: '100%' }}
          />
        </Col>
      </Row>
    </div>
  );
};

ExclusiveContent.propTypes = {
  className: PropTypes.string,
};

ExclusiveContent.defaultProps = {
  className: '',
};

export default ExclusiveContent;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, PureSpinner } from 'components';
import styled from 'styled-components';

const StyledButtonChildren = styled.div`
  display: flex;
  align-items: center;
`;

const LoadableButton = ({ onClick, loading, label, ...props }) => {
  return (
    <Button onClick={onClick} {...props}>
      <StyledButtonChildren>
        <span className="pr-2">{label}</span>
        {loading ? <PureSpinner /> : null}
      </StyledButtonChildren>
    </Button>
  );
};

LoadableButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default LoadableButton;

import React from 'react';
import PropTypes from 'prop-types';

const HeartIconSvg = ({ size }) => {
  return (
    <div>
      <svg
        width="26px"
        height="24px"
        viewBox="0 0 26 24"
        style={{ width: size }}
      >
        <g
          id="pages"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="25/WEB-my-orders"
            transform="translate(-826.000000, -409.000000)"
            fill="#12DFC0"
          >
            <path
              d="M839.571749,432.601811 L839.5,433 L839.389405,432.729794 C839.260587,432.819876 839.130785,432.909945 839,433 L839.03664,431.867915 L838.957067,431.673502 L839,433 C832.037245,428.205611 827.861138,423.373726 826.471677,418.504346 C826.167174,417.717584 826,416.861104 826,415.965109 C826,412.118385 829.081334,409 832.882353,409 C835.545771,409 837.855826,410.531117 839,412.771063 C840.144174,410.531117 842.454229,409 845.117647,409 C848.918666,409 852,412.118385 852,415.965109 C852,416.861104 851.832826,417.717584 851.528323,418.504346 C850.17732,423.23895 846.191796,427.938105 839.571749,432.601811 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

HeartIconSvg.propTypes = {
  size: PropTypes.any,
};

HeartIconSvg.defaultProps = {
  size: 26,
};

export default HeartIconSvg;

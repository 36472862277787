import { createSelector } from 'reselect';

const userItemsSelector = state => state.item.list;
const soldItemSelector = state => state.item.soldItem;

export const selectUserItems = createSelector(
  userItemsSelector,
  userItems => userItems.data,
);

export const selectUserItemsLoading = createSelector(
  userItemsSelector,
  userItems => userItems.loading,
);

export const selectUserItemsError = createSelector(
  userItemsSelector,
  userItems => userItems.error,
);

export const selectSoldItemState = createSelector(
  soldItemSelector,
  soldItem => soldItem,
);

export const selectItemsPagination = createSelector(
  userItemsSelector,
  userItems => userItems.pagination,
);

import { concat } from 'lodash';
import createReducer from '../createReducer';
import {
  ARCHIVE_ORDER,
  ARCHIVE_ORDER_FAILURE,
  ARCHIVE_ORDER_SUCCESS,
  CLEAR_ORDER_STATE,
  CREATE_AN_ORDER,
  CREATE_AN_ORDER_FAILURE,
  CREATE_AN_ORDER_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_BUSINESS_DETAIL,
  GET_BUSINESS_DETAIL_FAILURE,
  GET_BUSINESS_DETAIL_SUCCESS,
  GET_BUSINESS_PRODUCTS,
  GET_BUSINESS_PRODUCTS_FAILURE,
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_MY_BUSINESSES,
  GET_MY_BUSINESSES_FAILURE,
  GET_MY_BUSINESSES_SUCCESS,
  GET_MY_ORDERS,
  GET_MY_ORDERS_FAILURE,
  GET_MY_ORDERS_SUCCESS,
  GET_ORDERS_OF_BUSINESS,
  GET_ORDERS_OF_BUSINESS_FAILURE,
  GET_ORDERS_OF_BUSINESS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
} from './actionTypes';

const initialState = {
  myBusinesses: {
    data: [],
    loading: false,
    pagination: {
      limit: 12,
      offset: 0,
      total: 0,
    },
    error: {
      error: false,
      message: '',
    },
  },
  products: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
    pagination: {
      limit: 20,
      offset: 0,
    },
  },
  productDetail: {
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  detail: {
    data: {},
    permissions: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  orderedProduct: {
    hasPurchased: false,
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  ordersOfBusiness: {
    data: [],
    permissions: [],
    sizePerResponse: 0,
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  myOrders: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  archivedOrder: {
    error: {
      error: false,
      message: '',
    },
  },
  allProducts: [],
  allProductsPagination: {
    limit: 6,
    offset: 0,
    total: 0,
  },
  enjoyMoreDiscounts: [],
  allProductsLoading: false,
  allProductsError: {
    error: false,
    message: '',
  },
};

export default createReducer(initialState, {
  [GET_MY_BUSINESSES]: state => {
    state.myBusinesses.loading = true;
    state.myBusinesses.error.error = false;
  },
  [GET_MY_BUSINESSES_SUCCESS]: (state, action) => {
    state.myBusinesses.loading = false;
    state.myBusinesses.pagination = action.payload.meta.pagination;
    state.myBusinesses.sizePerResponse = action.payload.data
      ? action.payload.data.length
      : 0;
    state.myBusinesses.data = action.payload.isLoadMore
      ? concat(state.myBusinesses.data, action.payload.data)
      : action.payload.data;
  },
  [GET_MY_BUSINESSES_FAILURE]: (state, action) => {
    state.myBusinesses.loading = false;
    state.myBusinesses.error.error = true;
    state.myBusinesses.error.message = action.payload.message;
  },
  [GET_BUSINESS_PRODUCTS]: state => {
    state.products.loading = true;
    state.products.error.error = false;
  },
  [GET_BUSINESS_PRODUCTS_SUCCESS]: (state, action) => {
    state.products.loading = false;
    state.products.data = action.payload.data;
  },
  [GET_BUSINESS_PRODUCTS_FAILURE]: (state, action) => {
    state.products.loading = false;
    state.products.error.error = true;
    state.products.error.message = action.payload.message;
  },
  [GET_BUSINESS_DETAIL]: state => {
    state.detail.loading = true;
    state.detail.error.error = false;
  },
  [GET_BUSINESS_DETAIL_SUCCESS]: (state, action) => {
    state.detail.loading = false;
    state.detail.data = action.payload.data;
    state.detail.permissions = action.payload.meta.permissions;
  },
  [GET_BUSINESS_DETAIL_FAILURE]: (state, action) => {
    state.detail.loading = false;
    state.detail.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [GET_PRODUCT_DETAIL]: state => {
    state.productDetail.loading = true;
    state.productDetail.error.error = false;
  },
  [GET_PRODUCT_DETAIL_SUCCESS]: (state, action) => {
    state.productDetail.loading = false;
    state.enjoyMoreDiscounts = action.payload.meta.more_products;
    state.productDetail.data = action.payload.data;
  },
  [GET_PRODUCT_DETAIL_FAILURE]: (state, action) => {
    state.productDetail.loading = false;
    state.productDetail.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [CREATE_AN_ORDER]: state => {
    state.orderedProduct.loading = true;
    state.orderedProduct.error.error = false;
  },
  [CREATE_AN_ORDER_SUCCESS]: (state, action) => {
    state.orderedProduct.loading = false;
    state.orderedProduct.hasPurchased = true;
    state.orderedProduct.data = action.payload.data;
  },
  [CREATE_AN_ORDER_FAILURE]: (state, action) => {
    state.orderedProduct.loading = false;
    state.orderedProduct.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [CLEAR_ORDER_STATE]: state => {
    state.orderedProduct = initialState.orderedProduct;
  },
  [GET_ORDERS_OF_BUSINESS]: state => {
    state.ordersOfBusiness.loading = true;
    state.ordersOfBusiness.error.error = false;
  },
  [GET_ORDERS_OF_BUSINESS_SUCCESS]: (state, action) => {
    state.ordersOfBusiness.loading = false;
    state.ordersOfBusiness.permissions = action.payload.meta.permissions;
    state.ordersOfBusiness.sizePerResponse = action.payload.data
      ? action.payload.data.length
      : 0;
    state.ordersOfBusiness.data = action.payload.isLoadMore
      ? concat(state.ordersOfBusiness.data, action.payload.data)
      : action.payload.data;
  },
  [GET_ORDERS_OF_BUSINESS_FAILURE]: (state, action) => {
    state.ordersOfBusiness.loading = false;
    state.ordersOfBusiness.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [GET_MY_ORDERS]: state => {
    state.myOrders.loading = true;
    state.myOrders.error.error = false;
  },
  [GET_MY_ORDERS_SUCCESS]: (state, action) => {
    state.myOrders.loading = false;
    state.myOrders.data = action.payload.data;
  },
  [GET_MY_ORDERS_FAILURE]: (state, action) => {
    state.myOrders.loading = false;
    state.myOrders.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [ARCHIVE_ORDER]: state => {
    state.archivedOrder.error.error = false;
  },
  [ARCHIVE_ORDER_SUCCESS]: (state, action) => {
    state.ordersOfBusiness.data = state.ordersOfBusiness.data.filter(
      order => order.id !== action.payload.data.id,
    );
  },
  [ARCHIVE_ORDER_FAILURE]: (state, action) => {
    state.archivedOrder.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [GET_ALL_PRODUCTS]: state => {
    state.allProductsLoading = true;
    state.allProductsError.error = false;
  },
  [GET_ALL_PRODUCTS_SUCCESS]: (state, action) => {
    state.allProducts = action.payload.isLoadMore
      ? concat(state.allProducts, action.payload.data)
      : action.payload.data;

    state.allProductsPagination = action.payload.meta.pagination;
    state.allProductsLoading = false;
  },
  [GET_ALL_PRODUCTS_FAILURE]: (state, action) => {
    state.allProductsLoading = false;
    state.allProductsError.error = true;
    state.allProductsError.message = action.payload.message;
  },
});

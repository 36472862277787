import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withResponsive from '../../../helper/withResponsive';

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.isMobile ? '28px' : `${props.size}px`)};
  height: ${props => (props.isMobile ? '28px' : `${props.size}px`)};
  background-color: ${props =>
    props.isMobile && props.active ? '#12DFC0' : undefined};
  border: ${props =>
    `${props.isMobile ? 2 : 4}px solid  ${
      props.active ? '#12DFC0' : '#7B8E95'
    }`};
  border-radius: 50%;
  span {
    display: inline-block;
    color: ${props =>
      props.active ? (props.isMobile ? '#fff' : '#12DFC0') : '#7B8E95'};
    font-weight: ${props => (props.isMobile ? 400 : 600)};
    font-size: ${props =>
      props.isMobile ? '18px' : `${props.numberFontSize}px`};
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;
  }
`;

const StepLabel = styled.span`
  margin-top: 14px;
  color: ${props => (props.active ? '#12DFC0' : '#7B8E95')};
  font-weight: ${props => (props.isMobile ? 400 : 600)};
  font-size: ${props => (props.isMobile ? '16px' : `${props.labelFontSize}px`)};
  color: ${props => props.textColor};
  text-align: center;
  line-height: ${props => `${props.labelFontSize}px`};
`;

const Step = ({
  number,
  label,
  active,
  size,
  numberFontSize,
  labelFontSize,
  textColor,
  isMobile,
}) => {
  return (
    <StepContainer>
      <StepNumber
        isMobile={isMobile}
        size={size}
        active={active}
        numberFontSize={numberFontSize}
      >
        <span>{number}</span>
      </StepNumber>
      <StepLabel
        isMobile={isMobile}
        labelFontSize={labelFontSize}
        textColor={textColor}
      >
        {label}
      </StepLabel>
    </StepContainer>
  );
};

Step.propTypes = {
  number: PropTypes.number,
  size: PropTypes.number,
  label: PropTypes.string,
  labelFontSize: PropTypes.number,
  numberFontSize: PropTypes.number,
  textColor: PropTypes.string,
  active: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Step.defaultProps = {
  number: 0,
  size: 56,
  numberFontSize: 36,
  labelFontSize: 22,
  label: '',
  textColor: '#000000',
  active: false,
  isMobile: false,
};
export default withResponsive(Step);

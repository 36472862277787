import React from 'react';
import PropTypes from 'prop-types';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Form, FormGroup, Label, Input, Row } from 'reactstrap';

import FormError, { FormFieldError } from 'components/stateless/FormError';
import { Button }  from 'components';
import Log from 'services/log';
import * as types from 'types';

import { BoldSpan } from './styles';

const SubtotalPrice = props => {
  return (
    <BoldSpan>${props.price} USD</BoldSpan>
  );
};

SubtotalPrice.propTypes = {
  price: PropTypes.number.isRequired,
};

// see https://github.com/stripe/react-stripe-elements

class CardPurchaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleChange = e => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = e => {
    const { name } = this.state;
    e.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({ name })
    .then(({ token }) => {
      if (typeof token !== 'undefined') {
        Log.info(`Got token ${token}`, 'Card Purchase Form');
        this.props.onSubmitCC({
          paymentMethod: 'credit_card',
          paymentValue: token.id,
          name,
        });
      }
    });
  }

  render() {
    const { price, error, working } = this.props;
    const {
      name,
    } = this.state;

    const isInvalid = name === '';
    return (
      <Form className="mt-3" onSubmit={this.handleSubmit}>
        <FormError error={error} />
        <Row>
          <FormGroup className="col-md-4">
            <Label for="name">Name as it appears on card</Label>
            <Input
              type="text"
              className="input-text form-control"
              name="name"
              id="name"
              placeholder=""
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Row>
        <FormFieldError error={error} field="name" />
        <Row>
          <FormGroup className="col-md-6">
            <Label>Pay with</Label>
            <CardElement style={{ base: { fontSize: '18px' } }} hidePostalCode />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6">
            <div className="input-label">Subtotal <SubtotalPrice price={price / 100} /></div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
            <Button size="large" disabled={isInvalid || working}  type="submit">Confirm Purchase</Button>
          </FormGroup>
        </Row>
      </Form>
    );
  }
}

CardPurchaseForm.propTypes = {
  price: PropTypes.number.isRequired,
  stripe: PropTypes.object,
  onSubmitCC: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

CardPurchaseForm.defaultProps = {
  stripe: null,
  error: null,
  working: false,
};

export default injectStripe(CardPurchaseForm);

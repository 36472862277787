export const initialSubscribeState = {
  error: null,
  working: false,
  status: false,
};

export const initialState = {
  subscribe: initialSubscribeState,
};

export const getState = (state = initialState, stateName) => state[stateName];
export const getStateValue = (state = initialState, stateName, value) => state[stateName] ? state[stateName][value] : undefined;

import React from 'react';
import { FaqSection, PageTitle } from 'components';
import Redirect from 'config/redirect';
//import { Link } from 'react-router-dom';

const FaqPage = () => {
  return (
    <div>
      <main>
        <Redirect />
        <div className="container-fluid row-section pos-rel overflow-hidden-reg">
          <div className="container">
            <div className="jumbotron ">
              <PageTitle title="FAQ" />
              <div className="col-md-12 px-0">
                <p className="lead ff-semi-bold mb-0">
                  Have a question? Chat with us in our{' '}
                  <a
                    href="http://t.me/hellohedgie"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b>Telegram group</b>
                  </a>{' '}
                  or{' '}
                  <a href="mailto:hedgie@hedgie.io">
                    <b>email us</b>
                  </a>{' '}
                  directly.
                </p>
              </div>
              <div className="row justify-content-end  bgr-bottom-images pt-5">
                
                <FaqSection
                  title="What is Hedgie exactly?"
                  content="Hedgie is an OG NFT project, launched in 2018 on the heels of CryptoKitties. It’s a P2E game where you train your Hedgie by going on journeys, collect loot, level up, battle other players, and accumulate Curio coin. The project had good momentum in 2018 and then plateaued all through ‘crypto winter’. We’ve always believed in it and never abandoned our early players, genesis Hedgie holders. Now, we’re back in full dev mode, and ready to embrace new players."
                />
                <FaqSection
                  title="I heard Hedgies used to be given away for free?"
                  content="When we first launched in 2018 people could join and get a randomly generated free Hedgie (Essential Edition, bare Hedgie with no additional attributes). Recently those players had the last chance to mint their free Hedgies. Some did, and those who didn’t will never have a chance to mint them again. These Hedgies will remain playable, but with limited capacity. There are about 24K free Hedgies in the game."
                />
                <FaqSection
                  title="Why should I buy a Hedgie?"
                  content="Hedgie is a true OG project, started in late 2017 and launched in spring 2018. Unlike most other NFT projects it already has amazing utility, a P2E game that allows players to make Curio coin and spend  it on NFTs and other offers in our marketplace. This is a unique opportunity to enter an OG project at the ground leve."
                />
                <FaqSection
                  title="What is the NFT Battle Arena you’re talking about?"
                  content="This is our flagship product, offering great entertainment utility for your Hedgie. You stake Curio coin to go head-to-head with another Hedgie, and the winner takes all. We will soon open this to other carefully selected NFT projects to participate. There will be cross-project tournaments with amazing prizes. Train your Hedgie to be ready!"
                />
                <FaqSection
                  title="What are you doing with the OG Hedgie collection proceeds?"
                  content="Give back to the community! We reinvest profits into building and improving the project so our players see their assets go up in value. We’re also creating a community fund to be used for tournament prizes, giveaways, promotions and exclusive benefits for Hedgie holders."
                />
                <FaqSection
                  title="As an OG project from 2018, what is your claim to fame from the past?"
                  content="Hedgie was the first project to allow purchasing NFT for fiat. The world was completely different back then, and most people did not know what a Metamask wallet is, or how to buy Ether. We also sold a Cryptopunk in our marketplace for Curio coin in April 2020."
                />
                <FaqSection
                  title="What is Curio coin?"
                  content="Curio is the original name of our in-game coin. This is the coin you make as a Hedgie player, the coin you stake to participate in battles, and the coin you can spend on other NFTs in our Marketplace."
                />
                <FaqSection
                  title="What is the tokenomics of Curio coin and when is it going to be minted?"
                  content="This is work-in-progress, we’re working on it as fast as we can, and will share the announcement once we’re ready. Minting Curio coin on chain and listing it on Uniswap, or similar exchange service, are critical milestones in our 2022 roadmap."
                />
                    
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FaqPage;

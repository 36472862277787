import React, { Component } from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

import {
  Hue,
  Saturation,
  EditableInput,
} from 'react-color/lib/components/common/';
import { CustomPicker } from 'react-color';

const styles = reactCSS({
  default: {
    input: {
      fontSize: '12px',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    span: {
      marginLeft: '25px',
    },
    picker: {
      background: '#fff',
      borderRadius: '2px',
      boxShadow: '0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)',
      boxSizing: 'initial',
      width: '350px',
      fontFamily: 'Menlo',
      margin: 'auto',
      maxWidth: '100%',
    },
    saturation: {
      width: '100%',
      paddingBottom: '55%',
      position: 'relative',
      borderRadius: '2px 2px 0 0',
      overflow: 'hidden',
      height: '300px',
    },
    Saturation: {
      radius: '2px 2px 0 0',
    },
    body: {
      padding: '16px 16px 12px',
    },
    controls: {
      display: 'flex',
    },
    color: {
      width: '32px',
    },
    swatch: {
      marginTop: '6px',
      width: '16px',
      height: '16px',
      borderRadius: '8px',
      position: 'relative',
      overflow: 'hidden',
    },
    toggles: {
      flex: '1',
    },
    hue: {
      height: '10px',
      position: 'relative',
      marginBottom: '8px',
    },
    Hue: {
      radius: '2px',
    },
    alpha: {
      height: '10px',
      position: 'relative',
    },
    Alpha: {
      radius: '2px',
    },
    SpanError: {
      color: 'red',
      fontWeight: 700,
    },
  },
});
const validateColor = color => {
  const regex = /^((0x){0,1}|#{0,1})([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g;
  return regex.test(color);
};

class HueAndSaturation extends Component {
  state = {
    firstmsg: '',
    secondmsg: '',
  }
  handleColor = value => {
    let color = value.color;

    if (!validateColor(color)) {
      this.setState({
        firstmsg: 'Only valid hex:',
        secondmsg: '"a-f, A-F, and 0-9"',
      });
    } else {
      this.setState({
        firstmsg: '',
        secondmsg: '',
      });
    }
    this.props.onChange(color);
  }
  handleColorPicker = color => {

    if (validateColor(color)) {
      this.setState({
        firstmsg: 'Only valid hex:',
        secondmsg: '"a-f, A-F, and 0-9"',
      });
    } else {
      this.setState({
        firstmsg: '',
        secondmsg: '',
      });
    }
    this.props.onChange(color);
  }
  render() {
    const editableInputStyles = {
      label: {
        display: 'none'
      },
    };

    return (
      <div style={styles.picker}>
        <div style={styles.saturation}>
          <Saturation
            {...this.props}
            style={styles.Saturation}
            onChange={this.handleColorPicker}
          />
        </div>
        <div style={styles.body}>
          <div style={styles.controls} className="flexbox-fix">
            <div style={styles.toggles}>
              <div style={styles.hue}>
                <Hue
                  {...this.props}
                  style={styles.Hue}
                  onChange={this.handleColorPicker}
                />
              </div>
            </div>
          </div>
          <div style={styles.input}>
            <div style={styles.row}>
              <EditableInput
                style={editableInputStyles}
                label="color"
                is="Input"
                value={this.props.color}
                onChange={this.handleColor}
              />
              <div style={styles.span}>
                <span style={styles.SpanError}>{this.state.firstmsg} <br /> {this.state.secondmsg}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HueAndSaturation.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomPicker(HueAndSaturation);

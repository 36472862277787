import {
  GET_BATTLE_DETAIL,
  GET_BATTLE_DETAIL_FAILURE,
  GET_BATTLE_DETAIL_SUCCESS,
  SAW_BATTLE_DETAIL,
} from './actionTypes';

export const getBattleDetail = (battleID) => ({
  type: GET_BATTLE_DETAIL,
  payload: battleID,
});

export const getBattleDetailSuccess = (payload) => ({
  type: GET_BATTLE_DETAIL_SUCCESS,
  payload,
});

export const getBattleDetailFailure = (error) => ({
  type: GET_BATTLE_DETAIL_FAILURE,
  payload: error,
});

export const sawBattleDetail = (color) => ({
  type: SAW_BATTLE_DETAIL,
  payload: color,
});

import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormText,
} from 'reactstrap';

export default function DomainForm({ visible, onChange, error }) {
  return visible ? (
    <Form className="pt-5">
      <FormGroup row>
        <Label sm={2} for="exampleEmail">
          Domain
        </Label>
        <Col sm={6}>
          <Input
            placeholder="Your domain"
            invalid={error.domain}
            type="text"
            name="domain"
            onChange={onChange}
          />
          <FormText>
            Domain must have suffix <strong>.zil</strong>
          </FormText>
          <FormText>
            Eg: bitcoin.zil
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} for="ownerType">
          Owner type
          </Label>
        <Col sm={6}>
          <FormGroup check inline>
            <Label check>
              <Input type="radio" name="ownerType" checked /> ETH Public Key
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input type="radio" name="ownerType" /> ZIL Public Key
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} for="ownerPublicKey">
          Owner Public Key
        </Label>
        <Col sm={6}>
          <Input
            placeholder="0xa2f646354d081019fa3197ad8eae554ffbd266172d84dee778a0e41eb4f7330d991bdb57bc5d65e0928b343bc71bc8cb68e4932ea1721d4c6445059702a17b5b"
            invalid={error.ownerPublicKey}
            type="text"
            name="ownerPublicKey"
            onChange={onChange}
          />
          <FormText>
            Owner must be ether <strong>ETH</strong> or <strong>ZIL</strong>{' '} public key.
          </FormText>
          <FormText>
            How to generate public key from a private key?
            <a href="https://medium.com/@piyopiyo/how-to-get-ethereum-public-key-from-private-key-javascript-835c276e39bc">
              https://medium.com/@piyopiyo/how-to-get-ethereum-public-key-from-private-key-javascript-835c276e39bc
            </a>
          </FormText>
        </Col>
      </FormGroup>
    </Form>
  ) : null;
}

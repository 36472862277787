import React from 'react';

const MetaMaskStep = ({number, img, content}) => {
    return (
        <div className="row install-step-item mt-5">
            <div className="col-md-2 text-center">
                <div className="order-number">
                    <span>{number}</span>
                </div>
            </div>
            <div className="col-md-8">
                <div className="title-row">
                    <h2 className="ff-semi-bold mb-0">{content}</h2>
                </div>
                <div className="mt-5">
                    {img ? <img src={img} alt="step" className="img-fluid step-pic" /> : ""}
                </div>
            </div>
        </div>
    )
}

export default MetaMaskStep
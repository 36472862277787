/* eslint react/no-danger: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledOpenNewTab } from 'styles/main';

const HedgieSocialIcon = props => {
  const size = 42;
  const style = { height: size, width: size };
  return (
    <StyledOpenNewTab href={props.url} target="_blank">
      <span className="fa-layers fa-fw" style={style}>
        <FontAwesomeIcon icon={faCircle} color="#62295e" style={style} />
        <FontAwesomeIcon
          icon={props.network}
          color="#b163ac"
          transform="shrink-6"
          style={style}
        />
      </span>
    </StyledOpenNewTab>
  );
};

HedgieSocialIcon.propTypes = {
  url: PropTypes.string.isRequired,
  network: PropTypes.object.isRequired,
};

export default HedgieSocialIcon;

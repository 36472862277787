import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as paths from 'config/paths';
import {
  FormError,
  ProgressiveImageLoader,
  Button,
  Typography,
} from 'components';
import { isEmpty, includes } from 'lodash';
import { StyledOpenNewTab } from 'styles/main';
import Location from './Location';
import {
  StyledContainer,
  StyledGroupImgAndInfo,
  StyledImage,
  StyledButton,
  StyledInfoWrapper,
  StyledLocation,
  StyledHead,
  StyledWebsite,
} from './styles';
import { BUSINESS_PERMISSIONS } from '../../../const/permissions';
import { removeLastCharacter } from '../../../helper/string';

function renderLocation(address, city, country) {
  if (!address && !city && !country) {
    return '';
  }
  const addressStr = address ? `${address}` : '';
  const cityStr = city ? `, ${city}` : '';
  const countryStr = country ? `, ${country}` : '';

  return `${addressStr}${cityStr}${countryStr}`;
}

function checkPermissionsRead(permissions) {
  return includes(permissions, BUSINESS_PERMISSIONS.read);
}

const Detail = ({ businessId, getBusinessDetail, businessDetail }) => {
  const { data, error, permissions } = businessDetail;

  useEffect(() => {
    getBusinessDetail(businessId);
  }, [businessId, getBusinessDetail]);

  const { address, city, country, website } = data;

  const memoizedWebsite = useMemo(() => removeLastCharacter(website), [
    website,
  ]);

  const memorizedRenderLocation = useMemo(
    () => renderLocation(address, city, country),
    [address, city, country],
  );

  const memoizedPermissionRead = useMemo(
    () => checkPermissionsRead(permissions),
    [permissions],
  );

  if (isEmpty(data)) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledHead>
        <StyledGroupImgAndInfo>
          <StyledImage>
            <ProgressiveImageLoader
              imgUrl={data.imageURL}
              width={180}
              height={180}
              placeHolderUrl=""
            />
          </StyledImage>
          <StyledInfoWrapper>
            <Typography isBold text={data.name} />
            {memorizedRenderLocation ? (
              <StyledLocation>
                <Location width={18} />
                <Typography
                  className="pl-1"
                  color="#62295E"
                  fontSize={16}
                  text={memorizedRenderLocation}
                />
              </StyledLocation>
            ) : null}
            {website ? (
              <StyledWebsite>
                <StyledOpenNewTab href={website} target="_blank">
                  {memoizedWebsite}
                </StyledOpenNewTab>
              </StyledWebsite>
            ) : null}
          </StyledInfoWrapper>
        </StyledGroupImgAndInfo>
        {memoizedPermissionRead ? (
          <StyledButton>
            <Button tag={Link} to={`${paths.BUSINESS_PAGE}/${businessId}`}>
              View orders
            </Button>
          </StyledButton>
        ) : null}
      </StyledHead>
      <Typography
        className="pt-3 bu-description"
        fontSize={16}
        text={data.description}
        align="left"
      />
      {error.error ? <FormError error={error} /> : null}
    </StyledContainer>
  );
};

Detail.propTypes = {
  businessId: PropTypes.string.isRequired,
  businessDetail: PropTypes.object.isRequired,
  getBusinessDetail: PropTypes.func.isRequired,
};

export default Detail;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PageTitle, Tabs, Businesses, Button } from 'components';
import * as paths from 'config/paths';

const BusinessesPage = ({ getMyBusinesses, myBusinesses, user }) => {
  const BusinessesTypeSigned = 'signed';
  const BusinessesTypeManaged = 'managed';
  const userId = user && user.id;

  const tabs = [
    {
      id: 'allBusinesses',
      name: 'All Businesses',
      tabPanel: (
        <Businesses
          key="all-businesses"
          myBusinesses={myBusinesses}
          isAllBusinesses
          getMyBusinesses={getMyBusinesses}
        />
      ),
    },
    {
      id: 'myBusinesses',
      name: 'My Businesses',
      tabPanel: userId ? (
        <Businesses
          key="myBusinesses"
          myBusinesses={myBusinesses}
          isAllBusinesses={false}
          getMyBusinesses={getMyBusinesses}
          businessType={BusinessesTypeManaged}
        />
      ) : (
        <div className="text-center mt-5" >
          <Link to={paths.SIGNIN}>
            <Button size="large" className="mt-0 mb-5">Sign In</Button>
          </Link>
        </div>
        ),
    },
    {
      id: 'signedBusinesses',
      name: 'Signed Businesses',
      tabPanel: userId ? (
        <Businesses
          key="signedBusinesses"
          myBusinesses={myBusinesses}
          isAllBusinesses={false}
          businessType={BusinessesTypeSigned}
          getMyBusinesses={getMyBusinesses}
        />
      ) : (
        <div className="text-center mt-5" >
          <Link to={paths.SIGNIN}>
            <Button size="large" className="mt-0 mb-5">Sign In</Button>
          </Link>
        </div>
        ),
    },
  ];

  return (
    <div>
      <PageTitle title="Businesses" />
      <Tabs tabsData={tabs} activeTab={tabs[0].id} />
    </div>
  );
};

BusinessesPage.propTypes = {
  getMyBusinesses: PropTypes.func,
  myBusinesses: PropTypes.object,
  user: PropTypes.object.isRequired,
};

BusinessesPage.defaultProps = {
  getMyBusinesses: null,
  myBusinesses: {},
};

export default BusinessesPage;

import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  Button,
  PageTitle,
  SubHeadingBold,
  HedgieImg,
  Divider,
} from 'components';
import * as paths from 'config/paths';
import ExclusiveItems from 'images/exclusive_items.png';
import styled from 'styled-components';

const ExclusiveImages = styled.div`
  min-height: 180px;
`;

class PresalePremiumSlide extends React.PureComponent {
  render() {
    const rowClass = 'py-5 ';
    return (
      <ScrollableAnchor id="premium-hedgies">
        <div className="container" id="premium-hedgies">
          <Row className="pt-5">
            <Col>
              <PageTitle title="Exclusive Hedgies" />
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md="6">
              <Row>
                <Col>
                  <ExclusiveImages className="d-flex text-center justify-content-center">
                    <div>
                      <HedgieImg width="100" color="#111ddd" alt="#111ddd" />
                      <p>#111ddd</p>
                    </div>
                    <div>
                      <HedgieImg width="100" color="#dd44dd" alt="#dd44dd" />
                      <p>#dd44dd</p>
                    </div>
                    <div>
                      <HedgieImg width="100" color="#f1ff1f" alt="#f1ff1f" />
                      <p>#f1ff1f</p>
                    </div>
                    <div>
                      <HedgieImg width="100" color="#44ee44" alt="#44ee44" />
                      <p>#44ee44</p>
                    </div>
                  </ExclusiveImages>
                </Col>
              </Row>
              <Row className="pt-2 pb-3">
                <Col md="12">
                  <SubHeadingBold className="text-center" heading="Exclusively Awesome" />
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p className="text-center">
                    Exclusive Hedgies are especially rare and unique. 
                    There are six tiers available with a limited 
                    number of Hedgies in each. There’s our rarest
                    Radiant Hedgies (only 8 in existence, each with the 
                    highest power rank for 1 of 8 available powers), 
                    hhhhhhedgies (only 14 in existence, each with a hex 
                    code containing six of the same characters), Double 
                    Trouble Hedgies (3,360 in existence, each with 
                    cool 2-2-2 hex code combo), and more!
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col>
                  <ExclusiveImages className="d-flex text-center justify-content-center mx-2 align-items-center">
                    <img src={ExclusiveItems} alt="exclusive items" style={{ width: '100%' }} />
                  </ExclusiveImages>
                </Col>
              </Row>
              <Row className="pt-2 pb-3">
                <Col md="12">
                  <SubHeadingBold className="text-center" heading="Exclusive Content" />
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p className="text-center">
                    Each exclusive tier comes with a different exclusive item 
                    that boosts your Hedgie’s performance in the game. The highest 
                    tier will receive all six exclusive items, the next highest 
                    will receive 5, then 4...you get it!
                  </p>
                  <p className="text-center">
                    And who knows, when we introduce new kingdoms 
                    in the Hedgie game, we may just give exclusive Hedgie owners first dibs...
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md="12">
              <div className="d-flex text-center">
                <Button size="large" className="m-auto" tag={Link} to={paths.PREMIUM_HEDGIES}>
                  Pick Your Exclusive Hedgie Now
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ScrollableAnchor>
    );
  }
}

PresalePremiumSlide.propTypes = {
};

PresalePremiumSlide.defaultProps = {
};

export default PresalePremiumSlide;

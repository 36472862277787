import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as paths from 'config/paths';
import NumberUtil from 'services/numberUtil';

import imgCurio from 'images/curio.svg';

const CurioBalanceWrapper = styled(Link)`
  margin: 20px 0px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
`;
const CurioImg = styled.img`
  width: 45px;
`;
const StyledBalance = styled.div`
  margin-left: 20px;
`;

const CurioBalance = ({ balance, onClick }) => (
  <CurioBalanceWrapper to={paths.TRANSACTIONS} onClick={onClick}>
    <CurioImg src={imgCurio} />
    <StyledBalance>
      <span className="color-light ff-semi-bold">Curio Balance:</span> <br />
      <span className="color-pink ff-semi-bold" style={{ fontSize: '22px' }}>
        {NumberUtil.toDecimal(balance)}
      </span>
    </StyledBalance>
  </CurioBalanceWrapper>
);

CurioBalance.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

CurioBalance.defaultProps = {
  balance: 0,
  onClick: () => {},
};

export default CurioBalance;

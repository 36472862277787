import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import Info from '../Info';
import { StyledInfo } from '../TheGame/TopSlide';
import Anchor from '../Anchor';

const TEXT =
  'Every hero needs a home base! Journeys cost energy, and the Hedgie House is where your Hedgie recovers its energy. ' +
  'Use the resources your Hedgie finds in Curiopia to build furniture, and make upgrades  ' +
  'to speed up your Hedgie’s recovery time.';

const StyledText = styled.div`
  position: absolute;
  left: 20px;
  width: 100%;
  top: 20px;
  ${media.phone`
    left: 0;  
  `};
`;

const SweetHome = ({ isMobile, onNextPage }) => {
  if (isMobile) {
    return (
      <React.Fragment>
        <StyledText>
          <Info title="Home Sweet Home" text={TEXT} textColor="#000" />
        </StyledText>
        <Anchor
          text="Spend Curio in Real Life"
          textColor="#000"
          onClick={onNextPage}
          bottom={60}
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <StyledInfo>
        <Info title="Home Sweet Home" text={TEXT} textColor="#000" />
      </StyledInfo>
      <Anchor
        text="Spend Curio in Real Life"
        textColor="#000"
        onClick={onNextPage}
        bottom={isMobile ? 70 : 50}
      />
    </React.Fragment>
  );
};

SweetHome.propTypes = {
  isMobile: PropTypes.bool,
  onNextPage: PropTypes.func,
};

SweetHome.defaultProps = {
  isMobile: false,
  onNextPage: null,
};

export default memo(SweetHome);

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const MARK_ALL_AS_SEEN = 'MARK_ALL_AS_SEEN';
export const MARK_ALL_AS_SEEN_SUCCESS = 'MARK_ALL_AS_SEEN_SUCCESS';
export const MARK_ALL_AS_SEEN_FAILURE = 'MARK_ALL_AS_SEEN_FAILURE';
export const REGISTER_A_DEVICE = 'REGISTER_A_DEVICE';
export const REGISTER_A_DEVICE_SUCCESS = 'REGISTER_A_DEVICE_SUCCESS';
export const REGISTER_A_DEVICE_FAILURE = 'REGISTER_A_DEVICE_FAILURE';
export const GET_NOTIFICATION_UNSEEN_NUMBER = 'GET_NOTIFICATION_UNSEEN_NUMBER';
export const GET_NOTIFICATION_UNSEEN_NUMBER_SUCCESS =
  'GET_NOTIFICATION_UNSEEN_NUMBER_SUCCESS';
export const GET_NOTIFICATION_UNSEEN_NUMBER_FAILURE =
  'GET_NOTIFICATION_UNSEEN_NUMBER_FAILURE';
export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS';
export const MARK_ALL_AS_READ_FAILURE = 'MARK_ALL_AS_READ_FAILURE';
export const MARK_A_NOTIFICATION_AS_READ = 'MARK_A_NOTIFICATION_AS_READ';
export const MARK_A_NOTIFICATION_AS_READ_SUCCESS =
  'MARK_A_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_A_NOTIFICATION_AS_READ_FAILURE =
  'MARK_A_NOTIFICATION_AS_READ_FAILURE';
export const ARCHIVE_NOTIFICATION = 'ARCHIVE_NOTIFICATION';
export const ARCHIVE_NOTIFICATION_SUCCESS = 'ARCHIVE_NOTIFICATION_SUCCESS';
export const ARCHIVE_NOTIFICATION_FAILURE = 'ARCHIVE_NOTIFICATION_FAILURE';
export const UPDATE_NEW_NOTIFICATION = 'UPDATE_NEW_NOTIFICATION';
export const UN_REGISTER_DEVICE = 'UN_REGISTER_DEVICE';
export const UN_REGISTER_DEVICE_SUCCESS = 'UN_REGISTER_DEVICE_SUCCESS';
export const UN_REGISTER_DEVICE_FAILURE = 'UN_REGISTER_DEVICE_FAILURE';
export const ENABLE_BROWSER_NOTIFICATION = 'ENABLE_BROWSER_NOTIFICATION';

export const UPDATE_NOTIFICATION_REFERENCE = 'UPDATE_NOTIFICATION_REFERENCE';
export const UPDATE_NOTIFICATION_REFERENCE_SUCCESS =
  'UPDATE_NOTIFICATION_REFERENCE_SUCCESS';
export const UPDATE_NOTIFICATION_REFERENCE_FAILURE =
  'UPDATE_NOTIFICATION_REFERENCE_FAILURE';

export const GET_NOTIFICATION_REFERENCE = 'GET_NOTIFICATION_REFERENCE';
export const GET_NOTIFICATION_REFERENCE_SUCCESS =
  'GET_NOTIFICATION_REFERENCE_SUCCESS';
export const GET_NOTIFICATION_REFERENCE_FAILURE =
  'GET_NOTIFICATION_REFERENCE_FAILURE';

export const getNotifications = payload => ({
  type: GET_NOTIFICATIONS,
  payload,
});

export const getNotificationsSuccess = payload => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsFailure = error => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const registerADevice = payload => ({
  type: REGISTER_A_DEVICE,
  payload,
});

export const registerADeviceSuccess = payload => ({
  type: REGISTER_A_DEVICE_SUCCESS,
  payload,
});

export const registerADeviceFailure = error => ({
  type: REGISTER_A_DEVICE_FAILURE,
  payload: error,
});

export const getNumberOfUnseenNotifications = () => ({
  type: GET_NOTIFICATION_UNSEEN_NUMBER,
});

export const getNumberOfUnseenNotificationsSuccess = payload => ({
  type: GET_NOTIFICATION_UNSEEN_NUMBER_SUCCESS,
  payload,
});

export const getNumberOfUnseenNotificationsFailure = error => ({
  type: GET_NOTIFICATION_UNSEEN_NUMBER_FAILURE,
  payload: error,
});

export const markAllNotificationsAsRead = () => ({
  type: MARK_ALL_AS_READ,
});

export const markAllNotificationsAsReadSuccess = payload => ({
  type: MARK_ALL_AS_READ_SUCCESS,
  payload,
});

export const markAllNotificationsAsReadFailure = error => ({
  type: MARK_ALL_AS_READ_FAILURE,
  payload: error,
});

export const markANotificationAsRead = notificationId => ({
  type: MARK_A_NOTIFICATION_AS_READ,
  payload: notificationId,
});

export const markANotificationAsReadSuccess = payload => ({
  type: MARK_A_NOTIFICATION_AS_READ_SUCCESS,
  payload,
});

export const markANotificationAsReadFailure = error => ({
  type: MARK_A_NOTIFICATION_AS_READ_FAILURE,
  payload: error,
});

export const archiveNotification = payload => ({
  type: ARCHIVE_NOTIFICATION,
  payload,
});

export const archiveNotificationSuccess = payload => ({
  type: ARCHIVE_NOTIFICATION_SUCCESS,
  payload,
});

export const archiveNotificationFailure = error => ({
  type: ARCHIVE_NOTIFICATION_FAILURE,
  payload: error,
});

export const markAllAsSeen = () => ({
  type: MARK_ALL_AS_SEEN,
});

export const markAllAsSeenSuccess = payload => ({
  type: MARK_ALL_AS_SEEN_SUCCESS,
  payload,
});

export const markAllAsSeenFailure = error => ({
  type: MARK_ALL_AS_SEEN_FAILURE,
  payload: error,
});

export const updateNewNotification = () => ({
  type: UPDATE_NEW_NOTIFICATION,
});

export const unRegisterDevice = token => ({
  type: UN_REGISTER_DEVICE,
  payload: token,
});

export const unRegisterDeviceSuccess = payload => ({
  type: UN_REGISTER_DEVICE_SUCCESS,
  payload,
});
export const unRegisterDeviceFailure = error => ({
  type: UN_REGISTER_DEVICE_FAILURE,
  payload: error,
});

export const blockBrowser = isBlocking => ({
  type: ENABLE_BROWSER_NOTIFICATION,
  payload: isBlocking,
});

export const updateNotificationReference = (isEnabled, isSettingBrowser) => ({
  type: UPDATE_NOTIFICATION_REFERENCE,
  payload: { isEnabled, isSettingBrowser },
});
export const updateNotificationReferenceSuccess = payload => ({
  type: UPDATE_NOTIFICATION_REFERENCE_SUCCESS,
  payload,
});

export const updateNotificationReferenceFailure = error => ({
  type: UPDATE_NOTIFICATION_REFERENCE_FAILURE,
  payload: error,
});

export const getNotificationReference = () => ({
  type: GET_NOTIFICATION_REFERENCE,
});

export const getNotificationReferenceSuccess = payload => ({
  type: GET_NOTIFICATION_REFERENCE_SUCCESS,
  payload,
});

export const getNotificationReferenceFailure = error => ({
  type: GET_NOTIFICATION_REFERENCE_FAILURE,
  payload: error,
});

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import * as paths from 'config/paths';
import { HedgieSocialIcon, Desktop, Mobile, NewsLetterForm } from 'components';
import { brandIcons } from 'icons';

const PresaleFooter = () => {
  return (
    <footer className="container-fluid dark-bgr py-5 pos-rel">
      <Desktop>
        <Container>
          <Row className="justify-content-center">
            <Col md="3">
              <ul className="footer-nav color-dark-contrast ff-extra-bold">
                <li>
                  <Link to={paths.ABOUT_PAGE}>The Game</Link>
                </li>
                <li>
                  <Link to={paths.MARKETPLACE}>Spend Curio</Link>
                </li>
                <li>
                  <Link to="/our-team">Team</Link>
                </li>
                <li>
                  <Link to="/roadmap">Roadmap</Link>
                </li>
              </ul>
            </Col>
            <Col md="3">
              <ul className="footer-nav color-dark-contrast ff-extra-bold">
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <a href="https://medium.com/hellohedgie" target="blank">
                    Blog
                  </a>
                </li>
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <a href="mailto:hedgie@hedgie.io" target="blank">
                    Contact Us
                  </a>
                </li>
              </ul>
            </Col>
            <Col md="4" className="flex-vcenter">
              <div>
                <div className="subscribe-news">
                  <NewsLetterForm />
                </div>
                <div className="soc-icons">
                  <HedgieSocialIcon
                    url="https://t.me/hellohedgie"
                    network={brandIcons.faTelegramPlane}
                  />
                  <HedgieSocialIcon
                    url="https://discord.gg/mhYwH5G"
                    network={brandIcons.faDiscord}
                  />
                  <HedgieSocialIcon
                    url="https://www.instagram.com/hedgieio/"
                    network={brandIcons.faInstagram}
                  />
                  <HedgieSocialIcon
                    url="https://twitter.com/hellohedgie"
                    network={brandIcons.faTwitter}
                  />
                  <HedgieSocialIcon
                    url="https://www.facebook.com/hellohedgie/"
                    network={brandIcons.faFacebookF}
                  />
                  <HedgieSocialIcon
                    url="https://medium.com/hellohedgie"
                    network={brandIcons.faMediumM}
                  />
                  <HedgieSocialIcon
                    url="https://www.youtube.com/hellohedgie"
                    network={brandIcons.faYoutube}
                  />
                  <HedgieSocialIcon
                    url="https://www.reddit.com/r/Hedgie"
                    network={brandIcons.faReddit}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <div className="copyrightFooter">
                <p className="color-dark-contrast">
                  &copy; Copyright 2021 Hedgie Inc. All rights reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Desktop>
      <Mobile>
        <Container>
          <Row>
            <Col md="12">
              <div className="subscribe-news">
                <NewsLetterForm />
              </div>
              <div className="mobileFooter d-flex justify-content-center">
                <p className="color-dark-contrast text-center">
                  Copyright &copy; 2021, Hedgie Inc. All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Mobile>
    </footer>
  );
};

export default PresaleFooter;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const ContentContainer = ({ ...props }) => {
  return (
    <Container style={{ }}>
      {props.children}
    </Container>);
};

ContentContainer.propTypes = {
    children: PropTypes.node,
};

ContentContainer.defaultProps = {
    children: null,
};

export default ContentContainer;

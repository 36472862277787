import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

export default search => {
  const urlParams = new URLSearchParams(search);
  const referralCodeFromUrl = urlParams.get('referral_code');

  const [referralCodePersist, setReferralCodePersist] = useLocalStorage(
    'referralCode',
    '',
  );

  useEffect(() => {
    if (referralCodeFromUrl) {
      setReferralCodePersist(referralCodeFromUrl);
    }
  }, [referralCodeFromUrl, setReferralCodePersist]);

  return {
    referralCode: referralCodeFromUrl || referralCodePersist,
    removeReferralCodeFromLocalStorage: () => setReferralCodePersist(''),
  };
};

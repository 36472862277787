import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { palette } from 'styled-theme';
import * as types from 'types';

import MetaMaskPurchase from './MetaMaskPurchase';

const StyledButton = styled(Button)`
  && {
    background-color: ${palette(0)};
    font-weight: normal;
    font-size: 0.7rem;
    padding: 12px 30px;
    border-radius: 10px;
  }
`;

StyledButton.defaultProps = {
  palette: 'button',
};

const PurchaseWithEther = ({ ...props }) => {
  const className = `pageTitle text-center ${props.className}`;
  return (
    <MetaMaskPurchase className={className} {...props} />
  );
};

PurchaseWithEther.propTypes = {
  onCompleteEtherBuyNow: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: types.error,
  working: PropTypes.bool,
  price: PropTypes.string.isRequired,
};

PurchaseWithEther.defaultProps = {
  className: '',
  error: null,
  working: false,
};

export default PurchaseWithEther;

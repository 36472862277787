import React from 'react';
// import PropTypes from 'prop-types';

const Tools = props => {
  return <div className="align-center">Coming soon!</div>;
};

Tools.propTypes = {};

export default Tools;

import React from 'react';
import { Col, Row } from 'reactstrap';
import { useBattle } from './battle-context';
import { HedgieImg, PageTitle } from 'components';
import BattleForm from './BattleForm';
import { StyledContainer } from './MainFlow';

const BattleHome = () => {
  const { hedgie, submitBetForm } = useBattle();
  return (
    <StyledContainer>
      <PageTitle title="Arena" />
      <Row gutter={20}>
        <Col md={6} className="align-center">
          <div className="text-center">
            <HedgieImg
              color={`#${hedgie ? hedgie.color : ''}`}
              className="img-fluid"
              width={360}
              imgURL={hedgie? hedgie.imgURL: null}
            />
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-center align-items-center">
          <div>
            <BattleForm submitForm={submitBetForm} />
          </div>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default BattleHome;

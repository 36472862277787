import { createSelector } from 'reselect';

const onboardingSelector = state => state.onboarding;

export const selectOnboarding = createSelector(
  onboardingSelector,
  onboarding => onboarding,
);

export const selectOnboardingCurrentStep = createSelector(
  onboardingSelector,
  onboarding => onboarding.currentStep,
);

export const selectOnboardingWorldId = createSelector(
  onboardingSelector,
  onboarding => onboarding.worldId,
);

import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { PageTitle, CurioPrice } from 'components';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { transferCurio } from 'store/user/actions';
import { selectCurrentCurio } from 'store/user/selectors';
import NumberUtil from 'services/numberUtil';
import TransferCurioForm from './TransferCurioForm';

const TransferCurioPage = () => {
  const dispatch = useDispatch();
  const currentCurioBalance = useSelector(state => selectCurrentCurio(state));

  const handleSubmit = useCallback(
    value => {
      const convertedCurio = NumberUtil.convertCuritoToWEI(value.amount);
      dispatch(transferCurio({ ...value, amount: convertedCurio }));
    },
    [dispatch],
  );

  return (
    <div>
      <PageTitle title="Transfer curio" />
      <div className="align-center pb-3">
        <span className="pr-2">Your current: </span>
        <CurioPrice curioPrice={currentCurioBalance} />
      </div>
      <Container>
        <Row>
          <Col xs="12" sm="6" md={4}>
            <TransferCurioForm
              onSubmit={handleSubmit}
              currentCurioBalance={currentCurioBalance}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// TransferCurioPage.propTypes = {};

export default TransferCurioPage;

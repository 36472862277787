import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { displayDurationLikeClock, updateDuration } from 'helper/handleTime';
import { HedgieImg, SubHeading, DurationTimer } from 'components';
import styled from 'styled-components';
import UnveilTreasuresButton from './UnveilTreasuresButton';

const MOBILE_HEDGIE_IMG_WIDTH = 98;
const DESKTOP_HEDGIE_IMG_WIDTH = 168;

const StyledWrapper = styled.div`
  margin: 10px 0;
  background-color: ${props =>
    props.index % 2 && props.isMobile ? '#ffffff' : null};
  padding: ${props => (props.isMobile ? '35px 0 30px 25px' : null)};
  .img-fluid {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const JourneyListItem = React.memo(
  ({ journey, isMobile, index, openModal, isCountdown }) => {
    const hedgieImgWidth = isMobile
      ? MOBILE_HEDGIE_IMG_WIDTH
      : DESKTOP_HEDGIE_IMG_WIDTH;

    return (
      <Col md={6}>
        <StyledWrapper index={index} isMobile={isMobile}>
          <Row>
            <Col md={6} xs={isMobile ? 4 : 6} sm={6}>
              <HedgieImg
                color={`#${journey.hedgie.color}`}
                className="img-fluid"
                width={hedgieImgWidth}
                imgURL={journey.hedgie.imgURL}
              />
            </Col>
            <Col md={6} xs={isMobile ? 8 : 6} sm={6}>
              <SubHeading
                className="pt-1 sub-heading-left"
                heading={`World: ${journey.world ? journey.world.name : ''}`}
              />
              <SubHeading
                className="pt-1 sub-heading-left"
                heading={`Color: #${journey.hedgie.color}`}
              />
              <SubHeading
                className="pt-1 sub-heading-left"
                render={() =>
                  isCountdown ? (
                    <DurationTimer
                      duration={updateDuration(
                        journey.createdAt,
                        journey.duration,
                      )}
                    />
                  ) : (
                    `Duration: ${displayDurationLikeClock(
                      Number(journey.duration),
                    )}`
                  )
                }
              />
              <SubHeading
                className="pt-1 sub-heading-left"
                heading={`Date: ${moment(journey.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <div className="pt-3">
                {journey.status === 'completed' ? (
                  <UnveilTreasuresButton
                    isMobile={isMobile}
                    label="Unveil Treasures"
                    onClick={openModal}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </StyledWrapper>
      </Col>
    );
  },
);

JourneyListItem.propTypes = {
  journey: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  isCountdown: PropTypes.bool,
};

JourneyListItem.defaultProps = {
  openModal: null,
  isCountdown: false,
};

export default JourneyListItem;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import stamp from '../../../images/hedgie-owned.png';

const OwnedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 20px; */
`;

const OwnedImage = styled.img`
  padding: 0 5px;
  width: 70px;
`;

const OwnedTitle = styled.h3`
  font-weight: 900;
  color: #97aeb8;
  text-transform: uppercase;
  font-size: 1.1rem;
  margin: 0;
`;

const HedgieOwnedStamp = ({ className }) => {
  return (
    <OwnedContainer className={className}>
      <OwnedImage src={stamp} alt="Owned" />
      <OwnedTitle>Minted</OwnedTitle>
    </OwnedContainer>
  );
};

HedgieOwnedStamp.propTypes = {
  className: PropTypes.string,
};

HedgieOwnedStamp.defaultProps = {
  className: '',
};

export default HedgieOwnedStamp;

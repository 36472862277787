import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Spinner, FormError, Button } from 'components';
import Message from './Message';

const StyledContainer = styled.div`
  position: relative;
`;

const List = ({
  notifications,
  error,
  loading,
  canLoadMore,
  loadMore,
  markANotificationAsRead,
  archiveNotification,
  history,
  onCloseDrawer,
}) => {
  return (
    <StyledContainer>
      {loading ? <Spinner /> : null}
      {isEmpty(notifications) ? null : (
        <div>
          {notifications.map(msg => (
            <Message
              message={msg}
              key={msg.id}
              markAsRead={markANotificationAsRead}
              archive={archiveNotification}
              history={history}
              onCloseDrawer={onCloseDrawer}
            />
          ))}
        </div>
      )}
      {error.error ? <FormError error={error} /> : null}
      {canLoadMore ? (
        <div className="align-center pt-3 pb-3">
          <Button onClick={loadMore}>Load more</Button>
        </div>
      ) : null}
    </StyledContainer>
  );
};

List.propTypes = {
  notifications: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  canLoadMore: PropTypes.bool,
  loadMore: PropTypes.func,
  markANotificationAsRead: PropTypes.func,
  archiveNotification: PropTypes.func,
  history: PropTypes.object,
  onCloseDrawer: PropTypes.func,
};

List.defaultProps = {
  notifications: [],
  loading: false,
  canLoadMore: false,
  error: {
    error: false,
    message: '',
  },
  loadMore: null,
  markANotificationAsRead: null,
  archiveNotification: null,
  history: null,
  onCloseDrawer: null,
};

export default React.memo(List);

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { media } from 'styles/main';
import {
  HeadLine,
  Typography,
  CurioPrice,
  Button,
  IconAndText,
} from 'components';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { assembleTextWithComma } from 'helper/string';

const StyledContent = styled.div`
  background-color: #eef3f4;
`;

const StyledCurioAndButton = styled.div`
  padding-top: 20px;
  .buy-btn {
    margin-left: 0 !important;
    margin-top: 10px;
    width: 120px;
  }
  ${media.phone`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buy-btn {
      margin-left: 0;
      margin-top: 0;
      width: 80px;
    }
  `}
`;

const StyledSpan = styled.span`
  font-weight: 600;
  padding-left: 5px;
`;

const ProductInfo = ({
  id,
  description,
  curioPrice,
  business,
  buyItem,
  name,
  featured,
  isOnline,
  showAddress,
  inventoryQuantity,
  ...props
}) => {
  const companyName = get(business, 'name', '');
  const city = get(business, 'city', '');
  const country = get(business, 'country', '');

  const buyCallback = useCallback(
    () => {
      buyItem(id);
    },
    [buyItem, id],
  );
  const title = featured ? `Featured: ${name}` : name;

  const location = useMemo(
    () => {
      return assembleTextWithComma([city, country]);
    },
    [city, country],
  );


  return (
    <StyledContent {...props}>
      <HeadLine title={title} fontSize={18} color="#000" align="left" />
      <Typography text="By" fontSize={16} color="#6F0056" align="left">
        <StyledSpan>{companyName}</StyledSpan>
      </Typography>
      {location && showAddress ? (
        <div className="mt-2">
          <IconAndText text={location} icon={faMapMarkerAlt} />
        </div>
      ) : null}
      {(inventoryQuantity && inventoryQuantity>0)?
      <StyledCurioAndButton className="mt-2">
        <CurioPrice curioPrice={curioPrice} />
        {buyItem ? (
          <Button onClick={buyCallback} className="buy-btn">
            Buy
          </Button>
        ) : null}
      </StyledCurioAndButton> 
      :
      <Typography className="mt-3" fontSize={16} color="#6F0056" align="left">
        <StyledSpan>
          SOLD OUT
        </StyledSpan>
      </Typography>
       
      }
    </StyledContent>
  );
};

ProductInfo.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  curioPrice: PropTypes.string,
  buyItem: PropTypes.func,
  business: PropTypes.object,
  featured: PropTypes.bool,
  isOnline: PropTypes.bool,
  showAddress: PropTypes.bool,
};

ProductInfo.defaultProps = {
  id: '',
  name: '',
  curioPrice: '',
  buyItem: null,
  business: {
    name: '',
  },
  featured: false,
  isOnline: false,
  showAddress: false,
};

export default React.memo(ProductInfo);

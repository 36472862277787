export const FOOL_TEXT = [
  'I’m tired.😥</br> Can’t you go instead?',
  'Huh? What’s cryptocoin? </br> I just want to nap… 😴',
  'Sorry, junior 🤦‍</br>My coin hunting days are over…',
  'Wouldn’t you rather just sit here with me instead?',
  'Why don’t I tell you a long, 🤔</br>drawn-out story instead?',
  'Eh, 😠</br>I’m not really feeling it today…',
  '😓 I didn’t sleep well last night. </br>Maybe tomorrow…',
  'How about you look for coin, </br>and I wait for you here?',
  'Coin shmoin.</br>Let’s play checkers instead.',
  'I don’t know about today…</br>😇 I’m feeling superstitious.',
  'Sorry, junior. 🤦‍</br>I’ve decided to become an accountant instead…',
  'Eh, I don’t really feel like moving… </br>You go? 🤔️',
];

export const POWER_LABEL = [
  {
    label: 'Water',
    powerName: 'water',
    name: 'powerWater',
    performsIn: 'Wetlands',
    performsLabel: '',
  },
  {
    label: 'Fire',
    powerName: 'fire',
    name: 'powerFire',
    performsIn: 'Desert',
    performsLabel: '',
  },
  {
    label: 'Earth',
    powerName: 'earth',
    name: 'powerEarth',
    performsIn: 'Forest',
    performsLabel: '',
  },
  {
    label: 'Air',
    powerName: 'air',
    name: 'powerAir',
    performsIn: 'Mountains',
    performsLabel: '',
  },
  {
    label: 'Luck',
    powerName: 'luck',
    name: 'skillLuck',
    performsIn: '',
    performsLabel: 'Find more loot on a Random journey',
  },
  {
    label: 'Prudence',
    powerName: 'prudence',
    name: 'skillPrudence',
    performsIn: '',
    performsLabel: 'Performs better searching for Tools',
  },
  {
    label: 'Intelligence',
    powerName: 'intelligence',
    name: 'skillIntelligence',
    performsIn: '',
    performsLabel: 'Performs better searching for edibles and non-edibles',
  },
  {
    label: 'Charm',
    powerName: 'charm',
    name: 'skillCharm',
    performsIn: '',
    performsLabel: 'Performs better searching for Curio',
  },
];

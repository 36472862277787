import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, FormError } from 'components';
import styled from 'styled-components';
import { isEmpty, get } from 'lodash';
import SellAndConsumeItem from '../../../containers/SellAndConsumeItem';
import Carousel from '../../commons/Carousel';
import RewardCurio from './RewardCurio';
import RewardResource from './RewardResource';
import HedgieXP from './HedgieXP';

const StyledWrapper = styled.div`
  .slider-control-bottomcenter {
    display: none;
  }
  .slider {
    &:focus {
      outline: none !important;
    }
  }
`;

const NO_DATA_MSG = `Oh, no! You're Hedgie didn't find anything. Try increasing the journey duration, and send it again!`;

const rewardTypes = {
  ITEM: 'item',
  CURIO: 'curio',
  RESOURCE: 'resource',
  XP: 'xp',
};

const RewardsCarousel = memo(
  ({
    rewardsLoading,
    rewardsError,
    rewards,
    archiveJourney,
    journeyId,
    getMyHedgies,
    handleAfterSellingOrConsumingSuccess,
    hedgieResourceTypes,
  }) => {
    useEffect(() => {
      if (journeyId) {
        archiveJourney(journeyId);
      }
      getMyHedgies({
        limit: 20,
        offset: 0,
        isLoadMore: false,
      });
    }, [archiveJourney, getMyHedgies, journeyId]);

    function renderRewardElement(reward) {
      const resourceAmount = get(reward, 'resourceAmount', 0);
      const resourceType = get(reward, 'resourceType', '');
      const xp = get(reward, 'xp', 0);

      switch (reward.type) {
        case rewardTypes.ITEM:
          return (
            <SellAndConsumeItem
              key={reward.id}
              isRewardPopup
              item={get(reward, 'userItem.item', null)}
              curio={get(reward, 'curio', 0)}
              userItemId={get(reward, 'userItem.id', '')}
              onActionSuccess={handleAfterSellingOrConsumingSuccess}
            />
          );
        case rewardTypes.CURIO:
          return <RewardCurio key={reward.id} curio={reward.curio} />;

        case rewardTypes.RESOURCE:
          return hedgieResourceTypes ? (
            <RewardResource
              key={reward.id}
              resourceTypeInfo={
                resourceType ? hedgieResourceTypes[resourceType] : null
              }
              resourceAmount={resourceAmount}
            />
          ) : null;
        case rewardTypes.XP:
          return <HedgieXP key={reward.id} xp={xp} />;

        default:
          return null;
      }
    }

    if (rewardsLoading) {
      return <Spinner noFixed />;
    }

    if (rewardsError.error) {
      return (
        <div className="align-center">
          <FormError error={rewardsError} />
        </div>
      );
    }

    return (
      <StyledWrapper>
        {isEmpty(rewards) && !rewardsError.error ? (
          <div className="align-center">{NO_DATA_MSG}</div>
        ) : (
          <Carousel hasPagination={false} numberOfItems={rewards.length}>
            {rewards.map(reward => renderRewardElement(reward))}
          </Carousel>
        )}
      </StyledWrapper>
    );
  },
);

RewardsCarousel.propTypes = {
  rewards: PropTypes.array,
  rewardsLoading: PropTypes.bool,
  rewardsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  archiveJourney: PropTypes.func,
  journeyId: PropTypes.string.isRequired,
  getMyHedgies: PropTypes.func,
  handleAfterSellingOrConsumingSuccess: PropTypes.func,
  hedgieResourceTypes: PropTypes.object,
};

RewardsCarousel.defaultProps = {
  rewards: [],
  rewardsLoading: false,
  rewardsError: {
    error: false,
    message: '',
  },
  archiveJourney: null,
  getMyHedgies: null,
  handleAfterSellingOrConsumingSuccess: null,
  hedgieResourceTypes: null,
};

export default RewardsCarousel;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import MyStoreCheckout from './myStoreCheckout';

const GiftCode = ({ id, email, onSubmitGiftRequest }) => {
  return (
    <div>
      <Row>
        <Col md="6">
          <MyStoreCheckout id={id} email={email} onSubmitGiftRequest={onSubmitGiftRequest} />
        </Col>
      </Row>
    </div>
  );
};

GiftCode.propTypes = {
  id: PropTypes.string,
  email: PropTypes.string,
  onSubmitGiftRequest: PropTypes.func,
};

GiftCode.defaultProps = {
  id: '',
  email: '',
  onSubmitGiftRequest: () => {},
};

export default GiftCode;

import { concat, get, isEmpty } from 'lodash';
import createReducer from '../createReducer';
import {
  GET_USER_ITEMS,
  GET_USER_ITEMS_FAILURE,
  GET_USER_ITEMS_SUCCESS,
  SELL_ITEM,
  SELL_ITEM_SUCCESS,
  SELL_ITEM_FAILURE,
  RESET_SELL_ITEM,
} from './actionTypes';
import { CONSUME_ITEM_SUCCESS } from '../effect/actionTypes';

const initialState = {
  list: {
    data: [],
    pagination: {
      limit: 8,
      offset: 0,
      total: 0,
    },
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  soldItem: {
    isItemSold: false,
    successMessage: '',
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
};

function calculateRemainingItems(data, userItemId, itemId) {
  return data.map(item => {
    if (item.id === itemId) {
      const userItemIds = get(item, 'user_item_ids', []);
      if (isEmpty(userItemIds)) {
        return item;
      }

      const amount = get(item, 'amount', 0);
      return {
        ...item,
        user_item_ids: userItemIds.filter(uItemId => uItemId !== userItemId),
        amount: amount - 1,
      };
    }
    return item;
  });
}

export default createReducer(initialState, {
  [GET_USER_ITEMS]: state => {
    state.list.loading = true;
    state.list.error = initialState.list.error;
  },
  [GET_USER_ITEMS_SUCCESS]: (state, action) => {
    state.list.loading = false;
    state.list.data = action.payload.canLoadMore
      ? concat(state.list.data, action.payload.data)
      : action.payload.data;
    state.list.pagination = action.payload.meta.pagination;
  },
  [GET_USER_ITEMS_FAILURE]: (state, action) => {
    state.list.loading = false;
    state.list.error.error = true;
    state.list.error.message = action.payload.message;
  },
  [SELL_ITEM]: state => {
    state.soldItem.loading = true;
    state.soldItem.error.error = false;
  },
  [SELL_ITEM_SUCCESS]: (state, action) => {
    state.soldItem.loading = false;
    state.soldItem.isItemSold = true;
    state.soldItem.successMessage = action.payload.data.message;
    state.list.data = calculateRemainingItems(
      state.list.data,
      action.payload.userItemId,
      action.payload.itemId,
    );
  },
  [CONSUME_ITEM_SUCCESS]: (state, action) => {
    state.list.data = calculateRemainingItems(
      state.list.data,
      action.payload.userItemId,
      action.payload.itemId,
    );
  },
  [SELL_ITEM_FAILURE]: (state, action) => {
    state.soldItem.loading = false;
    state.soldItem.error.error = true;
    state.soldItem.error.message = action.payload.message;
  },
  [RESET_SELL_ITEM]: state => {
    state.soldItem = initialState.soldItem;
  },
});

import querystring from 'querystring';
import { put, call, fork, take } from 'redux-saga/effects';
// import { sessionService } from 'redux-react-session';
import Log from 'services/log';
import * as actions from './actions';
import { trackPurchasing } from '../../tracking';

export function* getHedgie(api, { colorHexCode }) {
  try {
    Log.debug(colorHexCode, 'saga: getHedgie');
    const detail = yield call([api, api.get], `/hedgies/${colorHexCode}`);
    Log.debug(detail, 'saga: getHedgie:success');
    yield put(actions.getHedgieSuccess(detail));
  } catch (e) {
    Log.error(e, 'saga: getHedgie:error');
    yield put(actions.getHedgieFailure(e));
  }
}

export function* bookingHedgie({ api, firebase }, { colorHexCode }) {
  try {
    // const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

    // if (!idToken) {
    //   throw Error('saga: bookingHedgie: No access token in session');
    // }
    // yield call([api, api.setToken], idToken);

    Log.debug(colorHexCode, 'saga: bookingHedgie');
    const detail = yield call(
      [api, api.post],
      `/hedgies/reservations/${colorHexCode}`,
    );
    Log.debug(detail, 'saga: bookingHedgie:success');
    yield put(actions.bookingHedgieSuccess(detail));
  } catch (e) {
    Log.error(e, 'saga: bookingHedgie:error');
    yield put(actions.bookingHedgieFailure(e));
  }
}


export function* orderHedgie({ api, firebase }, { id }) {
  try {
    Log.debug(id, 'saga: orderHedgie');
    const detail = yield call(
      [api, api.get],
      `/hedgies/order/${id}`,
    );
    Log.debug(detail, 'saga: orderHedgie:success');
    yield put(actions.orderHedgieSuccess(detail));
  } catch (e) {
    Log.error(e, 'saga: orderHedgie:error');
    yield put(console.log(e));
  }
}





export function* getHedgies(
  api,
  { offset = 0, limit = 0, isPremium = false, tier = 0 },
) {
  const requestParams = {
    offset,
    limit,
    is_premium: isPremium,
    tier,
  };
  const qs = querystring.stringify(requestParams);
  try {
    const detail = yield call([api, api.get], `/hedgies?${qs}`);
    Log.debug(detail, 'saga: getHedgies:detail');
    yield put(actions.getHedgiesSuccess(detail));
  } catch (e) {
    Log.error(e, 'saga: getHedgies:error');
    yield put(actions.getHedgiesFailure(e));
  }
}

export function* giftHedgies(api, payload) {
  try {
    Log.debug(payload, 'saga: giftHedgies');
    const response = yield call(
      [api, api.post],
      '/giftcodes/purchases',
      payload,
    );
    Log.debug(response, 'saga: giftHedgies:response');
    yield put(actions.giftHedgieRequestSuccess(response));

    // Send giftcode purchase event to Google Analytics
    trackPurchasing({ ...response.data, ...payload, isGiftCode: true });
  } catch (e) {
    Log.error(e, 'saga: giftHedgies:error');
    yield put(actions.giftHedgieRequestFailure(e));
  }
}

export function* purchaseHedgie(api, payload) {
  try {
    Log.debug(payload, 'saga: purchaseHedgie');
    const response = yield call([api, api.post], '/hedgies/purchases', payload);
    Log.debug(response, 'saga: purchaseHedgie:response');
    yield put(actions.purchaseHedgieRequestSuccess(response));

    // Send giftcode purchase event to Google Analytics
    trackPurchasing({ ...response.data, ...payload });
  } catch (e) {
    Log.error(e, 'saga: purchaseHedgie:error');
    yield put(actions.purchaseHedgieRequestFailure(e));
  }
}

export function* purchaseWithEther({ api, firebase }, payload) {
  try {
    Log.debug(payload, 'saga: purchaseWithEther');
    //const response = yield call([api, api.post], '/hedgies/purchases', payload);
    const response = yield call([api, api.post], '/hedgies/mint', payload);
    Log.debug(response, 'saga: purchaseWithEther:response');
    yield put(actions.purchaseWithEtherRequestSuccess(response));
    yield call(getMyHedgies, {api, firebase}, {
      limit: 100,
      offset: 0,
      isLoadMore: false,
    });
    trackPurchasing({ ...response.data, ...payload });

    // Send ether purchase event to Google Analytics
  } catch (e) {
    Log.error(e, 'saga: purchaseWithEther:error');
    yield put(actions.purchaseWithEtherRequestFailure(e));
  }
}

export function* redeemGiftCode(api, payload) {
  try {
    Log.debug(payload, 'saga: redeemGiftCode');
    const response = yield call(
      [api, api.post],
      '/giftcodes/redemptions',
      payload,
    );
    Log.debug(response, 'saga: redeemGiftCode:response sucess');
    yield put(actions.redeemGiftCodeHedgieRequestSuccess(response));
    trackPurchasing({ ...response.data, ...payload, isRedeem: true });
  } catch (e) {
    Log.error(e, 'saga: redeemGiftCode:error failure');
    yield put(actions.redeemGiftCodeHedgieRequestFailure(e));
  }
}

export function* getMyHedgies({ api, firebase }, payload) {
  const { limit, offset } = payload;
  try {
    Log.debug(firebase, 'saga: getMyHedgies:begin');
    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

    if (!idToken) {
      throw Error('saga: getMyHedgies: No access token in session');
    }
    yield call([api, api.setToken], idToken);
    const detail = yield call(
      [api, api.get],
      `/users/me/hedgies?limit=${limit}&offset=${offset}`,
    );
    Log.debug(detail, 'saga: getMyHedgies:detail');
    yield put(
      actions.getMyHedgiesSuccess({
        ...detail,
        isLoadMore: payload.isLoadMore,
      }),
    );
  } catch (e) {
    Log.error(e, 'saga: getMyHedgies:error');
    yield put(actions.getMyHedgiesFailure(e));
  }
}

export function* syncMyHedgies({ api, firebase }, payload) {
  const { wallet, signature, type } = payload;

  try {
    Log.debug(payload, 'saga: syncMyHedgies');
    const idToken = yield firebase.firebase.auth.currentUser.getIdToken();

    if (!idToken) {
      throw Error('saga: syncMyHedgies: No access token in session');
    }
    // yield call([api, api.setToken], idToken);
    const response = yield call([api, api.post], '/hedgies/sync', {
      wallet,
      signature,
    });
    Log.debug(response, 'saga: syncMyHedgies:response');
    yield put(actions.syncMyHedgiesSuccess({ ...response, type }));
  } catch (e) {
    Log.error(e, 'saga: syncMyHedgies:error');
    yield put(actions.syncMyHedgiesFailure({ ...e, type }));
  }
}

export function* watchGetHedgieRequest(api) {
  while (true) {
    const { payload } = yield take(actions.GET_HEDGIE_REQUEST);
    yield call(getHedgie, api, payload);
  }
}

export function* watchBookingHedgieRequest(params) {
  while (true) {
    const { payload } = yield take(actions.BOOKING_HEDGIE_REQUEST);
    yield call(bookingHedgie, { ...params }, payload);
  }
}

export function* watchGetHedgiesRequest(api) {
  while (true) {
    const { payload } = yield take(actions.GET_HEDGIES_REQUEST);
    yield call(getHedgies, api, payload);
  }
}

export function* watchGiftHedgiesRequest(api) {
  while (true) {
    const { payload } = yield take(actions.GIFT_HEDGIE_REQUEST);
    yield call(giftHedgies, api, payload);
  }
}

export function* watchPurchaseHedgiesRequest(api) {
  while (true) {
    const { payload } = yield take(actions.PURCHASE_HEDGIE_REQUEST);
    yield call(purchaseHedgie, api, payload);
  }
}

export function* watchPurchaseWithEtherRequest(params) {
  while (true) {
    const { payload } = yield take(actions.PURCHASE_HEDGIE_WITH_ETHER_REQUEST);
    yield call(purchaseWithEther, {...params}, payload);
  }
}

export function* watchRedeemGiftCodeRequest(api) {
  while (true) {
    const { payload } = yield take(actions.REDEEM_GIFTCODE_HEDGIE_REQUEST);
    yield call(redeemGiftCode, api, payload);
  }
}

export function* watchGetMyHedgiesRequest(params) {
  while (true) {
    const { payload } = yield take(actions.GET_MY_HEDGIES_REQUEST);
    yield call(getMyHedgies, { ...params }, payload);
  }
}

export function* watchSyncMyHedgies(params) {
  while (true) {
    const { payload } = yield take(actions.SYNC_MY_HEDGIES_REQUEST);
    yield call(syncMyHedgies, { ...params }, payload);
  }
}

export default function*({ api, firebase }) {
  yield fork(watchGetHedgieRequest, api);
  yield fork(watchGetHedgiesRequest, api);
  yield fork(watchBookingHedgieRequest, { api, firebase });
  yield fork(watchGiftHedgiesRequest, api);
  yield fork(watchPurchaseHedgiesRequest, api);
  yield fork(watchPurchaseWithEtherRequest, { api, firebase });
  yield fork(watchRedeemGiftCodeRequest, api);
  yield fork(watchGetMyHedgiesRequest, { api, firebase });
  yield fork(watchSyncMyHedgies, { api, firebase });
}

import moment from 'moment';

export function parseTime(h, m, s) {
  const hours = h < 10 ? `0${h}` : h;
  const minutes = m < 10 ? `0${m}` : m;
  const seconds = s < 10 ? `0${s}` : s;

  return `${hours}:${minutes}:${seconds}`;
}

export function msToTime(duration) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  let seconds = duration - hours * 3600 - minutes * 60;
  seconds = Math.round(seconds * 100) / 100;

  //console.log("msToTime(duration)===>>>", duration, { h: hours, m: minutes, s: seconds })
  return { h: hours, m: minutes, s: seconds };
}

export function displayDurationLikeClock(duration) {
  if (duration) {
    const { h, m, s } = msToTime(duration);
    return parseTime(h, m, s);
  }
  return '';
}

export function showHumanTime(duration) {
  if (duration) {
    const { h, m, s } = msToTime(duration);
    const hours = h > 0 ? `${h} hours` : '';
    const minutes = m > 0 ? `${m} minutes` : '';
    const seconds = s > 0 ? `${s} seconds` : '';
    return `${hours} ${minutes} ${seconds}`;
  }
  return '';
}

export function updateDuration(createdAt, duration) {
  const createdAtMm = moment(createdAt);
  const current = moment(new Date()).utc();
  const difDuration = moment.duration(current.diff(createdAtMm));
  const remainingTime = duration - difDuration.as('seconds');

  if (remainingTime > 0) {
    return Math.floor(remainingTime);
  }

  return duration;
}

export function formatDateTime(time, formatType) {
  if (time) {
    return moment(time).format(formatType);
  }
  return '';
}

const START_OF_APR_FOOL_DAY = moment(new Date('Apr 01, 2019 12:01:00 am'));
const END_OF_APR_FOOL_DAY = moment(new Date('Apr 01, 2019 11:59:59 pm'));

export function checkAprilFoolsDay() {
  return moment().isBetween(START_OF_APR_FOOL_DAY, END_OF_APR_FOOL_DAY);
}

import React from 'react';
import { PresaleHeader } from 'components';
import { StyledOpenNewTab } from 'styles/main';
import image from 'images/blue-result.png';

const WrongBrowserPage = () => {
  return (
    <div>
      <PresaleHeader />
      <main>
        <div className="container-fluid row-section pos-rel pink-sky overflow-hidden-reg">
          <div className="container">
            <div className="jumbotron ">
              <h1 className="dark-color title mb-3">Hedgie demands the best...</h1>
              <div className="col-md-6 px-0">
                <p className="lead ff-extra-bold mb-0">
                  Please use your Chrome browser to play the game.
                  Hedgie loves it over there! If you don’t have Chrome, it’s easy to install:</p>
              </div>
              <StyledOpenNewTab target="_blank" href="https://www.google.com/chrome/index.html">
                <div className="cool-info pos-rel mt-4">
                  <div className=" single-price chrome">
                    <span className="price-inner fs-lg always-center ff-bold fs-length-1" />
                  </div>
                  <div className="price-label road-sign-reg">
                    <label htmlFor="Install Chrome" className="arrow-right ff-extra-bold ">
                      <span className="color-code fs-semi-lg color-contrast title-arrow">Install Chrome!
                      </span>
                    </label>
                  </div>
                </div>
              </StyledOpenNewTab>
            </div>
          </div>
          <div className="row justify-content-end overflow-hidden bgr-bottom-images pt-5 mt-5">
            <div className="col-md-4 text-right hedige-bubble pt-5 mt-5" />
            <div className="col-md-7 text-right hedige-on-bgr-holder" />
          </div>
          <div className="buble-on-bgr cool-bubble btn-big ff-semi-bold fs-extra-md color-light" >
            <span>How exciting!</span>
          </div>
          <img alt="" src={image} className="img-fluid cool-rotate" />
        </div>
      </main>
    </div>
  );
};

export default WrongBrowserPage;

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledOpenNewTab } from 'styles/main';
import { PageTitle } from 'components';
import ScrollToTop from 'config/redirect';
import OpenSeaImage from 'images/partners/open-sea.png';
import CryptoCollectionImage from 'images/partners/crypto-collectors.png';
import EmoonIoImage from 'images/partners/emoon-io.png';
import CryptoBitGamesImage from 'images/partners/crypto-bit-games.png';

const PARTNERS = [
  {
    id: 1,
    name: 'OpenSea',
    description:
      'OpenSea is a decentralized, peer-to-peer marketplace for digital assets on the Ethereum blockchain, and the official third-party exchange platform for all Hedgies.',
    image: OpenSeaImage,
    url: 'https://opensea.io/category/hedgie/',
  },
  {
    id: 2,
    name: 'CryptoCollectors',
    description:
      'The Crypto Collectors is a leading publication in the field of blockchain gaming and cryptocollectibles, featuring Hedgie in their Hall of Fame and online platforms.',
    image: CryptoCollectionImage,
    url: 'https://thecryptocollectors.com/',
  },
  {
    id: 3,
    name: 'Emoon.io',
    description:
      'Emoon Market is a decentralized marketplace for crypto assets, and a proud third-party exchange partner for Hedgie.',
    image: EmoonIoImage,
    url: 'https://www.emoon.io/',
  },
  {
    id: 4,
    name: 'CryptoBitGames',
    description:
      'Crypto Bit Games is Hedgie’s favourite blockchain gaming publication, delivering the latest news and events in the exciting world of crypto gaming.',
    image: CryptoBitGamesImage,
    url: 'https://cryptobitgames.com/',
  },
];

const PartnerImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
`;

const PartnerBlock = ({ partner }) => {
  return (
    <Row className="mb-5 mb-md-0">
      <Col md="6" className="d-flex justify-content-center ">
        <StyledOpenNewTab href={partner.url} target="_blank">
          <PartnerImg
            href={partner.url}
            target="_blank"
            src={partner.image}
            alt={partner.name}
          />
        </StyledOpenNewTab>
      </Col>
      <Col md="6">
        <StyledOpenNewTab
          href={partner.url}
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <h3 className="ff-semi-bold color-dark-contrast pb-3 text-center text-md-left mt-md-0 mt-3 linked-heading">
            {partner.name}
          </h3>
        </StyledOpenNewTab>
        <p className="text-center text-md-left mb-5 mb-md-0">
          {partner.description}
        </p>
      </Col>
    </Row>
  );
};

PartnerBlock.propTypes = {
  partner: PropTypes.object.isRequired,
};

const PartnerCell = ({ partner }) => {
  if (!partner) {
    return null;
  }
  return (
    <Col md="6">
      <PartnerBlock partner={partner} />
    </Col>
  );
};

const PartnerRow = ({ p1, p2 }) => {
  return (
    <Row className="mb-5">
      <PartnerCell partner={p1} />
      <PartnerCell partner={p2} />
    </Row>
  );
};

const OurPartnersPage = () => {
  const cols = 2;
  const rowCount = Math.ceil(PARTNERS.length / cols);
  const rows = Array.from(Array(rowCount).keys());
  return (
    <main>
      <ScrollToTop />
      <Container fluid>
        <Container className="mb-5">
          <Row>
            <Col>
              <PageTitle title="Our Partners" />
            </Col>
          </Row>
          {rows.map(val => {
            const index = val * cols;
            return (
              <PartnerRow
                key={val}
                p1={PARTNERS[index]}
                p2={PARTNERS[index + 1]}
              />
            );
          })}
        </Container>
      </Container>
    </main>
  );
};
OurPartnersPage.propTypes = {};
OurPartnersPage.defaultProps = {};
export default OurPartnersPage;

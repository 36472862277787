import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, CurioPrice, ProductImage, Button } from 'components';
import { formatDateTime } from 'helper/handleTime';
import { media } from 'styles/main';
import { get } from 'lodash';
import { TRANSACTION_DATE_FORMAT } from 'const/dateTime';

const StyledContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  &:nth-child(odd) {
    background: rgba(202, 214, 217, 0.3);
  }
  ${media.tablet`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const StyledProduct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .business-name {
    padding-left: 20px;
  }
  ${media.phone`
   .business-name{
      padding-left: 0;
    } 
  `}
`;

const StyledOrderCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.phone`
    .code{
      font-size: 14px;
    }
    align-items: flex-start;
  `}
`;

const StyledCodeAndButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet`
    width: 100%;
    margin-top: 20px;
    padding-bottom: 10px;
  `}
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .curio {
    padding-right: 10px;
  }
  ${media.phone`
    .curio{
      padding-bottom: 5px;
     }
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const Order = ({ order, isBusinessOwner, archiveOrder, isPendingOrder }) => {
  const { product, business } = order;
  const businessOwnerEmail = get(order, 'user.email', '');

  const archivedOrderCallback = useCallback(
    () => {
      archiveOrder(order.id);
    },
    [archiveOrder, order],
  );

  return (
    <StyledContainer isPendingOrder={isPendingOrder}>
      <StyledProduct>
        {isBusinessOwner ? null : (
          <ProductImage imgURL={business.imageURL} width={84} height={84} />
        )}
        <div className="business-name">
          <Typography
            text={isBusinessOwner ? businessOwnerEmail : business.name}
            align="left"
            isBold
            fontSize={16}
          />
          <StyledRow className="pt-2">
            <CurioPrice
              curioPrice={product.curioPrice}
              imgWidth={27}
              className="curio"
            />
            <Typography text={product.name} fontSize={14} color="#62295E" />
            {order.meta && order.meta.unstoppabledomain ? <Typography text={'\u00a0(' + order.meta.unstoppabledomain.domain + ')'} fontSize={14} color="#62295E" /> : null}

          </StyledRow>
          {order.productCode ? (
            <Typography text={order.productCode} color="#35BD51" className="code" />
          ) : null}
        </div>
      </StyledProduct>
      <StyledCodeAndButton>
        <StyledOrderCode>
          <Typography text={order.code} color="#35BD51" className="code" />
          <Typography
            fontSize={14}
            className="pt-2"
            text={formatDateTime(order.created_at, TRANSACTION_DATE_FORMAT)}
          />
        </StyledOrderCode>
        {isPendingOrder ? (
          <div className="button-archive">
            <Button size="small" onClick={archivedOrderCallback}>
              Archive
            </Button>
          </div>
        ) : null}
      </StyledCodeAndButton>
    </StyledContainer >
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
  isBusinessOwner: PropTypes.bool,
  isPendingOrder: PropTypes.bool,
  archiveOrder: PropTypes.func,
};

Order.defaultProps = {
  isBusinessOwner: false,
  archiveOrder: null,
  isPendingOrder: false,
};

export default memo(Order);

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import * as paths from 'config/paths';
import styled from 'styled-components';
import BackButton from '../../commons/BackButton';
import Detail from './Detail';
import ProductsList from './ProductsList';

export const BusinessContext = React.createContext({});

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

const BusinessDetailPage = ({
  match,
  products,
  businessDetail,
  getBusinessProducts,
  getBusinessDetail,
  user,
}) => {
  const businessId = get(match, 'params.businessId', '');

  const { curioBalance } = user;
  return (
    <BusinessContext.Provider
      value={{
        match,
        curioBalance,
      }}
    >
      <StyledContainer>
        <BackButton link={paths.ALL_BUSINESSES} />
        <Detail
          businessDetail={businessDetail}
          businessId={businessId}
          getBusinessDetail={getBusinessDetail}
        />
        <ProductsList
          products={products}
          businessId={businessId}
          getBusinessProducts={getBusinessProducts}
        />
      </StyledContainer>
    </BusinessContext.Provider>
  );
};

BusinessDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  products: PropTypes.object.isRequired,
  businessDetail: PropTypes.object.isRequired,

  getBusinessProducts: PropTypes.func.isRequired,
  getBusinessDetail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default BusinessDetailPage;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, /* PriceDisplay */ } from 'components';

const PurchaseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-left: 30px;
`;
/* 
const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-right: 10px;
`; */

const HedgiePurchaseWithPriceButton = ({
  className,
  price,
  hedgie,
  onPurchaseHedgie,
  color,
}) => {
  const onPurchaseHedgieCallback = useCallback(() => {
    onPurchaseHedgie(color);
  }, [color, onPurchaseHedgie]);


  var eth = 0;
  var priceD = 0;
  if (hedgie) {
    eth = hedgie.etherPrice / 1e18;
    priceD = (hedgie.dollarPrice + hedgie.gasPrice) / 100;
  }

  return (
    <>
      <span>{eth}ETH+gas (${priceD})</span>
      <PurchaseContainer className={className}>
        {/* <PriceContainer>
        <PriceDisplay price={price} />
      </PriceContainer> */}
        <Button size="large" type="submit" onClick={onPurchaseHedgieCallback}>
          Mint this Hedgie
        </Button>
      </PurchaseContainer>
    </>
  );
};

HedgiePurchaseWithPriceButton.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number.isRequired,
  onPurchaseHedgie: PropTypes.func.isRequired,
  color: PropTypes.string,
};

HedgiePurchaseWithPriceButton.defaultProps = {
  color: '',
  className: '',
};

export default HedgiePurchaseWithPriceButton;

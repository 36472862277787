import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import { HedgieOwnedStamp, SubHeading } from 'components';
import { MyHedgiesContext } from '../../pages/NewMyHedgiesPage/MyHedgiesWrapper';

const GroupNameAndStamp = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .my-hd-name {
    flex-grow: 49;
    width: 100%;
  }
  .my-hd-minting-status {
    flex-grow: 49;
    width: 100%;
  }
  .my-hd-divider {
    border-right: 1px solid #cad6d9;
    padding-left: 10px;
    flex-grow: 2;
  }
  ${media.tablet`
    flex-direction: column-reverse;
    .my-hd-name {
        margin-top: 30px;
        text-align: center ;
      }
    .my-hd-minting-status {
      display: flex;
      justify-content: center;
    }
  `};
  padding-top: 30px;
`;

const StyledFreeHedgie = styled.div`
  flex-grow: 48;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyHedgieInfo = ({ mintingStatus, name, status, color, isPurchased }) => {
  const {isMinting, fetchHedgieTimer} = useContext(MyHedgiesContext);

  useEffect(() => {
    if(mintingStatus === 'minting' && !!fetchHedgieTimer){
      fetchHedgieTimer(color);
    }
  },[mintingStatus, fetchHedgieTimer, color]);

  return (
    <GroupNameAndStamp>
      <div className="my-hd-name">
        {name ? (
          <SubHeading
            className="pt-1 sub-heading-center ff-bold"
            heading={name}
          />
        ) : null}
        <SubHeading heading={`#${color}`} />
      </div>
      <div className="my-hd-divider" />
      {isPurchased ? (
        <div className="my-hd-minting-status">
          {mintingStatus === 'ready' && (
            <SubHeading
              className="sub-heading-center text-center ff-bold"
              heading={isMinting?'Minting in progress':'Ready for Minting'}
            />
          )}
          {mintingStatus === 'minting' && (
            <SubHeading
              className="sub-heading-center text-center ff-bold"
              heading="Your hedgie is being minted"
            />
          )}
          {mintingStatus === 'minted' && <HedgieOwnedStamp />}
        </div>
      ) : (
        <StyledFreeHedgie>
          {status === 3 ? (
            <SubHeading
              className="sub-heading-center text-center ff-bold"
              heading="Free"
            />
          ) : null}
        </StyledFreeHedgie>
      )}
    </GroupNameAndStamp>
  );
};

MyHedgieInfo.propTypes = {
  isPurchased: PropTypes.bool,
  mintingStatus: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.number,
  color: PropTypes.string,
};

MyHedgieInfo.defaultProps = {
  isPurchased: false,
  mintingStatus: '',
  name: '',
  status: -1,
  color: '',
};

export default React.memo(MyHedgieInfo);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { MetaMask } from 'components';
import { StyledTextBtn } from 'styles/main';
import { fromHedgies } from 'store/selectors';
import { syncMyHedgiesRequest } from 'store/actions';
import MetaMaskUtil from 'services/metamaskutil';


const HedgieMetaMaskNew = ({ metaMaskEnabled }) => {
  const dispatch = useDispatch();


  const syncHedgiesLoading = useSelector(state =>
    fromHedgies.syncHedgiesLoading(state),
  );

  const walletTyle = useSelector(state => fromHedgies.getWalletTyle(state));


  const onSyncHedgies = useCallback(
    params => {
      dispatch(syncMyHedgiesRequest(params));
    },
    [dispatch],
  );

  const handleSyncHedgies = useCallback(
    e => {
      e.preventDefault();
      // setQueryingMetaMask(true);

      MetaMaskUtil.getAddressAndSignature()
        .then(({ address, signature }) => {
          onSyncHedgies({ wallet: address, signature, type: 'metaMask' });
        })
        .catch(err => {
          // setQueryingMetaMask(false);
        });
    },
    [onSyncHedgies],
  );

  
  return (
    <div>
      {metaMaskEnabled ? (
        <Row>
          <Col>
            <div className="myhedgies-metamask text-center mt-1">
              <MetaMask>
                <StyledTextBtn
                  onClick={handleSyncHedgies}
                  disabled={syncHedgiesLoading && walletTyle === 'metaMask'}
                >
                  {syncHedgiesLoading && walletTyle === 'metaMask'
                    ? 'Syncing...'
                    : 'Connect wallet'}
                </StyledTextBtn>
              </MetaMask>
            </div>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

HedgieMetaMaskNew.propTypes = {
  metaMaskEnabled: PropTypes.bool,
};

HedgieMetaMaskNew.defaultProps = {
  metaMaskEnabled: false,
};

export default HedgieMetaMaskNew;

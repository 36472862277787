import styled from 'styled-components';
import { media } from 'styles/main';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledBgContainer = styled.div`
  background-image: url(${props => props.src});
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  min-height: 100%;
  transition: filter ease 1s;
  filter: ${props => (!props.loaded ? 'blur(5px)' : 'unset')};
  padding-bottom: 50px;
`;

export const StyledHouseBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 980px;
  height: auto;
  margin: 0 auto;
  position: relative;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  &:after {
    content: '';
    display: block;
    padding-bottom: calc(100%);
  }
`;

export const StyledResources = styled.div`
  padding-top: 33px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const StyledResourcesBar = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 104px);
  grid-gap: 20px;
  ${media.phone`
    grid-template-columns: repeat(5, 50px);
    grid-gap: 10px;  
  `}
  .house-rs-item {
    ${media.phone`
      padding: 5px;
  `}
  }
`;

export const StyledTopTitle = styled.div`
  width: 100%;
  text-align: center;
  .hg-house-header-text {
    margin: 20px 0 20px 0;
    span {
      display: block;
      text-align: center;
      color: #fff;
    }
  }
`;

export const StyledDirtyHouseContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledHedgieIcon = styled.div`
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 10%;
  bottom: 3%;
  width: calc(100% * 80 / 980);
  height: calc(100% * 80 / 980);
  z-index: 15;
  ${media.tablet`
    bottom: -5px;
    width: 40px;
    height: 40px;
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-image: linear-gradient(-180deg, #ca8314 0%, #f7e890 100%);
  }
  .wrapper-icon {
    background: #fff;
    border-radius: 50%;
    width: 94%;
    height: 94%;
    ${media.tablet`
      width: 40px;
      height: 40px;
  `};
    overflow: hidden;
    .hedgie-image {
      margin: 6px auto 0 3px;
    }
  }
`;

import React from 'react';
import { Container, Row, Col, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import {
  PageTitle,
  Button,
  ScarceAndCool,
  ExclusiveContent,
  HedgieTierView,
  SubHeading,
  Divider,
  Desktop,
} from 'components';
import * as types from 'types';
import Log from 'services/log';

const PREMIUM_PAGE_LIMIT = 14;
const PREMIUM_INITIAL_TIER = 6;

class PremiumHedgiesPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      premium: {
        offset: 0,
        limit: PREMIUM_PAGE_LIMIT,
        tier: PREMIUM_INITIAL_TIER,
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getHedgies(this.state.premium);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleOnChangeTier = tierId => {
    const newPremium = {
      offset: 0,
      limit: PREMIUM_PAGE_LIMIT,
      tier: tierId, // the tier id
    };
    this.setState({ premium: newPremium });
    this.props.getHedgiesReset();
    this.props.getHedgies(newPremium);
  };

  handleOnLoadMorePremium = () => {
    const newPremium = this.state.premium;
    newPremium.offset += PREMIUM_PAGE_LIMIT;
    this.setState({ premium: newPremium });
    this.props.getHedgies(newPremium);
  };

  render() {
    const {
      hedgies,
      tiers,
      className,
      loadingPremium,
      paginationData,
    } = this.props;
    Log.debug({ hedgies, paginationData }, 'PremiumHedgiesPage:render');

    return (
      <div className={classnames([className, 'tabbed-container'])}>
        <main>
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Exclusive Hedgies" />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <SubHeading
                    className="text-center"
                    heading="Exclusive Hedgies are especially rare. There are six tiers available, each with different kinds of cool hex codes. Exclusive Hedgies vary in price based on their rarity."
                  />
                </Col>
              </Row>
              <Desktop>
                <Divider className="mt-4" />
              </Desktop>
              <Row className="pb-5">
                <Col className="text-center">
                  <Button
                    size="large"
                    onClick={() => goToAnchor('choose-premium')}
                  >
                    Pick yours now
                  </Button>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="premimum-tabs-1">
            <Container className="premimum-tabs-1 pt-5">
              {/* <div tabs className="d-flex justify-content-center nav nav-tabs"> */}
              <Row>
                <Col className="col-md-6 text-center exclusive-tab">
                  <h4>
                    <NavLink
                      className={classnames([
                        { active: this.state.activeTab === '1' },
                        'ff-bold',
                      ])}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Exclusively Awesome
                    </NavLink>
                  </h4>
                </Col>
                <Col className="col-md-6 text-center exclusive-tab">
                  <h4>
                    <NavLink
                      className={classnames([
                        { active: this.state.activeTab === '2' },
                        'ff-bold',
                      ])}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Exclusive Content
                    </NavLink>
                  </h4>
                </Col>
              </Row>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" className="pt-5">
                      <ScarceAndCool loading={loadingPremium} tiers={tiers} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12" className="pt-5">
                      <ExclusiveContent />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
          </Container>
          <ScrollableAnchor id="choose-premium">
            <div />
          </ScrollableAnchor>
          <HedgieTierView
            tiers={tiers}
            tier={this.state.premium.tier}
            loading={loadingPremium}
            premiumHedgies={hedgies}
            onChangeTier={this.handleOnChangeTier}
            onLoadMorePremium={this.handleOnLoadMorePremium}
            paginationData={paginationData}
          />
        </main>
      </div>
    );
  }
}

PremiumHedgiesPage.propTypes = {
  className: PropTypes.string,
  loadingPremium: PropTypes.bool,
  errorGettingHedgies: types.error,
  getHedgies: PropTypes.func.isRequired,
  getHedgiesReset: PropTypes.func.isRequired,
  hedgies: PropTypes.arrayOf(types.hedgie),
  paginationData: types.paginationData,
  tiers: PropTypes.arrayOf(types.tier),
};

PremiumHedgiesPage.defaultProps = {
  className: null,
  hedgies: [],
  tiers: [],
  paginationData: {},
  errorGettingHedgies: null,
  loadingPremium: false,
};

export default PremiumHedgiesPage;

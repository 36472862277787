import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as paths from 'config/paths';
import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { Button } from 'components';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { media } from 'styles/main';
import Info from '../Info';
import { StyledInfo } from '../TheGame/TopSlide';

export const StyledMobileContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const StyledListBussiness = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 120px);
  ${media.tablet`
    grid-template-columns: repeat(3, 80px);
  `};
  grid-gap: 10px;
  align-items: flex-start;
`;

export const StyledButton = styled.div`
  padding-top: 30px;
  margin-left: -16px;
`;

export const StyledButtonMb = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  margin-left: -16px;
`;

const TierBlockContainer = styled.img`
  color: #ffffff;
  border-radius: 30%;
  padding: 10px 10px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const EarnMoney = ({ isMobile, getBusinesses, businesses }) => {
  useEffect(() => {
    getBusinesses({
      offset: 0,
      limit: 3,
    });
  }, [getBusinesses]);

  const renderBusinesses = isEmpty(businesses.list) ? null : (
    <StyledListBussiness>
      {businesses.list.map(business => (
        <TierBlockContainer
          key={business.id}
          width={isMobile ? 80 : 120}
          height={isMobile ? 80 : 120}
          src={business.imageURL}
          className="tier-block d-flex text-center justify-content-center flex-column m-auto"
        />
      ))}
    </StyledListBussiness>
  );

  const renderInfo = (
    <Info
      title="You Earn it!"
      subTitle="Curio in real life"
      text="Spend your hard earned Curio at our partner businesses!"
      textColor="#000"
    >
      {renderBusinesses}
    </Info>
  );

  const renderButton = (
    <Button tag={Link} to={paths.ALL_BUSINESSES} className="earn-money-btn">
      View all businesses
    </Button>
  );

  if (isMobile) {
    return (
      <React.Fragment>
        <StyledMobileContainer>{renderInfo}</StyledMobileContainer>
        <StyledButtonMb>{renderButton}</StyledButtonMb>
      </React.Fragment>
    );
  }

  return (
    <StyledInfo>
      {renderInfo}
      <StyledButton>{renderButton}</StyledButton>
    </StyledInfo>
  );
};

EarnMoney.propTypes = {
  isMobile: PropTypes.bool,
  getBusinesses: PropTypes.func,
  businesses: PropTypes.object.isRequired,
};

EarnMoney.defaultProps = {
  isMobile: false,
  getBusinesses: null,
};

const mapStateToProps = state => ({
  businesses: fromResource.getResourceState(state, 'businesses'),
});

const mapDispatchToProps = dispatch => ({
  getBusinesses: params =>
    dispatch(resourceListReadRequest('businesses', params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EarnMoney);

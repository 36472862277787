import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, DurationTimer } from 'components';
import { updateDuration } from 'helper/handleTime';

const StyledContainer = styled.div`
  border-bottom: ${props => (!props.isLastOne ? '1px solid #e9ecef' : null)};
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function showIncreaseValue(effectValue, effectUnit) {
  if (effectUnit === 'percentage') {
    return `${effectValue}%`;
  }
  return effectValue;
}

const HedgieEffectInfo = memo(
  ({
    effectType,
    effectValue,
    effectDuration,
    effectUnit,
    createdAt,
    isLastOne,
    ...props
  }) => {
    const displayIncreaseValue = useMemo(
      () => showIncreaseValue(effectValue, effectUnit),
      [effectValue, effectUnit],
    );

    if (displayIncreaseValue === 0) {
      return null;
    }

    let effectInfo;
    switch (effectType) {
      case 'energy_recover':
        effectInfo = `+${effectValue}% energy recovery for: `;
        break;
      case 'extra_draw_consumable':
        effectInfo = `${effectValue}% chance of finding extra consumable for: `;
        break;
      case 'extra_draw_curio':
        effectInfo = `${effectValue}% chance of finding extra curio for: `;
        break;
      default:
        effectInfo = 'Unknown effect';
    }

    return (
      <StyledContainer {...props} isLastOne={isLastOne}>
        <div>
          <StyledTitle>
            <Typography
              align="left"
              text={effectInfo}
              className="pl-1"
              fontSize={16}
            >
              <DurationTimer
                duration={updateDuration(createdAt, effectDuration)}
              />
            </Typography>
          </StyledTitle>
        </div>
      </StyledContainer>
    );
  },
);

HedgieEffectInfo.propTypes = {
  effectDuration: PropTypes.number,
  effectType: PropTypes.string,
  effectValue: PropTypes.number,
  effectUnit: PropTypes.string,
  createdAt: PropTypes.string,
  isLastOne: PropTypes.bool,
};
HedgieEffectInfo.defaultProps = {
  effectDuration: 60,
  effectType: 'string',
  effectValue: 0,
  effectUnit: 'percentage',
  createdAt: '2019-02-16T02:16:07Z',
  isLastOne: false,
};
export default HedgieEffectInfo;

import React, { useState, useEffect } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import RequestOtpButton from './request_otp_button';

const EmailVerificationModal = ({
    error,
    requestOtpCode,
    requestOtpSuccess,
    verifyEmailAddress,
    verifyingEmailSucesss,
    verifyingEmailError,
}) => {

    const [otp, setOtp] = useState(false);
    const [alert, setAlert] = useState(null);
    useEffect(() => {
        if (verifyingEmailError) {
            setAlert(verifyingEmailError.message)
        }
    }, [verifyingEmailError, requestOtpSuccess]);


    const handleChangeInput = otp => {
        setOtp(otp)
        if (otp.length === 6) {
            verifyEmailAddress(otp)
        } else {
            setAlert(null)
        }
    };

    const handleRequestOtpClick = () => {
        setAlert(null)
        setOtp(null)
        requestOtpCode()
    };

    return (
        (error === null || error.code !== 'email_verification_required_error') ? null : (
            <div>
                <Modal isOpen={true}>
                    <ModalHeader >Email verification</ModalHeader>
                    <ModalBody>
                        {verifyingEmailSucesss ? (
                            <div>
                                <h4>Your email has been verified successfully. </h4>
                                <p>( You might need re-login to Hedgie)</p>
                            </div>) : (
                                <div>
                                    <Alert color="danger" hidden={!alert}>{alert}</Alert>
                                    <OtpInput
                                        value={otp}
                                        isInputNum={true}
                                        onChange={handleChangeInput}
                                        numInputs={6}
                                        inputStyle='otpInputStyle'
                                        separator={<span>-</span>}
                                    />
                                </div>
                            )}
                    </ModalBody>
                    <ModalFooter>
                        <RequestOtpButton verifyingEmailSucesss={verifyingEmailSucesss} requestOtp={handleRequestOtpClick}></RequestOtpButton>
                    </ModalFooter>
                </Modal>
            </div >
        )
    );
};

EmailVerificationModal.propTypes = {
    verifyingEmailSucesss: PropTypes.bool,
    requestOtpSuccess: PropTypes.bool,
    requestOtpCode: PropTypes.func.isRequired,
    verifyEmailAddress: PropTypes.func.isRequired,
    verifyingEmailError: PropTypes.object,
};

EmailVerificationModal.defaultProps = {
    requestOtpSuccess: false,
    verifyingEmailSucesss: false,
    requestOtpCodeSuccess: null,
    verifyingEmailError: null,
};


export default EmailVerificationModal;

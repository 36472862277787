import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledCoverBase = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 295px;
  width: 100%;
  ${media.phone`
    height: 160px;
  `};
`;

const StyledCover = styled(StyledCoverBase)`
  background-image: url(${props => props.src});
`;

const StyledPlaceholder = styled(StyledCoverBase)`
  background-color: #eef3f4;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-size: 18px;
  }
`;

const BusinessCover = ({ coverImgUrl }) => {
  if (!coverImgUrl) {
    return (
      <StyledPlaceholder>
        <span>Loading cover...</span>
      </StyledPlaceholder>
    );
  }
  return <StyledCover src={coverImgUrl} />;
};

BusinessCover.propTypes = {
  coverImgUrl: PropTypes.string,
};

BusinessCover.defaultProps = {
  coverImgUrl: '',
};

export default BusinessCover;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { domain } from 'config/env';
import { Typography } from 'components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ReferralCodeModal = ({ referralCode, onClose, ...props }) => {
  const closeCallback = useCallback(
    () => {
      onClose();
    },
    [onClose],
  );

  return (
    <Modal isOpen {...props}>
      <ModalHeader toggle={closeCallback}>Welcome!</ModalHeader>
      <ModalBody>
        <Typography
          fontSize={16}
          align="center"
          text="Curiopia is waiting! Hedgie is more fun with friends. Get bonus Curio for you and your friends when they sign up with the referral code below"
        />
        <Typography
          fontSize={16}
          className="mt-3"
          align="center"
          text="Referral link:"
        />
        <Typography
          fontSize={16}
          align="center"
          className="mt-2"
          text={`${domain}/signup?referral_code=${referralCode}`}
        />
        <Typography
          fontSize={16}
          className="mt-3"
          align="center"
          text="You can always find this link in your settings."
        />
      </ModalBody>
    </Modal>
  );
};

ReferralCodeModal.propTypes = {
  onClose: PropTypes.func,
  referralCode: PropTypes.string,
};

ReferralCodeModal.defaultProps = {
  referralCode: '',
  onClose: null,
};

export default ReferralCodeModal;

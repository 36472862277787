import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, HeadLine } from 'components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .rs-content {
    min-height: 150px;
  }
`;

const RewardResource = ({ resourceTypeInfo, resourceAmount }) => {
  const { name, description, imgURL } = resourceTypeInfo;
  if (!resourceTypeInfo) {
    return <div>There is no resource type info</div>;
  }
  return (
    <StyledContainer>
      <div className="rs-content">
        <HeadLine title="Resource" fontSize={18} />
        <img width={100} src={imgURL} alt={name} />
        <Typography text={name} key="name" />
        <Typography text={description} key="description" />
        <Typography
          text={`Amount: ${resourceAmount}`}
          isBold
          color="#62295E"
          fontSize={16}
          key="amount"
        />
      </div>
    </StyledContainer>
  );
};

RewardResource.propTypes = {
  resourceTypeInfo: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    imgURL: PropTypes.string,
    scarcity: PropTypes.string,
  }),
  resourceAmount: PropTypes.number,
};

RewardResource.defaultProps = {
  resourceTypeInfo: {
    type: '',
    name: '',
    description: '',
    imgURL: '',
    scarcity: '',
  },
  resourceAmount: 0,
};

export default RewardResource;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import ItemWithBadge from './ItemWithBadge';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  padding-bottom: 40px;
  ${media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 20px;
    padding: 0 20px;
    justify-content: center;
  `}
`;

const GridItemsImage = memo(({ items, onClick }) => {
  return (
    <StyledGrid>
      {items.map(item => (
        <ItemWithBadge key={item.id} onClick={onClick} item={item} />
      ))}
    </StyledGrid>
  );
});

GridItemsImage.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
};

GridItemsImage.defaultProps = {
  items: [],
  onClick: null,
};

export default GridItemsImage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomTab, JourneyWorldItems } from 'components';

export const ItemsTab = {
  ITEMS: 'items',
  COLLECTIONS: 'collections',
};

class JourneyWorldItemTab extends Component {
  state = {
    selectedTab: ItemsTab.ITEMS,
  };

  componentDidMount() {
    if (this.props.worldId) {
      this.fetchItemsOfTheWorld(this.props.worldId, this.state.selectedTab);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.worldId !== this.props.worldId) {
      this.fetchItemsOfTheWorld(nextProps.worldId, this.state.selectedTab);
    }
  }

  handleSelectTab = selectedTab => {
    this.setState({ selectedTab }, () => {
      this.fetchItemsOfTheWorld(this.props.worldId, selectedTab);
    });
  };

  fetchItemsOfTheWorld = (worldId, selectedTab) => {
    this.props.getItems({
      worldId,
      typeOfItem: selectedTab,
    });
  };

  render() {
    const { selectedTab } = this.state;
    const { worldItems } = this.props;
    const { collections, items, itemsLoading } = worldItems;
    const tabContent = [
      {
        id: ItemsTab.ITEMS,
        tabName: 'Consumables',
        content: (
          <div>
            <JourneyWorldItems
              items={items}
              key="items"
              loading={itemsLoading}
            />
          </div>
        ),
      },
      {
        id: ItemsTab.COLLECTIONS,
        tabName: 'Collections',
        content: (
          <div>
            <JourneyWorldItems
              items={collections}
              key="collections"
              loading={itemsLoading}
            />
          </div>
        ),
      },
    ];
    return (
      <div>
        <CustomTab
          tabContent={tabContent}
          switchTab={this.handleSelectTab}
          activeTab={selectedTab}
        />
      </div>
    );
  }
}

JourneyWorldItemTab.propTypes = {
  worldId: PropTypes.string.isRequired,
  worldItems: PropTypes.shape({
    consumables: PropTypes.array,
    collections: PropTypes.array,
    itemsLoading: PropTypes.bool,
  }),
  getItems: PropTypes.func,
};

JourneyWorldItemTab.defaultProps = {
  worldItems: {
    consumables: [],
    collections: [],
    itemsLoading: false,
  },
  getItems: null,
};

export default JourneyWorldItemTab;

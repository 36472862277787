import { concat } from 'lodash';
import createReducer from '../createReducer';
import {
  ARCHIVE_NOTIFICATION_FAILURE,
  ARCHIVE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_UNSEEN_NUMBER_FAILURE,
  GET_NOTIFICATION_UNSEEN_NUMBER_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_A_NOTIFICATION_AS_READ_FAILURE,
  MARK_A_NOTIFICATION_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_SEEN_FAILURE,
  MARK_ALL_AS_SEEN_SUCCESS,
  ENABLE_BROWSER_NOTIFICATION,
  UPDATE_NEW_NOTIFICATION,
  GET_NOTIFICATION_REFERENCE_SUCCESS,
  UPDATE_NOTIFICATION_REFERENCE_SUCCESS,
} from './actions';

export const initialState = {
  notificationList: {
    data: [],
    isLoadMore: false,
    pagination: {
      limit: 10,
      offset: 0,
      total: 0,
    },
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  unseenNotifications: 0,
  unseenNotificationsError: {
    error: false,
    message: '',
  },
  markAllAsReadError: {
    error: false,
    message: '',
  },
  markANotificationAsReadError: {
    error: false,
    message: '',
  },
  archivedNotificationId: '',
  archiveNotificationError: {
    error: false,
    message: '',
  },
  markAllSeenError: {
    error: false,
    message: '',
  },
  isEnabledSetting: false,
  isBlockingBrowser: false,
};

export default createReducer(initialState, {
  [GET_NOTIFICATIONS]: state => {
    state.notificationList.loading = true;
    state.notificationList.error.error = false;
  },
  [GET_NOTIFICATIONS_SUCCESS]: (state, action) => {
    state.notificationList.loading = false;
    state.notificationList.data = action.payload.isLoadMore
      ? concat(state.notificationList.data, action.payload.data)
      : action.payload.data;
    state.notificationList.pagination = action.payload.meta.pagination;
  },

  [GET_NOTIFICATIONS_FAILURE]: (state, action) => {
    state.notificationList.loading = false;
    state.notificationList.error.error = true;
    state.notificationList.error.message = action.payload.message;
  },
  [GET_NOTIFICATION_UNSEEN_NUMBER_SUCCESS]: (state, action) => {
    state.unseenNotifications = action.payload.data.count;
  },
  [GET_NOTIFICATION_UNSEEN_NUMBER_FAILURE]: (state, action) => {
    state.unseenNotificationsError.error = true;
    state.unseenNotificationsError.message = action.payload.message;
  },
  [MARK_ALL_AS_READ_SUCCESS]: state => {
    state.notificationList.data = state.notificationList.data.map(
      notification => ({ ...notification, status: 'read' }),
    );
  },
  [MARK_ALL_AS_READ_FAILURE]: (state, action) => {
    state.markAllAsReadError.error = true;
    state.markAllAsReadError.message = action.payload.message;
  },
  [MARK_A_NOTIFICATION_AS_READ_SUCCESS]: (state, action) => {
    state.notificationAsReadId = action.payload.data.id;
    state.unseenNotifications =
      state.unseenNotifications > 1 ? state.unseenNotifications - 1 : 0;
    state.notificationList.data = state.notificationList.data.map(
      notification =>
        notification.id === action.payload.data.id
          ? { ...notification, status: 'read' }
          : notification,
    );
  },
  [MARK_A_NOTIFICATION_AS_READ_FAILURE]: (state, action) => {
    state.markANotificationAsReadError.error = true;
    state.markANotificationAsReadError.message = action.payload.message;
  },
  [ARCHIVE_NOTIFICATION_SUCCESS]: (state, action) => {
    state.archivedNotificationId = action.payload.archivedNotificationId;
    state.notificationList.data = state.notificationList.data.filter(
      notification => notification.id !== action.payload.archivedNotificationId,
    );
  },
  [ARCHIVE_NOTIFICATION_FAILURE]: (state, action) => {
    state.archiveNotificationError.error = true;
    state.archiveNotificationError.message = action.payload.message;
  },
  [MARK_ALL_AS_SEEN_SUCCESS]: state => {
    state.unseenNotifications = 0;
  },
  [MARK_ALL_AS_SEEN_FAILURE]: (state, action) => {
    state.markAllSeenError.error = true;
    state.markAllSeenError.message = action.payload.message;
  },
  [UPDATE_NEW_NOTIFICATION]: state => {
    state.unseenNotifications = state.unseenNotifications + 1;
  },
  [ENABLE_BROWSER_NOTIFICATION]: (state, action) => {
    state.isBlockingBrowser = action.payload;
  },
  [GET_NOTIFICATION_REFERENCE_SUCCESS]: (state, action) => {
    state.isEnabledSetting = action.payload.data.isEnabled;
  },
  [UPDATE_NOTIFICATION_REFERENCE_SUCCESS]: (state, action) => {
    state.isEnabledSetting = action.payload.data.isEnabled;
  },
});

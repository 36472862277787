import React, { useEffect, useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as paths from 'config/paths';
import { get } from 'lodash';
import {
  Spinner,
  PageTitle,
  GradientButton,
  JourneysList,
  CustomTab,
  RewardsCarousel,
} from 'components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { trackClickingJourneyButton } from '../../../tracking';

const JourneyState = {
  NEW: 'completed',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'archived',
};

const MyJourneysPage = memo(
  ({
    loading,
    gettingJourneysError,
    journeysList,
    userIsReady,
    journeyLoading,
    archivedJourney,
    archiveJourney,
    pagination,
    getMyJourneys,
    closeRewardDialog,
    getMyHedgiesRequest,
    hedgieResourceTypes,
    history,
  }) => {
    const [state, setState] = useState({
      activeTab: JourneyState.NEW,
      modal: false,
      journeyId: '',
    });

    const { activeTab, journeyId, modal } = state;

    const getJourneys = useCallback(
      (status, limit, offset, isLoadMore) => {
        getMyJourneys({
          status,
          offset,
          limit,
          isLoadMore,
        });
      },
      [getMyJourneys],
    );

    const onLoadMore = tab => () => {
      const { limit, offset, total } = pagination;
      const canLoadMore = limit + offset < total;
      if (canLoadMore) {
        getJourneys(tab, limit, limit + offset, true);
      }
    };

    const switchTab = tab => {
      if (activeTab !== tab) {
        setState({
          ...state,
          activeTab: tab,
        });
      }
    };

    const openModal = journeyId => () => {
      setState({
        ...state,
        modal: true,
        journeyId,
      });
    };

    const closeModal = () => {
      setState({
        ...state,
        modal: false,
      });
      closeRewardDialog(journeyId);
    };

    useEffect(() => {
      getJourneys(activeTab, 5, 0, false);
    }, [activeTab, getJourneys]);

    function handleAfterSellingOrConsumingSuccess() {
      closeModal();
    }

    function handleStartJourneyButton() {
      history.push(paths.SELECT_HEDGIE);
      trackClickingJourneyButton();
    }

    const { limit, offset, total } = pagination;
    const canLoadMore = limit + offset < total;

    const tabContent = [
      {
        id: JourneyState.NEW,
        tabName: 'New',
        content: (
          <JourneysList
            error={gettingJourneysError}
            journeys={journeysList}
            journeyLoading={journeyLoading}
            canLoadMore={canLoadMore}
            onLoadMore={onLoadMore(JourneyState.NEW)}
            key={JourneyState.NEW}
            emtyMessage="You currently don't have any new journeys"
            openModal={openModal}
          />
        ),
      },
      {
        id: JourneyState.IN_PROGRESS,
        tabName: 'In Progress',
        content: (
          <JourneysList
            error={gettingJourneysError}
            canLoadMore={canLoadMore}
            isCountdown
            journeyLoading={journeyLoading}
            onLoadMore={onLoadMore(JourneyState.IN_PROGRESS)}
            journeys={journeysList}
            key={JourneyState.IN_PROGRESS}
            emtyMessage="You currently don't have any journeys in progress"
          />
        ),
      },
      {
        id: JourneyState.COMPLETED,
        tabName: 'Archived',
        content: (
          <JourneysList
            error={gettingJourneysError}
            journeyLoading={journeyLoading}
            canLoadMore={canLoadMore}
            onLoadMore={onLoadMore(JourneyState.COMPLETED)}
            journeys={journeysList}
            key={JourneyState.COMPLETED}
            emtyMessage="There are no completed journeys"
          />
        ),
      },
    ];

    if (loading || !userIsReady) {
      return <Spinner />;
    }

    const rewards = get(archivedJourney, 'data.rewards', []);
    return (
      <div>
        <PageTitle title="Journeys" />
        <GradientButton
          label="Start Journey!"
          onClick={handleStartJourneyButton}
        />
        <div style={{ position: 'relative', marginTop: 40 }}>
          <CustomTab
            activeTab={activeTab}
            tabContent={tabContent}
            switchTab={switchTab}
          />
        </div>
        {modal ? (
          <Modal isOpen={modal} className="large-modal">
            <ModalHeader toggle={closeModal} />
            <ModalBody>
              <RewardsCarousel
                journeyId={journeyId}
                getMyHedgies={getMyHedgiesRequest}
                archiveJourney={archiveJourney}
                rewardsLoading={archivedJourney.loading}
                rewardsError={archivedJourney.error}
                rewards={rewards}
                hedgieResourceTypes={hedgieResourceTypes}
                handleAfterSellingOrConsumingSuccess={
                  handleAfterSellingOrConsumingSuccess
                }
              />
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  },
);

MyJourneysPage.propTypes = {
  journeysList: PropTypes.array,
  getMyJourneys: PropTypes.func,
  archiveJourney: PropTypes.func,
  closeRewardDialog: PropTypes.func,
  loading: PropTypes.bool,
  journeyLoading: PropTypes.bool,
  userIsReady: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  gettingJourneysError: PropTypes.object,
  archivedJourney: PropTypes.object,
  getMyHedgiesRequest: PropTypes.func,
  history: PropTypes.object.isRequired,
};

MyJourneysPage.defaultProps = {
  getMyJourneys: null,
  gettingJourneysError: null,
  journeysList: [],
  loading: false,
  userIsReady: false,
  journeyLoading: false,
  getRewardsByJourneyId: null,
  archiveJourney: null,
  archivedJourney: null,
  closeRewardDialog: null,
  getMyHedgiesRequest: null,
};

export default MyJourneysPage;

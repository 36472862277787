import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ConsumablesDropdown, HedgieButton, Spinner } from 'components';
import NumberUtil from 'services/numberUtil';
import curioImg from 'images/curio.svg';

const StyledApplyToHedgie = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${props => (props.isMobile ? '1fr' : '20% 80%')};
  justify-content: space-between;
  padding: 0 12px;
`;

const StyledCurio = styled.div`
  display: flex;
  justify-content: ${props =>
    props.isMobile ? 'space-between' : 'flex-start'};
  align-items: center;
  padding: 40px 12px 0 12px;
`;

const SellAndConsumeUI = ({
  isMobile,
  hedgiesOptions,
  onSelectHedgieChange,
  consumeItemCallback,
  sellItemCallback,
  soldItemState,
  price,
}) => {
  return (
    <div>
      <StyledApplyToHedgie className="pt-3" isMobile={isMobile}>
        <span className={isMobile ? 'align-center pb-2' : null}>
          Apply to:{' '}
        </span>
        <div
          style={{
            display: isMobile ? null : 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ConsumablesDropdown
            options={hedgiesOptions}
            onChange={onSelectHedgieChange}
          />
          <HedgieButton
            full={isMobile}
            style={{
              marginLeft: isMobile ? 0 : 15,
              marginTop: isMobile ? 12 : 0,
            }}
            onClick={consumeItemCallback}
          >
            Consume
          </HedgieButton>
        </div>
      </StyledApplyToHedgie>
      <StyledCurio isMobile={isMobile}>
        <div>
          <img width={32} src={curioImg} alt="curio" />
          <span className="ml-1">{NumberUtil.toDecimal(price)}</span>
        </div>

        <HedgieButton
          disabled={soldItemState.isItemSold}
          style={{ marginLeft: isMobile ? 0 : 15 }}
          onClick={sellItemCallback}
        >
          {soldItemState.loading ? <Spinner noFixed /> : null}
          {soldItemState.isItemSold ? 'Sold' : 'Sell item'}
        </HedgieButton>
      </StyledCurio>
    </div>
  );
};

SellAndConsumeUI.propTypes = {
  isMobile: PropTypes.bool,
  hedgiesOptions: PropTypes.array,
  onSelectHedgieChange: PropTypes.func,
  consumeItemCallback: PropTypes.func,
  sellItemCallback: PropTypes.func,
  soldItemState: PropTypes.object,
  price: PropTypes.string,
};

SellAndConsumeUI.defaultProps = {
  isMobile: false,
  hedgiesOptions: [],
  onSelectHedgieChange: null,
  consumeItemCallback: null,
  isItemSold: false,
  sellItemCallback: null,
  soldItemState: null,
  price: '0',
};

export default React.memo(SellAndConsumeUI);

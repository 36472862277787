import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as types from 'types';
import * as paths from 'config/paths';
import Redirect from 'config/redirect';
import { Desktop, Mobile } from 'components';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';

export const loggedInCls = authenticated =>
  classnames(' nav-header', {
    'logged-in': authenticated,
  });

const PresaleHeader = ({ location, user, authenticated }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const toggleNavbar = useCallback(() => {
    setCollapsed(collapsed => !collapsed);
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarOpened(sidebarOpened => !sidebarOpened);
  }, []);

  const isHomePage = useMemo(
    () => location.pathname === '/' || location.pathname === paths.JOURNEY,
    [location.pathname],
  );

  return (
    <div className={isHomePage ? 'fixed-header' : ''}>
      <header>
        <Redirect />
        <Desktop>
          <DesktopNav
            {...user}
            authenticated={authenticated}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
        </Desktop>
        <Mobile>
          <MobileNav
            {...user}
            authenticated={authenticated}
            collapsed={collapsed}
            toggleNavbar={toggleNavbar}
          />
        </Mobile>
        <span className="for-logged-in" />
      </header>
    </div>
  );
};

PresaleHeader.propTypes = {
  user: types.user,
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
};

PresaleHeader.defaultProps = {
  location: {
    pathname: '',
  },
  user: {
    email: '',
    anchor: false,
    curioBalance: 0,
  },
};

export default PresaleHeader;

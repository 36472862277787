import { put, call, fork, take } from 'redux-saga/effects';
import { getBattleDetailFailure, getBattleDetailSuccess } from './actions';

import { GET_BATTLE_DETAIL, SAW_BATTLE_DETAIL } from './actionTypes';
import { getMyHedgiesRequest } from '../hedgies/actions';

export function* getBattleDetail(api, battleID) {
  try {
    const response = yield call([api, api.get], `/battles/${battleID}`);
    yield put(getBattleDetailSuccess(response));
  } catch (e) {
    yield put(getBattleDetailFailure(e));
  }
}

export function* watchGetBattleDetail(api) {
  while (true) {
    const { payload } = yield take(GET_BATTLE_DETAIL);
    yield call(getBattleDetail, api, payload);
  }
}

export function* sawBattleDetail(api, color) {
  try {
    yield call([api, api.get], `/battles/shown?color=${color}`);
    yield put(
      getMyHedgiesRequest({
        limit: 100,
        offset: 0,
        isLoadMore: false,
      }),
    );
  } catch (e) {}
}

export function* watchSawBattle(api) {
  while (true) {
    const { payload } = yield take(SAW_BATTLE_DETAIL);
    yield call(sawBattleDetail, api, payload);
  }
}

export default function* ({ api }) {
  yield fork(watchGetBattleDetail, api);
  yield fork(watchSawBattle, api);
}

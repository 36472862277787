import React from 'react';
import PropTypes from 'prop-types';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import * as types from 'types';
import { PurchaseWithCard, PurchaseWithGiftCode, PurchaseWithEther } from 'components';

import Log from 'services/log';
import MetaMaskUtil from 'services/metamaskutil';

const VALID_FOR_GIFTCODE_TIER = 1;

const canGiftCodeHedgie = hedgie => (
  hedgie.tier === VALID_FOR_GIFTCODE_TIER
);

const canPurchaseHedgie = (hedgie, user) => {
  if (!hedgie || !user || !user.id) {
    return false;
  }

  if (hedgie.status === 0) {
    return true;
  } else if ((hedgie.status === 3 || hedgie.status === 2)&& hedgie.owner === user.id) {
    return true;
  }

  return false;
};

const MessageContainer = styled.div`
  min-height: 200px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

class BookingPurchase extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { user, hedgie, tier, className, purchaseError, redeemError, working, purchaseWithEtherError } = this.props;
    Log.debug({ hedgie, tier, purchaseWithEtherError }, 'Booking purchase render');
    const reservedMessage = (
      <MessageContainer>
        <b className="error">
          This hedgie has been reserved. Please pick another color.
        </b>
      </MessageContainer>
    );

    const purchaseContainer = (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Credit Card
            </NavLink>
          </NavItem>
          {MetaMaskUtil.isCompatible() &&
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                Ether
              </NavLink>
            </NavItem>
          }
          {canGiftCodeHedgie(hedgie) &&
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                Gift Code
              </NavLink>
            </NavItem>
          }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <PurchaseWithCard error={purchaseError} working={working} price={tier.dollar} onSubmitCC={this.props.onSubmitCC} />
              </Col>
            </Row>
          </TabPane>
          {canGiftCodeHedgie(hedgie) &&
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <PurchaseWithGiftCode error={redeemError} working={working} onSubmitGiftCode={this.props.onSubmitGiftCode} />
                </Col>
              </Row>
            </TabPane>
          }
          {MetaMaskUtil.isCompatible() && tier.ether &&
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <PurchaseWithEther hedgie={hedgie} error={purchaseWithEtherError} price={tier.ether.toString()} onCompleteEtherBuyNow={this.props.onCompleteEtherBuyNow} />
                </Col>
              </Row>
            </TabPane>
          }
        </TabContent>
      </React.Fragment>
    );

    return (
      <div
        className={classnames([className, 'purchase-container tabbed-container'])}
      >
        <Container>
          {
            (hedgie && canPurchaseHedgie(hedgie, user)) ? purchaseContainer : reservedMessage
          }
        </Container>
      </div>
    );
  }
}

BookingPurchase.propTypes = {
  user: types.user,
  hedgie: types.hedgie.isRequired,
  tier: types.tier.isRequired,
  onSubmitCC: PropTypes.func.isRequired,
  onSubmitGiftCode: PropTypes.func.isRequired,
  onCompleteEtherBuyNow: PropTypes.func.isRequired,
  purchaseError: types.error,
  purchaseWithEtherError: types.error,
  redeemError: types.error,
  className: PropTypes.string,
  working: PropTypes.bool,
};

BookingPurchase.defaultProps = {
  user: null,
  purchaseError: null,
  purchaseWithEtherError: null,
  redeemError: null,
  className: null,
  working: false,
};

export default BookingPurchase;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Row, Col } from 'reactstrap';
import { Button } from 'components';
import FormError from 'components/stateless/FormError';
import env from 'config/env';

import * as types from 'types';

import * as paths from 'config/paths';
import Log from 'services/log';

const initialState = () => {
  if (env.prefillForms) {
    return {
      email: 'adam.hunter+hedgiesignup95@splitelement.com',
      password: 'Testing123',
    };
  }
  return {
    email: '',
    password: '',
  };
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState() };
  }

  onChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  onSubmit = event => {
    const {
      email,
      password,
    } = this.state;

    Log.debug('onSubmit', 'SignInForm');

    this.props.onSignIn(email, password);

    event.preventDefault();
  }

  render() {
    Log.debug('render', 'SignInForm');

    const {
      email,
      password,
    } = this.state;

    const {
      error,
    } = this.props;

    Log.debug(this.props, 'SignInForm: render');

    const isInvalid =
      password === '' ||
      email === '';

    const groupClass = 'col-md-12';

    return (
      <div>
        <Form className="mt-3" onSubmit={this.onSubmit}>
          <Row>
            <FormGroup className={groupClass}>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={event => this.onChange(event)}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className={groupClass}>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={event => this.onChange(event)}
                value={password}
              />
            </FormGroup>
          </Row>
          <FormError error={error} />
          <Row>
            <FormGroup className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start" >
              <Button size="large" disabled={isInvalid} type="submit">Sign In</Button>
            </FormGroup>
          </Row>
        </Form>
        <Row style={{ marginTop:30 }}>
          <Col className="mb-5 mb-md-0 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
            <Link to={paths.FORGOT_PASSWORD}>
              Forgot your password?
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

SignInForm.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  error: types.error,
};

SignInForm.defaultProps = {
  error: null,
};

export default SignInForm;

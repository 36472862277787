import React from 'react';
import { media } from 'styles/main';
import styled from 'styled-components';
import guidanceImgURL1 from '../../../images/guidance-images/notification_guidance_1.jpg';
import guidanceImgURL2 from '../../../images/guidance-images/notification_guidance_2.jpg';

const StyledBox = styled.div`
  overflow: scroll;
  max-height: 90vh;
  padding: 0 20px;
`;
const StyledImg = styled.img`
  width: 360px;
  ${media.tablet`
    width: 100%;
    height:auto;
  `}
`;

const TurnOnNotificationGuidance = () => {
  return (
    <StyledBox>
      <div className="mt-3">
        <p className="ff-medium mt-4">
          1. Click on the ‘Lock’ symbol next to the website URL, then You should
          now be able to see a <code>"Notifications"</code> field saying{' '}
          <code>"Block"</code>.
        </p>
        <StyledImg src={guidanceImgURL1} alt="guidanceImgURL1" />
      </div>
      <div className="mt-3">
        <p className="ff-medium mt-4">
          2. Select <code>"Allow"</code> option.
        </p>
        <StyledImg src={guidanceImgURL2} alt="guidanceImgURL2" />
      </div>
      <div className="mt-3">
        <p className="ff-medium mt-4">3. Reload page!</p>
      </div>
    </StyledBox>
  );
};

export default TurnOnNotificationGuidance;

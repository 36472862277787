import React from 'react';
import PropTypes from 'prop-types';

const showErrors = errors => {
  if (errors && Object.keys(errors).length === 0) {
    return '';
  }
  if (errors) {
    return Object.values(errors).join('<br/>');
  }
  return '';
};

const ErrorMessages = ({ fields, message }) => {
  return (
    <div className="form-line error-msg">
      <label className="ff-semi-bold fs-extra-md color-error">
        <p>{message}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: showErrors(fields),
          }}
        />
      </label>
    </div>
  );
};

ErrorMessages.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.string,
};

ErrorMessages.defaultProps = {
  fields: null,
  message: '',
};

export default ErrorMessages;

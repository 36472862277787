import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import leftStars from './left-stars.svg';
import rightStars from './right-stars.svg';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: ${({ align }) => {
    if (align === 'left') {
      return 'flex-start';
    } else if (align === 'right') {
      return 'flex-end';
    }
    return 'center';
  }};
`;

const StyledButton = styled.button`
  border-radius: ${props =>
    props.borderRadius ? `${props.borderRadius}px` : '24px'};
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  border: 1px solid rgba(${props => props.mainColor}, 1);
  background-color: rgba(${props => props.mainColor}, 1);
  background-blend-mode: luminosity;
  background-image: url(${props => props.leftStars}),
    url(${props => props.rightStars}),
    linear-gradient(-180deg, #f1d97e 0%, #d69e35 37%, #ca8314 67%, #ca8314 100%);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 6% 50%, 95% 50%;
  box-shadow: 0 4px 8px 0 rgba(${props => props.mainColor}, 0.39);
  text-align: center;
  cursor: ${props => (props.disabled ? 'null' : 'pointer')};
  transition: all 300ms ease-in-out;
  position: relative;
  &:focus {
    outline: none;
  }
  &:disabled {
  background-blend-mode: normal;
    background-image: linear-gradient(
      -180deg,
      gray 0%,
      #d0d0d0 80%,
      #f0f0f0 100%
    );
    box-shadow: inset 0px 0px 0px 0 gray, inset 0 -2px 2px 0 #d0d0d0;
    border: 1px solid #d0d0d0;
  }
  &:hover:not(:disabled) {
    transition: all 300ms ease-in-out;
    background-blend-mode: luminosity;
    box-shadow: 0 6px 20px 0 rgba(${props => props.mainColor}, 0.8);
    .btn-text {
      transition: all 300ms ease-in-out;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    }
  }
`;

const StyledLabel = styled.span`
  font-weight: 600;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '21px')};
  color: ${props => (props.disabled ? 'rgba(255,255,255,.6)' : '#ffffff')};
  letter-spacing: 0;
  text-align: center;
`;

const COLORS = {
  golden: '242, 179, 9',
  cyan: '0, 213, 255',
  turquoise: '0, 255, 213',
  pink: '250,112,135',
};

const GradientButton = memo(
  ({
    width,
    height,
    label,
    link,
    onClick,
    align,
    search,
    isLabelBold,
    fontSize,
    borderRadius,
    bgColor,
    ...props
  }) => {
    const button = (
      <StyledButton
        width={width}
        height={height}
        borderRadius={borderRadius}
        onClick={onClick}
        mainColor={COLORS[bgColor]}
        leftStars={leftStars}
        rightStars={rightStars}
        {...props}
      >
        <StyledLabel
          disabled={!!props.disabled}
          className="btn-text"
          fontSize={fontSize}
          isLabelBold={isLabelBold}
        >
          {label}
        </StyledLabel>
      </StyledButton>
    );

    return (
      <StyledWrapper align={align}>
        {link ? <Link to={{ pathname: link, search }}>{button}</Link> : button}
      </StyledWrapper>
    );
  },
);

GradientButton.defaultProps = {
  label: '',
  onClick: null,
  width: 296,
  height: 81,
  fontSize: 26,
  link: '',
  align: 'center',
  search: '',
  borderRadius: 24,
  isLabelBold: true,
  bgColor: 'golden',
};

GradientButton.propTypes = {
  label: PropTypes.string,
  fontSize: PropTypes.number,
  link: PropTypes.string,
  search: PropTypes.string,
  mainColor: PropTypes.string,
  width: PropTypes.number,
  borderRadius: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  isLabelBold: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  bgColor: PropTypes.oneOf(['golden', 'cyan', 'turquoise', 'pink']),
};

export default GradientButton;

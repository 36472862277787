import React from 'react';
import PropTypes from 'prop-types';
import { ItemImage } from 'components';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${media.tablet`
  `};
  &:before {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: absolute;
    top: 5px;
    right: 5px;
    content: '${props => props.count}';
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #fff;
    background-color: #12DFC0;
  }
`;

const ItemWithBadge = ({ item, onClick }) => {
  return (
    <StyledItem count={item.amount}>
      <ItemImage item={item} onClick={onClick} isInvisible={item.amount > 0} />
    </StyledItem>
  );
};

ItemWithBadge.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

ItemWithBadge.defaultProps = {
  item: [],
  onClick: null,
};

export default ItemWithBadge;

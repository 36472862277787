import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { parse } from 'query-string';
import * as types from 'types';
import Log from 'services/log';
import * as paths from 'config/paths';

import { ResetPasswordForm, PageTitle, SideHedgie } from 'components';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    if (this.props.user === null) {
      this.props.history.push(paths.HOME);
    }
  }

  componentDidUpdate() {
    if (this.props.resetPasswordStatus === true) {
      this.props.history.push(paths.SIGNIN);
    }
  }

  handleResetPassword = params => {
    Log.debug(this.props);
    const actionCode = parse(this.props.location.search).oobCode;
    //    Log.debug({ params, actionCode }, 'handleResetPassword');

    const resetParams = { ...params, actionCode };

    this.props.resetPassword(resetParams);
  };

  render() {
    const { error, working } = this.props;
    Log.debug(this.props, 'handleResetPassword:render');
    return (
      <main>
        <Container fluid className="pos-rel sign-up-wrap">
          <Container>
            <Row>
              <Col>
                <PageTitle title="Reset Password" />
              </Col>
            </Row>
            <Row style={{ marginBottom: 100 }}>
              <Col>
                <ResetPasswordForm
                  onSubmit={this.handleResetPassword}
                  error={error}
                  working={working}
                />
              </Col>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5" />
                  <div className="col-md-7" />
                </div>
              </div>
            </Row>
          </Container>
          <SideHedgie
            className="side-hedgie-secret"
            color="blue"
            title="It’s a secret!"
          />
        </Container>
      </main>
    );
  }
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  working: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
  error: types.error,
  user: types.user.isRequired,
  resetPasswordStatus: PropTypes.object,
};

ResetPasswordPage.defaultProps = {
  signUpFailed: false,
  signUpSuccess: false,
  error: null,
  working: false,
  resetPasswordStatus: null,
};

export default ResetPasswordPage;

import PropTypes from 'prop-types';

const { shape, number, float, string, arrayOf, object } = PropTypes;

export const error = shape({
  message: string,
  fields: object,
});

export const user = shape({
  address: string,
  email: string,
  id: string,
});

export const session = shape({
  token: string,
  refreshToken: string,
});

export const hedgie = shape({
  hid: number,
  color: string,
  name: string,
  fire: float,
  water: float,
  earth: float,
  air: float,
  intelligence: float,
  luck: float,
  resilience: float,
  bravery: float,
  owner: string,
  status: number,
  tier: number,
  imgURL: string,
});

export const tier = shape({
  tier: number,
  dollar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hcc: number,
  ether: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tierName: string,
  desc: string,
});

export const hedgies = arrayOf(
  shape({
    tier,
    values: arrayOf(hedgie),
  })
);

export const paginationData = shape({
  limit: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number,
});

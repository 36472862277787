import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Spinner, GridItemsImage } from 'components';

const StyledSpinner = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
`;

const ConsumablesList = React.memo(
  ({ consumables, selectItem, loadMore, canLoadMore, loading }) => {
    return (
      <React.Fragment>
        {loading ? (
          <StyledSpinner>
            <Spinner noFixed />
          </StyledSpinner>
        ) : null}
        <GridItemsImage items={consumables} onClick={selectItem} />
        {canLoadMore ? (
          <div className="align-center pt-3 pb-5">
            <Button onClick={loadMore}>Load more</Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  },
);

ConsumablesList.propTypes = {
  consumables: PropTypes.array,
  selectItem: PropTypes.func,
  loadMore: PropTypes.func,
  canLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
};

ConsumablesList.defaultProps = {
  isOpen: false,
  selectItem: null,
  consumables: [],
  loadMore: null,
  canLoadMore: false,
  loading: false,
};

export default ConsumablesList;

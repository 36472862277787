import React, { memo } from 'react';
import { Parallax } from 'react-spring/renderprops-addons';
import TheGame from './TheGame';
import MeetHedgie from './MeetHedgie';
import PowerSlide from './PowerSlide';
import KingdomsSlide from './KingdomsSlide';
import TreasuresSlide from './TreasuresSlide';
import HedgieHouseSlide from './HedgieHouseSlide';
import EarnMoneySlide from './EarnMoneySlide';
import EndingSlide from './EndingSlide';

const DesktopPage = React.forwardRef(({ isMobile }, ref) => {
  return (
    <Parallax ref={ref} pages={8}>
      <TheGame isMobile={isMobile} ref={ref} />
      <MeetHedgie
        isMobile={isMobile}
        onNextPage={() => ref.current.scrollTo(2)}
      />
      <PowerSlide
        isMobile={isMobile}
        onNextPage={() => ref.current.scrollTo(3)}
      />
      <KingdomsSlide
        isMobile={isMobile}
        onNextPage={() => ref.current.scrollTo(4)}
      />
      <TreasuresSlide
        isMobile={isMobile}
        onNextPage={() => ref.current.scrollTo(5)}
      />
      <HedgieHouseSlide
        isMobile={isMobile}
        onNextPage={() => ref.current.scrollTo(6)}
      />
      <EarnMoneySlide isMobile={isMobile} />
      <EndingSlide isMobile={isMobile} />
    </Parallax>
  );
});

export default memo(DesktopPage);

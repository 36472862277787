import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AffiliateTracker, PageTitle, SubHeading, Button } from 'components';

class ThankYou extends Component {
  render() {
    const { title, message, transactionInfo, redirect } = this.props;

    return (
      <div>
        {transactionInfo && (
          <AffiliateTracker
            orderNum={transactionInfo.id}
            salesAmount={transactionInfo.amount / 100}
          />
        )}
        <div className="container-fluid row-section thank-you-section">
          <div className="container text-center">
            <PageTitle title={title} />
            <SubHeading heading={message} className="sub-heading-center" />
            <Button
              size="large"
              tag={Link}
              to={redirect.target}
              className="justify-content-center"
            >
              {redirect.text}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ThankYou.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  redirect: PropTypes.object,
  transactionInfo: PropTypes.object,
};
ThankYou.defaultProps = {
  title: '',
  message: '',
  redirect: {},
  transactionInfo: null,
};

export default ThankYou;

export const ON_BOARDING_GOTO_CHOOSE_DURATION =
  'ON_BOARDING_GOTO_CHOOSE_DURATION';
export const ON_BOARDING_START_JOURNEY = 'ON_BOARDING_START_JOURNEY';
export const ON_BOARDING_END_JOURNEY = 'ON_BOARDING_END_JOURNEY';
export const ON_BOARDING_RESET_STATE = 'ON_BOARDING_RESET_STATE';

export const GOTO_JOURNEY_STEP = 'GOTO_JOURNEY_STEP';

export const gotoJourneyStep = step => ({
  type: GOTO_JOURNEY_STEP,
  step,
});

export const gotoChooseDuration = (nextStep, worldId) => ({
  type: ON_BOARDING_GOTO_CHOOSE_DURATION,
  payload: {
    nextStep,
    worldId,
  },
});

export const startJourney = (nextStep, duration) => ({
  type: ON_BOARDING_START_JOURNEY,
  payload: {
    nextStep,
    duration,
  },
});

export const finishOnboarding = nextStep => ({
  type: ON_BOARDING_END_JOURNEY,
  payload: {
    nextStep,
  },
});

export const resetOnboardingState = () => ({
  type: ON_BOARDING_RESET_STATE,
});

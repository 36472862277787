import React from 'react';
import PropTypes from 'prop-types';
import { JourneyChooseDuration } from 'components';

const DEFAULT_MINUTES = [
  { duration: 60 },
  { duration: 3 * 60 },
  { duration: 12 * 60 },
  { duration: 24 * 60 },
];

const ChooseDuration = ({
  unitDuration,
  durationsDataList,
  hasEnergy,
  onDurationChange,
  isNotEnergyEnough,
  startJourney,
}) => {
  return (
    <JourneyChooseDuration
      align="inline"
      theme="dark"
      displayType={unitDuration}
      durationsData={durationsDataList}
      startJourney={startJourney}
      buttonProps={{ width: 180, height: 50, fontSize: 16 }}
      onDurationChange={onDurationChange}
      disabled={hasEnergy && isNotEnergyEnough}
    />
  );
};

ChooseDuration.propTypes = {
  startJourney: PropTypes.func,
  durationsDataList: PropTypes.array,
  unitDuration: PropTypes.oneOf(['sec', 'min']),
  hasEnergy: PropTypes.bool,
  hedgieEnergy: PropTypes.number,
  onDurationChange: PropTypes.func,
  isNotEnergyEnough: PropTypes.bool,
};

ChooseDuration.defaultProps = {
  startJourney: null,
  unitDuration: 'min',
  durationsDataList: DEFAULT_MINUTES,
  hasEnergy: false,
  hedgieEnergy: 0,
  onDurationChange: null,
  isNotEnergyEnough: false,
};

export default ChooseDuration;

import React, { useContext } from 'react';
import { GradientButton } from 'components';
import { MyHedgiesContext } from './MyHedgiesWrapper';
import useCheckingInProgress from './useCheckingInProgress';

const SelectedBattleButton = () => {
  const { selectedHedgie } = useContext(MyHedgiesContext);
  const { isJourneyInProgress, isBattleInProgress } =
    useCheckingInProgress(selectedHedgie);

  const isDisabled = isJourneyInProgress || isBattleInProgress;

  return (
    <div>
      <GradientButton
        label={selectedHedgie?.show_battle ? 'Watch Result' : 'Battle'}
        bgColor="turquoise"
        disabled={isDisabled}
        link={
          selectedHedgie && !isDisabled
            ? selectedHedgie?.show_battle
              ? `/battles/${selectedHedgie.show_battle}`
              : `/battle/${selectedHedgie.color}`
            : null
        }
      />
      {isBattleInProgress ? (
        <div className="pt-2" style={{ textAlign: 'center' }}>
          Battle in Progress
        </div>
      ) : null}
    </div>
  );
};

export default SelectedBattleButton;

import Web3 from 'web3';
import { BigNumber } from 'bignumber.js';
import ethUtil from 'ethereumjs-util';
import { ethSignText } from 'config/env';
import Log from 'services/log';

class MetaMaskUtil {
  static isCompatible() {
    // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    // return !!window.chrome && !!window.chrome.webstore;
    return isChrome;
  }

  static isInstalled() {
    // Log.debug('MetaMaskUtil.isInstalled', !!window.web3);
    return !!window.web3;
  }

  static openMetaMaskWindow() {
    window.ethereum.enable();
  }

  static async isUnlocked() {
    if (!MetaMaskUtil.isInstalled()) {
      // Log.debug('MetaMaskUtil.isUnlocked - is not installed');
      return false;
    }

    const web3 = new Web3(window.web3.currentProvider);

    if (typeof web3 !== 'undefined') {
      return web3.eth.getAccounts().then(accounts => {
        const address = accounts[0];
        if (address === undefined) {
          // Log.debug('MetaMaskUtil.isUnlocked account is undefined (locked)');
          return false;
        }
        // Log.debug('MetaMaskUtil.isUnlocked account is valid (unlocked)');
        return true;
      });
    }
    Log.warn('MetaMaskUtil.isUnlocked web3 is undefined - probably an error');
    return Promise.reject(new Error('web3 is undefined'));
  }

  static async getAccount() {
    if (!MetaMaskUtil.isInstalled()) {
      // Log.debug('MetaMaskUtil.isUnlocked - is not installed');
      return Promise.reject(new Error('metamask is not installed'));
    }

    const web3 = new Web3(window.web3.currentProvider);

    if (typeof web3 !== 'undefined') {
      return web3.eth.getAccounts().then(accounts => {
        const address = accounts[0];
        // Log.debug('MetaMaskUtil.getAccount account is valid (unlocked)');
        return address;
      });
    }
    Log.warn('MetaMaskUtil.isUnlocked web3 is undefined - probably an error');
    return Promise.reject(new Error('web3 is undefined'));
  }

  static async getEstimatedGasPrice(from, to, amountInWei) {
    const web3 = new Web3(window.web3.currentProvider);
    return web3.eth
      .estimateGas({
        from,
        to,
        amount: amountInWei,
      })
      .then(gas => {
        return web3.eth.getGasPrice().then(gasPrice => {
          return gasPrice * gas;
        });
      });
  }

  static async makePurchase(from, to, amountInWei) {
    Log.debug(`MetaMaskUtil.makePurchase - ${from} ${to} ${amountInWei}`);
    const web3 = new Web3(window.web3.currentProvider);

    // see https://web3js.readthedocs.io/en/1.0/web3-eth.html#sendtransaction
    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction({
          from,
          to,
          value: amountInWei,
        })
        .on('transactionHash', hash => {
          resolve(hash);
        })
        .on('error', err => {
          Log.error(err);
          reject(err);
        });
    });
  }

  static toEther(wei) {
    const web3 = new Web3(window.web3.currentProvider);
    return web3.utils.fromWei(new BigNumber(wei).toString(), 'ether');
  }

  static sum(num1, num2) {
    const a = new BigNumber(num1);
    const b = new BigNumber(num2);
    return a.plus(b);
  }

  static async getAddressAndSignature() {
    if (!MetaMaskUtil.isInstalled()) {
      Log.debug(
        'getAddressAndSignature',
        'MetaMaskUtil.isUnlocked - is not installed',
      );
      return Promise.reject(new Error('metamask is not installed'));
    }

    const web3 = new Web3(window.web3.currentProvider);
    web3.currentProvider.setMaxListeners(300);

    if (typeof web3 === 'undefined') {
      Log.error('MetaMaskUtil.getAddressAndSignature - web3 is undefined');
      return Promise.reject(new Error('web3 is undefined'));
    }
    Log.debug('getAddressAndSignature', 'MetaMaskUtil.isUnlocked');

    return web3.eth.getAccounts().then(accounts => {
      const address = accounts[0];
      if (address === undefined) {
        return Promise.reject(new Error('account is not available'));
      }
      Log.debug('getAddressAndSignature', `Account is ${address}`);
      if (address) {
        const msg = ethUtil.bufferToHex(Buffer.from(ethSignText, 'utf8'));
        const params = [msg, address];
        const method = 'personal_sign';
        Log.debug(
          'MetaMaskUtil.getAddressAndSignature',
          'Sending sendAsync request to sign request',
        );
        return new Promise((resolve, reject) => {
          Log.debug(
            'MetaMaskUtil.getAddressAndSignature',
            'Sending sendAsync request to sign request',
          );
          web3.currentProvider.sendAsync(
            {
              method,
              params,
              address,
            },
            (err, result) => {
              if (err) {
                Log.error(
                  'MetaMaskUtil.getAddressAndSignature',
                  `currentProvider.sendAsync  ${err}`,
                );
                reject(err);
              } else {
                Log.debug(
                  'MetaMaskUtil.getAddressAndSignature:currentProvider.sendAsync',
                  { result },
                );
                const signature = result.result;
                resolve({ address, signature });
              }
            },
          );
        });
      }
      Log.error(
        'MetaMaskUtil.getAddressAndSignature',
        'address is not valid / account is not valid',
      );

      return Promise.reject(new Error('account is not valid'));
    });
  }
  static getChainId(){
    const web3 = new Web3(window.web3.currentProvider);
    return web3.eth.getChainId();
  }
}

export default MetaMaskUtil;

import { put, call, fork, take } from 'redux-saga/effects';
import {
  closePopUpExp,
  UPGRADE_HEDGIE,
  upgradeHedgieFailure,
  upgradeHedgieSuccess,
} from './actions';
import { selectJourneyHedgie } from '../journey/actions';

export function* upgradeHedgie(api, payload) {
  try {
    const response = yield call([api, api.post], '/hedgies/levels', payload);
    yield put(upgradeHedgieSuccess(response));
    yield put(selectJourneyHedgie(response.data));
    yield put(closePopUpExp());
  } catch (e) {
    yield put(upgradeHedgieFailure(e));
  }
}

export function* watchUpgradeHedgie(api) {
  while (true) {
    const { payload } = yield take(UPGRADE_HEDGIE);
    yield call(upgradeHedgie, api, payload);
  }
}

export default function*({ api }) {
  yield fork(watchUpgradeHedgie, api);
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import Info from '../Info';
import { StyledInfo } from '../TheGame/TopSlide';
import Anchor from '../Anchor';

const TEXT =
  'Hedgies are legendary creatures. Skilled explorers with a storied past, these limited edition digital collectibles ' +
  '(or “cryptocollectibles”) are on a never-ending mission to uncover lost treasure and make Curio coin.';

export const StyledHref = styled.a`
  font-size: 14px;
  ${media.phone`
    font-size: 12px;
    padding-left: 18px;
  `};
`;

const HREF = 'https://medium.com/hellohedgie/hedgie-origin-story-a4469e7c6d21';

const MeetHedgieContent = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <StyledInfo>
        <Info title="Meet Hedgie" textColor="#000" text={TEXT} />
        <StyledHref href={HREF} target="_blank">
          Read the Hedgie Origin Story
        </StyledHref>
      </StyledInfo>
      <Anchor
        text="Hedgie Powers"
        textColor="#000"
        onClick={onNextPage}
        bottom={isMobile ? 40 : 20}
      />
    </React.Fragment>
  );
};

MeetHedgieContent.propTypes = {
  onNextPage: PropTypes.func,
  isMobile: PropTypes.bool,
};

MeetHedgieContent.defaultProps = {
  onNextPage: null,
  isMobile: false,
};

export default MeetHedgieContent;

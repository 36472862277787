import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HedgieButton } from 'components';
import { isEmpty } from 'lodash';
import MyBusinessesList from './MyBusinessesList';

export const ROUTER_QUERY_PARAMS = {
  ALL: 'allBusinesses',
  USER: 'myBusinesses',
};
const Businesses = ({
  getMyBusinesses,
  myBusinesses,
  isAllBusinesses,
  businessType,
}) => {
  const { pagination } = myBusinesses;
  const { limit, offset, total } = pagination;
  const canLoadMore = limit + offset < total;

  useEffect(() => {
    const params = {
      offset: 0,
      limit,
      isLoadMore: false,
      isAllBusinesses,
    };

    getMyBusinesses(businessType ? { ...params, type: businessType } : params);
  }, [businessType, getMyBusinesses, isAllBusinesses, limit]);

  const loadMoreCallback = useCallback(() => {
    if (canLoadMore) {
      const params = {
        offset: limit + offset,
        limit,
        isLoadMore: true,
        isAllBusinesses,
      };

      getMyBusinesses(
        businessType ? { ...params, type: businessType } : params,
      );
    }
  }, [
    businessType,
    canLoadMore,
    getMyBusinesses,
    isAllBusinesses,
    limit,
    offset,
  ]);

  if (isEmpty(myBusinesses.data)) {
    return <div className="align-center pt-3">No data!</div>;
  }

  return (
    <div>
      <MyBusinessesList myBusinesses={myBusinesses.data} />
      {canLoadMore ? (
        <div className="align-center pt-3 pb-5">
          <HedgieButton onClick={loadMoreCallback}>Load more</HedgieButton>
        </div>
      ) : null}
    </div>
  );
};

Businesses.propTypes = {
  getMyBusinesses: PropTypes.func,
  myBusinesses: PropTypes.object,
  isAllBusinesses: PropTypes.bool,
  businessType: PropTypes.string,
};

Businesses.defaultProps = {
  getMyBusinesses: null,
  myBusinesses: {},
  isAllBusinesses: false,
  businessType: 'managed',
};

export default Businesses;

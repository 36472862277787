import React from 'react';
import { Col, Row } from 'reactstrap';
import { HedgieImg, SubHeading, PageTitle } from 'components';
import imgCurio from 'images/curio-styled.svg';
import BattleCountdown from './BattleCountdown';
import { pageNames, useBattle } from './battle-context';
import { StyledContainer } from './MainFlow';
import useMedia from '../../custom-hooks/useMedia';
import styled from 'styled-components';

const StyledText = styled.div`
  color: ${(p) => (p.isMobile && p.hasDarkBg ? '#fff' : '#000')};
`;

export function Participants({ hasDarkBg }) {
  const { hedgieOwner, opponent, isMobile } = useBattle();

  const hedgieImgWidth = useMedia(
    ['(min-width: 992px)', '(max-width: 768px)'],
    [360, 160],
    360,
  );

  return (
    <Row gutter={20}>
      {[hedgieOwner.hedgie, opponent.hedgie].map((hg) =>
        !hg ? null : (
          <Col md={6} lg={6} sm={6} xs={6} className="align-center" key={hg.id}>
            <div className="text-center">
              <HedgieImg
                color={`#${hg.color}`}
                className="img-fluid"
                width={hedgieImgWidth}
                imgURL={hg? hg.imgURL: null}
              />
              <StyledText
                className="font-weight-bold"
                isMobile={isMobile}
                hasDarkBg={hasDarkBg}
              >
                {hg.ownerName ? hg.ownerName : 'Anonymous'}
              </StyledText>
              <StyledText isMobile={isMobile} hasDarkBg={hasDarkBg}>
                #{hg.color}
              </StyledText>
            </div>
          </Col>
        ),
      )}
    </Row>
  );
}

const PreFightingBattle = () => {
  const { battleInfo, setPage } = useBattle();

  function gotoNextPage() {
    setPage(pageNames.POINTS_SUMMARY);
  }

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-center pt-3 pb-3">
        <div className="d-flex justify-content-center align-items-center">
          <img src={imgCurio} alt="curio" height={80} />
          <div className="pl-3">
            <div className="font-weight-bold">Bet</div>
            <span>{battleInfo.bet_amount} curio</span>
          </div>
        </div>
      </div>
      <div className="text-center pt-3">
        <span>The Battle begins in:</span>
      </div>
      <div className="d-flex justify-content-center pt-3 battle-count-down">
        <BattleCountdown onFinish={gotoNextPage} />
      </div>
    </div>
  );
};
const ChallengerFound = () => {
  return (
    <StyledContainer>
      <PageTitle title="Arena" />
      <SubHeading
        heading="Challenger found!"
        className="font-weight-bold text-center pb-5 op-hide"
      />
      <Participants />
      <PreFightingBattle />
    </StyledContainer>
  );
};

export default ChallengerFound;

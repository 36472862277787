import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import NumberUtil from 'services/numberUtil';
import { Button } from 'components';
import { transferCurio, toggleTransferCurioModal } from 'store/user/actions';
import {
  selectCurrentCurio,
  selectIsOpenModal,
  selectTransferredCurioError,
} from 'store/user/selectors';
import TransferCurioForm from './TransferCurioForm';

const TransferModal = ({
  fromBusinessID,
  curioBalance,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => selectIsOpenModal(state));

  const transferredCurioError = useSelector(state =>
    selectTransferredCurioError(state),
  );

  const currentCurioBalance = useSelector(state => selectCurrentCurio(state));


  const handleSubmit = useCallback(
    value => {
      const convertedCurio = NumberUtil.convertCuritoToWEI(value.amount);
      dispatch(transferCurio({ ...value, amount: convertedCurio }));
    },
    [dispatch],
  );

  const closeModal = useCallback(
    () => {
      dispatch(toggleTransferCurioModal(false));
    },
    [dispatch],
  );

  const openModal = useCallback(
    () => {
      dispatch(toggleTransferCurioModal(true));
    },
    [dispatch],
  );

  return (
    <React.Fragment>
      <div className="pb-2 pt-1" style={{ marginLeft: -16 }}>
        <Button onClick={openModal}>Transfer curio</Button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={closeModal}>Transfer curio</ModalHeader>
        <ModalBody>
          <TransferCurioForm
            fromBusinessID={fromBusinessID}
            onSubmit={handleSubmit}
            currentCurioBalance={currentCurioBalance}
            transferredCurioError={transferredCurioError}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

TransferModal.propTypes = {
  curioBalance: PropTypes.string.isRequired,
  fromBusinessID: PropTypes.string,
};

TransferModal.defaultProps = {
  fromBusinessID: null,
}

export default TransferModal;

import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SwitchButton } from 'components';
import { updateNotificationReference } from '../../../store/notification/actions';
import { askForPermission } from '../../pages/NotificationPage/notification-services';
import { isSupportedNotification } from '../../../services/firebase/firebase';

const NotificationSubscription = () => {
  const dispatch = useDispatch();

  const isEnabledSetting = useSelector(
    state => state.notification.isEnabledSetting,
  );

  const changeCallback = useCallback(
    checked => {
      if (checked) {
        askForPermission()
          .then(token => {
            if (token) {
              dispatch(updateNotificationReference(true));
            }
          })
          .catch(error => {
            dispatch(
              updateNotificationReference(true, isSupportedNotification),
            );
          });
      } else {
        dispatch(updateNotificationReference(false));
      }
    },
    [dispatch],
  );

  return (
    <SwitchButton
      label="Receive notifications: "
      checked={isEnabledSetting}
      handleChange={changeCallback}
    />
  );
};

NotificationSubscription.propTypes = {};

NotificationSubscription.defaultProps = {};

export default React.memo(NotificationSubscription);

import { put, call, fork, take } from 'redux-saga/effects';
import querystring from 'querystring';
import { omit } from 'lodash';
import {
  getMyBusinessesSuccess,
  getMyBusinessesFailure,
  getBusinessProductsSuccess,
  getBusinessProductsFailure,
  getBusinessDetailSuccess,
  getBusinessDetailFailure,
  getProductDetailSuccess,
  getProductDetailFailure,
  createAnOrderSuccess,
  createAnOrderFailure,
  getOrdersOfBusinessSuccess,
  getOrdersOfBusinessFailure,
  getMyOrdersSuccess,
  getMyOrdersFailure,
  archiveAnOrderSuccess,
  archiveAnOrderFailure,
  getAllProductsSuccess,
  getAllProductsFailure,
} from './actions';
import {
  ARCHIVE_ORDER,
  CREATE_AN_ORDER,
  GET_ALL_PRODUCTS,
  GET_BUSINESS_DETAIL,
  GET_BUSINESS_PRODUCTS,
  GET_MY_BUSINESSES,
  GET_MY_ORDERS,
  GET_ORDERS_OF_BUSINESS,
  GET_PRODUCT_DETAIL,
} from './actionTypes';

import { getCurrentUserRequest } from '../user/actions';

export function* getMyBusinesses(api, requestParams) {
  try {
    const url = requestParams.isAllBusinesses
      ? '/businesses'
      : '/users/me/businesses';

    const qs = querystring.stringify(
      omit(requestParams, ['isAllBusinesses', 'isLoadMore']),
    );

    const response = yield call([api, api.get], `${url}?${qs}`);
    yield put(
      getMyBusinessesSuccess({
        ...response,
        ...requestParams,
      }),
    );
  } catch (e) {
    yield put(getMyBusinessesFailure(e));
  }
}

export function* getBusinessProducts(api, { businessId, offset, limit }) {
  try {
    const qs = querystring.stringify({ offset, limit });
    const response = yield call(
      [api, api.get],
      `/businesses/${businessId}/products?${qs}`,
    );
    yield put(getBusinessProductsSuccess(response));
  } catch (e) {
    yield put(getBusinessProductsFailure(e));
  }
}
export function* getBusinessDetail(api, businessId) {
  try {
    const response = yield call([api, api.get], `/businesses/${businessId}`);
    yield put(getBusinessDetailSuccess(response));
  } catch (e) {
    yield put(getBusinessDetailFailure(e));
  }
}

export function* getProductDetail(api, productId) {
  try {
    const response = yield call([api, api.get], `/products/${productId}`);
    yield put(getProductDetailSuccess(response));
  } catch (e) {
    yield put(getProductDetailFailure(e));
  }
}

export function* createAnOrder(api, payload) {
  try {
    const response = yield call([api, api.post], '/orders', payload);
    yield put(createAnOrderSuccess(response));
    yield put(getCurrentUserRequest());
  } catch (e) {
    yield put(createAnOrderFailure(e));
  }
}

export function* archiveOrder(api, orderId) {
  try {
    const response = yield call([api, api.post], `/orders/${orderId}/archive`);
    yield put(archiveAnOrderSuccess(response));
  } catch (e) {
    yield put(archiveAnOrderFailure(e));
  }
}

export function* getOrdersOfBusiness(api, params) {
  try {
    const qs = querystring.stringify(
      omit(params, ['businessId', 'isLoadMore']),
    );
    const response = yield call(
      [api, api.get],
      `/businesses/${params.businessId}/orders?${qs}`,
    );
    yield put(
      getOrdersOfBusinessSuccess({
        ...response,
        isLoadMore: params.isLoadMore,
      }),
    );
  } catch (e) {
    yield put(getOrdersOfBusinessFailure(e));
  }
}

export function* getMyOrders(api) {
  try {
    const response = yield call([api, api.get], '/orders');
    yield put(getMyOrdersSuccess(response));
  } catch (e) {
    yield put(getMyOrdersFailure(e));
  }
}

export function* getAllProducts(api, params) {
  try {
    const qs = querystring.stringify(omit(params, 'isLoadMore'));
    const response = yield call([api, api.get], `/products?${qs}`);
    yield put(
      getAllProductsSuccess({ ...response, isLoadMore: params.isLoadMore }),
    );
  } catch (e) {
    yield put(getAllProductsFailure(e));
  }
}

export function* watchGetMyBusinesses(api) {
  while (true) {
    const { payload } = yield take(GET_MY_BUSINESSES);
    yield call(getMyBusinesses, api, payload);
  }
}

export function* watchGetBusinessProducts(api) {
  while (true) {
    const { payload } = yield take(GET_BUSINESS_PRODUCTS);
    yield call(getBusinessProducts, api, payload);
  }
}

export function* watchGetBusinessDetail(api) {
  while (true) {
    const { payload } = yield take(GET_BUSINESS_DETAIL);
    yield call(getBusinessDetail, api, payload);
  }
}

export function* watchGetProductDetail(api) {
  while (true) {
    const { payload } = yield take(GET_PRODUCT_DETAIL);
    yield call(getProductDetail, api, payload);
  }
}

export function* watchCreateAnOrder(api) {
  while (true) {
    const { payload } = yield take(CREATE_AN_ORDER);
    yield call(createAnOrder, api, payload);
  }
}

export function* watchGetOrdersOfBusiness(api) {
  while (true) {
    const { payload } = yield take(GET_ORDERS_OF_BUSINESS);
    yield call(getOrdersOfBusiness, api, payload);
  }
}

export function* watchGetMyOrders(api) {
  while (true) {
    yield take(GET_MY_ORDERS);
    yield call(getMyOrders, api);
  }
}

export function* watchArchiveOrder(api) {
  while (true) {
    const { payload } = yield take(ARCHIVE_ORDER);
    yield call(archiveOrder, api, payload);
  }
}

export function* watchGetAllProducts(api) {
  while (true) {
    const { payload } = yield take(GET_ALL_PRODUCTS);
    yield call(getAllProducts, api, payload);
  }
}

export default function* ({ api }) {
  yield fork(watchGetMyBusinesses, api);
  yield fork(watchGetBusinessProducts, api);
  yield fork(watchGetBusinessDetail, api);
  yield fork(watchGetProductDetail, api);
  yield fork(watchCreateAnOrder, api);
  yield fork(watchGetOrdersOfBusiness, api);
  yield fork(watchGetMyOrders, api);
  yield fork(watchArchiveOrder, api);
  yield fork(watchGetAllProducts, api);
}

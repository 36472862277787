import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { fromHedgies } from 'store/selectors';
import { StyledTextBtn } from 'styles/main';
import { syncMyHedgiesRequest } from 'store/actions';
import { syncApp } from './squareLinkUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import useLockBodyScroll from '../../custom-hooks/useLockBodyScroll';
import squareLinkLogo from './squarelink_logo.png';
import metaMaskLogo from './metamask-logo.png';
import ConnectWallet from './ConnectWallet';

const StyeldWrapper = styled.div``;

const StyledBackdrop = styled.div`
  background: rgba(255, 255, 255, 0.78);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModal = styled.div`
  background: #ffffff;
  box-shadow: 1px 2px 2px 1px #c2c2c2;
  width: 574px;
  height: auto;
`;

const StyledGroupLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px;
`;

const StyledDivider = styled.div`
  background-color: #d8d8d8;
  width: 1px;
  height: 150px;
`;

const StyledCloseBtn = styled.div`
  padding: 5px 6px 0 0;
  text-align: right;
  cursor: pointer;
`;

const StyledError = styled.div`
  color: red;
  padding: 5px;
  text-align: center;
`;

function EthWallet({ metaMask, squareLink }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);

  useLockBodyScroll(openModal);

  const syncHedgiesLoading = useSelector(state =>
    fromHedgies.syncHedgiesLoading(state),
  );

  const doneSyncHedgies = useSelector(state =>
    fromHedgies.syncHedgiesSuccess(state),
  );

  const walletAddress = useSelector(state =>
    fromHedgies.getWalletAddress(state),
  );

  const errorSyncingHedgies = useSelector(state =>
    fromHedgies.errorSyncingHedgies(state),
  );
  const walletTyle = useSelector(state => fromHedgies.getWalletTyle(state));

  const handleConnectSquareLink = React.useCallback(() => {
    syncApp().then(res => {
      dispatch(
        syncMyHedgiesRequest({
          wallet: res.address,
          signature: res.signature,
          type: 'squareLink',
        }),
      );
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (doneSyncHedgies) {
      setOpenModal(false);
    }
  }, [doneSyncHedgies]);

  return (
    <StyeldWrapper>
      <div className="align-center">
        <div style={{ textAlign: 'center' }}>
          <StyledTextBtn
            // onClick={() => (doneSyncHedgies ? null : setOpenModal(true))}
          >
            {doneSyncHedgies ? `Completed Sync with` : metaMask }
          </StyledTextBtn>
          {doneSyncHedgies && walletAddress ? <div>{walletAddress}</div> : null}
        </div>
      </div>
      {openModal ? (
        <StyledBackdrop>
          <StyledModal>
            <StyledCloseBtn onClick={() => setOpenModal(false)}>
              <FontAwesomeIcon icon={faTimes} color="#62295e" />
            </StyledCloseBtn>
            <div>
              <Typography text="Please connect to continue:" />
              <StyledGroupLogo>
                <ConnectWallet
                  text="Recommended wallet:"
                  logoUrl={squareLinkLogo}
                  loading={syncHedgiesLoading && walletTyle === 'squareLink'}
                  onClick={handleConnectSquareLink}
                />
                <StyledDivider />
                <ConnectWallet
                  text="For crypto-enthusiasts:"
                  logoUrl={metaMaskLogo}
                  node={metaMask}
                />
              </StyledGroupLogo>
              {errorSyncingHedgies ? (
                <StyledError>{errorSyncingHedgies.message}</StyledError>
              ) : null}
            </div>
          </StyledModal>
        </StyledBackdrop>
      ) : null}
    </StyeldWrapper>
  );
}

EthWallet.propTypes = {
  metaMask: PropTypes.element,
};

EthWallet.defaultProps = {
  metaMask: null,
};

export default EthWallet;

import React from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';

const errorElement = (className, text) => (
  <Row>
    <Col>
      <p
        className={classnames(
          `booking-error form-error ${className} color-error`
        )}
      >
        {text}
      </p>
    </Col>
  </Row>
);

export const FormFieldError = ({ error, field }) => {
  if (error && error.fields && error.fields[field]) {
    return errorElement('form-field-error', error.fields[field]);
  }
  return null;
};

FormFieldError.propTypes = {
  error: types.error,
  field: PropTypes.string,
};

FormFieldError.defaultProps = {
  error: null,
  field: null,
};

const FormError = ({ error }) => {
  if (error && error.message) {
    return errorElement('form-form-error', error.message);
  } else if (error && error.fields && error.fields.hedgieID) {
    return <FormFieldError error={error} field="hedgieID" />;
  }
  return null;
};

FormError.propTypes = {
  error: types.error,
};

FormError.defaultProps = {
  error: null,
};

export default FormError;

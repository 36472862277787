import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import { media } from 'styles/main';
import { Col, Container, Row } from 'reactstrap';
import {
  HedgiePower,
  HedgieImg,
  HedgieOwnedStamp,
  SubHeading,
  HedgieEnergy,
  PageTitle,
  FormError,
  Level,
} from 'components';
import Hedgie from 'models/Hedgie';
import { getHedgieRequest } from 'store/hedgies/actions';
import {
  selectAHedgie,
  selectAHedgieError,
} from 'store/hedgies/cacheHedgieSelector';

const hedgieImgWidth = 350;

export const HedgieImgContainer = styled.div`
  padding-top: 60px;
  width: ${hedgieImgWidth}px;
  max-width: 100%;
  margin: auto;
  position: relative;
`;

const StyledLevel = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledContainer = styled.div`
  padding-bottom: 50px;
`;

const GroupNameAndStamp = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .my-hd-name {
    flex-grow: 49;
    width: 100%;
  }
  .my-hd-minting-status {
    flex-grow: 49;
    width: 100%;
  }
  .my-hd-divider {
    border-right: 1px solid #cad6d9;
    padding-left: 10px;
    flex-grow: 2;
  }
  ${media.tablet`
    flex-direction: column-reverse;
    .my-hd-name {
        margin-top: 30px;
        text-align: center ;
      }
    .my-hd-minting-status {
      display: flex;
      justify-content: center;
    }
  `};
  padding-top: 30px;
`;

const StyledFreeHedgie = styled.div`
  flex-grow: 48;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PublicHedgie = ({ match }) => {
  const { color } = match.params;
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getHedgieRequest(color));
      return () => {};
    },
    [color, dispatch],
  );

  const hedgie = useSelector(state => selectAHedgie(state));
  const hedgieError = useSelector(state => selectAHedgieError(state));
  const currentXp = get(hedgie, 'xp', 0);

  const currentLevel = get(hedgie, 'currentLevel', {
    level: 0,
    requiredXp: 0,
    points: 0,
  });

  const nextLevel = get(hedgie, 'nextLevel', {
    level: 0,
    requiredXp: 0,
    points: 0,
  });

  const tierInfoDescription = get(hedgie, 'tierInfo.description', '');

  return (
    <Container>
      {hedgie ? <PageTitle title={`Hedgie: #${color}`} /> : null}
      <StyledContainer>
        {hedgie ? (
          <Row>
            <Col md="6">
              <HedgieImgContainer>
                <HedgieImg
                  color={`#${hedgie.color}`}
                  className="img-fluid"
                  width={hedgieImgWidth}
                  imgURL={hedgie.imgURL}
                />
                <StyledLevel>
                  <Level
                    xp={currentXp}
                    currentLevel={currentLevel}
                    nextLevel={nextLevel}
                  />
                </StyledLevel>
              </HedgieImgContainer>
            </Col>
            <Col md="5">
              <div
                style={{
                  marginTop: '62px',
                  marginLeft: '38px',
                  margin: 'auto',
                }}
              >
                <GroupNameAndStamp>
                  <div className="my-hd-name">
                    {hedgie.name ? (
                      <SubHeading
                        className="pt-1 sub-heading-center ff-bold"
                        heading={hedgie.name}
                      />
                    ) : null}
                    <SubHeading heading={`#${hedgie.color}`} />
                  </div>
                  <div className="my-hd-divider" />
                  {Hedgie.isPurchased(hedgie) ? (
                    <div className="my-hd-minting-status">
                      {hedgie.mintingStatus === 'ready' && (
                        <SubHeading
                          className="sub-heading-center text-center ff-bold"
                          heading="Ready for Minting"
                        />
                      )}
                      {hedgie.mintingStatus === 'minting' && (
                        <SubHeading
                          className="sub-heading-center text-center ff-bold"
                          heading="Your hedgie is being minted"
                        />
                      )}
                      {hedgie.mintingStatus === 'minted' && (
                        <HedgieOwnedStamp />
                      )}
                    </div>
                  ) : (
                    <StyledFreeHedgie>
                      {hedgie.status === 3 ? (
                        <SubHeading
                          className="sub-heading-center text-center ff-bold"
                          heading="Free"
                        />
                      ) : null}
                    </StyledFreeHedgie>
                  )}
                </GroupNameAndStamp>
                <SubHeading
                  className="pt-4 sub-heading-center"
                  heading={hedgie.description || tierInfoDescription}
                />
                <HedgiePower hedgie={hedgie} className="pt-5" />
                <HedgieEnergy
                  className="pt-1"
                  color={`#${hedgie.color}`}
                  energy={hedgie.energy}
                  recovery={hedgie.energyRecovery}
                />
              </div>
            </Col>
          </Row>
        ) : null}
        {hedgieError ? <FormError error={hedgieError} /> : null}
      </StyledContainer>
    </Container>
  );
};

PublicHedgie.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(PublicHedgie);

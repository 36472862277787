import styled from 'styled-components';
import { media } from 'styles/main';

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.tablet`
    flex-direction: column;
  `}
`;

export const StyledContainer = styled.div`
  padding: 40px 20px 20px 20px;
  ${media.tablet`
    .bu-description{
      display:none;
    }
  `}
`;

export const StyledImage = styled.div`
  border-radius: 30px;
  //padding: 10px 10px;
  width: 180px;
  height: 180px;
  overflow: hidden;
`;

export const StyledGroupImgAndInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  ${media.tablet`
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `}
`;

export const StyledButton = styled.div`
  margin-left: -16px;
  margin-top: 20px;
`;

export const StyledLocation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

export const StyledWebsite = styled.div`
  margin-top: 5px;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  ${media.tablet`
    padding-top:16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  `}
`;

export const StyledProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
  grid-gap: 20px;
  align-items: start;
  padding: 40px 20px;
`;

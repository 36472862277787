import React, {
  memo,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, forEach } from 'lodash';
import { Spinner, FormError, Modal } from 'components';
import { media } from 'styles/main';
import Furniture from './Furniture';
import BuildingFurnitureModal from './BuildingFurnitureModal';
import { HouseContext } from './index';

const StyledFurniture = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-gap: 40px;
  justify-content: center;
  justify-items: center;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(161px, 161px));
  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 15px;
    padding: 0 20px;
  `}
`;

function getUpgradeToObj(furniture) {
  const rs = [];
  if (isEmpty(furniture)) {
    return rs;
  }
  forEach(furniture, value => {
    if (value.upgradeTo) {
      rs.push({
        ...value.upgradeTo,
        currentId: value.id,
        currentLevel: value.level,
        currentEffectValue: value.effectValue,
      });
    }
  });
  return rs;
}

const UpgradingFurniture = memo(({ furniture, houseId, buildFurniture }) => {
  const [modal, setModal] = useState({
    open: false,
    selectedFurniture: null,
    houseId: '',
  });

  const { data, loading, error } = furniture;

  const { upgradedFurniture, clearBuildingFurnitureState } = useContext(
    HouseContext,
  );

  const closeModal = useCallback(() => {
    setModal({
      open: false,
      ...modal,
    });
    clearBuildingFurnitureState();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (upgradedFurniture.hasBuilt) {
      setTimeout(() => closeModal(), 600);
    }
  }, [closeModal, upgradedFurniture.hasBuilt]);

  if (loading) {
    return <Spinner noFixed />;
  }

  function selectFurniture(selectedFurniture, houseId) {
    setModal({
      open: true,
      selectedFurniture,
      houseId,
    });
  }

  function handleBuild(selectedFurniture, houseId) {
    setModal({
      ...modal,
      selectedFurniture,
      houseId,
    });
    buildFurniture({ houseId, furnitureId: selectedFurniture.currentId });
  }

  if (loading) {
    return <Spinner noFixed />;
  }

  if (error.error) {
    return (
      <div className="align-center">
        <FormError error={error} />
      </div>
    );
  }

  const furnitureNeedToUpgrade = getUpgradeToObj(data);

  if (isEmpty(furnitureNeedToUpgrade)) return null;

  return (
    <div>
      <StyledFurniture length={6}>
        {furnitureNeedToUpgrade.map(furniture => (
          <Furniture
            houseId={houseId}
            key={furniture.id}
            furniture={furniture}
            buildFurniture={handleBuild}
            selectFurniture={selectFurniture}
            loading={
              modal.selectedFurniture &&
              modal.selectedFurniture.type === furniture.type
                ? upgradedFurniture.loading
                : false
            }
            error={
              modal.selectedFurniture &&
              modal.selectedFurniture.type === furniture.type
                ? upgradedFurniture.error
                : null
            }
          />
        ))}
      </StyledFurniture>
      {modal.open ? (
        <Modal onClose={closeModal} modalWidth="450px">
          <BuildingFurnitureModal
            {...modal}
            {...upgradedFurniture}
            buildFurniture={handleBuild}
          />
        </Modal>
      ) : null}
    </div>
  );
});

UpgradingFurniture.propTypes = {
  buildFurniture: PropTypes.func,
  houseId: PropTypes.string.isRequired,
  furniture: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
  }),
};

UpgradingFurniture.defaultProps = {
  buildFurniture: null,
  furniture: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
};

export default UpgradingFurniture;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { SIGNIN } from 'config/paths';
import { Spinner } from 'components';

const PrivateRoute = ({
  component: Component,
  authenticated,
  userIsReady,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (authenticated) {
          return userIsReady ? (
            <Component {...props} />
          ) : (
            <div style={{ margin: '50px auto' }}>
              <Spinner noFixed />
            </div>
          );
        }
        return (
          <Redirect
            to={{
              pathname: SIGNIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  userIsReady: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
  location: null,
};

export default PrivateRoute;

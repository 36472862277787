import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { MetaMask, Button } from 'components';
import { fromHedgies } from 'store/selectors';
import { syncMyHedgiesRequest } from 'store/actions';
import MetaMaskUtil from 'services/metamaskutil';
// import Log from 'services/log';

const HedgieMetaMask = ({ metaMaskEnabled }) => {
  const dispatch = useDispatch();
  // const [queryingMetaMask, setQueryingMetaMask] = useState(false);

  // const user = useSelector(state => state.session.user);

  const syncHedgiesLoading = useSelector(state =>
    fromHedgies.syncHedgiesLoading(state),
  );

  // const errorUpdatingWallet = useSelector(state =>
  //   fromUser.errorUpdatingWallet(state),
  // );
  // const errorSyncingHedgies = useSelector(state =>
  //   fromHedgies.errorSyncingHedgies(state),
  // );
  // const syncHedgiesSuccess = useSelector(state =>
  //   fromHedgies.syncHedgiesSuccess(state),
  // );

  const walletTyle = useSelector(state => fromHedgies.getWalletTyle(state));

  // const onUpdateUserWallet = useCallback(
  //   params => {
  //     dispatch(updateUserWalletRequest(params));
  //   },
  //   [dispatch],
  // );

  const onSyncHedgies = useCallback(
    params => {
      dispatch(syncMyHedgiesRequest(params));
    },
    [dispatch],
  );

  // const handleUpdateWallet = useCallback(
  //   e => {
  //     e.preventDefault();
  //     setQueryingMetaMask(true);

  //     MetaMaskUtil.getAddressAndSignature()
  //       .then(({ address, signature }) => {
  //         onUpdateUserWallet({ wallet: address, signature });
  //       })
  //       .catch(err => {
  //         Log.error(
  //           'handleUpdateWallet:getAddressAndSignature',
  //           `error fetching account ${err}`,
  //         );
  //       })
  //       .finally(() => {
  //         setQueryingMetaMask(false);
  //       });
  //   },
  //   [onUpdateUserWallet],
  // );

  const handleSyncHedgies = useCallback(
    e => {
      e.preventDefault();
      // setQueryingMetaMask(true);

      MetaMaskUtil.getAddressAndSignature()
        .then(({ address, signature }) => {
          onSyncHedgies({ wallet: address, signature, type: 'metaMask' });
        })
        .catch(err => {
          // setQueryingMetaMask(false);
        });
    },
    [onSyncHedgies],
  );

  // const userHasWallet = useMemo(() => user && user.address, [user]);

  // let metamaskContent = <p>MetaMask is ready</p>;

  // if (!userHasWallet) {
  //   metamaskContent = (
  //     <div>
  //       <Button onClick={handleUpdateWallet} disabled={queryingMetaMask}>
  //         Connect
  //       </Button>
  //       {errorUpdatingWallet && (
  //         <div className="error-block">
  //           <p>There was an error updating your wallet</p>
  //           <FormError error={errorUpdatingWallet} className="mt-0 pt-0" />
  //         </div>
  //       )}
  //     </div>
  //   );
  // } else {
  //   let buttonText = 'Connect';
  //   if (syncHedgiesLoading && walletTyle === 'metaMask') {
  //     buttonText = 'Syncing...';
  //   }
  //   if (!syncHedgiesSuccess) {
  //     metamaskContent = (
  //       <div>
  //         <Button
  //           size="large"
  //           onClick={handleSyncHedgies}
  //           disabled={queryingMetaMask || syncHedgiesLoading}
  //         >
  //           {buttonText}
  //         </Button>
  //         <SubHeading
  //           className="pt-3 sub-heading-center text-center"
  //           heading={user.address}
  //         />
  //         {errorSyncingHedgies && (
  //           <div className="error-block">
  //             <p>There was an error updating your wallet</p>
  //             <FormError error={errorSyncingHedgies} className="mt-0 pt-0" />
  //           </div>
  //         )}
  //       </div>
  //     );
  //   } else {
  //     metamaskContent = (
  //       <div>
  //         <p>Completed Sync with MetaMask</p>
  //       </div>
  //     );
  //   }
  // }
  return (
    <div>
      {metaMaskEnabled ? (
        <Row>
          <Col>
            <div className="myhedgies-metamask text-center mt-1">
              <MetaMask>
                <Button
                  onClick={handleSyncHedgies}
                  disabled={syncHedgiesLoading && walletTyle === 'metaMask'}
                >
                  {syncHedgiesLoading && walletTyle === 'metaMask'
                    ? 'Syncing...'
                    : 'Connect'}
                </Button>
              </MetaMask>
            </div>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

HedgieMetaMask.propTypes = {
  metaMaskEnabled: PropTypes.bool,
};

HedgieMetaMask.defaultProps = {
  metaMaskEnabled: false,
};

export default HedgieMetaMask;

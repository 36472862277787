import React from 'react';
import PropTypes from 'prop-types';
import * as types from 'types';

import MetaMaskPurchaseForm from './MetaMaskPurchaseForm';

class MetaMaskPurchase extends React.Component {
  render() {
    return (
      <MetaMaskPurchaseForm {...this.props} />
    );
  }
}

MetaMaskPurchase.propTypes = {
  onCompleteEtherBuyNow: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
  price: PropTypes.string.isRequired,
};

MetaMaskPurchase.defaultProps = {
  error: null,
  working: false,
};

export default MetaMaskPurchase;

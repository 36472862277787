import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import { firebase as firebaseConfig } from 'config/env';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const isSupportedNotification = firebase.messaging.isSupported();

let messaging = null;

if (isSupportedNotification) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(firebaseConfig.publicVapidKey);
}

export { auth, messaging, isSupportedNotification };

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as types from 'types';
import * as paths from 'config/paths';
import { SignUpForm, PageTitle, SideHedgie } from 'components';
import useReferralCode from '../../custom-hooks/useReferralCode';

const SignUpPage = ({
  signUp,
  signUpSuccess,
  onBoardingWorldId,
  location,
  history,
  error,
  working,
}) => {
  const { referralCode, removeReferralCodeFromLocalStorage } = useReferralCode(
    location.search,
  );

  useEffect(() => {
    if (signUpSuccess) {
      const pathname = onBoardingWorldId ? paths.MY_JOURNEY : paths.MY_HEDGIES;

      const { from } = location.state || {
        from: { pathname },
      };
      history.push(from);
    }
    return () => {
      if (signUpSuccess) {
        removeReferralCodeFromLocalStorage();
      }
    };
  }, [
    history,
    location.state,
    onBoardingWorldId,
    removeReferralCodeFromLocalStorage,
    signUpSuccess,
  ]);

  function handleSignUp(
    email,
    username,
    password,
    tocAccepted,
    caslAccepted,
    referralCode,
    recaptcha,
  ) {
    signUp(
      email,
      username,
      password,
      tocAccepted,
      caslAccepted,
      referralCode,
      recaptcha,
    );
  }

  return (
    <div>
      <main>
        <Container fluid className="pos-rel sign-up-wrap">
          <Container>
            <Row>
              <Col>
                <PageTitle title="Create Account" />
              </Col>
            </Row>
            <Row>
              <Col>
                <SignUpForm
                  referralCode={referralCode}
                  onSignUp={handleSignUp}
                  {...{ error, working }}
                />
              </Col>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-5" />
                  <div className="col-md-7" />
                </div>
              </div>
            </Row>
          </Container>
          <SideHedgie
            className="side-hedgie-signup"
            title="Welcome!"
            color="green"
          />
        </Container>
      </main>
    </div>
  );
};

SignUpPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  signUpFailed: PropTypes.bool,
  signUpSuccess: PropTypes.bool,
  signUp: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
  onBoardingWorldId: PropTypes.string,
};

SignUpPage.defaultProps = {
  signUpFailed: false,
  signUpSuccess: false,
  error: null,
  onBoardingWorldId: null,
  working: false,
};

export default SignUpPage;

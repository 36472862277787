import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components';
import { getNotificationLink } from 'helper';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { media } from 'styles/main';
// import { domain } from 'config/env';

const StyledContainer = styled.div`
  padding: 10px;
  ${media.tablet`
    padding: 10px 0;
  `};
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 50px;
  justify-content: space-between;
  grid-gap: 8px;
  .ms-actions {
    display: grid;
    grid-template-columns: 20px 20px;
    grid-gap: 5px;
    align-items: center;
    .close-msg {
      visibility: hidden;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  ${media.largeDesktop`
    &:hover {
      background-color: #eff2f3;
      border-radius: 5px;
      .close-msg {
        visibility: visible;
      }
    }
  `};
`;

const StyledButtonMarkAsRead = styled.span`
  display: flex;
  cursor: pointer;
  margin-top: 2px;
  &.new {
    background-color: #12dfc0;
    border: 2px solid #ebecf0;
  }
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #ebecf0;
  border: 2px solid transparent;
`;

const StyledMessageTitle = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: ${props => (props.read ? 400 : 700)};
  color: ${props => (props.read ? '#62295E' : '#000')};
`;

const styledCloseIcon = {
  width: 20,
  color: '#798C93',
};

const Message = ({ message, markAsRead, archive, history, onCloseDrawer }) => {
  const markAsReadCallback = useCallback(
    () => {
      markAsRead(message.id);
    },
    [markAsRead, message.id],
  );

  const archiveCallback = useCallback(
    () => {
      archive({
        notificationId: message.id,
        haveNotSeen: message.status === 'new',
      });
    },
    [archive, message.id, message.status],
  );

  const gotoPageCallback = useCallback(
    () => {
      if (message.status !== 'read') {
        markAsRead(message.id);
      }
      const paths = getNotificationLink(message.href);
      onCloseDrawer();
      history.push(`/${paths}`);
    },
    [
      history,
      markAsRead,
      message.href,
      message.id,
      message.status,
      onCloseDrawer,
    ],
  );

  return (
    <StyledContainer>
      <div onClick={gotoPageCallback}>
        <StyledMessageTitle read={message.status === 'read'}>
          {message.title}
        </StyledMessageTitle>
        <Typography
          text={message.body}
          fontSize={13}
          align="left"
          color="#798C93"
          className="pt-1"
        />
      </div>
      <div className="ms-actions">
        <FontAwesomeIcon
          icon={faTimes}
          style={styledCloseIcon}
          onClick={archiveCallback}
          className="close-msg"
        />
        <StyledButtonMarkAsRead
          className={message.status === 'read' ? '' : 'new'}
          onClick={message.status === 'read' ? null : markAsReadCallback}
        />
      </div>
    </StyledContainer>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  markAsRead: PropTypes.func,
  archive: PropTypes.func,
  seen: PropTypes.bool,
  history: PropTypes.object,
  onCloseDrawer: PropTypes.func,
};

Message.defaultProps = {
  seen: false,
  markAsRead: null,
  archive: null,
  history: null,
  onCloseDrawer: null,
};

export default Message;

import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import rays from '../images/rays.svg';
import ParallaxMouseHover from '../ParallaxMouseHover';
import house from '../images/house.png';
import furniture from '../images/furniture.png';
import SweetHome from './SweetHome';

const HedgieHouseSlide = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer
        offset={5}
        speed={0}
        style={{
          backgroundImage: `url(${rays})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: isMobile ? '0 0' : 'right top',
        }}
      />
      <ParallaxLayer offset={isMobile ? 5.3 : 5.0} speed={isMobile ? 0.2 : 0.2}>
        <ParallaxMouseHover>
          <img
            src={house}
            style={{
              width: isMobile ? '90%' : '44%',
              marginLeft: isMobile ? '5%' : '40%',
            }}
            alt="hedgie-house"
          />
        </ParallaxMouseHover>
      </ParallaxLayer>
      {isMobile ? null : (
        <ParallaxLayer offset={5.05} speed={-0.05}>
          <ParallaxMouseHover>
            <img
              src={furniture}
              style={{
                width: '15%',
                marginLeft: '10%',
              }}
              alt="hedgie-house"
            />
          </ParallaxMouseHover>
        </ParallaxLayer>
      )}

      <ParallaxLayer offset={5} speed={0} key="SweetHome">
        <SweetHome isMobile={isMobile} onNextPage={onNextPage} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

HedgieHouseSlide.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default HedgieHouseSlide;

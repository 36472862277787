import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';

const theme = {
  // container
  container: {
    background: 'rgba(255, 255, 255, 0.9)',
  },

  // arrows
  arrow: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    fill: '#E1B652',
    opacity: 1,
    transition: 'opacity 200ms',
    outline: 'none !important',
    ':hover': {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    borderRadius: 40,
    height: 40,
    marginTop: -20,
    transform: 'translateY(-50%)',
    '@media (min-width: 768px)': {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 10 },
  arrow__direction__right: { marginRight: 10 },
  close: {
    fill: '#62295E',
    opacity: 1,
    transition: 'all 200ms',
    ':hover': {
      opacity: 1,
    },
  },

  // footer
  footer: {
    color: 'black',
  },
  footerCount: {
    color: 'rgba(0, 0, 0, 0.6)',
  },

  // thumbnails
  thumbnail: {},
  thumbnail__active: {
    boxShadow: '0 0 0 2px #00E0C0',
  },
};

const PhotoGallery = ({ isOpen, onClose, images, thumbnail }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const gotoNext = useCallback(() => {
    setCurrentImage(current => current + 1);
  }, []);

  const gotoPrevious = useCallback(() => {
    setCurrentImage(current => current - 1);
  }, []);

  const gotoImage = useCallback(index => {
    setCurrentImage(index);
  }, []);

  const handleClickImage = useCallback(
    () => {
      if (currentImage === images.length - 1) return;
      gotoNext();
    },
    [currentImage, gotoNext, images.length],
  );

  return (
    <Lightbox
      images={images}
      thumbnail={thumbnail}
      currentImage={currentImage}
      showThumbnails
      isOpen={isOpen}
      onClickPrev={gotoPrevious}
      onClickNext={gotoNext}
      onClickThumbnail={gotoImage}
      onClickImage={handleClickImage}
      onClose={onClose}
      theme={theme}
    />
  );
};

PhotoGallery.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  images: PropTypes.array,
  thumbnail: PropTypes.array,
};

PhotoGallery.defaultProps = {
  isOpen: false,
  onClose: null,
  images: [],
  thumbnail: [],
};

export default PhotoGallery;

import { BigNumber } from 'bignumber.js';

class NumberUtil {
  static minusTwoNumber(x, y) {
    const bigNumberX = new BigNumber(x);
    const bigNumberY = new BigNumber(y);
    return this.toDecimal(bigNumberX.minus(bigNumberY));
  }

  static minusTwoBigNumber(x, y) {
    const bigNumberX = new BigNumber(x);
    const bigNumberY = new BigNumber(y);
    return bigNumberX.minus(bigNumberY).toString();
  }

  static addTwoBigNumber(x, y) {
    const bigNumberX = new BigNumber(x);
    const bigNumberY = new BigNumber(y);
    return bigNumberX.plus(bigNumberY).toString();
  }

  static toDecimal(value) {
    const a = new BigNumber(value);
    return a
      .shiftedBy(-18)
      .decimalPlaces(2)
      .toFixed(2)
      .toString();
  }

  static convertCuritoToWEI(value) {
    const a = new BigNumber(value);
    return a.shiftedBy(18).toString(10);
  }

  static checkXGreaterThanY(x, y) {
    const bigNumberX = new BigNumber(x);
    const bigNumberY = new BigNumber(y);
    return bigNumberX.isGreaterThan(bigNumberY);
  }
}

export default NumberUtil;

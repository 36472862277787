import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledArrow = styled.div`
  transform: ${props =>
    props.direction === 'left' ? 'rotate(0deg)' : 'rotate(180deg)'};
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
`;

const DEFAULT_COLOR = 'rgba(0,0,0,.1)';

const ArrowSvg = ({ direction, isActive, width, height }) => {
  const activeColor = isActive ? '#EABE4D' : DEFAULT_COLOR;
  return (
    <StyledArrow direction={direction} width={width} height={height}>
      <svg viewBox="0 0 16 25" version="1.1">
        <g
          id="Game-UI"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="1"
        >
          <g
            id="Journeys-Mobile---New---Details"
            transform="translate(-37.000000, -569.000000)"
            fill={activeColor}
          >
            <polygon
              id="Fill-1"
              transform="translate(45.205750, 581.360765) scale(-1, 1) translate(-45.205750, -581.360765) "
              points="40.8875 569.000051 37.4115 572.54699 46.049 581.360255 37.4115 590.174541 40.8875 593.72148 53 581.361276"
            />
          </g>
        </g>
      </svg>
    </StyledArrow>
  );
};

ArrowSvg.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  isActive: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowSvg.defaultProps = {
  direction: 'left',
  isActive: false,
  width: 16,
  height: 25,
};

export default ArrowSvg;

import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import * as paths from 'config/paths';
import { PrivateRoute } from 'components';

const About = lazy(() => import('../../containers/About'));
const Booking = lazy(() => import('../../containers/Booking'));
const RussianLandingPage = lazy(() =>
  import('../../containers/RussianLandingPage'),
);
const SignIn = lazy(() => import('../../containers/SignIn'));
const SignOut = lazy(() => import('../../containers/SignOut'));
const SignUp = lazy(() => import('../../containers/SignUp'));
const ResetPassword = lazy(() => import('../../containers/ResetPassword'));
const ForgotPassword = lazy(() => import('../../containers/ForgotPassword'));
const Terms = lazy(() => import('../../containers/Terms'));
const OurTeam = lazy(() => import('../../containers/OurTeam'));
const Privacy = lazy(() => import('../../containers/Privacy'));
const Faq = lazy(() => import('../../containers/Faq'));
const GiftCode = lazy(() => import('../../containers/GiftCode'));
const Settings = lazy(() => import('../../containers/Settings'));
// const MyHedgies = lazy(() => import('../../containers/MyHedgies'));
const OurPartners = lazy(() => import('../../containers/OurPartners'));
const Business = lazy(() => import('../../containers/Business'));
const PartnerWithHedgie = lazy(() =>
  import('../../containers/PartnerWithHedgie'),
);
const PremiumHedgies = lazy(() => import('../../containers/PremiumHedgies'));
const Roadmap = lazy(() => import('../../containers/Roadmap'));
const GiftCodeThankYou = lazy(() =>
  import('../../containers/GiftCodeThankYou'),
);
const ForgotPasswordThankyou = lazy(() =>
  import('../../containers/ForgotPasswordThankyou'),
);
const BookingThankYou = lazy(() => import('../../containers/BookingThankYou'));
const NotFound = lazy(() => import('../../containers/NotFound'));
const MyJourneys = lazy(() => import('../../containers/MyJourneys'));
const Home = lazy(() => import('../../components/pages/HomePage'));
 const GettingHedgie = lazy(() => import('../../containers/GettingHedgie'));
const JourneyFlow = lazy(() => import('../../containers/JourneyFlow'));
const JourneySelectHedgie = lazy(() =>
  import('../../containers/JourneySelectHedgie'),
);

const Inventory = lazy(() => import('../../containers/Inventory'));
const MyHouse = lazy(() => import('../../containers/MyHouse'));
const Businesses = lazy(() => import('../../containers/Businesses'));
const BusinessDetail = lazy(() => import('../../containers/BusinessDetail'));
const ProductOrderConfirmation = lazy(() =>
  import('../../containers/ProductOrderConfirmation'),
);
const Transactions = lazy(() => import('../../containers/Transactions'));
const BusinessOwnerOrders = lazy(() =>
  import('../../containers/BusinessOwnerOrders'),
);
const AboutPage = lazy(() => import('../../components/pages/AboutPage'));
const Marketplace = lazy(() =>
  import('../../components/pages/MarketplacePage'),
);
const ProductDetail = lazy(() =>
  import('../../components/pages/ProductDetailPage'),
);

const PublicHedgie = lazy(() => import('../../components/pages/PublicHedgie'));

const NewMyHedgiesPage = lazy(() =>
  import('../../components/pages/NewMyHedgiesPage'),
);

const LeaderBoard = lazy(() =>
  import('../../components/pages/LeaderBoardPage'),
);

const Battle = lazy(() => import('../../components/pages/Battle'));
const BattleDetail = lazy(() => import('../../containers/BattleDetail'));

const Routes = ({ userIsReady, authenticated }) => {
  return (
    <Switch>
      <Route exact path={paths.HOME} component={Home} />
      <Route
        exact
        component={RussianLandingPage}
        path={paths.RUSSIAN_LANDING_PAGE}
      />
       <Route path={paths.GET_HEDGIE} component={GettingHedgie} /> 
      <PrivateRoute
        path={paths.BOOKING}
        component={Booking}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.MY_HEDGIES}
        component={NewMyHedgiesPage}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.SETTINGS}
        component={Settings}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.MY_JOURNEY}
        component={MyJourneys}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <Route
        path={paths.ALL_BUSINESSES}
        exact
        component={Businesses}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.BUSINESS_DETAIL}
        component={BusinessDetail}
        userIsReady={userIsReady}
        exact
        authenticated={authenticated}
      />
      <Route
        path={paths.ORDER_PRODUCT_CONFIRMATION}
        component={ProductOrderConfirmation}
      />
      <PrivateRoute
        path={paths.JOURNEY}
        component={JourneyFlow}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.SELECT_HEDGIE}
        component={JourneySelectHedgie}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.INVENTORY}
        component={Inventory}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.HEDGIE_HOUSE}
        component={MyHouse}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.TRANSACTIONS}
        component={Transactions}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.BUSINESS_ORDERS}
        component={BusinessOwnerOrders}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.BATTLE}
        component={Battle}
        userIsReady={userIsReady}
        authenticated={authenticated}
      />
      <PrivateRoute
        path={paths.BATTLE_DETAIL}
        component={BattleDetail}
        userIsReady={userIsReady}
        exact
        authenticated={authenticated}
      />
      <Route path={paths.MARKETPLACE} component={Marketplace} />
      <Route path={paths.LEADER_BOARD} component={LeaderBoard} />
      <Route path={paths.PRODUCT_DETAIL} component={ProductDetail} exact />
      <Route path={paths.SIGNIN} component={SignIn} />
      <Route path={paths.SIGNOUT} component={SignOut} />
      <Route path={paths.SIGNUP} component={SignUp} />
      <Route path={paths.ABOUT_PAGE} component={AboutPage} />
      <Route path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={paths.RESET_PASSWORD} component={ResetPassword} />
      <Route path={paths.TERMS_OF_USE} component={Terms} />
      <Route path={paths.OUR_TEAM_PAGE} component={OurTeam} />
      <Route path={paths.PRIVACY_PAGE} component={Privacy} />
      <Route path={paths.FAQ} component={Faq} />
      <Route path={paths.ABOUT_PAGE} component={About} />
      <Route path={paths.GIFT_CODE} component={GiftCode} />
      <Route path={paths.PARTNERS} component={OurPartners} />
      <Route path={paths.SPEND_CURIO} component={Business} />
      <Route path={paths.BUSINESSES} component={PartnerWithHedgie} />
      <Route path={paths.PUBLIC_HEDGIE} component={PublicHedgie} />
      <Route path={paths.PREMIUM_HEDGIES} component={PremiumHedgies} />
      <Route path={paths.ROADMAP} component={Roadmap} />
      <Route path={paths.GIFT_CODE_THANK_YOU} component={GiftCodeThankYou} />
      <Route
        path={paths.FORGOT_PASSWORD_THANK_YOU}
        component={ForgotPasswordThankyou}
      />
      <Route path={paths.BOOKING_THANK_YOU} component={BookingThankYou} />
      <Route
        path="/airdrop"
        component={() => {
          window.location = 'https://airdrop.hedgie.io';
          return null;
        }}
      />
      <Route
        path="/signupthanks"
        component={() => {
          window.location = 'https://signupthanks.hedgie.io';
          return null;
        }}
      />
      <Route
        path="/purchasethanks"
        component={() => {
          window.location = 'https://purchasethanks.hedgie.io';
          return null;
        }}
      />
      <Route
        path="/purchase"
        component={() => {
          window.location = 'https://purchase.hedgie.io';
          return null;
        }}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Routes.propTypes = {
  userIsReady: PropTypes.bool,
  authenticated: PropTypes.bool,
};

Routes.defaultProps = {
  userIsReady: false,
  authenticated: false,
};

export default Routes;

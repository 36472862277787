import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components';
import styled from '@xstyled/styled-components';
import AnnouncementContent from './AnnouncementContent';

const StyledWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const AnnouncementCard = ({ href, bannerURL, openHref, ...props }) => {
  const openLink = useCallback(
    () => {
      openHref(href, props.id);
    },
    [href, openHref, props.id],
  );
  return (
    <StyledWrapper>
      <Card
        imgUrl={bannerURL}
        content={<AnnouncementContent {...props} />}
        onClick={openLink}
      />
    </StyledWrapper>
  );
};

AnnouncementCard.propTypes = {
  href: PropTypes.string.isRequired,
  bannerURL: PropTypes.string.isRequired,
  openHref: PropTypes.func,
};

AnnouncementCard.defaultProps = {
  openHref: null,
};

export default React.memo(AnnouncementCard);

import React from 'react';
import styled from 'styled-components';
import useCountDown from '../../custom-hooks/useCountDown';

const StyledCircle = styled.div`
  border: 3px solid #12dfc0;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 80px;
    font-weight: 200;
    color: #12dfc0;
    padding-bottom: 14px;
  }
`;

const BattleCountdown = ({ onFinish, count }) => {
  const { time } = useCountDown(count || 5);

  React.useEffect(() => {
    if (time === 0 && onFinish) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [time]);

  return (
    <StyledCircle>
      <span>{time}</span>
    </StyledCircle>
  );
};

export default BattleCountdown;

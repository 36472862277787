import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import * as types from 'types';
import * as paths from 'config/paths';
import { Desktop } from 'components';
import BlueResult from 'images/blue-result.png';
import styled from 'styled-components';

const StyledLink = styled.a``;

const NotFoundPage = () => {
  return (
    <div className="container-fluid row-section pos-rel overflow-hidden-reg">
      <div className="container">
        <div className="jumbotron ">
          <div>
            <h1 className="col-md-7 dark-color title mb-3 p-0">
              Looks like the page you’re looking for has moved, or doesn’t
              exist.
            </h1>
            <div className="col-md-6 px-0">
              <p className="lead ff-extra-bold mb-0">
                Sorry about that! Please go to the&nbsp;
                <Link to={paths.HOME} className="color-contrast">
                  Homepage
                </Link>
                &nbsp; to begin your journey, or use navigation to find what
                you’re looking for.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-end overflow-hidden bgr-bottom-images pt-5 mt-5">
        <div className="col-md-4 text-right hedige-bubble pt-5 mt-5" />
        <div className="col-md-7 text-right hedige-on-bgr-holder" />
      </div>
      <Desktop>
        <StyledLink
          href="#"
          className="buble-on-bgr cool-bubble btn-big ff-semi-bold fs-extra-md color-light"
          role="button"
        >
          What the...
        </StyledLink>
      </Desktop>
      <img
        src={BlueResult}
        className="img-fluid mobile-side-hedgie cool-rotate"
        alt=""
      />
    </div>
  );
};

export default NotFoundPage;

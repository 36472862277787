import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'components';
import { hedgie as hedgieType } from 'types';

const PowerRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const PowerItem = styled.div`
  flex: 1 0 20%;
  margin: 1px;
  margin-bottom: 20px;
  text-align: center;
`;

const PowerTitle = styled.h2`
  margin: 10px 0;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 800;
  color: #20041E;
`;

const PowerLabel = ({ label }) => (
  <span className="power-label ff-semi-bold">{label}</span>
);

const PerformsLabel = ({ label }) => (
  <span className="performs-label">{label}</span>
);

PowerLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const hedgiePowerValue = hedgie => {
  return attr => {
    if (!hedgie) {
      return '0';
    }
    return `${hedgie[attr]}`;
  };
};

const PowerItemContainer = ({ label, name, value }) => (
  <PowerItem key={label}>
    <PowerLabel label={label} />
    <Icon name={name} />
    <span className="option-value">{value}</span>
  </PowerItem>
);

PowerItemContainer.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PowerItemPerformsContainer = ({ label, name, performsIn, performsLabel }) => (
  <PowerItem key={label}>
    <PowerLabel label={label} />
    <Icon name={name} />
    <PerformsLabel label={`${performsLabel} ${performsIn || ''}`} />
  </PowerItem>
);

PowerItemPerformsContainer.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  performsIn: PropTypes.string,
  performsLabel: PropTypes.string,
};

PowerItemPerformsContainer.defaultProps = {
  performsLabel: 'Performs best in the',
  performsIn: null,
};

const HedgiePowerRu = props => {
  const { hedgie, showTitle, performsView } = props;
  const hedgiePowerBuilder = hedgiePowerValue(hedgie);
  let ItemContainer;
  if (performsView) {
    ItemContainer = PowerItemPerformsContainer;
  } else {
    ItemContainer = PowerItemContainer;
  }
  return (
    <div style={{ marginTop: '50px', maxWidth: '400px' }}>
      {showTitle && <PowerTitle>Power</PowerTitle>}
      <PowerRow>
        <ItemContainer label="Вода" name="powerWater" performsLabel="Более эффективен в водном мире" value={hedgiePowerBuilder('water')} />
        <ItemContainer label="Огонь" name="powerFire" performsLabel="Лучше всего работает в пустыне" value={hedgiePowerBuilder('fire')} />
        <ItemContainer label="Земля" name="powerEarth" performsLabel="Пригодится в лесу" value={hedgiePowerBuilder('earth')} />
        <ItemContainer label="Воздух" name="powerAir" performsLabel="Более необходим в горах" value={hedgiePowerBuilder('air')} />
        <ItemContainer label="Удача" name="skillLuck" performsLabel="Больше находок в произвольном путешествии" value={hedgiePowerBuilder('luck')} />
        <ItemContainer label="Осторожность" name="skillPrudence" performsLabel="Улучшает поиск и сохранность инструментов" value={hedgiePowerBuilder('water')} />
        <ItemContainer label="Интеллект" name="skillIntelligence" performsLabel="Лучше выполняет поиск съестных и не-съестных припасов" value={hedgiePowerBuilder('intelligence')} />
        <ItemContainer label="Обаяние" name="skillCharm" performsLabel="Улучшает поиск игровой монеты Кюрио" value={hedgiePowerBuilder('charm')} />
      </PowerRow>
    </div>
  );
};

HedgiePowerRu.propTypes = {
  hedgie: hedgieType,
  performsView: PropTypes.bool,
  showTitle: PropTypes.bool,
};

HedgiePowerRu.defaultProps = {
  hedgie: null,
  performsView: false,
  showTitle: true,
};


export default HedgiePowerRu;

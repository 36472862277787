import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions } = createSlice({
  name: 'leaderBoard',
  initialState: {
    list: Array(10).fill(null),
    loading: false,
    getListError: {
      error: false,
      message: '',
    },
  },
  reducers: {
    getLeaderBoard: state => {
      state.loading = true;
    },
    getLeaderBoardSuccess: (state, { payload }) => {
      state.loading = false;
      state.list = payload.data;
    },
    getLeaderBoardFailure: (state, { payload }) => {
      state.loading = false;
      state.getListError.error = true;
      state.getListError.message = payload.message;
    },
    getMyTopHedgies: state => {
      state.loading = true;
    },
    getMyTopHedgiesSuccess: (state, { payload }) => {
      state.loading = false;
      state.myTopHedgies = payload.data;
    },
    getMyTopHedgiesFailure: (state, { payload }) => {
      state.loading = false;
      state.getListError.error = true;
      state.getListError.message = payload.message;
    },
  },
});

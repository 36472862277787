import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import { HedgieImg } from 'components';
import stripeBg from '../images/stripeBg.svg';
import MeetHedgieContent from './MeetHedgieContent';

const MeetHedgie = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer offset={1} speed={0} style={{ opacity: 1, zIndex: -4 }}>
        <img
          src={stripeBg}
          style={{
            display: 'block',
            width: isMobile ? '70%' : '50%',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          alt="meet-hedgie"
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={isMobile ? 1.15 : 1.35}
        speed={isMobile ? -0.01 : 0.1}
        style={{ marginLeft: isMobile ? '50%' : '58%', zIndex: 3 }}
      >
        <HedgieImg maxWidth={isMobile ? '36%' : '24%'} color="#019BE0" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.05 : 1.2}
        speed={isMobile ? 0.1 : 0.2}
        style={{ marginLeft: isMobile ? '50%' : '60%' }}
      >
        <HedgieImg maxWidth={isMobile ? '16%' : '12%'} color="#00FFAB" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.1 : 1.3}
        speed={isMobile ? 0.1 : 0.1}
        style={{ marginLeft: isMobile ? '30%' : '52%' }}
      >
        <HedgieImg maxWidth={isMobile ? '14%' : '8%'} color="#FF2828" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.25 : 1.55}
        speed={isMobile ? -0.15 : -0.2}
        style={{ marginLeft: isMobile ? '15%' : '46%' }}
      >
        <HedgieImg maxWidth={isMobile ? '12%' : '7%'} color="#A222FF" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.04 : 1.1}
        speed={0.05}
        style={{ marginLeft: isMobile ? '38%' : '55%' }}
      >
        <HedgieImg maxWidth={isMobile ? '11%' : '7%'} color="#FF8500" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.35 : 1.8}
        speed={isMobile ? 0.2 : 0.2}
        style={{
          marginLeft: isMobile ? '90%' : '90%',
        }}
      >
        <HedgieImg maxWidth={isMobile ? '15%' : '12%'} color="#FEF641" />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isMobile ? 1.4 : 1.6}
        speed={-0.2}
        style={{ marginLeft: '88%' }}
      >
        <HedgieImg maxWidth="8%" color="#FF0B82" />
      </ParallaxLayer>
      <ParallaxLayer offset={1} speed={0.1} style={{ zIndex: 20 }}>
        <MeetHedgieContent onNextPage={onNextPage} isMobile={isMobile} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

MeetHedgie.propTypes = {
  onNextPage: PropTypes.func,
  isMobile: PropTypes.bool,
};

MeetHedgie.defaultProps = {
  onNextPage: null,
  isMobile: false,
};

export default MeetHedgie;

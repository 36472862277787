import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlainButton } from 'components';
import { staticUrl } from 'config/env';
import facebookShare from 'images/hedgie-share-facebook.png';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FacebookImage = () => (
  <img src={facebookShare} alt="Share on Facebook" width="92" />
);

// let FB;

const ShareHedgie = ({ hedgieColor }) => {
  const openFacebookShare = useCallback(() => {
    const url = `${staticUrl}/public/hedgies/${hedgieColor}/fb`;
    window.FB.ui(
      {
        method: 'share',
        href: url,
      },
      () => {},
    );
  }, [hedgieColor]);

  if (!hedgieColor) {
    return null;
  }

  return (
    <CenteredContainer>
      <PlainButton onClick={openFacebookShare} title="Share to facebook">
        <FacebookImage />
      </PlainButton>
    </CenteredContainer>
  );
};

ShareHedgie.propTypes = {
  hedgieColor: PropTypes.string,
};

ShareHedgie.defaultProps = {
  hedgieColor: '',
};

export default React.memo(ShareHedgie);

import React from 'react';
import PropTypes from 'prop-types';
import { createMarkup } from 'helper';
import styled from 'styled-components';

const StyledGroup = styled.div``;

const HtmlStringToDom = ({ htmlString }) => {
  return <StyledGroup dangerouslySetInnerHTML={createMarkup(htmlString)} />;
};

HtmlStringToDom.propTypes = {
  htmlString: PropTypes.string.isRequired,
};

export default HtmlStringToDom;

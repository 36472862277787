import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { media } from 'styles/main';
import Tab from './Tab';
import TabPanel from './TabPanel';

const StyledTabsWrapper = styled.div`
  margin-top: 30px;
`;
const StyledTabs = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.length}, 200px);
  grid-gap: 30px;
  justify-items: center;
  justify-content: center;
  ${media.tablet`
    grid-template-columns: repeat(${props => props.length}, 1fr);  
  `}
`;
const StyledTabPanels = styled.div``;

function getTabPanel(tabId, tabData) {
  if (isEmpty(tabData)) return null;
  return tabData.find(tab => tab.id === tabId);
}

const Tabs = ({ tabsData, activeTab, onTabChange }) => {
  const [tabId, setTabId] = useState(activeTab);

  const memoizedTabPanel = useMemo(() => getTabPanel(tabId, tabsData), [
    tabId,
    tabsData,
  ]);

  function onSelectTab(tabId) {
    setTabId(tabId);
    if (onTabChange) {
      onTabChange(tabId);
    }
  }

  return (
    <StyledTabsWrapper>
      <StyledTabs length={tabsData.length}>
        {tabsData.map(tab => (
          <Tab
            key={tab.id}
            active={tab.id === tabId}
            onSelect={onSelectTab}
            tab={tab}
          />
        ))}
      </StyledTabs>
      <StyledTabPanels>
        <TabPanel>{memoizedTabPanel.tabPanel}</TabPanel>
      </StyledTabPanels>
    </StyledTabsWrapper>
  );
};

Tabs.propTypes = {
  tabsData: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  onTabChange: null,
};

export default Tabs;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';

const StyledPlaceHolder = styled.div`
  background-color: #eff3f4;
  width: 100%;
  padding-bottom: ${props => `calc(100% / ${props.ratio})`};
  position: relative;
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const PhotoPlaceHolder = ({ ratio }) => {
  return (
    <StyledPlaceHolder ratio={ratio}>
      <div>
        <FontAwesomeIcon icon={faImages} size="6x" inverse />
        <span>Loading...</span>
      </div>
    </StyledPlaceHolder>
  );
};

PhotoPlaceHolder.propTypes = {
  ratio: PropTypes.number,
};

PhotoPlaceHolder.defaultProps = {
  ratio: 16 / 9,
};

export default PhotoPlaceHolder;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Typography } from 'components';

const StyledContainer = styled.div`
  text-align: center;
`;

function ConnectWallet({ text, logoUrl, onClick, node, loading }) {
  return (
    <StyledContainer>
      <Typography text={text} fontSize={12} color="#979797" />
      <div className="pt-3">
        <img src={logoUrl} alt="wallet logo" />
      </div>
      <div className="pt-5">
        {node ? (
          node
        ) : (
          <Button onClick={onClick}>
            {loading ? 'Syncing...' : 'Connect'}
          </Button>
        )}
      </div>
    </StyledContainer>
  );
}

ConnectWallet.propTypes = {
  text: PropTypes.string,
  logoUrl: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

ConnectWallet.defaultProps = {
  text: '',
  logoUrl: '',
  onClick: null,
  loading: false,
};

export default ConnectWallet;

export const netWorkMapper = {
  mainnet: {
    hex:'0x1',
    decimal: 1,
    name: 'Ethereum Main Network'
  },
  ropsten: {
    hex:'0x3',
    decimal: 3,
    name: 'Ropsten Test Network'
  }
}

export const NET_WORK = process.env.REACT_APP_METAMASK_NET_WORK || 'ropsten';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, Events } from 'react-scroll';
import withResponsive from '../../../helper/withResponsive';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
  margin-bottom: ${props => (props.isMobile ? 0 : '46px')};
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
`;

const StyledContainer = styled.div`
  max-width: 540px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: stretch;
  justify-content: stretch;
  cursor: pointer;
`;

const StyledText = styled.span`
  font-weight: ${props => (props.isMobile ? 700 : 800)};
  display: block;
  font-size: ${props => (props.isMobile ? '18px' : '24px')};
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #61285d;
  }
  border-bottom: ${props => (props.active ? '5px solid #12dfc0' : null)};
  color: ${props => (props.active ? '#61285d' : '#4a4a4a')};
`;

class ScrollableTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHash: props.tabs[0].hash,
    };
  }

  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.selectedHash !== this.state.selectedHash ||
      nextProps.tabs !== this.props.tabs
    );
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleSetActive = selectedHash => {
    this.setState({ selectedHash });
  };

  render() {
    const { tabs, isMobile } = this.props;
    const { selectedHash } = this.state;
    return (
      <React.Fragment>
        <StyledWrapper isMobile={isMobile}>
          <StyledContainer>
            {tabs.map(tab => (
              <Link
                key={tab.hash}
                activeClass="active"
                className={tab.hash}
                to={tab.hash}
                spy
                smooth
                offset={-30}
                duration={500}
                onSetActive={this.handleSetActive}
              >
                <StyledText
                  active={selectedHash === tab.hash}
                  isMobile={isMobile}
                >
                  {tab.name}
                </StyledText>
              </Link>
            ))}
          </StyledContainer>
        </StyledWrapper>
      </React.Fragment>
    );
  }
}

ScrollableTab.propTypes = {
  tabs: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withResponsive(ScrollableTab);

import React, { Fragment } from 'react';
import { Desktop, Mobile } from 'components';

export default function (WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Fragment>
          <Desktop>
            <WrappedComponent {...this.props} isMobile={false} />
          </Desktop>
          <Mobile>
            <WrappedComponent {...this.props} isMobile />
          </Mobile>
        </Fragment>
      );
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import useLockBodyScroll from '../../custom-hooks/useLockBodyScroll';
import Modal from '../../stateless/Modal';
import TurnOnNotificationGuidance from './TurnOnNotificationGuidance';

const GuidanceModal = ({ openModal, closeModal }) => {
  useLockBodyScroll(openModal);

  return openModal ? (
    <Modal onClose={closeModal} modalWidth="480px">
      <TurnOnNotificationGuidance />
    </Modal>
  ) : null;
};

GuidanceModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default GuidanceModal;

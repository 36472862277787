import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledContainer = styled.div(({ isMobile, noBackground, styles }) =>
  isMobile && !noBackground
    ? {
        background: '#EEF4F4',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
        borderRadius: 46,
        maxWidth: 340,
        width: '100%',
        ...styles,
      }
    : { maxWidth: 340, ...styles },
);

const StyledTitle = styled.h1`
  font-weight: 800;
  font-size: 40px;
  color: #12dfc0;
  letter-spacing: 0;
  text-align: left;
  ${media.phone`
    font-size: 20px;  
    text-align: center;
    padding: 10px 0;
  `};
`;

const StyledText = styled.p`
  font-weight: 600;
  font-size: 18px;
  ${media.tablet`
    font-size:14px;
  `};
  ${media.phone`
    font-size:12px;
    padding:0 18px;
    line-height: 18px;
  `};
  color: ${props => props.color};
  letter-spacing: 0;
  text-align: ${props => props.align};
  line-height: 22px;
  padding: 0 20px 0 0;
`;

const StyledSubText = styled.p`
  font-size: 14px;
  padding: 25px 0;
  ${media.phone`
    font-size:12px;
    padding: 10px 0 0 18px;
  `};
`;

const Info = ({
  title,
  text,
  subText,
  textColor,
  isMobile,
  styles,
  noBackground,
  children,
}) => {
  return (
    <StyledContainer
      isMobile={isMobile}
      styles={styles}
      noBackground={noBackground}
    >
      {title ? <StyledTitle isMobile={isMobile}>{title}</StyledTitle> : null}
      {text ? (
        <StyledText color={textColor} isMobile={isMobile}>
          {text}
        </StyledText>
      ) : null}
      {subText ? <StyledSubText>{subText}</StyledSubText> : null}
      {children}
    </StyledContainer>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  textColor: PropTypes.string,
  subText: PropTypes.string,
  text: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  noBackground: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.element,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

Info.defaultProps = {
  subText: '',
  textColor: '#fff',
  title: '',
  isMobile: false,
  noBackground: false,
  styles: null,
  children: null,
  align: 'left',
};

export default React.memo(Info);

import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';
import styled from 'styled-components';
import { Typography } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useLockBodyScroll from '../../custom-hooks/useLockBodyScroll';
import withResponsive from '../../../helper/withResponsive';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto 50px;
  grid-gap: 10px;
  padding: 20px 10px 20px 20px;
`;

const StyledHeader = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`;

const StyledArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
  .arrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border-radius: 50%;
      background-color: rgba(9, 30, 66, 0.08);
    }
  }
`;
const StyledBody = styled.div`
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  height: calc(100vh - 30px);
`;

const Drawer = ({ children, open, toggle, direction, title, isMobile }) => {
  const styles = {
    position: 'fixed',
    top: 0,
    [direction]: 0,
    height: '100vh',
    width: isMobile ? '100%' : 480,
    backgroundColor: '#fff',
    zIndex: 109900,
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)',
  };

  const props = useSpring({
    to: {
      transform: `translate3d(${open ? 0 : 100}%,0,0)`,
      ...styles,
    },
    config: config.default,
  });

  useLockBodyScroll(open);

  return (
    <animated.div style={props}>
      <StyledContainer>
        <StyledBody>
          <StyledHeader>
            <Typography
              align="left"
              text={title}
              fontSize={24}
              isBold
              className="pl-2"
            />
          </StyledHeader>
          {children}
        </StyledBody>
        <StyledArrow onClick={toggle}>
          <span className="arrow">
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </StyledArrow>
      </StyledContainer>
    </animated.div>
  );
};

Drawer.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func,
  direction: PropTypes.oneOf(['left', 'right']),
  isMobile: PropTypes.bool,
};

Drawer.defaultProps = {
  direction: 'right',
  title: '',
  open: false,
  toggle: null,
  children: null,
  isMobile: false,
};

export default withResponsive(Drawer);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ActionImage = ({ className, src, alt }) => (
  <img src={src} className={`${className} rounded-circle`} alt={alt} />
);

ActionImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

ActionImage.defaultProps = {
  className: '',
  src: '',
};

export const StyledActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`;

export const StyledBusinessImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 75px;
  max-height: 75px;
  margin: 10px 10px;
`;

export const StyledBusinessActionImage = styled(ActionImage)`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  margin: 10px 10px;
`;

export const StyledBusinessImageContainer = styled.div`
  flex: 0 0 20%;
  margin: 5px;
  height: auto;
`;

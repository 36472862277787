import React, { useEffect, useState, useCallback } from 'react';
import { actions } from 'store/leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle, CustomTab } from 'components';
import { fromAuth } from 'store/selectors';
import { selectLeaderBoardList, selectMyTopHedgiesList } from '../../../store/leaderboard/selectors';
import { isEmpty } from 'lodash';
import LeaderList from './LeadersList';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 10px;
`;

const StyledMyHedgies = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff2f3;
  span {
    font-weight: 700;
    font-size: 18px;
  }
`;

const RANK_TYPE = {
  day: 'd',
  week: 'w',
  month: 'm',
};

const LeaderBoardPage = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(RANK_TYPE.week);
  const leaders = useSelector(state => selectLeaderBoardList(state));
  const userId = useSelector(state => state.session.user.id);
  const myHedgies = useSelector(state => selectMyTopHedgiesList(state));
  const firebaseAuthenticated = useSelector(state =>
    fromAuth.firebaseAuthSuccess(state),
  );

  const switchTab = useCallback(
    tabId => {
      if (tabId !== activeTab) {
        setActiveTab(tabId);
      }
    },
    [activeTab],
  );

  useEffect(() => {
    dispatch(actions.getLeaderBoard({ rank_type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (isEmpty(myHedgies) && userId && firebaseAuthenticated) {
      dispatch(actions.getMyTopHedgies({ rank_type: 'd', user_id: userId }));
    }
  }, [dispatch, firebaseAuthenticated, myHedgies, userId]);

  const tabContent = [
    {
      id: RANK_TYPE.week,
      tabName: 'Last 7 days',
      content: null,
    },
    {
      id: RANK_TYPE.month,
      tabName: 'Last 30 days',
      content: null,
    },
    {
      id: RANK_TYPE.day,
      tabName: 'All Time',
      content: null,
    },
  ];

  return (
    <StyledContainer>
      <PageTitle title="Leaderboard" />
      <CustomTab
        activeTab={activeTab}
        tabContent={tabContent}
        switchTab={switchTab}
        background="#fff"
        className="pt-2"
      />
      <LeaderList list={leaders} key="leaders" userId={userId} />
      <div className="pb-3" />
      <StyledMyHedgies>
        <span>My Hedgies</span>
      </StyledMyHedgies>
      <LeaderList key="my-hedgies" list={myHedgies} isHighlight={false} />
    </StyledContainer>
  );
};

export default LeaderBoardPage;

import React, { useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Spinner,
  FormError,
  EmptyDataMessage,
  HeadLine,
  GradientButton,
  Typography,
} from 'components';
import * as paths from 'config/paths';
import { isEmpty } from 'lodash';
import { StyledContainer, StyledResources } from './style';
import UpgradingFurniture from './UpgradingFurniture';
import House from './House';
import useImgLoader from '../../custom-hooks/useImgLoader';
import bgOutsideUrl from '../../../images/house/house_large_bg.jpg';
import bgHouseUrl from '../../../images/house/bg_house.png';
import ResourcesWrapper from './ResourcesWrapper';

import { selectCleanedHouse } from '../../../store/hedgieHouse/selectors';

export const HouseContext = createContext({
  resourceTypesDetail: {},
  upgradedFurniture: {
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  houseEffects: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
});

const MyHousePage = ({
  getHouses,
  hedgieHouses,
  furnitureForHouse,
  buildFurniture,
  upgradedFurniture,
  resourceTypesDetail,
  clearBuildingFurnitureState,
  history,
  houseEffectsTotal,
  cancelRequest,
  health,
}) => {
  const loadingBackground = useImgLoader('', bgOutsideUrl);
  const loadedBgHouse = useImgLoader('', bgHouseUrl);

  const cleaned = useSelector(state => selectCleanedHouse(state));

  useEffect(() => {
    getHouses();
    return () => {
      cancelRequest();
    };
  }, [cancelRequest, getHouses]);

  const { data, loading, error } = hedgieHouses;

  function gotoMyHedgiesPage() {
    history.push(paths.MY_HEDGIES);
  }

  function gotoJourneySelectHedgie() {
    history.push(paths.SELECT_HEDGIE);
  }

  if (loading || !loadingBackground.loaded) {
    return <Spinner noFixed />;
  }

  if (isEmpty(data)) {
    return <EmptyDataMessage message="Does not any House" />;
  }

  return (
    <HouseContext.Provider
      value={{
        upgradedFurniture,
        resourceTypesDetail,
        clearBuildingFurnitureState,
      }}
    >
      <StyledContainer>
        <House
          furniture={furnitureForHouse}
          loadedBgHouse={loadedBgHouse}
          loadingBackground={loadingBackground}
          health={health}
          onClickHedgieIcon={gotoMyHedgiesPage}
        />
        <StyledResources>
          <HeadLine
            title="Resources"
            color="#798C93"
            fontSize={24}
            align="center"
            fontWeight={800}
          />
          <Typography
            text={`Your Hedgies currently recover ${cleaned ? cleaned : houseEffectsTotal} energy every hour. 
            You can increase it by building more furniture!`}
            className="pt-2"
          />
          <ResourcesWrapper />
        </StyledResources>
        <div className="pt-5 pb-5">
          <UpgradingFurniture
            buildFurniture={buildFurniture}
            furniture={furnitureForHouse}
            houseId={data[0].id}
          />
          <div className="pt-5">
            <GradientButton
              label="Start Journey!"
              width={220}
              height={60}
              borderRadius={20}
              fontSize={18}
              onClick={gotoJourneySelectHedgie}
            />
          </div>
        </div>
        {error.error ? (
          <div className="align-center">
            <FormError error={error} />
          </div>
        ) : null}
      </StyledContainer>
    </HouseContext.Provider>
  );
};

MyHousePage.propTypes = {
  history: PropTypes.object.isRequired,
  getHouses: PropTypes.func,
  cancelRequest: PropTypes.func,
  buildFurniture: PropTypes.func,
  resourceTypesDetail: PropTypes.object,
  hedgieHouses: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
  }),
  furnitureForHouse: PropTypes.object,
  upgradedFurniture: PropTypes.object,
  houseEffectsTotal: PropTypes.string,
  clearBuildingFurnitureState: PropTypes.func,
  health: PropTypes.number,
};

MyHousePage.defaultProps = {
  getHouses: null,
  cancelRequest: null,
  buildFurniture: null,
  upgradedFurniture: null,
  resourceTypesDetail: null,
  furnitureForHouse: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  clearBuildingFurnitureState: null,
  houseEffectsTotal: '',
  hedgieHouses: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  health: 100,
};

export default MyHousePage;

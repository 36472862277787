import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, FormError, LoadableButton, Typography } from 'components';
import { media } from 'styles/main';
import NumberUtil from 'services/numberUtil';
import DomainForm from './DomainForm';

const StyledContainer = styled.div`
  .product-list {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 10px;
  }
`;

const StyledProductList = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .product-list {
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
  }
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 30px;
  ${media.tablet`
    justify-content: center;
  `}
`;

const FREE_UNSTOPPABLE_DOMAIN = 'free_unstoppable_domain';

const ProductList = ({
  productId,
  product,
  getProductDetail,
  createAnOrder,
  cancelOrder,
  orderedProduct,
}) => {
  const [formValues, setFormValues] = React.useState({
    claimUD: {},
    ownerType: 'ETH',
    ownerPublicKey: '',
  });

  const [formError, setFormError] = React.useState({});

  React.useEffect(() => {
    getProductDetail(productId);
  }, [getProductDetail, productId]);

  const { data } = product;
  const isFreeUnstoppableDomain =
    data &&
    data.hasOwnProperty('specialType') &&
    data.specialType === FREE_UNSTOPPABLE_DOMAIN;

  const createAnOrderCallback = React.useCallback(() => {

    let payload = { productID: productId };

    if (isFreeUnstoppableDomain) {
      setFormError({
        domain: !formValues.domain,
        ownerType: !formValues.ownerType,
        ownerPublicKey: !formValues.ownerPublicKey,
      });

      if (!formValues.domain || !formValues.ownerPublicKey || !formValues.ownerType) {
        return;
      }

      let claimUD = {
        domain: formValues.domain,
        owner: {
          type: formValues.ownerType,
          publicKey: formValues.ownerPublicKey,
        }
      };

      payload.claimUD = claimUD;
    }

    createAnOrder(payload);
  }, [createAnOrder, productId, formValues, isFreeUnstoppableDomain]);

  const cancelOrderCallback = React.useCallback(() => {
    cancelOrder();
  }, [cancelOrder]);

  function onChange(evt) {
    setFormError({
      ownerType: false,
      ownerPublicKey: false,
      domain: false,
    });
    setFormValues({
      ...formValues,
      [evt.target.name]: evt.target.value,
    });
  }


  return (
    <StyledContainer>
      <Typography text="Product Information" className="product-list" align="left" />
      <StyledProductList>
        <Typography text={data.name} fontSize={16} color="#642460" />
        <Typography
          text={NumberUtil.toDecimal(data.curioPrice)}
          color="#35BD51"
          className="pl-2"
        />
      </StyledProductList>

      <DomainForm
        visible={isFreeUnstoppableDomain}
        onChange={onChange}
        error={formError}
      />
      {orderedProduct.error.error ? (
        <div className="align-center">
          <FormError error={orderedProduct.error} />
        </div>
      ) : null}
      <StyledActions>
        <div>
          <LoadableButton
            onClick={createAnOrderCallback}
            loading={orderedProduct.loading}
            disabled={orderedProduct.loading}
            label="Pay now"
          />
          <Button onClick={cancelOrderCallback}>Cancel</Button>
        </div>
      </StyledActions>
    </StyledContainer>
  );
};

ProductList.propTypes = {
  productId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  getProductDetail: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  createAnOrder: PropTypes.func.isRequired,
  orderedProduct: PropTypes.object.isRequired,
};

export default ProductList;

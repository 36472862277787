import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabPanel = styled.div``;

const TabPanel = props => {
  return <StyledTabPanel>{props.children}</StyledTabPanel>;
};

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TabPanel;

import { put, call, fork, take } from 'redux-saga/effects';
import querystring from 'querystring';
import { actions } from './index';

export function* getLeaderBoard(api, params) {
  try {
    const qs = querystring.stringify(params);
    const response = yield call(
      [api, api.get],
      `/leaderboard/hedgies/curio?${qs}`,
    );
    yield put(actions.getLeaderBoardSuccess(response));
  } catch (e) {
    yield put(actions.getLeaderBoardFailure(e));
  }
}

export function* getMyTopHedgies(api, params) {
  try {
    const qs = querystring.stringify(params);
    const response = yield call(
      [api, api.get],
      `/leaderboard/hedgies/curio?${qs}`,
    );
    yield put(actions.getMyTopHedgiesSuccess(response));
  } catch (e) {
    yield put(actions.getMyTopHedgiesFailure(e));
  }
}

export function* watchGetLeaderBoard(api) {
  while (true) {
    const { payload } = yield take(actions.getLeaderBoard);
    yield call(getLeaderBoard, api, payload);
  }
}

export function* watchGetMyTopHedgies(api) {
  while (true) {
    const { payload } = yield take(actions.getMyTopHedgies);
    yield call(getMyTopHedgies, api, payload);
  }
}

export default function* ({ api, firebase }) {
  yield fork(watchGetLeaderBoard, api);
  yield fork(watchGetMyTopHedgies, api);
}

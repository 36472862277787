import { connect } from 'react-redux';
import { fromUser } from 'store/selectors';
import { fromAuth } from 'store/selectors';

import {
    requestOtpCode,
    verifyEmailAddress,
} from 'store/actions';

import EmailVerificationModal from 'components/pages/EmailVerificationModal';

const mapStateToProps = state => ({
    error: fromUser.errorGettingUser(state) || fromAuth.getError(state),
    requestOtpSuccess: fromUser.requestOtpSuccess(state),
    verifyingEmailSucesss: fromUser.verifyingEmailSucesss(state),
    verifyingEmailError: fromUser.verifyingEmailError(state),
});

const mapDispatchToProps = dispatch => ({
    requestOtpCode: () => dispatch(requestOtpCode()),
    verifyEmailAddress: otp => dispatch(verifyEmailAddress(otp)),
});


export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationModal);

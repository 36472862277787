import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  PremiumHedgie,
  SubHeading,
  Spinner,
  Button,
  Desktop,
  Mobile,
} from 'components';
import * as types from 'types';
import Log from 'services/log';

const HedgiesList = ({ hedgies }) => {
  const items = hedgies.map(hedgie => (
    <PremiumHedgie
      key={hedgie.id}
      color={`#${hedgie.color}`}
      price={parseInt(hedgie.tierInfo.dollar, 10)}
      imgURL={hedgie.imgURL}
      status={hedgie.status}
      width={160}
      height={182}
    />
  ));
  return items;
};

const TierSelect = ({ tier, tiers, onChangeValue }) => {
  const options = tiers.map(t => {
    return { value: t.tier, label: t.tierName };
  });
  if (options.length === 0) {
    return null;
  }
  let selectedValue;
  if (tier) {
    [selectedValue] = options.filter(o => o.value === tier);
  }
  return (
    <Select
      className="react-select-container"
      value={selectedValue}
      options={options}
      onChange={e => {
        const [selected] = tiers.filter(t => t.tier === e.value);
        onChangeValue(selected);
      }}
    />
  );
};

TierSelect.propTypes = {
  tier: PropTypes.number.isRequired,
  tiers: PropTypes.arrayOf(types.tier).isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

const selectedTierObject = (tiers, tier) => {
  const [selected] = tiers.filter(t => t.tier === tier);
  return selected;
};

class HedgieTierView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      selectedTier: null,
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleChangeTier = tier => {
    if (
      this.state.selectedTier === null ||
      this.state.selectedTier.tier !== tier.tier
    ) {
      this.setState({
        selectedTier: tier,
      });
      this.props.onChangeTier(tier.tier);
    }
  };

  render() {
    const { premiumHedgies, loading, tiers, tier, paginationData } = this.props;
    Log.debug(
      { premiumHedgies, paginationData, loading, tiers, tier },
      'HedgieTierView:render',
    );
    const totalHedgies =
      paginationData && paginationData.total ? paginationData.total : 0;
    const selectedTier = selectedTierObject(tiers, tier);
    const selectedTierImg = selectedTier ? selectedTier.uniqueItem : '';
    const selectedTierImgDescription = selectedTier
      ? selectedTier.uniqueItemDesc
      : '';
    const selectedTierDescription = selectedTier ? selectedTier.desc : '';
    const selectedItemDescription = selectedTier
      ? selectedTier.uniqueItemDesc
      : '';
    return (
      <Container fluid>
        <Container className="mt-5">
          <Row className="p-2">
            <Col lg="3" md="6" sm="12">
              <h2 className="ff-bold">Select Tier</h2>
            </Col>
            <Col lg="3" md="6" sm="12">
              <TierSelect
                tiers={tiers}
                tier={tier}
                onChangeValue={this.handleChangeTier}
              />
            </Col>
          </Row>
          <Desktop>
            <Row>
              <Col md="3">
                <SubHeading heading="" />
              </Col>
              <Col md="3" className="text-center">
                <SubHeading heading="Total Hedgies" />
              </Col>
              <Col md="6" className="text-center">
                <SubHeading heading="Unique Item" />
              </Col>
            </Row>
            <Row>
              <Col md="3" style={{ marginTop: '-1.8em' }}>
                <SubHeading heading={selectedTierDescription} />
              </Col>
              <Col md="3" className="text-center">
                <SubHeading
                  heading={`${totalHedgies}`}
                  className="large-sub-heading"
                />
              </Col>
              <Col md="6" className="text-center">
                <Row className="justify-content-center">
                  <Col md="8">{selectedItemDescription}</Col>
                  {selectedTierImg && (
                    <Col md="3">
                      <img
                        className="tier-unique-item-img"
                        src={selectedTierImg}
                        alt={selectedTierImgDescription}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Desktop>
          <Mobile>
            <Row className="pb-3">
              <Col>{selectedTierDescription}</Col>
            </Row>
            {/* <Row className="d-flex">
              <Col ><SubHeading heading="Total Hedgies" /></Col>
              <Col className="align-self-end"><SubHeading heading={totalHedgies} /></Col>
            </Row> */}
            <Row>
              <Col xs="6" className="text-center">
                <SubHeading heading="Total Hedgies" />
              </Col>
              <Col xs="6" className="text-center">
                <SubHeading heading="Unique Item" />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="text-center">
                <SubHeading
                  heading={`${totalHedgies}`}
                  className="large-sub-heading"
                />
              </Col>
              <Col xs="6" className="text-center">
                <img
                  className="tier-unique-item-img"
                  src={selectedTierImg}
                  alt={selectedTierImgDescription}
                />
              </Col>
            </Row>
          </Mobile>
          {loading && premiumHedgies.length === 0 && (
            <div className="pos-rel m-5 p-5">
              <Spinner />
            </div>
          )}
          {premiumHedgies.length > 0 && tier && (
            <React.Fragment>
              <Row className="p-5">
                <HedgiesList hedgies={premiumHedgies} />
              </Row>
              {premiumHedgies.length > 0 &&
                paginationData.total > premiumHedgies.length && (
                  <Row className="p-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
                    <Col className="text-center  col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-center">
                      <Button
                        size="large"
                        onClick={this.props.onLoadMorePremium}
                      >
                        Load More
                      </Button>
                    </Col>
                  </Row>
                )}
            </React.Fragment>
          )}
        </Container>
      </Container>
    );
  }
}

HedgieTierView.propTypes = {
  className: PropTypes.string,
  premiumHedgies: PropTypes.arrayOf(types.hedgie),
  tiers: PropTypes.arrayOf(types.tier),
  tier: PropTypes.number,
  paginationData: types.paginationData,
  loading: PropTypes.bool,
  onChangeTier: PropTypes.func,
  onLoadMorePremium: PropTypes.func,
};

HedgieTierView.defaultProps = {
  className: null,
  premiumHedgies: [],
  tier: 0,
  tiers: [],
  paginationData: {},
  loading: false,
  onChangeTier: () => {},
  onLoadMorePremium: () => {},
};

export default HedgieTierView;

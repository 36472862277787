import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Step } from 'components';
import { isEmpty } from 'lodash';

const Container = styled.div`
  max-width: 927px;
  margin: 0 auto;
`;
const JourneySteps = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  justify-content: center;
`;

const StepHolder = styled.div`
  //flex: 1 100%;
`;

const StartJourneySteps = ({ steps, activeStep, ...props }) => {
  if (isEmpty(steps)) {
    return <span>No steps</span>;
  }
  return (
    <Container {...props}>
      <JourneySteps>
        {steps.map(({ id, name, step }) => (
          <StepHolder key={id}>
            <Step
              number={step}
              size={56}
              label={name}
              active={activeStep >= step}
            />
          </StepHolder>
        ))}
      </JourneySteps>
    </Container>
  );
};

StartJourneySteps.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default StartJourneySteps;

export const GET_ALL_ANNOUNCEMENTS = 'GET_ALL_ANNOUNCEMENTS';
export const GET_ALL_ANNOUNCEMENTS_SUCCESS = 'GET_ALL_ANNOUNCEMENTS_SUCCESS';
export const GET_ALL_ANNOUNCEMENTS_FAILURE = 'GET_ALL_ANNOUNCEMENTS_FAILURE';

export const GET_UNREAD_ANNOUNCEMENTS = 'GET_UNREAD_ANNOUNCEMENTS';
export const GET_UNREAD_ANNOUNCEMENTS_SUCCESS =
  'GET_UNREAD_ANNOUNCEMENTS_SUCCESS';
export const GET_UNREAD_ANNOUNCEMENTS_FAILURE =
  'GET_UNREAD_ANNOUNCEMENTS_FAILURE';

export const MARK_AN_ANNOUNCEMENT_AS_READ = 'MARK_AN_ANNOUNCEMENT_AS_READ';
export const MARK_AN_ANNOUNCEMENT_AS_READ_SUCCESS =
  'MARK_AN_ANNOUNCEMENT_AS_READ_SUCCESS';
export const MARK_AN_ANNOUNCEMENT_AS_READ_FAILURE =
  'MARK_AN_ANNOUNCEMENT_AS_READ_FAILURE';

export const getAllAnnouncements = payload => ({
  type: GET_ALL_ANNOUNCEMENTS,
  payload,
});
export const getAllAnnouncementsSuccess = payload => ({
  type: GET_ALL_ANNOUNCEMENTS_SUCCESS,
  payload,
});
export const getAllAnnouncementsFailure = error => ({
  type: GET_ALL_ANNOUNCEMENTS_FAILURE,
  payload: error,
});

export const getUnreadAnnouncements = () => ({
  type: GET_UNREAD_ANNOUNCEMENTS,
});
export const getUnreadAnnouncementsSuccess = payload => ({
  type: GET_UNREAD_ANNOUNCEMENTS_SUCCESS,
  payload,
});
export const getUnreadAnnouncementsFailure = error => ({
  type: GET_UNREAD_ANNOUNCEMENTS_FAILURE,
  payload: error,
});

export const markAnAnnouncementAsRead = announcementId => ({
  type: MARK_AN_ANNOUNCEMENT_AS_READ,
  payload: announcementId,
});

export const markAnAnnouncementAsReadSuccess = payload => ({
  type: MARK_AN_ANNOUNCEMENT_AS_READ_SUCCESS,
  payload,
});
export const markAnAnnouncementAsReadFailure = error => ({
  type: MARK_AN_ANNOUNCEMENT_AS_READ_FAILURE,
  payload: error,
});

import React from 'react';
import PropTypes from 'prop-types';
import {
  LearnMoreButton,
  HeadLine,
  Typography,
  BusinessList,
} from 'components';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import * as paths from 'config/paths';
import withResponsive from '../../../helper/withResponsive';

const StyledWrapper = styled.div`
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 70px;
`;

const Spend = ({ businesses, isMobile }) => {
  return (
    <StyledWrapper>
      <HeadLine className="pb-3" title="Spend" fontSize={isMobile ? 27 : 40} />
      <Typography
        className="pb-2"
        text="Spend your Curio on products and perks at Hedgie-approved businesses!"
      />
      <LearnMoreButton
        label="Learn more"
        href={paths.SPEND_CURIO_SCROLL_TO_APPROVED_BUSINESS}
      />
      {isEmpty(businesses) ? (
        <div style={{ textAlign: 'center' }}>No data</div>
      ) : (
        <BusinessList loading={false} businesses={businesses} />
      )}
    </StyledWrapper>
  );
};

Spend.propTypes = {
  businesses: PropTypes.array,
  isMobile: PropTypes.bool,
};

Spend.defaultProps = {
  businesses: [],
  isMobile: false,
};

export default withResponsive(Spend);

import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from './CheckoutForm';

class MyStoreCheckout extends React.Component {
  render() {
    const { id, email, onSubmitGiftRequest } = this.props;
    return (
      <Elements>
        <InjectedCheckoutForm id={id} email={email} onSubmitGiftRequest={onSubmitGiftRequest} />
      </Elements>
    );
  }
}

MyStoreCheckout.propTypes = {
  id: PropTypes.string,
  email: PropTypes.string,
  onSubmitGiftRequest: PropTypes.func,
};

MyStoreCheckout.defaultProps = {
  id: '',
  email: '',
  onSubmitGiftRequest: () => {},
};

export default MyStoreCheckout;

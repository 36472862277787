import { messaging } from '../../../services/firebase/firebase';

export const BROWSER_NOT_COMPATIBLE = 'BROWSER_NOT_COMPATIBLE';

export function askForPermission() {
  return new Promise((resolve, reject) => {
    if (messaging) {
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then(token => {
          resolve(token);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject(BROWSER_NOT_COMPATIBLE);
    }
  });
}

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledTab = styled.div`
  cursor: pointer;
  border-bottom: ${props => (props.active ? '6px solid #12DFC0' : null)};
  font-weight: 800;
  color: ${props => (props.active ? '#000' : '#798C93')};
  font-size: 22px;
  letter-spacing: 0;
  text-align: left;
  ${media.tablet`
    font-size: 16px;
   `
  }
`;

const Tab = ({ active, onSelect, tab }) => {
  const selectTabCallback = useCallback(
    () => {
      onSelect(tab.id);
    },
    [onSelect, tab.id],
  );

  return (
    <StyledTab onClick={selectTabCallback} active={active}>
      {tab.name}
    </StyledTab>
  );
};

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Tab;

import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Log from 'services/log';
import * as types from 'types';
import MetaMaskUtil from 'services/metamaskutil';
import downloadMetaMaskImage from 'images/download-metamask.png';
import unlockMetaMaskImage from 'images/download-metamask.png';
import { StyledTextBtn } from '../../../styles/main';

const installLink =
  'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en';

class MetaMask extends React.Component {
  constructor(props) {
    super(props);
    this.accountInterval = null;
    this.state = {
      isInstalled: MetaMaskUtil.isInstalled(),
      isUnlocked: false,
      account: '',
      modal: false,
    };
    Log.debug('MetaMask.constructor - isInstalled', this.state.isInstalled);
  }

  componentDidMount() {
    Log.debug('MetaMask - componentDidMount state:', this.state);
    this.pollAccountStatus();
  }

  componentWillUnmount() {
    clearInterval(this.accountInterval);
    this.accountInterval = null;
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  modalHeaderText = () => {
    if (!this.state.isInstalled) {
      return 'Install MetaMask';
    }
    if (this.state.isInstalled) {
      return 'Unlock MetaMask';
    }
    return '';
  };

  modalBody = () => {
    if (!this.state.isInstalled) {
      return (
        <div>
          <div>
            Metamask is a Chrome wallet that allows you to safetly store your
            Hedgies. Install it by clicking “Add Extension” on the Metamask
            Chrome store page.
          </div>
          <div className="d-flex justify-content-center">
            <Row>
              <StyledTextBtn>
                <a href={installLink} target="_self" /*className="button-link"*/>
                  {this.buttonMessage()}
                </a>
              </StyledTextBtn>
            </Row>
          </div>
        </div>
      );
    }
    if (!this.state.account && this.state.isInstalled) {
      return (
        <div>
          <div>
            Metamask is a Chrome wallet that allows you to safetly store your
            Hedgies. Unlock it by clicking the button below.
          </div>
          <div className="d-flex justify-content-center">
            <Row>
              <StyledTextBtn>{this.buttonMessage()}</StyledTextBtn>
            </Row>
          </div>
        </div>
      );
    }
    return null;
  };

  buttonMessage = () => {
    let buttonMessage = 'Install MetaMask';
    if (!this.state.isInstalled) {
      buttonMessage = 'Install MetaMask';
    }
    if (this.state.isInstalled) {
      buttonMessage = 'I Unlocked MetaMask';
    }
    return buttonMessage;
  };

  modalImage = () => {
    if (!this.state.isInstalled) {
      return <img src={downloadMetaMaskImage} alt="Download MetaMask" />;
    }
    if (this.state.isInstalled) {
      return <img src={unlockMetaMaskImage} alt="Unlock MetaMask" />;
    }
    return <img src={unlockMetaMaskImage} alt="Unlock MetaMask" />;
  };

  pollAccountStatus = () => {
    clearInterval(this.accountInterval);
    this.accountInterval = setInterval(() => {
      let { isInstalled } = this.state;
      if (!isInstalled) {
        Log.debug(`MetaMask - isInstalled: ${isInstalled}`);
        const nextIsInstalled = MetaMaskUtil.isInstalled();
        if (nextIsInstalled) {
          this.setState({ isInstalled: nextIsInstalled });
        }
        isInstalled = nextIsInstalled;
      }
      if (isInstalled) {
        MetaMaskUtil.getAccount()
          .then(account => {
            if (this.state.account !== account) {
              const isUnlocked = !account;
              this.setState({ account, isUnlocked });
            }
          })
          .catch(err => {
            Log.error('MetaMask - pollAccountStatus', err);
          });
      }
    }, 500);
  };

  openMetaMaskWindow = () => {
    MetaMaskUtil.openMetaMaskWindow();
  };

  render() {
    return (
      <div className="metamask-container text-center mb-3">
        {/* <div>{status}</div> */}
        {!this.state.isInstalled && (
          <div>
            <div className="d-flex justify-content-center">
              <Row>
                <StyledTextBtn>
                  <a href={installLink} target="_self" /*className="button-link"*/>
                    {this.buttonMessage()}
                  </a>
                </StyledTextBtn>
              </Row>
            </div>
          </div>
        )}
        {!this.state.account && this.state.isInstalled && (
          <div>
            <div className="d-flex justify-content-center">
              <Row>
                <StyledTextBtn onClick={this.openMetaMaskWindow}>
                  {this.buttonMessage()}
                </StyledTextBtn>
              </Row>
            </div>
          </div>
        )}
        {this.state.account && this.props.children}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className="metamask-modal"
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.modalHeaderText()}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{this.modalBody()}</Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-center">{this.modalImage()}</Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

MetaMask.propTypes = {
  error: types.error,
  working: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MetaMask.defaultProps = {
  error: null,
  working: false,
};

export default MetaMask;

import { createSelector } from 'reselect';

const notificationSelector = state => state.notification;

export const notificationListSelector = createSelector(
  notificationSelector,
  notifications => notifications.notificationList,
);

export const selectNotifications = createSelector(
  [notificationListSelector],
  list => list.data,
);

export const selectNotificationsError = createSelector(
  [notificationListSelector],
  list => list.error,
);

export const selectNotificationsLoading = createSelector(
  [notificationListSelector],
  list => list.loading,
);

export const selectNotificationsPagination = createSelector(
  [notificationListSelector],
  list => list.pagination,
);

export const selectUnseenNotifications = createSelector(
  [notificationSelector],
  unseenNo => unseenNo.unseenNotifications,
);

export const selectUnseenNotificationsError = createSelector(
  [notificationSelector],
  unseenNo => unseenNo.unseenNotificationsError,
);

export const selectMarkAllAsReadError = createSelector(
  [notificationSelector],
  notification => notification.markAllAsReadError,
);

export const selectANotificationIdAsReadError = createSelector(
  [notificationSelector],
  notification => notification.markANotificationAsReadError,
);

export const selectArchivedNotification = createSelector(
  [notificationSelector],
  notification => notification.archivedNotificationId,
);

export const selectArchivedNotificationError = createSelector(
  [notificationSelector],
  notification => notification.archiveNotificationError,
);

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openPopUpExp } from '../../../store/level/actions';
import CircleProgress from '../CircleProgress';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Level = ({ currentLevel, xp, nextLevel }) => {
  const dispatch = useDispatch();
  const canLevelUp = useMemo(() => nextLevel && xp >= nextLevel.requiredXp, [
    nextLevel,
    xp,
  ]);

  const nextLevelXpDiff =
    nextLevel && currentLevel
      ? nextLevel.requiredXp - currentLevel.requiredXp
      : 0;

  const progressAbs = currentLevel ? xp - currentLevel.requiredXp : 0;

  const percent = useMemo(
    () =>
      nextLevelXpDiff !== 0
        ? Math.floor((100 * progressAbs) / nextLevelXpDiff)
        : 0,
    [progressAbs, nextLevelXpDiff],
  );

  const handleOpen = useCallback(
    () => {
      dispatch(openPopUpExp());
    },
    [dispatch],
  );

  return (
    <StyledWrapper>
      <CircleProgress
        percent={percent}
        level={currentLevel ? currentLevel.level : 0}
        width={80}
        height={80}
        canLevelUp={canLevelUp}
        onClick={canLevelUp ? handleOpen : null}
      />
    </StyledWrapper>
  );
};

Level.propTypes = {
  currentLevel: PropTypes.object,
  nextLevel: PropTypes.object,
  xp: PropTypes.number,
  openModal: PropTypes.func,
};

Level.defaultProps = {
  currentLevel: null,
  nextLevel: null,
  openModal: null,
  xp: 0,
};

export default React.memo(Level);

import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormError } from 'components';
import ProductItem from './ProductItem';
import { StyledProductsGrid } from './styles';

const ProductsList = ({ products, getBusinessProducts, businessId }) => {
  useEffect(
    () => {
      getBusinessProducts({ businessId, offset: 0, limit: 20 });
    },
    [businessId, getBusinessProducts],
  );

  const { data, error } = products;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <StyledProductsGrid>
      {data.map(product => (
        <ProductItem key={product.id} product={product} />
      ))}
      {error.error ? (
        <div className="align-center">
          <FormError error={error} />
        </div>
      ) : null}
    </StyledProductsGrid>
  );
};

ProductsList.propTypes = {
  businessId: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired,
  getBusinessProducts: PropTypes.func.isRequired,
};

export default ProductsList;

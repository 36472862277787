import React from 'react';
import PropTypes from 'prop-types';

const TimerSpeechBubble = ({ text, width, isMobile, color }) => {
  const transformStyle = `translate(${isMobile ? 100 : 197}, 65)`;
  return (
    <svg width="268px" height="92px" viewBox="0 0 268 92" style={{ width }}>
      <defs>
        <polygon
          id="path-1"
          points="0.0508450704 0.116689655 49.4825352 0.116689655 49.4825352 33.7931034 0.0508450704 33.7931034"
        />
        <polygon id="path-3" points="0 0.9733 27.278 0.9733 27.278 35 0 35" />
      </defs>
      <g
        id="Home/Onboarding"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Concept-2---Home---page-3"
          transform="translate(-376.000000, -341.000000)"
        >
          <g id="Group-4" transform="translate(376.000000, 341.000000)">
            <g id="Group-6">
              <g id="Group-3" transform={transformStyle}>
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M27.8070961,0.116689655 C27.8070961,0.116689655 15.9774964,15.5765503 38.4825352,25.6815379 C38.4825352,25.6815379 11.302817,34.9233649 0.0508450704,0.116689655 L27.8070961,0.116689655 Z"
                  id="Fill-1"
                  fill={color}
                  mask="url(#mask-2)"
                />
              </g>
              <polyline
                id="Fill-4"
                fill={color}
                points="6.68640217 64.3902188 0 1.35098113 268 0 260.038651 67"
              />
            </g>
            <g id="Group" transform="translate(25.000000, 14.000000)">
              <text
                id="I’ll-be-back-in-0:05"
                fontSize="18"
                fontWeight="600"
                fill="#FFFFFF"
              >
                <tspan x="41" y="22">
                  {text}
                </tspan>
              </text>
              <g id="Group-12">
                <g id="Group-3" transform="translate(0.000000, 0.027100)">
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3" />
                  </mask>
                  <g id="Clip-2" />
                  <path
                    d="M23.8687,32.8733 L3.4097,32.8733 C2.7837,32.8733 2.2727,32.3963 2.2727,31.8103 C2.2727,31.2233 2.7837,30.7463 3.4097,30.7463 L23.8687,30.7463 C24.4957,30.7463 25.0047,31.2233 25.0047,31.8103 C25.0047,32.3963 24.4957,32.8733 23.8687,32.8733 M10.3817,20.0543 C11.1237,19.6003 11.5667,18.8283 11.5667,17.9873 C11.5667,17.1463 11.1247,16.3743 10.3827,15.9203 C7.7577,14.3163 6.0057,11.0193 5.7377,7.3523 L21.5407,7.3523 C21.2727,11.0193 19.5207,14.3163 16.8957,15.9193 C16.1537,16.3723 15.7097,17.1443 15.7097,17.9863 C15.7097,18.8273 16.1547,19.6003 16.8957,20.0513 C19.5207,21.6563 21.2727,24.9533 21.5407,28.6193 L5.7377,28.6193 C6.0057,24.9533 7.7577,21.6563 10.3817,20.0543 M2.2727,4.1633 C2.2727,3.5773 2.7837,3.1003 3.4097,3.1003 L23.8687,3.1003 C24.4957,3.1003 25.0047,3.5773 25.0047,4.1633 C25.0047,4.7493 24.4957,5.2273 23.8687,5.2273 L3.4097,5.2273 C2.7837,5.2273 2.2727,4.7493 2.2727,4.1633 M23.8687,28.6193 L23.8137,28.6193 C23.5397,24.1953 21.4327,20.2843 18.1377,18.2723 C18.0037,18.1903 17.9827,18.0593 17.9827,17.9873 C17.9827,17.9163 18.0037,17.7833 18.1387,17.7013 C21.4327,15.6873 23.5397,11.7773 23.8137,7.3523 L23.8687,7.3523 C25.7487,7.3523 27.2787,5.9223 27.2787,4.1633 C27.2787,2.4043 25.7487,0.9733 23.8687,0.9733 L3.4097,0.9733 C1.5297,0.9733 -0.0003,2.4043 -0.0003,4.1633 C-0.0003,5.9223 1.5297,7.3523 3.4097,7.3523 L3.4647,7.3523 C3.7387,11.7773 5.8457,15.6873 9.1407,17.7003 C9.2747,17.7833 9.2947,17.9153 9.2947,17.9863 C9.2947,18.0583 9.2757,18.1893 9.1407,18.2713 C5.8457,20.2843 3.7387,24.1953 3.4647,28.6193 L3.4097,28.6193 C1.5297,28.6193 -0.0003,30.0513 -0.0003,31.8103 C-0.0003,33.5683 1.5297,35.0003 3.4097,35.0003 L23.8687,35.0003 C25.7487,35.0003 27.2787,33.5683 27.2787,31.8103 C27.2787,30.0513 25.7487,28.6193 23.8687,28.6193"
                    id="Fill-1"
                    fill="#FFFFFF"
                    mask="url(#mask-4)"
                  />
                </g>
                <path
                  d="M13.6392,22.2676 C13.0112,22.2676 12.5022,22.7436 12.5022,23.3296 C12.5022,23.9176 13.0112,24.3936 13.6392,24.3936 C14.2662,24.3936 14.7762,23.9176 14.7762,23.3296 C14.7762,22.7436 14.2662,22.2676 13.6392,22.2676"
                  id="Fill-4"
                  fill="#FFFFFF"
                />
                <path
                  d="M13.6392,12.6973 C13.0112,12.6973 12.5022,13.1733 12.5022,13.7593 C12.5022,14.3473 13.0112,14.8233 13.6392,14.8233 C14.2662,14.8233 14.7762,14.3473 14.7762,13.7593 C14.7762,13.1733 14.2662,12.6973 13.6392,12.6973"
                  id="Fill-6"
                  fill="#FFFFFF"
                />
                <path
                  d="M10.2296,9.5068 C9.6016,9.5068 9.0926,9.9828 9.0926,10.5698 C9.0926,11.1568 9.6016,11.6338 10.2296,11.6338 C10.8566,11.6338 11.3656,11.1568 11.3656,10.5698 C11.3656,9.9828 10.8566,9.5068 10.2296,9.5068"
                  id="Fill-8"
                  fill="#FFFFFF"
                />
                <path
                  d="M17.0489,11.6338 C17.6769,11.6338 18.1859,11.1568 18.1859,10.5708 C18.1859,9.9828 17.6769,9.5068 17.0489,9.5068 C16.4219,9.5068 15.9119,9.9828 15.9119,10.5708 C15.9119,11.1568 16.4219,11.6338 17.0489,11.6338"
                  id="Fill-10"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

TimerSpeechBubble.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  isMobile: PropTypes.bool,
  hideCountDown: PropTypes.bool,
  color: PropTypes.string,
};

TimerSpeechBubble.defaultProps = {
  width: 268,
  isMobile: false,
  hideCountDown: false,
  color: '#EE6506',
};

export default TimerSpeechBubble;

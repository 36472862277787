import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { HedgieImg, ContentLoader, CurioPrice } from 'components';
import useMedia from '../../custom-hooks/useMedia';

const StyledLeader = styled.div(
  breakpoints({
    xs: css`
      display: grid;
      grid-template-columns: 12% 18% auto 25%;
      justify-items: left;
      align-items: center;
      padding: 14px 10px;
      margin-bottom: 8px;
      border-bottom: 1px solid #eff2f3;
      border-left: 8px solid ${p => p.borderColor || 'transparent'};
      overflow: hidden;
      transition: all 400ms ease-in-out;
      &.isOwner {
        transition: all 400ms ease-in-out;
        background-image: linear-gradient(
          270deg,
          #009cde 1%,
          rgba(0, 234, 195, 0.5) 100%
        );
        border-radius: 12px;
        .name {
          color: #6f0056;
        }
      }
      &:first-child {
        border-top: 1px solid #eff2f3;
      }
      &:last-child {
        border-bottom: none;
      }
    `,
    md: css`
      padding: 14px 50px;
      grid-template-columns: 10% 15% auto 10%;
    `,
    lg: css`
      padding: 14px 100px;
      grid-template-columns: 10% 15% auto 10%;
    `,
  }),
);
const StyledShadow = styled.div(
  breakpoints({
    xs: css`
      width: 40px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.26);
      overflow: hidden;
      border-radius: 50%;
      .leader-board-hd {
        margin-top: 6px;
        margin-left: 4px;
      }
    `,
    md: css`
      width: 60px;
      .leader-board-hd {
        margin-top: 8px;
        margin-left: 5px;
      }
    `,
    lg: css`
      width: 73px;
      height: 73px;
      .leader-board-hd {
        margin-top: 10px;
        margin-left: 6px;
      }
    `,
  }),
);

const StyledText = styled.span(
  breakpoints({
    xs: css`
      color: ${p => p.color || '#000000'};
      font-weight: 400;
      font-size: 16px;
    `,
    lg: css`
      font-size: 18px;
    `,
  }),
);

function getBorderColor(rank) {
  switch (rank) {
    case 1:
      return '#FFB129';
    case 2:
      return '#FF48C1';
    case 3:
      return '#009CDE';
    default:
      return '';
  }
}

function generateOrder(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const StyledImgAndColor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledColorText = styled.span(
  breakpoints({
    xs: css`
      font-size: 12px;
      color: #878c97;
    `,
    lg: css`
      font-size: 14px;
    `,
  }),
);

const Leader = ({ rank, name, index, color, img, curioFound, isOwner, isHighlight }) => {
  useEffect(() => {
    setTimeout(forceCheck, 1000);
  }, []);

  const hedgieImgWidth = useMedia(
    ['(min-width: 992px)', '(max-width: 768px)'],
    [64, 40],
    64,
  );

  if (!color) {
    return (
      <StyledLeader>
        <ContentLoader width={22} height={12} type="rec" />
        <StyledImgAndColor>
          <ContentLoader
            width={hedgieImgWidth}
            height={hedgieImgWidth}
            type="circle"
            className="mb-1"
          />
          <ContentLoader width={40} height={12} type="text" />
        </StyledImgAndColor>
        <ContentLoader width={120} height={14} type="text" />
        <ContentLoader width={80} height={14} type="text" />
      </StyledLeader>
    );
  }
  return (
    <StyledLeader
      borderColor={isHighlight ? getBorderColor(rank) : null}
      className={isOwner ? 'isOwner' : ''}
    >
      <StyledText color={getBorderColor(rank)}>
        {generateOrder(rank)}
      </StyledText>
      <StyledImgAndColor>
        <StyledShadow className="mb-1">
          <LazyLoad
            height={hedgieImgWidth}
            offset={[0, hedgieImgWidth]}
            once
            placeholder={
              <HedgieImg color="#eff2f3" className="leader-board-hd" />
            }
          >
            
                <HedgieImg 
                color={`#${color}`}
                imgURL={img? img : null} 
                className="leader-board-hd" />
             
          </LazyLoad>
        </StyledShadow>
        <StyledColorText>{`#${color}`}</StyledColorText>
      </StyledImgAndColor>
      <StyledText className="name">{`${name || ''} ${
        isOwner ? '(you)' : ''
        }`}</StyledText>
      <CurioPrice curioPrice={curioFound} textColor="#5D5D5D" />
    </StyledLeader>
  );
};

Leader.propTypes = {
  rank: PropTypes.number,
  name: PropTypes.string,
  index: PropTypes.number,
  color: PropTypes.string,
  img: PropTypes.string,
  curioFound: PropTypes.string,
  isOwner: PropTypes.bool,
  isHighlight: PropTypes.bool,
};

Leader.defaultProps = {
  rank: 1,
  name: '',
  index: 0,
  color: '',
  img: '',
  curioFound: '0',
  isOwner: false,
  isHighlight: true,
};

export default Leader;

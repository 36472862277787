import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ParallaxMouseHover = props => {
  const [styles, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <StyledContainer
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div style={{ transform: styles.xy.interpolate(trans1) }}>
        {props.children}
      </animated.div>
    </StyledContainer>
  );
};

ParallaxMouseHover.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ParallaxMouseHover;

import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import FormErrorMessage from '../FormErrorMessage';

const CustomCheckBox = ({
  label,
  field,
  form: { touched, setFieldValue, errors, setFieldTouched },
  ...props
}) => {
  return (
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          onChange={event => {
            setFieldValue(field.name, event.target.checked);
            setFieldTouched(field.name, true);
          }}
          name={field.name}
          value={field.value}
          checked={field.value}
          {...props}
        />
        {label}
      </Label>
      {touched[field.name] && errors[field.name] && (
        <FormErrorMessage error={errors[field.name]} />
      )}
    </FormGroup>
  );
};

CustomCheckBox.propTypes = {
  label: PropTypes.element.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default CustomCheckBox;

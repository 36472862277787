import { put, call, take, fork } from 'redux-saga/effects';
import * as actions from './actions';
import { trackSubscribeToNewsletters } from '../../tracking';

export function* subScribeNews(api, payload) {
  try {
    const response = yield call(
      [api, api.post],
      '/subscribe-newsletter',
      payload,
    );
    yield put(actions.subscribeNewsSuccess(response));
    trackSubscribeToNewsletters();
  } catch (e) {
    yield put(actions.subscribeNewsFailure(e));
  }
}

export function* watchSubscribeNewsRequest(api) {
  while (true) {
    const { payload } = yield take(actions.SUBSCRIBE_NEWS_REQUEST);
    yield call(subScribeNews, api, payload);
  }
}

export default function*({ api }) {
  yield fork(watchSubscribeNewsRequest, api);
}

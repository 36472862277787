import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { HedgieImg } from 'components';

const HedgieImgContainer = styled.div`
  transform: rotate(-24deg);
  position: absolute;
  max-width: 550px;
  right: -55px;
  bottom: -100px;
`;

const containerClasses = color => (
  classnames(
    'ok-bubble color-light status-bubble',
    {
      [`ok-bubble-${color}`]: color,
    }
  )
);

const sideHedgieClasses = className => (
  classnames(
    'side-hedgie',
    {
      [className]: className,
    }
  )
);

const hexFromColorString = colorStr => {
  switch (colorStr) {
    case 'green': return '#12DFC0';
    case 'blue': return '#009CDE';
    case 'purple': return '#9d08fb';
    case 'yellow': return '#fbd608';
    default: return '#000000';
  }
};

const SideHedgie = ({ className, title, color, width, height }) => {
  return (
    <div className={sideHedgieClasses(className)}>
      {title &&
        <div className={containerClasses(color)}>
          <span className="ff-semi-bold fs-semi-lg">{title}</span>
        </div>
      }
      <HedgieImgContainer>
        <HedgieImg className="img-fluid" color={hexFromColorString(color)} width={width} />
      </HedgieImgContainer>
    </div>
  );
};

SideHedgie.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

SideHedgie.defaultProps = {
  title: '',
  color: '',
  className: '',
  width: '435',
  height: '494',
};

export default SideHedgie;

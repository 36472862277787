import React from 'react';
import { PageTitle, SubHeadingBold } from 'components';
import { Link } from 'react-router-dom';
import * as types from 'types';
import Redirect from 'config/redirect';
import { StyledOpenNewTab } from 'styles/main';

const TermsPage = () => {
  return (
    <div>
      <main>
        <Redirect />
        <div className="container-fluid row-section pos-reloverflow-hidden-reg">
          <div className="container">
            <div className="jumbotron ">
              <PageTitle title="Hedgie.io Terms of Use" />
              <div className="pb-3">Last Updated On: 11/08/2018</div>
              <div className="col-md-12 px-0">
                <p>
                  PLEASE READ THESE TERMS AND CONDITIONS (“TERMS”) CAREFULLY. BY
                  VISITING OR USING THIS HEDGIE.IO WEBSITE, OR ANY PART OF IT,
                  YOU AGREE TO THESE TERMS AND ALL TERMS INCORPORATED HEREIN BY
                  REFERENCE. BY AGREEING TO THESE TERMS, YOU ARE ENTERING INTO A
                  BINDING AGREEMENT WITH HEDGIE, INC. IF YOU DO NOT AGREE TO ANY
                  PART OF THESE TERMS, NAVIGATE AWAY FROM THE HEDGIE.IO WEBSITE.
                  FAILURE TO COMPLY WITH THESE TERMS MAY RESULT IN ACCOUNT
                  CLOSURE OR LEGAL ACTION AGAINST YOU.
                </p>
              </div>
              <div className="row justify-content-end  bgr-bottom-images pt-5">
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="1. WHAT IS HEDGIE?" />
                  <p className="ff-medium mt-4 mb-3">
                    Hedgie is a game being developed on Ethereum blockchain (
                    <strong>“Game”</strong>) in which a virtual character,
                    cryptocollectible Hedgie (<strong>“Hedgie”</strong>
                    ), goes on journeys and brings back various artifacts that
                    players can collect or exchange for in-game currency.
                  </p>
                  <p className="ff-medium mt-4 mb-3">
                    Hedgie is a limited edition cryptocollectible, each one
                    corresponding with one of the 16,777,216 hex colours. The
                    total number of hex colours in existence sets the limit for
                    total available Hedgies.
                  </p>
                  <p className="ff-medium mt-4 mb-3">
                    Hedgie Inc. (<strong>“Us”, “We”, “Our”</strong>) enables
                    users to own a Hedgie, which can later be used in the Game.
                    Currently a user can select their favourite colour of their
                    Hedgie via a colour picker tool on hedgie.io website (the{' '}
                    <strong>“Hedgie Site”</strong> or the{' '}
                    <strong>“Site”</strong>) and purchase it.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="2. HEDGIE, THE VIRTUAL CHARACTER" />
                  <p className="ff-medium mt-4 mb-3">
                    A. To use the Hedgie Site, you must first create an account
                    by providing your email and password, and agreeing to these
                    terms. You can then interact with all website functionality
                    with the exception of syncing your purchased Hedgie(s) to
                    Blockchain, or purchasing a Hedgie with ETH crypto currency.
                    In order to purchase a Hedgie with ETH, and/or sync your
                    purchased Hedgie(s) with Ethereum Blockchain, you first must
                    install the Google Chrome web browser. Once you have
                    installed Chrome, you will need to install a browser
                    extension called MetaMask. At that point your site account
                    will be connected with your MetaMask, and will unlock full
                    Blockchain-related features of the website.
                  </p>
                  <p className="ff-medium mt-4 mb-3">
                    B. We neither own nor control MetaMask, Google Chrome or any
                    other third party product, or service that you might access,
                    visit, or use for the purpose of enabling you to use the
                    various features of the Hedgie Site. We will not be liable
                    for the acts or omissions of any such third parties, nor
                    will we be liable for any damage that you may suffer as a
                    result of your transactions or any other interaction with
                    any such third parties.
                  </p>
                  <p className="ff-medium mt-4 mb-3">
                    C. You are responsible for the security of your account and
                    your MetaMask. If you become aware of any unauthorized use
                    of your password or of your account with us, you agree to
                    notify us immediately at{' '}
                    <StyledOpenNewTab
                      href="mailto:hedgie@hedgie.io"
                      target="_blank"
                    >
                      hedgie@hedgie.io
                    </StyledOpenNewTab>
                    .
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="3. Hedgie Reservation" />
                  <p className="ff-medium mt-4">
                    A. Except as otherwise stated, these Terms will govern
                    reservation of your Hedgie character.
                  </p>
                  <p className="ff-medium mt-4">
                    B. You may reserve up to four Hedgie characters per one
                    account using your full legal name and identification. You
                    may not use any alternative names, inaccurate or untrue
                    information to reserve additional Hedgie characters.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="4. Ownership; Restrictions" />
                  <p className="ff-medium mt-4">
                    A. You acknowledge and agree that we own all legal right,
                    title and interest in and to all elements of the Hedgie
                    Site, and all intellectual property rights therein. The
                    visual interfaces, graphics (including, without limitation,
                    all art and drawings associated with Hedgie), design,
                    systems, methods, information, computer code, software,
                    services, “look and feel”, organization, compilation of the
                    content, code, data, and all other elements of the Hedgie
                    Site.
                  </p>
                  <p className="ff-medium mt-4">
                    B. Your use of the Hedgie Site does not grant you ownership,
                    or any other rights with respect to any content, code, data,
                    or other materials that you may access on or through the
                    Hedgie Site. A purchase of a Hedgie only grants you license
                    to play the Game in accordance with these Terms. Hedgie Inc.
                    may revoke the above contemplated license at its sole and
                    absolute discretion. You further agree not to use, change or
                    delete any content the Site or content downloaded from the
                    Site.
                  </p>
                  <p className="ff-medium mt-4">
                    C. You may choose to submit comments, bug reports, ideas or
                    other feedback about the Site, including without limitation
                    about how to improve the Hedgie Site (collectively,
                    “Feedback”). By submitting any Feedback, you agree that we
                    are free to use such Feedback at our discretion and without
                    additional compensation to you, and to disclose such
                    Feedback to third parties (whether on a non-confidential
                    basis, or otherwise). You hereby grant us a perpetual,
                    irrevocable, nonexclusive, worldwide license under all
                    rights necessary for us to incorporate and use your Feedback
                    for any purpose.
                  </p>
                  <p className="ff-medium mt-4">
                    D. You agree that you are responsible for your own conduct
                    while accessing or using the Hedgie Site, and for any
                    consequences thereof. You agree to use the Hedgie Site only
                    for purposes that are legal, proper and in accordance with
                    these Terms and laws regulations. By way of example, and not
                    as a limitation, you may not, and may not allow any third
                    party to:
                    <br />
                    <br />
                    (i) send, upload, distribute or disseminate any unlawful,
                    defamatory, harassing, abusive, fraudulent, obscene, or
                    otherwise objectionable content;
                    <br />
                    <br />
                    (ii) distribute viruses, worms, defects, trojan horses,
                    corrupted files, hoaxes, or any other items of a destructive
                    or deceptive nature; <br />
                    <br />
                    (iii) impersonate another person (via the use of an email
                    address or otherwise);
                    <br />
                    <br />
                    (iv) upload, post, transmit or otherwise make available
                    through the Site any content that infringes the intellectual
                    proprietary rights of any party; <br />
                    <br />
                    (v) use the Hedgie Site to violate the legal rights (such as
                    rights of privacy and publicity) of others; <br />
                    <br />
                    (vi) engage in, promote, or encourage illegal activity
                    (including, without limitation, money laundering); <br />
                    <br />
                    (vii) interfere with other users’ enjoyment of the Hedgie
                    Site; <br />
                    <br />
                    (viii) exploit the Hedgie Site for any unauthorized
                    commercial purpose; <br />
                    <br />
                    (ix) modify, adapt, translate, or reverse engineer any
                    portion of the Hedgie Site or any content downloaded from
                    the Hedgie Site; <br />
                    <br />
                    (x) remove any copyright, trademark or other proprietary
                    rights notices contained in or on the Hedgie Site or any
                    part of it; <br />
                    <br />
                    (xi) reformat or frame any portion of the Hedgie Site;{' '}
                    <br />
                    <br />
                    (xii) display any content on the Hedgie Site that contains
                    any hate-related or violent content or contains any other
                    material, products or services that violate or encourage
                    conduct that would violate any criminal laws, any other
                    applicable laws, or any third party rights; <br />
                    <br />
                    (xiii) use any robot, spider, search/retrieval site, or
                    other device to retrieve or index any portion of the Hedgie
                    Site or the content posted on the Hedgie Site, or to collect
                    information about its users for any unauthorized purpose;{' '}
                    <br />
                    <br />
                    (xiv) create user accounts by automated means or under false
                    or fraudulent pretenses; or <br />
                    <br />
                    (xv) access or use the Hedgie Site for the purpose of
                    creating a product or service that is competitive with any
                    of our products or services.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="5. Termination" />
                  <p className="ff-medium mt-4">
                    You may terminate these Terms at any time by canceling your
                    account on the Hedgie Site and discontinuing your access to
                    and use of the Hedgie Site. You will not receive any refunds
                    if you cancel your account, or otherwise terminate these
                    Terms. You agree that we, in our sole discretion and for any
                    or no reason, may terminate these Terms and suspend and/or
                    terminate your account(s) for the Hedgie Site. You agree
                    that any suspension or termination of your access to the
                    Hedgie Site may be without prior notice, and that we will
                    not be liable to you or to any third party for any such
                    suspension or termination. If we terminate these Terms or
                    suspend or terminate your access to or use of the Hedgie
                    Site due to your breach of these Terms or any suspected
                    fraudulent, abusive, or illegal activity, then termination
                    of these Terms will be in addition to any other remedies we
                    may have at law or in equity. Upon any termination or
                    expiration of these Terms, whether by you or us, you may no
                    longer have access to information that you have posted on
                    the Hedgie Site or that is related to your account, and you
                    acknowledge that we will have no obligation to maintain any
                    such information in our databases or to forward any such
                    information to you or to any third party. Sections 2.C and 4
                    through 17 will survive the termination or expiration of
                    these Terms for any reason.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="6. Disclaimers" />
                  <p className="ff-medium mt-4">
                    A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO
                    AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE
                    IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF
                    ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                    PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, OUR
                    SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS
                    WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES
                    REGARDING THE SITE AND ANY PART OF IT, INCLUDING THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR
                    RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE
                    FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS
                    DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO
                    OR USE OF THE SITE WILL MEET YOUR REQUIREMENTS, (II) YOUR
                    ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY,
                    SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH
                    THE SITE WILL BE ACCURATE, (III) THE SITE OR ANY CONTENT,
                    SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE
                    ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV)
                    THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL
                    BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                    IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR
                    ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                  </p>
                  <p className="ff-medium mt-4">
                    B. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                    INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE
                    THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH
                    OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
                  </p>
                  <p className="ff-medium mt-4">
                    C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY
                    LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE METAMASK
                    ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES,
                    DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS
                    FORGOTTEN PASSWORDS OR OTHER TRANSACTIONS; (B) SERVER
                    FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D)
                    UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES,
                    INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING,
                    BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE, OR
                    THE METAMASK ELECTRONIC WALLET.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="7. Limitation of Liability" />
                  <p className="ff-medium mt-4">
                    A. YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES,
                    AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO
                    ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR,
                    HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
                    INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER
                    INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR
                    BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF
                    SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS,
                    EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES.
                  </p>
                  <p className="ff-medium mt-4">
                    B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR
                    ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS
                    OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR
                    USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT,
                    STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO
                    THE LESSER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER
                    THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE
                    CLAIM AROSE, OR (B) CAD $10,000.
                  </p>
                  <p className="ff-medium mt-4">
                    C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE
                    AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE
                    UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY
                    SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR
                    ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL
                    BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO
                    PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
                  </p>
                  <p className="ff-medium mt-4">
                    D. YOU AGREE THAT THE FOLLOWING LIMITATION OF LIABILITY WILL
                    BE APPLIED TO THE FULLEST EXTENT OF THE LAW OF YOUR
                    JURISDICTION. YOU FURTHER ACKNOWLEDGE THAT TO THE EXTENT ANY
                    OF THE ABOVE IS NOT APPLICABLE OR OTHERWISE NOT PERMISSABLE
                    IN YOUR JURISDICTION, ONLY SUCH LANGUAGE THAT IS DIRECTLY
                    NON APPLICABLE OR OTHERWISE NOT PERMISSABLE WILL BE WAIVED
                    AND ALL OTHER LANGUAGE WILL CONTINUE TO APPLY.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="8. Indemnification" />
                  <p className="ff-medium mt-4">
                    You agree to hold harmless and indemnify Hedgie Inc. and its
                    subsidiaries, affiliates, officers, agents, employees,
                    advertisers, licensors, suppliers or partners from and
                    against any claim, liability, loss, damage (actual and
                    consequential) of any kind or nature, suit, judgment,
                    litigation cost, and attorneys’ fees arising out of or in
                    any way related to (i) your breach of these Terms, (ii) your
                    use of the Hedgie Site, or (iii) your violation of
                    applicable laws, rules or regulations in connection with
                    your access to or use of the Hedgie Site. You agree that
                    Hedgie Inc. will have control of the defense or settlement
                    of any such claims and will notify Hedgie Inc. within 5
                    business days of receiving a claim described above.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="9. Assumption of Risk" />
                  <p className="ff-medium mt-4">
                    You accept and acknowledge each of the following:
                  </p>
                  <p className="ff-medium mt-4">
                    A. We cannot guarantee nor is Hedgie Inc. responsible for
                    any records, information or purchases of Hedgie or Game
                    information that may become unavailable or otherwise lost.
                  </p>
                  <p className="ff-medium mt-4">
                    B. For absolute clarity, any Hedgie information, purchase
                    information or Game information is not the property of any
                    individual or entity other than Hedgie Inc. If any of the
                    above becomes lost or destroyed, no third party should have
                    any expectation of monetary reimbursement or retrieval of
                    information. Specifically, your purchased Hedgie or Game
                    progress may be lost or destroyed and a user should have no
                    specific expectation of reimbursement.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="10. Hyperlinks" />
                  <p className="ff-medium mt-4">
                    The Site may contain links to third-party web sites or
                    services that are not owned or controlled by us.
                  </p>
                  <p className="ff-medium mt-4">
                    We have no control over, and assume no responsibility for,
                    the content, privacy policies, or practices of any third
                    party web sites or services. You further acknowledge and
                    agree that we shall not be responsible or liable, directly
                    or indirectly, for any damage or loss caused or alleged to
                    be caused by or in connection with use of or reliance on any
                    such content, goods or services available on or through any
                    such websites or services.
                  </p>
                  <p className="ff-medium mt-4">
                    We strongly advise you to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that you visit.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="11. Changes to the Site" />
                  <p className="ff-medium mt-4">
                    We may make changes to the Terms from time to time. When we
                    make changes, we will make the updated Terms available on
                    the Site and update the “Last Updated” date at the beginning
                    of these Terms accordingly. Please check these Terms
                    periodically for changes. Any changes to the Terms will
                    become effective on the date that they are made, and your
                    continued access to or use of the Site after the Terms have
                    been updated will constitute your binding acceptance of the
                    updates. If you do not agree to any revised Terms, you may
                    not access or use the Site.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="11. Changes to the Site" />
                  <p className="ff-medium mt-4">
                    We may make changes to the Terms from time to time. When we
                    make changes, we will make the updated Terms available on
                    the Site and update the “Last Updated” date at the beginning
                    of these Terms accordingly. Please check these Terms
                    periodically for changes. Any changes to the Terms will
                    become effective on the date that they are made, and your
                    continued access to or use of the Site after the Terms have
                    been updated will constitute your binding acceptance of the
                    updates. If you do not agree to any revised Terms, you may
                    not access or use the Site.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="12. Copyright Policy" />
                  <p className="ff-medium mt-4">
                    We respect the intellectual property rights of others. It is
                    our policy to respond to any claim that content or user
                    content posted on the Site infringes the copyright or other
                    intellectual property infringement of any person.
                  </p>
                  <p className="ff-medium mt-4">
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement that
                    is taking place through the Site, you must submit your
                    notice (and include in your notice a detailed description of
                    the alleged infringement) in writing to the attention of:{' '}
                    <StyledOpenNewTab
                      href="mailto:hedgie@hedgie.io"
                      target="_blank"
                    >
                      hedgie@hedgie.io
                    </StyledOpenNewTab>
                    .
                  </p>
                  <p className="ff-medium mt-4">
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresenting that any content or
                    user Content is infringing your copyright.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="13. Governing Law" />
                  <p className="ff-medium mt-4">
                    These Terms shall be governed by, and interpreted and
                    enforced in accordance with, the laws in the Province of
                    Ontario and the laws of Canada, as applicable.
                  </p>
                  <p className="ff-medium mt-4">
                    If any provision of these Terms is held to be invalid or
                    unenforceable by a court of competent jurisdiction, then any
                    remaining provisions of these Terms will remain in effect.
                    These Terms constitute the entire agreement between you and
                    us regarding the use of our Site, and supersede and replace
                    any prior agreements, oral or otherwise, regarding the Site.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="14. Children" />
                  <p className="ff-medium mt-4">
                    You affirm that you are over the age of 13, as the Site is
                    not intended for children under 13. IF YOU ARE 13 OR OLDER
                    BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY WHERE
                    YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF
                    MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR
                    PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR
                    PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU
                    AGREE TO HAVE YOUR PARENT OR GUARDIAN REVIEW AND ACCEPT
                    THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN
                    AGREEING TO THE TERMS FOR THE BENEFIT OF A CHILD OVER 13,
                    THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT
                    CHILD’S USE OF THE SITE, INCLUDING ALL FINANCIAL CHARGES AND
                    LEGAL LIABILITY THAT HE OR SHE MAY INCUR.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="15. Privacy Policy" />
                  <p className="ff-medium mt-4">
                    Our <Link to="/privacy">Privacy Policy</Link> describes the
                    ways we collect, use, store and disclose your personal
                    information, and is hereby incorporated by this reference
                    into these Terms. You agree to the collection, use, storage,
                    and disclosure of your data in accordance with our{' '}
                    <Link to="/privacy">Privacy Policy</Link>.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="16. Dispute Resolution; Arbitration" />
                  <p className="ff-medium mt-4">
                    All disputes arising out of or in connection with these
                    Terms, including without limitation your access or use of
                    the Site or to any products sold or distributed through the
                    Site or derived from these Terms, will be finally resolved
                    by arbitration under the Simplified Arbitration Rules of the
                    ADR Institute of Canada, Inc. The place of arbitration will
                    be Toronto. The language of the arbitration will be English.
                    The award of the arbitrator will be final and binding, and
                    any judgment on the award rendered by the arbitrator may be
                    entered in any court of competent jurisdiction.
                    Notwithstanding the foregoing, Hedgie Inc. may seek and
                    obtain injunctive relief in any jurisdiction in any court of
                    competent jurisdiction, and you agree that these Terms are
                    specifically enforceable by Hedgie Inc. through injunctive
                    relief and other equitable remedies without proof of
                    monetary damages.
                  </p>
                  <p className="ff-medium mt-4">
                    WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO
                    THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED
                    TO THE SITE, OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE
                    SITE: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A
                    TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR
                    RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN
                    ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION
                    LAWSUITS INVOLVING ANY SUCH DISPUTE.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="17. General" />
                  <p className="ff-medium mt-4">
                    These Terms constitute the entire legal agreement between
                    you and Hedgie Inc., govern your access to and use of the
                    Site, and completely replace any prior or contemporaneous
                    agreements between the parties related to your access to or
                    use of the Site, whether oral or written. There are no third
                    party beneficiaries to these Terms. The parties are
                    independent contractors, and nothing in these Terms create
                    any agency, partnership, or joint venture. The language in
                    these Terms will be interpreted as to its fair meaning, and
                    not strictly for or against any party. You may not assign
                    any or your rights or obligations under these Terms, whether
                    by operation of law or otherwise, without our prior written
                    consent. We may assign our rights and obligations under
                    these Terms in our sole discretion to an affiliate, or in
                    connection with an acquisition, sale or merger. Should any
                    part of these Terms be held invalid or unenforceable, that
                    portion shall be construed consistent with applicable law
                    and the remaining portions will remain in full force and
                    effect. Our failure to enforce any provision of these Terms
                    will not be deemed a waiver of such provision, nor of the
                    right to enforce such provision. These Terms will be
                    governed by and construed in accordance with the laws of the
                    province of Ontario, Canada and the federal laws of Canada
                    applicable therein, excluding its conflicts of law rules and
                    principles. We will not be liable for any failure or delayed
                    performance of our obligations that result from any
                    condition beyond our reasonable control, including, but not
                    limited to, governmental action, acts of terrorism,
                    earthquake, fire, flood, acts of God, labor conditions,
                    power failures, Internet disturbances, or acts or omissions
                    of third parties. You agree that we may provide you with
                    notices (including, without limitation those regarding
                    changes to these Terms) by email, regular mail, or postings
                    on the Site. By providing us with your email address, you
                    consent to our using the email address to send you any
                    notices required by law in lieu of communication by postal
                    mail.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
TermsPage.propTypes = {
  user: types.user,
};
TermsPage.defaultProps = {
  user: null,
};
export default TermsPage;

import React from 'react';
import PropTypes from 'prop-types';
import { HeadLine } from 'components';
import styled from 'styled-components';
import Linkify from 'react-linkify';

const StyledList = styled.div`
  list-style: decimal;
`;

const HowToRedeem = ({ instructions, ...props }) => {
  return (
    <div {...props}>
      <HeadLine title="How to Redeem" align="left" fontSize={18} color="#000" />
      <StyledList>
        {instructions.map(instruction => (
          <li key={instruction}><Linkify properties={{ target: '_blank' }}>{instruction}</Linkify></li>
        ))}
      </StyledList>
    </div>
  );
};

HowToRedeem.propTypes = {
  instructions: PropTypes.array,
};

HowToRedeem.defaultProps = {
  instructions: [],
};

export default React.memo(HowToRedeem);

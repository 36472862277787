import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as paths from 'config/paths';
import { Nav, NavItem, NavLink } from 'reactstrap';
import CurioBalance from './CurioBalance';
import useOnClickOutside from '../../custom-hooks/useOnClickOutside';

const CloseBtn = styled.button`
  position: absolute;
  right: 2px;
  top: -28px;
  color: #804f6d;
  font-size: 60px;
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
`;

const DestkopSidebar = ({
  isOpened,
  email,
  onClose,
  curioBalance,
  toggleSidebar,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => (isOpened ? toggleSidebar(false) : null));

  return (
    <div
      className={`desktop-sidebar ${isOpened ? 'opened' : 'closed'}`}
      ref={ref}
    >
      <div className="sidebar-content">
        <CloseBtn onClick={onClose}>
          <span>×</span>
        </CloseBtn>
        <Nav className="desktopUserMenuNav">
          <div className="desktopUserMenu">
            <NavItem className="navEmail">{email}</NavItem>
            <CurioBalance balance={curioBalance} onClick={onClose} />
            <NavItem onClick={onClose}>
              <NavLink tag={Link} to={paths.SETTINGS}>
                Settings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.ALL_BUSINESSES}>
                Businesses
              </NavLink>
            </NavItem>
            <NavItem onClick={onClose}>
              <NavLink tag={Link} to={paths.SIGNOUT}>
                Sign Out
              </NavLink>
            </NavItem>
          </div>
        </Nav>
      </div>
    </div>
  );
};

DestkopSidebar.propTypes = {
  email: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  toggleSidebar: PropTypes.func,
  curioBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DestkopSidebar.defaultProps = {
  email: null,
  isOpened: false,
  onClose: () => {},
  curioBalance: 0,
  toggleSidebar: null,
};

export default DestkopSidebar;

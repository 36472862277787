const merge = require('lodash/merge');

const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.REACT_APP_PUBLIC_PATH,
    isBrowser: typeof window !== 'undefined',
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
    domain: process.env.REACT_APP_DOMAIN,
    firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: '',
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      publicVapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
    },
    paymentAddress: process.env.REACT_APP_HEDGIE_PAYMENT_WALLET_ADDRESS,
  },
  test: {},
  development: {
    apiUrl: process.env.REACT_APP_API_URL,
    staticUrl: process.env.REACT_APP_STATIC_URL,
    ethSignText: process.env.REACT_APP_ETH_SIGN_TEXT,
    prefillForms: process.env.REACT_APP_DEBUG_PREFILL_FORMS === 'true',
    socketUrl: process.env.REACT_APP_SOCKET_URL,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL,
    staticUrl: process.env.REACT_APP_STATIC_URL,
    ethSignText: process.env.REACT_APP_ETH_SIGN_TEXT,
    prefillForms: false,
    socketUrl: process.env.REACT_APP_SOCKET_URL,
  },
};

module.exports = merge(config.all, config[config.all.env]);

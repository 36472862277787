import { createSelector } from 'reselect';
import { minBy, isEmpty } from 'lodash';
import Hedgie from 'models/Hedgie';

const journeySelector = state => state.journey;
const journeyListSelector = state => state.journey.journeys;
const worldsSelector = state => state.journey.worlds;
const worldSelector = state => state.journey.world;
const goalSelector = state => state.journey.goal;
const createdJourneySelector = state => state.journey.createdJourney;
const rewardSelector = state => state.journey.reward;
const archivedJourneySelector = state => state.journey.archivedJourney;
const costsSelector = state => state.journey.costs;

export const selectSelectedWorld = createSelector(
  worldSelector,
  world => world.selectedWorld,
);

export const selectJourneysList = createSelector(
  journeyListSelector,
  journeys => journeys.data,
);

export const selectJourneyPagination = createSelector(
  journeyListSelector,
  journeys => journeys.pagination,
);

export const selectJourneysError = createSelector(
  journeyListSelector,
  journeys => ({
    error: journeys.error,
    message: journeys.message,
  }),
);

export const selectJourneysLoading = createSelector(
  journeyListSelector,
  journeys => journeys.loading,
);

export const selectSelectedHedgie = createSelector(
  journeySelector,
  journey => journey.selectedJourneyHedgie,
);

export const selectCreatedJourney = createSelector(
  createdJourneySelector,
  createdJourney => createdJourney.data,
);

export const selectCreatedJourneyLoading = createSelector(
  createdJourneySelector,
  createdJourney => createdJourney.loading,
);

export const selectCreatedJourneyError = createSelector(
  createdJourneySelector,
  createdJourney => ({
    error: createdJourney.error,
    message: createdJourney.message,
  }),
);

export const selectWorlds = createSelector(
  worldsSelector,
  worlds => worlds.data,
);

export const selectWorldsLoading = createSelector(
  worldsSelector,
  worlds => worlds.loading,
);

export const selectWorldsError = createSelector(
  worldsSelector,
  worlds => ({
    error: worlds.error,
    message: worlds.message,
  }),
);

export const selectRewards = createSelector(
  rewardSelector,
  reward => reward.data,
);

export const selectRewardsLoading = createSelector(
  rewardSelector,
  reward => reward.loading,
);

export const selectRewardsError = createSelector(
  rewardSelector,
  reward => ({
    error: reward.error,
    message: reward.message,
  }),
);

export const selectSelectedGoal = createSelector(
  goalSelector,
  goal => goal,
);

export const selectArchivedJourney = createSelector(
  archivedJourneySelector,
  archived => archived,
);

export const selectJourneyCostsData = createSelector(
  costsSelector,
  costs => costs.data,
);

export const selectJourneyCostsLoading = createSelector(
  costsSelector,
  costs => costs.loading,
);

export const selectJourneyCostsError = createSelector(
  costsSelector,
  costs => costs.error,
);

export const selectLowestJourneyEnergy = createSelector(
  costsSelector,
  costs => {
    if (isEmpty(costs.data)) {
      return 0;
    }
    const { energy = 0 } = minBy(costs.data, 'energy');
    return energy;
  },
);

export const selectIsPurchased = createSelector(
  selectSelectedHedgie,
  hedgie => Hedgie.isPurchased(hedgie),
);

export const selectIsReserved = createSelector(
  selectSelectedHedgie,
  hedgie => Hedgie.isReserved(hedgie),
);

export const selectIsPending = createSelector(
  selectSelectedHedgie,
  hedgie => Hedgie.isPending(hedgie),
);

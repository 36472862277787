import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import styled from 'styled-components';
import * as paths from 'config/paths';
import treasureImg from 'images/treasure.png';
import { trackSignUpToUnveilTreasures } from '../../../tracking';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTreasure = styled.div`
  width: ${props => (props.isMobile ? '200px' : '340px')};
  img {
    margin-top: ${props => (props.isMobile ? '-40px' : '-100px')};
    width: 100%;
    height: auto;
  }
`;

const EndOnBoarding = props => {
  const gotoSignUpPage = () => {
    trackSignUpToUnveilTreasures();
    props.history.push(paths.SIGNUP);
    props.emitHeaderVersion();
  };

  return (
    <StyledContainer>
      <StyledTreasure isMobile={props.isMobile}>
        <img src={treasureImg} alt="treasure" />
      </StyledTreasure>
      <Button onClick={gotoSignUpPage}>Signup to Unveil Treasures</Button>
    </StyledContainer>
  );
};

EndOnBoarding.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  emitHeaderVersion: PropTypes.func,
  isMobile: PropTypes.bool,
};

EndOnBoarding.defaultProps = {
  emitHeaderVersion: null,
  isMobile: false,
};

export default EndOnBoarding;

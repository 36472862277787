import React from 'react';
import PropTypes from 'prop-types';
import { CardGroup, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';

const WorldItem = ({ title, img, text }) => (
  <Card>
    <CardImg src={img} alt={title} />
    <CardBody>
      <CardTitle>{title}</CardTitle>
      <CardText>{text}</CardText>
    </CardBody>
  </Card>
);

WorldItem.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const WorldItems = () => {
  return (
    <div>
      <CardGroup>
        <WorldItem title="Curio" img="" text="When you collect enough loot, you’ll be able to trade it in for ‘Curio’ AKA crypto coin! Use your coin to buy gear* for your Hedgie, or use it as currency to be spent in the real world." />
        <WorldItem title="Loot" img="" text="Your Hedgie will find a mix of edible and non-edible loot along its journey. Consume edible loot right away for a 24-hour power boost!" />
        <WorldItem title="Collections" img="" text="Instead of using loot right away, you can collect every kind of edible or non-edible loot and trade it in for a huge week-long boost!" />
      </CardGroup>
      <p>*Gear helps permanently boost your Hedgie’s skills. Every adorable piece of gear helps improve performance further.If all your gear is the same hex colour as your Hedgie, it’ll become a crypto coin-making machine!</p>
    </div>
  );
};

export default WorldItems;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import mobileArrow from '../images/mobileArrow.svg';

export const upAndDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const StyledContainer = styled.div`
  position: absolute;
  bottom: ${props => props.bottom}px;
  left: 50%;
  transform: translate(-50%);
  &:hover {
    cursor: pointer;
  }
`;
const StyledGr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledArrow = styled.div`
  animation: ${upAndDown} 2s ease-in-out infinite;
`;
const StyledText = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.color};
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
`;

const Anchor = ({ onClick, arrow, text, textColor, ...props }) => {
  const clickCallback = useCallback(
    () => {
      onClick();
    },
    [onClick],
  );
  return (
    <StyledContainer onClick={clickCallback} {...props}>
      <StyledGr>
        <StyledText color={textColor}>{text}</StyledText>
        <StyledArrow>
          <img src={arrow || mobileArrow} alt="arrow" />
        </StyledArrow>
      </StyledGr>
    </StyledContainer>
  );
};

Anchor.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  arrow: PropTypes.any,
  bottom: PropTypes.number,
};

Anchor.defaultProps = {
  text: '',
  onClick: null,
  textColor: '',
  arrow: '',
  bottom: 60,
};

export default Anchor;

/* eslint react/no-danger: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { whiteTemplate, genericTemplate } from './svg-templates.js';

const createSVG = (width, color, maxWidth) => {
  const ref = color.replace('#', '');
  const svgId = `hedgie_${ref}`;
  const svgIdRef = `#${svgId}`;

  const svg =
    color === '#ffffff'
      ? whiteTemplate(width, svgId, svgIdRef, maxWidth)
      : genericTemplate(color, width, svgId, svgIdRef, maxWidth);

  return { __html: svg };
};

const containerClasses = className =>
  classnames('hedgie-image', {
    [className]: className,
  });

const HedgieImg = React.memo(
  ({ width, color, className, maxWidth, imgURL }) => {
    if (imgURL && imgURL.length) {
      return (
        <div className={containerClasses(className)}>
          <img
            src={imgURL}
            alt={`Hedgie with color ${color}`}
            width={width}
            maxwidth={maxWidth}
          />
        </div>
      );
    }

    return (
      <div
        className={containerClasses(className)}
        dangerouslySetInnerHTML={createSVG(width, color, maxWidth)}
      />
    );
  },
);

HedgieImg.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  imgURL: PropTypes.string,
};

HedgieImg.defaultProps = {
  className: '',
  maxWidth: '100%',
  imgURL: null,
  height: null,
  width: '100%',
};

export default HedgieImg;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import * as paths from 'config/paths';
import topNavBg from '../../../images/home-backround.png';
import { StyledBranch } from './commonStyles';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import Notification from 'containers/Notification';
import { Button } from 'components';
import { PRIVATE_MENUS, PUBLIC_MENUS } from 'config/menus';
import Menu from './Menu';
import SidebarToggler from './SidebarToggler';
import DesktopSidebar from './DesktopSidebar';
import { loggedInCls } from './index';

const StyledHeaderBg = styled.div`
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
`;

const DesktopNav = ({
  authenticated,
  email,
  curioBalance,
  toggleSidebar,
  sidebarOpened,
}) => {
  const menus = authenticated ? PRIVATE_MENUS : PUBLIC_MENUS;

  return (
    <StyledHeaderBg src={topNavBg}>
      <Navbar className={loggedInCls(authenticated)} dark expand="lg">
        <StyledBranch>
          <Link to="/">Hedgie</Link>
        </StyledBranch>
        <Nav className="ml-auto" navbar>
          {menus.map(nav => (
            <Menu {...nav} key={nav.id} />
          ))}
          {authenticated ? (
            <React.Fragment>
              <NavItem>
                <NavLink tag={Link} to={paths.SETTINGS}>
                  {email}
                </NavLink>
              </NavItem>
              <Notification />
              <SidebarToggler className="ml-3" onClick={toggleSidebar} />
            </React.Fragment>
          ) : (
            <NavItem>
              <Button tag={Link} className="nav-link" to={paths.SIGNIN}>
                Sign In
              </Button>
            </NavItem>
          )}
          <DesktopSidebar
            isOpened={sidebarOpened}
            onClose={toggleSidebar}
            email={email}
            curioBalance={curioBalance}
            toggleSidebar={toggleSidebar}
          />
        </Nav>
      </Navbar>
    </StyledHeaderBg>
  );
};

DesktopNav.propTypes = {
  email: PropTypes.string,
  curioBalance: PropTypes.string,
  authenticated: PropTypes.bool,
  sidebarOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};
DesktopNav.defaultProps = {
  email: '',
  curioBalance: '',
  authenticated: false,
  sidebarOpened: false,
  toggleSidebar: null,
};

export default React.memo(DesktopNav);

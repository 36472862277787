import React from 'react';
// import PropTypes from 'prop-types';

class Gear extends React.PureComponent {
  render() {
    return <div className="align-center">Coming soon!</div>;
  }
}

// Gear.propTypes = {};
//
// Gear.defaultProps = {};

export default Gear;

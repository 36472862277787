import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import styled from 'styled-components';
import energySvgUrl from 'images/energy_icon.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledProgressBar = styled.div`
  margin-left: 6px;
  width: 100%;
  .progress {
    height: 16px;
    border-radius: 8px;
    .progress-bar {
      background-color: ${props => props.color};
    }
  }
`;

const HedgieEnergy = props => {
  const { energy, color, maxEnergy, recovery } = props;
  return (
    <div>
      <StyledContainer {...props}>
        <img width={20} src={energySvgUrl} alt="energy_hedgie" />
        <span className="pl-1">Energy:</span>
        <StyledProgressBar color={color}>
          <Progress value={energy} max={maxEnergy}>{energy}</Progress>
        </StyledProgressBar>
      </StyledContainer>
      <div>Hedgie Bonus Recovery Rate: +{recovery}/hour</div>
    </div>
  );
};

HedgieEnergy.propTypes = {
  energy: PropTypes.number,
  color: PropTypes.string,
  maxEnergy: PropTypes.number,
  recovery: PropTypes.number,
};

HedgieEnergy.defaultProps = {
  energy: 25,
  color: '#12DFC0',
  maxEnergy: 200,
  recovery: 0,
};

export default HedgieEnergy;

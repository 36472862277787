import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberUtil from 'services/numberUtil';
import styled from 'styled-components';
import {
  PageTitle,
  Tabs,
  CurioTransactions,
  CurioBalance,
  MyOrders,
} from 'components';

export const MyOrdersContext = React.createContext({});

const StyledContainer = styled.div`
  padding: 0 20px 40px 20px;
  max-width: 1140px;
  margin: 0 auto;
`;

const TransactionsPage = ({
  user,
  myOrders,
  getMyOrders,
  getCurioTransactionsRequest,
  curioTransactions,
  userIsReady,
}) => {
  const { curioBalance } = user;

  const memoizedCurioBalance = useMemo(
    () => NumberUtil.toDecimal(curioBalance),
    [curioBalance],
  );

  const tabs = [
    {
      id: 'recentActivity',
      name: 'Recent Activity',
      tabPanel: (
        <CurioTransactions
          getCurioTransactions={getCurioTransactionsRequest}
          curioTransactions={curioTransactions}
          user={user}
          userIsReady={userIsReady}
        />
      ),
    },
    {
      id: 'myOrders',
      name: 'My Orders',
      tabPanel: <MyOrders myOrders={myOrders} getMyOrders={getMyOrders} />,
    },
  ];

  return (
    <StyledContainer>
      <PageTitle title="Transaction History" />
      <CurioBalance curioBalance={memoizedCurioBalance} />
      <MyOrdersContext.Provider value={{ user, myOrders, getMyOrders }}>
        <Tabs tabsData={tabs} activeTab={tabs[0].id} />
      </MyOrdersContext.Provider>
    </StyledContainer>
  );
};

TransactionsPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,
  myOrders: PropTypes.object.isRequired,
  getMyOrders: PropTypes.func.isRequired,

  getCurioTransactionsRequest: PropTypes.func.isRequired,
  curioTransactions: PropTypes.object.isRequired,
  userIsReady: PropTypes.bool.isRequired,
};

export default TransactionsPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import {
  HedgieImg,
  SubHeading,
  Spinner,
  FormError,
  JourneyChooseDuration,
} from 'components';
import * as paths from 'config/paths';
import { HedgieImgContainer, hedgieImgWidth } from '../../stateless/MyHedgies';
import JourneyConfirmationPage from '../JourneyConfirmationPage';
import { StyledFlexBox } from '../../../styles/main';

class JourneySummaryPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    selectedJourneyHedgie: PropTypes.object,
    createJourney: PropTypes.func,
    resetJourneyState: PropTypes.func,
    createdJourney: PropTypes.object,
    createJourneyLoading: PropTypes.bool.isRequired,
    createJourneyError: PropTypes.object.isRequired,
    userIsReady: PropTypes.bool.isRequired,
    selectedWorld: PropTypes.object,
  };

  static defaultProps = {
    selectedJourneyHedgie: null,
    createJourney: null,
    createdJourney: null,
    resetJourneyState: null,
    selectedWorld: null,
  };

  componentWillUnmount() {
    this.props.resetJourneyState();
  }

  startJourney = selectedDuration => () => {
    const { selectedJourneyHedgie, createJourney, selectedWorld } = this.props;
    if (
      selectedDuration.value !== 0 &&
      selectedJourneyHedgie &&
      selectedWorld
    ) {
      const params = {
        hedgieId: selectedJourneyHedgie.id,
        duration: selectedDuration.value,
        worldId: selectedWorld.id,
      };
      createJourney(params);
    }
  };

  render() {
    const {
      selectedJourneyHedgie,
      createdJourney,
      createJourneyLoading,
      createJourneyError,
    } = this.props;
    if (!selectedJourneyHedgie) {
      return (
        <Redirect to={{ pathname: paths.NEW_JOURNEY, search: '?step=1' }} />
      );
    }
    return (
      <div>
        {createdJourney ? (
          <JourneyConfirmationPage
            hedgie={selectedJourneyHedgie}
            duration={createdJourney.duration}
          />
        ) : (
          <div
            style={{
              maxWidth: 800,
              margin: '0 auto 30px auto',
            }}
          >
            <Row>
              {selectedJourneyHedgie ? (
                <Col md={6}>
                  <HedgieImgContainer>
                    <HedgieImg
                      color={`#${selectedJourneyHedgie.color}`}
                      className="img-fluid"
                      width={hedgieImgWidth}
                      imgURL={selectedJourneyHedgie.imgURL}
                    />
                    <StyledFlexBox>
                      <Link
                        to={{ pathname: paths.NEW_JOURNEY, search: '?step=1' }}
                      >
                        Change Hedige
                      </Link>
                    </StyledFlexBox>
                  </HedgieImgContainer>
                </Col>
              ) : null}
              <Col md={6}>
                <SubHeading
                  className="pt-5 sub-heading-center"
                  heading={`#${selectedJourneyHedgie.color}`}
                />
                <JourneyChooseDuration startJourney={this.startJourney} />
                {createJourneyLoading ? <Spinner /> : null}
                {createJourneyError ? (
                  <FormError error={createJourneyError} />
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default JourneySummaryPage;

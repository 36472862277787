import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';
import { FormError } from 'components';
import { useSelector } from 'react-redux';
import {
  selectJourneyCostsData,
  selectJourneyCostsError,
} from 'store/journey/selectors';
import ChooseDuration from '../HeaderHomePage/ChooseDuration';

const DURATION_DATA_LIST = [60, 3 * 60, 6 * 60, 12 * 60, 24 * 60];

const getEnergyByDuration = (arr, duration) => {
  if (isEmpty(arr)) {
    return 0;
  }
  const existingDuration = find(arr, item => item.duration === duration * 60);
  //const existingDuration = find(arr, item => item.duration === duration);
  return existingDuration ? existingDuration.energy : 0;
};

const JourneyParsingDuration = ({
  startJourneyCallback,
  isNotEnergyEnough,
  checkEnoughEnergyCallback,
  selectedHedgie,
}) => {
  const journeyCostsList = useSelector(state => selectJourneyCostsData(state));
  const journeyCostsError = useSelector(state =>
    selectJourneyCostsError(state),
  );

  const durationsWithEnergy = useMemo(
    () =>
      DURATION_DATA_LIST.map(time => {
        if (getEnergyByDuration(journeyCostsList, time)) {
          return {
            duration: time,
            energy: getEnergyByDuration(journeyCostsList, time),
          };
        }
        return {
          duration: time,
        };
      }),
    [journeyCostsList],
  );
  return (
    <div>
      <ChooseDuration
        startJourney={startJourneyCallback}
        unitDuration="min"
        hasEnergy
        isNotEnergyEnough={isNotEnergyEnough}
        onDurationChange={checkEnoughEnergyCallback}
        hedgieEnergy={selectedHedgie ? selectedHedgie.energy : 0}
        durationsDataList={durationsWithEnergy}
      />
      {journeyCostsError.error ? <FormError error={journeyCostsError} /> : null}
    </div>
  );
};

JourneyParsingDuration.propTypes = {
  checkEnoughEnergyCallback: PropTypes.func,
  startJourneyCallback: PropTypes.func,
  isNotEnergyEnough: PropTypes.bool,
  selectedHedgie: PropTypes.object,
};

JourneyParsingDuration.defaultProps = {
  checkEnoughEnergyCallback: null,
  startJourneyCallback: null,
  isNotEnergyEnough: false,
  selectedHedgie: null,
};

export default JourneyParsingDuration;

// see https://firebase.google.com/docs/auth/admin/errors

export const firebaseAuthErrorBuilder = firebaseError => {
    let message = '';
    switch (firebaseError.code) {
        case 'auth/user-not-found': {
            message = 'Username and/or Password are incorrect';
            break;
        }
        case 'auth/email-already-in-use': {
            message = 'Email address is already in use';
            break;
        }
        case 'auth/invalid-email': {
            message = 'Email address is invalid';
            break;
        }
        case 'auth/wrong-password': {
            message = 'Incorrect Password';
            break;
        }
        case 'auth/weak-password': {
            message = firebaseError.message;
            break;
        }
        case 'auth/invalid-action-code': {
            message = 'Incorrect access code.  Please check the link you were sent';
            break;
        }
        default: {
            message = `General Error: ${firebaseError.code}`;
        }
    }
    return {
        message,
        origin: 'firebaseAuth',
        source: firebaseError,
    };
};

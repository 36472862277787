import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import * as types from 'types';
import styled from 'styled-components';

import { GET_HEDGIE } from 'config/paths';
import { HedgiePower, PriceDisplayNew, PageTitle, HedgieImg, CategoryTitle, Desktop, Mobile } from 'components';

const HedgieImgContainer = styled.div`
  position: absolute;
  right: 0;
  max-width: 435px;
  top: 90px;
`;

const HedgieMobileImgContainer = styled.div`
  position: relative;
  max-width: 435px;
`;

const BookingConfirmation = ({ hedgie, tier, error }) => {
  return (
    <div>
      <Container>
        <PageTitle title="Ready for your very own Hedgie?" />
        <Row className="row justify-content-center mt-4">
          <Col className="col-md-4 step-item step-odd text-center  p-0">
            <div className="step-number-holder">
              <span className="step-number ff-semi-bold">1</span>
            </div>
            <p className="semi-bold-title dark-color pt-3">
              <span className="ff-extra-bold fs-extra-md step-item step-title">
                Choose your one-of-a-kind <b>Hedgie</b>. Pick your favourite hex colour{' '}
              </span>
            </p>
          </Col>
          <Col className="col-md-4 step-item step-even text-center p-0 current-step">
            <div className="step-number-holder">
              <span className="step-number ">2</span>
            </div>
            <p className="semi-bold-title dark-color pt-3">
              <span className="ff-extra-bold fs-extra-md step-title">
                Sooo close!
              </span>
              <span className="fs-semi-lg ff-semi-bold">
                Confirm your booking below.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Desktop>
          <Row className="pos-rel">
            <Col md="6" sm="12" className="p-0">
              <div className="single-product">
                {error && <p className="booking-error color-error">{error.message}</p>}
                <Row>
                  <Col>
                    <CategoryTitle title={tier.tierName} />
                  </Col>
                </Row>
                <Row>
                  <Col className="">
                    <div className="pos-rel booking-confirmation">
                      <PriceDisplayNew hedgie={hedgie} /* price={tier ? tier.dollar : 5} */ />
                      <div className="price-label road-sign-reg">
                        <span className="ff-extra-bold color-contrast">
                          {`#${hedgie.color}`}
                        </span>
                      </div>
                      <Link to={GET_HEDGIE}>Change colour</Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pl-0">
                    <HedgiePower hedgie={hedgie} />
                  </Col>
                </Row>
              </div>
            </Col>

            <HedgieImgContainer className="img-fluid">
              <HedgieImg color={`#${hedgie.color}`} width="435" imgURL={hedgie.imgURL} />
            </HedgieImgContainer>
          </Row>
        </Desktop>

        <Mobile>
          <Row>
            <Col md="6" sm="12" className="p-0 justify-content-center">
              <div className="single-product">
                {error && <p className="booking-error color-error">{error.message}</p>}
                <Row>
                  <Col>
                    <CategoryTitle textAlign="text-center" title={tier.tierName} />
                  </Col>
                </Row>
                <Row className="">
                  <Col className="d-flex justify-content-center">
                    <div className="pos-rel booking-confirmation">
                      {/* <PriceDisplay price={tier ? tier.dollar : 5} /> */}
                      <PriceDisplayNew hedgie={hedgie} /* price={tier ? tier.dollar : 5} */ />
                      <div className="price-label road-sign-reg">
                        <span className="ff-extra-bold color-contrast">
                          {`#${hedgie.color}`}
                        </span>
                      </div>
                      <Link to={GET_HEDGIE}>Change colour</Link>
                    </div>
                  </Col>
                </Row>
                <Row className="testingyo">
                  <Col className="pl-0 d-flex justify-content-center">
                    <HedgiePower hedgie={hedgie} />
                  </Col>
                </Row>
              </div>
            </Col>
            <HedgieMobileImgContainer className="img-fluid">
              <HedgieImg color={`#${hedgie.color}`} width="435" imgURL={hedgie.imgURL} />
            </HedgieMobileImgContainer>
          </Row>
        </Mobile>
      </Container>
    </div>
  );
};

BookingConfirmation.propTypes = {
  hedgie: types.hedgie.isRequired,
  tier: types.tier.isRequired,
  onReserve: PropTypes.func.isRequired,
  error: types.error,
};

BookingConfirmation.defaultProps = {
  error: null,
};

export default BookingConfirmation;

import React from 'react';
// import PropTypes from 'prop-types';
import { Typography, Button } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as paths from 'config/paths';

const StyledWrapper = styled.div`
  background: #eff3f4;
  padding-top: 60px;
  padding-bottom: 64px;
`;

const ChooseYourHedgie = () => {
  return (
    <StyledWrapper>
      <Typography
        className="pb-3"
        text="What are you waiting for?"
        fontSize={23}
        isBold
        color="#62295E"
      />
      <div className="text-center" style={{ marginTop: -24 }}>
        <Link to={paths.SIGNUP}>
          <Button size="large">Get Started Now</Button>
        </Link>
      </div>
    </StyledWrapper>
  );
};

// ChooseYourHedgie.propTypes = {};

export default ChooseYourHedgie;

export const GET_RESOURCES_META = 'GET_RESOURCES_META';
export const GET_RESOURCES_META_SUCCESS = 'GET_RESOURCES_META_SUCCESS';
export const GET_RESOURCES_META_FAILURE = 'GET_RESOURCES_META_FAILURE';

export const GET_HOUSES = 'GET_HOUSES';
export const GET_HOUSES_SUCCESS = 'GET_HOUSES_SUCCESS';
export const GET_HOUSES_FAILURE = 'GET_HOUSES_FAILURE';

export const GET_FURNITURE_FOR_HOUSE = 'GET_FURNITURE_FOR_HOUSE';
export const GET_FURNITURE_FOR_HOUSE_SUCCESS =
  'GET_FURNITURE_FOR_HOUSE_SUCCESS';
export const GET_FURNITURE_FOR_HOUSE_FAILURE =
  'GET_FURNITURE_FOR_HOUSE_FAILURE';

export const BUILD_FURNITURE = 'BUILD_FURNITURE';
export const BUILD_FURNITURE_SUCCESS = 'BUILD_FURNITURE_SUCCESS';
export const BUILD_FURNITURE_FAILURE = 'BUILD_FURNITURE_FAILURE';
export const CLEAR_BUILDING_FURNITURE = 'CLEAR_BUILDING_FURNITURE';

export const GET_HOUSE_EFFECTS = 'GET_HOUSE_EFFECTS';
export const GET_HOUSE_EFFECTS_SUCCESS = 'GET_HOUSE_EFFECTS_SUCCESS';
export const GET_HOUSE_EFFECTS_FAILURE = 'GET_HOUSE_EFFECTS_FAILURE';

export const CANCEL_REQUEST = 'CANCEL_REQUEST';

export const CLEAN_HOUSE = 'CLEAN_HOUSE';
export const CLEAN_HOUSE_SUCCESS = 'CLEAN_HOUSE_SUCCESS';
export const CLEAN_HOUSE_FAILURE = 'CLEAN_HOUSE_FAILURE';
export const RESET_CLEAN_HOUSE = 'RESET_CLEAN_HOUSE';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLearnMore = styled.span`
  text-align: center;
  display: block;
  margin-bottom: ${props => (props.isMobile ? '34px' : '57px')};
  a {
    letter-spacing: 0;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
    color: #63275f;
    :hover {
      text-decoration: none;
      color: #63275f;
    }
  }
`;

const LearnMoreButton = ({ label, href, isMobile }) => {
  return (
    <StyledLearnMore isMobile={isMobile}>
      <Link to={href}>{label}</Link>
    </StyledLearnMore>
  );
};

LearnMoreButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  isMobile: PropTypes.bool,
};

LearnMoreButton.defaultProps = {
  href: '',
  isMobile: false,
};

export default LearnMoreButton;

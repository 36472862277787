// import 'whatwg-fetch';
import { stringify } from 'query-string';
import merge from 'lodash/merge';
import { apiUrl } from 'config/env';
import axios from './axiosConfig';

// export const parseJSON = response => {
//   return new Promise(resolve =>
//     response.data.then(json =>
//       resolve({
//         status: response.status,
//         ok: response.ok,
//         json,
//       }),
//     ),
//   );
// };

export const parseSettings = ({
  method = 'get',
  data,
  locale,
  ...otherSettings
} = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': locale,
  };
  const settings = merge(
    {
      data: data ? JSON.stringify(data) : undefined,
      method,
      headers,
    },
    otherSettings,
  );
  return settings;
};

export const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : apiUrl + endpoint;
  const querystring = params ? `?${stringify(params)}` : '';
  return `${url}${querystring}`;
};

const api = {};

api.request = (endpoint, { params, ...settings } = {}) => {
  return new Promise((resolve, reject) => {
    axios(parseEndpoint(endpoint, params), parseSettings(settings))
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        // extract the error from the server's json
        return reject(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request);
        } else {
          reject(error.message);
        }
      });
  });
};
['delete', 'get'].forEach(method => {
  api[method] = (endpoint, settings) =>
    api.request(endpoint, { method, ...settings });
});
['post', 'put', 'patch'].forEach(method => {
  api[method] = (endpoint, data, settings) =>
    api.request(endpoint, { method, data, ...settings });
});

let apiObjectInternal = null;

export const apiInstance = () => apiObjectInternal;

api.create = (settings = {}) => {
  apiObjectInternal = {
    settings,

    setToken(token) {
      this.settings.headers = {
        ...this.settings.headers,
        Authorization: `Bearer ${token}`,
      };
    },

    unsetToken() {
      this.settings.headers = {
        ...this.settings.headers,
        Authorization: undefined,
      };
    },

    request(endpoint, settings) {
      return api.request(endpoint, merge({}, this.settings, settings));
    },

    post(endpoint, data, settings) {
      return this.request(endpoint, { method: 'post', data, ...settings });
    },

    get(endpoint, settings) {
      return this.request(endpoint, { method: 'get', ...settings });
    },

    put(endpoint, data, settings) {
      return this.request(endpoint, { method: 'put', data, ...settings });
    },

    patch(endpoint, data, settings) {
      return this.request(endpoint, { method: 'patch', data, ...settings });
    },

    delete(endpoint, settings) {
      return this.request(endpoint, { method: 'delete', ...settings });
    },
  };
  return apiObjectInternal;
};

export default api;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledViewMore = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column: 1 / -1;
  grid-gap: 8px;
  align-items: center;

  span {
    color: #007bff;
    cursor: pointer;
  }
  &:after,
  &:before {
    content: '';
    display: block;
    background-color: #e0e0e0;
    height: 1px;
  }
`;

const ViewMore = ({ moreList, itemsLength, previewList }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const viewMoreCallback = useCallback(() => {
    setIsExpanded(isExpanded => !isExpanded);
  }, []);

  if (itemsLength <= 2) {
    return previewList;
  }

  return (
    <StyledWrapper>
      {previewList}
      <Collapse isOpen={isExpanded}>{moreList}</Collapse>
      <StyledViewMore>
        <span onClick={viewMoreCallback}>{`${isExpanded ? '-' : '+'} View ${
          isExpanded ? 'less' : 'more'
        }`}</span>
      </StyledViewMore>
    </StyledWrapper>
  );
};

ViewMore.propTypes = {
  moreList: PropTypes.element.isRequired,
  previewList: PropTypes.element.isRequired,
  itemsLength: PropTypes.number.isRequired,
};

export default ViewMore;

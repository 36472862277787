import React from 'react';
import PropTypes from 'prop-types';
import { FormError, PageTitle } from 'components';
import styled from 'styled-components';
import NumberUtil from 'services/numberUtil';
import { Redirect } from 'react-router';
import { SIGNIN } from 'config/paths';
import HeadLine from '../../stateless/HeadLine';
import ProductList from './ProductList';
import UserInfo from './UserInfo';
import ConfirmationMessage from './ConfirmationMessage';

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ProductOrderConfirmationPage = ({
  match,
  product,
  getProductDetail,
  businessDetail,
  getBusinessDetail,
  user,
  history,
  createAnOrder,
  orderedProduct,
  clearOrderState,
  authenticated,
  location,
}) => {
  const { productId, businessId } = match.params;

  React.useEffect(
    () => {
      getBusinessDetail(businessId);
    },
    [getBusinessDetail, businessId],
  );

  function cancelOrder() {
    clearOrderState();
    history.push(`/product/${productId}`);
  }

  function handleOrderProduct(productId) {
    createAnOrder(productId);
  }

  const { curioBalance } = user;
  const { curioPrice } = product.data;

  const userBalance = React.useMemo(() => NumberUtil.toDecimal(curioBalance), [
    curioBalance,
  ]);

  const productTotal = React.useMemo(() => NumberUtil.toDecimal(curioPrice), [
    curioPrice,
  ]);

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: SIGNIN,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <StyledContainer>
      <div>
        <PageTitle title="Payment" />
        <HeadLine
          title={businessDetail.data.name}
          color="#000000"
          fontSize={20}
        />
        <UserInfo userBalance={userBalance} total={productTotal} />
        <ConfirmationMessage
          clearOrderState={clearOrderState}
          hasPurchased={orderedProduct.hasPurchased}
          history={history}
        />
        <ProductList
          productId={productId}
          getProductDetail={getProductDetail}
          product={product}
          createAnOrder={handleOrderProduct}
          cancelOrder={cancelOrder}
          orderedProduct={orderedProduct}
        />
      </div>
      {businessDetail.error.error ? (
        <FormError error={businessDetail.error} />
      ) : null}
    </StyledContainer>
  );
};

ProductOrderConfirmationPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  getProductDetail: PropTypes.func.isRequired,
  businessDetail: PropTypes.object.isRequired,
  getBusinessDetail: PropTypes.func.isRequired,
  orderedProduct: PropTypes.object.isRequired,
  createAnOrder: PropTypes.func.isRequired,
  clearOrderState: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

export default ProductOrderConfirmationPage;

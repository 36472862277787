import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron } from 'reactstrap';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const Title = styled.h1`
  color: ${palette(1)};
  font-weight: bold;
`;

const TitleContainer = styled(Jumbotron)`
  && {
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 0;
  }
`;

Title.defaultProps = {
  palette: 'primary',
};

const PageTitle = ({ title }) => {
  return (
    <TitleContainer px="0" mb="0">
      <Title
        className="pageTitle text-center"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </TitleContainer>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;

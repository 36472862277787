import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import * as types from 'types';

import InjectedGiftCodePurchaseForm from './GiftCodePurchaseForm';

class GiftCodePurchase extends React.Component {
  render() {
    const { onSubmitGiftCode, error, working } = this.props;
    return (
      <Elements>
        <InjectedGiftCodePurchaseForm onSubmitGiftCode={onSubmitGiftCode} error={error} working={working} />
      </Elements>
    );
  }
}

GiftCodePurchase.propTypes = {
  onSubmitGiftCode: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

GiftCodePurchase.defaultProps = {
  error: null,
  working: false,
};

export default GiftCodePurchase;

import React from 'react';
import PropTypes from 'prop-types';
import { HedgieImg, GradientButton } from 'components';
import withResponsive from 'helper/withResponsive';
import styled from 'styled-components';
import * as paths from 'config/paths';
import homePageHeaderBgUrl from '../../../images/home-backround.png';
import homePageHeaderBgUrlMobile from '../../../images/mobile-header-bg.png';

const StyledBgWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-image: ${props => `url(${props.homePageHeaderBg})`};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.isMobile ? 'center right' : 'center center'};
  background-size: cover;
  background-attachment: scroll;
`;

const StyledHedgieImg = styled.div`
  position: absolute;
  bottom: ${props => (props.isMobile ? '-65px' : '-110px')};
  right: ${props => (props.isMobile ? '-88px' : '24%')};
  transform: ${props => (props.isMobile ? 'rotate(10deg)' : 'rotate(4deg)')};
`;

const StyledDescription = styled.span`
  font-weight: 600;
  font-size: ${props => (props.isMobile ? '16px' : '18px')};
  color: #62295e;
  line-height: 21px;
`;

const StyledTitle = styled.h1`
  font-weight: 600;
  font-size: ${props => (props.isMobile ? '27px' : '40px')};
  color: #ffffff;
  letter-spacing: 0;
`;

const StyledGroupText = styled.div`
  margin-left: ${props => (props.isMobile ? '10px' : '5rem')};
  padding-bottom: ${props => (props.isMobile ? '88px' : '197px')};
  padding-top: ${props => (props.isMobile ? '29px' : '90px')};
`;

class PresaleTopSlide extends React.PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    emitHeaderVersion: PropTypes.func,
  };

  static defaultProps = {
    isMobile: false,
    emitHeaderVersion: null,
  };

  render() {
    const { isMobile, emitHeaderVersion } = this.props;
    return (
      <StyledBgWrapper
        homePageHeaderBg={
          isMobile ? homePageHeaderBgUrlMobile : homePageHeaderBgUrl
        }
        isMobile={isMobile}
      >
        <StyledGroupText isMobile={isMobile}>
          <StyledTitle isMobile={isMobile}>Hello, I’m Hedgie!</StyledTitle>
          <div className="col-md-7 px-0">
            {isMobile ? (
              <StyledDescription isMobile={isMobile}>
                Play the game for free. Earn cryptocoin. Spend in real life.
              </StyledDescription>
            ) : (
              <StyledDescription isMobile={isMobile}>
                Play the game for free. Earn cryptocoin. Spend in real life.
              </StyledDescription>
            )}
          </div>
          <div className="pt-3" style={{ display: 'inline-block' }}>
            <GradientButton
              onClick={emitHeaderVersion}
              label="Mint Your Hedgies"
              width={isMobile ? 200 : 228}
              height={62}
              borderRadius={18}
              fontSize={21}
              align="left"
              link={paths.SIGNIN}
            />
          </div>
        </StyledGroupText>
        <StyledHedgieImg isMobile={isMobile}>
          <HedgieImg color="#009BDE" width={isMobile ? 220 : 390} />
        </StyledHedgieImg>
      </StyledBgWrapper>
    );
  }
}

export default withResponsive(PresaleTopSlide);

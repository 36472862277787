import React from 'react';
import { TeamMember, PageTitle } from 'components';
import Stas from 'images/team/stas.jpg';
import Stanislav from 'images/team/stanislav.jpg';
import Eugene from 'images/team/eugene.jpeg';
import Linh from 'images/team/linh.jpg';
//import Heidy from 'images/team/heidy.jpg'
import Ben from 'images/team/ben.jpg';
import David from 'images/team/david.jpg';
import Redirect from 'config/redirect';
import Chase from 'images/team/chase.jpg';
import Kun from 'images/team/kun.jpeg'
import Mariana from 'images/team/mariana.jpg'
import Chris from 'images/team/chris.jpg'

const OurTeamPage = () => {
  return (
    <div>
      <main>
        <Redirect />
        <div className="our-team-page container-fluid row-section pos-rel overflow-hidden-reg">
          <div className="container">
            <PageTitle title="Nice to meet you!" />
            <div className="dark-color col-md-12 px-0">
              <p className="lead ff-semi-bold mb-0 text-center">
                Get to know our team below.
              </p>
            </div>
            <div className="row bgr-bottom-images mt-5">
              <TeamMember
                name="Stas Zlobinski"
                title="Project Lead"
                bio="With 19 years experience as a digital project manager at advertising agencies, technology consulting firms, and startups, Stas brings field-tested expertise to successfully deliver most complex, risk-ridden, and high stakes projects. By building the right team, setting up the efficient process, motivating people, mitigating risks, and managing conflicting priorities without missing a beat, Stas’ expert skills and calm confidence ensure that Hedgie project is set for success."
                img={Stas}
                social={{
                  linkedin: 'https://www.linkedin.com/in/stazie/',
                  twitter: 'https://twitter.com/stazie',
                }}
              />
              <TeamMember
                name="Stanislav Rafilovich"
                title="Business Development"
                bio="From early on in his career Stanislav showed a strong prowess for visionary ideas and charisma that magnetizes and aligns teams for great performance. Starting with a tenure as an Infantry Sergeant in Israeli army, Stanislav honed his people skills in different industries, such as food service, construction, real estate and digital content management. With the track record of successful projects in these varied domains, and his passion for games and great storytelling, Stanislav is the visionary and the engine of growth behind Hedgie."
                img={Stanislav}
                social={{
                  linkedin: 'https://www.linkedin.com/in/stanislav-rafilovich/',
                }}
              />
             
              <TeamMember
                name="Andrey Borisov"
                title="CTO"
                bio="Andrey Borisov (also known as suborg) is a multidisciplinary artist and senior developer based in Moscow. Andrey created his first applications on a computer, that had only 64kb of memory, which is 50 times less than an average JPEG today, without hard drive or even a mouse. That computer was a little broken and didn't have the saving wire attached. Being a small child Andrey had to re-create all of his software after every power off. Having developed skills in fast and tricky programming he grew up and discovered the wonderful world of powerful, fully functional computers, while also venturing into blockchain programming. Hello metaverse!"
                img="https://img.staging.hedgie.io/suborg.png"
                social={{
                }}
              />

              <TeamMember
                name="Eugene Brodsky"
                title="Sr. DevOps Engineer"
                bio="Hacking on hardware and software since early age, Eugene brings 20 years of experience in many facets of IT, ranging from front-line support and networking to systems administration to cloud operations. He takes pride in building scalable, secure and resilient systems using state-of-the-art tools, automating mundane tasks, and enabling Hedgie engineers to deploy, operate and monitor our code in a way that blends the traditional barriers between Dev and Ops. In his limited spare time, Eugene enjoys building micro racing drones, aquascaping, and discovering indie video games."
                img={Eugene}
                social={{ linkedin: 'https://www.linkedin.com/in/brodsky/' }}
              />

              <TeamMember
                name="Linh (Kyo) Nguyễn"
                title="Sr. Backend Engineer"
                bio="Linh is an energetic software engineer with over 7 years of experience developing robust code for high-volume businesses. Over his career Linh became a pro at developing, integrating, supporting and debugging web applications, RESTful APIs, databases, and websites.  Linh is also a big fan of GOLANG."
                img={Linh}
                social={{ linkedin: 'https://www.linkedin.com/in/kyo-nguyen/' }}
              />

              <TeamMember
                name="David Swift"
                title="Lead Illustrator"
                bio="David is a concept artist and illustrator helping bring the worlds of Hedgie to life. With experience in games and animation, David brings his creativity and passion to every facet of Hedgie's visual design."
                img={David}
                social={{
                  linkedin:
                    'https://www.linkedin.com/in/david-swift-a64b82105/',
                }}
              />
              <TeamMember
                name="Chris Ho"
                title="St. Frontend Engineer"
                bio="Chris is a Frontend Developer with over 6 years of experience working on projects with applied javascript frameworks such as React JS, Redux, Angular 4+, Typescript, Node JS, and others. In his leisure time Chris loves watching movies, taking photos, and going out for picnics with his family."
                img={Chris}
                social={{
                  linkedin: 'https://www.linkedin.com/in/chris-ho-75202b39/',
                }}
              />
              <TeamMember
                name="Mariana Yonamine"
                title="Designer"
                bio="Made in Argentina, Mariana is a Graphic Designer and Illustrator with more than 10 years experience in the gaming industry. She jumped into Hedgie project head-on willing to sail across the Crypto seas with a gentle yet fearless resolve."
                img={Mariana}
                social={{
                  linkedin: 'https://www.linkedin.com/in/mariana-yonamine-84608438/',
                }}
              />
              <TeamMember
                name="KunAn Li"
                title="Game Designer"
                bio="Graduating with Game Design major, KunAn has successfully contributed his talents in the video game industry over the past few years. From Mobile to Crypto, he's passionate and driven to deliver immersive gaming experiences and captivating, enjoyable content to Hedgie players."
                img={Kun}
                social={{
                  linkedin: 'https://www.linkedin.com/in/kunan-li-4b51099a/',
                }}
              />
            </div>
            <div>
              <p className="lead ff-semi-bold mb-0 text-center">Advisors</p>
            </div>
            <div className="row bgr-bottom-images mt-5">
              <TeamMember
                name="Ben Noble"
                title="PR and Blockchain Advisor"
                bio="Ben Noble is an acclaimed public relations and content strategist who has supported some of the top crypto projects to date. Currently, he works as Head of Communication at Multiplied.io. His deep understanding of blockchain technology, coupled with his writing/PR prowess, allows him to effectively coach emerging blockchain companies. His clients have appeared in such publications as Forbes, Fortune, TechCrunch, Bloomberg and Reuters. Before blockchain, Ben spent several years building some of the top brands in enterprise tech."
                img={Ben}
                social={{
                  linkedin: 'https://www.linkedin.com/in/benjaminmnoble/',
                  twitter: 'https://twitter.com/Noble_Block',
                }}
              />

              <TeamMember
                name="Chase Freo"
                title="Gaming Advisor"
                bio="Chase Freo has a wealth of experience in finance and tech before entering the gaming industry. He was previously the CMO of R2Games where he oversaw the company’s PR and publishing strategies for its browser and mobile games division. He is currently CEO of Alto.io and oversees the development of the Alto platform for game developers and creators,  allowing them to easily mint, sell and connect interoperable game items on the blockchain."
                img={Chase}
                social={{
                  linkedin: 'https://www.linkedin.com/in/chase-freo-38656912/',
                  twitter: 'https://twitter.com/ChaseFreo',
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OurTeamPage;

import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'components';
// import { media } from 'styles/main';

const itemBorderColor = scarcity => {
  const base = '3px solid';
  const scarcityColor = {
    rare: 'fdcb5f',
    special: '229bd4',
    ordinary: 'ffffff',
  };
  const color = scarcityColor[scarcity] || 'ffffff';
  return `${base} #${color}`;
};

const StyledConsumable = styled.div`
  cursor: pointer;
  background: ${props => (props.count ? '#ffffff' : '#f6f6f6')};
  box-shadow: ${props =>
    props.active || !props.insideCircle
      ? null
      : '0 0 8px 0 rgba(0, 0, 0, 0.5)'};
  transition: all ease 300ms;
  display: flex;
  border: ${props => itemBorderColor(props.scarcity)};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: ${props =>
    props.width ? `${Math.floor(props.width / 10) + 4}px` : '15px'};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: auto;
  border-radius: ${props => (props.insideCircle ? '50%' : null)};
  &:active,
  &:hover {
    transition: all ease-in-out 300ms;
    box-shadow: none;
    border: ${props => (props.hasHover ? '3px solid #f4ab13' : null)};
  }
  &.selected {
    border: 3px solid #f4ab13;
  }
`;

const StyledImg = styled.div`
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: auto;
  transition: all ease-in-out 300ms;
  &:after {
    content: '';
    display: block;
    padding-bottom: calc(100%);
  }
  &:hover {
    transition: all ease-in-out 300ms;
    transform: ${props =>
      props.hasHover ? 'rotate(8deg) scale(1.1, 1.1)' : null};
  }
  opacity: ${props => (props.isInvisible ? 1 : 0.2)};
`;

const ItemImage = memo(
  ({
    onClick,
    item,
    isSelected,
    className,
    label,
    labelFontSize,
    ...props
  }) => {
    if (!item) {
      return <div>No data</div>;
    }
    const clickCallback = useCallback(() => {
      if (onClick) {
        onClick(item);
      }
    }, [item, onClick]);
    if (!label) {
      return (
        <StyledConsumable
          active={isSelected}
          {...props}
          onClick={clickCallback}
          className={`${className} ${isSelected ? 'selected' : ''}`}
          count={item.amount}
          scarcity={item.scarcity}
        >
          <StyledImg src={item.imgURL} count={item.amount} {...props} />
        </StyledConsumable>
      );
    }
    return (
      <div>
        <StyledConsumable
          active={isSelected}
          {...props}
          onClick={clickCallback}
          className={`${className} ${isSelected ? 'selected' : ''}`}
        >
          <StyledImg src={item.imgURL} isInvisible={props.isInvisible} />
        </StyledConsumable>
        <Typography
          text={label}
          isBold
          align="center"
          fontSize={labelFontSize}
          className="pt-2"
        />
      </div>
    );
  },
);

ItemImage.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  insideCircle: PropTypes.bool,
  hasHover: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  labelFontSize: PropTypes.number,
  isInvisible: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ItemImage.defaultProps = {
  item: null,
  onClick: null,
  isSelected: false,
  insideCircle: true,
  hasHover: true,
  className: '',
  label: '',
  labelFontSize: 16,
  width: null,
  isInvisible: true,
};

export default ItemImage;

import React from 'react';
import { HedgieImg, Button, Spinner, PageTitle } from 'components';
import { useBattle } from './battle-context';
import { Col, Row } from 'reactstrap';
import { StyledContainer } from './MainFlow';

function WaitingChallengers() {
  const { hedgie, onLeave } = useBattle();

  return (
    <StyledContainer>
      <PageTitle title="Arena" />
      <Row gutter={20}>
        <Col md={6} className="align-center">
          <div className="text-center">
            <HedgieImg
              color={`#${hedgie ? hedgie.color : ''}`}
              className="img-fluid"
              width={360}
              imgURL={hedgie? hedgie.imgURL: null}
            />
            
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-center align-items-center">
          <div>
            <div>
            <div className="d-flex justify-content-center">
                <span className="h3 mb-3">#{hedgie ? hedgie.color : ''}</span>
              </div>
              <div className="d-flex justify-content-start">
                <span className="pr-2">Waiting for Challengers…</span>
                <Spinner noFixed />
              </div>
              <Button onClick={onLeave}>Leave</Button>
            </div>
          </div>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default WaitingChallengers;

import React, { memo, useCallback, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pagination from './Pagination';
import NavButton from './NavButton';

const StyledSliderContainer = styled.div`
  //padding: 20px;
`;
const StyledPagination = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
`;

const StyledMainSlider = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.hideArrow ? '1fr' : '20px auto 20px'};
  align-items: center;
  //justify-items: center;
  grid-gap: ${props => (props.noGap ? 0 : 10)}px;
  position: relative;
`;

const WrapPagination = memo(
  ({
    hasPagination,
    isOverLapSlide,
    index,
    handleSelectDot,
    numberOfItems,
  }) => {
    if (hasPagination && isOverLapSlide) {
      return (
        <StyledPagination>
          <Pagination
            max={numberOfItems}
            currentIndex={index}
            onSelect={handleSelectDot}
          />
        </StyledPagination>
      );
    } else if (hasPagination) {
      return (
        <Pagination
          max={numberOfItems}
          currentIndex={index}
          onSelect={handleSelectDot}
        />
      );
    }
    return null;
  },
);

const Carousel = ({
  hasPagination,
  numberOfItems,
  children,
  hideArrow,
  isOverLapSlide,
  noGap,
  disabledTouchEvent,
  changeSlideIndex,
}) => {
  const [index, setIndex] = useState(0);
  const isHiddenArrow = hideArrow || numberOfItems <= 1;

  const handleChangeIndex = useCallback(index => {
    setIndex(index);
  }, []);

  const onNext = useCallback(() => {
    if (index < numberOfItems) {
      setIndex(index + 1);
      if (changeSlideIndex) {
        changeSlideIndex(index, index + 1);
      }
    }
  }, [index, numberOfItems, changeSlideIndex]);

  const onPrev = useCallback(() => {
    if (index > 0) {
      setIndex(index - 1);
      if (changeSlideIndex) {
        changeSlideIndex(index, index - 1);
      }
    }
  }, [index, changeSlideIndex]);

  function handleSelectDot(index) {
    setIndex(index);
  }

  return (
    <StyledSliderContainer>
      <StyledMainSlider hideArrow={isHiddenArrow} noGap={noGap}>
        {isHiddenArrow ? (
          <div />
        ) : (
          <NavButton disabled={index === 0} onClick={onPrev} isLeft />
        )}
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={handleChangeIndex}
          index={index}
          disabled={disabledTouchEvent}
        >
          {children}
        </SwipeableViews>
        {isHiddenArrow ? (
          <div />
        ) : (
          <NavButton disabled={index + 1 === numberOfItems} onClick={onNext} />
        )}
      </StyledMainSlider>
      <WrapPagination
        isOverLapSlide={isOverLapSlide}
        hasPagination={hasPagination}
        numberOfItems={numberOfItems}
        handleSelectDot={handleSelectDot}
        index={index}
      />
    </StyledSliderContainer>
  );
};

Carousel.propTypes = {
  hasPagination: PropTypes.bool,
  hideArrow: PropTypes.bool,
  isOverLapSlide: PropTypes.bool,
  numberOfItems: PropTypes.number,
  children: PropTypes.any,
  noGap: PropTypes.bool,
  overscanSlideAfter: PropTypes.number,
  overscanSlideBefore: PropTypes.number,
  disabledTouchEvent: PropTypes.bool,
  changeSlideIndex: PropTypes.func,
};

Carousel.defaultProps = {
  hasPagination: false,
  isOverLapSlide: false,
  hideArrow: false,
  numberOfItems: 0,
  children: null,
  noGap: false,
  overscanSlideAfter: 1,
  overscanSlideBefore: 1,
  disabledTouchEvent: false,
  changeSlideIndex: null,
};

export default Carousel;

import React, { createContext, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { apiInstance } from '../../../index';
import cogoToast from 'cogo-toast';

export const MyHedgiesContext = createContext(null);

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

const MyHedgiesWrapper = ({ children }) => {
  const [selectedHedgie, setSelectedHedgie] = useState(null);
  const [isMinting, setIsMinting] = useState(false);
  const intervalRef = useRef(null);

  function fetchHedgieTimer(color){
    intervalRef.current = setInterval(async () => {
      const {data} =  await apiInstance.get(`/hedgies/${color}`);
      if(data.hedgie.mintingStatus === 'minted' && !!intervalRef.current){
        clearInterval(intervalRef.current);
        setSelectedHedgie(prevState => ({
          ...prevState,
          mintingStatus: 'minted'
        }));
        cogoToast.success("Your hedgie have been minted successfully");
      }
    },5000);
  }

  return (
    <MyHedgiesContext.Provider value={{
      selectedHedgie,
      setSelectedHedgie,
      isMinting,
      setIsMinting,
      fetchHedgieTimer
    }}>
      <StyledContainer>
        <Container>{children}</Container>
      </StyledContainer>
    </MyHedgiesContext.Provider>
  );
};

export default MyHedgiesWrapper;

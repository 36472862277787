import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ItemImage } from 'components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { selectResourceTypesData } from '../../../store/hedgieHouse/selectors';

const Resource = React.memo(({ name, resourceTypesDetail, ...props }) => {
  if (!name) {
    return null;
  }

  const amount = useMemo(() => {
    return get(resourceTypesDetail[name], 'amount', '');
  }, [name, resourceTypesDetail]);

  return (
    <ItemImage
      {...props}
      item={resourceTypesDetail[name]}
      label={`x${amount}`}
    />
  );
});

Resource.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  resourceTypesDetail: PropTypes.object.isRequired,
};

Resource.defaultProps = {
  isSelected: false,
};

const mapState = state => ({
  resourceTypesDetail: selectResourceTypesData(state),
});

export default connect(
  mapState,
  null,
)(Resource);

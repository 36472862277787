import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDot = styled.div`
  width: 9px;
  height: 9px;
  background-color: #62295e;
  border-radius: 50%;
  transition: transform 400ms ease-in-out;
  cursor: pointer;
  &.active {
    background-color: #00feee;
    transform: scale(1.5, 1.5);
    transition: transform 400ms ease-in-out;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  justify-items: center;
  z-index: 90;
`;

const Dot = ({ active, index, onClick }) => {
  const clickCallback = useCallback(
    () => {
      onClick(index);
    },
    [index, onClick],
  );
  return (
    <StyledDot className={active ? 'active' : ''} onClick={clickCallback} />
  );
};

Dot.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Indicator = ({ pages, active, onClick }) => {
  const items = new Array(pages).fill(StyledDot);

  return (
    <StyledContainer activeIndex={active}>
      {items.map((item, index) => (
        <Dot
          key={index}
          active={index === active}
          index={index}
          onClick={onClick}
        />
      ))}
    </StyledContainer>
  );
};

Indicator.propTypes = {
  pages: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(Indicator);

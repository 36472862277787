import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  BusinessList,
  PageTitle,
  Button,
  SubHeadingBold,
  SubHeading,
  Mobile,
  Desktop,
  Divider,
} from 'components';
import {
  StyledBusinessActionImage,
  StyledActionImage,
} from 'components/stateless/ActionImage';
import * as paths from 'config/paths';
import BuyItemsImage from 'images/buy-items.png';
import SellOnExchangesImage from 'images/sell-on-exchanges.png';
import SpendInWorldImage from 'images/spend-in-world.png';
import LargeCoinImage from 'images/large-coin.png';
import BusinessFavourite from 'images/business-favourite.png';
import BusinessReachOutImage from 'images/business-reach-out.png';
import BusinessJoins from 'images/business-joins.png';
import Redirect from 'config/redirect';
import ScrollableAnchor from 'react-scrollable-anchor';

const handleGetInTouch = e => {
  e.preventDefault();
  window.open(paths.SUPPORT_EMAIL);
};

const BUSINESSES_PAGE_LIMIT = 4;

class BusinessPage extends PureComponent {
  state = {
    businesses: [],
  };
  UNSAFE_componentWillMount() {
    this.loadApprovedBusinesses();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.businesses !== newProps.businesses) {
      this.setState({
        businesses: newProps.businesses.list,
      });
    }
  }

  loadApprovedBusinesses = () => {
    const limit = BUSINESSES_PAGE_LIMIT;
    const offset = 0;

    this.props.getBusinesses({
      offset,
      limit,
    });
  };

  handleOnLearnMore = e => {
    e.preventDefault();
    this.props.history.push(paths.BUSINESSES);
  };

  handleOnSuggestABusiness = e => {
    handleGetInTouch(e);
  };

  render() {
    const { businesses } = this.state;
    const rowClass = 'py-5';
    const approvedBusinessText = (
      <React.Fragment>
        <p className="text-center">
          Hedgie has proudly partnered with forward-thinking local and online
          businesses and services. Here are just a few, and we’re always looking
          for more!
        </p>
        <p className="text-center">
          Got your own business?{' '}
          <Link to={paths.BUSINESSES}>
            Find out how you can work with Hedgie!
          </Link>
        </p>
      </React.Fragment>
    );
    const coin = (
      <div className="d-flex justify-content-center  m-auto">
        <div>
          <StyledBusinessActionImage
            src={LargeCoinImage}
            style={{ width: '170px' }}
            alt="Tell us your favourite online or local business, and why you’re a fan."
          />
        </div>
      </div>
    );
    const businessSearch = (
      <p>
        We’re always digging around for potential partners. Learn about our
        partnership opportunities and what Hedgie can do for your business. This
        is the start of something beautiful, we just know it!
      </p>
    );
    const learnMore = (
      <div className="text-center text-md-left">
        <Button size="large" onClick={this.handleOnLearnMore}>
          Learn more
        </Button>
      </div>
    );
    const seeAllBusinesses = (
      <div className="text-center">
        <Link to={paths.ALL_BUSINESSES}>
          <Button size="large" className="mt-0 mb-5">
            View All Participating Businesses
          </Button>
        </Link>
      </div>
    );
    return (
      <div className="business-page">
        <Redirect />
        <main>
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Earn in the Game.<br />Spend in the Real World." />
                </Col>
              </Row>
              <Row className="pb-5">
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <div className="text-left text-md-center">
                    <SubHeading heading="A reward for all your Hedgie’s hard work! The Curio you make in the game can be used to spend in the real world at local and online Hedgie-Approved businesses." />
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row className={rowClass}>
                <Col md="6">
                  <SubHeadingBold
                    className="text-center text-md-left"
                    heading="Curio Explained"
                  />
                  <p>Curio is an ERC20 token on the Ethereum blockchain.</p>
                  <p>
                    It has a cap of 8,888,888,888 and can only be made inside
                    the Hedgie game. You can earn Curio by sending your Hedgie
                    on journeys in the game. Once you’ve earned enough, use it
                    to buy items inside the game, or spend it in the real world!
                  </p>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={BuyItemsImage}
                          alt="Buy items in the game"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Buy items in the game" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={SellOnExchangesImage}
                          alt="Sell on crypto exchanges"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Sell on crypto exchanges" />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="py-3 py-md-0">
                      <div
                        style={{ maxWidth: '150px' }}
                        className="text-center m-auto"
                      >
                        <StyledActionImage
                          src={SpendInWorldImage}
                          alt="Spend in the real world"
                        />
                        <div className="ff-semi-bold pt-3">
                          <SubHeading heading="Spend in the real world" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="grey-light-bgr">
            <ScrollableAnchor id="hedgie-approved-businesses">
              <div>
                <Container>
                  <Desktop>
                    <Row className={`${rowClass} justify-content-center`}>
                      <Col className="text-center col-md-10 d-flex flex-column">
                        <SubHeadingBold heading="Hedgie-Approved Businesses" />
                        {approvedBusinessText}
                      </Col>
                    </Row>
                    <Row>
                      <BusinessList loading={false} businesses={businesses} />
                    </Row>
                    {seeAllBusinesses}
                  </Desktop>
                  <Mobile>
                    <Row className={`${rowClass} justify-content-center`}>
                      <Col className="text-center col-md-10 d-flex flex-column">
                        <SubHeadingBold heading="Hedgie-Approved Businesses" />
                        {approvedBusinessText}
                      </Col>
                    </Row>
                    <Row>
                      <BusinessList loading={false} businesses={businesses} />
                    </Row>
                    {seeAllBusinesses}
                  </Mobile>
                </Container>
              </div>
            </ScrollableAnchor>
          </Container>
          <Container fluid>
            <Container>
              <Row className="pt-5">
                <Col md="12" className="text-center">
                  <SubHeadingBold heading="Favourite store isn’t Hedgie-Approved?" />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <p className="text-left text-md-center">
                    The more, the merrier! We are actively looking for
                    businesses to partner with and extend our community. Suggest
                    your favourite businesses below. Leave an especially awesome
                    suggestion, and when that business signs up with us, you may
                    receive a special reward!
                  </p>
                </Col>
              </Row>
              <Row className={rowClass}>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={BusinessFavourite}
                        style={{ width: '170px' }}
                        alt="Tell us your favourite online or local business, and why you’re a fan."
                      />
                      <p className="pt-4">
                        Tell us your favourite online or local business, and why
                        you’re a fan.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={BusinessReachOutImage}
                        style={{ width: '170px' }}
                        alt="We’ll reach out to suggested businesses."
                      />
                      <p className="pt-4">
                        We’ll reach out to suggested businesses.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="d-flex text-center">
                    <div className="m-auto">
                      <StyledBusinessActionImage
                        src={BusinessJoins}
                        style={{ width: '170px' }}
                        alt="Your beloved business joins the Hedgie community, offering another great way to spend your Curio!"
                      />
                      <p className="pt-4">
                        Your beloved business joins the Hedgie community,
                        offering another great way to spend your Curio!
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col className="text-center">
                  <Button size="large" onClick={this.handleOnSuggestABusiness}>
                    Suggest a business
                  </Button>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className="pink-light-brg">
            <Container>
              <Desktop>
                <Row className={rowClass}>
                  <Col md="6">{coin}</Col>
                  <Col md="6">
                    <Row>
                      <Col>
                        <SubHeadingBold heading="Own a Business?" />
                        {businessSearch}
                        {learnMore}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Desktop>
              <Mobile>
                <Row className={rowClass}>
                  <Col xs="12">
                    <SubHeadingBold
                      className="text-center"
                      heading="Own a Business?"
                    />
                  </Col>
                  <Col xs="12">{coin}</Col>
                  <Col xs="12">{businessSearch}</Col>
                  <Col xs="12">{learnMore}</Col>
                </Row>
              </Mobile>
            </Container>
          </Container>
        </main>
      </div>
    );
  }
}

BusinessPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  businesses: PropTypes.object.isRequired,
  getBusinesses: PropTypes.func.isRequired,
};
BusinessPage.defaultProps = {};
export default BusinessPage;

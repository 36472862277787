import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${media.tablet`
    padding-right:10px;
  `}
`;
const StyledIcon = styled.div`
  position: relative;
  &:hover {
    .notification-icon {
      opacity: 0.8;
    }
  }
`;

const StyledCount = styled.span`
  background-color: #fa3e3e;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  top: 1px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  z-index: 1;
  position: absolute;
  .count-value {
    font-size: 13px;
    color: #fff;
    padding: 2px;
  }
`;

const Badge = ({
  messageNo,
  onClick,
  getNumberOfUnseenNotifications,
  ...props
}) => {
  const style = { height: 24, width: 24, color: '#fff' };
  useEffect(
    () => {
      getNumberOfUnseenNotifications();
    },
    [getNumberOfUnseenNotifications],
  );
  return (
    <StyledWrapper {...props} onClick={onClick}>
      <StyledIcon>
        {messageNo > 0 ? (
          <StyledCount>
            <span className="count-value">{messageNo}</span>
          </StyledCount>
        ) : null}
        <FontAwesomeIcon
          icon={faBell}
          style={style}
          className="notification-icon"
        />
      </StyledIcon>
    </StyledWrapper>
  );
};

Badge.propTypes = {
  messageNo: PropTypes.number,
  onClick: PropTypes.func,
  getNumberOfUnseenNotifications: PropTypes.func,
};

Badge.defaultProps = {
  messageNo: 0,
  onClick: null,
  getNumberOfUnseenNotifications: null,
};

export default React.memo(Badge);

import { Component } from 'react';
import PropTypes from 'prop-types';
import { msToTime } from 'helper/handleTime';

export default class CountDownTimer extends Component {
  static propTypes = {
    duration: PropTypes.number.isRequired,
    onComplete: PropTypes.func,
    render: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onComplete: null,
  };

  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = msToTime(props.duration);
  }
  componentDidMount() {
    this.timer = setInterval(this.countDown, 1000);
  }

  componentDidUpdate() {
    const { h, m, s } = this.state;
    if (h === 0 && m === 0 && s === 0 && this.props.onComplete) {
      this.props.onComplete();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countDown = () => {
    const { h, m, s } = this.state;
    if (s > 0) {
      return this.setState({
        s: s - 1,
      });
    }
    if (s === 0) {
      if (m > 0) {
        return this.setState({
          m: m - 1,
          s: 59,
        });
      }
    }
    if (m === 0) {
      if (h > 0) {
        return this.setState({
          h: h - 1,
          m: 59,
          s: 59,
        });
      }
    }
    return clearInterval(this.timer);
  };

  render() {
    return this.props.render(this.state);
  }
}

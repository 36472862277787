import { initialState } from './selectors';
import {
  SUBSCRIBE_NEWS_REQUEST,
  SUBSCRIBE_NEWS_SUCCESS,
  SUBSCRIBE_NEWS_FAILURE,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_NEWS_REQUEST:
      return {
        ...state,
        subscribe: {
          ...state.subscribe,
          error: null,
          working: true,
          status: false,
        },
      };
    case SUBSCRIBE_NEWS_SUCCESS:
      return {
        ...state,
        subscribe: {
          ...state.subscribe,
          error: null,
          working: false,
          status: true,
        },
      };
    case SUBSCRIBE_NEWS_FAILURE:
      return {
        ...state,
        subscribe: {
          ...state.subscribe,
          error: payload,
          working: false,
          status: false,
        },
      };
    default:
      return state;
  }
};

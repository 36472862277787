import React from 'react';
import PropTypes from 'prop-types';
import { HedgieImg, GradientButton } from 'components';
import styled from 'styled-components';
import * as paths from 'config/paths';


export const StyledContainer = styled.div`
  width: 100%;
`;

const StyledButton = styled.div(props =>
  props.isMobile
    ? {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '20%',
      }
    : {
        position: 'absolute',
        left: '10%',
        bottom: '30%',
      },
);

const StyledHedgies = styled.div`
  position: absolute;
  right: ${props => props.right}%;
  bottom: ${props => props.bottom}%;
  width: ${props => props.width}%;
  width: ${props => props.zIndex};
  transform: rotate(-${props => props.deg}deg);
`;

const EndSlide = ({ isMobile }) => {
  return (
    <StyledContainer>
      <StyledButton isMobile={isMobile}>
        <GradientButton link={paths.SELECT_HEDGIE} label="Get started!" />
      </StyledButton>
      <StyledHedgies
        deg={14}
        right={isMobile ? 45 : 25}
        bottom={isMobile ? 17 : 15}
        width={isMobile ? 20 : 10}
        zIndex={18}
      >
        <HedgieImg color="#39caa4" />
      </StyledHedgies>
      <StyledHedgies
        deg={22}
        right={isMobile ? 40 : 24}
        bottom={isMobile ? -2 : -5}
        width={isMobile ? 36 : 14.5}
        zIndex={19}
      >
        <HedgieImg color="#e04168" />
      </StyledHedgies>
      <StyledHedgies
        deg={20}
        right={isMobile ? -12 : -4}
        bottom={isMobile ? 0 : -10}
        width={isMobile ? 65 : 32}
        zIndex={20}
      >
        <HedgieImg color="#0092ff" />
      </StyledHedgies>
    </StyledContainer>
  );
};

EndSlide.propTypes = {
  isMobile: PropTypes.bool,
};

EndSlide.defaultProps = {
  isMobile: false,
};

export default EndSlide;

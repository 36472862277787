import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageTitle, SelectedHedgie, EthWallet } from 'components';
import MyHedgiesWrapper from './MyHedgiesWrapper';
import MyHedgiesCarousel from './MyHegiesCarousel';
import HedgiePreLoader from '../../stateless/MyHedgies/HedgiePreLoader';
import StartJourneyButton from '../../stateless/MyHedgies/StartJourneyButton';
import HedgieHouseIcon from '../../stateless/MyHedgies/HedgieHouseIcon';
//import MyHedgieShare from './MyHedgieShare';
import HedgieMetaMaskNew from '../../stateless/HedgieMetaMaskNew';
import AnnouncementsModal from '../../../containers/AnnouncementsModal';
import SelectedBattleButton from './SelectedBattleButton';

const StyledGridLayout = styled.div``;
const StyledGroupButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 40px;
  > div {
    &:first-child {
      margin-right: 20px;
    }
  }
  @media (max-width: 767px) {
    padding: 30px 0;
    justify-content: center;
    > div {
      &:first-child {
        margin-right: 0px;
      }
    }
    > div {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
`;

const NewMyHedgiesPage = () => {
  return (
    <MyHedgiesWrapper>
      <PageTitle title="My Hedgies" />
      <StyledGridLayout>
        <EthWallet metaMask={<HedgieMetaMaskNew metaMaskEnabled />} />
        <MyHedgiesCarousel />
        <SelectedHedgie />
        <HedgiePreLoader />
        <StyledGroupButtons>
          <StartJourneyButton />
          <SelectedBattleButton />
        </StyledGroupButtons>
        <HedgieHouseIcon />
        {/* <MyHedgieShare /> */}
      </StyledGridLayout>
      <AnnouncementsModal />
    </MyHedgiesWrapper>
  );
};

NewMyHedgiesPage.propTypes = {
  location: PropTypes.object,
};
NewMyHedgiesPage.defaultProps = {
  location: null,
};

export default NewMyHedgiesPage;

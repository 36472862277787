import React from 'react';
import PropTypes from 'prop-types';
import { SubHeadingBold, ReadMoreText } from 'components';
import { StyledOpenNewTab } from 'styles/main';

const TeamMember = ({ name, title, bio, img, social }) => {
  return (
    <div className="col-md-4 mb-5">
      <div className="img-holder team-member-image-holder">
        <img src={img} className="team-photo rounded-circle" alt={name} />
        <div className="member-options-wrap">
          {Object.keys(social).map(key => {
            return (
              <StyledOpenNewTab
                key={key}
                className={'info-options ' + key}
                href={social[key]}
                target="_blank"
              />
            );
          })}
        </div>
      </div>
      <div className="dark-color member-info pt-3">
        <SubHeadingBold heading={name} style={{ textAlign: 'center' }} />
        <p className="lead ff-semi-bold mt-0 pt-0 mb-0 text-center">{title}</p>
        <ReadMoreText>{bio}</ReadMoreText>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  social: PropTypes.object,
};

TeamMember.defaultProps = {
  social: {},
};

export default TeamMember;

import debug from 'debug';

const BASE = 'hedgie';
const COLOURS = {
  debug: 'purple',
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red',
};

class Log {
  static generateMessage(level, message, source) {
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    createDebug.color = COLOURS[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }

  static trace(message, source) {
    return this.generateMessage('trace', message, source);
  }

  static info(message, source) {
    return this.generateMessage('info', message, source);
  }

  static warn(message, source) {
    return this.generateMessage('warn', message, source);
  }

  static error(message, source) {
    return this.generateMessage('error', message, source);
  }

  static debug(message, source) {
    return this.generateMessage('debug', message, source);
  }
}

export default Log;

// Actions for news letter subscription
export const SUBSCRIBE_NEWS_REQUEST = 'SUBSCRIBE_NEWS_REQUEST';
export const SUBSCRIBE_NEWS_SUCCESS = 'SUBSCRIBE_NEWS_SUCCESS';
export const SUBSCRIBE_NEWS_FAILURE = 'SUBSCRIBE_NEWS_FAILURE';

export const subscribeNewsRequest = email => ({
  type: SUBSCRIBE_NEWS_REQUEST,
  payload: { email },
});
export const subscribeNewsSuccess = payload => ({
  type: SUBSCRIBE_NEWS_SUCCESS,
  payload,
});
export const subscribeNewsFailure = error => ({
  type: SUBSCRIBE_NEWS_FAILURE,
  payload: error,
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faImage } from '@fortawesome/free-solid-svg-icons';
import useProgressiveImage from '../../custom-hooks/useProgressiveImage';

const StyledPhoto = styled.div`
  background-image: url(${p => p.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  max-height: ${p => p.maxHeight}px;
  cursor: ${p => (p.isHover ? 'pointer' : 'normal')};
  &:after {
    display: block;
    content: '';
    padding-bottom: ${p => `calc(100%/${p.ratio})`};
  }
`;

const styleIcon = {
  width: 32,
  height: 32,
  color: '#fff',
};

const stylePlaceholderImageIcon = {
  width: 48,
  height: 48,
  color: '#878c97',
  opacity: 0.2,
};

const StyledImgIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledPlaceholder = styled(StyledPhoto)`
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  position: relative;
  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : null)};
  }
`;

const StyledMorePhotos = styled.span`
  font-size: 12px;
  color: #fff;
  text-shadow: 0 1px 2px #000;
`;

const StyledClickableDiv = styled.div`
  height: 100%;
`;

const Photo = ({ numberOfPhotos, onClick, ...props }) => {
  const { src, placeholderUrl } = props;
  const loadedUrl = useProgressiveImage(src, placeholderUrl);

  return (
    <StyledWrapper className={props.className}>
      {!loadedUrl ? (
        <StyledPlaceholder>
          <FontAwesomeIcon icon={faImage} style={stylePlaceholderImageIcon} />
        </StyledPlaceholder>
      ) : (
        <StyledClickableDiv>
          {onClick ? (
            <StyledImgIcon onClick={onClick}>
              <FontAwesomeIcon icon={faImages} style={styleIcon} />
              <StyledMorePhotos>
                {numberOfPhotos} {numberOfPhotos > 1 ? 'Photos' : 'Photo'}
              </StyledMorePhotos>
            </StyledImgIcon>
          ) : null}
          <StyledPhoto {...props} onClick={onClick} isHover={!!onClick} />
        </StyledClickableDiv>
      )}
    </StyledWrapper>
  );
};

Photo.propTypes = {
  src: PropTypes.string,
  placeholderUrl: PropTypes.string,
  ratio: PropTypes.number,
  numberOfPhotos: PropTypes.number,
  maxHeight: PropTypes.number,
};

Photo.defaultProps = {
  src: '',
  placeholderUrl: '',
  ratio: 16 / 9,
  maxHeight: 320,
  numberOfPhotos: 0,
};

export default Photo;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: ${props => `${props.fontSize}px`};
  color: ${props => props.color};
  text-align: ${props => props.align};
`;

const FormErrorMessage = ({ error, ...props }) => {
  return <StyledErrorMessage {...props}>{error}</StyledErrorMessage>;
};

FormErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

FormErrorMessage.defaultProps = {
  align: 'left',
  color: '#dc3545',
  fontSize: 12,
};

export default FormErrorMessage;

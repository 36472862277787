import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { PureSpinner, Order, Button } from 'components';
import { isEmpty } from 'lodash';
import { BusinessTabIds } from '../../pages/BusinessOwnerOrdersPage';

const StyledPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
`;

const StyledDivider = styled.div`
  border-bottom: 2px solid #eff2f3;
`;

const OrderList = ({
  orders,
  isBusinessOwner,
  archiveOrder,
  tabId,
  loadMore,
  isLoadableMore,
  hasPermissionWrite,
}) => {
  const loadMoreCallback = useCallback(
    () => {
      loadMore(tabId);
    },
    [loadMore, tabId],
  );

  return isEmpty(orders) ? (
    <div className="align-center">No data!</div>
  ) : (
      <div>
        <StyledDivider className="pt-2 pb-2" />
        {orders.map(order => (
          <LazyLoad
            height={90}
            key={order.id}
            placeholder={
              <StyledPlaceholder>
                <PureSpinner size={16} />
              </StyledPlaceholder>
            }
            offset={90}
          >
            <Order
              order={order}
              isBusinessOwner={isBusinessOwner}
              isPendingOrder={
                tabId === BusinessTabIds.PENDING && hasPermissionWrite
              }
              archiveOrder={archiveOrder}
            />
          </LazyLoad>
        ))}
        {isLoadableMore ? (
          <div className="align-center pt-3 pb-5">
            <Button onClick={loadMoreCallback}>Load more</Button>
          </div>
        ) : null}
      </div>
    );
};

OrderList.propTypes = {
  orders: PropTypes.array,
  isBusinessOwner: PropTypes.bool,
  isLoadableMore: PropTypes.bool,
  archiveOrder: PropTypes.func,
  loadMore: PropTypes.func,
  tabId: PropTypes.string,
  hasPermissionWrite: PropTypes.bool,
};

OrderList.defaultProps = {
  orders: [],
  isBusinessOwner: false,
  isLoadableMore: false,
  hasPermissionWrite: false,
  archiveOrder: null,
  loadMore: null,
  tabId: '',
};

export default memo(OrderList);

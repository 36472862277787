import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

const Wrapper = styled.div`
  background-color: ${props => props.background};
  height: 100%;
`;

const CustomTab = memo(
  ({ switchTab, activeTab, tabContent, background, ...props }) => {
    return (
      <Wrapper background={background}>
        <div className={classnames(['tabbed-container'], props.className)}>
          <Container className="p-0 large-nav">
            <Nav tabs className="d-flex justify-content-center">
              {tabContent.map(tab => (
                <NavItem key={tab.id}>
                  <NavLink
                    className={classnames({
                      active: activeTab === tab.id,
                    })}
                    onClick={() => {
                      switchTab(tab.id);
                    }}
                  >
                    {tab.tabName}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {tabContent.map(tab =>
                activeTab === tab.id ? (
                  <TabPane key={tab.id} tabId={tab.id}>
                    {tab.content}
                  </TabPane>
                ) : null,
              )}
            </TabContent>
          </Container>
        </div>
      </Wrapper>
    );
  },
);

CustomTab.propTypes = {
  tabContent: PropTypes.array.isRequired,
  switchTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  background: PropTypes.string,
  className: PropTypes.string,
};

CustomTab.defaultProps = {
  background: '#eff3f4',
  className: 'pt-5',
};

export default CustomTab;

import styled, { css } from 'styled-components';

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

const sizes = {
  largeDesktop: 993,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (label === 'largeDesktop') {
      return css`
        @media (min-width: ${sizes[label] / 16}em) {
          ${css(...args)};
        }
      `;
    }
    return css`
      @media (max-width: ${sizes[label] / 16}em) {
        ${css(...args)};
      }
    `;
  };
  return acc;
}, {});

export const StyledTextBtn = styled.button`
  font-weight: 600;
  font-size: 20px;
  color: #62295e;
  letter-spacing: 0;
  text-align: center;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
  ${media.tablet`
    font-size: 18px;
  `} &:hover {
    color: #12dfc0;
  }
`;

export const StyledFlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => props.wrap || undefined};
`;

export const StyledOpenNewTab = styled.a``;

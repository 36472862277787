import React from 'react';
import PropTypes from 'prop-types';

import { Form, FormGroup, Row, Col } from 'reactstrap';
import * as types from 'types';

import { Button, Spinner }  from 'components';
import FormError, { FormFieldError } from 'components/stateless/FormError';
import Log from 'services/log';
import { paymentAddress } from 'config/env';
import MetaMaskUtil from 'services/metamaskutil';

import MetaMask from '../MetaMask';

const initialState = {
  estimatedCost: 0,
  etherPrice: 0,
  estimatedTotal: 0,
  hasEstimatedCost: false,
  working: false,
  error: null,
};

class MetaMaskPurchaseForm extends React.Component {
  constructor(props) {
    super(props);
    Log.info(`props are ${JSON.stringify(props)}`);
    this.state = initialState;
  }

  componentDidMount() {
    //this.resolvePrice(this.props.price);
    this.resolvePrice(this.props.price,this.props.hedgie);
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ working: true });
    Log.info(`Submitting metamask purchase for amount: ${this.state.weiCost}`);
    MetaMaskUtil.getAccount()
    .then(account => {
      Log.info(`Submitting metamask purchase for account address: ${account} ${paymentAddress} ${this.state.weiCost}`);
      return MetaMaskUtil.makePurchase(account, paymentAddress, this.state.weiCost);
    }).then(txHash => {
      Log.info(`Got transaction hash, txHash: ${JSON.stringify(txHash)}`);
      this.setState({ working: false, error: null });
      this.props.onCompleteEtherBuyNow({
        paymentMethod: 'ether',
        paymentValue: txHash,
      });
    }).catch(err => {
      Log.error(`Purchase action has errro: ${JSON.stringify(err)}`);
      this.setState({ working: false, error: err });
    });
  }

  
  resolvePrice = async (costInWei, hedgie) => {
    Log.info(`Resolving price for ${hedgie}`);
    this.setState({ working: true });
    let etherPrice = (hedgie?.etherPrice>0)?(hedgie.etherPrice/1e18).toFixed(3):0;
    let estimatedCost = (hedgie.gasFeeWei/1e18).toFixed(3);
    let estimatedTotal = (Number(etherPrice)+Number(estimatedCost)).toFixed(3)
   
        this.setState({
          weiCost: costInWei,
          etherPrice: etherPrice,
          estimatedCost: estimatedCost,
          estimatedTotal: estimatedTotal,
          hasEstimatedCost: true,
          working: false,
        });
      
  }
/* 
  resolvePrice = async hedgie => {
    Log.info(`Resolving price for ${hedgie}`);
    this.setState({ working: true });
    let costInWei = hedgie.etherPrice
    MetaMaskUtil.getAccount()
    .then(account => {
      MetaMaskUtil.getEstimatedGasPrice(account, paymentAddress, costInWei)
      .then(estimatedCost => {
        Log.info(`Resolving price for ${costInWei} - estimated cost is: ${estimatedCost}`);
        this.setState({
          weiCost: costInWei,
          etherPrice: MetaMaskUtil.toEther(costInWei.toString()),
          estimatedCost: MetaMaskUtil.toEther(estimatedCost.toString()),
          estimatedTotal: MetaMaskUtil.toEther(MetaMaskUtil.sum(costInWei, estimatedCost)),
          hasEstimatedCost: true,
          working: false,
        });
      });
    });
  } */

  render() {
    Log.info('Submitting metamask purchase Form:render', this.props.error);
    let contentBlock = null;
    if (!this.state.hasEstimatedCost || this.state.working) {
      contentBlock = (
        <Row className="mt-5">
          <Col md="6" className="">
            <Spinner />
          </Col>
        </Row>
      );
    }
    if (this.state.hasEstimatedCost && !this.state.working) {
      contentBlock = (
        <Form className="mt-3" onSubmit={this.handleSubmit}>
          <FormError error={this.state.error} />
          <FormError error={this.props.error} />
          <Row>
            <Col md="3" className="">
              <span>Hedgie</span>
            </Col>
            <Col md="3" className="">
              <span>{this.state.etherPrice} Eth</span>
            </Col>
          </Row>
          <Row>
            <Col md="3" className="">
              <span>Estimated Gas</span>
            </Col>
            <Col md="3" className="">
              <span>{this.state.estimatedCost} Eth</span>
            </Col>
          </Row>
          <Row>
            <Col md="3" className="">
              <span>Estimated Subtotal</span>
            </Col>
            <Col md="3" className="">
              <span>{this.state.estimatedTotal} Eth</span>
            </Col>
          </Row>
          <Row>
            <Col md="3" className="">
              <span className="note">This is our estimated subtotal. Once you confirm, you will be prompted by Metamask with the final amount.</span>
            </Col>
          </Row>
          <FormFieldError error={this.state.error} field="code" />
          <FormFieldError error={this.props.error} field="code" />

          <Row>
            <FormGroup className="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-sm-center justify-content-md-start">
              <Button size="large" className="" disabled={this.state.working} type="submit">Mint this hedgie{/* Buy Now */}</Button>
            </FormGroup>
          </Row>
        </Form>);
    }
    return (
      <MetaMask>
        {contentBlock}
      </MetaMask>
    );
  }
}

MetaMaskPurchaseForm.propTypes = {
  onCompleteEtherBuyNow: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
  price: PropTypes.string.isRequired,
};

MetaMaskPurchaseForm.defaultProps = {
  error: null,
  working: false,
};

export default MetaMaskPurchaseForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';

const StyledButtonSmall = styled(Button)`
  && {
    background-color: #61285D;
    font-weight: normal;
    border-radius: 6px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: 16px;
  }
`;

const StyledButtonLarge = styled(StyledButtonSmall)`
  && {
    margin-left: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 12px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px;
  }
`;

const StyledButtonDropdown = styled(StyledButtonSmall)`
  && {
    margin-left: 0;
    width: 100px;
    border-color: transparent;
    color: #ffffff !important;
  }
`;

const HedgieButton = ({ ...props }) => {
  const className = `pageTitle text-center ${props.className}`;
  if (props.size === 'large') {
    return (
      <StyledButtonLarge className={className} {...props} >{props.children}</StyledButtonLarge>
    );
  }
  if (props.size === 'menu') {
    return (
      <StyledButtonDropdown className={className} {...props} >{props.children}</StyledButtonDropdown>
    );
  }
  return (
    <StyledButtonSmall className={className} {...props} >{props.children}</StyledButtonSmall>
  );
};

HedgieButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

HedgieButton.defaultProps = {
  title: '',
  className: '',
  children: null,
  size: 'small',
};

export default HedgieButton;

import React, { useContext, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import HedgiePagination from '../../stateless/MyHedgies/HedgiePagination';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { selectHedgiesWithPlaceholder } from './selector';
import { MyHedgiesContext } from './MyHedgiesWrapper';
import { getMyHedgiesRequest } from 'store/actions';

const MyHedgiesCarousel = ({ location }) => {
  const isRunOnce = useRef(true);
  const dispatch = useDispatch();
  const hedgies = useSelector(state => selectHedgiesWithPlaceholder(state));
  const urlParams = new URLSearchParams(location.search);
  let color = (location.hash)?(location.hash).substring(1):urlParams.get('color');

  


  const {selectedHedgie, setSelectedHedgie} = useContext(MyHedgiesContext);
  

  useEffect(() => {
    dispatch(
      getMyHedgiesRequest({
        limit: 100,
        offset: 0,
        isLoadMore: false,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(hedgies) && !urlParams.get('color') && !location.hash) {
      if (isRunOnce) {
        setSelectedHedgie(hedgies[0]);
        isRunOnce.current = false;
      }
    }
    // eslint-disable-next-line
  }, [hedgies, setSelectedHedgie]);

  const handleOnSelectHedgie = useCallback(
    hedgie => {
      if (hedgie !== selectedHedgie) {
        setSelectedHedgie(hedgie);
      }
    },
    // eslint-disable-next-line
    [setSelectedHedgie],
  );

  const handleLoadMore = limit => {
    // getMyHedgies({ limit, offset: hedgies.length, isLoadMore: true });
  };

  return (
    <HedgiePagination
      hedgies={hedgies}
      selectedHedgie={selectedHedgie}
      onSelectHedgie={handleOnSelectHedgie}
      onLoadMore={handleLoadMore}
      urlColor={color}
    />
  );
};

MyHedgiesCarousel.propTypes = {
  location: PropTypes.object,
};

MyHedgiesCarousel.defaultProps = {
  location: null,
};

export default withRouter(MyHedgiesCarousel);

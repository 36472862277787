import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

export const StyledImage = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: #eff2f3;
  border-radius: 15px;
  overflow: hidden;
  ${media.phone`
    display: none
  `}
  img {
    width: 100%;
    height: auto;
  }
`;

const ProductImage = props => {
  return (
    <StyledImage {...props}>
      <img src={props.imgURL} alt="product" />
    </StyledImage>
  );
};

ProductImage.propTypes = {
  imgURL: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default memo(ProductImage);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import CompanyInfo from './CompanyInfo';
import ProductDiscount from '../ProductDiscount';
import Typography from '../../stateless/Typography';
import HowToRedeem from './HowToRedeem';
import DiscountMore from './DiscountMore';
import { getProductDetail } from '../../../store/business/actions';
import PhotoGallery from '../../commons/PhotoGallery';
import FormError from '../../stateless/FormError';
import BusinessCover from './BusinessCover';
import Limits from './Limits';
import useScrollToTop from '../../custom-hooks/useScrollToTop';

const StyledContainer = styled.div``;

const StyledContent = styled.div`
  max-width: 932px;
  margin: 0 auto;
  padding-bottom: 30px;
`;

const ProductDetailPage = ({ match, history }) => {
  useScrollToTop();
  const productId = match.params.id;
  const dispatch = useDispatch();
  const [openGallery, setOpenGallery] = useState(false);
  const productDetail = useSelector(state => state.business.productDetail.data);
  const enjoyMoreDiscounts = useSelector(
    state => state.business.enjoyMoreDiscounts,
  );
  const productDetailError = useSelector(
    state => state.business.productDetail.error,
  );

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  const avatarUrl = get(productDetail, 'business.imageURL', '');
  const coverImgUrl = get(productDetail, 'business.coverImgURL', '');
  const business = get(productDetail, 'business', null);
  const instructions = get(productDetail, 'instructions', []);
  const galleryPhotos = get(productDetail, 'images', []);
  const limits = get(productDetail, 'limits', []);
  const businessId = get(productDetail, 'businessID', '');

  function gotoProductPayment() {
    history.push(`/business/${businessId}/purchases/${productId}`);
  }

  const handleClickPhoto = useCallback(() => {
    setOpenGallery(true);
  }, []);

  const closeLightbox = useCallback(() => {
    setOpenGallery(false);
  }, []);

  const galleryPhotosMemoized = useMemo(
    () => galleryPhotos.map(pt => ({ src: pt.previewURL })),
    [galleryPhotos],
  );

  const galleryPhotosThumbnailMemoized = useMemo(
    () => galleryPhotos.map(pt => ({ src: pt.thumbnailURL })),
    [galleryPhotos],
  );
  

  return (
    <StyledContainer>
      <BusinessCover coverImgUrl={coverImgUrl} />
      <StyledContent>
        {business ? <CompanyInfo avatarUrl={avatarUrl} {...business} /> : null}
        <div className="pt-5 pb-3">
          <ProductDiscount
            product={productDetail}
            displayType="inline"
            buyItem={(productDetail.inventoryQuantity && productDetail.inventoryQuantity>0)?gotoProductPayment:null}
            numberOfSmallPhotos={galleryPhotos.length}
            onClickPhoto={galleryPhotos.length > 0 ? handleClickPhoto : null}
            isOddOrder
          />
          <Typography
            text={productDetail.description}
            fontSize={16}
            className="pt-3 pl-2 pr-2"
            align="left"
            isLink
          />
        </div>
        {isEmpty(instructions) ? null : (
          <HowToRedeem
            className="pt-5 pb-3 pl-2 pr-2"
            instructions={instructions}
          />
        )}
        {isEmpty(limits) ? null : (
          <Limits limits={limits} className="pt-3 pb-3" />
        )}
        {isEmpty(enjoyMoreDiscounts) ? null : (
          <DiscountMore className="pt-5" products={enjoyMoreDiscounts} />
        )}
      </StyledContent>
      {openGallery && !isEmpty(galleryPhotosThumbnailMemoized) ? (
        <PhotoGallery
          isOpen={openGallery}
          onClose={closeLightbox}
          images={galleryPhotosMemoized}
          thumbnail={galleryPhotosThumbnailMemoized}
        />
      ) : null}
      {productDetailError.error ? (
        <FormError error={productDetail.error} />
      ) : null}
    </StyledContainer>
  );
};

ProductDetailPage.propTypes = {
  urlImg: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
};

ProductDetailPage.defaultProps = {
  urlImg: '',
  match: null,
  history: null,
};

export default withRouter(ProductDetailPage);

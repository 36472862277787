import { connect } from 'react-redux';
import { NotificationPage } from 'components';
import { fromAuth } from 'store/selectors';
import { withRouter } from 'react-router-dom';
import {
  getNotifications,
  registerADevice,
  getNumberOfUnseenNotifications,
  markAllNotificationsAsRead,
  markANotificationAsRead,
  archiveNotification,
  markAllAsSeen,
  updateNewNotification,
  unRegisterDevice,
} from 'store/actions';
import {
  selectArchivedNotification,
  selectNotifications,
  selectNotificationsError,
  selectNotificationsLoading,
  selectNotificationsPagination,
  selectUnseenNotifications,
} from '../store/notification/selectors';

const mapStateToProps = state => ({
  userIsReady: !!state.session.user.id && fromAuth.firebaseAuthSuccess(state),
  notifications: selectNotifications(state),
  getNotificationsError: selectNotificationsError(state),
  getNotificationsLoading: selectNotificationsLoading(state),
  pagination: selectNotificationsPagination(state),
  unseenNotifications: selectUnseenNotifications(state),
  hasNotificationArchived: selectArchivedNotification(state),
});

const mapDispatchToProps = {
  getNotifications,
  getNumberOfUnseenNotifications,
  markAllNotificationsAsRead,
  markANotificationAsRead,
  archiveNotification,
  markAllAsSeen,
  updateNewNotification,
  registerADevice,
  unRegisterDevice,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NotificationPage),
);

import { put, call, fork, take, delay } from 'redux-saga/effects';
import Log from 'services/log';
import {
  consumeItemSuccess,
  consumeItemFailure,
  resetConsumableItem,
} from './actions';
import { CONSUME_ITEM } from './actionTypes';

export function* consumeItem(api, { hedgieID, userItemId, itemId }) {
  try {
    Log.debug('saga: consumeItem');
    const response = yield call([api, api.post], '/effects', {
      hedgieID,
      userItemID: userItemId,
    });
    Log.debug('saga: consumeItem:response');
    yield put(consumeItemSuccess({ ...response, userItemId, itemId }));
    yield delay(1000);
    yield put(resetConsumableItem());
  } catch (e) {
    Log.debug('saga: consumeItem:failure');
    yield put(consumeItemFailure(e));
  }
}

export function* watchConsumeItem(api) {
  while (true) {
    const { payload } = yield take(CONSUME_ITEM);
    yield call(consumeItem, api, payload);
  }
}

export default function* ({ api, firebase }) {
  yield fork(watchConsumeItem, api);
}

import stashBag from '../images/house/stash_bag.png';
import stashBucket from '../images/house/stash_bucket.png';
import stachChest from '../images/house/stach_chest.png';

export const FURNITURE_TYPES = {
  table: 'table',
  chair: 'chair',
  water_basin: 'water_basin',
  lamp: 'lamp',
  bed: 'bed',
  stool: 'stool',
  heater: 'heater',
  fireplace: 'fireplace',
  bookshelf: 'bookshelf',
};

export const STASH = [
  {
    id: 'stashBucket',
    name: 'stashBucket',
    imgURL: stashBucket,
    placeholder: '',
    width: 157,
    pos: { left: '46%', bottom: '25%' },
  },
  {
    id: 'stashBag',
    name: 'stashBag',
    imgURL: stashBag,
    placeholder: '',
    width: 196,
    pos: { right: '27%', bottom: '23.8%' },
  },
  {
    id: 'stachChest',
    name: 'stachChest',
    imgURL: stachChest,
    placeholder: '',
    width: 215,
    pos: { left: '30.6%', bottom: '22.9%' },
  },
];

export const FURNITURE_POSITIONS = {
  [FURNITURE_TYPES.table]: {
    width: 262,
    anchor: FURNITURE_TYPES.table,
    pos: { right: '36.5%', bottom: '-3%', zIndex: 3 },
  },

  [FURNITURE_TYPES.chair]: {
    width: 193,
    anchor: FURNITURE_TYPES.chair,
    pos: { right: '21.8%', bottom: '4%', zIndex: 1 },
  },
  [FURNITURE_TYPES.stool]: {
    width: 160,
    anchor: FURNITURE_TYPES.stool,
    pos: { left: '24%', bottom: '4%', zIndex: 5 },
  },
  [FURNITURE_TYPES.bed]: {
    width: 234,
    anchor: FURNITURE_TYPES.bed,
    pos: { top: '14.5%', right: '18%', zIndex: 4 },
  },
  [FURNITURE_TYPES.lamp]: {
    width: 138,
    anchor: FURNITURE_TYPES.lamp,
    pos: { top: '24%', right: '9%', zIndex: 6 },
  },
  [FURNITURE_TYPES.water_basin]: {
    width: 136,
    anchor: FURNITURE_TYPES.water_basin,
    pos: { left: '37%', top: '41%' },
  },
  [FURNITURE_TYPES.heater]: {
    width: 127,
    anchor: FURNITURE_TYPES.heater,
    pos: { right: '36.5%', top: '26%', zIndex: 5 },
  },
  [FURNITURE_TYPES.fireplace]: {
    width: 386,
    anchor: FURNITURE_TYPES.fireplace,
    pos: { left: '4%', top: '20%' },
  },
  [FURNITURE_TYPES.bookshelf]: {
    width: 232,
    anchor: FURNITURE_TYPES.bookshelf,
    pos: { bottom: '11%', right: '8%', zIndex: 0 },
  },
};

export const EARTH = 'earth';
export const FIRE = 'fire';
export const METAL = 'metal';
export const WATER = 'water';
export const WOOD = 'wood';

export const RESOURCE_TYPES = [EARTH, FIRE, METAL, WATER, WOOD];
export const REQUIRED_RESOURCES = [
  'requiredEarth',
  'requiredWater',
  'requiredWood',
  'requiredFire',
  'requiredMetal',
];

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'components';
import useDidMount from '../../custom-hooks/useDidMount';

const LoadableImg = styled.div`
  background-image: url(${props => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-origin: border-box;
  transition: filter ease 1s;
  filter: ${props => (!props.loaded ? 'blur(3px)' : 'unset')};
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const StyledPlaceholderImg = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  position: relative;
`;

const ProgressiveImageLoader = ({ placeHolderUrl, imgUrl, ...props }) => {
  const isMounted = useDidMount();
  const [loadState, setLoadState] = useState({
    imgUrl: placeHolderUrl,
    loaded: false,
  });
  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      if (isMounted) {
        setLoadState({
          imgUrl: img.src,
          loaded: true,
        });
      }
    };
    img.src = imgUrl;
  }, [imgUrl, isMounted]);

  return !loadState.loaded && !placeHolderUrl ? (
    <StyledPlaceholderImg {...props}>
      <div>
        <Spinner noFixed />{' '}
        <span style={{ fontSize: 12, color: '#888' }}>Image Loading...</span>
      </div>
    </StyledPlaceholderImg>
  ) : (
    <LoadableImg {...loadState} {...props} />
  );
};

ProgressiveImageLoader.propTypes = {
  placeHolderUrl: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
};

export default memo(ProgressiveImageLoader);

import powerWaterImg from 'images/powers/powerWater.svg';
import powerFireImg from 'images/powers/powerFire.svg';
import powerEarthImg from 'images/powers/powerEarth.svg';
import powerAirImg from 'images/powers/powerAir.svg';
import skillLuckImg from 'images/powers/skillLuck.svg';
import skillPrudenceImg from 'images/powers/skillPrudence.svg';
import skillIntelligenceImg from 'images/powers/skillIntelligence.svg';
import skillCharmImg from 'images/powers/skillCharm.svg';
import lvlIcon from 'images/powers/lvl.svg';

const POINTS = [
    {
        id: 'attribute_fire_points',
        url: powerFireImg,
    },
    {
        id: 'attribute_water_points',
        url: powerWaterImg,
    },
    {
        id: 'attribute_air_points',
        url: powerAirImg,
    },
    {
        id: 'attribute_earth_points',
        url: powerEarthImg,
    },

    {
        id: 'attribute_charm_points',
        url: skillCharmImg,
    },
    {
        id: 'attribute_intelligence_points',
        url: skillIntelligenceImg,
    },
    {
        id: 'attribute_luck_points',
        url: skillLuckImg,
    },
    {
        id: 'attribute_prudence_points',
        url: skillPrudenceImg,
    },
    {
        id: 'level_points',
        url: lvlIcon,
    }
];

export default POINTS;
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const SubHeadingStyled = styled.h3`
  color: ${palette(0)};
  font-weight: normal;
  font-size: 1em;
`;
SubHeadingStyled.defaultProps = {
  palette: 'headline',
};

const SubHeadingImage = styled.img`
  max-width: 57.5px;
  padding-bottom: 20px;
`;

const SubHeading = ({ heading, className, imgSrc, render }) => {
  let headingComp;
  if (!render) {
    headingComp = (<SubHeadingStyled className={`pageSubHeading ${className}`} dangerouslySetInnerHTML={{ __html: heading }} />);
  } else {
    headingComp = (<SubHeadingStyled className={`pageSubHeading ${className}`} >{render()}</SubHeadingStyled>);
  }
  if (imgSrc) {
    return (
      <div className="pageSubHeadingContainer d-flex justify-content-center flex-column">
        <SubHeadingImage src={imgSrc} alt="" className="m-auto subheading-image" />
        { headingComp }
      </div>
    );
  }
  return (
    <React.Fragment>{ headingComp }</React.Fragment>
  );
};

SubHeading.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  render: PropTypes.func,
};

SubHeading.defaultProps = {
  heading: '',
  className: '',
  imgSrc: null,
  render: null,
};

export default SubHeading;

import { includes } from 'lodash';
import { useSelector } from 'react-redux';
import { fromHedgies } from 'store/selectors';
import { selectLowestJourneyEnergy } from 'store/journey/selectors';

export default (selectedHedgie) => {
  const hedigesOnJourney = useSelector((state) =>
    fromHedgies.hedigesOnJourney(state),
  );
  const lowestJourneyEnergy = useSelector((state) =>
    selectLowestJourneyEnergy(state),
  );

  const isJourneyInProgress =
    selectedHedgie && includes(hedigesOnJourney, selectedHedgie.id);

  const isBattleInProgress = selectedHedgie && selectedHedgie.jsBattling;
  const isNotEnoughEnergy =
    selectedHedgie && selectedHedgie.energy < lowestJourneyEnergy;

  return { isJourneyInProgress, isBattleInProgress, isNotEnoughEnergy };
};

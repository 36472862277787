import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageTitle } from 'components';
import { get } from 'lodash';
import Participants from './Participants';

import styled from 'styled-components';

export const BattleDetailContext = React.createContext({});

const StyledContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 10px;
`;

const BattleDetailPage = ({
  match,
  myUserID,
  battleDetail,
  getBattleDetail,
  sawBattleDetail,
}) => {
  const battleId = get(match, 'params.battleId', '');
  const {
    data: { participants },
  } = battleDetail;
  const playerColor =
    participants?.[0]?.hedgie?.owner === myUserID
      ? participants?.[0]?.hedgie?.color
      : participants?.[1]?.hedgie?.color;

  useEffect(() => {
    getBattleDetail(battleId);
  }, [battleId, getBattleDetail]);

  useEffect(() => {
    sawBattleDetail(playerColor);
  }, [playerColor, sawBattleDetail]);

  return (
    <BattleDetailContext.Provider
      value={{
        match,
      }}
    >
      <StyledContainer>
        <PageTitle title="Battle result" />
        <Participants participants={participants} myUserID={myUserID} />
      </StyledContainer>
    </BattleDetailContext.Provider>
  );
};

BattleDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  myUserID: PropTypes.string.isRequired,
  battleDetail: PropTypes.object.isRequired,
  getBattleDetail: PropTypes.func.isRequired,
};

export default BattleDetailPage;

import React from 'react';
import PropTypes from 'prop-types';

const PriceDisplay = ({ price }) => {
  const dollarPrice = price / 100;
  const numDigits = dollarPrice.toString().length;
  return (
    <div className="green-circle">
      <span className={`price-inner fs-lg always-center fs-length-${numDigits}`} >
      (0.04ETH+GAS {dollarPrice}<em>$</em>)
      </span>
    </div>
  );
};

PriceDisplay.propTypes = {
  price: PropTypes.number.isRequired,
};

export default PriceDisplay;

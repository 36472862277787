import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { isDev, isBrowser } from 'config/env';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { sessionService } from 'redux-react-session';
import { createLogger } from 'redux-logger';
import { SIGNIN } from 'config/paths';
import middlewares from './middlewares';
import createRootReducer from './reducer';
import sagas from './sagas';

/* eslint-disable no-underscore-dangle */
const devtools =
  isDev && isBrowser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : () => fn => fn;
/* eslint-enable */

export const history = createBrowserHistory();

const configureStore = (initialState, services = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  // Build the middleware for intercepting and dispatching navigation actions
  const middleware = routerMiddleware(history);
  const allMiddlewares = [
    sagaMiddleware,
    thunk.withExtraArgument(services),
    middleware,
  ];

  if (process.env.REACT_APP_DEBUG === 'true') {
    allMiddlewares.push(createLogger());
  }

  const enhancers = [
    applyMiddleware(...middlewares, ...allMiddlewares),
    devtools(),
  ];

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(...enhancers),
  );
  let sagaTask = sagaMiddleware.run(sagas, services);
  // Init the session service
  sessionService.initSessionService(store, {
    redirectPath: SIGNIN,
    driver: 'LOCALSTORAGE',
  });

  if (module.hot) {
    const reducerComponent = require('./reducer').default;
    module.hot.accept(reducerComponent, () => {
      const nextReducer = require('./reducer').default;
      store.replaceReducer(nextReducer);
    });
    const sagasComponent = require('./sagas').default;
    module.hot.accept(sagasComponent, () => {
      const nextSagas = require('./sagas').default;
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(nextSagas, services);
      });
    });
  }

  return store;
};

export default configureStore;

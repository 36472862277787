import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useCountDown from '../../custom-hooks/useCountDown';

const Timer = ({ seconds, onComplete }) => {
  const { time, display } = useCountDown(seconds);
  useEffect(
    () => {
      if (time === 0) {
        onComplete();
      }
    },
    [onComplete, time],
  );

  return `${display}`;
};

Timer.propTypes = {
  seconds: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
};

Timer.defaultProps = {
  onComplete: null,
};

export default Timer;

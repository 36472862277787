import React from 'react';
import { SubHeading } from 'components';

const SubHeadingBold = props => {
  let classNames = 'ff-semi-bold fs-extra-md sub-heading-bold';
  if (!props.noPadding) {
    classNames = `${classNames} pb-3`;
  }
  return (<div className={classNames} style={props.style}><SubHeading {...props} /></div>);
};

export default SubHeadingBold;

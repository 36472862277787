import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as types from 'types';
import Log from 'services/log';
import * as paths from 'config/paths';
import { ForgotPasswordForm, PageTitle, SideHedgie } from 'components';

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    if (this.props.user === null) {
      this.props.history.push(paths.HOME);
    }
  }

  componentDidUpdate() {
    if (this.props.forgotPasswordStatus) {
      this.props.history.push(paths.FORGOT_PASSWORD_THANK_YOU);
    }
  }

  handleForgotPassword = ({ email }) => {
    Log.debug({ email }, 'handleForgotPassword');
    this.props.forgotPassword({ email });
  };

  render() {
    const { error, working } = this.props;
    Log.debug(this.props, 'SettingsPage:render');
    return (
      <React.Fragment>
        <main>
          <Container fluid className="pos-rel sign-up-wrap">
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Forgot Password" />
                </Col>
              </Row>
              <Row style={{ marginBottom: 100 }}>
                <Col>
                  <ForgotPasswordForm
                    onSubmit={this.handleForgotPassword}
                    error={error}
                    working={working}
                  />
                </Col>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-5" />
                    <div className="col-md-7" />
                  </div>
                </div>
              </Row>
            </Container>
            <SideHedgie
              className="side-hedgie-secret"
              color="blue"
              title="It’s a secret!"
            />
          </Container>
        </main>
      </React.Fragment>
    );
  }
}

ForgotPasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  working: PropTypes.bool,
  forgotPassword: PropTypes.func.isRequired,
  error: types.error,
  user: types.user.isRequired,
  forgotPasswordStatus: PropTypes.bool,
};

ForgotPasswordPage.defaultProps = {
  signUpFailed: false,
  signUpSuccess: false,
  error: null,
  working: false,
  forgotPasswordStatus: false,
};

export default ForgotPasswordPage;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import * as paths from 'config/paths';
import Typography from '../../stateless/Typography';
import HeartIconSvg from './HeartIconSvg';

const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const scale = keyframes`
  0% { transform: scale(1, 1); }
  50% { transform: scale(0.8, 0.8);}
  100% { transform: scale(1, 1);}
`;

const StyledPopUp = styled.div`
  .message {
    border: 3px solid #12dfc0;
    border-radius: 15px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
  }
  opacity: 0;
  transform: scale(0.6, 0.6);
  transition: all 400ms cubic-bezier(0, 1.55, 0.57, 0.57);
  &.open {
    transition: all 400ms cubic-bezier(0, 1.55, 0.57, 0.57);
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const StyleHeart = styled.div`
  animation: ${scale} 1s linear infinite;
`;

const ConfirmationMessage = ({ hasPurchased, history, clearOrderState }) => {
  const [openMessage, setOpenMessage] = React.useState(false);

  React.useEffect(
    () => {
      if (hasPurchased) {
        setOpenMessage(true);
        setTimeout(() => {
          clearOrderState();
          history.push(paths.TRANSACTIONS);
        }, 1500);
      }
    },
    [clearOrderState, hasPurchased, history],
  );

  return (
    <StyledMessage>
      <StyledPopUp className={openMessage ? 'open' : ''}>
        <div className="message">
          <Typography
            text="Thank You! Purchase Confirmed"
            isBold
            className="pr-3"
            color="#12DFC0"
          />
          <StyleHeart>
            <HeartIconSvg />
          </StyleHeart>
        </div>
      </StyledPopUp>
    </StyledMessage>
  );
};

ConfirmationMessage.propTypes = {
  hasPurchased: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  clearOrderState: PropTypes.func.isRequired,
};

export default ConfirmationMessage;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

const StyledText = styled.span`
  color: ${props => props.color};
  font-size: 16px;
  font-weight: 400;
`;

const StyledLink = styled.a``;

const IconAndText = ({ icon, text, link, color, ...props }) => {
  const style = { height: 18, width: 18, color };
  if (link) {
    return (
      <StyledWrapper {...props}>
        <StyledLink href={link} target="_blank">
          <FontAwesomeIcon icon={icon} style={style} />
          <StyledText className="pl-1" color={color}>
            {text}
          </StyledText>
        </StyledLink>
      </StyledWrapper>
    );
  }
  return (
    <StyledWrapper {...props}>
      <FontAwesomeIcon icon={icon} style={style} />
      <StyledText className="pl-1" color={color}>
        {text}
      </StyledText>
    </StyledWrapper>
  );
};

IconAndText.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
};

IconAndText.defaultProps = {
  icon: null,
  text: '',
  link: '',
  color: '#62295E',
};

export default IconAndText;

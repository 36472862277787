import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  BusinessList,
  Button,
  Mobile,
  Desktop,
  PageTitle,
  SubHeading,
} from 'components';
import * as paths from 'config/paths';
import Redirect from 'config/redirect';

const BUSINESSES_PAGE_LIMIT = 20;

class ApprovedBusinessPage extends PureComponent {
  state = {
    businesses: [],
    loadMoreBusinesses: true,
  };
  UNSAFE_componentWillMount() {
    this.handleOnLoadMore();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.businesses !== newProps.businesses) {
      const { pagination } = newProps.businesses;
      this.setState({
        businesses: [...this.state.businesses, ...newProps.businesses.list],
        loadMoreBusinesses:
          pagination && pagination.offset + pagination.limit < pagination.total,
      });
    }
  }

  handleOnLoadMore = () => {
    const limit = BUSINESSES_PAGE_LIMIT;
    const offset = this.state.businesses.length;

    this.props.getBusinesses({
      offset,
      limit,
    });
  };

  render() {
    const { businesses, loadMoreBusinesses } = this.state;
    const approvedBusinessText = (
      <React.Fragment>
        <p className="text-center">
          Got your own business?{' '}
          <Link to={paths.BUSINESSES}>
            Find out how you can work with Hedgie!
          </Link>
        </p>
      </React.Fragment>
    );
    const loadMoreBtn = (
      <div className="text-center">
        <Button
          size="large"
          className="mt-0 mb-5"
          onClick={this.handleOnLoadMore}
        >
          More Businesses
        </Button>
      </div>
    );
    return (
      <div className="business-page">
        <Redirect />
        <main>
          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <PageTitle title="Hedgie-Approved Businesses" />
                </Col>
              </Row>
              <Row className="pb-5">
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <div className="text-left text-md-center">
                    <SubHeading heading="A reward for all your Hedgie’s hard work! The Curio you make in the game can be used to spend in the real world at these local and online Hedgie-Approved businesses." />
                  </div>
                  {approvedBusinessText}
                </Col>
              </Row>
              <Desktop>
                <Row className="mt-3">
                  <BusinessList loading={false} businesses={businesses} />
                </Row>
                {loadMoreBusinesses && loadMoreBtn}
              </Desktop>
              <Mobile className="mt-3">
                <Row>
                  <BusinessList loading={false} businesses={businesses} />
                </Row>
                {loadMoreBusinesses && loadMoreBtn}
              </Mobile>
            </Container>
          </Container>
        </main>
      </div>
    );
  }
}

ApprovedBusinessPage.propTypes = {
  businesses: PropTypes.object.isRequired,
  getBusinesses: PropTypes.func.isRequired,
};
ApprovedBusinessPage.defaultProps = {};
export default ApprovedBusinessPage;

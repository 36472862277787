import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormGroup, Row, Col } from 'reactstrap';
import { Button, CustomInput, CustomCheckBox, CustomCheckBoxCasl } from 'components';
import * as paths from 'config/paths';
import Log from 'services/log';
import { recaptchaKey } from 'config/env';
import FormError from 'components/stateless/FormError';
import * as types from 'types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
const usernameRegex = /^[a-z0-9.\-_]{3,32}$/;

const signUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Please fill your email'),
  username: Yup.string().matches(usernameRegex, 'Please use only small cap letters or digits in username. No special characters.'),
  passwordOne: Yup.string()
    .min(6, 'Password must be at least 6 characters!')
    .required('Please fill your password'),
  passwordTwo: Yup.string()
    .oneOf([Yup.ref('passwordOne'), null], 'Passwords entered does not match')
    .min(6, 'Password must be at least 6 characters!')
    .required('Please fill your password again'),
  tocAccepted: Yup.boolean().oneOf([true,false], 'Please accept Terms & Conditions'),
  recaptcha: Yup.string().required('Recaptcha is required'),
});

const SignUpForm = ({ error, onSignUp, referralCode, working }) => {
  Log.debug('render', 'SignUpForm');
  const mdSize = 8;
  const initialValues = {
    email: '',
    username: '',
    passwordOne: '',
    passwordTwo: '',
    tocAccepted: false,
    caslAccepted: false,
    referralCode: referralCode || '',
    recaptcha: '',
  };

  return (
    <div className="hedgieForm">
      <Formik
        validationSchema={signUpSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const {
            email,
            username,
            passwordOne,
            tocAccepted,
            caslAccepted,
            referralCode,
            recaptcha,
          } = values;
          onSignUp(
            email,
            username,
            passwordOne,
            tocAccepted,
            caslAccepted,
            referralCode,
            recaptcha,
          );
        }}
      >
        {({ dirty, setFieldValue, handleSubmit, errors, touched }) => (
          <Form m3={3}>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  component={CustomInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Username"
                  component={CustomInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="password"
                  id="passwordOne"
                  name="passwordOne"
                  placeholder="Password"
                  component={CustomInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="password"
                  id="passwordTwo"
                  name="passwordTwo"
                  placeholder="Confirm Password"
                  component={CustomInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="text"
                  id="referralCode"
                  name="referralCode"
                  placeholder="Referral Code"
                  component={CustomInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="checkbox"
                  id="tocAccepted"
                  name="tocAccepted"
                  component={CustomCheckBox}
                  label={
                    <span className="checkbox-label">
                      I agree to{' '}
                      <a
                        href="/terms"
                        target="_blank"
                        className="ff-extra-bold"
                      >
                        Terms &amp; Conditions
                      </a>
                    </span>
                  }
                />
              </Col>
            </FormGroup>
             <FormGroup row>
              <Col md={mdSize}>
                <Field
                  type="checkbox"
                  id="caslAccepted"
                  name="caslAccepted"
                  component={CustomCheckBoxCasl}
                  label={
                    <span className="checkbox-label">
                      I agree to receive emails
                    </span>
                  }
                />
              </Col>
            </FormGroup> 
            <FormError error={error} />
            <FormGroup row>
              <Col md={mdSize}>
                <div>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={(v) => setFieldValue('recaptcha', v)}
                  />
                  {errors.recaptcha && touched.recaptcha && (
                    <FormError error={{ message: errors.recaptcha }} />
                  )}
                </div>
              </Col>
            </FormGroup>
            <Row>
              <FormGroup className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
                <Button size="large" type="submit" disabled={!dirty || working}>
                  {working ? 'Saving...' : 'Sign Up'}
                </Button>
              </FormGroup>
            </Row>
          </Form>
        )}
      </Formik>
      <Row>
        <Col>
          Already have an account?{' '}
          <Link to={paths.SIGNIN} className="ff-extra-bold">
            Sign In Here.
          </Link>
        </Col>
      </Row>
    </div>
  );
};

SignUpForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  referralCode: PropTypes.string,
  working: PropTypes.bool,
  error: types.error,
};

SignUpForm.defaultProps = {
  error: null,
  referralCode: '',
  working: false,
};

export default SignUpForm;

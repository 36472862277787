import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeadLine, Typography } from 'components';
import imgCurio from 'images/curio-styled.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const StyledGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCurioInfo = styled.div`
  padding-left: 20px;
`;

const UserInfo = ({ userBalance, total }) => {
  return (
    <React.Fragment>
      <StyledContainer>
        <StyledGroup>
          <img src={imgCurio} alt="curio" />
          <StyledCurioInfo>
            <Typography fontSize={18} text="Curio:" isBold align="left" />
            <HeadLine
              title={total}
              fontSize={24}
              fontWeight={800}
              align="left"
              color="#642460"
            />
            <Typography
              fontSize={18}
              text={`Your balance: ${userBalance}`}
              isBold
            />
          </StyledCurioInfo>
        </StyledGroup>
      </StyledContainer>
    </React.Fragment>
  );
};

UserInfo.propTypes = {
  userBalance: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
};

export default UserInfo;

import React from 'react';
import WaitingChallengers from './WaitingChallengers';
import { pageNames, useBattle } from './battle-context';
import BattleHome from './BattleHome';
import ChallengerFound from './ChallengerFound';
import styled from 'styled-components';
import BattlePoints from './BattlePoints';
import Winner from './Winner';

export const StyledBattleLayout = styled.div`
  position: relative;
`;

export const StyledContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 0;
`;

const MainFlow = () => {
  const { page, battleError } = useBattle();

  return (
    <StyledBattleLayout>
      <div>
        {page === pageNames.BATTLE_HOME && <BattleHome />}
        {page === pageNames.WAITING_CHALLENGER && <WaitingChallengers />}
        {page === pageNames.CHALLENGER_FOUND && <ChallengerFound />}
        {page === pageNames.POINTS_SUMMARY && <BattlePoints />}
        {page === pageNames.WINNER && <Winner />}
        {battleError && (
          <div className="alert alert-danger pt-3 pb-3">{battleError}</div>
        )}
      </div>
    </StyledBattleLayout>
  );
};

export default MainFlow;

import React from 'react';
import styled from 'styled-components';
import * as paths from 'config/paths';
import houseIconUrl from 'images/house/house-icon.png';
import { Link } from 'react-router-dom';

const StyledContainer = styled.div`
  text-align: right;
  > a {
    display: inline-block;
  }
`;
const StyledWrapper = styled.div`
  border: 2px solid #f4ab13;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 10px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
  .img-wrapper {
    transition: transform 400ms ease;
    transform: rotate(0deg) scale(1, 1);
    &:hover {
      transform: rotate(8deg) scale(1.1, 1.1);
      transition: transform 400ms ease;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const HedgieHouseIcon = () => {
  return (
    <StyledContainer>
      <Link to={paths.HEDGIE_HOUSE || ''}>
        <StyledWrapper id="TooltipExample">
          <div className="img-wrapper">
            <img src={houseIconUrl} alt="houseIconUrl" />
          </div>
        </StyledWrapper>
      </Link>
    </StyledContainer>
  );
};

// HedgieHouseIcon.propTypes = {
// };

export default HedgieHouseIcon;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getNotificationLink } from 'helper';
import { Typography } from 'components';

const StyledMessageTitle = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #000;
`;

const StyledWrapper = styled.div`
  padding: 10px;
`;

const NotificationToast = ({
  message,
  href,
  history,
  dispatch,
  markANotificationAsRead,
  notificationId,
}) => {
  const clickCallback = useCallback(
    () => {
      const path = getNotificationLink(href);
      dispatch(markANotificationAsRead(notificationId));
      history.push(path);
    },
    [dispatch, history, href, markANotificationAsRead, notificationId],
  );

  return (
    <StyledWrapper onClick={clickCallback}>
      <StyledMessageTitle>{message.title}</StyledMessageTitle>
      <Typography
        text={message.body}
        fontSize={12}
        align="left"
        color="#798C93"
        className="pt-1"
      />
    </StyledWrapper>
  );
};

NotificationToast.propTypes = {
  message: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  markANotificationAsRead: PropTypes.func,
  notificationId: PropTypes.string,
};

NotificationToast.defaultProps = {
  history: null,
  dispatch: null,
  markANotificationAsRead: null,
  notificationId: '',
};

export default withRouter(NotificationToast);

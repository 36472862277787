import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider } from 'react-stripe-elements';

const StripeContext = ({ children }) => {
  const [stripe, setStripe] = useState(null);
  const { REACT_APP_STRIPE_API_KEY } = process.env;

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(REACT_APP_STRIPE_API_KEY));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(REACT_APP_STRIPE_API_KEY));
      });
    }
  }, [REACT_APP_STRIPE_API_KEY]);

  return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
};

StripeContext.propTypes = {
  children: PropTypes.any.isRequired,
};

export default StripeContext;

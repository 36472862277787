import { createSelector } from 'reselect';

const myBusinessesSelector = state => state.business.myBusinesses;
const productsSelector = state => state.business.products;
const businessDetailSelector = state => state.business.detail;
const productDetailSelector = state => state.business.productDetail;
const orderedProductSelector = state => state.business.orderedProduct;
const ordersOfBusinessSelector = state => state.business.ordersOfBusiness;
const myOrdersSelector = state => state.business.myOrders;
const archivedOrderSelector = state => state.business.archivedOrder;

export const selectMyBusinesses = createSelector(
  myBusinessesSelector,
  myBusinesses => myBusinesses,
);

export const selectBusinessProducts = createSelector(
  productsSelector,
  products => products,
);

export const selectBusinessDetail = createSelector(
  businessDetailSelector,
  business => business,
);

export const selectProductDetail = createSelector(
  productDetailSelector,
  product => product,
);

export const selectOrderedProduct = createSelector(
  orderedProductSelector,
  orderedProduct => orderedProduct,
);

export const selectOrdersOfBusiness = createSelector(
  ordersOfBusinessSelector,
  ordersOfBusiness => ordersOfBusiness,
);

export const selectMyOrders = createSelector(
  myOrdersSelector,
  myOrders => myOrders,
);

export const selectArchivedOrder = createSelector(
  archivedOrderSelector,
  archivedOrder => archivedOrder,
);

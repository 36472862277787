import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { HedgieImg } from 'components';
import styled from 'styled-components';

const StyledCustomOption = styled.div`
  padding: 5px 10px;
  background: ${props => {
    if (props.isSelected) {
      return 'rgba(18,223,192, .6)';
    } else if (props.isFocused) {
      return 'rgba(18,223,192, .2)';
    }
    return null;
  }};
`;

const StyledHedgieOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const HedgieOption = ({ color }) => (
  <StyledHedgieOption>
    <HedgieImg color={`#${color}`} width={24} />
    <span className="pl-1">{`#${color}`}</span>
  </StyledHedgieOption>
);

HedgieOption.propTypes = {
  color: PropTypes.string.isRequired,
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <HedgieOption color={children} />
    </components.SingleValue>
  );
};

const CustomOption = ({
  children,
  innerProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  return !isDisabled ? (
    <StyledCustomOption
      {...innerProps}
      isSelected={isSelected}
      isFocused={isFocused}
    >
      <HedgieOption color={children} />
    </StyledCustomOption>
  ) : null;
};

const customStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    cursor: 'pointer',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #13dfc0',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#13dfc0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    overflowY: 'scroll',
    position: 'absolute !important',
    zIndex: '999999 !important',
  }),
};

const ConsumablesDropdown = React.memo(({ options, onChange }) => {
  const [value, setValue] = useState(options[0]);
  function handleChange(value) {
    setValue(value);
  }

  useEffect(
    () => {
      onChange(value);
    },
    [onChange, value],
  );

  return (
    <Select
      value={value}
      styles={customStyles}
      components={{ Option: CustomOption, SingleValue }}
      options={options}
      onChange={handleChange}
      theme={theme => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary25: 'rgba(18,223,192, .6)',
          primary: 'rgba(18,223,192, 1)',
          neutral10: 'rgba(18,223,192, 1)',
        },
      })}
    />
  );
});

ConsumablesDropdown.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
};

ConsumablesDropdown.defaultProps = {
  options: [],
  onChange: null,
};

export default ConsumablesDropdown;

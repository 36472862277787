import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';

const StyledHeadLine = styled.h1`
  font-weight: ${prosp => prosp.fontWeight};
  font-size: ${props => `${props.fontSize}px`};
  color: ${props => `${props.color}`};
  text-align: ${props => `${props.align}`};
  letter-spacing: 0;
  ${media.phone`
    font-size: ${props => props.fontSize - 2}px};
  `};
`;

const HeadLine = ({ title, ...props }) => {
  return <StyledHeadLine {...props}>{title}</StyledHeadLine>;
};

HeadLine.propTypes = {
  title: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

HeadLine.defaultProps = {
  fontSize: 40,
  fontWeight: 600,
  title: '',
  color: '#12dfc0',
  align: 'center',
};
export default HeadLine;

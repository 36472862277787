import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, FormError } from 'components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { closePopUpExp, upgradeHedgie } from '../../../store/level/actions';
import PowerChangeGrid from './PowerChangeGrid';

const PowerChangePopup = ({ hedgieId, nextLevel }) => {
  const dispatch = useDispatch();
  const openModalExp = useSelector(state => state.level.openModal);

  const upgradedHedgieError = useSelector(
    state => state.level.upgradedHedgieErorr,
  );

  const closePopUpCallback = useCallback(() => {
    dispatch(closePopUpExp());
  }, [dispatch]);

  const nextLevelPoints = get(nextLevel, 'points', 0);

  return (
    <Modal isOpen={openModalExp}>
      <ModalHeader toggle={closePopUpCallback}>
        Your Hedgie Leveled Up!
      </ModalHeader>
      <ModalBody>
        <Typography
          className="pb-3"
          fontSize={16}
          text={`Your have ${nextLevelPoints} XP points. Each point boosts your power by 2 or your energy by 1.`}
          color="#000"
        />
        <PowerChangeGrid
          points={nextLevelPoints}
          hedgieId={hedgieId}
          upgradeHedgie={upgradeHedgie}
          dispatch={dispatch}
        />
        {upgradedHedgieError.error ? (
          <FormError error={upgradedHedgieError} />
        ) : null}
      </ModalBody>
    </Modal>
  );
};

PowerChangePopup.propTypes = {
  hedgieId: PropTypes.number,
  nextLevel: PropTypes.object,
};

PowerChangePopup.defaultProps = {
  hedgieId: 0,
  nextLevel: null,
};

export default React.memo(PowerChangePopup);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Element } from 'react-scroll';
// import { media } from 'styles/main';

const StyledFurniture = styled.div`
  position: absolute;
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => `calc(100% * ${props.width} / 980)`};
  z-index: ${props => props.zIndex};
  transition: transform ease-in-out 300ms;
  img {
    width: 100%;
    height: auto;
  }
`;

const FurnitureImage = ({ width, imgURL, pos, type, anchor }) => {
  return (
    <StyledFurniture width={width} {...pos}>
      <Element name={anchor}>
        <img src={imgURL} alt={type} />
      </Element>
    </StyledFurniture>
  );
};

FurnitureImage.propTypes = {
  pos: PropTypes.object.isRequired,
  imgURL: PropTypes.string.isRequired,
  width: PropTypes.number,
};

FurnitureImage.defaultProps = {
  width: 0,
};
export default FurnitureImage;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

const TreasuresBlockBlock = styled.div`
  display: flex !important;
  background: #ffffff;
  width: 100%;
`;

const TreasuresBlockText = ({ children }) => (
  <p className="text-right">{children}</p>
);
TreasuresBlockText.propTypes = {
  children: PropTypes.any.isRequired,
};

const TreasuresBlockHeader = ({ children }) => (
  <h2 className="text-right">{children}</h2>
);
TreasuresBlockHeader.propTypes = {
  children: PropTypes.any.isRequired,
};

const TreasuresBlock = () => {
  return (
    <Row>
      <TreasuresBlockBlock>
        <Col md="6">
          { ' ' }
        </Col>
        <Col md="6">
          <TreasuresBlockHeader>What treasures lie below?</TreasuresBlockHeader>
          <TreasuresBlockText>Many years ago, a fateful day known as The Day of Digging forced every Hedgie in Curiopia underground. (Psst… read the full story here). Below the surface, they uncovered all sorts of valuables from their present day, and from centuries earlier. This remains one of the reasons why Hedgies continue to dig deeper to this day…</TreasuresBlockText>
          <TreasuresBlockText>Dig with your Hedgie to uncover loot, boost performance, and earn you some crypto coin!</TreasuresBlockText>
        </Col>
      </TreasuresBlockBlock>
    </Row>
  );
};

export default TreasuresBlock;

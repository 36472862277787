import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import * as types from 'types';

import InjectedCardPurchaseForm from './CardPurchaseForm';

class CardPurchase extends React.Component {
  render() {
    const { id, price, onSubmitCC, error, working } = this.props;
    return (
      <Elements>
        <InjectedCardPurchaseForm error={error} id={id} price={price} onSubmitCC={onSubmitCC} working={working} />
      </Elements>
    );
  }
}

CardPurchase.propTypes = {
  id: PropTypes.string,
  price: PropTypes.number.isRequired,
  onSubmitCC: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

CardPurchase.defaultProps = {
  id: '',
  error: null,
  working: false,
};

export default CardPurchase;

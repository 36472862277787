import {
  ARCHIVE_ORDER,
  ARCHIVE_ORDER_FAILURE,
  ARCHIVE_ORDER_SUCCESS,
  CLEAR_ORDER_STATE,
  CREATE_AN_ORDER,
  CREATE_AN_ORDER_FAILURE,
  CREATE_AN_ORDER_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_BUSINESS_DETAIL,
  GET_BUSINESS_DETAIL_FAILURE,
  GET_BUSINESS_DETAIL_SUCCESS,
  GET_BUSINESS_PRODUCTS,
  GET_BUSINESS_PRODUCTS_FAILURE,
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_MY_BUSINESSES,
  GET_MY_BUSINESSES_FAILURE,
  GET_MY_BUSINESSES_SUCCESS,
  GET_MY_ORDERS,
  GET_MY_ORDERS_FAILURE,
  GET_MY_ORDERS_SUCCESS,
  GET_ORDERS_OF_BUSINESS,
  GET_ORDERS_OF_BUSINESS_FAILURE,
  GET_ORDERS_OF_BUSINESS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
} from './actionTypes';

export const getMyBusinesses = payload => ({
  type: GET_MY_BUSINESSES,
  payload,
});

export const getMyBusinessesSuccess = payload => ({
  type: GET_MY_BUSINESSES_SUCCESS,
  payload,
});

export const getMyBusinessesFailure = error => ({
  type: GET_MY_BUSINESSES_FAILURE,
  payload: error,
});

export const getBusinessProducts = businessId => ({
  type: GET_BUSINESS_PRODUCTS,
  payload: businessId,
});

export const getBusinessProductsSuccess = payload => ({
  type: GET_BUSINESS_PRODUCTS_SUCCESS,
  payload,
});

export const getBusinessProductsFailure = error => ({
  type: GET_BUSINESS_PRODUCTS_FAILURE,
  payload: error,
});

export const getBusinessDetail = businessId => ({
  type: GET_BUSINESS_DETAIL,
  payload: businessId,
});

export const getBusinessDetailSuccess = payload => ({
  type: GET_BUSINESS_DETAIL_SUCCESS,
  payload,
});

export const getBusinessDetailFailure = error => ({
  type: GET_BUSINESS_DETAIL_FAILURE,
  payload: error,
});

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  payload: productId,
});

export const getProductDetailSuccess = payload => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload,
});

export const getProductDetailFailure = error => ({
  type: GET_PRODUCT_DETAIL_FAILURE,
  payload: error,
});

export const createAnOrder = payload => ({
  type: CREATE_AN_ORDER,
  payload,
});

export const createAnOrderSuccess = payload => ({
  type: CREATE_AN_ORDER_SUCCESS,
  payload,
});

export const createAnOrderFailure = error => ({
  type: CREATE_AN_ORDER_FAILURE,
  payload: error,
});

export const clearOrderState = () => ({
  type: CLEAR_ORDER_STATE,
});

export const getOrdersOfBusiness = payload => ({
  type: GET_ORDERS_OF_BUSINESS,
  payload,
});

export const getOrdersOfBusinessSuccess = payload => ({
  type: GET_ORDERS_OF_BUSINESS_SUCCESS,
  payload,
});

export const getOrdersOfBusinessFailure = error => ({
  type: GET_ORDERS_OF_BUSINESS_FAILURE,
  payload: error,
});

export const getMyOrders = () => ({
  type: GET_MY_ORDERS,
});

export const getMyOrdersSuccess = payload => ({
  type: GET_MY_ORDERS_SUCCESS,
  payload,
});

export const getMyOrdersFailure = error => ({
  type: GET_MY_ORDERS_FAILURE,
  payload: error,
});

export const archiveAnOrder = orderId => ({
  type: ARCHIVE_ORDER,
  payload: orderId,
});

export const archiveAnOrderSuccess = payload => ({
  type: ARCHIVE_ORDER_SUCCESS,
  payload,
});

export const archiveAnOrderFailure = error => ({
  type: ARCHIVE_ORDER_FAILURE,
  payload: error,
});

export const getAllProducts = payload => ({
  type: GET_ALL_PRODUCTS,
  payload,
});

export const getAllProductsSuccess = payload => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload,
});

export const getAllProductsFailure = error => ({
  type: GET_ALL_PRODUCTS_FAILURE,
  payload: error,
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toString } from 'lodash';

const ImageLoaded = styled.div`
  width: 100%;
  background-image: ${props => (props.src ? `url(${props.src})` : '')};
  transition: ${props => `${props.transition}s filter linear`};
  filter: ${props => (props.loading ? `blur(${props.isBlur ? 20 : 0}px)` : '')};
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: ${props => `calc(100% / ${props.ratio})`};
  border: ${props =>
    props.active ? `${props.isMobile ? 4 : 6}px solid #CA8314` : null};
`;

class ImgLoader extends Component {
  state = {
    currentImage: this.props.preview,
    loading: true,
  };

  componentDidMount() {
    this.fetchImage(this.props.url);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ currentImage: nextProps.preview, loading: true }, () => {
        this.fetchImage(nextProps.url);
      });
    }
  }

  componentWillUnmount() {
    if (this.loadingImage) {
      this.loadingImage.onload = null;
    }
  }

  fetchImage = src => {
    const image = new Image();
    image.onload = () =>
      this.setState({ currentImage: this.loadingImage.src, loading: false });
    image.src = src;
    this.loadingImage = image;
  };

  render() {
    const { currentImage, loading } = this.state;
    const { alt, ratio, transition, isBlur } = this.props;
    return (
      <ImageLoaded
        isBlur={isBlur}
        src={currentImage}
        transition={transition}
        loading={toString(loading)}
        alt={alt}
        ratio={ratio}
      />
    );
  }
}

ImgLoader.propTypes = {
  alt: PropTypes.string,
  isBlur: PropTypes.bool,
  preview: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  transition: PropTypes.number,
};

ImgLoader.defaultProps = {
  transition: 0.5,
  isBlur: true,
  alt: 'default image',
};

export default ImgLoader;

import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import bgEarnMoney from '../images/earn-money-bg.jpg';
import endSlideMobile from '../images/earnMoneyMobile.png';
import EarnMoney from './EarnMoney';

const EarnMoneySlide = ({ isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer
        offset={6}
        speed={0}
        key="EarnMoney"
        style={{
          backgroundImage: `url(${isMobile ? endSlideMobile : bgEarnMoney})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <EarnMoney isMobile={isMobile} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

EarnMoneySlide.propTypes = {
  isMobile: PropTypes.bool,
};

EarnMoneySlide.defaultProps = {
  isMobile: false,
};

export default EarnMoneySlide;

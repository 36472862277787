import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowSvg } from 'components';

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
  }
  &:focus {
    outline: none;
  }
  &:active {
  }
`;

const NavButton = ({ onClick, disabled, isLeft }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <ArrowSvg direction={isLeft ? 'left' : 'right'} isActive={!disabled} />
    </StyledButton>
  );
};

NavButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLeft: PropTypes.bool,
};

NavButton.defaultProps = {
  onClick: null,
  disabled: false,
  isLeft: false,
};

export default NavButton;

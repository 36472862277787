import { createSelector } from 'reselect';
import { forEach } from 'lodash';

function mapResourcesWithUser(resourceTypes, user) {
  const newResources = { ...resourceTypes };
  forEach(resourceTypes, (value, key) => {
    newResources[key] = {
      ...newResources[key],
      amount: user[key],
    };
  });

  return newResources;
}

const hedgieResourceTypesSelector = state =>
  state.hedgieHouse.hedgieResourceTypes;
const userSelector = state => state.session.user;
const housesSelector = state => state.hedgieHouse.houses;
const furnitureForHouseSelector = state => state.hedgieHouse.furnitureForHouse;
const upgradedFurnitureSelector = state => state.hedgieHouse.upgradedFurniture;
const houseEffectsSelector = state => state.hedgieHouse.houseEffects;
const cleanHouseSelector = state => state.hedgieHouse.cleanHouse;

export const selectHedgieResourceTypes = createSelector(
  hedgieResourceTypesSelector,
  hedgieResourceTypes => hedgieResourceTypes,
);

export const selectResourceTypesData = createSelector(
  [hedgieResourceTypesSelector, userSelector],
  (hedgieResourceTypes, userInfo) =>
    mapResourcesWithUser(hedgieResourceTypes.data, userInfo),
);

export const selectHedgieHouses = createSelector(
  housesSelector,
  houses => houses,
);

export const selectFurnitureForHouse = createSelector(
  furnitureForHouseSelector,
  furniture => furniture,
);

export const selectUpgradedFurniture = createSelector(
  upgradedFurnitureSelector,
  upgradedFurniture => upgradedFurniture,
);

export const selectHouseEffectsTotal = createSelector(
  houseEffectsSelector,
  houseEffect => {
    const { energyRecoverRate } = houseEffect.data;
    if (energyRecoverRate) {
      return (Math.round(energyRecoverRate * 100) / 100).toFixed(2);
    }
    return '';
  },
);

export const selectHouseHealth = createSelector(
  houseEffectsSelector,
  effect => effect.data.health,
);

export const selectCleanHouseLoading = createSelector(
  cleanHouseSelector,
  clHouse => clHouse.loading,
);

export const selectCleanedHouse = createSelector(
  cleanHouseSelector,
  clHouse => clHouse.cleaned,
  clHouse => clHouse.eneryRecoverRate,
);

export const selectCleanHouseError = createSelector(
  cleanHouseSelector,
  clHouse => clHouse.error,
);

export const selectCleanHouseSuccess = createSelector(
  cleanHouseSelector,
  clHouse => clHouse.cleaned,
);

export const selectHouseId = createSelector(
  houseEffectsSelector,
  houseEff => houseEff.data.id,
);


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner, FormError, JourneyWorlds } from 'components';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as paths from 'config/paths';
import { isEmpty } from 'lodash';
import { StyledFlexBox } from '../../../styles/main';

class SelectWorldsPage extends Component {
  componentDidMount() {
    if (isEmpty(this.props.worlds)) {
      this.props.getWorlds();
    }
  }

  render() {
    const {
      worlds,
      worldsLoading,
      worldsError,
      selectedWorld,
      selectWorld,
    } = this.props;
    return (
      <div>
        {worldsLoading ? <Spinner noFixed /> : null}
        {worldsError.error ? <FormError error={worldsError} /> : null}
        <JourneyWorlds
          worlds={worlds}
          selectedWorld={selectedWorld}
          selectWorld={selectWorld}
        />
        <StyledFlexBox>
          <Button
            tag={Link}
            size="large"
            className="justify-content-center mt-3 mb-3"
            to={{ pathname: paths.NEW_JOURNEY, search: '?step=3' }}
          >
            Next
          </Button>
        </StyledFlexBox>
      </div>
    );
  }
}

SelectWorldsPage.propTypes = {
  getWorlds: PropTypes.func.isRequired,
  selectWorld: PropTypes.func.isRequired,
  userLoading: PropTypes.bool,
  worlds: PropTypes.array.isRequired,
  worldsLoading: PropTypes.bool.isRequired,
  worldsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  userIsReady: PropTypes.bool.isRequired,
  selectedWorld: PropTypes.object,
};

SelectWorldsPage.defaultProps = {
  userLoading: false,
  selectedJourneyHedgie: null,
  selectedWorld: null,
};
export default SelectWorldsPage;

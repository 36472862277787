import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Spinner } from 'components';
import StonesList from '../../pages/SelectGoalPage/StonesList';

const formatItems = items => {
  if (isEmpty(items)) return null;
  return items.map(({ name, imgURL, id }) => ({
    id,
    url: imgURL,
    mobileUrl: imgURL,
    label: name,
  }));
};

class JourneyWorldItems extends Component {
  render() {
    const { items, loading } = this.props;
    return (
      <div>
        {loading ? <Spinner noFixed /> : null}
        <StonesList stones={formatItems(items)} loading={loading} />
      </div>
    );
  }
}

JourneyWorldItems.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
};

JourneyWorldItems.defaultProps = {
  items: [],
  loading: false,
};

export default JourneyWorldItems;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SubHeading, Typography } from 'components';
import { Link } from 'react-router-dom';

const TierBlockContainer = styled.img`
  color: #ffffff;
  border-radius: 30%;
  padding: 10px 10px;
  width: 180px;
  height: 180px;
`;

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

const MyBusinessBlock = ({ business, isCenterContent }) => {
  return (
    <StyledBlock className={isCenterContent ? 'align-center' : ''}>
      <Link to={`/business/${business.id}`}>
        <TierBlockContainer
          src={business.imageURL}
          className="tier-block d-flex text-center justify-content-center flex-column m-auto"
        />
        <div className="align-center">
          <SubHeading heading={business.name} />
        </div>
      </Link>
      <Typography
        className="pt-2"
        align="center"
        text={business.description}
        fontSize={14}
      />
    </StyledBlock>
  );
};

MyBusinessBlock.propTypes = {
  business: PropTypes.object.isRequired,
  isCenterContent: PropTypes.bool,
};

MyBusinessBlock.defaultProps = {
  isCenterContent: true,
};

export default MyBusinessBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { JourneyWorld } from 'components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: start;
`;

export const SQUARE_RATIO = 1;

const JourneyWorldsGrid = React.memo(
  ({ worlds, selectedWorld, selectWorld }) => {
    return (
      <Grid>
        {worlds.map(world => (
          <JourneyWorld
            active={selectedWorld && selectedWorld.id === world.id}
            world={world}
            key={world.id}
            ratio={SQUARE_RATIO}
            onClick={selectWorld}
          />
        ))}
      </Grid>
    );
  },
);

JourneyWorldsGrid.propTypes = {
  worlds: PropTypes.array.isRequired,
  selectedWorld: PropTypes.object,
  selectWorld: PropTypes.func,
};

JourneyWorldsGrid.defaultProps = {
  selectedWorld: null,
  selectWorld: null,
};

export default JourneyWorldsGrid;

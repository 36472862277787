import React from 'react';
import PropTypes from 'prop-types';
import {  animated } from 'react-spring';
import {Transition} from 'react-spring/renderprops'
import styled from 'styled-components';
import withResponsive from '../../../helper/withResponsive';

const StyledCloseButton = styled.span`
  //position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //right: 10px;
  //top: 5px;
  span {
    cursor: pointer;
    font-weight: 700;
    display: inline-block;
    font-size: ${props => `${props.fontSize}px`};
    color: #62295e;
    line-height: ${props => `${props.fontSize}px`};
  }
`;

const StyledChildren = styled.div`
  position: relative;
  padding: 10px;
`;

const TransitionCom = ({
  children,
  toggle,
  onClose,
  customStyles,
  isMobile,
}) => {
  return (
    <Transition
      native
      config={{ tension: 2000, friction: 100, precision: 1 }}
      unique
      items={toggle}
      from={{ overflow: 'hidden', height: 0, ...customStyles }}
      enter={[{ height: 'auto' }]}
      leave={{ height: 0 }}
    >
      {show =>
        show &&
        (props => (
          <animated.div style={props}>
            <StyledChildren>
              <StyledCloseButton fontSize={isMobile ? 18 : 25}>
                <span onClick={onClose}>x</span>
              </StyledCloseButton>
              <div>{children}</div>
            </StyledChildren>
          </animated.div>
        ))
      }
    </Transition>
  );
};

TransitionCom.propTypes = {
  children: PropTypes.element.isRequired,
  toggle: PropTypes.bool,
  onClose: PropTypes.func,
  customStyles: PropTypes.object,
  closeBtnSize: PropTypes.number,
  isMobile: PropTypes.bool,
};

TransitionCom.defaultProps = {
  toggle: false,
  onClose: null,
  closeBtnSize: 25,
  customStyles: {},
  isMobile: false,
};

export default withResponsive(TransitionCom);

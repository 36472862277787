const STATUS_AVAILABLE = 0;
const STATUS_PENDING = 1;
const STATUS_PURCHASED = 2;
const STATUS_RESERVED = 3;

const isPurchased = hedgie => {
    if (!hedgie) {
        return false;
    }
    return hedgie.status === STATUS_PURCHASED;
};

const isReserved = hedgie => {
    if (!hedgie) {
        return false;
    }
    return hedgie.status === STATUS_RESERVED;
};

const isAvailable = hedgie => {
    if (!hedgie) {
        return false;
    }
    return hedgie.status === STATUS_AVAILABLE;
};

const isPending = hedgie => {
    if (!hedgie) {
        return false;
    }
    return hedgie.status === STATUS_PENDING;
};

export default {
    isAvailable,
    isPending,
    isReserved,
    isPurchased,
    isSold: isPurchased,
};

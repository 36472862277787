import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

// import Log from 'services/log';
import * as types from 'types';
import { SubHeading, Spinner, ContentContainer } from 'components';
import * as tierImages from './tierImages';

const TierImg = styled.img`
  max-width: 65px;
`;

const TierBlockContainer = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 10px 30px;
  width: 240px;
  height: 240px;
`;

const tierImgSource = tier => {
  switch (tier.tier) {
    case 2:
      return tierImages.Image2;
    case 3:
      return tierImages.Image3;
    case 4:
      return tierImages.Image4;
    case 5:
      return tierImages.Image5;
    case 6:
      return tierImages.Image6;
    case 7:
      return tierImages.Image7;
    default:
      return tierImages.Image2;
  }
};

const TierBlock = ({ tier }) => {
  return (
    <TierBlockContainer className="tier-block d-flex text-center justify-content-center flex-column m-auto">
      <TierImg
        src={tierImgSource(tier)}
        alt={tier.tierName}
        className="m-auto"
      />
      <SubHeading heading={`Tier ${tier.tier}: ${tier.tierName}`} />
      <p style={{ fontSize: '0.8rem' }}>{tier.desc}</p>
      <p style={{ fontSize: '0.8rem' }}>Total: {tier.total}</p>
    </TierBlockContainer>
  );
};

TierBlock.propTypes = {
  tier: PropTypes.object.isRequired,
};

const TierCell = ({ tier, size }) => {
  if (!tier) {
    return null;
  }
  return (
    <Col md={12 / size} className="pt-5 pt-md-0">
      <TierBlock tier={tier} />
    </Col>
  );
};

TierCell.propTypes = {
  tier: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
};

const TierRow = ({ tiers }) => {
  return (
    <Row className="d-flex text-center justify-content-center align-items-start tier-row mb-0 mb-md-5">
      {tiers.map(tier => (
        <TierCell key={tier.tier} tier={tier} size={tiers.length} />
      ))}
    </Row>
  );
};

TierRow.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const renderTiers = tiers => {
  const colCount = 3;
  const rowCount = Math.ceil(tiers.length / colCount);
  const rows = Array.from(Array(rowCount).keys());
  const cols = Array.from(Array(colCount).keys());
  return rows.map(val => {
    const index = val * colCount;
    const rowTiers = cols.map(val => tiers[index + val]);
    return <TierRow key={val} tiers={rowTiers} />;
  });
};

const renderTierList = (tiers, loading) => {
  let TierList;
  if (loading) {
    TierList = (
      <div className="pos-rel m-5 p-5">
        <Spinner />
      </div>
    );
  } else {
    TierList = renderTiers(tiers);
  }
  return TierList;
};

const ScarceAndCool = props => {
  const { tiers, loading } = props;
  const className = `scarceAndCool ${props.className}`;

  const tierList = renderTierList(tiers, loading);
  return (
    <div className={className}>
      <Row className="">
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SubHeading
            className="text-center"
            heading="Which will you choose? Hint: They’re all amazing!"
          />
        </Col>
      </Row>
      <ContentContainer>
        {tierList}
        <Row className="">
          <Col sm="12">&nbsp;</Col>
        </Row>
      </ContentContainer>
    </div>
  );
};

ScarceAndCool.propTypes = {
  className: PropTypes.string,
  error: types.error,
  tiers: PropTypes.arrayOf(types.tier),
  loading: PropTypes.bool,
};

ScarceAndCool.defaultProps = {
  className: '',
  error: null,
  premiumHedgies: [],
  loading: false,
  tiers: [],
};

export default ScarceAndCool;

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components';
import styled from 'styled-components';
import Info from './Info';

const StyledContainer = styled.div`
  display: grid;
  max-width: ${props => (props.isFixedWidth ? '1140px' : '100%')};
  margin: 0 auto;
  align-items: ${props => (props.largePhotoRatio !== 1 ? 'start' : 'center')};
  padding: ${props => (props.largePhotoRatio !== 1 ? 0 : '0 20px')};
  grid-template-columns: ${props =>
    props.largePhotoRatio !== 1
      ? '45% 1fr'
      : 'repeat(auto-fit, minmax(350px, 1fr))'};
  grid-gap: ${props => (props.largePhotoRatio !== 1 ? 0 : 50)}px;
  justify-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledItemInfo = styled.div`
  margin-top: 50px;
`;

const StyledImgWrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  width: ${props => (props.keepOriginalSize ? null : '100%')};
  height: ${props => (props.keepOriginalSize ? null : 'auto')};
`;

const StyledPhotoRatio = styled.div`
  background-image: url(${props => props.imgUrl});
  &:before {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
  width: 100%;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const StyledItemList = styled.div`
  max-width: 430px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(${props => props.limitedItem}, 1fr);
  grid-gap: 20px;
  justify-content: center;
`;

const StyledShadowCircle = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  cursor: pointer;

  &:hover {
    .pw-label {
      color: #710157;
    }
  }
`;

const StyledItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledItemLabel = styled.span`
  font-weight: ${props => (props.isLarge ? 800 : 600)};
  padding-top: ${props => (props.isLarge ? 38 : 10)}px;
  font-size: ${props => (props.isLarge ? 22 : 18)}px;
  color: ${props => (props.isLarge ? '#710157' : '#000000')};
  text-align: center;
  line-height: 22px;
`;

const StyledImgCircle = styled.div`
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.26);
`;

const Item = memo(
  ({ item, onClick, isCircle, smallCircleImgSize, isCoverSmallPhoto }) => {
    const clickCallback = useCallback(() => {
      onClick(item);
    }, [item, onClick]);

    function renderChild() {
      if (isCoverSmallPhoto && isCircle) {
        return (
          <StyledImgCircle
            size={smallCircleImgSize}
            imgUrl={item.imgUrl}
            isCover={isCoverSmallPhoto}
          />
        );
      } else if (isCircle) {
        return (
          <StyledShadowCircle width={90} height={90}>
            <StyledImg
              src={item.imgUrl}
              alt={item.name}
              style={{ width: 'auto', height: 50 }}
            />
          </StyledShadowCircle>
        );
      }
      return (
        <StyledImgWrapper width="45px" height="45px">
          <StyledImg src={item.imgUrl} alt={item.name} />
        </StyledImgWrapper>
      );
    }

    return (
      <StyledItem onClick={clickCallback}>
        {renderChild()}
        <StyledItemLabel isLarge={false} className="pw-label">
          {item.label}
        </StyledItemLabel>
      </StyledItem>
    );
  },
);

Item.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isCircle: PropTypes.bool,
  smallCircleImgSize: PropTypes.number,
  isCoverSmallPhoto: PropTypes.bool,
};

Item.defaultProps = {
  onClick: null,
  isCircle: false,
  isCoverSmallPhoto: true,
  smallCircleImgSize: 90,
};

const ItemsList = memo(
  ({
    data,
    selectItem,
    clickItemGuideline,
    isCircle,
    smallCircleImgSize,
    isCoverSmallPhoto,
    limitedItem,
  }) => {
    return (
      <div className="pt-3">
        <Typography text={clickItemGuideline} className="mt-2" align="center" />
        <StyledItemList limitedItem={limitedItem}>
          {data.map(item => (
            <Item
              item={item}
              key={item.name}
              onClick={selectItem}
              isCoverSmallPhoto={isCoverSmallPhoto}
              isCircle={isCircle}
              smallCircleImgSize={smallCircleImgSize}
            />
          ))}
        </StyledItemList>
      </div>
    );
  },
);

ItemsList.propTypes = {
  data: PropTypes.array.isRequired,
  selectItem: PropTypes.func.isRequired,
  clickItemGuideline: PropTypes.string.isRequired,
  isCircle: PropTypes.bool,
  smallCircleImgSize: PropTypes.number,
  limitedItem: PropTypes.number,
  isCoverSmallPhoto: PropTypes.bool,
};

ItemsList.defaultProps = {
  isCircle: false,
  smallCircleImgSize: 90,
  isCoverSmallPhoto: true,
  limitedItem: 4,
};

const ItemDetail = memo(
  ({
    item,
    isCircleLargePhoto,
    hideTitleLargePhoto,
    largePhotoRatio,
    widthLargePhoto,
    heightLargePhoto,
  }) => {
    return (
      <StyledItemDetail>
        {isCircleLargePhoto ? (
          <StyledShadowCircle width={276} height={276}>
            <StyledImgWrapper width={widthLargePhoto} height={heightLargePhoto}>
              <StyledImg
                src={item.fullImgUrl}
                alt={item.name}
                keepOriginalSize
              />
            </StyledImgWrapper>
          </StyledShadowCircle>
        ) : (
          <StyledImgWrapper width={widthLargePhoto} height={heightLargePhoto}>
            {largePhotoRatio !== 1 ? (
              <StyledPhotoRatio
                imgUrl={item.fullImgUrl}
                ratio={largePhotoRatio}
              />
            ) : (
              <StyledImg
                src={item.fullImgUrl}
                alt={item.name}
                style={{
                  height: item.name === 'curio' ? 400 : null,
                }}
                keepOriginalSize={item.name !== 'curio'}
              />
            )}
          </StyledImgWrapper>
        )}
        {hideTitleLargePhoto ? null : (
          <StyledItemLabel isLarge className="pw-label">
            {item.label}
          </StyledItemLabel>
        )}
        <Typography
          style={{ maxWidth: 400 }}
          text={item.description}
          className="pt-2"
        />
      </StyledItemDetail>
    );
  },
);

ItemDetail.propTypes = {
  item: PropTypes.object.isRequired,
  isCircleLargePhoto: PropTypes.bool,
  hideTitleLargePhoto: PropTypes.bool,
  isFixedWidthLargePhoto: PropTypes.bool,
  largePhotoRatio: PropTypes.number,
  widthLargePhoto: PropTypes.string.isRequired,
  heightLargePhoto: PropTypes.string.isRequired,
};

ItemDetail.defaultProps = {
  isCircleLargePhoto: false,
  hideTitleLargePhoto: false,
  isFixedWidthLargePhoto: false,
  largePhotoRatio: 1,
};

const ClickableSlide = ({
  data,
  clickItemGuideline,
  introTitle,
  introText,
  isCircleSmallImg,
  smallCircleImgSize,
  isCircleLargePhoto,
  hideTitleLargePhoto,
  isFixedWidth,
  isFixedWidthLargePhoto,
  largePhotoRatio,
  isCoverSmallPhoto,
  widthLargePhoto,
  heightLargePhoto,
  limitedItem,
}) => {
  const [item, setItem] = useState(data[0]);

  function handleSelectItem(selectedItem) {
    setItem(selectedItem);
  }

  return (
    <StyledContainer
      isFixedWidth={isFixedWidth}
      largePhotoRatio={largePhotoRatio}
    >
      <div>
        <ItemsList
          data={data}
          limitedItem={limitedItem}
          selectItem={handleSelectItem}
          clickItemGuideline={clickItemGuideline}
          isCircle={isCircleSmallImg}
          isCoverSmallPhoto={isCoverSmallPhoto}
          smallCircleImgSize={smallCircleImgSize}
        />
        <StyledItemInfo>
          <Info text={introText} title={introTitle} textColor="#000" />
        </StyledItemInfo>
      </div>
      <ItemDetail
        item={item}
        isCircleLargePhoto={isCircleLargePhoto}
        hideTitleLargePhoto={hideTitleLargePhoto}
        isFixedWidthLargePhoto={isFixedWidthLargePhoto}
        largePhotoRatio={largePhotoRatio}
        widthLargePhoto={widthLargePhoto}
        heightLargePhoto={heightLargePhoto}
      />
    </StyledContainer>
  );
};

ClickableSlide.propTypes = {
  data: PropTypes.array.isRequired,
  clickItemGuideline: PropTypes.string,
  introText: PropTypes.string,
  introTitle: PropTypes.string,
  isCircleSmallImg: PropTypes.bool,
  smallCircleImgSize: PropTypes.number,
  isCircleLargePhoto: PropTypes.bool,
  hideTitleLargePhoto: PropTypes.bool,
  isFixedWidth: PropTypes.bool,
  isFixedWidthLargePhoto: PropTypes.bool,
  isCoverSmallPhoto: PropTypes.bool,
  largePhotoRatio: PropTypes.number,
  widthLargePhoto: PropTypes.string,
  heightLargePhoto: PropTypes.string,
  limitedItem: PropTypes.number,
};

ClickableSlide.defaultProps = {
  introTitle: '',
  introText: '',
  clickItemGuideline: '',
  isCircleSmallImg: false,
  smallCircleImgSize: 90,
  isCircleLargePhoto: false,
  hideTitleLargePhoto: false,
  isFixedWidth: false,
  isFixedWidthLargePhoto: false,
  isCoverSmallPhoto: true,
  largePhotoRatio: 1,
  widthLargePhoto: '100%',
  heightLargePhoto: 'auto',
  limitedItem: 4,
};

export default ClickableSlide;

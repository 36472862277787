import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import topSilde from '../images/sky-red.jpg';
import EndSlide from './EndSlide';

const EndingSlide = ({ isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer
        offset={7}
        speed={0}
        style={{
          backgroundImage: `url(${topSilde})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <ParallaxLayer offset={7.1} speed={0.5}>
        <EndSlide isMobile={isMobile} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

EndingSlide.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default EndingSlide;

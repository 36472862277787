import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ItemImage, Button, Typography, Spinner, FormError } from 'components';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';
import useCheckingUpgradable from './useCheckingUpgradable';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .furniture-btn {
    margin-left: 0;
  }
`;

const StyledResources = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 10px));
  grid-gap: 10px;
  justify-content: center;
  justify-items: center;
`;

export const StyledIncrementalEnergy = styled.span`
  color: #35bd51;
  padding-left: 5px;
  font-weight: 600;
`;

export function getIncrementalEnergy(currentEnergy, upgradedEnergy) {
  const rs = upgradedEnergy - currentEnergy;
  return (rs.toFixed(3) * 1000) / 1000;
}

const FurnitureItem = memo(
  ({
    houseId,
    isMobile,
    buildFurniture,
    furniture,
    maxWidth,
    selectFurniture,
    loading,
    error,
  }) => {
    const selectFurnitureCallback = useCallback(
      () => {
        selectFurniture(furniture, houseId);
      },
      [furniture, houseId, selectFurniture],
    );

    const clickCallback = useCallback(
      () => {
        buildFurniture(furniture, houseId);
      },
      [buildFurniture, furniture, houseId],
    );

    const { requiredResources, hasEnoughResources } = useCheckingUpgradable(
      furniture,
    );

    const { currentEffectValue, effectValue } = furniture;

    const incrementalEnergy = useMemo(
      () => getIncrementalEnergy(currentEffectValue, effectValue),
      [currentEffectValue, effectValue],
    );

    return (
      <StyledContainer>
        <ItemImage
          onClick={isMobile ? selectFurnitureCallback : null}
          key="FurnitureItem-large"
          width={isMobile ? null : maxWidth}
          insideCircle
          item={{ imgURL: furniture.imgURL }}
        />
        <Typography
          text={furniture.name}
          align="center"
          isBold
          className="pt-3"
          fontSize={16}
        />
        {isMobile ? null : (
          <React.Fragment>
            <Typography
              text={`Level ${furniture.level}`}
              align="center"
              className="mb-0 pt-1"
              fontSize={14}
            />
            <Typography
              text={`Energy Recovery: ${furniture.effectValue}`}
              align="center"
              className="mb-0 pt-1"
              fontSize={14}
            >
              <StyledIncrementalEnergy>
                {`(+${incrementalEnergy})`}
              </StyledIncrementalEnergy>
            </Typography>
          </React.Fragment>
        )}
        {isMobile ? null : (
          <StyledResources className="pt-3 pb-3">
            {requiredResources.map(rs => (
              <ItemImage
                key={rs.name}
                width={50}
                item={rs}
                label={`x${rs.amount}`}
              />
            ))}
          </StyledResources>
        )}
        {isMobile ? null : (
          <div>
            {!hasEnoughResources ? (
              <Typography
                fontSize={14}
                isBold
                text="You don’t have enough Resources to build this"
              />
            ) : (
              <Button onClick={clickCallback} className="furniture-btn">
                Upgrade
              </Button>
            )}
          </div>
        )}
        {isMobile ? null : (
          <div>
            {loading ? <Spinner noFixed /> : null}
            {error && error.error ? (
              <div className="align-center">
                <FormError error={error} />
              </div>
            ) : null}
          </div>
        )}
      </StyledContainer>
    );
  },
);

FurnitureItem.propTypes = {
  houseId: PropTypes.string,
  isMobile: PropTypes.bool,
  buildFurniture: PropTypes.func,
  furniture: PropTypes.object,
  maxWidth: PropTypes.number,
  selectFurniture: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
};

FurnitureItem.defaultProps = {
  furniture: null,
  houseId: '',
  isMobile: false,
  buildFurniture: null,
  maxWidth: 161,
  selectFurniture: null,
  loading: false,
  error: {
    error: false,
    message: '',
  },
};

export default withResponsive(FurnitureItem);

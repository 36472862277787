import { createSelector } from 'reselect';

const announcementSelector = state => state.announcement;

export const selectAllAnnouncements = createSelector(
  announcementSelector,
  announcementState => announcementState.announcements,
);

export const selectUnreadAnnouncements = createSelector(
  announcementSelector,
  announcementState => announcementState.unreadAnnouncements,
);
export const selectUnreadAnnouncementsLoading = createSelector(
  announcementSelector,
  announcementState => announcementState.unreadAnnouncementsLoading,
);
export const selectUnreadAnnouncementsError = createSelector(
  announcementSelector,
  announcementState => announcementState.unreadAnnouncementsError,
);

export const selectMarkedAnnouncement = createSelector(
  announcementSelector,
  announcementState => announcementState.markedAnnouncementAsRead,
);

export const selectMarkAnnouncementAsReadLoading = createSelector(
  announcementSelector,
  announcementState => announcementState.markAnnouncementAsReadLoading,
);

export const selectMarkAnnouncementAsReadLoadingError = createSelector(
  announcementSelector,
  announcementState => announcementState.markAnnouncementAsReadLoadingError,
);

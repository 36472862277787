import React from 'react';
import { PageTitle, SubHeadingBold } from 'components';
import { Link } from 'react-router-dom';
import Redirect from 'config/redirect';
import { StyledOpenNewTab } from 'styles/main';

const PrivacyPage = () => {
  return (
    <div>
      <main>
        <Redirect />
        <div className="container-fluid row-section pos-rel overflow-hidden-reg">
          <div className="container">
            <div className="jumbotron ">
              <PageTitle title="HEDGIE - PRIVACY POLICY" />
              <div>Last Updated On: 11/08/2018</div>
              <div className="row justify-content-end  bgr-bottom-images pt-5">
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Introduction" />
                  <p className="ff-medium mt-4 mb-3">
                    Welcome to the Hedgie website located at{' '}
                    <Link to="/">https://www.hedgie.io</Link> Protecting your
                    personal information and respecting your privacy is very
                    important to Hedgie Inc.
                  </p>
                  <p className="ff-medium mt-4 mb-3">
                    Hedgie Inc. (<strong>“We”, “Us”</strong> and/or{' '}
                    <strong>“Our”</strong>
                    ), owns and operates the{' '}
                    <Link to="/">https://www.hedgie.io </Link> website (the
                    “Site”). We have adopted this Privacy Policy to inform users
                    of the Site how we collect, store and use Personal
                    Information submitted by users. We have developed this
                    policy to comply and be consistent with the Personal
                    Information Protection and Electronic Documents Act (
                    <strong>“PIPEDA”</strong>
                    ), and other relevant privacy laws.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Consent" />
                  <p className="ff-medium mt-4">
                    BY USING THE SITE, YOU ARE CONSENTING TO OUR COLLECTION,
                    USE, TRANSFER AND STORAGE IN ACCORDANCE WITH THIS PRIVACY
                    POLICY OF ANY PERSONAL INFORMATION OR OTHER INFORMATION
                    RECEIVED BY US A RESULT OF SUCH USE.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Minors" />
                  <p className="ff-medium mt-4">
                    Consistent with our Terms of Use, minors must have parental
                    consent to use the service/register and provide us with your
                    personal information. We reserve the right to request proof
                    of parental consent. Please do not send any personal
                    information about yourself to us without prior consent from
                    your parent or guardian, and please do not send any personal
                    information other than what we request from you in
                    connection with this Site.
                  </p>
                  <p className="ff-medium mt-4">
                    If we learn we have collected personal information from a
                    minor without parental consent being obtained, or if we
                    learn a minor has provided us personal information beyond
                    what we request from him or her, we will delete that
                    information as quickly as possible.
                  </p>
                  <p className="ff-medium mt-4">
                    If you are under 13 years of age, pursuant to our Terms of
                    Use, you are restricted from using this Site or sending us
                    any information whatsoever. If we learn we have collected
                    personal information from a child under 13 years of age,
                    then we will delete that information as quickly as possible.
                    If you believe that a user under 13 years of age may have
                    provided us with personal information in violation of this
                    section, please contact us via email:
                    <StyledOpenNewTab
                      href="mailto:hedgie@hedgie.io"
                      target="_blank"
                    >
                      hedgie@hedgie.io
                    </StyledOpenNewTab>
                    .
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Information We Collect" />
                  <h4 className="ff-semi-bold">
                    a. Personal Information; EMAIL, METAMASK LOGIN
                  </h4>
                  <p className="ff-medium mt-4">
                    Personal information is any information recorded in any form
                    that identifies or can identify you as an individual and
                    includes any information provided to us by you in using the
                    Site and services (“Personal Information”). This may include
                    certain registration information and user profile
                    information that you provide to us in creating your account
                    to use the Site and services, such as your name, email
                    address, current IP address and / or geographical location,
                    and public key of your Metamask wallet.
                  </p>
                  <p className="ff-medium mt-4">
                    To use the Hedgie Site, you must first create an account by
                    providing your email and password, and agreeing to terms of
                    use. You can then interact with all website functionality
                    with the exception of syncing your purchased Hedgie(s) to
                    Blockchain, or purchasing a Hedgie with ETH crypto currency.
                    In order to purchase a Hedgie with ETH, and/or sync your
                    purchased Hedgie(s) with Ethereum Blockchain, you first must
                    install the Google Chrome web browser. Once you have
                    installed Chrome, you will need to install a browser
                    extension called MetaMask. At that point your site account
                    will be connected with your MetaMask, and will unlock full
                    Blockchain-related features of the website.
                  </p>
                  <p className="ff-medium mt-4">
                    This Privacy Policy applies to information collected by
                    Hedgie through your login to the Site and connecting your
                    Metamask, however, your sharing of any content from the Site
                    through any third party social media websites or services,
                    is covered by the privacy policies and terms of use of those
                    websites or services, as applicable.
                  </p>
                  <h4 className="ff-semi-bold">
                    b. Non-Personally Identifiable Information
                  </h4>
                  <p className="ff-medium mt-4">
                    Personal Information does not include information that is
                    aggregated in such a manner that it cannot be connected to
                    you (
                    <strong>“Non-Personally Identifiable Information”</strong>
                    ). The Site uses certain technologies that are designed to
                    monitor the performance of the Site and to enhance the
                    usability of our Site for each user. Each time you visit the
                    Site, we may collect information specifically related to
                    that session. For example, we may collect information such
                    as the length of time you visited the site, the pages you
                    visited, the type of browser used to access our site as well
                    as to track the number of visitors to the site. None of the
                    foregoing by itself or together can be tracked to a specific
                    individual's personally identifiable information.
                  </p>
                  <p className="ff-medium mt-4">
                    Other information passively collected by us is in the form
                    of logs - files that record Site activity and gather
                    statistics about web users' browsing habits. These entries
                    are generated anonymously, and help us determine (among
                    other things) how many and how often users have visited our
                    Site, which pages they have visited, and other similar data.
                    We may use the log file entries for our internal traffic and
                    demographic studies, so we can constantly improve the online
                    services we provide you. Log files are used internally only,
                    and are not associated with any particular user, computer,
                    or browser.
                  </p>
                  <p className="ff-medium mt-4">
                    Our Site webpages contain electronic images known as Web
                    beacons (sometimes also called pixels, pixel tags, web
                    beacons, or web bugs) and are used along with cookies to
                    compile aggregated statistics to analyze how our site is
                    used and may be used in some of our emails to let us know
                    which emails and links have been opened by recipients. This
                    allows us to gauge the effectiveness of our communications
                    and promotional campaigns. We do not combine information
                    gathered through clear GIFs with your personal information.
                  </p>
                  <p className="ff-medium mt-4">
                    We use Google Analytics which uses cookies to track
                    non-personally identifiable information about traffic and
                    visitors to our Site in the aggregate to compile statistical
                    reports about the activity on the Site as well as to
                    benchmark this Site to other third parties who also use
                    Google Analytics. This information is used by us to improve
                    the Site and its functionality.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Why do We collect, use and disclose your Personal Information?" />
                  <p className="ff-medium mt-4">
                    We collect Personal Information for the following purposes:
                  </p>
                  <ul>
                    <li>
                      to provide services through our Site and to our users;
                    </li>
                    <li>
                      to establish, administer and maintain responsible
                      commercial relations and to communicate with our users in
                      order to provide service.
                    </li>
                    <li>
                      to lenders, financers, investors, collection agents,
                      counterparties to agreements and others having or
                      potentially engaging in business relations with us;
                    </li>
                    <li>
                      to respond to any correspondence you may direct to us;
                    </li>
                    <li>to understand our users’ needs and preferences;</li>
                    <li>
                      to direct advertisements to you about goods and services
                      of interest to you;
                    </li>
                    <li>to establish your identification;</li>
                    <li>to verify some of the information You give Us;</li>
                    <li>to manage and administer accounts;</li>
                    <li>to protect you and us from error and fraud;</li>
                    <li>
                      to meet the requirements of applicable law, governmental
                      or regulatory authority or other law enforcement purposes;
                      and
                    </li>
                    <li>
                      for any other reasonable purposes for which You may have
                      provided your express consent or in which your consent can
                      be reasonably implied.
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="How do We use Non-Personally Identifiable Information?" />
                  <p className="ff-medium mt-4">
                    The Non-Personally Identifiable Information collected
                    through your use of the Site may be used to provide relevant
                    content; monitor website traffic; collect statistical data;
                    determine the effectiveness of advertisements and content;
                    and collect data across multiple websites to categorize
                    likely consumer interest segments for use in improving Site
                    user experience.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Consent" />
                  <p className="ff-medium mt-4">
                    We respect your privacy and, unless otherwise required by
                    law, we will not collect, use or disclose your Personal
                    Information without your prior consent. Your consent may be
                    expressed or implied. You may expressly give your consent in
                    writing, verbally or through any electronic means. In
                    certain circumstances, your consent may be implied by your
                    actions. For example, providing us Personal Information to
                    register for our services is implied consent to use such
                    information to provide you the associated services.
                  </p>
                  <p className="ff-medium mt-4">
                    Where appropriate, we will generally seek consent for the
                    use or disclosure of the information at the time of
                    collection. In certain circumstances, consent with respect
                    to use or disclosure may be sought after the information has
                    been collected but before use (for example, when we want to
                    use information for a purpose other than those identified
                    above). In obtaining consent, we will use reasonable efforts
                    to ensure that a user is advised of the identified purposes
                    for which
                  </p>
                  <p className="ff-medium">
                    <strong>
                      Personal Information collected will be used or disclosed.
                    </strong>
                  </p>
                  <p className="ff-medium">
                    You may withdraw consent at any time, subject to legal or
                    contractual restrictions and reasonable notice. In order to
                    withdraw consent, you must provide notice to us in writing.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="How and when do we use and disclose your Personal Information?" />
                  <p className="ff-medium mt-4">
                    We may use or disclose your Personal Information:
                  </p>
                  <ul>
                    <li>
                      for the purposes described in this Privacy Policy; or
                    </li>
                    <li>
                      for any additional purposes for which we have obtained
                      your consent to the use or disclosure of your Personal
                      Information.
                    </li>
                  </ul>
                  <p className="ff-medium mt-4">
                    We may use or disclose your personal information without
                    your knowledge or consent where we are permitted or required
                    by applicable law or regulatory body.
                  </p>
                  <p className="ff-medium">In addition:</p>
                  <p className="ff-medium mt-4">
                    Agents, Contractors, Suppliers and Affiliated Companies
                    (“Affiliates”):
                  </p>
                  <ul>
                    <li>
                      We may sometimes engage third party suppliers, contractors
                      and agents and other parties who require such information
                      to assist us with establishing, maintaining and managing
                      our relationship with you. For instance, we use a third
                      party service to help us email you important information:{' '}
                      <StyledOpenNewTab
                        href="https://sendgrid.com"
                        target="_blank"
                      >
                        Send Grid
                      </StyledOpenNewTab>
                      .
                    </li>
                    <li>
                      Such third parties will only use your Personal Information
                      for the purposes identified in this Privacy Policy.
                    </li>
                    <li>
                      We may also share your Personal Information with our
                      subsidiaries and/or affiliates for purposes consistent
                      with this Privacy Policy.
                    </li>
                    <li>
                      Only we and our Affiliates’ employees and/or contractors
                      with a business need to know, or whose duties reasonably
                      so require, are granted access to Personal Information.
                      All such persons will be required as a condition of
                      employment to contractually respect the confidentiality of
                      the Personal Information
                    </li>
                  </ul>
                  <p className="ff-medium mt-4">Business Transactions:</p>
                  <ul>
                    <li>
                      In the event of a corporate sale, merger, reorganization,
                      dissolution or similar event, we may transfer your
                      Personal Information to a successor or acquirer of Hedgie
                      Inc. (or its assets).
                    </li>
                    <li>
                      We may also share your Personal Information to lenders,
                      financers, investors, collection agents, counterparties to
                      agreements and others having or potentially engaging in
                      business relations with us.
                    </li>
                    <li>
                      In the event your Personal Information is disclosed to a
                      third party pursuant to the above paragraphs, we will
                      ensure that the third party has entered into an agreement
                      under which the collection, use and disclosure of the
                      information is related to those purposes that relate to
                      the transaction and in accordance with the terms of this
                      Privacy Policy.
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Retention; Editing Your Personal Information" />
                  <p className="ff-medium">
                    We will retain Personal Information for only as long as
                    required to fulfill the identified purposes or as required
                    by law. Personal information that is no longer required to
                    fulfill the identified purposes will be destroyed, erased or
                    made anonymous according to the guidelines and procedures
                    established by us.
                  </p>
                  <p className="ff-medium">
                    If you wish to request that we edit or no longer use your
                    information, please contact us via email{' '}
                    <StyledOpenNewTab
                      href="mailto:hedgie@hedgie.io"
                      target="_blank"
                    >
                      hedgie@hedgie.io
                    </StyledOpenNewTab>
                    .
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="How can I Access my Personal Information?" />
                  <p className="ff-medium">
                    Upon request, we will provide information to you regarding
                    the existence, use and disclosure of your Personal
                    Information and you shall be given access to that
                    information. You may challenge the accuracy and completeness
                    of the information and request to have it amended as
                    appropriate.
                  </p>
                  <p className="ff-medium">
                    <strong>NOTE:</strong> In certain circumstances, we may not
                    be able to provide access to all of your Personal
                    Information. Exceptions may include information that is
                    prohibitively costly to provide, information that contains
                    references to other individuals, information that cannot be
                    disclosed for legal, security or commercial proprietary
                    reasons, or information that is subject to solicitor-client
                    or litigation privilege. We will provide the reasons for
                    denying access upon request.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Data May Be Stored in USA" />
                  <p className="ff-medium">
                    Currently personal information you submit is stored only in
                    Canada. However, it may be transferred to, processed on, and
                    stored on, our servers, which may be located in the USA. By
                    submitting your personal information, you agree that as a
                    result, US governments, courts, law enforcement or
                    regulatory agencies may be able to obtain disclosure of your
                    information in compliance with USA laws, regulations or
                    court orders.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Safeguards" />
                  <p className="ff-medium">
                    We have taken steps to assure that all information collected
                    will remain secure and in its original form, i.e., free from
                    any alteration. As such, access to all Personal Information
                    is strictly controlled. We have put in place appropriate
                    physical, electronic, and managerial procedures in an effort
                    to safeguard and help prevent unauthorized access, maintain
                    data security, and correctly use the information we collect
                    online.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Release" />
                  <p className="ff-medium">
                    You acknowledge that we cannot ensure the security or
                    privacy of information and/or content you provide through
                    the Internet, and you release us from any and all liability
                    in connection with the use of such content by other parties,
                    including, without limitation, the use or disclosure of any
                    information by or to third parties by credit reporting
                    agencies or credit bureaus.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Third Party Links" />
                  <p className="ff-medium">
                    Third party products or services included or offered via our
                    Site have separate and independent privacy policies. We
                    therefore have no responsibility or liability for the
                    content or activities of these linked sites.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="How to Contact Us; PIPEDA" />
                  <p className="ff-medium">
                    In compliance with Canadian law, if you have any questions,
                    comments or complaints about how we use your information,
                    please contact Our Privacy Officer.
                  </p>
                  <p className="ff-medium">Contact: Privacy Officer</p>
                  <p className="ff-medium">
                    Via mail: Hedgie Inc., Attention: Privacy Officer, 813 – 66
                    Broadway Ave., Toronto, ON, M4P 1T6
                  </p>
                  <p className="ff-medium">Email: hedgie@hedgie.io</p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Anti-Spam Policy" />
                  <p className="ff-medium">
                    We maintain a strict no-spam policy. If you receive
                    unsolicited email from us, our vendors, or agents, please
                    notify us via email hedgie@hedgie.io. Include the offending
                    email with all the headers intact. Please be aware that it
                    is not uncommon for spammers to hide behind someone else's
                    email account, as well as use mail servers that they do not
                    own.
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <SubHeadingBold heading="Changes to this Privacy Policy" />
                  <p className="ff-medium">
                    If we decide to change our Privacy Policy, we will post
                    those changes on the Site, and other places we deem
                    appropriate so that you are aware of what information we
                    collect, how we use it, and under what circumstances, if
                    any, we disclose it.
                  </p>
                  <p className="ff-medium">
                    We reserve the right to modify this Privacy Policy at any
                    time, so please review it frequently. If we make material
                    changes to this Privacy Policy, we will notify you here, by
                    email, or by means of a notice on the Site (e.g. on the home
                    page).
                  </p>
                  <p className="ff-medium">
                    All such changes are effective as of the date of posting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
PrivacyPage.propTypes = {};
PrivacyPage.defaultProps = {};
export default PrivacyPage;

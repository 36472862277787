import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SwitchButton = ({ label, checked, handleChange, ...props }) => {
  return (
    <StyledContainer>
      <span className="pr-3">{label}</span>
      <Switch
        onChange={handleChange}
        checked={checked}
        onColor="#12DFC0"
        className="react-switch"
        {...props}
      />
    </StyledContainer>
  );
};

SwitchButton.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
};

SwitchButton.defaultProps = {
  className: '',
  label: 'Switch button',
  handleChange: null,
  checked: false,
};

export default React.memo(SwitchButton);

export const ABOUT_PAGE = '/about-game';
// export const APPROVED_BUSINESSES = '/businesses';
export const BOOKING_HEDGIE = '/booking';
export const BOOKING = `${BOOKING_HEDGIE}/:hex`;
export const BOOKING_THANK_YOU = '/booking-thankyou';
export const BUSINESSES = '/partner-with-hedgie';
export const CHOOSE_PREMIUM_HEDGIES = '/premium-hedgies#choose-premium';
export const FAQ = '/faq';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_THANK_YOU = '/forgotpassword-thankyou';
export const GET_HEDGIE = '/get-hedgie';
export const GIFT_CODE = '/gift';
export const GIFT_CODE_THANK_YOU = '/giftcode-thankyou';
export const HOME = '/';
export const RUSSIAN_LANDING_PAGE = '/ru';
export const MY_HEDGIES = '/my-hedgies';
export const OUR_TEAM_PAGE = '/our-team';
export const PARTNERS = '/partners';
export const POST_URL = '/giftcodes/purchases';
export const PREMIUM_HEDGIES = '/exclusive-hedgies';
export const PRIVACY_PAGE = '/privacy';
export const RESET_PASSWORD = '/reset-password';
export const ROADMAP = '/roadmap';
export const SETTINGS = '/settings';
export const SIGNIN = '/signin';
export const SIGNOUT = '/signout';
export const SIGNUP = '/signup';
export const SPEND_CURIO = '/spend-curio';
export const SPEND_CURIO_SCROLL_TO_APPROVED_BUSINESS =
  '/spend-curio#hedgie-approved-businesses';
export const SUPPORT_EMAIL = 'mailto:hedgie@hedgie.io';
export const TERMS_OF_USE = '/terms';
export const TRANSACTIONS = '/transactions';
export const MY_JOURNEY = '/my-journeys';
export const SELECT_HEDGIE = '/select-hedgie';
export const JOURNEY_SUMMARY = '/journey-summary';
export const SELECT_WORLDS = '/select-worlds';
export const NEW_JOURNEY = '/new-journey';
export const JOURNEY = '/journey';
export const INVENTORY = '/inventory';
export const HEDGIE_HOUSE = '/hedgie-house';
export const ALL_BUSINESSES = '/businesses';
export const BUSINESS_DETAIL = '/business/:businessId';
export const ORDER_PRODUCT_CONFIRMATION =
  '/business/:businessId/purchases/:productId';
export const MY_ORDERS = '/my-orders';
export const BUSINESS_ORDERS = '/business-orders/:businessId';
export const BUSINESS_PAGE = '/business-orders';
export const MARKETPLACE = '/marketplace';
export const PRODUCT = '/product';
export const PRODUCT_DETAIL = `${PRODUCT}/:id`;
export const PUBLIC_HEDGIE = '/hedgies/:color';
export const NEW_MY_HEDGIES = '/new-hedgies';
export const LEADER_BOARD = '/leader-board';
export const BATTLE = '/battle/:color';
export const BATTLE_DETAIL = '/battles/:battleId';

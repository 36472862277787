import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Container } from 'reactstrap';
import {
  Color,
  Spinner,
  TooltipsModal,
  HedgieImg,
  Desktop,
  Mobile,
  SubHeading,
  PageTitle,
 // HedgiePurchaseWithPriceButtonNew,
  HedgiePower,
} from 'components';

import * as types from 'types';
import { get } from 'lodash';
import withResponsive from '../../../helper/withResponsive';

/**
 * todo: !!!THIS FILE NEEDS TO BE REFACTORED!!!
 */

const canPurchaseHedgie = (hedgie, user) => {
  if (!hedgie) {
    return false;
  }

  if (hedgie.status === 2 || hedgie.status === 4) {
    return false;
  }

  if (hedgie.status === 0 || !user || !user.id) {
    return true;
  } else if (hedgie.status === 3 && hedgie.owner === user.id) {
    return true;
  }

  return false;
};

const PowersTooltip = styled.button`
  top: -5px;
  right: -30px;
`;

const HedgiePowerBlock = withResponsive(({ hedgie, onClick, isMobile }) => (
  <React.Fragment>
    <h2 className="ff-extra-bold fs-lg dark-color text-center title-with-tooltip ">
      Powers{' '}
      <PowersTooltip href="" onClick={onClick} className="c-tooltip">
        ?
      </PowersTooltip>
    </h2>
    <div className="power-options">
      <HedgiePower hedgie={hedgie} circleBg={isMobile ? '#fff' : '#FFBBD7'} />
    </div>
  </React.Fragment>
));

HedgiePowerBlock.propTypes = {
  hedgie: PropTypes.object,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

HedgiePowerBlock.defaultProps = {
  hedgie: null,
  onClick: null,
  isMobile: false,
};

const PowerItemLabel = styled.b`
  display: inline-block;
  font-weight: bold;
  width: 30%;
  vertical-align: top;
  font-size: 18px;
`;

const PowerItemSkill = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 70%;
  font-size: 18px;
`;

const PowerItem = ({ label, skill }) => (
  <div>
    <PowerItemLabel>{label}: </PowerItemLabel>
    <PowerItemSkill>{skill}</PowerItemSkill>
  </div>
);

PowerItem.propTypes = {
  label: PropTypes.string,
  skill: PropTypes.string,
};

PowerItem.defaultProps = {
  label: null,
  skill: null,
};

function renderDescription(hedgie, tier) {
  const hedgieDescription = get(hedgie, 'description', 'memo');
  if (hedgieDescription) {
    return hedgieDescription;
  } else if (tier) {
    return tier.description;
  }
  return '';
}

class PresalePickerSlide extends React.PureComponent {
  render() {
    const {
      user,
      hedgie,
      tier,
      error,
      loading,
      selectedColor,
      modal,
      onChange,
      onClick,
     // onPurchaseHedgie,
    } = this.props;

    const description = renderDescription(hedgie, tier);

    const title = (
      <h2 className="ff-extra-bold fs-lg color-contrast text-center title-with-tooltip mt-4">
        Powers
      </h2>
    );
    const body = (
      <p className="lead ff-semi-bold mb-0 mt-2" style={{ fontSize: '18px' }}>
        Powers are ranked on a scale from 0 to 100 and determine your Hedgie’s
        performance in the game.
        <br />
        <br />
        <PowerItem label="Water" skill="Helps Hedgie perform best in the Wetlands" />
        <PowerItem label="Fire" skill="Helps Hedgie perform best in the Desert" />
        <PowerItem label="Earth" skill="Helps Hedgie perform best in the Forest" />
        <PowerItem label="Air" skill="Helps Hedgie perform best in the Mountains" />
        <PowerItem label="Luck" skill="Helps Hedgie find Consumables and Collectibles" />
        <PowerItem
          label="Prudence"
          skill="Helps Hedgie find and take care of Tools"
        />
        <PowerItem
          label="Intelligence"
          skill="Helps Hedgie find Resources"
        />
        <PowerItem label="Charm" skill="Helps Hedgie find Curio" />
        <br />
        Some Hedgies have clear dominant traits or powers, while others are more
        balanced. But we love all Hedgies the same!
      </p>
    );

    const defaultHedgieColour = '#229bd4';
    const defaultHedgie = (
      <div className="result-holder">
        <div className="img-fluid actual-result">
          <HedgieImg color={defaultHedgieColour} width="400" />
        </div>
        <span
          className="result-color-code ff-extra-bold fs-extra-md road-sign-stright"
          id="selected-color"
        >
          {defaultHedgieColour}
        </span>
      </div>
    );

    return (
      <div>
        <Container fluid className="pb-5">
          <PageTitle title="Essential Hedgies" />
          <div className="container pt-2" id="essential-hedgies">
            <SubHeading
              heading="Two steps and I’m all yours!"
              className="dark-color ff-semi-bold text-center pb-3"
            />
            <div className="row justify-content-center">
              <div className="col-6 step-item step-odd text-center current-step p-0">
                <div className="step-number-holder">
                  <span className="step-number ff-semi-bold">1</span>
                </div>
                <p className="semi-bold-title dark-color pt-3">
                  Choose your one-of-a-kind <b>Hedgie</b>.<br />
                  Pick your favourite hex colour{' '}
                </p>
              </div>
              <div className="col-6 step-item step-even text-center p-0">
                <div className="step-number-holder">
                  <span className="step-number ff-semi-bold">2</span>
                </div>
                <p className="step-item pt-3">
                  Sooo close
                  <br /> Confirm your purchase below!
                </p>
              </div>
            </div>
          </div>
        </Container>
        <Desktop>
          <div className="container-fluid work-space pink-bgr section-arrow">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12 p-0">
                  <div className="color-picker-holder">
                    <Color
                      color={selectedColor}
                      onChange={onChange}
                      disableAlpha
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12 p-0">
                  {loading && <Spinner />}
                  {error && (
                    <div className="text-center ff-semi-bold fs-semi-lg dark-color error-message relative">
                      {error.message}
                    </div>
                  )}
                  {!hedgie && defaultHedgie}
                  {hedgie && (
                    <div className="result-holder">
                      <div className="img-fluid actual-result">
                        <HedgieImg
                          color={`#${hedgie.color}`}
                          width="400"
                          imgURL={hedgie.imgURL}
                        />
                      </div>
                      <span
                        className="result-color-code ff-extra-bold fs-extra-md road-sign-stright"
                        id="selected-color"
                      >
                        {`#${hedgie.color}`}
                      </span>
                    </div>
                  )}
                  {error && (
                    <div className="text-center ff-semi-bold fs-semi-lg dark-color error-message relative">
                      {error.message}
                    </div>
                  )}
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12 p-0">
                  <div className="result-options text-center">
                    <HedgiePowerBlock {...{ hedgie, onClick }} />
                    <div className="result-info">
                      <p className="text-center ff-semi-bold fs-semi-lg dark-color">
                        {hedgie && !canPurchaseHedgie(hedgie, user) ? (
                          <span className="error">
                            This hedgie has been reserved. Please pick another
                            color.
                          </span>
                        ) : description ? (
                          <span>{description}</span>
                        ) : (
                          <span>Please choose a color to get started!</span>
                        )}
                      </p>
                      {hedgie && canPurchaseHedgie(hedgie, user) ? (
                        <div className="result-price text-center">
                          {/* <HedgiePurchaseWithPriceButtonNew
                            onPurchaseHedgie={() => onPurchaseHedgie(hedgie)}
                            //price={tier.dollar+tier.gasPrice}
                            hedgie={hedgie}
                          /> */}

                          {/* <PriceDisplay price={tier ? tier.dollar : 5} />
                          <div className="price-label road-sign-reg">
                            <Link to={`/booking/${selectedColor.substr(1)}`}>
                              <label className="arrow-right ff-extra-bold color-contrast title-arrow">
                                That&#39;s the one!
                              </label>
                            </Link>
                          </div> */}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="container-fluid section-arrow landing-picker">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-xs-12 p-0">
                  <Color
                    color={selectedColor}
                    onChange={onChange}
                    disableAlpha
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-6 col-xs-12 p-0">
                  {loading && <Spinner />}
                  {error && (
                    <div className="text-center ff-semi-bold fs-semi-lg dark-color error-message relative">
                      {error.message}
                    </div>
                  )}
                  {!hedgie && defaultHedgie}
                  {hedgie && (
                    <div className="result-holder">
                      <div className="img-fluid actual-result">
                        <HedgieImg
                          color={`#${hedgie.color}`}
                          width="400"
                          imgURL={hedgie.imgURL}
                        />
                      </div>
                      <span
                        className="result-color-code ff-extra-bold fs-extra-md road-sign-stright"
                        id="selected-color"
                      >
                        {`#${hedgie.color}`}
                      </span>
                    </div>
                  )}
                  {error && (
                    <div className="text-center ff-semi-bold fs-semi-lg dark-color error-message relative">
                      {error.message}
                    </div>
                  )}
                </div>
                <div
                  className="col-sm-12 col-xs-12 p-0"
                  style={{ marginTop: '150px' }}
                >
                  <div
                    className="result-options text-center"
                    style={{ paddingLeft: '0' }}
                  >
                    <HedgiePowerBlock {...{ hedgie, onClick }} />
                    <div className="result-info mt-1">
                      <p className="text-center ff-semi-bold fs-semi-lg dark-color">
                        {hedgie && hedgie.status !== 0 ? (
                          <span className="error">
                            This hedgie has been reserved. Please pick another
                            color.
                          </span>
                        ) : description ? (
                          <span>{description}</span>
                        ) : (
                          <span>Please choose a color to get started!</span>
                        )}
                      </p>
                      {hedgie && hedgie.status === 0 ? (
                        <div className="result-price text-center pb-3">
                         {/*  <HedgiePurchaseWithPriceButtonNew
                            onPurchaseHedgie={() => onPurchaseHedgie(hedgie)}
                            hedgie={hedgie}
                          /> */}
                          {/* <PriceDisplay price={tier ? tier.dollar : 5} />
                          <div className="price-label road-sign-reg">
                            <Link to={`/booking/${selectedColor.substr(1)}`}>
                              <label className="arrow-right ff-extra-bold color-contrast title-arrow">
                                That&#39;s the one!
                              </label>
                            </Link>
                          </div> */}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Mobile>
        <Modal
          isOpen={modal}
          toggle={onClick}
          modalClassName="dark-modal-backdrop"
        >
          <TooltipsModal title={title} body={body} />
          <p className="mt-4 btn-modal">
            <button
              onClick={onClick}
              className="road-sign-contrast extra-btn-big ff-extra-bold fs-extra-md read-more-btn dark-color title-arrow"
            >
              Gotcha!
            </button>
          </p>
        </Modal>
      </div>
    );
  }
}

PresalePickerSlide.propTypes = {
  user: types.user,
  hedgie: types.hedgie,
  tier: types.tier,
  error: types.error,
  loading: PropTypes.bool.isRequired,
  selectedColor: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onPurchaseHedgie: PropTypes.func.isRequired,
};

PresalePickerSlide.defaultProps = {
  user: null,
  hedgie: null,
  tier: null,
  error: {},
};

export default PresalePickerSlide;

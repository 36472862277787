export const initialState = {};

export const initialResourceState = {
  list: [],
  detail: null,
  errors: null,
  pagination: undefined,
};

export const getResourceState = (state = initialState, resource) =>
  state[resource] || initialResourceState;

export const getList = (state = initialState, resource) =>
  getResourceState(state, resource).list;

export const getDetail = (state = initialState, resource) =>
  getResourceState(state, resource).detail;

export const getErrors = (state = initialState, resource) =>
  getResourceState(state, resource).errors;

export const getPagination = (state = initialState, resource) =>
  getResourceState(state, resource).pagination;

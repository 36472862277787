import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FOOL_TEXT } from 'const/common';
import { randomInRange } from 'helper';
import { checkAprilFoolsDay } from 'helper/handleTime';
import DisplayCountDown from './DisplayCountDown';

const CountDown = ({ ...props }) => {
  const randomNo = randomInRange(0, 11);

  const memoizedAprFoolDay = useMemo(() => checkAprilFoolsDay(), []);

  return (
    <DisplayCountDown
      {...props}
      text={FOOL_TEXT[randomNo]}
      isOnlyText={memoizedAprFoolDay && !props.isDemo}
    />
  );
};

CountDown.propTypes = {
  duration: PropTypes.number.isRequired,
  onDurationComplete: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.number,
  isMobile: PropTypes.bool,
  isDemo: PropTypes.bool,
  color: PropTypes.string,
};

CountDown.defaultProps = {
  className: '',
  width: 268,
  onDurationComplete: null,
  isMobile: false,
  isDemo: false,
  color: '#EE6506',
};

export default CountDown;

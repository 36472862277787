import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Row, Col } from 'reactstrap';

import Log from 'services/log';
import { Button } from 'components';

import FormError from 'components/stateless/FormError';
import * as types from 'types';

const INITIAL_STATE = {
  currentPassword: '',
  passwordOne: '',
  passwordTwo: '',
  validation: {},
};

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      validation: {},
    });
  };

  onSubmit = event => {
    const { currentPassword, passwordOne, passwordTwo } = this.state;
    Log.debug(this.state, 'ChangePasswordForm:onSubmit');

    if (!passwordOne || !passwordTwo) {
      this.setState({
        validation: { message: 'Please provide all fields' },
      });
    } else if (passwordOne !== passwordTwo) {
      this.setState({
        validation: {
          message: 'Provided passwords don’t match, please double check.',
        },
      });
    } else {
      this.props.onSubmit({ currentPassword, newPassword: passwordOne });
    }
    event.preventDefault();
  };

  render() {
    Log.debug('render', 'ChangePasswordForm');

    const {
      currentPassword,
      passwordOne,
      passwordTwo,
      validation,
    } = this.state;

    const { error, working } = this.props;

    const mdSize = 8;
    return (
      <div>
        <Form m3={3} onSubmit={this.onSubmit}>
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="password"
                name="currentPassword"
                id="currentPassword"
                placeholder="Last Password"
                onChange={event => this.onChange(event)}
                value={currentPassword}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="password"
                name="passwordOne"
                id="passwordOne"
                placeholder="New Password"
                onChange={event => this.onChange(event)}
                value={passwordOne}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="password"
                name="passwordTwo"
                id="passwordTwo"
                placeholder="Confirm New Password"
                onChange={event => this.onChange(event)}
                value={passwordTwo}
              />
            </Col>
          </FormGroup>
          <FormError error={error || validation} />
          <Row>
            <FormGroup className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
              <Button
                disabled={validation.message !== undefined || working}
                type="submit"
                style={{ marginLeft: 0 }}
              >
                Change
              </Button>
            </FormGroup>
          </Row>
        </Form>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  error: null,
  working: false,
};

export default ChangePasswordForm;

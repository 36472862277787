import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PriceDisplay, HedgieImg } from 'components';


const TextReserved = styled.div`
  font-size: 10px;
`;
const GreenCircle = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: ${props => props.reserved ? '#d5bc56' : '#12dfc0'} !important;
`;

const ColorCode = styled.span`
  color: ${props => (props.reserved ? '#d5bc56' : '#ffffff')} !important;
`;


const PremiumHedgie = ({ color, price, status, width, height, imgURL }) => {
  let objectHedgie;
  if (status >= 1) {
    objectHedgie = (
      <div className="pb-5 px-3 col-md-3 d-flex justify-content-center align-items-center flex-column flex-wrap">
        <HedgieImg color={`${color}`} width={width} className="" imgURL={imgURL} />
        <div className="example-details">
          <div className="example-price premium-price">
            <GreenCircle reserved className="green-circle reserved">
              {status === 2 && <TextReserved>SOLD</TextReserved>}
              {(status === 3 || status === 1) && <TextReserved>RESERVED</TextReserved>}
            </GreenCircle>
          </div>
          <div className="example-color">
            <ColorCode reserved className="color-code">{color}</ColorCode>
            <span className="color-arrow" />
          </div>
        </div>
      </div>
    );
  } else {
    objectHedgie = (
      <div className="pb-5 px-3 col-md-3 d-flex justify-content-center align-items-center flex-column flex-wrap">
        <HedgieImg color={`${color}`} width={width} className="" imgURL={imgURL} />
        <Link to={`/booking/${color.substr(1)}`}>
          <div className="example-details">
            <div className="example-price premium-price">
              <PriceDisplay price={price} />
            </div>
            <div className="example-color">
              <span className="color-code">
                {color}
              </span>
              <span className="color-arrow" />
            </div>
          </div>
        </Link>
      </div>
    );
  }
  return objectHedgie;
};

PremiumHedgie.propTypes = {
  color: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceStyle: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  imgURL: PropTypes.string,
};

PremiumHedgie.defaultProps = {
  priceStyle: '',
  imgURL: null,
};

export default PremiumHedgie;

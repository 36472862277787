import React from 'react';
import PropTypes from 'prop-types';
import { LearnMoreButton, HeadLine, Typography } from 'components';
import styled from 'styled-components';
import * as paths from 'config/paths';
import curioImg from 'images/curio_coin_img.png';
import withResponsive from 'helper/withResponsive';

const StyledWrapper = styled.div`
  //padding-top: 40px;
`;
const StyledBg = styled.div`
  background-color: #eff3f4;
  padding-bottom: ${props => `${props.alignWithBg}px`};
  padding-top: ${props => (props.isMobile ? '50px' : '62px')};
`;

const StyledCurioImag = styled.div`
  text-align: center;
  margin-top: ${props => `-${props.alignWithBg}px`};
  img {
    width: ${props => (props.isMobile ? '260px' : '431px')};
    height: auto;
  }
`;

const Earn = ({ isMobile }) => {
  const alignWithBg = isMobile ? 150 : 210;
  return (
    <StyledWrapper>
      <StyledBg alignWithBg={alignWithBg} isMobile={isMobile}>
        <HeadLine className="pb-3" title="Earn" fontSize={isMobile ? 27 : 40} />
        <Typography
          className="pb-2"
          text="Trade loot for Curio you can use to buy gear, or spend in the real world!"
        />
        <LearnMoreButton label="Learn more" href={paths.SPEND_CURIO} />
      </StyledBg>
      <StyledCurioImag isMobile={isMobile} alignWithBg={alignWithBg}>
        <img src={curioImg} alt="curioImg" />
      </StyledCurioImag>
    </StyledWrapper>
  );
};

Earn.propTypes = {
  isMobile: PropTypes.bool,
};

Earn.defaultProps = {
  isMobile: false,
};

export default withResponsive(Earn);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button as BtnLink,
} from 'reactstrap';

import * as paths from 'config/paths';

import { Desktop, Mobile, Button, HedgieSocialIcon } from 'components';

import Redirect from 'config/redirect';

const loggedInCls = authenticated => {
  const cls = classnames(' nav-header', {
    'logged-in': authenticated,
  });
  return cls;
};

class PresaleHeaderRu extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = { collapsed: true };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  renderDesktopNav() {
    const { email, authenticated } = this.props;
    return (
      <Navbar className={loggedInCls(authenticated)} light expand="md">
        <NavbarBrand href="/">Hedgie</NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to={paths.ABOUT_PAGE}>
              The Game
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={paths.SPEND_CURIO}>
              Spend Curio
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={paths.BUSINESSES}>
              Partner With Hedgie
            </NavLink>
          </NavItem>
          {!authenticated && (
            <NavItem>
              <Button tag={Link} className="nav-link" to={paths.SIGNIN}>
                Sign In
              </Button>
            </NavItem>
          )}
          {email && authenticated && (
            <NavItem className="has-submenu">
              <BtnLink type="link" className="nav-link color-dark-contrast">
                {email}
                <span className="menu-icon">&nbsp;&nbsp;</span>
              </BtnLink>
              <ul className="sub-menu user-sub-menu pink-bgr-color ">
                <NavItem className="nav-item">
                  <Link className="nav-link" to={paths.MY_HEDGIES}>
                    My Hedgies
                  </Link>
                </NavItem>
                <NavItem className="nav-item">
                  <Link className="nav-link" to={paths.SETTINGS}>
                    Settings
                  </Link>
                </NavItem>
                <NavItem className="nav-item">
                  <Link className="nav-link" to={paths.SIGNOUT}>
                    Sign out
                  </Link>
                </NavItem>
              </ul>
            </NavItem>
          )}
        </Nav>
      </Navbar>
    );
  }

  renderMobileNav() {
    const { email, authenticated } = this.props;
    return (
      <Navbar className={loggedInCls(authenticated)} dark expand="md">
        <NavbarBrand href="/">Hedgie</NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse isOpen={!this.state.collapsed} className="dark-bgr" navbar>
          {authenticated && (
            <Nav className="mobileUserMenuNav" navbar>
              <div className="mobileUserMenu">
                <NavItem className="navEmail">{email}</NavItem>
                <NavItem>
                  <NavLink tag={Link} to={paths.MY_HEDGIES}>
                    My Hedgies
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={paths.SETTINGS}>
                    Settings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={paths.SIGNOUT}>
                    Sign Out
                  </NavLink>
                </NavItem>
              </div>
            </Nav>
          )}
          <Nav onClick={this.toggleNavbar} onSelect={this.toggleNavbar} navbar>
            {!authenticated && (
              <NavItem
                style={{ paddingTop: '30px', paddingBottom: '30px' }}
                className="sign-in"
              >
                <Button
                  tag={Link}
                  size="menu"
                  className="nav-link"
                  to={paths.SIGNIN}
                >
                  Sign In
                </Button>
              </NavItem>
            )}
            <NavItem>
              <NavLink tag={Link} to={paths.ABOUT_PAGE}>
                The Game
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.SPEND_CURIO}>
                Spend Curio
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.OUR_TEAM_PAGE}>
                Team
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.ROADMAP}>
                Roadmap
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://medium.com/hellohedgie" target="blank">
                Blog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.FAQ}>
                FAQ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.TERMS_OF_USE}>
                Terms of Use
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={paths.PRIVACY_PAGE}>
                Privacy Policy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="mailto:hedgie@hedgie.io" target="_blank">
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col md="6" className="flex-vcenter social-column mb-1">
              <div className="soc-icons">
                <HedgieSocialIcon
                  url="https://t.me/hellohedgie"
                  network="telegram-plane"
                />
                <HedgieSocialIcon
                  url="https://discordapp.com/channels/433937954948382732/433937955380264961"
                  network="discord"
                />
                <HedgieSocialIcon
                  url="https://www.instagram.com/hedgieio/"
                  network="instagram"
                />
                <HedgieSocialIcon
                  url="https://twitter.com/hellohedgie"
                  network="twitter"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="flex-vcenter social-column mb-3">
              <div className="soc-icons">
                <HedgieSocialIcon
                  url="https://www.facebook.com/hellohedgie/"
                  network="facebook-f"
                />
                <HedgieSocialIcon
                  url="https://medium.com/hellohedgie"
                  network="medium-m"
                />
                <HedgieSocialIcon
                  url="https://www.youtube.com/hellohedgie"
                  network="youtube"
                />
                <HedgieSocialIcon
                  url="https://www.reddit.com/r/Hedgie"
                  network="reddit-alien"
                />
              </div>
            </Col>
          </Row>
        </Collapse>
      </Navbar>
    );
  }

  render() {
    return (
      <header>
        <Redirect />
        <Desktop>{this.renderDesktopNav()}</Desktop>
        <Mobile>{this.renderMobileNav()}</Mobile>
        <span className="for-logged-in" />
      </header>
    );
  }
}

PresaleHeaderRu.propTypes = {
  email: PropTypes.string,
  anchor: PropTypes.bool,
  authenticated: PropTypes.bool.isRequired,
};

PresaleHeaderRu.defaultProps = {
  email: '',
  anchor: false,
};

export default PresaleHeaderRu;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import { Carousel, SubHeading } from 'components';
import curioImg from '../images/curio.svg';
import boostImg from '../images/opal.png';
import boostFullImg from '../images/boost.png';
import resourcesImg from '../images/fire.png';
import resourcesFullImg from '../images/resources-full.png';
import toolsImg from '../images/compas.png';
import toolsFullImg from '../images/tools-full.png';
import gearImg from '../images/umbrella.png';
import gearFullImg from '../images/gear-full.png';
import ClickableSlide from '../ClickableSlide';
import Info from '../Info';
import Anchor from '../Anchor';

const TEXT =
  'Curiopia has tons of loot to discover! Boost your Hedgie’s attributes with consumables, tools, and gear. ' +
  'Find resources to craft furniture for your Hedgie House, and use your loot to earn more Curio coin by selling it ' +
  'to the Game Coffers.';

const DATA_TREASURES = [
  {
    label: 'Curio',
    name: 'curio',
    description:
      'Find Curio in the four Kingdoms, and sell your boosts for more. Collect Curio and spend it inside the game (coming soon), and at Hedgie-Approved businesses in real world.',
    imgUrl: curioImg,
    fullImgUrl: curioImg,
  },
  {
    label: 'Consumables',
    name: 'consumables',
    description:
      'Each consumable can be ordinary, special or rare, and offers a different temporary boost to your Hedgie’s attributes. Discover different consumables in different Kingdoms.',
    imgUrl: boostImg,
    fullImgUrl: boostFullImg,
  },
  {
    label: 'Resources',
    name: 'resources',
    description:
      'Five resources are used to build furniture for the Hedgie House. Journey to the Mountains to find metal, to the Wetlands to collect water, to the Desert to find fire, and to the Forest to gather wood. Journey to all four Kingdoms to find earth.',
    imgUrl: resourcesImg,
    fullImgUrl: resourcesFullImg,
  },
  {
    label: 'Tools',
    name: 'tools',
    description:
      'Equip tools to boost your Hedgie’s attributes and loot-finding skills, or to shorten your Hedgie’s travel time. But watch out: Tools can be lost or break from wear and tear!',
    imgUrl: toolsImg,
    fullImgUrl: toolsFullImg,
  },
  {
    label: 'Gear',
    name: 'gear',
    description:
      'Suit up! Gear like hats, gloves, boots, coats, and umbrellas boosts your Hedgie’s attributes. They come in every Hedgie hex colour. Gear that’s the same hex colour as your Hedgie will give it a double boost. If your Hedgie is equipped with all Gear of its own hex colour, it will double the boost again!',
    imgUrl: gearImg,
    fullImgUrl: gearFullImg,
  },
];

const StyledTreasuresMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledToolsMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 180px;
    ${media.phone`
      height:120px;
    `}
  }
`;

const ToolsMobile = ({ fullImgUrl, label }) => (
  <StyledToolsMobile>
    <img src={fullImgUrl} alt={label} />
    <SubHeading heading={label} className="pt-3" />
  </StyledToolsMobile>
);

ToolsMobile.propTypes = {
  fullImgUrl: PropTypes.string,
  label: PropTypes.string,
};

ToolsMobile.defaultProps = {
  label: '',
  fullImgUrl: '',
};

const TreasuresMobile = ({ onNextPage }) => (
  <StyledTreasuresMobile>
    <Info text={TEXT} textColor="#000" noBackground align="left" />
    <Carousel hasPagination hideArrow numberOfItems={DATA_TREASURES.length}>
      {DATA_TREASURES.map(treasure => (
        <ToolsMobile key={treasure.name} {...treasure} />
      ))}
    </Carousel>
    <Anchor
      text="Hedgie House"
      textColor="#000"
      className="pt-3"
      onClick={onNextPage}
    />
  </StyledTreasuresMobile>
);

TreasuresMobile.propTypes = {
  onNextPage: PropTypes.func,
};

TreasuresMobile.defaultProps = {
  onNextPage: null,
};

const Treasures = ({ isMobile, onNextPage }) => {
  if (isMobile) return <TreasuresMobile onNextPage={onNextPage} />;
  return (
    <React.Fragment>
      <ClickableSlide
        clickItemGuideline="Click on a loot item to learn more!"
        introText={TEXT}
        introTitle="Loot"
        data={DATA_TREASURES}
        isCircleSmallImg
        limitedItem={3}
        isCoverSmallPhoto={false}
      />
      <Anchor
        text="Hedgie House"
        textColor="#000"
        className="pt-3"
        onClick={onNextPage}
        bottom={isMobile ? 120 : 100}
      />
    </React.Fragment>
  );
};

Treasures.propTypes = {
  onNextPage: PropTypes.func,
  isMobile: PropTypes.bool,
};

Treasures.defaultProps = {
  isMobile: false,
  onNextPage: null,
};

export default memo(Treasures);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
//import Linkify from 'react-linkify';

const StyledTypo = styled.div`
  font-weight: ${props => (props.isBold ? 600 : 400)};
  color: ${props => props.color};
  font-size: ${props => `${props.fontSize}px`};
  text-align: ${props => props.align};
  line-height: normal;
  ${media.phone`
    font-size: ${props => props.fontSize - 2}px};
  `};
`;

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url.substr(0, 50) + '...</a>';
  })
}

const Typography = ({ text, isLink, ...props }) => {
  return (
    <StyledTypo {...props}>
      {/* {isLink ? <Linkify properties={{ target: '_blank' }}>{text}</Linkify> : text} */}
      <span dangerouslySetInnerHTML={{ __html: urlify(text) }} />
      {props.children}
    </StyledTypo>
  );
};

Typography.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  isBold: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  isLink: PropTypes.bool,
};

Typography.defaultProps = {
  text: '',
  color: '#000',
  fontSize: 18,
  align: 'center',
  isBold: false,
  isLink: false,
};

export default Typography;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Desktop } from 'components';
import styled from 'styled-components';
import BlueResult from 'images/blue-result.png';

const StyledLink = styled.a``;

class SignOutPage extends Component {
  static propTypes = {
    signoutFailed: PropTypes.bool,
    signout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    signoutFailed: false,
  };

  UNSAFE_componentWillMount() {
    this.props.signout();
  }

  render() {
    const { signoutFailed } = this.props;
    return (
      <div>
        <main>
          <div className="container-fluid row-section pos-rel overflow-hidden-reg">
            <div className="container">
              <div className="jumbotron ">
                {signoutFailed && (
                  <p className="lead ff-extra-bold mb-0">
                    Signout failed. Try again.
                  </p>
                )}
                {!signoutFailed && (
                  <div>
                    <h1 className="dark-color title mb-3">
                      You’re now logged out.
                    </h1>
                    <div className="col-md-6 px-0">
                      <p className="lead ff-extra-bold mb-0">
                        Hope it’s not long until we get digging again!
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row justify-content-end overflow-hidden bgr-bottom-images pt-5 mt-5">
              <div className="col-md-4 text-right hedige-bubble pt-5 mt-5" />
              <div className="col-md-7 text-right hedige-on-bgr-holder" />
            </div>
            <Desktop>
              <StyledLink
                href="#"
                className="buble-on-bgr cool-bubble btn-big ff-semi-bold fs-extra-md color-light"
                role="button"
              >
                <span>Miss you already!</span>
              </StyledLink>
            </Desktop>
            <img
              src={BlueResult}
              className="img-fluid mobile-side-hedgie cool-rotate"
              alt=""
            />
          </div>
        </main>
      </div>
    );
  }
}

export default SignOutPage;

import React from 'react';
import PropTypes from 'prop-types';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Form, FormGroup, Label, Input, Row } from 'reactstrap';
import { Button, BoldSpan } from 'components';
import Log from 'services/log';

const GIFT_PRICE = 10;
const MAX_GIFTS = 100;

const GiftPrice = props => {
  return (
    <BoldSpan>${props.price} USD</BoldSpan>
  );
};

GiftPrice.propTypes = {
  price: PropTypes.number.isRequired,
};

// see https://github.com/stripe/react-stripe-elements

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      giftCount: 0,
      total: 0,
      hasCardError: true,
      hasFormError: true,
    };
  }

  handleChangeCC = e => {
    const { complete } = e;
    const hasFormError = !document.getElementById('gift-form').checkValidity() || !complete;
    this.setState({
      hasCardError: !complete,
      hasFormError,
    });
  }

  handleChange = e => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const hasFormError = !document.getElementById('gift-form').checkValidity() || this.state.hasCardError;
    this.setState({
      [name]: value,
      hasFormError,
    });
  };

  handleGiftCountChange = e => {
    const { target } = e;
    let { value } = target;
    if (value < 0) {
      value = 0;
    } else if (value > MAX_GIFTS) {
      value = this.state.giftCount;
    }
    const hasFormError = !document.getElementById('gift-form').checkValidity() || this.state.hasCardError;

    const total = value * GIFT_PRICE;
    this.setState({
      giftCount: value,
      total,
      hasFormError,
    });
  };

  handleSubmit = e => {
    const { email, giftCount, hasFormError, hasCardError } = this.state;
    e.preventDefault();
    if (hasFormError || hasCardError) {
      return;
    }

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({ name: this.props.id, email })
    .then(({ token }) => {
      Log.info(`Got token ${token}`, 'Checkout Form');
      this.props.onSubmitGiftRequest({
        email,
        number: giftCount,
        cardToken: token.id,
      });
    });
  }

  render() {
    return (
      <Form id="gift-form" className="mt-3" onSubmit={this.handleSubmit} noValidate>
        <Row>
          <FormGroup className="col-6 col-md-6 col-xs-6">
            <Label for="giftCount">Number of gift codes</Label>
            <Input
              type="number"
              name="giftCount"
              id="giftCount"
              placeholder="Insert number"
              value={this.state.giftCount}
              onChange={this.handleGiftCountChange}
              required
              min="1"
            />
          </FormGroup>
          <FormGroup className="col-6 col-md-6 col-xs-6">
            <Label>Price</Label>
            <div className="input-label pt-1">x <GiftPrice price={GIFT_PRICE} /></div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-12">
            <Label for="name">Name as it appears on card</Label>
            <Input
              type="text"
              className="input-text form-control"
              name="name"
              id="name"
              placeholder=""
              value={this.state.name}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-12">
            <Label>Pay with</Label>
            <CardElement style={{ base: { fontSize: '18px' } }} hidePostalCode onChange={this.handleChangeCC} />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-12">
            <Label for="email">Insert your email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Insert your email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-12">
            <div className="input-label">Subtotal <BoldSpan>${this.state.total} USD</BoldSpan></div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start">
            <Button size="large" type="submit" disabled={this.state.hasFormError}>Confirm Purchase</Button>
          </FormGroup>
        </Row>
      </Form>
    );
  }
}

CheckoutForm.propTypes = {
  id: PropTypes.string,
  email: PropTypes.string,
  stripe: PropTypes.object,
  giftCount: PropTypes.number,
  onSubmitGiftRequest: PropTypes.func,
};

CheckoutForm.defaultProps = {
  id: '',
  email: '',
  stripe: null,
  giftCount: 0,
  onSubmitGiftRequest: () => { },
};

export default injectStripe(CheckoutForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = ({ link, ...props }) => {
  return (
    <Button tag={Link} to={link} color="link" {...props}>
      <FontAwesomeIcon icon={faArrowLeft} />{' '}
      <span style={{ fontWeight: 600 }}>Back</span>
    </Button>
  );
};

BackButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default BackButton;

import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Button } from 'components';
import { Link } from 'react-router-dom';

function BattleForm({ submitForm }) {
  const [curio, setCurio] = React.useState(0);
  const [color, setColor] = React.useState("");
  const [user, setUser] = React.useState("");
  
  function handleChange(e) {
    setCurio(Number(e.target.value));
  }

   function handleChangeColor(e) {
    console.log(e.target.value);
      setColor(e.target.value);
  } 

  function handleChangeUser(e) {
    console.log(e.target.value);
    setUser(e.target.value);
  }

  function submitData(event) {
    event.preventDefault();
    if (curio) {
      submitForm(curio, color, user);
    }
  }

  return (
    <div>
      <Label>Place a Bet</Label>
      <Form onSubmit={submitData} >
        <FormGroup>
          <Input
            type="select"
            name="bet_amount"
            onChange={handleChange}
            style={{ width: 200 }}
          >
            <option value={0}>Select Curio</option>
            <option value={5}>5 Curio</option>
            <option value={50}>50 Curio</option>
          </Input>
        </FormGroup>

         <FormGroup className='d-none' >
        <Label className='mt-2 d-none'>Enter opponent color</Label>
        <Input
            className='d-none'
            type="text"
            name="opponent_color"
            onChange={handleChangeColor}
            style={{ width: 200 }}
            placeholder="ffffff "
            value={color}
          ></Input>
        </FormGroup> 
        <FormGroup>
        <Label className='mt-2'>Enter opponent user</Label>
        <Input
            type="text"
            name="opponent_user"
            onChange={handleChangeUser}
            style={{ width: 200 }}
            placeholder="username "
            value={user}
          ></Input>
        </FormGroup>

        <FormGroup className='mt-2'>
          <Button type="submit" disabled={!curio}>
            Enter
          </Button>
        </FormGroup>
      </Form>
      <div className="pt-3">
        <Link to="/my-hedgies">Cancel</Link>
      </div>
    </div>
  );
}

export default BattleForm;

import { createSelector } from 'reselect';
import { find, isEmpty, includes } from 'lodash';

const HEDGIE_LIST_LENGTH = 4;
const placeholderList = Array(HEDGIE_LIST_LENGTH).fill(null);

const hedgiesSelector = state => state.hedgies;
const hedgieIdSelector = (state, id) => id;

export const selectHedgiesList = createSelector(
  hedgiesSelector,
  hedgies => hedgies.myHedgies,
);

export const selectHedgieById = createSelector(
  [selectHedgiesList, hedgieIdSelector],
  (list, id) => find(list, hd => hd.id === id),
);

export const selectHedgiesWithPlaceholder = createSelector(
  hedgiesSelector,
  hedgies => generateHedgiesList(placeholderList, hedgies.myHedgies),
);

export const selectHedigesOnJourney = createSelector(
  hedgiesSelector,
  hedgiesState => hedgiesState.hedgie_ids_on_journey,
);

export const selectHedgiesAndHedgiesOnJourney = createSelector(
  [selectHedgiesWithPlaceholder, selectHedigesOnJourney],
  (hedgiesList, onJourneyList) =>
    hedgiesList.map(hd =>
      hd
        ? {
            ...hd,
            disabled: includes(onJourneyList, hd.id),
          }
        : null,
    ),
);

function generateHedgiesList(rootList, hedgieList) {
  const newRootList = [...rootList];
  if (!isEmpty(hedgieList)) {
    newRootList.splice(0, hedgieList.length, ...hedgieList);
  }
  return newRootList;
}

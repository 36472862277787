import NumberUtil from 'services/numberUtil';
import { get } from 'lodash';
import {
  trackEvent,
  trackCustomVariables,
  trackEcommerce,
} from '../services/dataLayer';

const events = {
  CLICK_JOURNEY_BUTTON: 'startjourney',
  SELECT_JOURNEY_HEDGIE: 'hedgieselected',
  CHANGE_WORLD: 'chooseworldclick',
  CHOSEN_WORLD: 'chosenworld',
  DURATION_CLICK: 'durationclick',
  START_A_JOURNEY: 'durationselected',
  FREE_GAME_SIGN_UP_CLICK: 'freegamesignupclick',
  SIGN_UP_CLICK: 'signupclick',
  SIGN_UP_SUCCESS: 'signupsuccess',
  SUBSCRIBE_CLICK: 'subscribeclick',
};

export const eventCategories = {
  JOURNEYS: 'Journeys',
  FREE_GAME: 'Free Game',
  SIGN_UP: 'Sign up',
  SUBSCRIPTION: 'Subscriptions',
};

const eventActions = {
  CLICK_JOURNEY_BUTTON: 'Start Journey',
  HEDGIE_SELECTED: 'Hedgie Selected',
  CHANGE_WORLD: 'Choose World Clicks',
  CHOSEN_WORLD: 'World Selected',
  DURATION_CLICK: 'Duration Clicked',
  START_A_JOURNEY: 'Duration Selected',
  FREE_GAME_SIGN_UP_CLICK: 'Sign up clicks',
  SIGN_UP_CLICK: 'Sign up form submits',
  SIGN_UP_SUCCESS: 'Sign up success',
  SUBSCRIBE_CLICK: 'Clicks to subscribe',
};

export const trackClickingJourneyButton = () => {
  trackEvent(
    events.CLICK_JOURNEY_BUTTON,
    eventCategories.JOURNEYS,
    eventActions.CLICK_JOURNEY_BUTTON,
    '',
  );
};

export const trackSelectingHedgie = () => {
  trackEvent(
    events.SELECT_JOURNEY_HEDGIE,
    eventCategories.JOURNEYS,
    eventActions.HEDGIE_SELECTED,
    '',
  );
};

export const trackClickingOnDifferentWorlds = (worldName, eventCategory) => {
  trackEvent(
    events.CHANGE_WORLD,
    eventCategory,
    eventActions.CHANGE_WORLD,
    worldName,
  );
};

export const trackClickingNextWithWorldSelected = (
  worldName,
  eventCategory,
) => {
  trackEvent(
    events.CHOSEN_WORLD,
    eventCategory,
    eventActions.CHOSEN_WORLD,
    worldName,
  );
};

export const trackDifferentDuration = (
  duration,
  errorMessage,
  eventCategory,
) => {
  const eventLabel = errorMessage ? `${duration}:${errorMessage}` : duration;
  trackEvent(
    events.DURATION_CLICK,
    eventCategory,
    eventActions.DURATION_CLICK,
    eventLabel,
  );
};

export const trackStartingtAJourney = (duration, eventCategory) => {
  trackEvent(
    events.START_A_JOURNEY,
    eventCategory,
    eventActions.START_A_JOURNEY,
    Math.floor(duration / 3600),
  );
};

export const trackSignUpToUnveilTreasures = () => {
  trackEvent(
    events.FREE_GAME_SIGN_UP_CLICK,
    eventCategories.FREE_GAME,
    eventActions.FREE_GAME_SIGN_UP_CLICK,
    '',
  );
};

export const trackSignUp = referralCode => {
  const eventLabel = referralCode || '';
  trackEvent(
    events.SIGN_UP_CLICK,
    eventCategories.SIGN_UP,
    eventActions.SIGN_UP_CLICK,
    eventLabel,
  );
};
export const trackSignUpSuccess = referralCode => {
  const eventLabel = referralCode || '';
  trackEvent(
    events.SIGN_UP_SUCCESS,
    eventCategories.SIGN_UP,
    eventActions.SIGN_UP_SUCCESS,
    eventLabel,
  );
};

export const trackSubscribeToNewsletters = () => {
  trackEvent(
    events.SUBSCRIBE_CLICK,
    eventCategories.SUBSCRIPTION,
    eventActions.SUBSCRIBE_CLICK,
    '',
  );
};

export const trackHomePage = variation => {
  trackCustomVariables({ variation });
};

export const trackUserState = loggedIn => {
  const signInStatus = loggedIn ? 'signed in' : 'anonymous';
  trackCustomVariables({ loginstate: signInStatus });
};

export const trackUserBalance = curioBalance => {
  if (curioBalance) {
    const balance = NumberUtil.toDecimal(curioBalance);
    trackCustomVariables({ balance });
  }
};

export const trackPurchasing = payload => {
  let transactionID = get(payload, 'id', '');
  const total = get(payload, 'total', 0);
  let affiliation = get(payload, 'code', '');
  const isRedeem = get(payload, 'isRedeem', false);
  const isGiftCode = get(payload, 'isGiftCode', false);
  const itemIds = get(payload, 'itemIds', []);
  const hedgieID = get(payload, 'hedgieID', '');
  const paymentMethod = get(payload, 'paymentMethod', '');

  if (paymentMethod === 'ether') {
    transactionID = get(payload, 'hedgieIds[0]', '');
    affiliation = 'ether';
  }

  let products = [];

  if (isGiftCode) {
    const id = get(payload, 'id', '');

    products.push({
      name: 'hedgie credit',
      id,
      price: Number(total) / (100 * itemIds.length),
      quantity: itemIds.length,
    });
  } else {
    products.push({
      name: 'hedgie',
      id: isRedeem ? hedgieID : itemIds[0],
      price: isRedeem ? 0 : 10,
      quantity: 1,
    });
  }

  if (isRedeem && !transactionID) {
    transactionID = get(payload, 'code', '');
  }

  const params = {
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionID,
          affiliation,
          revenue: Number(total) / 100,
          coupon: '',
        },
        products,
      },
    },
  };

  trackEcommerce(params);
};

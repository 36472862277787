export const trackEvent = (event, eventCategory, eventAction, eventLabel) => {
  window.dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export const trackCustomVariables = payload => {
  window.dataLayer.push(payload);
};

export const trackEcommerce = payload => {
  window.dataLayer.push(payload);
  window.dataLayer.push({ event: 'purchase' });
};

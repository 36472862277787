import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as paths from 'config/paths';
import { breakpoints } from '@xstyled/system';
import ProductInfo from './ProductInfo';
import Card from '../../commons/Card';

const StyledWrapper = styled.div(
  breakpoints({
    xs: css`
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    `,
    sm: css`
      margin-bottom: 0;
    `,
    lg: css`
      margin-bottom: 5px;
    `,
  }),
);

const ProductDiscount = ({
  product,
  buyItem,
  displayType,
  isOddOrder,
  onClickPhoto,
  isLink,
  showAddress,
  numberOfSmallPhotos,
}) => {
  if (isEmpty(product)) {
    return null;
  }

  const renderProduct = (
    <Card
      imgUrl={product.imgURL}
      isOddIndex={!isOddOrder}
      type={displayType}
      onClickPhoto={onClickPhoto}
      numberOfSmallPhotos={numberOfSmallPhotos}
      content={
        <ProductInfo {...product} buyItem={buyItem} showAddress={showAddress} />
      }
    />
  );

  return (
    <StyledWrapper>
      {isLink ? (
        <Link to={`${paths.PRODUCT}/${product.id}`}>{renderProduct}</Link>
      ) : (
        renderProduct
      )}
    </StyledWrapper>
  );
};

ProductDiscount.propTypes = {
  buyItem: PropTypes.func,
  product: PropTypes.object,
  displayType: PropTypes.oneOf(['inline', 'column']),
  onClickPhoto: PropTypes.func,
  isOddOrder: PropTypes.bool,
  isLink: PropTypes.bool,
  showAddress: PropTypes.bool,
  numberOfSmallPhotos: PropTypes.number,
};

ProductDiscount.defaultProps = {
  buyItem: null,
  product: null,
  onClickPhoto: null,
  isOddOrder: false,
  isLink: false,
  displayType: 'inline',
  showAddress: false,
  numberOfSmallPhotos: 0,
};

export default React.memo(ProductDiscount);

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  HeadLine,
  Typography,
  FormError,
  ProgressiveImageLoader,
} from 'components';
import { isEmpty } from 'lodash';
import withResponsive from 'helper/withResponsive';
import SellAndConsumeUI from './SellAndComsumeUI';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile ? '1fr' : 'repeat(auto-fit, minmax(250px, 1fr))'};
  padding: 20px;
  justify-content: center;
`;

const StyledImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-start')};
  height: 100%;
  max-width: ${props => (props.isMobile ? '100%' : '420px')};
  padding-top: ${props => (props.isMobile ? 0 : '20px')};
  padding-right: ${props => (props.isMobile ? 0 : '20px')};
  img {
    width: auto;
    height: ${props => (props.isMobile ? '110px' : '360px')};
  }
`;

const SellAndConsumeItemPage = React.memo(
  ({
    item,
    isMobile,
    curio,
    userItemId,
    sellItem,
    consumeItem,
    consumeItemState,
    hedgies,
    soldItemState,
    onActionSuccess,
    isRewardPopup,
    ...props
  }) => {
    const hedgiesOptions = isEmpty(hedgies)
      ? [{ label: '', value: '' }]
      : hedgies.map(hd => ({
          label: hd.color,
          value: hd.color,
        }));

    const { imgURL, description, name, price } = item;

    const [hedgieId, setHedgieId] = useState('');

    const sellItemCallback = useCallback(() => {
      sellItem({ userItemId, itemId: item.id });
    }, [item.id, sellItem, userItemId]);

    const consumeItemCallback = useCallback(() => {
      consumeItem({
        hedgieID: hedgieId,
        userItemId,
        itemId: item.id,
      });
    }, [consumeItem, hedgieId, item.id, userItemId]);

    const getHedgieId = color => {
      if (isEmpty(hedgies) || !color) {
        return '';
      }
      const existedHedgie = hedgies.find(hdg => hdg.color === color);
      if (!existedHedgie) {
        return '';
      }
      return existedHedgie.id;
    };

    const onSelectHedgieChange = hedigeOp => {
      const hedgieId = getHedgieId(hedigeOp.value);
      if (hedgieId) {
        setHedgieId(hedgieId);
      }
    };

    if (isEmpty(item)) {
      return <div className="align-center">No item</div>;
    }

    useEffect(() => {
      if (soldItemState.isItemSold || consumeItemState.isSold) {
        setTimeout(() => {
          onActionSuccess();
        }, 600);
      }
    }, [soldItemState.isItemSold, consumeItemState.isSold, onActionSuccess]);

    return (
      <div>
        <StyledContainer {...props}>
          <div>
            <StyledImg isMobile={isMobile}>
              {imgURL ? (
                <ProgressiveImageLoader
                  placeHolderUrl=""
                  imgUrl={imgURL}
                  width={isMobile ? 120 : 320}
                  height={isMobile ? 120 : 320}
                />
              ) : null}
            </StyledImg>
          </div>
          <div>
            <HeadLine
              className={isMobile ? 'pt-3' : 'pt-0'}
              title={name}
              fontSize={18}
              color="#000"
              align={isMobile ? 'center' : 'left'}
            />
            <Typography
              className="pt-3"
              text={description}
              fontSize={isMobile ? 16 : 18}
              align={isMobile ? 'center' : 'left'}
            />
            {item.amount || isRewardPopup ? (
              <SellAndConsumeUI
                consumeItemCallback={consumeItemCallback}
                hedgiesOptions={hedgiesOptions}
                onSelectHedgieChange={onSelectHedgieChange}
                isMobile={isMobile}
                price={price}
                sellItemCallback={sellItemCallback}
                soldItemState={soldItemState}
              />
            ) : null}
          </div>
        </StyledContainer>
        {soldItemState.error.error ? (
          <div className="pl-1 align-center">
            <FormError error={soldItemState.error} />
          </div>
        ) : null}
        {consumeItemState.error.error ? (
          <div className="pl-1 align-center">
            <FormError error={consumeItemState.error} />
          </div>
        ) : null}
        {soldItemState.isItemSold && soldItemState.successMessage ? (
          <div className="align-center">
            <Typography text={soldItemState.successMessage} color="#12DFC0" />
          </div>
        ) : null}
        {consumeItemState.isSold && consumeItemState.successMessage ? (
          <div className="align-center">
            <Typography
              text={consumeItemState.successMessage}
              color="#12DFC0"
            />
          </div>
        ) : null}
      </div>
    );
  },
);

SellAndConsumeItemPage.propTypes = {
  curio: PropTypes.string,
  hasAddToInventoryButton: PropTypes.bool,
  isRewardPopup: PropTypes.bool,
  hedgies: PropTypes.array,
  userItemId: PropTypes.string,
  sellItem: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    imgURL: PropTypes.string,
    worldID: PropTypes.string,
    effectDuration: PropTypes.number,
    effectValue: PropTypes.number,
    effectType: PropTypes.string,
    price: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  consumeItem: PropTypes.func,
  consumeItemState: PropTypes.shape({
    isSold: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
  }),
  soldItemState: PropTypes.object.isRequired,
  onActionSuccess: PropTypes.func,
};

SellAndConsumeItemPage.defaultProps = {
  curio: '0',
  isMobile: false,
  isRewardPopup: false,
  hedgies: [],
  userItemId: '',
  sellItem: null,
  item: {
    id: 'testId',
    name: 'name',
    worldID: 'worldID',
    description: '',
    effectType: '',
    imgURL: '',
    effectDuration: 0,
    effectValue: 0,
    price: '0',
  },
  consumeItem: null,
  consumeItemState: {
    isSold: false,
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  onActionSuccess: null,
};
export default withResponsive(SellAndConsumeItemPage);

import { useMemo, useState } from 'react';
import { displayDurationLikeClock } from 'helper/handleTime';
import { useInterval } from './useInterval';

export default seconds => {
  const [time, setTime] = useState(seconds);

  useInterval(() => {
    if (time <= 0) {
      return;
    }
    setTime(time - 1);
  }, 1000);

  const memoizedTime = useMemo(() => displayDurationLikeClock(time), [time]);

  return { time, display: memoizedTime || '00:00:00' };
};

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  JourneyWorldsGrid,
  HedgieImg,
  Button,
  HeadLine,
  SpeechBubble,
  CountDown,
  GradientButton,
  Typography,
} from 'components';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';
import { isEmpty, get } from 'lodash';
import * as paths from 'config/paths';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { media } from 'styles/main';
import {
  trackClickingOnDifferentWorlds,
  trackClickingNextWithWorldSelected,
  trackDifferentDuration,
  eventCategories,
} from '../../../tracking';
import JourneyParsingDuration from './JourneyParsingDuration';
import { selectHedgieById } from '../NewMyHedgiesPage/selector';

const StyledWorldBackground = styled.div`
  position: relative;
  background-image: ${props => `linear-gradient(
      rgba(0, 0, 0, .5), 
      rgba(0, 0, 0, .5)
    ), url(${props.background})`};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.isMobile ? 'center right' : 'center center'};
  background-size: cover;
  background-attachment: scroll;
`;

const StyledWorldOverlayName = styled.div`
  display: none;
  ${media.desktop`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 48px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .world-name{
      font-size: 18px;
      font-weight: 600;
    }
  `}
`;

const StyledHeaderContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const StyledInfo = styled.div`
  padding: 174px 0 160px 0;
  width: 100%;
  > div {
    height: 232px;
  }
`;

const StyledWorldsList = styled.div`
  position: ${props => (props.isMobile ? null : 'relative')};
  margin: ${props => `${props.isMobile ? 0 : -50}px auto 0 auto`};
  z-index: 9;
  max-width: 940px;
  transition: all ease-in-out 450ms;
`;

const StyledHedgieImg = styled.div`
  position: absolute;
  bottom: -80px;
  right: 0;
  width: 290px;
  transform: rotate(0deg);
  transition: all ease-in-out 450ms;
  &.moving {
    transform: translateX(50%) rotate(0deg);
    right: 50%;
    transition: all ease-in-out 450ms;
    width: 226px;
  }
`;

const StyledRibbonHedgieGroup = styled.div`
  position: relative;
  .ribbon {
    position: absolute;
    left: ${props => (props.isMobile ? '50%' : '-212px')};
    top: ${props => (props.isMobile ? '-30%' : '-70px')};
    transform: ${props => (props.isMobile ? 'translate(-50%, -30%)' : null)};
  }
  .speech-bubble {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -110%);
    transition: all 450ms ease-in-out;
    &.open {
      transform: translate(-50%, -95%);
      transition: all 450ms ease;
      opacity: 1;
      visibility: visible;
    }
  }
`;

const StyledButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const STEPS = {
  CHOOSE_A_WORLD: 'choose_a_world',
  CHOOSE_DURATION: 'choose_duration',
  COUNT_DOWN: 'count_down',
};

const ALERT_OVER_ENERGY_MESSAGE =
  'I do not have enough Energy to travel that long, please select a shorter duration.';

const NOT_ENOUGH_ENERGY_MSG =
  "I don't have enough Energy to travel that long, please select a shorter duration.";

const JourneyFlowPage = ({
  worlds,
  resetOnboardingState,
  history,
  isMobile,
  onboarding,
  selectedWorld,
  createJourney,
  getWorlds,
  gotoChooseDuration,
  selectWorld,
  journeyCostsList,
  getJourneyCosts,
  location,
}) => {
  const [isNotEnergyEnough, setIsNotEnergyEnough] = useState(false);

  const hedgieId = get(location, 'state.hedgieId', '');
  const selectedJourneyHedgie = useSelector(state =>
    selectHedgieById(state, hedgieId),
  );
  console.log("selectedHedgie: ", selectedJourneyHedgie);

  useEffect(() => {
    if (isEmpty(worlds)) {
      getWorlds();
    }
    return () => {
      resetOnboardingState();
    };
  }, [getWorlds, resetOnboardingState, worlds]);

  useEffect(() => {
    if (isEmpty(journeyCostsList)) {
      getJourneyCosts();
    }
  }, [getJourneyCosts, journeyCostsList]);

  const onDurationCompleteCallback = useCallback(() => {
    history.push(paths.MY_JOURNEY);
  }, [history]);

  const selectWorldCallback = useCallback(
    world => {
      selectWorld(world);
      trackClickingOnDifferentWorlds(world.name, eventCategories.JOURNEYS);
    },
    [selectWorld],
  );

  const gotoChooseDurationCallback = useCallback(() => {
    if (selectedWorld) {
      gotoChooseDuration(STEPS.CHOOSE_DURATION, selectedWorld.id);
      trackClickingNextWithWorldSelected(
        selectedWorld.name,
        eventCategories.JOURNEYS,
      );
    }
  }, [gotoChooseDuration, selectedWorld]);

  const checkEnoughEnergyCallback = useCallback(
    ({ value }) => {
      if (selectedJourneyHedgie) {
        const { energy, duration } = value;
        const hasEnoughEnergy = selectedJourneyHedgie.energy < energy;
        setIsNotEnergyEnough(hasEnoughEnergy);
        const errorMessage = hasEnoughEnergy ? ALERT_OVER_ENERGY_MESSAGE : '';
        trackDifferentDuration(
          duration,
          errorMessage,
          eventCategories.JOURNEYS,
        );
      }
    },
    [selectedJourneyHedgie],
  );

  const startJourneyCallback = useCallback(
    selectedData => {
      if (selectedJourneyHedgie && selectedWorld) {
        const { duration } = selectedData.value;
        const params = {
          hedgieId: selectedJourneyHedgie.id,
          duration,
          worldId: selectedWorld.id,
        };
        createJourney(params);
      }
    },
    [createJourney, selectedJourneyHedgie, selectedWorld],
  );

  const steps = {
    [STEPS.CHOOSE_A_WORLD]: (
      <div>
        <HeadLine title="Choose a World" color="#fff" fontSize={40} />
        <StyledButtonWrapper>
          <Button size="large" onClick={gotoChooseDurationCallback}>
            Next
          </Button>
        </StyledButtonWrapper>
      </div>
    ),
    [STEPS.CHOOSE_DURATION]: (
      <JourneyParsingDuration
        checkEnoughEnergyCallback={checkEnoughEnergyCallback}
        isNotEnergyEnough={isNotEnergyEnough}
        startJourneyCallback={startJourneyCallback}
        selectedHedgie={selectedJourneyHedgie}
      />
    ),
    [STEPS.COUNT_DOWN]: (
      <div className="align-center">
        <GradientButton
          align={isMobile ? 'center' : 'left'}
          label="Start another journey"
          width={220}
          height={55}
          fontSize={16}
          link={paths.SELECT_HEDGIE}
        />
      </div>
    ),
  };
  const { currentStep, duration } = onboarding;

  if (!selectedJourneyHedgie) {
    return <Redirect to={paths.SELECT_HEDGIE} />;
  }

  return (
    <div>
      <StyledWorldBackground
        background={selectedWorld ? selectedWorld.imageURL : ''}
      >
        {selectedWorld ? (
          <StyledWorldOverlayName>
            <Typography
              className="pl-3 world-name"
              text={selectedWorld.name}
              color="#fff"
            />
          </StyledWorldOverlayName>
        ) : null}
        <StyledHeaderContainer>
          <StyledInfo>
            <div>{steps[currentStep]}</div>
          </StyledInfo>
          <StyledHedgieImg
            className={currentStep !== STEPS.CHOOSE_A_WORLD ? 'moving' : ''}
          >
            <StyledRibbonHedgieGroup isMobile={isMobile}>
              {currentStep === STEPS.COUNT_DOWN ? (
                <CountDown
                  className="ribbon"
                  isMobile={isMobile}
                  color={`#${selectedJourneyHedgie.color}`}
                  duration={duration}
                  onDurationComplete={onDurationCompleteCallback}
                />
              ) : null}
              <SpeechBubble
                className={`speech-bubble ${isNotEnergyEnough ? 'open' : ''}`}
                isWarning
                text={NOT_ENOUGH_ENERGY_MSG}
              />
              <HedgieImg
                color={`#${selectedJourneyHedgie.color}`}
                imgURL={selectedJourneyHedgie? selectedJourneyHedgie.imgURL: null}
                width={isMobile ? 220 : '100%'}
              />
            </StyledRibbonHedgieGroup>
          </StyledHedgieImg>
        </StyledHeaderContainer>
      </StyledWorldBackground>
      {currentStep === STEPS.CHOOSE_A_WORLD ? (
        <StyledWorldsList isMobile={isMobile} className="pb-5">
          <JourneyWorldsGrid
            worlds={worlds}
            selectedWorld={selectedWorld}
            selectWorld={selectWorldCallback}
          />
        </StyledWorldsList>
      ) : null}
    </div>
  );
};

JourneyFlowPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  worlds: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  worldsLoading: PropTypes.bool,
  gotoChooseDuration: PropTypes.func,
  startJourney: PropTypes.func,
  selectWorld: PropTypes.func,
  finishOnboarding: PropTypes.func,
  selectedWorld: PropTypes.object,
  onboarding: PropTypes.object,
  getWorlds: PropTypes.func,
  resetOnboardingState: PropTypes.func,
  selectedJourneyHedgie: PropTypes.object,
  createJourney: PropTypes.func,
  getJourneyCosts: PropTypes.func,
  journeyCostsList: PropTypes.array,
};

JourneyFlowPage.defaultProps = {
  isMobile: false,
  gotoChooseDuration: null,
  selectedWorld: null,
  finishOnboarding: null,
  onboarding: null,
  startJourney: null,
  selectWorld: null,
  worldsLoading: false,
  getWorlds: null,
  resetOnboardingState: null,
  selectedJourneyHedgie: null,
  createJourney: null,
  getJourneyCosts: null,
  journeyCostsList: [],
};

export default withResponsive(JourneyFlowPage);

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Typography, GradientButton } from 'components';
import styled from 'styled-components';
import withResponsive from 'helper/withResponsive';

const StyledContainer = styled.div`
  max-width: 668px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${props =>
    props.align === 'inline' && !props.isMobile ? '1.5fr 2.8fr 2fr' : '1fr'};
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  justify-items: ${props => (props.isMobile ? 'center' : 'left')};
  .select-duration {
    max-width: 280px;
    width: 100%;
    padding: ${props => (props.isMobile ? '0 10px' : 0)};
  }
`;

const textColor = {
  light: '#000',
  dark: '#fff',
};

export const generateDurationValues = (durationDataArr, displayType) => {
  if (displayType === 'sec') {
    return durationDataArr.map(({ duration, energy })  => ({
      label: `${duration} seconds `,
      value: { duration: duration, energy: energy },
    }))
    .filter(dr => dr.value.energy && dr.value.energy > 0);
  }
  return durationDataArr
    .map(({ duration, energy }) => ({
      label:
        duration >= 60
          ? `${duration / 60}h ${energy ? `(${energy} energy)` : ''}`
          : `${duration}min (${energy} energy)`,
      value: { duration: duration * 60, energy },
    }))
    .filter(dr => dr.value.energy && dr.value.energy > 0);
};

const customStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    cursor: 'pointer',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #13dfc0',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#13dfc0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    overflowY: 'scroll',
    position: 'absolute !important',
    zIndex: '999999 !important',
  }),
};

const JourneyChooseDuration = ({
  startJourney,
  theme,
  align,
  buttonProps,
  isMobile,
  durationsData,
  displayType,
  disabled,
  onDurationChange,
}) => {
  const [selectedDuration, setSelectedDuration] = useState(
    generateDurationValues(durationsData, displayType)[0],
  );

  const durationChangeCallback = useCallback(
    value => {
      setSelectedDuration(value);
      if (onDurationChange) {
        onDurationChange(value);
      }
    },
    [onDurationChange],
  );

  const startJourneyCallback = useCallback(
    () => {
      if (startJourney) {
        startJourney(selectedDuration);
      }
    },
    [selectedDuration, startJourney],
  );

  const durationsList = useMemo(
    () => generateDurationValues(durationsData, displayType),
    [displayType, durationsData],
  );

  return (
    <StyledContainer theme={theme} align={align} isMobile={isMobile}>
      <Typography
        className={align === 'column' ? 'mt-3' : ''}
        text="Choose duration"
        color={textColor[theme]}
        align={isMobile ? 'center' : 'left'}
      />
      <Select
        className={`select-duration ${align === 'column' ? 'pt-1' : ''}`}
        defaultValue={durationsList[0]}
        value={selectedDuration}
        options={durationsList}
        styles={customStyles}
        onChange={durationChangeCallback}
      />
      <div className={align === 'column' ? 'pt-3' : ''}>
        <GradientButton
          align={isMobile ? 'center' : 'left'}
          label="Start journey!"
          {...buttonProps}
          onClick={startJourneyCallback}
          disabled={disabled}
        />
      </div>
    </StyledContainer>
  );
};

JourneyChooseDuration.propTypes = {
  startJourney: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
  align: PropTypes.oneOf(['inline', 'column']),
  displayType: PropTypes.oneOf(['sec', 'min']),
  buttonSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  durationsData: PropTypes.array.isRequired,
  onDurationChange: PropTypes.func,
  disabled: PropTypes.bool,
};

JourneyChooseDuration.defaultProps = {
  startJourney: null,
  theme: 'light',
  align: 'column',
  buttonSize: null,
  isMobile: false,
  displayType: 'min',
  onDurationChange: null,
  disabled: false,
};

export default React.memo(withResponsive(JourneyChooseDuration));

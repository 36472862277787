import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import {
  ScrollableTab,
  Play,
  Earn,
  Spend,
  PresaleTopSlide,
  Announcements,
} from 'components';
import { Redirect, withRouter } from 'react-router-dom';

import * as paths from 'config/paths';
import useApiCallHomePage from '../../custom-hooks/useApiCallHomePage';

const PLAY_TAB = {
  hash: '#play',
  name: 'Play',
};
const EARN_TAB = {
  hash: '#earn',
  name: 'Earn',
};

const SPEND_TAB = {
  hash: '#spend',
  name: 'Spend',
};

const HomePage = ({ location }) => {
  const [
    authenticated,
    worlds,
    worldsLoading,
    businesses,
    announcements,
  ] = useApiCallHomePage();

  const openLinkCallback = useCallback(href => {
    window.open(href, '_blank');
  }, []);

  if (authenticated) {
    return <Redirect to={paths.MY_HEDGIES} />;
  }

  return (
    <React.Fragment>
      <PresaleTopSlide />
      <div className="pt-3">
        <Announcements
          announcements={announcements}
          openHref={openLinkCallback}
        />
      </div>
      <ScrollableTab tabs={[PLAY_TAB, EARN_TAB, SPEND_TAB]} />
      <Element name={PLAY_TAB.hash} className={PLAY_TAB.hash}>
        <Play worlds={worlds} loading={worldsLoading} />
      </Element>
      <Element name={EARN_TAB.hash} className={EARN_TAB.hash}>
        <Earn />
      </Element>
      <Element name={SPEND_TAB.hash} className={SPEND_TAB.hash}>
        <Spend businesses={businesses.list} />
      </Element>
    </React.Fragment>
  );
};

HomePage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  announcements: PropTypes.array,
};

HomePage.defaultProps = {
  announcements: [],
};

export default withRouter(HomePage);

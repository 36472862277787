import createReducer from '../createReducer';
import {
  BUILD_FURNITURE,
  BUILD_FURNITURE_FAILURE,
  BUILD_FURNITURE_SUCCESS,
  CLEAN_HOUSE,
  CLEAN_HOUSE_FAILURE,
  CLEAN_HOUSE_SUCCESS,
  CLEAR_BUILDING_FURNITURE,
  GET_FURNITURE_FOR_HOUSE,
  GET_FURNITURE_FOR_HOUSE_FAILURE,
  GET_FURNITURE_FOR_HOUSE_SUCCESS,
  GET_HOUSE_EFFECTS,
  GET_HOUSE_EFFECTS_FAILURE,
  GET_HOUSE_EFFECTS_SUCCESS,
  GET_HOUSES,
  GET_HOUSES_FAILURE,
  GET_HOUSES_SUCCESS,
  GET_RESOURCES_META,
  GET_RESOURCES_META_FAILURE,
  GET_RESOURCES_META_SUCCESS,
  RESET_CLEAN_HOUSE,
} from './actionTypes';

const initialState = {
  hedgieResourceTypes: {
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  houses: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  houseEffects: {
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  upgradedFurniture: {
    data: '',
    hasBuilt: false,
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  furnitureForHouse: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  cleanHouse: {
    cleaned: false,
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
};
export default createReducer(initialState, {
  [GET_RESOURCES_META]: state => {
    state.hedgieResourceTypes.loading = true;
    state.hedgieResourceTypes.error.error = false;
  },
  [GET_RESOURCES_META_SUCCESS]: (state, action) => {
    state.hedgieResourceTypes.loading = false;
    state.hedgieResourceTypes.data = action.payload.data;
  },
  [GET_RESOURCES_META_FAILURE]: (state, action) => {
    state.hedgieResourceTypes.loading = false;
    state.hedgieResourceTypes.error.error = true;
    state.hedgieResourceTypes.error.message = action.payload.data.message;
  },
  [GET_HOUSES]: state => {
    state.houses.loading = true;
    state.houses.error.error = false;
  },
  [GET_HOUSES_SUCCESS]: (state, action) => {
    state.houses.loading = false;
    state.houses.data = action.payload.data;
  },
  [GET_HOUSES_FAILURE]: (state, action) => {
    state.houses.loading = false;
    state.houses.error.error = true;
    state.houses.error.message = action.payload.data.message;
  },
  [GET_FURNITURE_FOR_HOUSE]: state => {
    state.furnitureForHouse.loading = true;
    state.furnitureForHouse.error.error = false;
  },
  [GET_FURNITURE_FOR_HOUSE_SUCCESS]: (state, action) => {
    state.furnitureForHouse.loading = false;
    state.furnitureForHouse.data = action.payload.data;
  },
  [GET_FURNITURE_FOR_HOUSE_FAILURE]: (state, action) => {
    state.furnitureForHouse.loading = false;
    state.furnitureForHouse.error.error = true;
    state.furnitureForHouse.error.message = action.payload.message;
  },
  [BUILD_FURNITURE]: state => {
    state.upgradedFurniture.error.error = false;
    state.upgradedFurniture.loading = true;
  },
  [BUILD_FURNITURE_SUCCESS]: (state, action) => {
    state.upgradedFurniture.loading = false;
    state.upgradedFurniture.hasBuilt = true;
    state.furnitureForHouse.data = state.furnitureForHouse.data.map(fu => {
      if (fu.type === action.payload.data.type) {
        return action.payload.data;
      }
      return fu;
    });
  },
  [BUILD_FURNITURE_FAILURE]: (state, action) => {
    state.upgradedFurniture.loading = false;
    state.upgradedFurniture.error.error = true;
    state.upgradedFurniture.error.message = action.payload.message;
  },
  [CLEAR_BUILDING_FURNITURE]: state => {
    state.upgradedFurniture = initialState.upgradedFurniture;
  },
  [GET_HOUSE_EFFECTS]: state => {
    state.houseEffects.loading = true;
    state.houseEffects.error.error = false;
  },
  [GET_HOUSE_EFFECTS_SUCCESS]: (state, action) => {
    state.houseEffects.loading = false;
    state.houseEffects.data = action.payload.data;
  },
  [GET_HOUSE_EFFECTS_FAILURE]: (state, action) => {
    state.houseEffects.loading = false;
    state.houseEffects.error.error = true;
    state.houseEffects.error.message = action.payload.message;
  },
  [CLEAN_HOUSE]: state => {
    state.cleanHouse.loading = true;
    state.cleanHouse.cleaned = false;
    state.cleanHouse.energyRecoverRate = 0.0;
  },
  [CLEAN_HOUSE_SUCCESS]: (state, action) => {
    state.cleanHouse.loading = false;
    state.houseEffects.data.heath = 100;
    state.cleanHouse.cleaned = true;
    state.cleanHouse.heath = 100;
    state.cleanHouse.eneryRecoverRate = action.payload.data.energyRecoverRate.toFixed(1);
  },
  [RESET_CLEAN_HOUSE]: state => {
    state.cleanHouse.cleaned = false;
  },
  [CLEAN_HOUSE_FAILURE]: (state, action) => {
    state.cleanHouse.loading = false;
    state.cleanHouse.error = {
      error: true,
      message: action.payload.message,
    };
  },
});

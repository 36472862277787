import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

const KingdomBlock = styled.div`
  display: flex !important;
  background: #61285D;
  width: 100%;
`;

const KingdomText = ({ children }) => (
  <p className="text-right">{children}</p>
);
KingdomText.propTypes = {
  children: PropTypes.any.isRequired,
};

const KingdomHeader = ({ children }) => (
  <h2 className="text-right">{children}</h2>
);
KingdomHeader.propTypes = {
  children: PropTypes.any.isRequired,
};

const FourKingdoms = () => {
  return (
    <Row>
      <KingdomBlock>
        <Col md="6">
          { ' ' }
        </Col>
        <Col md="6">
          <KingdomHeader>The FourKingdoms</KingdomHeader>
          <KingdomText>There are four kingdoms of Curiopia to be explored: The Forest, Desert, Wetlands, and Mountains.</KingdomText>
          <KingdomText>In the game, you’ll have the option to explore all four kingdoms with your Hedgie. But choose wisely! Your Hedgie will perform better in some kingdoms than in others.</KingdomText>
        </Col>
      </KingdomBlock>
    </Row>
  );
};

export default FourKingdoms;

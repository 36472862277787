import { useEffect, useState, useRef } from 'react';
import useDidMount from './useDidMount';

export default (placeHolderUrl, imgUrl) => {
  const isFirstRun = useRef(true);
  const isMounted = useDidMount();

  const [loadState, setLoadState] = useState({
    imgUrl: placeHolderUrl,
    loaded: false,
  });

  useEffect(() => {
    if (isFirstRun) {
      const img = new Image();

      img.onload = () => {
        if (isMounted) {
          setLoadState({
            imgUrl: img.src,
            loaded: true,
          });
        }
      };
      img.src = imgUrl;
      isFirstRun.current = false;
    }
    return () => {
      isFirstRun.current = false;
    };
  }, [imgUrl, isMounted]);

  return loadState;
};

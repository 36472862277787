import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const OtpTimeout = 30

const RequestOtpButton = ({ requestOtp, verifyingEmailSucesss }) => {

    const [countdown, setCountdown] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const startCountdown = (value) => {
        if (value === 0) {
            return
        }
        value = value - 1
        setCountdown(value)
        setTimeout(() => {
            startCountdown(value);
        }, 1000);
    }

    const onButtonClick = () => {
        requestOtp()
        startCountdown(OtpTimeout)
    }

    const onOk = () => {
        window.location.reload()
    }

    return (
        verifyingEmailSucesss ? (
            <div>
                <Button color="primary" onClick={onOk}>OK</Button>
            </div >) : (
                <div>
                    <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                        We will sent a verification code to your email
                    </Tooltip>
                    <Button id="TooltipExample" color="primary" disabled={countdown > 0} onClick={onButtonClick}>Send code {countdown > 0 ? `(${countdown})` : null}</Button>
                </div>
            )
    );
}

RequestOtpButton.propTypes = {
    verifyingEmailSucesss: PropTypes.bool.isRequired,
    requestOtp: PropTypes.func.isRequired,
};

export default RequestOtpButton;
import camelCase from 'lodash/camelCase';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as thunk } from 'redux-saga-thunk';
import { sessionReducer } from 'redux-react-session';
import { connectRouter } from 'connected-react-router';

const reducers = {
  form,
  thunk,
  session: sessionReducer,
};

const req = require.context('.', true, /\.\/.+\/reducer\.js$/);

req.keys().forEach(key => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'));
  reducers[storeName] = req(key).default;
});

// export default combineReducers(reducers);
export default history =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

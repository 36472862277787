import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import { Resource } from 'components';
import { RESOURCE_TYPES } from '../../../const/hedgieHouse';

export const StyledResources = styled.div`
  padding-top: 33px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 104px);
  grid-gap: 20px;
  ${media.phone`
    grid-template-columns: repeat(5, 50px);
    grid-gap: 10px;  
  `}
  .house-rs-item {
    ${media.phone`
      padding: 8px;
  `}
  }
`;

const Resources = ({ onClick, selectedName }) => {
  return (
    <StyledResources>
      {RESOURCE_TYPES.map(resourceName => (
        <Resource
          onClick={onClick ? onClick : null}
          name={resourceName}
          key={resourceName}
          hasHover={false}
          isSelected={selectedName === resourceName}
          className="house-rs-item"
        />
      ))}
    </StyledResources>
  );
};

Resources.propTypes = {
  onClick: PropTypes.func,
  selectedName: PropTypes.string,
};

Resources.defaultProps = {
  onClick: null,
  selectedName: '',
};

export default Resources;

import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import rays from '../images/rays.svg';
import Kingdoms from './Kingdoms';

const KingdomsSlide = ({ onNextPage, isMobile }) => {
  return (
    <React.Fragment>
      <ParallaxLayer offset={3} speed={0} style={{ backgroundColor: '#fff' }} />
      {isMobile ? null : (
        <ParallaxLayer
          offset={3}
          speed={0}
          style={{
            backgroundImage: `url(${rays})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right top',
          }}
        />
      )}
      <ParallaxLayer offset={3} speed={0} key="Kingdoms">
        <Kingdoms isMobile={isMobile} onNextPage={onNextPage} />
      </ParallaxLayer>
    </React.Fragment>
  );
};

KingdomsSlide.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

KingdomsSlide.defaultProps = {
  isMobile: false,
};

export default KingdomsSlide;

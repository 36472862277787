import * as paths from './paths';

export const PRIVATE_MENUS = [
  {
    id: 'my-hedgies',
    name: 'Hedgies',
    path: paths.MY_HEDGIES,
  },
  {
    id: 'leader-board',
    name: 'Leaderboard',
    path: paths.LEADER_BOARD,
  },
  {
    id: 'house',
    name: 'House',
    path: paths.HEDGIE_HOUSE,
  },
  {
    id: 'my-journeys',
    name: 'Journeys',
    path: paths.MY_JOURNEY,
  },
  {
    id: 'inventory',
    name: 'Inventory',
    path: paths.INVENTORY,
  },
  {
    id: 'marketplace',
    name: 'Marketplace',
    path: paths.MARKETPLACE,
  },
];

export const PUBLIC_MENUS = [
  {
    id: 'leader-board',
    name: 'Leaderboard',
    path: paths.LEADER_BOARD,
  },
  {
    id: 'the-game',
    name: 'The Game',
    path: paths.ABOUT_PAGE,
  },
  {
    id: 'spend-curio',
    name: 'Spend Curio',
    path: paths.MARKETPLACE,
  },
];

export const MOBILE_PRIVATE_MENUS = [
  ...PRIVATE_MENUS,
  {
    id: 'settings',
    name: 'Settings',
    path: paths.SETTINGS,
  },
  {
    id: 'sign-out',
    name: 'Sign Out',
    path: paths.SIGNOUT,
  },
];

export const MOBILE_PUBLIC_MENUS = [
  ...PUBLIC_MENUS,
  {
    id: 'businesses',
    name: 'Businesses',
    path: paths.ALL_BUSINESSES,
  },
  {
    id: 'team',
    name: 'Team',
    path: paths.OUR_TEAM_PAGE,
  },
  {
    id: 'roadmap',
    name: 'Roadmap',
    path: paths.ROADMAP,
  },
  {
    id: 'blog',
    name: 'Blog',
    href: 'https://medium.com/hellohedgie',
  },
  {
    id: 'partner-with-hedgie',
    name: 'Partner With Hedgie',
    path: paths.BUSINESSES,
  },
  {
    id: 'faq',
    name: 'FAQ',
    path: paths.FAQ,
  },
  {
    id: 'term',
    name: 'Terms of Use',
    path: paths.TERMS_OF_USE,
  },
  {
    id: 'policy',
    name: 'Privacy Policy',
    path: paths.PRIVACY_PAGE,
  },
  {
    id: 'contact',
    name: 'Contact Us',
    href: 'mailto:hedgie@hedgie.io',
  },
];

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  .text {
    color: #b3bac5;
    font-size: 14px;
    margin-left: auto;
    text-transform: none;
    &:hover {
      color: #42526e;
      text-decoration: underline;
    }
  }
`;

const MarkAllAsReadButton = ({ markAllNotificationsAsRead }) => {
  const markAllAsReadCallback = useCallback(
    () => {
      markAllNotificationsAsRead();
    },
    [markAllNotificationsAsRead],
  );

  return (
    <StyledButton onClick={markAllAsReadCallback}>
      <span className="text">Mark all as read</span>
    </StyledButton>
  );
};

MarkAllAsReadButton.propTypes = {
  markAllNotificationsAsRead: PropTypes.func.isRequired,
};

export default MarkAllAsReadButton;

import { useEffect } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import {
  getWorlds,
  resourceListReadRequest,
  getAllAnnouncements,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import {
  selectWorlds,
  selectWorldsLoading,
} from '../../store/journey/selectors';
import { selectAllAnnouncements } from '../../store/announcement/selectors';

export default () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.session.authenticated);

  const worlds = useSelector(state => selectWorlds(state));
  const worldsLoading = useSelector(state => selectWorldsLoading(state));
  const businesses = useSelector(state =>
    fromResource.getResourceState(state, 'businesses'),
  );

  const announcements = useSelector(state => selectAllAnnouncements(state));

  useEffect(() => {
    batch(() => {
      if (isEmpty(worlds)) {
        dispatch(getWorlds());
      }
      if (isEmpty(businesses.list) || size(businesses.list) === 3) {
        dispatch(
          resourceListReadRequest('businesses', {
            offset: 0,
            limit: 8,
          }),
        );
      }
      if (isEmpty(announcements)) {
        dispatch(getAllAnnouncements({ limit: 3 }));
      }
    });
    return () => {};
    // eslint-disable-next-line
  }, []);

  return [authenticated, worlds, worldsLoading, businesses, announcements];
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Carousel } from 'components';
import LazyLoad from 'react-lazyload';
import {
  ImgLoader,
  LearnMoreButton,
  HeadLine,
  Typography,
  PhotoPlaceholder,
} from 'components';
import * as paths from 'config/paths';
import useMedia from '../../custom-hooks/useMedia';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 9;
  max-width: 940px;
  margin: 0 auto;
  padding-top: ${props => (props.isMobile ? '40px' : '66px')};
  padding-bottom: ${props => (props.isMobile ? '80px' : '114px')};
`;

const IMG_RATIO = 940 / 523;

const Play = ({ worlds, loading }) => {
  const isMobile = useMedia(
    ['(min-width: 992px)', '(max-width: 991px)'],
    [false, true],
    false,
  );

  if (isEmpty(worlds)) {
    return null;
  }
  return (
    <StyledWrapper isMobile={isMobile}>
      <HeadLine className="pb-3" title="Play" fontSize={isMobile ? 27 : 40} />
      <Typography
        className="pb-2"
        text="Venture with your Hedgie through different kingdoms, collect loot, and make upgrades!"
      />
      <LearnMoreButton
        label="Learn more"
        href={paths.ABOUT_PAGE}
        isMobile={isMobile}
      />
      <Carousel
        hasPagination
        hideArrow={isMobile}
        numberOfItems={worlds.length}
        isOverLapSlide={!isMobile}
      >
        {loading ? (
          <PhotoPlaceholder ratio={IMG_RATIO} />
        ) : (
          worlds.map(world => (
            <LazyLoad height={isMobile ? 200 : 520} key={world.id} once>
              <ImgLoader
                ratio={IMG_RATIO}
                alt={world.name}
                isBlur={false}
                transition={0.2}
                url={world.imageURL}
                preview={world.previewImageURL}
              />
            </LazyLoad>
          ))
        )}
      </Carousel>
    </StyledWrapper>
  );
};

Play.propTypes = {
  worlds: PropTypes.array,
  loading: PropTypes.bool,
};

Play.defaultProps = {
  worlds: [],
  loading: false,
};

export default Play;

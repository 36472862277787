import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledConsumable = styled.div`
  background: #ffffff;
  width: 100%;
  box-shadow: ${props =>
    props.active ? null : '0 0 8px 0 rgba(0, 0, 0, 0.5)'};
  transition: all ease-in-out 300ms;
  display: flex;
  border: ${props =>
    `${props.border}px solid ${props.active ? '#F4AB13' : '#fff'}`};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 15px;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: 50%;
  max-width: ${props => `${props.maxWidth}px`};
`;

const StyledImg = styled.div`
  max-width: ${props => `${props.maxWidth}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > img {
    width: 112px;
    height: auto;
  }
`;

const ConsumableImageItem = React.memo(
  ({ inCircle, imageUrl, maxWidth, ...props }) =>
    inCircle ? (
      <StyledConsumable {...props}>
        <StyledImg maxWidth={maxWidth}>
          <img src={imageUrl} alt="consumable img" />
        </StyledImg>
      </StyledConsumable>
    ) : (
      <StyledImg maxWidth={maxWidth}>
        <img src={imageUrl} alt="consumable img" />
      </StyledImg>
    ),
);

ConsumableImageItem.propTypes = {
  imageUrl: PropTypes.string,
  size: PropTypes.number,
  active: PropTypes.bool,
  border: PropTypes.number,
  inCircle: PropTypes.bool,
  maxWidth: PropTypes.number,
};

ConsumableImageItem.defaultProps = {
  imageUrl: '',
  size: 160,
  maxWidth: 480,
  active: false,
  border: 3,
  inCircle: true,
};

export default ConsumableImageItem;

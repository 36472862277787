import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledArrow = styled.i`
  border: solid #12dfc0;
  border-width: 0 ${props => props.size - 1}px ${props => props.size - 1}px 0;
  display: inline-block;
  padding: ${props => props.size}px;
  transform: rotate(${props => props.direction}deg);
`;

const StyledWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &:hover {
    .arrow {
      border-color: #62295e;
    }
  }
`;

const Deg = {
  left: 135,
  right: -45,
  up: -135,
  down: 45,
};

const ArrowCSS = ({ size, direction, onClick }) => {
  return (
    <StyledWrapper onClick={onClick}>
      <StyledArrow direction={Deg[direction]} size={size} className="arrow" />
    </StyledWrapper>
  );
};

ArrowCSS.propTypes = {
  size: PropTypes.number,
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
};

ArrowCSS.defaultProps = {
  size: 20,
  direction: 'left',
  onClick: null,
};

export default ArrowCSS;

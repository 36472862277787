import { put, call, fork, take, select } from 'redux-saga/effects';
import querystring from 'querystring';
import { omit } from 'lodash';
import Log from 'services/log';
import {
  GET_NOTIFICATIONS,
  getNotificationsSuccess,
  getNotificationsFailure,
  REGISTER_A_DEVICE,
  registerADeviceSuccess,
  registerADeviceFailure,
  GET_NOTIFICATION_UNSEEN_NUMBER,
  getNumberOfUnseenNotificationsSuccess,
  getNumberOfUnseenNotificationsFailure,
  markAllNotificationsAsReadSuccess,
  markAllNotificationsAsReadFailure,
  MARK_ALL_AS_READ,
  markANotificationAsReadSuccess,
  markANotificationAsReadFailure,
  MARK_A_NOTIFICATION_AS_READ,
  ARCHIVE_NOTIFICATION,
  archiveNotificationSuccess,
  archiveNotificationFailure,
  MARK_ALL_AS_SEEN,
  markAllAsSeenSuccess,
  markAllAsSeenFailure,
  UN_REGISTER_DEVICE,
  unRegisterDeviceSuccess,
  unRegisterDeviceFailure,
  GET_NOTIFICATION_REFERENCE,
  getNotificationReferenceSuccess,
  getNotificationReferenceFailure,
  UPDATE_NOTIFICATION_REFERENCE,
  updateNotificationReferenceSuccess,
  updateNotificationReferenceFailure,
  blockBrowser,
} from './actions';
import { selectUnseenNotifications } from './selectors';

export function* markAllAsSeen(api) {
  try {
    Log.debug('saga: markAllAsSeen');
    const response = yield call([api, api.post], '/notifications/seen');
    Log.debug('saga: markAllAsSeen:response');
    yield put(markAllAsSeenSuccess(response));
  } catch (e) {
    Log.debug('saga: markAllAsSeen:error');
    yield put(markAllAsSeenFailure(e));
  }
}

export function* getNotifications(api, payload) {
  try {
    Log.debug('saga: getNotifications');
    const qs = querystring.stringify(omit(payload, ['isLoadMore']));
    const response = yield call([api, api.get], `/notifications?${qs}`);
    Log.debug('saga: getNotifications:response');
    yield put(
      getNotificationsSuccess({ ...response, isLoadMore: payload.isLoadMore }),
    );
    const unseenNotifications = yield select(selectUnseenNotifications);
    if (unseenNotifications > 0) {
      yield call(markAllAsSeen, api);
    }
  } catch (e) {
    Log.debug('saga: getNotifications:error');
    yield put(getNotificationsFailure(e));
  }
}

export function* getNumberOfUnseenNotifications(api) {
  try {
    Log.debug('saga: getNumberOfUnseenNotifications');
    const response = yield call([api, api.get], '/notifications/count/unseen');
    Log.debug('saga: getNumberOfUnseenNotifications:response');
    yield put(getNumberOfUnseenNotificationsSuccess(response));
  } catch (e) {
    Log.debug('saga: getNumberOfUnseenNotifications:error');
    yield put(getNumberOfUnseenNotificationsFailure(e));
  }
}

export function* markAllNotificationsAsRead(api) {
  try {
    Log.debug('saga: markAllNotificationsAsRead');
    const response = yield call([api, api.post], '/notifications/read');
    Log.debug('saga: markAllNotificationsAsRead:response');
    yield put(markAllNotificationsAsReadSuccess(response));
  } catch (e) {
    Log.debug('saga: markAllNotificationsAsRead:error');
    yield put(markAllNotificationsAsReadFailure(e));
  }
}
export function* markANotificationAsRead(api, notificationId) {
  try {
    Log.debug('saga: markANotificationAsRead');
    const response = yield call(
      [api, api.post],
      `/notifications/${notificationId}/read`,
    );
    Log.debug('saga: markANotificationAsRead:response');
    yield put(markANotificationAsReadSuccess(response));
  } catch (e) {
    Log.debug('saga: markANotificationAsRead:error');
    yield put(markANotificationAsReadFailure(e));
  }
}

export function* unRegisterDevice(api, deviceToken) {
  try {
    Log.debug('saga: unRegisterDevice');
    const response = yield call(
      [api, api.delete],
      `/notifications/devices/tokens/${deviceToken}`,
    );
    Log.debug('saga: unRegisterDevice:response');
    yield put(unRegisterDeviceSuccess(response));
  } catch (e) {
    Log.debug('saga: unRegisterDevice:error');
    yield put(unRegisterDeviceFailure(e));
  }
}

export function* registerADevice(api, { platform, deviceToken, prevToken }) {
  try {
    Log.debug('saga: registerADevice');
    const response = yield call([api, api.post], '/notifications/devices', {
      platform,
      deviceToken,
    });
    Log.debug('saga: registerADevice:response');
    yield put(registerADeviceSuccess(response));
    if (prevToken) {
      yield call(unRegisterDevice, api, prevToken);
    }
  } catch (e) {
    Log.debug('saga: registerADevice:error');
    yield put(registerADeviceFailure(e));
  }
}
export function* getNotificationReference(api) {
  try {
    Log.debug('saga: getNotificationReference');
    const response = yield call([api, api.get], '/notifications/preference');
    Log.debug('saga: getNotificationReference:response');
    yield put(getNotificationReferenceSuccess(response));
  } catch (e) {
    Log.debug('saga: getNotificationReference:error');
    yield put(getNotificationReferenceFailure(e));
  }
}

export function* updateNotificationReference(
  api,
  { isEnabled, isSettingBrowser },
) {
  try {
    Log.debug('saga: updateNotificationReference');
    const response = yield call([api, api.put], '/notifications/preference', {
      isEnabled,
    });
    Log.debug('saga: updateNotificationReference:response');
    yield put(updateNotificationReferenceSuccess(response));
    if (isSettingBrowser) {
      yield put(blockBrowser(true));
    }
  } catch (e) {
    Log.debug('saga: updateNotificationReference:error');
    yield put(updateNotificationReferenceFailure(e));
  }
}

export function* archiveNotification(api, { notificationId, hasNotSeen }) {
  try {
    Log.debug('saga: archiveNotification');
    const response = yield call(
      [api, api.post],
      `/notifications/${notificationId}/archive`,
    );
    Log.debug('saga: archiveNotification:response');
    yield put(
      archiveNotificationSuccess({
        ...response,
        archivedNotificationId: notificationId,
        hasNotSeen,
      }),
    );
  } catch (e) {
    Log.debug('saga: archiveNotification:error');
    yield put(archiveNotificationFailure(e));
  }
}

export function* watchGetNotifications(api) {
  while (true) {
    const { payload } = yield take(GET_NOTIFICATIONS);
    yield call(getNotifications, api, payload);
  }
}
export function* watchRegisterADevice(api) {
  while (true) {
    const { payload } = yield take(REGISTER_A_DEVICE);
    yield call(registerADevice, api, payload);
  }
}
export function* watchGetNumberOfUnseenNotifications(api) {
  while (true) {
    yield take(GET_NOTIFICATION_UNSEEN_NUMBER);
    yield call(getNumberOfUnseenNotifications, api);
  }
}
export function* watchMarkAllNotificationsAsRead(api) {
  while (true) {
    yield take(MARK_ALL_AS_READ);
    yield call(markAllNotificationsAsRead, api);
  }
}
export function* watchMarkANotificationAsRead(api) {
  while (true) {
    const { payload } = yield take(MARK_A_NOTIFICATION_AS_READ);
    yield call(markANotificationAsRead, api, payload);
  }
}
export function* watchArchiveNotification(api) {
  while (true) {
    const { payload } = yield take(ARCHIVE_NOTIFICATION);
    yield call(archiveNotification, api, payload);
  }
}

export function* watchMarkAllAsSeen(api) {
  while (true) {
    yield take(MARK_ALL_AS_SEEN);
    yield call(markAllAsSeen, api);
  }
}

export function* watchUnRegisterDevice(api) {
  while (true) {
    const { payload } = yield take(UN_REGISTER_DEVICE);
    yield call(unRegisterDevice, api, payload);
  }
}

export function* watchGetNotificationReference(api) {
  while (true) {
    yield take(GET_NOTIFICATION_REFERENCE);
    yield call(getNotificationReference, api);
  }
}

export function* watchUpdateNotificationReference(api) {
  while (true) {
    const { payload } = yield take(UPDATE_NOTIFICATION_REFERENCE);
    yield call(updateNotificationReference, api, payload);
  }
}

export default function*({ api }) {
  yield fork(watchGetNotifications, api);
  yield fork(watchRegisterADevice, api);
  yield fork(watchGetNumberOfUnseenNotifications, api);
  yield fork(watchMarkAllNotificationsAsRead, api);
  yield fork(watchMarkANotificationAsRead, api);
  yield fork(watchArchiveNotification, api);
  yield fork(watchMarkAllAsSeen, api);
  yield fork(watchUnRegisterDevice, api);
  yield fork(watchGetNotificationReference, api);
  yield fork(watchUpdateNotificationReference, api);
}

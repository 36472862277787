import React from 'react';
import PropTypes from 'prop-types';
import { SubHeading } from 'components';

const FaqSection = ({ title, content }) => {
  return (
    <div className="col-md-12 mt-3">
      <SubHeading className="fs-length-2" heading={title} />
      {content ? <p className="ff-medium mt-4">{content}</p> : null}
    </div>
  );
};

FaqSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

export default FaqSection;

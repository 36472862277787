import React from 'react';
import PropTypes from 'prop-types';
import { SubHeading, Button, FormError } from 'components';
import { Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import withResponsive from 'helper/withResponsive';
import { StyledFlexBox } from 'styles/main';
import JourneyListItem from './JourneyListItem';

class JourneysList extends React.PureComponent {
  render() {
    const {
      isCountdown,
      journeys,
      emtyMessage,
      isMobile,
      onLoadMore,
      canLoadMore,
      error,
      journeyLoading,
      openModal,
    } = this.props;
    if (error && error.error) {
      return (
        <StyledFlexBox>
          <FormError error={error} />
        </StyledFlexBox>
      );
    }
    return (
      <div className="pb-5">
        <Row>
          {isEmpty(journeys) && !journeyLoading ? (
            <SubHeading
              className="pt-1 sub-heading-center"
              heading={emtyMessage}
            />
          ) : (
            journeys.map((journey, index) =>
              !isEmpty(journey) && !isEmpty(journey.hedgie) ? (
                <JourneyListItem
                  journey={journey}
                  isCountdown={isCountdown}
                  key={journey.id}
                  isMobile={isMobile}
                  openModal={openModal ? openModal(journey.id) : null}
                  index={index}
                />
              ) : null,
            )
          )}
        </Row>
        {canLoadMore ? (
          <StyledFlexBox>
            <Button size="large" onClick={onLoadMore}>
              Load More
            </Button>
          </StyledFlexBox>
        ) : null}
      </div>
    );
  }
}

JourneysList.propTypes = {
  journeys: PropTypes.array,
  emtyMessage: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  journeyLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  openModal: PropTypes.func,
  isCountdown: PropTypes.bool,
};

JourneysList.defaultProps = {
  error: null,
  journeys: null,
  onLoadMore: null,
  openModal: null,
  canLoadMore: false,
  journeyLoading: false,
  emtyMessage: '',
  isMobile: false,
  isCountdown: false,
};

export default withResponsive(JourneysList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PresalePickerSlide } from 'components';
import * as types from 'types';
import * as paths from 'config/paths';

const DEFAULT_COLOR = '#229bd4';

class GettingHedgiePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: DEFAULT_COLOR,
      modal: false,
    };
    this.handlePurchase = this.handlePurchase.bind(this);
  }

  handleChangeComplete = color => {
    this.setState({ selectedColor: color.hex }, () => {
      this.props.getHedgie(color.hex.substr(1));
    });
  };

  handlePurchase = hedgie => {
    this.props.history.push(`${paths.BOOKING_HEDGIE}/${hedgie.color}`);
  };

  handleModal = e => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { hedgie, tier, errorChoosingHedgie, loading, user } = this.props;
    const { selectedColor, modal } = this.state;
    return (
      <div>
        <PresalePickerSlide
          user={user}
          hedgie={hedgie}
          tier={tier}
          error={errorChoosingHedgie}
          selectedColor={selectedColor}
          onChange={this.handleChangeComplete}
          onClick={this.handleModal}
          modal={modal}
          loading={loading}
          onPurchaseHedgie={this.handlePurchase}
        />
      </div>
    );
  }
}

GettingHedgiePage.propTypes = {
  hedgie: types.hedgie,
  tier: types.tier,
  hedgies: types.hedgies,

  errorChoosingHedgie: types.error,
  errorGettingHedgies: types.error,

  loading: PropTypes.bool.isRequired,
  loadingPremium: PropTypes.bool,
  user: types.user,
  authenticated: PropTypes.bool.isRequired,
  // actions
  getHedgie: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

GettingHedgiePage.defaultProps = {
  hedgie: null,
  tier: null,
  hedgies: [],
  errorGettingHedgies: {},
  errorChoosingHedgie: {},
  user: null,
  loadingPremium: false,
};

export default GettingHedgiePage;

import { TIERS } from '../../config/data';

export const initialState = {
  hedgie: null,
  tier: null,
  tiers: TIERS,
  premiumTiers: TIERS.filter(t => t.tier !== 1),
  hedgies: [],
  hedgiesPagination: { limit: 0, offset: 0, total: 0 },

  loading: false,
  loadingPremium: false,
  bookingSuccess: false,

  errorBookingHedgie: null,
  errorGettingHedgies: null,
  errorGettingHedgie: null,
  errorChoosingHedgie: null,

  purchaseWithEtherSuccess: false,
  errorPurchasingWithEther: null,

  giftingSuccess: false,
  giftingError: null,

  purchaseSuccess: false,
  errorPurchasingHedgie: null,

  redeemSuccess: false,
  errorRedeemingGiftCode: null,

  myHedgies: [],
  hedgie_ids_on_journey: [],
  myHedgiesSuccess: undefined,
  errorGetMyHedgies: null,

  syncHedgiesSuccess: false,
  errorSyncingHedgies: null,
  syncHedgiesLoading: false,

  transactionInfo: null,
  walletTyle: '',
  walletAddress: '',
};

export const hedgie = (state = initialState) =>
  state.hedgie || initialState.hedgie;
export const hedgies = (state = initialState) =>
  state.hedgies || initialState.hedgies;
export const hedgiesPagination = (state = initialState) =>
  state.hedgiesPagination || initialState.hedgiesPagination;
export const tier = (state = initialState) => state.tier || initialState.tier;
export const tiers = (state = initialState) =>
  state.tiers || initialState.tiers;
export const premiumTiers = (state = initialState) =>
  state.premiumTiers || initialState.premiumTiers;

export const loading = (state = initialState) =>
  state.loading || initialState.loading;
export const loadingPremium = (state = initialState) =>
  state.loadingPremium || initialState.loadingPremium;

export const errorGettingHedgies = (state = initialState) =>
  state.errorGettingHedgies || initialState.errorGettingHedgies;
export const errorGettingHedgie = (state = initialState) =>
  state.errorGettingHedgie || initialState.errorGettingHedgie;

// Booking
export const bookingSuccess = (state = initialState) =>
  state.bookingSuccess || initialState.bookingSuccess;
export const errorChoosingHedgie = (state = initialState) =>
  state.errorChoosingHedgie || initialState.errorChoosingHedgie;
export const errorBookingHedgie = (state = initialState) =>
  state.errorBookingHedgie || initialState.errorBookingHedgie;

// Gifting
export const giftingSuccess = (state = initialState) =>
  state.giftingSuccess || initialState.giftingSuccess;
export const giftingError = (state = initialState) =>
  state.giftingError || initialState.giftingError;

// Transactions
export const transactionInfo = (state = initialState) =>
  state.transactionInfo || initialState.transactionInfo;

// Purchasing
export const purchaseSuccess = (state = initialState) =>
  state.purchaseSuccess || initialState.purchaseSuccess;
export const errorPurchasingHedgie = (state = initialState) =>
  state.errorPurchasingHedgie || initialState.errorPurchasingHedgie;
// w/ Ether
export const purchaseWithEtherSuccess = (state = initialState) =>
  state.purchaseWithEtherSuccess || initialState.purchaseWithEtherSuccess;
export const errorPurchasingWithEther = (state = initialState) =>
  state.errorPurchasingWithEther || initialState.errorPurchasingWithEther;

// Redeeming
export const redeemSuccess = (state = initialState) =>
  state.redeemSuccess || initialState.redeemSuccess;
export const errorRedeemingGiftCode = (state = initialState) =>
  state.errorRedeemingGiftCode || initialState.errorRedeemingGiftCode;

// My Hedgies
export const myHedgiesSuccess = (state = initialState) =>
  state.myHedgiesSuccess || initialState.myHedgiesSuccess;
export const errorGetMyHedgies = (state = initialState) =>
  state.errorGetMyHedgies || initialState.errorGetMyHedgies;
export const myHedgies = (state = initialState) => {
  return state.myHedgies || initialState.myHedgies;
};
export const hedigesOnJourney = (state = initialState) => {
  return state.hedgie_ids_on_journey || initialState.hedgie_ids_on_journey;
};

// Sync'ing hedgies
export const syncHedgiesLoading = (state = initialState) =>
  state.syncHedgiesLoading;
export const syncHedgiesSuccess = (state = initialState) =>
  state.syncHedgiesSuccess;
export const errorSyncingHedgies = (state = initialState) =>
  state.errorSyncingHedgies;
export const getWalletAddress = (state = initialState) => state.walletAddress;

export const getWalletTyle = (state = initialState) => state.walletTyle;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import * as types from 'types';
import {
  PageTitle,
  //ShareHedgie,
  FormError,
  SelectedHedgie,
  EthWallet,
} from 'components';
import HedgiePagination from './HedgiePagination';
import HedgieHouseIcon from './HedgieHouseIcon';
import HedgieMetaMask from '../HedgieMetaMask';
import StartJourneyButton from './StartJourneyButton';
import HedgiePreLoader from './HedgiePreLoader';

export const hedgieImgWidth = 350;

export const HedgieImgContainer = styled.div`
  padding-top: 60px;
  width: ${hedgieImgWidth}px;
  max-width: 100%;
  margin: auto;
  position: relative;
`;

const MyHedgies = ({
  hedgies,
  journeyCostsError,
  selectedHedgie,
  location,
  onGetHedgie,
  selectJourneyHedgie,
  staticUrl,
  onLoadMore,
  onCompleteEtherBuyNow,
}) => {
  const handleOnSelectHedgie = useCallback(
    (hedgie) => {
      if (!hedgie) {
        onGetHedgie();
      } else if (hedgie !== selectedHedgie) {
        selectJourneyHedgie(hedgie);
      }
    },
    // eslint-disable-next-line
    [selectJourneyHedgie],
  );

  const urlParams = new URLSearchParams(location.search);
  return (
    <div>
      <Container>
        <PageTitle title="My Hedgies" />
        <main style={{ paddingBottom: '65px' }}>
          <EthWallet metaMask={<HedgieMetaMask metaMaskEnabled />} />
          <Row>
            <Col>
              <HedgiePagination
                hedgies={hedgies}
                selectedHedgie={selectedHedgie}
                onSelectHedgie={handleOnSelectHedgie}
                onLoadMore={onLoadMore}
                urlColor={urlParams.get('color')}
               

              />
            </Col>
          </Row>
          <SelectedHedgie  onCompleteEtherBuyNow={onCompleteEtherBuyNow} />
          <HedgiePreLoader
            selectedHedgie={selectedHedgie}
            handleOnSelectHedgie={handleOnSelectHedgie}
          />

          <StartJourneyButton selectedHedgie={selectedHedgie} />

          {journeyCostsError.error ? (
            <FormError error={journeyCostsError} />
          ) : null}
          <div className="pt-3">
            <HedgieHouseIcon />
          </div>
          {selectedHedgie && selectedHedgie.status !== 3 && (
            <Row>
              <Col>
                <div style={{ paddingTop: '30px' }}>
                  {/* <ShareHedgie hedgieColor={selectedHedgie.color} /> */}
                </div>
              </Col>
            </Row>
          )}
        </main>
      </Container>
    </div>
  );
};

MyHedgies.propTypes = {
  hedgies: PropTypes.arrayOf(types.hedgie),
  hedigesOnJourney: PropTypes.array,
  onGetHedgie: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  staticUrl: PropTypes.string.isRequired,
  error: types.error,
  loading: PropTypes.bool,
  selectJourneyHedgie: PropTypes.func,
  lowestJourneyEnergy: PropTypes.number,
  journeyCostsError: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  selectedHedgie: PropTypes.object,
  location: PropTypes.object,
};

MyHedgies.defaultProps = {
  error: null,
  hedgies: [],
  hedigesOnJourney: [],
  loading: false,
  lowestJourneyEnergy: 0,
  journeyCostsError: {
    error: false,
    message: '',
  },
  selectedHedgie: null,
  location: null,
};

export default MyHedgies;

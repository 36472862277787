import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import powerWaterImg from 'images/powers/powerWater.svg';
import powerFireImg from 'images/powers/powerFire.svg';
import powerEarthImg from 'images/powers/powerEarth.svg';
import powerAirImg from 'images/powers/powerAir.svg';
import skillLuckImg from 'images/powers/skillLuck.svg';
import skillPrudenceImg from 'images/powers/skillPrudence.svg';
import skillIntelligenceImg from 'images/powers/skillIntelligence.svg';
import skillCharmImg from 'images/powers/skillCharm.svg';
import energyIcon2 from 'images/powers/energy2.svg';
import lvlIcon from 'images/powers/lvl.svg';
import { pageNames, useBattle } from './battle-context';
import { useInterval } from '../../custom-hooks/useInterval';
import { includes } from 'lodash';
import { useWebsocket } from '../../../contexts/websocket-context';

const StyledPoint = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #accacc;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid #accacc;
  padding: 5px 0;
  min-height: 64px;
  border-radius: ${(p) => (p.isMobile ? '27px' : 0)};
  background-color: ${(p) =>
    p.isHighlight ? '#12DFC0' : p.isMobile ? 'rgba(0,0,0,.4)' : '#fff'};
  .point-name {
    color: ${(p) => (p.isMobile ? '#fff' : '#000')};
  }
`;

const StyledWrapper = styled.div`
  .show-att {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
    transition: all ease-in-out 300ms;
  }
  .hide-att {
    transition: all ease-in-out 300ms;
    visibility: hidden;
    opacity: 0;
    transform: scale(0, 0);
  }
`;

function genTextPoint(point) {
  if (point <= 0) {
    return '';
  }
  if (point === 1) {
    return `+${point} point`;
  }
  return `+${point} points`;
}
const Point = ({
  id,
  ownerPoint,
  opponentPoint,
  iconUrl,
  text,
  isMobile,
  className,
}) => {
  return (
    <StyledPoint
      isHighlight={id === 'energy_points'}
      isMobile={isMobile}
      className={className}
    >
      <div className="point-name">{genTextPoint(ownerPoint)}</div>
      <div>
        {text ? (
          <span className="point-name">{text}</span>
        ) : (
          <img width={40} src={iconUrl} alt="point" />
        )}
      </div>
      <div className="point-name">{genTextPoint(opponentPoint)}</div>
    </StyledPoint>
  );
};

const POINTS = [
  {
    id: 'attribute_fire_points',
    url: powerFireImg,
  },
  {
    id: 'attribute_water_points',
    url: powerWaterImg,
  },
  {
    id: 'attribute_air_points',
    url: powerAirImg,
  },
  {
    id: 'attribute_earth_points',
    url: powerEarthImg,
  },

  {
    id: 'attribute_charm_points',
    url: skillCharmImg,
  },
  {
    id: 'attribute_intelligence_points',
    url: skillIntelligenceImg,
  },
  {
    id: 'attribute_luck_points',
    url: skillLuckImg,
  },
  {
    id: 'attribute_prudence_points',
    url: skillPrudenceImg,
  },
  {
    id: 'level_points',
    url: lvlIcon,
  },
  {
    id: 'sub_total_points',
    text: 'Total',
    url: '',
  },
];

const AttributeSummary = () => {
  const { hedgieOwner, opponent, isMobile, onLeave, setPage } = useBattle();
  const { sendEvent } = useWebsocket();
  const [animatedIndex, setAnimatedIndex] = React.useState([]);
  const count = React.useRef(0);

  useInterval(() => {
    if (count.current < POINTS.length + 1) {
      count.current = count.current + 1;
      setAnimatedIndex((prev) => [...prev, count.current]);
    }
  }, 1000);

  function fightAgain() {
    sendEvent('cancelled_challenge');
    setPage(pageNames.BATTLE_HOME);
  }

  return (
    hedgieOwner &&
    opponent && (
      <StyledWrapper className="pt-4">
        {POINTS.map((point, index) => (
          <Point
            key={point.id}
            id={point.id}
            className={
              includes(animatedIndex, index + 1) ? 'show-att' : 'hide-att'
            }
            opponentPoint={opponent[point.id]}
            iconUrl={point.url}
            text={point.text || ''}
            ownerPoint={hedgieOwner[point.id]}
            isMobile={isMobile}
          />
        ))}

        <div
          className={
            includes(animatedIndex, POINTS.length + 1) ? 'show-att' : 'hide-att'
          }
        >
          {hedgieOwner.sub_total_points === opponent.sub_total_points && (
            <div>
              <div className="text-center pt-3 pb-3">It’s a Tie!</div>
              <StyledPoint isHighlight={true} isMobile={isMobile}>
                <div>{hedgieOwner.energy_points} points</div>
                <img width={40} src={energyIcon2} alt="point" />
                <div>{opponent.energy_points} points</div>
              </StyledPoint>
              <StyledPoint isMobile={isMobile}>
                <div>
                  {hedgieOwner.sub_total_points + hedgieOwner.energy_points}{' '}
                  points
                </div>
                <span>Total</span>
                <div>
                  {opponent.sub_total_points + opponent.energy_points} points
                </div>
              </StyledPoint>
            </div>
          )}
          <StyledPoint className={'show-att'} isMobile={isMobile}>
            <div>
              {hedgieOwner.isWinner ? (
                <span className="blink_me">
                  You win!{' '}
                  <span role="img" aria-label="funny">
                    🤩
                  </span>
                </span>
              ) : (
                <span>
                  {' '}
                  <span role="img" aria-label="sad">
                    😓
                  </span>{' '}
                  Better luck next time!
                </span>
              )}
            </div>
            <div style={{ fontSize: 30 }}>
              <span role="img" aria-label="cup">
                🏆
              </span>
            </div>
            <div>{opponent.isWinner ? <span>Winner!</span> : ''}</div>
          </StyledPoint>
        </div>
        {count.current === POINTS.length + 1 && (
          <div className="text-center pt-4 pb-5">
            <Button onClick={onLeave}>Leave</Button>
            <Button onClick={fightAgain}>Battle again!</Button>
          </div>
        )}
      </StyledWrapper>
    )
  );
};

export default AttributeSummary;

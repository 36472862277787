//  Auth
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

export const signinRequest = (email, password) => ({
  type: SIGNIN_REQUEST,
  payload: {
    email,
    password,
  },
});

export const signinSuccess = (detail) => ({
  type: SIGNIN_SUCCESS,
  payload: detail,
});

export const signinFailure = (error) => ({
  type: SIGNIN_FAILURE,
  payload: error,
});

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';

export const signoutRequest = () => ({
  type: SIGNOUT_REQUEST,
  payload: {},
});

export const signoutSuccess = () => ({
  type: SIGNOUT_SUCCESS,
  payload: {},
});

export const signoutFailure = (error) => ({
  type: SIGNOUT_FAILURE,
  payload: error,
});

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const signUpRequest = (
  email,
  username,
  password,
  tocAccepted,
  caslAccepted,
  referralCode,
  recaptcha,
) => ({
  type: SIGNUP_REQUEST,
  payload: {
    email,
    username,
    password,
    tocAccepted,
    caslAccepted,
    referralCode,
    recaptcha,
  },
});

export const signUpSuccess = () => ({
  type: SIGNUP_SUCCESS,
  payload: {},
});

export const signUpFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

export const CLEAR_ERROR = 'CLEAR_ERROR';

export const clearError = () => ({
  type: CLEAR_ERROR,
  payload: {},
});

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const updatePasswordRequest = (payload) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload,
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: {},
});

export const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const FIREBASE_AUTH_SUCCESS = 'FIREBASE_AUTH_SUCCESS';
export const FIREBASE_AUTH_FAILURE = 'FIREBASE_AUTH_FAILURE';

export const firebaseAuthSuccess = (payload) => ({
  type: FIREBASE_AUTH_SUCCESS,
  payload,
});

export const firebaseAuthFailure = (error) => ({
  type: FIREBASE_AUTH_FAILURE,
  payload: error,
});

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const forgotPasswordRequest = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: {},
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: {},
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const CLOSE_REFERRAL_CODE_MODAL = 'CLOSE_REFERRAL_CODE_MODAL';
export const closeReferralCodeModal = () => ({
  type: CLOSE_REFERRAL_CODE_MODAL,
});


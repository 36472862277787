import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const Title = styled.h2`
  color: ${palette(0)};
  font-weight: normal;
`;

Title.defaultProps = {
  palette: 'black',
};

const CategoryTitle = ({ title, textAlign }) => {
  return (
    <Title className={`CategoryTitle ${textAlign}`}>{title}</Title>
  );
};

CategoryTitle.propTypes = {
  title: PropTypes.string,
  textAlign: PropTypes.string,
};

CategoryTitle.defaultProps = {
  title: '',
  textAlign: 'text-left',
};

export default CategoryTitle;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMessage = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
`;

const EmptyDataMessage = ({ message }) => {
  return (
    <div className="align-center">
      <StyledMessage>{message}</StyledMessage>
    </div>
  );
};

EmptyDataMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyDataMessage;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ImgLoader } from 'components';
import { media } from 'styles/main';
import styled from 'styled-components';
import Typography from '../Typography';

const GridItem = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: all 300ms ease;
`;
const StyledActive = styled.div`
  border: 8px solid #f9b540;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  ${media.tablet`
    border:4px solid #f9b540;
  `}
`;
const StyledOverlayName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 48px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${media.desktop`
    display: none;
    .world-name{
      font-size: 16px;
    }
  `}
`;

const JourneyWorld = memo(({ world, onClick, active, ratio }) => {
  const clickCallback = useCallback(
    () => {
      if (onClick) {
        onClick(world);
      }
    },
    [onClick, world],
  );
  return (
    <GridItem active={active} onClick={clickCallback}>
      <ImgLoader
        ratio={ratio}
        alt="world"
        isBlur={false}
        transition={0.2}
        url={world.imageURL}
        preview={world.previewImageURL}
      />
      {active ? <StyledActive /> : null}
      <StyledOverlayName>
        <Typography
          className="pl-3 world-name"
          text={world.name}
          color="#fff"
        />
      </StyledOverlayName>
    </GridItem>
  );
});

JourneyWorld.propTypes = {
  world: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  ratio: PropTypes.number,
};

JourneyWorld.defaultProps = {
  onClick: null,
  active: false,
  ratio: 16 / 9,
};

export default JourneyWorld;

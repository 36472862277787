import React from 'react';
import { socketUrl } from 'config/env';
import tokenConfig from '../config/tokenConfig';
import ReconnectingWebSocket from 'reconnecting-websocket';
import cogoToast from 'cogo-toast';

const WebSocketContext = React.createContext(null);

const WebSocketProvider = ({ children, isReadyToConnectWebsocket }) => {
  const token = tokenConfig.token;
  const ws = React.useRef(null);
  const [socketMessage, setSocketMessage] = React.useState({
    event: '',
    data: null,
  });

  function connect() {
    return new Promise((resolve, reject) => {
      try {
        ws.current = new ReconnectingWebSocket(socketUrl, token);
        ws.current.debug = true;

        ws.current.onopen = () => {
          console.log('connected success');
          resolve(true);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  React.useEffect(() => {
    if (isReadyToConnectWebsocket && token) {
      connect();
      ws.current.onmessage = (event) => {
        const response = JSON.parse(event.data);
        setSocketMessage(response);
      };

      ws.current.onclose = function (event) {
        if (window.location.pathname.startsWith("/battle/")) {
          cogoToast.warn("Your connection has been lost.");
          setSocketMessage({
            event: "cancelled_challenge",
          });
        }
      };

      ws.current.onerror = function (event) {
      };
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
    // eslint-disable-next-line
  }, [isReadyToConnectWebsocket, token]);

  function sendEvent(eventName, data) {
    ws.current.send(
      JSON.stringify({
        event: eventName,
        data,
      }),
    );
    setSocketMessage({
      event: eventName,
      data,
    });
  }

  return (
    <WebSocketContext.Provider
      value={{
        webSocket: ws.current,
        socketMessage,
        sendEvent,
        setSocketMessage
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebsocket = () => React.useContext(WebSocketContext);

export { WebSocketProvider, useWebsocket };

import axios from 'axios';
import firebase from 'services/firebase';
import nprogress from 'nprogress';
import { apiInstance } from '../../index';
import tokenConfig from "../../config/tokenConfig";

const instance = axios.create();
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
let count = 0;


function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

instance.interceptors.request.use(
  (config) => {
    nprogress.start();
    return config;
  },
  (error) => {
    nprogress.done();
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    count = 0;
    nprogress.done();
    return response;
  },
  (error) => {
    nprogress.done();
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        if (count < 3) {
          firebase.firebase.auth.currentUser.getIdToken(true).then((token) => {
            count += 1;
            apiInstance.setToken(token);
            tokenConfig.token = token;
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(token);
          });
        }
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(instance(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  },
);

export default instance;

export const initialState = {
  error: null,
  signinSuccess: false,
  signinFailed: false,
  signoutFailed: false,
  signoutSuccess: false,
  signUpFailed: false,
  signUpSuccess: false,
  updatePasswordStatus: null,
  working: false,
  firebaseAuthSuccess: false,
  firebaseAuthFailure: false,
  forgotPasswordStatus: null,
  resetPasswordStatus: null,
};

export const getError = (state = initialState) => state.error;

export const signinFailed = (state = initialState) => state.signinFailed;

export const signinSuccess = (state = initialState) => state.signinSuccess;

export const signoutFailed = (state = initialState) => state.signoutFailed;

export const signoutSuccess = (state = initialState) => state.signoutSuccess;

export const signUpFailed = (state = initialState) => state.signUpFailed;

export const signUpSuccess = (state = initialState) => state.signUpSuccess;

export const updatePasswordStatus = (state = initialState) => state.updatePasswordStatus;

export const working = (state = initialState) => state.working;

export const changeSettingsSuccess = (state = initialState) => state.updatePasswordStatus === true;

export const forgotPasswordSuccess = (state = initialState) => state.forgotPasswordStatus === true;

export const forgotPasswordStatus = (state = initialState) => state.forgotPasswordStatus;

export const resetPasswordStatus = (state = initialState) => state.resetPasswordStatus;

export const firebaseAuthSuccess = (state = initialState) => state.firebaseAuthSuccess;

export const firebaseAuthFailure = (state = initialState) => state.firebaseAuthFailure;

export function getParticipantInfo(socketMessage, hedgieOwnerId) {
  if (hedgieOwnerId === null) {
    return {
      hedgieOwner: null,
      opponent: null,
    };
  }
  let hedgieOwner = null;
  let opponent = null;
  if (socketMessage.event === 'battle_finished') {
    // console.log(socketMessage);
    // console.log("-->>",socketMessage.data.participants);
    
    for (const p of socketMessage.data.participants) {
      // console.log("p-->>",p);
      if(p.hedgie_id===hedgieOwnerId){
        hedgieOwner=p;
        break;
      }
    }
   /*  hedgieOwner = socketMessage.data.parcipants.find((hg) => {
      return hg.hedgie_id === hedgieOwnerId;
    }); */

    const isOwnerWinning =
      hedgieOwnerId === socketMessage.data.winner_hedgie_id;
    hedgieOwner = {
      ...hedgieOwner,
      isWinner: isOwnerWinning,
    };

    opponent = socketMessage.data.participants.find((hg) => {
      return hg.hedgie_id !== hedgieOwnerId;
    });

    opponent = { ...opponent, isWinner: !isOwnerWinning };
  }
  return { hedgieOwner, opponent };
}

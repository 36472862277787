import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { HeadLine } from 'components';
import ProductDiscount from '../ProductDiscount';

const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

const StyledWrapper = styled.div`
  padding: 0 10px;
`;

const DiscountMore = ({ products, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <HeadLine
        title="Related Offers"
        fontSize={22}
        align="left"
        color="#62295E"
      />
      <hr />
      <StyledList>
        {products.map(product => (
          <LazyLoad height={306} key={product.id} once throttle={200}>
            <ProductDiscount
              product={product}
              displayType="column"
              isLink
              height={240}
              mobileHeight={130}
            />
          </LazyLoad>
        ))}
      </StyledList>
    </StyledWrapper>
  );
};

DiscountMore.propTypes = {
  products: PropTypes.array,
};

DiscountMore.defaultProps = {
  products: [],
};

export default React.memo(DiscountMore);

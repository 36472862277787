import createReducer from '../createReducer';
import {
  CLOSE_POPUP_EXP,
  OPEN_POPUP_EXP,
  UPGRADE_HEDGIE,
  UPGRADE_HEDGIE_FAILURE,
  UPGRADE_HEDGIE_SUCCESS,
} from './actions';

const initialState = {
  openModal: false,
  upgradedHedgieRes: {},
  upgradedHedgieLoading: false,
  upgradedHedgieErorr: {
    error: false,
    message: '',
  },
};

export default createReducer(initialState, {
  [OPEN_POPUP_EXP]: state => {
    state.openModal = true;
  },
  [CLOSE_POPUP_EXP]: state => {
    state.openModal = false;
    state.upgradedHedgieErorr.error = false;
    state.upgradedHedgieErorr.message = '';
  },
  [UPGRADE_HEDGIE]: state => {
    state.upgradedHedgieLoading = true;
  },
  [UPGRADE_HEDGIE_SUCCESS]: (state, action) => {
    state.upgradedHedgieLoading = false;
    state.upgradedHedgieRes = action.payload.data;
  },
  [UPGRADE_HEDGIE_FAILURE]: (state, action) => {
    state.upgradedHedgieLoading = false;
    state.upgradedHedgieErorr.error = true;
    state.upgradedHedgieErorr.message = action.payload.message;
  },
});

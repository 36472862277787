import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/main';
import Info from '../Info';
import Anchor from '../Anchor';
import downArrow from '../images/downArrow.svg';
import topSilde from '../images/sky-red.jpg';

const GAME_INTRO =
  'Hedgie is a free-to-play idle strategy game. Journey with your Hedgie through four unique kingdoms and dig up all ' +
  'sorts of loot to boost your Hedgie’s performance and most importantly - earn crypto coin!';

const StyledTopSlide = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: ${props => props.src};
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
`;

export const StyledInfo = styled.div`
  position: absolute;
  left: 10%;
  bottom: 20%;
  ${media.phone`
    left: 0;
  `};
`;

const TopSlide = ({ onClick, isMobile }) => {
  const src = isMobile
    ? `linear-gradient(to top, rgba(0,0,0,.5), transparent), url(${topSilde})`
    : `url(${topSilde})`;

  return (
    <React.Fragment>
      <StyledTopSlide src={src}>
        <StyledInfo>
          <Info title="The Game" text={GAME_INTRO} />
        </StyledInfo>
      </StyledTopSlide>
      <Anchor
        text={isMobile ? 'Meet Your Hedgie' : ''}
        onClick={onClick}
        arrow={isMobile ? null : downArrow}
        textColor={isMobile ? '#fff' : '#000'}
      />
    </React.Fragment>
  );
};

TopSlide.propTypes = {
  onClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

TopSlide.defaultProps = {
  isMobile: false,
};

export default memo(TopSlide);

import React from 'react';
import PropTypes from 'prop-types';
import { HeadLine, Typography } from 'components';
import styled from 'styled-components';

const StyledHedgieXP = styled.div`
  min-height: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HedgieXP = ({ xp }) => {
  return (
    <StyledHedgieXP>
      <div>
        <HeadLine title="XP" fontSize={18} />
        <Typography text={`${xp || 0}`} />
      </div>
    </StyledHedgieXP>
  );
};

HedgieXP.propTypes = {
  xp: PropTypes.number,
};

HedgieXP.defaultProps = {
  xp: 0,
};

export default HedgieXP;

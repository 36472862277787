import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { media } from 'styles/main';
import { Carousel, HeadLine, Typography } from 'components';
import powerWaterImg from 'images/powers/powerWater.svg';
import powerFireImg from 'images/powers/powerFire.svg';
import powerEarthImg from 'images/powers/powerEarth.svg';
import powerAirImg from 'images/powers/powerAir.svg';
import skillLuckImg from 'images/powers/skillLuck.svg';
import skillPrudenceImg from 'images/powers/skillPrudence.svg';
import skillIntelligenceImg from 'images/powers/skillIntelligence.svg';
import skillCharmImg from 'images/powers/skillCharm.svg';
import ClickableSlide from '../ClickableSlide';
import Info from '../Info';
import Anchor from '../Anchor';

const POWER_INTRO =
  'No two Hedgies are the same – each one has its own hex colour and possesses eight unique attributes. ' +
  'These attributes determine your Hedgie’s performance in the game. Some Hedgies have dominant attributes, ' +
  'while others are more balanced. But we love all Hedgies the same!';

const POWER_GUIDANCE = 'Psst... click the power to see what it does!';

const POWER_DATA = [
  {
    label: 'Water',
    name: 'powerWater',
    description: 'Helps Hedgie perform best in the Wetlands',
    imgUrl: powerWaterImg,
    fullImgUrl: powerWaterImg,
  },
  {
    label: 'Fire',
    name: 'powerFire',
    description: 'Helps Hedgie perform best in the Desert',
    imgUrl: powerFireImg,
    fullImgUrl: powerFireImg,
  },
  {
    label: 'Earth',
    name: 'powerEarth',
    description: 'Helps Hedgie perform best in the Forest',
    imgUrl: powerEarthImg,
    fullImgUrl: powerEarthImg,
  },
  {
    label: 'Air',
    name: 'powerAir',
    description: 'Helps Hedgie perform best in the Mountains',
    imgUrl: powerAirImg,
    fullImgUrl: powerAirImg,
  },
  {
    label: 'Luck',
    name: 'skillLuck',
    description: 'Helps Hedgie find Consumables and Collectibles',
    imgUrl: skillLuckImg,
    fullImgUrl: skillLuckImg,
  },
  {
    label: 'Prudence',
    name: 'skillPrudence',
    description: 'Helps Hedgie find and take care of Tools',
    imgUrl: skillPrudenceImg,
    fullImgUrl: skillPrudenceImg,
  },
  {
    label: 'Intelligence',
    name: 'skillIntelligence',
    description: 'Helps Hedgie find Resources',
    imgUrl: skillIntelligenceImg,
    fullImgUrl: skillIntelligenceImg,
  },
  {
    label: 'Charm',
    name: 'skillCharm',
    description: 'Helps Hedgie find Curio',
    imgUrl: skillCharmImg,
    fullImgUrl: skillCharmImg,
  },
];

const SUB_TEXT = 'Swap on the powers to see what they do!';

const StyledPowerMobile = styled.div`
  padding: 0 15px;
  margin-top: 30px;
`;

const StyledPowerItemMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PowerItemMobile = ({ label, fullImgUrl, description }) => (
  <StyledPowerItemMobile>
    <img width={90} src={fullImgUrl} alt={label} />
    <HeadLine
      title={label}
      color="#62295e"
      align="center"
      className="pt-3"
      fontSize={18}
    />
    {description ? (
      <Typography
        text={description}
        color="#000"
        fontSize={14}
        align="center"
      />
    ) : null}
  </StyledPowerItemMobile>
);

PowerItemMobile.propTypes = {
  label: PropTypes.string.isRequired,
  fullImgUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Power = ({ isMobile, onNextPage }) => {
  if (isMobile) {
    return (
      <StyledPowerMobile>
        <Info
          subTitle="The Powers"
          text={POWER_INTRO}
          textColor="#000"
          subText={SUB_TEXT}
        />
        <Carousel hasPagination hideArrow numberOfItems={POWER_DATA.length}>
          {POWER_DATA.map(world => (
            <PowerItemMobile key={world.name} {...world} />
          ))}
        </Carousel>
        <Anchor
          text="Discover Curiopia"
          textColor="#000"
          onClick={onNextPage}
        />
      </StyledPowerMobile>
    );
  }
  return (
    <React.Fragment>
      <ClickableSlide
        data={POWER_DATA}
        isMobile={isMobile}
        clickItemGuideline={POWER_GUIDANCE}
        introTitle="The Powers"
        introText={POWER_INTRO}
        isCircleLargePhoto
        isFixedWidth
        isFixedWidthLargePhoto
      />
      <Anchor
        text="Discover Curiopia"
        textColor="#000"
        onClick={onNextPage}
        bottom={isMobile ? 250 : 100}
      />
    </React.Fragment>
  );
};

Power.propTypes = {
  isMobile: PropTypes.bool,
  onNextPage: PropTypes.func,
};

Power.defaultProps = {
  isMobile: false,
  onNextPage: null,
};

export default memo(Power);

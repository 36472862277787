import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimerSpeechBubble, SpeechBubble } from 'components';
import useCountDown from '../../custom-hooks/useCountDown';
import HtmlStringToDom from '../HtmlStringToDom';

const DisplayCountDown = ({
  isOnlyText,
  text,
  duration,
  onDurationComplete,
  className,
  color,
  ...props
}) => {
  const { time, display } = useCountDown(duration);
  useEffect(
    () => {
      if (time === 0 && onDurationComplete) {
        onDurationComplete();
      }
    },
    [onDurationComplete, time],
  );

  return isOnlyText ? (
    <SpeechBubble backgroundColor={color}>
      <HtmlStringToDom htmlString={text} />
    </SpeechBubble>
  ) : (
    <div className={className}>
      <TimerSpeechBubble
        text={`I’ll be back in ${display}`}
        color={color}
        {...props}
        key="count-down"
      />
    </div>
  );
};

DisplayCountDown.propTypes = {
  duration: PropTypes.number.isRequired,
  onDurationComplete: PropTypes.func,
  isOnlyText: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
};
DisplayCountDown.defaultProps = {
  onDurationComplete: null,
  isOnlyText: false,
  text: '',
  color: '',
};
export default DisplayCountDown;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import { parseTime } from 'helper/handleTime';
import { CountDownTimer } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

const StyledWrapper = styled.div`
  //display: flex;
  //align-items: flex-start;
  //justify-content: flex-start;
  display: inline-block;
  vertical-align: middle;
`;

const iconStyle = {
  width: 16,
  height: 16,
  color: '#62295E',
};
const DurationTimer = ({ duration, onComplete }) => {
  if (duration === 0) {
    return <span>Journey is completed</span>;
  }
  return (
    <StyledWrapper>
      <FontAwesomeIcon icon={faHourglassHalf} style={iconStyle} />
      <span style={{ fontWeight: 500 }} className="pl-1">
        <CountDownTimer
          duration={duration}
          onComplete={onComplete}
          render={({ h, m, s }) => parseTime(h, m, s)}
        />
      </span>
    </StyledWrapper>
  );
};

DurationTimer.propTypes = {
  duration: PropTypes.number,
  onComplete: PropTypes.func,
};

DurationTimer.defaultProps = {
  duration: 0,
  onComplete: null,
};

export default DurationTimer;

import { concat } from 'lodash';
import createReducer from '../createReducer';
import {
  GET_MY_JOURNEYS,
  GET_MY_JOURNEYS_SUCCESS,
  GET_MY_JOURNEYS_FAILURE,
  CREATE_JOURNEY_SUCCESS,
  CREATE_JOURNEY_FAILURE,
  CREATE_JOURNEY,
  RESET_CREATED_JOURNEY_STATE,
  GET_WORLDS,
  GET_WORLDS_SUCCESS,
  GET_WORLDS_FAILURE,
  SELECT_WORLD,
  CLEAR_SELECTED_WORLD,
  SELECT_GOAL,
  CLEAR_GOAL,
  GET_REWARDS_BY_JOURNEY_ID,
  GET_REWARDS_BY_JOURNEY_ID_SUCCESS,
  GET_REWARDS_BY_JOURNEY_ID_FAILURE,
  ARCHIVE_JOURNEY_SUCCESS,
  ARCHIVE_JOURNEY,
  ARCHIVE_JOURNEY_FAILURE,
  CLOSE_REWARD_DIALOG,
  GET_JOURNEY_COSTS,
  GET_JOURNEY_COSTS_SUCCESS,
  GET_JOURNEY_COSTS_FAILURE,
} from './actions';

export const initialState = {
  journeys: {
    data: [],
    isLoadMore: false,
    pagination: {
      limit: 5,
      offset: 0,
      total: 0,
    },
    loading: false,
    error: false,
    message: '',
  },
  createdJourney: {
    data: null,
    loading: false,
    error: false,
    message: '',
  },
  world: {
    selectedWorld: null,
  },
  worlds: {
    data: [],
    loading: false,
    error: false,
    message: '',
  },
  reward: {
    data: {},
    loading: {},
    error: false,
    message: '',
  },
  archivedJourney: {
    data: {},
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
  costs: {
    data: [],
    loading: false,
    error: {
      error: false,
      message: '',
    },
  },
};

export default createReducer(initialState, {
  [GET_MY_JOURNEYS]: ({ journeys }) => {
    journeys.loading = true;
    journeys.error = false;
    journeys.message = '';
  },
  [GET_MY_JOURNEYS_SUCCESS]: ({ journeys }, action) => {
    journeys.loading = false;
    if (action.payload.isLoadMore) {
      journeys.data = concat(journeys.data, action.payload.data);
    } else {
      journeys.data = action.payload.data || [];
    }
    journeys.pagination = action.payload.meta.pagination;
  },
  [GET_MY_JOURNEYS_FAILURE]: ({ journeys }, action) => {
    journeys.loading = false;
    journeys.error = action.error;
    journeys.message = action.payload.message;
  },
  [CREATE_JOURNEY]: ({ createdJourney }) => {
    createdJourney.loading = true;
  },
  [CREATE_JOURNEY_SUCCESS]: ({ createdJourney }, action) => {
    createdJourney.loading = false;
    createdJourney.data = action.payload.data;
  },
  [CREATE_JOURNEY_FAILURE]: ({ createdJourney }, action) => {
    createdJourney.loading = false;
    createdJourney.error = action.error;
    createdJourney.message = action.payload.message;
  },
  [RESET_CREATED_JOURNEY_STATE]: state => {
    state.createdJourney = initialState.createdJourney;
  },
  [GET_WORLDS]: ({ worlds }) => {
    worlds.loading = true;
    worlds.error = false;
    worlds.message = '';
  },
  [GET_WORLDS_SUCCESS]: (state, action) => {
    state.worlds.loading = false;
    state.worlds.data = action.payload.data;
  },
  [GET_WORLDS_FAILURE]: ({ worlds }, action) => {
    worlds.loading = false;
    worlds.error = true;
    worlds.message = action.payload.message;
  },
  [SELECT_WORLD]: (state, action) => {
    state.world.selectedWorld = action.payload;
  },
  [CLEAR_SELECTED_WORLD]: state => {
    state.world.selectedWorld = null;
  },
  [SELECT_GOAL]: (state, action) => {
    state.goal = action.payload;
  },
  [CLEAR_GOAL]: state => {
    state.goal = null;
  },
  [GET_REWARDS_BY_JOURNEY_ID]: (state, action) => {
    state.reward.loading[action.payload] = true;
    state.reward.error = false;
    state.reward.message = '';
  },
  [GET_REWARDS_BY_JOURNEY_ID_SUCCESS]: (state, action) => {
    state.reward.loading[action.payload.journeyId] = false;
    state.reward.data[action.payload.journeyId] = action.payload.data;
  },
  [GET_REWARDS_BY_JOURNEY_ID_FAILURE]: (state, action) => {
    state.reward.loading[action.payload.journeyId] = false;
    state.reward.error = true;
    state.reward.message = action.payload.message;
  },
  [ARCHIVE_JOURNEY]: state => {
    state.archivedJourney.loading = true;
    state.archivedJourney.error = initialState.archivedJourney.error;
  },
  [ARCHIVE_JOURNEY_SUCCESS]: (state, action) => {
    state.archivedJourney.loading = false;
    state.archivedJourney.data = action.payload.data;
  },
  [ARCHIVE_JOURNEY_FAILURE]: (state, action) => {
    state.archivedJourney.loading = false;
    state.archivedJourney.error = {
      error: true,
      message: action.payload.message,
    };
  },
  [CLOSE_REWARD_DIALOG]: (state, action) => {
    if (!state.archivedJourney.error.error) {
      state.journeys.data = state.journeys.data.filter(
        journey => journey.id !== action.journeyId,
      );
      state.archivedJourney = initialState.archivedJourney;
    }
  },
  [GET_JOURNEY_COSTS]: state => {
    state.costs.loading = true;
    state.costs.error.error = false;
    state.costs.error.message = '';
  },
  [GET_JOURNEY_COSTS_SUCCESS]: (state, action) => {
    state.costs.loading = false;
    state.costs.data = action.payload.data;
  },
  [GET_JOURNEY_COSTS_FAILURE]: (state, action) => {
    state.costs.loading = false;
    state.costs.error.error = true;
    state.costs.error.message = action.payload.message;
  },
});

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { palette } from 'styled-theme';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const PureSpinner = styled.span`
  display: inline-block;
  border: 0.2em solid ${palette('grayscale', 1, true)};
  border-bottom-color: ${palette(1)};
  border-radius: 50%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  animation: ${spin} 1s linear infinite;
`;

PureSpinner.propTypes = {
  size: PropTypes.number,
  palette: PropTypes.string,
  reverse: PropTypes.bool,
};

PureSpinner.defaultProps = {
  palette: 'primary',
  size: 20,
};

export default PureSpinner;

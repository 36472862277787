import React from 'react';
import PropTypes from 'prop-types';
import HeadLine from '../HeadLine';
import Typography from '../Typography';

const AnnouncementContent = ({ title, body }) => {
  return (
    <div>
      <HeadLine title={title} fontSize={20} align="left" />
      <Typography text={body} fontSize={14} align="left" />
    </div>
  );
};

AnnouncementContent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
};
AnnouncementContent.defaultProps = {
  body: '',
};

export default AnnouncementContent;

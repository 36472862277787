export const OPEN_POPUP_EXP = 'OPEN_POPUP_EXP';
export const CLOSE_POPUP_EXP = 'CLOSE_POPUP_EXP';

export const openPopUpExp = () => ({
  type: OPEN_POPUP_EXP,
});

export const closePopUpExp = () => ({
  type: CLOSE_POPUP_EXP,
});

export const UPGRADE_HEDGIE = 'UPGRADE_HEDGIE';
export const UPGRADE_HEDGIE_SUCCESS = 'UPGRADE_HEDGIE_SUCCESS';
export const UPGRADE_HEDGIE_FAILURE = 'UPGRADE_HEDGIE_FAILURE';

export const upgradeHedgie = payload => ({
  type: UPGRADE_HEDGIE,
  payload,
});

export const upgradeHedgieSuccess = payload => ({
  type: UPGRADE_HEDGIE_SUCCESS,
  payload,
});

export const upgradeHedgieFailure = error => ({
  type: UPGRADE_HEDGIE_FAILURE,
  payload: error,
});

import React from 'react';
import PropTypes from 'prop-types';
import { GradientButton } from 'components';

const UnveilTreasuresButton = ({ onClick, label, isMobile }) => {
  const unveilTreasuresProps = {
    width: isMobile ? 151 : 200,
    height: isMobile ? 41 : 55,
    fontSize: isMobile ? 13 : 16,
    borderRadius: isMobile ? 16 : 20,
    isLabelBold: false,
    align: 'left',
  };
  return (
    <GradientButton
      onClick={onClick}
      label={label}
      {...unveilTreasuresProps}
    />
  );
};

UnveilTreasuresButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

UnveilTreasuresButton.defaultProps = {
  onClick: null,
};
export default UnveilTreasuresButton;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Leader from './Leader';

const LeaderList = ({ list, userId, isHighlight }) => {
  if (isEmpty(list)) {
    return <div className="align-center">No data</div>;
  }
  return (
    <div>
      {list.map((hd, index) => (
        <Leader
          key={`${hd ? hd.id : 'key'}${index}`}
          color={hd ? hd.color : ''}
          img={hd ? hd.imgURL : ''}
          rank={hd ? hd.rank : 1}
          index={index}
          name={hd ? hd.ownerName : ''}
          curioFound={hd ? hd.curioFound : '0'}
          isOwner={hd && userId === hd.owner}
          isHighlight={isHighlight}
        />
      ))}
    </div>
  );
};

LeaderList.propTypes = {
  list: PropTypes.array,
  userId: PropTypes.string,
  isHighlight: PropTypes.bool,
};

LeaderList.defaultProps = {
  list: [],
  userId: '',
  isHighlight: true,
};

export default LeaderList;

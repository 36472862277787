import Camden from 'images/logo_camden_LARGE.png';
import Demello from 'images/logo_demello_LARGE.png';
import Jules from 'images/logo_jules_cafe_LARGE.png';
import Himalayan from 'images/logo_himalayan_javahouse_LARGE.png';
import SkyKey from 'images/sky_key.png';
import EtherBaton from 'images/ether_baton.png';

export const TIERS = [
  {
    tier: 7,
    hcc: 0,
    ether: 0,
    tierName: 'Radiant',
    desc:
      'Radiant Hedgies are the rarest of all, with only eight in existence. Each one has the highest possible value in one of the eight Hedgie attributes.',
    dollar: 312500,
    total: 8,
    uniqueItem: SkyKey,
    uniqueItemName: 'Item name',
    uniqueItemDesc:
      'Don’t lose this one! The Sky Key shortens your Exclusive Hedgie’s travel time in any Kingdom by 70%, making for super-efficient journeys. You’ll also get every other tier’s Exclusive Tool!',
  },
  {
    tier: 6,
    hcc: 0,
    ether: 0,
    tierName: 'hhhhhhedgies',
    desc:
      'These Hedgies have a hex code with all the same characters. Cooooool...',
    dollar: 62500,
    total: 14,
    uniqueItem: EtherBaton,
    uniqueItemName: 'Ether Baton',
    uniqueItemDesc:
      'Conjure up sunny skies in any kingdom so your Hedgie can find special loot that cannot be found without it. In addition to the Ether Baton, you’ll also get the Mushroom Crystal, Desert Crown, Liquid Light, and Cloud Ring. Score!',
  },
  {
    tier: 5,
    hcc: 0,
    ether: 0,
    tierName: 'Half-and-Half',
    desc:
      'These Hedgies have a 3-and-3 hex character combo. How will you take yours',
    dollar: 12500,
    total: 240,
    uniqueItemDesc:
      'Exclusive Tools: Rule all four Kingdoms! Get all four tools that help your Hedgie find specific loot in each Kingdom; Mushroom Crystal in the Forest, the Desert Crown in the Desert, Liquid Light in the Wetlands, and the Cloud Ring in the Mountains.\n',
  },
  {
    tier: 4,
    hcc: 0,
    ether: 0,
    tierName: 'Cool Combo',
    desc:
      'The name doesn’t lie! These Hedgies each have a cool hex character combo.',
    dollar: 2500,
    total: 240,
    uniqueItemDesc:
      'Choose any three of the four items that help your Hedgie find specific loot in each world - Crystal Mushroom in Forest, Desert Crown in Desert, Liquid Light in Wetlands, and Cloud Ring in Mountains. ',
  },
  {
    tier: 3,
    hcc: 0,
    ether: 0,
    tierName: 'Repeat Combo',
    desc:
      'These Hedgies have a repeating hex character combo. Is there an echo in here?!',
    dollar: 500,
    total: 3360,
    uniqueItemDesc:
      'Choose any two of the four items that help your Hedgie find specific loot in each world - Crystal Mushroom in Forest, Desert Crown in Desert, Liquid Light in Wetlands, and Cloud Ring in Mountains. ',
  },
  {
    tier: 2,
    hcc: 0,
    ether: 0,
    tierName: 'Double Trouble',
    desc:
      'These Hedgies have a 2-2-2 hex character combo. Rumor has it they’re twice as cute...',
    dollar: 100,
    total: 3360,
    uniqueItemDesc:
      'Choose any one of the four items that help your Hedgie find specific loot in each world - Crystal Mushroom in Forest, Desert Crown in Desert, Liquid Light in Wetlands, and Cloud Ring in Mountains. ',
  },
  {
    tier: 1,
    hcc: 0,
    ether: 0,
    tierName: 'Radiant Hedgies',
    desc:
      'Radiant Hedgies are the rarest of all, with only eight in existence. Each one has the highest possible value in one of the eight Hedgie attributes. ',
    dollar: 10,
    total: 8,
  },
];

export const BUSINESSES = [
  {
    id: 1,
    image: Jules,
    name: 'Jules Café Patisserie',
    description:
      'Bringing authentic French baked goods to Torontonians for over 10 years while finding ways to rejuvenate the fast food industry using high quality, local ingredients.',
    url: 'http://www.julescafe.ca/',
  },
  {
    id: 2,
    image: Demello,
    name: 'De Mello Palheta Coffee Roasters',
    description:
      'From seed to cup, this favourite local Toronto roaster works to produce the finest cup of coffee and improve the quality of life for all those along the coffee chain.',
    url: 'http://hellodemello.net/',
  },
  {
    id: 3,
    image: Camden,
    name: 'Camden Market',
    description:
      'For 16 years, this Toronto-based business has committed to delivering sustainable, farm-raised, local meat of the highest quality and at the lowest prices.',
    url: 'https://www.camdenmarkettoronto.com/',
  },
  {
    id: 4,
    image: Himalayan,
    name: 'Himalayan Java House',
    description:
      'From pancakes to paninis, Americanos and Affogatos, this new-to-Canada coffee chain is serving up all kinds of Nepalese-inspired goods.',
    url: 'https://www.yelp.ca/biz/himalayan-coffee-house-toronto-2',
  },
];

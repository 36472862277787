import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Button, CustomInput } from 'components';
import { Label, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import FormError from '../../stateless/FormError';

const signUpSchema = Yup.object().shape({
  toUsername: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter Username'),
  note: Yup.string()
    .required('Please enter note'),
});

function validateAmount(currentCurio, value) {
  if (!value) {
    return 'Please enter amount';
  }

  return '';
}

const TransferCurioForm = ({
  onSubmit,
  currentCurioBalance,
  loading,
  transferredCurioError,
  fromBusinessID,
}) => {

  const validateNumberCallback = useCallback(
    value => {
      return validateAmount(currentCurioBalance, value);
    },
    [currentCurioBalance],
  );

  const submitCallback = useCallback(
    values => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Formik
      validationSchema={signUpSchema}
      initialValues={{
        fromBusinessID: fromBusinessID,
        toUsername: '',
        amount: 0,
        note: '',
      }}
      onSubmit={submitCallback}
    >
      {({ dirty }) => (
        <Form>
          <Field type="hidden" className="form-control" name="fromBusinessID" ></Field>
          <FormGroup>
            <Label for="toUsername">To username</Label>
            <Field
              type="text"
              id="toUsername"
              name="toUsername"
              component={CustomInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="amount">Amount</Label>
            <Field
              type="number"
              id="amount"
              name="amount"
              validate={validateNumberCallback}
              component={CustomInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="note">Note</Label>
            <Field
              type="textarea"
              id="note"
              name="note"
              component={CustomInput}
            />
          </FormGroup>
          <FormError error={transferredCurioError} />
          <div className="pb-3">
            <Button size="large" type="submit" disabled={!dirty || loading}>
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TransferCurioForm.propTypes = {
  onSubmit: PropTypes.func,
  currentCurioBalance: PropTypes.string,
  fromBusinessID: PropTypes.string,
  loading: PropTypes.bool,
  transferredCurioError: PropTypes.object,
};

TransferCurioForm.defaultProps = {
  onSubmit: null,
  fromBusinessID: null,
  currentCurioBalance: '0',
  loading: false,
  transferredCurioError: {
    error: false,
    message: '',
  },
};

export default TransferCurioForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Row, Col } from 'reactstrap';

import Log from 'services/log';
import { Button } from 'components';

import FormError from 'components/stateless/FormError';
import * as types from 'types';

const INITIAL_STATE = {
  actionCode: '',
  passwordOne: '',
  passwordTwo: '',
  validation: {},
};

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      validation: {},
    });
  };

  onSubmit = event => {
    const {
      passwordOne,
      passwordTwo,
    } = this.state;
    Log.debug(this.state, 'ChangePasswordForm:onSubmit');

    if (!passwordOne || !passwordTwo) {
      this.setState({
        validation: { message: 'Please provide all fields' },
      });
    } else if (passwordOne !== passwordTwo) {
      this.setState({
        validation: { message: 'Provided passwords don’t match, please double check.' },
      });
    } else {
      this.props.onSubmit({ newPassword: passwordOne });
    }
    event.preventDefault();
  }

  render() {
    Log.debug('render', 'ChangePasswordForm');

    const {
      passwordOne,
      passwordTwo,
      validation,
    } = this.state;

    const {
      error,
      working,
    } = this.props;

    const mdSize = 8;
    return (
      <div>
        <h4>Reset Password</h4>
        <Form m3={3} onSubmit={this.onSubmit} >
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="password"
                name="passwordOne"
                id="passwordOne"
                placeholder="New Password"
                onChange={event => this.onChange(event)}
                value={passwordOne}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={mdSize}>
              <Input
                disabled={working}
                type="password"
                name="passwordTwo"
                id="passwordTwo"
                placeholder="Confirm New Password"
                onChange={event => this.onChange(event)}
                value={passwordTwo}
              />
            </Col>
          </FormGroup>
          <FormError error={error || validation} />
          <Row>
            <FormGroup className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center justify-content-md-start" >
              <Button size="large" disabled={validation.message !== undefined || working} type="submit">Submit</Button>
            </FormGroup>
          </Row>
        </Form>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: types.error,
  working: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  error: null,
  working: false,
};

export default ResetPasswordForm;

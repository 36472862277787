import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormError, OrderList } from 'components';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

const StyledContainer = styled.div`
  width: 100%;
`;

const MyOrders = ({ myOrders, getMyOrders }) => {
  useEffect(() => {
    getMyOrders();
  }, [getMyOrders]);

  const { data, error } = myOrders;
  return (
    <StyledContainer>
      {isEmpty(data) ? <div> No data!</div> : <OrderList orders={data} />}
      {error.error ? <FormError error={error} /> : null}
    </StyledContainer>
  );
};

MyOrders.propTypes = {
  myOrders: PropTypes.object.isRequired,
  getMyOrders: PropTypes.func.isRequired,
};

export default memo(MyOrders);
